

export const slusa = {
    name: 'Scotlynn USA Division',
    main_phone: '239-210-3000',
    website_url: 'https://www.scotlynn.com/',
    address1: '9597 Gulf Research Lane',
    city: 'Fort Myers',
    state_id: 'FL',
    zip_code: '33912'
}


export const slc = {
    name: 'Scotlynn Group',
    main_phone: '1-800-263-9117',
    website_url: 'https://www.scotlynn.com/',
    address1: '1150 Vittoria Rd.',
    city: 'Vittoria',
    state_id: 'ON',
    zip_code: 'N0E 1W0'
}