import React, { Component } from 'react';
import { Alert, Button, Card, Col, Container, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap';

export class NewProspectForm extends Component {
    static displayName = NewProspectForm.name;
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    update_account(e) {
        const new_customer = this.props.new_customer
        if (new_customer) {
            const salesperson_list = this.props.salesperson_list ? this.props.salesperson_list.filter(s => (s.role === 'LAM' || s.role === 'GM') && s.salesperson_is_active === 'Y') : []
            const selected_lam = salesperson_list.find(s => s.salesperson_id.toUpperCase() === e.target.value.toUpperCase() && s.company_id === new_customer.company_id);
            const update_body = {
                target: { name: 'sales_manager_id', value: selected_lam.sales_manager_id }
            }
            this.props.update_account(update_body)

            this.props.update_account(e)
        }

    }

    check_phone_number(e) {
        var reg = /^-?\d*\.?\d*$/;
        let update_body = {
            target: { name: e.target.name, value: this.props.new_customer.main_phone }
        }
        if (e.target.value) {
            if (reg.test(e.target.value)) {
                update_body.value=e.target.value
            }
        } else {
            update_body.value = '';           
        }
        this.props.update_account(update_body)
    }


    render() {

        const new_customer = this.props.new_customer;
        const commodities = this.props.commodities ? this.props.commodities : [];
        const salesperson_list = this.props.salesperson_list ? this.props.salesperson_list.filter(s => (s.role === 'LAM' || s.role === 'GM') && s.company_id === new_customer.company_id && s.salesperson_is_active === 'Y') : []


        const get_row = (col1, col2) =>
            <Row className=" d-flex flex-row justify-content-center w-100 py-1">
                <Col sm={12} med={12} lg={12} xl={6} className="p-2">
                    {col1}
                </Col>
                <Col sm={12} med={12} lg={12} xl={6} className="p-2">
                    {col2}
                </Col>
            </Row>

        const commodity_input = <InputGroup className="overview-tab-input-group">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold w-25">Commodity:</InputGroup.Text>
            <Form.Select
                className="text-sm bg-white text-start w-75"
                name='bill_format_flag'
                isInvalid={!new_customer.bill_format_flag}
                value={new_customer.bill_format_flag}
                onChange={(e) => this.props.update_account(e)}
            >
                <option value=''>Select</option>
                {commodities.map(d =>
                    <option key={d.id} value={d.id}>{ d.name}</option>
                )}
            </Form.Select>
        </InputGroup>;

        const company_id_input = <InputGroup className="overview-tab-input-group" id='company-id-input-add-prospect'>
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold w-25">Company:</InputGroup.Text>
            <Form.Select
                className="text-sm bg-white text-start w-75"
                name='company_id'
                isInvalid={!new_customer.company_id}
                value={new_customer.company_id}
                onChange={(e) => this.props.update_account(e)}
            >
                <option value='TMS'>SLC</option>
                <option value='TMS2'>SLUSA</option>
            </Form.Select>
        </InputGroup>;

        const salesperson_input = <InputGroup className="overview-tab-input-group" id='salesperson-input-add-prospect'>
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold w-25">LAM:</InputGroup.Text>
            <Form.Select
                className="text-sm bg-white text-start w-75"
                name='salesperson_id'
                isInvalid={!new_customer.salesperson_id}
                value={new_customer.salesperson_id}
                onChange={(e) => this.update_account(e)}
            >
                <option value=''>Select LAM</option>
                {salesperson_list.sort((a,b)=>a.salesperson_name.localeCompare(b.salesperson_name)).map((d,index) =>
                    <option key={index} value={d.salesperson_id}>{`${d.salesperson_name} (${d.revenue_code})`}</option>
                )}
            </Form.Select>
        </InputGroup>;

        const manager_input = <InputGroup className="overview-tab-input-group" id='sales-manager-input-add-prospect'>
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold w-25">GM:</InputGroup.Text>
            <Form.Select
                className="text-sm bg-white text-start w-75"
                name='salesperson_id'
                isInvalid={!new_customer.salesperson_id}
                value={new_customer.sales_manager_id.toUpperCase()}
                disabled={ true}
            >
                <option value=''></option>
                {salesperson_list.sort((a, b) => a.salesperson_name.localeCompare(b.salesperson_name)).map((d, index) =>
                    <option key={index} value={d.salesperson_id.toUpperCase()}>{`${d.salesperson_name}`}</option>
                )}
            </Form.Select>
        </InputGroup>;

        const phone_input =  <InputGroup className="overview-tab-input-group">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold w-25">Main Phone:</InputGroup.Text>
            <Form.Control
                className="text-sm bg-white text-start w-75"
                pattern="[0-9]*"
                maxLength={20}
                name='main_phone'
                value={new_customer.main_phone}
                onChange={(e) => this.props.update_account(e)}
            />
        </InputGroup>

        const text_input = (label,property,max) => <InputGroup className="overview-tab-input-group">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold w-25">{label}:</InputGroup.Text>
            <Form.Control
                className="text-sm bg-white text-start w-75"
                maxLength={ max}
                name={property}
                value={new_customer[property]}
                onChange={(e) => this.props.update_account(e)}
            />
        </InputGroup>

        const required_text_input = (label, property, max) => <InputGroup className="overview-tab-input-group">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold w-25">{label}:</InputGroup.Text>
            <Form.Control
                className="text-sm bg-white text-start w-75"
                maxLength={max}
                name={property}
                isInvalid={!new_customer[property]}
                value={new_customer[property]}
                onChange={(e) => this.props.update_account(e)}
            />
        </InputGroup>

        const city_state_input = <InputGroup className="overview-tab-input-group">
            <InputGroup.Text className="text-nowrap text-sm p-1 pe-3 text-bold flex-grow-2">City:</InputGroup.Text>
            <Form.Control
                className="text-sm bg-white text-start flex-grow-1"
                maxLength={50}
                name="city"
                isInvalid={!new_customer.city}
                value={new_customer.city}
                onChange={(e) => this.props.update_account(e)}
            />
            <InputGroup.Text className="text-nowrap text-sm p-1 pe-3 text-bold flex-grow-2">State:</InputGroup.Text>
            <Form.Control
                className="text-sm bg-white text-start flex-grow-3"
                maxLength={2}
                name="state_id"
                isInvalid={!new_customer.state_id}
                value={new_customer.state_id}
                onChange={(e) => this.props.update_account(e)}
            />
        </InputGroup>

        return new_customer ? (
            <Container className="d-flex flex-column w-100" fluid="xs">
                {this.props.role === 'credit' || this.props.role === 'admin' ? get_row(company_id_input) : null}
                {this.props.role === 'credit' || this.props.role === 'admin' ? get_row(salesperson_input, manager_input) : null}
                {get_row(required_text_input('Name', 'name', 40), phone_input)}
               
                {get_row(required_text_input('Address', 'address1', 40), text_input('Suite/Unit', 'address2', 40))}
              
                {get_row(city_state_input, required_text_input('Zip', 'zip_code', 50))}
                {get_row(commodity_input, text_input('Website', 'website_url', 60))}

        </Container>) :
        null;
    }
}
