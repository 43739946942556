import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row, ButtonGroup } from 'react-bootstrap';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';
import * as svg_icons from '../../Media/svg_exports';

export class SalespersonSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search_name: '',
            sales_manager_id:'',
            role: '',
            is_active: true,
            is_inactive: false,
            sort_direction: 'H',
            sort_field: 'Name'
        };
    }

    get_headers() {
        const header_labels = [
            { label: 'Selected', property: 'company_id' },
            { label: 'Company', property: 'company_id' },
            { label: 'Name', property: 'salesperson_name' },
            { label: 'Role', property: 'role' },
            { label: 'Manager', property: 'sales_manager_id' },
            { label: 'STC', property: 'sales_team_captain_name' }
        ]
        let headers = [];
        header_labels.map((d) =>
            headers.push({ column_title: d.label, property: d.property, sort_direction: this.state.sort_direction, is_sortable: 'Y', header_style: '' })
        )

        return headers;
    }

    get_selected_checkbox(salesperson_filter, d) {
        if (salesperson_filter.find(s => s.salesperson_id === d.salesperson_id)) {
            return (<label className="hover-over text-success" onClick={() => this.update_salesperson_filter(d)}>{svg_icons.checkmark_icon}</label>)
        } else {
            return (<label className="hover-over opacity-75 border" onClick={() => this.update_salesperson_filter(d)}>{svg_icons.simple_checkmark_icon}</label>)
        }
    }

    get_data(list) {
        if (list) {
            const salesperson_filter = this.props.search_filter ? this.props.search_filter.Salesperson_ids : [];
            const sortingStrategies = {
                Selected: (a, b) => salesperson_filter.filter(s => s.salesperson_id === a.salesperson_id).length - salesperson_filter.filter(s => s.salesperson_id === b.salesperson_id).length,
                Company: (a, b) => a.company_id.localeCompare(b.company_id),
                Name: (a, b) => a.salesperson_name.localeCompare(b.salesperson_name),
                Role: (a, b) => a.role.localeCompare(b.role),
                Manager: (a, b) => a.sales_manager_id.localeCompare(b.sales_manager_id),
                STC: (a, b) => a.sales_team_captain_name.localeCompare(b.sales_team_captain_name)
            };
            list = list.sort(sortByStrategy(sortingStrategies, this.state.sort_field, this.state.sort_direction));

           
            let data = [];
            let cell_style = 'py-1 text-nowrap'
            list.filter(s=>s.role!=='LC').map((d) =>
                data.push(
                    {
                        row_style:'',
                        cells: [
                            {
                                cell: this.get_selected_checkbox(salesperson_filter, d), cell_style: cell_style
                            },
                            { cell:d.company_id==='TMS2'?'SLUSA':'SLC', cell_style: cell_style },
                            { cell: d.salesperson_name, cell_style: cell_style },
                            { cell: d.role, cell_style: cell_style },
                            { cell: d.sales_manager_name, cell_style: cell_style },
                            { cell: d.sales_team_captain_name, cell_style: cell_style }
                          
                        ],
                        data: d
                    }
                )

            )
            return data;
        }

    }

    update_salesperson_filter(d) {
        let search_filter = this.props.search_filter;
        let salesperson_filter = search_filter ? search_filter.Salesperson_ids : [];
        if (salesperson_filter.find(s => s.salesperson_id === d.salesperson_id)) {
            salesperson_filter = salesperson_filter.filter(s => s.salesperson_id !== d.salesperson_id)
        } else {
            salesperson_filter.push({salesperson_id:d.salesperson_id})
        }
        search_filter.Salesperson_ids = salesperson_filter;
        this.props.update_search_filter(search_filter)
    }

    update_company_filter(e) {

        let search_filter = this.props.search_filter;
        let new_filter = [];
        if (e.target.value === 'Both') {
            new_filter.push('TMS')
            new_filter.push('TMS2')
        } else {
            new_filter.push(e.target.value)
        }
 
        search_filter.Company_id = new_filter;
        
        this.props.update_search_filter(search_filter)
    }

    select_all(list) {
        if (list) {
            let search_filter = this.props.search_filter;
            let salesperson_filter = search_filter ? search_filter.Salesperson_ids : [];
            for (let i = 0; i < list.length; i++) {
                if (!salesperson_filter.find(s => s.salesperson_id === list[i].salesperson_id)) {
                    salesperson_filter.push({ salesperson_id: list[i].salesperson_id })
                }
            }
            this.props.update_search_filter(search_filter)
        }
       
    }
    deselect_all() {
        let search_filter = this.props.search_filter;
        let salesperson_filter = search_filter ? search_filter.Salesperson_ids : [];
        search_filter.Salesperson_ids = [];
        this.props.update_search_filter(search_filter)
    }


    render() {
        const { search_name, sales_manager_id, is_active, is_inactive, sort_direction, sort_field } = this.state;
        let salesperson_list = this.props.salesperson_list ? this.props.salesperson_list : [];
        let gm_list = this.props.salesperson_list ? this.props.salesperson_list.filter(s => s.role === 'GM') : [];
        const search_filter = this.props.search_filter;
        const company_id_filter = search_filter ? search_filter.Company_id : [];
        let displayed_company = 'Both';
        if (company_id_filter.length === 1) {
            displayed_company = company_id_filter[0]
            salesperson_list = salesperson_list.filter(s => s.company_id === company_id_filter[0])
            gm_list = gm_list.filter(s => s.company_id === company_id_filter[0])
        }
        if (search_name) {
            salesperson_list = salesperson_list.filter(s => s.salesperson_name.toLowerCase().includes(search_name.toLowerCase()) || s.salesperson_id.toLowerCase().includes(search_name.toLowerCase()))
        }
        if (sales_manager_id) {
            salesperson_list = salesperson_list.filter(s => s.sales_manager_id.includes(sales_manager_id.toLowerCase()))
        }
        if (is_active && !is_inactive) {
            salesperson_list = salesperson_list.filter(s => !s.inactive_date)
        } else if (!is_active && is_inactive) {
            salesperson_list = salesperson_list.filter(s => s.inactive_date)
        }
        

        let display = this.props.width < 800 ? 'column' : 'row';
        return (

            <Modal
                {...this.props}
                size="xl"
                fullscreen="md-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                    <div className="flex-grow-2">
                        <h5 onClick={() => this.props.onHide()} className="opacity-75 hover-over p-2">
                            X
                        </h5>
                    </div>
                    <div className="d-flex flex-column justify-content-center flex-grow-1">
                        <h4 className="w-100 text-center p-0 pt-2">
                            Select LAMs or GMs {search_filter ? `(${search_filter.Salesperson_ids.length})`:'' }
                        </h4>
                    </div>

                    <div className="flex-grow-2"></div>
                </Modal.Header>
                <Modal.Body style={{ height: '70vh', overflow: 'auto' }}>
                    <div className="d-flex flex-column">
                        <div className={ `d-flex flex-${display} mb-3`}>
                            <InputGroup className={ `me-3 ${this.props.width<800?'mb-2':''}`}>
                                <InputGroup.Text className="text-sm">Company:</InputGroup.Text>
                                <Form.Select className="text-sm" onChange={(e) => this.update_company_filter(e)} value={displayed_company}>
                                    <option value='Both'>SLC/SLUSA</option>
                                    <option value='TMS'>SLC</option>
                                    <option value='TMS2'>SLUSA</option>
                                </Form.Select>
                            </InputGroup>
                            <InputGroup className={`me-3 ${this.props.width < 800 ? 'mb-2' : ''}`}>
                                <InputGroup.Text className="text-sm">Name/ID:</InputGroup.Text>
                                <Form.Control className="text-sm" onChange={(e) => this.setState({ search_name: e.target.value })} value={search_name} />
                            </InputGroup>
                            <InputGroup className={`me-3 ${this.props.width < 800 ? 'mb-2' : ''}`}>
                                <InputGroup.Text className="text-sm">GM:</InputGroup.Text>
                                <Form.Select className="text-sm" onChange={(e) => this.setState({ sales_manager_id: e.target.value })} value={sales_manager_id}>
                                    <option value=''>Select GM</option>
                                    {gm_list.map(d =>
                                        <option value={d.user_id} key={d.user_id}>{d.salesperson_name}</option>
                                    )}
                                </Form.Select>
                            </InputGroup>
                            <div className={`d-flex flex-row ${this.props.width < 800 ? 'mb-2' : ''}`}>
                                <div className="d-flex flex-row me-3">
                                    <input type="checkbox" checked={is_active} onClick={(e) => this.setState({ is_active: e.target.checked })} />
                                    <label className="align-self-center text-sm ps-2">Active</label>
                                </div>
                                <div className="d-flex flex-row">
                                    <input type="checkbox" checked={is_inactive} onClick={(e) => this.setState({ is_inactive: e.target.checked })} />
                                    <label className="align-self-center text-sm ps-2">Inactive</label>
                                </div>
                            </div>
                            <div className="px-2 d-flex flex-column justify-content-center">
                                <Button size="sm" variant="danger" className="text-sm py-0 px-1" onClick={() => this.deselect_all()}>Clear</Button>
                            </div>
                           
                        </div>
                        {search_filter ?
                            search_filter.Salesperson_ids.length === salesperson_list.length ?
                                <Button
                                    onClick={() => this.deselect_all()}
                                    className="text-sm w-25 mb-3 align-self-center opacity-75"
                                    variant="secondary"
                                    size="sm"
                                >
                                 Deselect All
                                </Button> :
                                <Button
                                    onClick={() => this.select_all(salesperson_list)}
                                    className="text-sm w-25 mb-3 align-self-center opacity-75"
                                    variant="secondary"
                                    size="sm"
                                >
                                    Select All
                                </Button>
                                :null
                        }
                      
                            <SortableTable
                                onSort={(prop) =>
                                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                                }
                                table_headers={this.get_headers()}
                                table_data={this.get_data(salesperson_list)}
                                row_click={(prop) =>null}
                                table_title=""
                                sort_field={sort_field}
                            />
                   
                    </div>
           

                </Modal.Body>
            </Modal>)
    }


    
}
