/**
 * Returns a sorting strategy based on the key and direction.
 *
 * @param {object[]} strategies - A mapping of key names to sorting strategies.
 * @param {string} key - The key to sort by.
 * @param {string} direction - The direction to sort by ('H' for ascending, 'L' for descending).
 * @returns {function} The sorting strategy.
 */
const sortByStrategy = (strategies, key, direction) => {
    const sortInDirection = (strategy, direction) => {
        return direction === 'H' ? strategy : (a, b) => strategy(b, a);
    };

    // Select a strategy if it's defined; otherwise, return a no-op sorting
    // strategy.
    const strategy =
        strategies[key] ||
        ((_a, _b) => {
            return 0;
        });

    return sortInDirection(strategy, direction);
};

export default sortByStrategy;
