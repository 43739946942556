export const comment_type_ids = [
    { type_id: 'NOTES' },
    { type_id: ' CUSSETUP' },
    { type_id: 'CUSTCON' },
    { type_id: 'CUSCREDI' },
    { type_id: 'RATES' },
];

export const SLC_managers = [
    {
        emailAddress: {
            address: 'sproctor@scotlynn.com',
        },
    },
    {
        emailAddress: {
            address: 'dtulett@scotlynn.com',
        },
    },
    {
        emailAddress: {
            address: 'jzerbes@scotlynn.com',
        },
    },
];

export const SLUSA_gm_ids = [
    'ASTACHUR',
    'BCARTER',
    'BROPER',
    'CNEPTUNE',
    'LMULLINA',
    'PFIBBE',
    'RSOWELL',
    'SKONKLE',
    'SPROCTOR',
    'DTULETT',
    'JZERBES',
];

export const commodities = [
    { value: 'ALCO', label: 'Alcohol' },
    { value: 'AUTO', label: 'Automotive' },
    { value: 'BUMT', label: 'Building Material' },
    { value: 'FLOR', label: 'Floral' },
    { value: 'FRBR', label: 'Freight Broker' },
    { value: 'FROZ', label: 'Frozen' },
    { value: 'GROC', label: 'Grocery' },
    { value: 'MEAT', label: 'Meat' },
    { value: 'PACK', label: 'Packaging' },
    { value: 'PROD', label: 'Produce' },
];
