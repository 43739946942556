// Always return either "TMS" or "TMS2".  Simplifies the dependant code.
const normalizedCompanyId = (companyId) => (companyId === 'SLUSA' || companyId === 'TMS2' ? 'TMS2' : 'TMS');

const defaultHeadersFor = (companyId) =>
    new Headers({
        Authorization: process.env.REACT_APP_MCLEOD_AUTH,
        'x-com.mcleodsoftware.CompanyID': normalizedCompanyId(companyId),
    });

const getBaseURL = (companyId) =>
    normalizedCompanyId(companyId) === 'TMS2' ? process.env.REACT_APP_SLUSA_BASE_URL : process.env.REACT_APP_SLC_BASE_URL;

export const getHeader = (companyId) => {
    const headers = defaultHeadersFor(companyId);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return headers;
};

export const fileHeader = (company_id) => {
    const headers = new Headers();
    headers.append('Authorization', process.env.REACT_APP_MCLEOD_AUTH);
    headers.append('x-com.mcleodsoftware.CompanyID', company_id);
    return headers;
};


//#region Comment Endpoints
export const getAttachment = (company_id, comment_id) =>
    getBaseURL(company_id) + 'comments/' + comment_id + '/attachment';

export const postComment = (company_id) => getBaseURL(company_id) + 'comment/create';
export const attachFileToComment = (company_id, comment_id, fileName) =>
    getBaseURL(company_id) + 'comments/' + comment_id + '/attachFile?fileName=' + fileName;
//#endregion Comment Endpoints



