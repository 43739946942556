import React, { Component } from 'react';
import { Alert, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { isBefore, addDays, differenceInDays, addMonths, format } from 'date-fns';
import * as svg_icons from '../../Media/svg_exports';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as fetchFunctions from '../Functions/FetchFunctions';
import { numericFormatter } from 'react-number-format';
export class ResultsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort_field: 'Company',
            sort_direction: 'H'
        };
    }

    account_cell(d) {



        if (d.activity_remarks && d.action_date_str) {
            let tooltip = <div className="w-100">
                <p style={{ whiteSpace: 'pre-wrap' }} className="p-0 m-0 w-100 text-xs">
                    {
                        `${d.activity_remarks.replaceAll('&#xA;', '\n\n')}`
                    }
                </p>
            </div>
            return (<OverlayTrigger 
                placement="right"
                overlay={<Popover id='remarks-search-tooltip'>
                    {d.action_date_str ?
                        <Popover.Header as="h6" className="text-sm">{d.action_date_str}</Popover.Header> :
                        null
                    }                    
                    <Popover.Body as="div" >
                        {tooltip}
                    </Popover.Body>
                 
                </Popover>}
            >
                <div className="d-flex flex-column">
                    <label className="capitalize fw-bold text-xs">{d.name}</label>
                    <i>{`${d.id} (${d.company_id === 'TMS2' ? 'SLUSA' : 'SLC'})`}</i>
                </div>
            </OverlayTrigger>)

        } else {
            return (<div className="d-flex flex-column">
                <label className="capitalize fw-bold text-xs">{d.name}</label>
                <i>{`${d.id} (${d.company_id === 'TMS2' ? 'SLUSA' : 'SLC'})`}</i>
            </div>)
        }
      
    }

    lam_cell(d) {
        if (d.salesperson_id === 'UNCLAIMD') {
            return (<div className="d-flex flex-column opacity-75">
                <label className="capitalize fw-bold text-xs">Unclaimed</label>
           
            </div>)
        } else {
            return (<div className="d-flex flex-column">
                <label className="capitalize fw-bold text-xs">{d.salesperson_name}</label>
                {d.salesperson_is_active === 'Y' ?
                    <div className="d-flex flex-row justify-content-start pt-1">
                        { d.salesperson_phone ?
                            <div
                                className="rounded hover-over me-3 text-phone"
                                onClick={() => window.open(`tel:+${fetchFunctions.formatPhoneNumber(d.salesperson_phone)}`, '_blank')}
                                title={`Call: ${d.salesperson_phone}`}>{svg_icons.phone_icon}</div> :
                            null
                        }
                        {d.salesperson_email_address ?
                            <div
                                className="rounded hover-over me-3 text-email"
                                onClick={() => window.open(`mailto:${d.salesperson_email_address}?subject=Inquiring about ${d.id}`, '_blank')}
                                title={`Email: ${d.salesperson_email_address}`}>{svg_icons.email_icon}</div> :
                            null
                        }
                        {d.salesperson_email_address ?
                            <div
                                className="rounded hover-over me-3 text-purple"
                                onClick={() => window.open(`MSTeams:/l/chat/0/0?users=${d.salesperson_email_address}`, '_blank')}
                                title={`Teams: ${d.salesperson_name}`}>{svg_icons.teams_icon}</div> :
                            null
                        }

                    </div> :
                    <i>LAM is inactive</i>
            }
                
            </div>)
        }
   
    }

    paid_billed_cell(d) {
        if (d.billed_loads) {
            return (<div className="d-flex flex-column justify-content-center fw-bold" >
                <small className={`align-self-center ${d.paid_loads === d.billed_loads ? '' : 'text-danger'}`}>{`${d.paid_loads} paid`}</small>
                <div className="bg-dark" style={{ height: '0.05rem' }}></div>
                <small className="align-self-center">{`${d.billed_loads} billed`}</small>
            </div>)
        } else {
            return null;
        }
     
    }

    get_results_list() {
        let accounts = this.props.accounts ? this.props.accounts:[];

        const sort_field = this.state.sort_field;
        const sort_direction = this.state.sort_direction;

        if (accounts.length>0) {
            const sortingStrategies = {
                Company: (a, b) => a.name.localeCompare(b.name),
                Type: (a, b) => a.type_of.localeCompare(b.type_of),
                Commodity: (a, b) => a.commodity.localeCompare(b.commodity),
                LAM: (a, b) => a.salesperson_id.localeCompare(b.salesperson_id),
                Expiry: (a, b) => new Date(a.expiry_date) - new Date(b.expiry_date),
                'Credit Limit': (a, b) => a.credit_limit - b.credit_limit,
                City: (a, b) => a.city.localeCompare(b.city),
                'State/Prov': (a, b) => a.state_id.localeCompare(b.state_id),
                'Loads': (a, b) => a.billed_loads-b.billed_loads,
                'Last Shipped': (a, b) => a.last_ship_days - b.last_ship_days
            };
            accounts = accounts.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
            

            let header_labels = [
                { label: 'Company', property: 'id' },
                { label: 'Type', property: 'type_of' },
                { label: 'Commodity', property: 'commodity' },
                { label: 'LAM', property: 'salesperson_id' },
                { label: 'Expiry', property: 'expiry_date' },
                { label: 'Credit Limit', property: 'credit_limit' },
                { label: 'City', property: 'city' },
                { label: 'State/Prov', property: 'state_id' },
                { label: 'Loads', property: 'paid_loads' },
                { label: 'Last Shipped', property: 'last_ship_days' },
                 { label: '', property: '' }
            ]         
            let headers = [];
            header_labels.map((d) =>
                headers.push({ column_title: d.label, property: d.property, sort_direction: sort_direction, is_sortable: 'Y', header_style:'pe-3' })
            )
            let data = [];
            let cell_style = 'py-1 text-nowrap pe-3'
            accounts.map((d,index) =>
                data.push(
                    {
                        row_style:d.crm_do_not_use==='Y' || d.is_active==='N' || d.salesperson_id==='DONOTUSE'?'opacity-75 bg-danger':'',
                        cells: [
                            { cell: this.account_cell(d), cell_style: cell_style },
                            { cell: d.type_of, cell_style: cell_style },
                            { cell: d.commodity, cell_style: cell_style },
                            { cell: this.lam_cell(d), cell_style: cell_style },
                            { cell: <bdi title={d.start_date}>{d.expiry_date}</bdi>, cell_style: cell_style },
                            { cell: numericFormatter(d.credit_limit.toString(), utilityFunctions.currency_format_props) , cell_style: cell_style },
                            { cell: d.city, cell_style: cell_style },
                            { cell: d.state_id, cell_style: cell_style },
                            { cell: this.paid_billed_cell(d), cell_style: cell_style },
                            { cell: d.last_ship_days, cell_style: cell_style },
                            {
                                cell:
                                    <div className="d-flex justify-content-center h-100">
                                        <Button variant="secondary" className="text-xs align-self-center" size="sm" onClick={() => this.props.view_account(d.company_id, d.id)}>View</Button>
                                </div>
                                    ,
                                cell_style: `${cell_style} pt-2`
                            },
                        ],
                        data: d
                    }
                )

            )

            const activity_table = <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                table_headers={headers}
                table_data={data}
                row_click={(prop) => null}
                table_title=""
                sort_field={sort_field}
            />;
            return (
                activity_table
            )
        } else { return null }
    }




    render() {
        const { open_tile } = this.state;
 


        return this.props.accounts ? (
            <div className="mt-2 w-100 d-flex flex-column">
                <h6 className="tile-title">{ this.props.title}</h6>
                {this.get_results_list()}
            </div>

        ) : null;
    }
}