import { Spinner, Image, Overlay, Button } from 'react-bootstrap';
import React, { createRef, Component } from 'react';
import {
    isSameISOWeek,
    isToday,
} from 'date-fns';



export class ProfilePic extends Component {
    static displayName = ProfilePic.name;
    constructor(props) {
        super(props);
        this.state = {
            show_options: false
           
        }
        this.target = createRef();
    }

    handleLogout(instance) {
        instance.logoutRedirect().catch((e) => {
            localStorage.clear();
            sessionStorage.clear();
        });
    }

    render() {
        const { show_options } = this.state;
        const completed_today = this.props.prospect_actions.loaded==='Y' ? this.props.prospect_actions.list.filter(s => s.status === 'C' && isToday(new Date(s.action_date_str))) : [];
        const completed_week = this.props.prospect_actions.loaded === 'Y' ? this.props.prospect_actions.list.filter(s => s.status === 'C' && isSameISOWeek(new Date(),new Date(s.action_date_str))) : [];

        const activities_view = this.props.role === 'lam' ?
            <div className='d-flex flex-column text-xs text-white p-1 pb-5' id="lam-activities">
                <label className="text-xs text-bold">Activities Completed:</label>
                <hr className="m-0 p-0 mb-1" />
                <bdi>Today: {completed_today.length}</bdi>
                <bdi>This Week: {completed_week.length}</bdi>
            </div> :
            null;

        if (this.props.profile_photo) {
            if (this.props.profile_photo.loaded === 'Y') {
                return (<div className="p-2 align-self-center" style={{ width: '3rem' }} id="profile-pic-icon">
                    <Image src={this.props.profile_photo.photo} className="rounded-circle align-self-center hover-over" alt="Profile" fluid ref={this.target} onClick={() => this.setState({ show_options: !show_options })} />
                    <Overlay target={this.target.current} show={show_options} placement="bottom-end">
                        {({
                            placement: _placement,
                            arrowProps: _arrowProps,
                            show: _show_options,
                            popper: _popper,
                            hasDoneInitialMeasure: _hasDoneInitialMeasure,
                            ...props
                        }) => (
                            <div
                                {...props}
                                style={{
                                    position: 'absolute',
                                    backgroundColor: 'black',
                                    padding: '0.5rem 0rem',
                                    width: '8rem',
                                    borderRadius: '0.3rem',
                                    ...props.style,
                                }}
                            >
                              
                                {activities_view}
                                <Button
                                    variant="warning"
                                    id='logout-button'
                                    className="text-xs text-nowrap w-100 p-0 rounded-0"
                                    size="sm"
                                    onClick={() => this.handleLogout(this.props.instance)}
                                >
                                    Log out
                                </Button>
                            </div>
                        )}
                    </Overlay>
                </div>)
            } else {
                return (<Spinner animation="grow" variant="warning" className="align-self-center" />)
            }
        }
       
    }




}


