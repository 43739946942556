import { format, addDays, isBefore, isAfter } from 'date-fns';
import React, { Component } from 'react';
import * as baseColors from '../Functions/BaseColors';
import { commodities } from '../Functions/InitialValues';
import { Userpilot } from 'userpilot';
import { MsalContext } from '@azure/msal-react';
import LoadingIcons from 'react-loading-icons';
import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    FloatingLabel,
    Form,
    InputGroup,
    OverlayTrigger,
    Row,
    Toast,
    Tooltip,
} from 'react-bootstrap';

import * as utilityFunction from '../Functions/UtilityFunctions';
import * as searchFilterFunctions from '../Functions/SearchFilterFunctions';
import * as salespersonFunctions from '../Functions/SalespersonFunctions';
import * as customerFunctions from '../Functions/CustomerFunctions';
import * as svg_icons from '../../Media/svg_exports';
import ComparatorInput from '../Inputs/ComparatorInput';
import NumericComparatorInput from '../Inputs/NumericComparatorInput';

import { SearchBar } from './SearchBar';
import { ResultsTable } from './ResultsTable';
import { SalespersonSelector } from './SalespersonSelector';
import { SearchLoading } from './SearchLoading';
import { LoadingModal } from '../Modals/LoadingModal';
import { RemarksSearchModal } from '../Modals/RemarksSearchModal'
import { ViewAccountModal } from '../Modals/Account/ViewAccountModal';



export class SearchProspects extends Component {
    static displayName = SearchProspects.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            // get_hot_prospects vars
            hot_prospects: { list: [], loaded: false, failed: false },
            list_view: 'Search',
            loading_hot_prospects: false,
            search_filter: {
                Search_Input: '',
                Code_Only:'',
                Company_id: ['TMS','TMS2'],
                Unclaimed: 'Both',
                City: '',
                State_id: '',
                Salesperson_ids: [],
                Salesperson_Type: 'salesperson_id',
                Commodity: '',
                Zone_id: '',
                Start_Date_Comparator: '',
                Low_Start_Date: '',
                High_Start_Date: '',
                Conversion_Date_Comparator: '',
                Low_Conversion_Date: '',
                High_Conversion_Date: '',
                Last_Ship_Date_Comparator: '',
                Low_Last_Ship_Date: '',
                High_Last_Ship_Date: '',
                Entered_Date_Comparator: '',
                Low_Entered_Date: '',
                High_Entered_Date: '',
                High_Balance_Comparator: '',
                Low_High_Balance: 0,
                High_High_Balance: 0,
                Credit_Limit_Comparator: '',
                Low_Credit_Limit: 0,
                High_Credit_Limit: 0,
                Orders_Billed_Comparator: '',
                Low_Orders_Billed: 0,
                High_Orders_Billed: 0,
                Orders_Paid_Comparator: '',
                Low_Orders_Paid: 0,
                High_Orders_Paid: 0,
                Type_Of: ['Customer','Prospect','Dormant'],
                Remark: '',
                Main_Phone: '',
                Address: '',
                Google_Place_id: '',
            },
            displayed_prospects: [],
            showModal: false,

            // Selected Company Vars
          
            account_clicked: false,

            showSLC: false,
            showSLUSA: false,
            wait_for_data: false,
            show_unclaimed: true,
            show_claimed: false,
            sort_field: 'Company',
            sort_direction: 'H',
            hide_filters: true,
            state_ids: [],
            current_user: null,
            search_clicked: false,
            zone_ids: [],
            form_error: '',
            show_advanced: false,
            filter_changed: false,
            master_list: [],
            user_loaded: false,
            sales_manager_list: [],
            salesperson_list: [],
            salesperson_list_loaded: false,
            master_salesperson_list: [],
            master_sales_manager_list: [],
            sales_manager_list_loaded: false,
            salesperson_list_filter: '',
            search_name_code: 'Name',
            set_page: 500,
            list_index: -1,
            do_not_use_clicked: false,
            dnu_reason: '',
            open_dnu: false,
            result_message: '',
            hide_dnu_result: false,
            search_by_remark: '',
            remark_company_id:'',
            show_remarks_search:false,
            selected_account: null,
            selected_account_loaded: true,
            show_selected_account: true,
            show_salespersons_selector: false,
            index: 0,
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleCompanyCheckboxChange = this.handleCompanyCheckboxChange.bind(this);
        this.handleStatusCheckboxChange = this.handleStatusCheckboxChange.bind(this);

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.setTypeOf = this.setTypeOf.bind(this);

        this.cancel_search = this.cancel_search.bind(this);
        this.reset_search_filter = this.reset_search_filter.bind(this);
    }

    reset_search_filter() {
        this.setState({
            search_filter: {
                Search_Input: '',
                Code_Only: '',
                Company_id: ['TMS','TMS2'],
                Unclaimed: 'Both',
                City: '',
                State_id: '',
                Salesperson_ids: [],
                Salesperson_Type: 'salesperson_id',
                Commodity: '',
                Zone_id: '',
                Start_Date_Comparator: '',
                Low_Start_Date: '',
                High_Start_Date: '',
                Conversion_Date_Comparator: '',
                Low_Conversion_Date: '',
                High_Conversion_Date: '',
                Last_Ship_Date_Comparator: '',
                Low_Last_Ship_Date: '',
                High_Last_Ship_Date: '',
                Entered_Date_Comparator: '',
                Low_Entered_Date: '',
                High_Entered_Date: '',
                High_Balance_Comparator: '',
                Low_High_Balance: 0,
                High_High_Balance: 0,
                Credit_Limit_Comparator: '',
                Low_Credit_Limit: 0,
                High_Credit_Limit: 0,
                Orders_Billed_Comparator: '',
                Low_Orders_Billed: 0,
                High_Orders_Billed: 0,
                Orders_Paid_Comparator: '',
                Low_Orders_Paid: 0,
                High_Orders_Paid: 0,
                Type_Of: ['Customer', 'Prospect', 'Dormant'],
                Remark: '',
                Main_Phone: '',
                Address: '',
                Google_Place_id: '',
            },
            index:0
        });
    }

    handleKeyPress(event) {
        if (!this.state.search_clicked && !this.state.show_remarks_search) {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.searchPage(this.state.search_filter);
            }
        }
    }

    handleCompanyCheckboxChange(val) {
        let search_filter = this.state.search_filter;
        let company_filter = search_filter ? search_filter.Company_id : [];
      
        if (company_filter.find(s => s === val)) {
            company_filter = company_filter.filter(s => s !== val)
        } else {
            company_filter.push(val)
        }
        if (company_filter.length === 0) {
            search_filter.Company_id = ['TMS', 'TMS2']
        } else {
            search_filter.Company_id = company_filter;
        }
        this.setState({search_filter: search_filter, index:0});
    }

    handleStatusCheckboxChange(e) {
        const current_filter = this.state.search_filter;
        if (e.target.checked) {
            if (e.target.name === 'CLAIMD') {
                if (current_filter.Unclaimed === 'UNCLAIMD') {
                    current_filter.Unclaimed = 'Both';
                } else {
                    current_filter.Unclaimed = 'CLAIMD';
                }
            } else if (e.target.name === 'UNCLAIMD') {
                if (current_filter.Unclaimed === 'CLAIMD') {
                    current_filter.Unclaimed = 'Both';
                } else {
                    current_filter.Unclaimed = 'UNCLAIMD';
                }
            }
        } else {
            if (e.target.name === 'CLAIMD') {
                if (current_filter.Unclaimed === 'Both') {
                    current_filter.Unclaimed = 'UNCLAIMD';
                } else {
                    current_filter.Unclaimed = '';
                }
            } else if (e.target.name === 'UNCLAIMD') {
                if (current_filter.Unclaimed === 'Both') {
                    current_filter.Unclaimed = 'CLAIMD';
                } else {
                    current_filter.Unclaimed = '';
                }
            }
        }

        this.setState({ search_filter: current_filter, index:0 });
    }

    handleFilterChange(e) {
  
        const current_filter = this.state.search_filter;
        if (e.target.name === 'Salesperson_ids') {
            current_filter.Unclaimed = 'CLAIMD';
        } else if (e.target.name === 'Salesperson_Type') {
            this.setState({ salesperson_list_filter: '' });
            current_filter.Salesperson_id = [];
        }
        current_filter[e.target.name] = e.target.value;
        this.setState({ search_filter: current_filter, index:0 });
    }

    setTypeOf(type) {
        try {
            const current_filter = this.state.search_filter;
            const current_type_filter = current_filter.Type_Of;
            if (current_type_filter.includes(type)) {
                current_filter.Type_Of = current_type_filter.filter((s) => s !== type);
            } else {
                current_type_filter.push(type);
                current_filter.Type_Of = current_type_filter;
            }
            this.setState({ search_filter: current_filter, index:0 });
        } catch (e) {
            console.log(e);
        }
    }

    handleSearchInput(e) {
        if (!this.state.search_clicked) {
            if (this.state.displayed_prospects.length > 0) {
                this.setState({ filter_changed: true });
            }
            const current_filter = this.state.search_filter;
            if (e.target.value.includes('&')) {
                const index_of_amper = e.target.value.indexOf('&');
                if (index_of_amper + 1 <= e.target.value.length - 1 && index_of_amper > 0) {
                    if (e.target.value[index_of_amper - 1] === ' ' && e.target.value[index_of_amper + 1] === ' ') {
                        current_filter.Search_Input = e.target.value;
                    } else if (
                        e.target.value[index_of_amper - 1] === ' ' &&
                        e.target.value[index_of_amper + 1] !== ' '
                    ) {
                        const first_part = e.target.value.substring(0, Number.parseInt(index_of_amper));
                        const second_part = e.target.value.substring(Number.parseInt(index_of_amper) + 1);
                        current_filter.Search_Input = `${first_part}& ${second_part}`;
                    } else {
                        const first_part = e.target.value.substring(0, Number.parseInt(index_of_amper));
                        const second_part = e.target.value.substring(Number.parseInt(index_of_amper) + 1);
                        current_filter.Search_Input = `${first_part} & ${second_part}`;
                    }
                } else {
                    current_filter.Search_Input = e.target.value;
                }
            } else {
                current_filter.Search_Input = e.target.value;
            }

            this.setState({ search_filter: current_filter, index:0 });
        }
    }

    compare_search_input(search_input, list) {
        let current_search_input = search_input.toLowerCase();
        let return_list = [];
        if (this.state.search_name_code === 'Code') {

            if (current_search_input[0] === '*') {
                return_list = list.filter(s => s.id.toLowerCase().includes(current_search_input.toLowerCase().substring(1)));
            } else {
                return_list = list.filter(s => s.id.toLowerCase().includes(current_search_input.toLowerCase()));
            }

        } else {
            return_list = list.filter(s =>
                utilityFunction.remove_special_characters(s.name).includes(current_search_input.toLowerCase()) ||
                utilityFunction.remove_special_characters(s.name.replaceAll(' ', '')).includes(current_search_input.toLowerCase()) ||
                s.id.toLowerCase().includes(current_search_input.toLowerCase()));
        }
        let search_input_split = search_input.split(' ');
       
        //search_input_split.map(word => {
           
                 
        //})
        return return_list;
    }


    async componentDidMount() {
            const currentUser = this.props.salesperson;
            const salespersons = await salespersonFunctions.Get_All()
            const states = await utilityFunction.Get_States();
            const zones = await utilityFunction.Get_Zones()
            this.setState({
                current_user: currentUser,
                user_loaded: true,
                state_ids: states,
                zone_ids: zones,
                salesperson_list: salespersons
            });    
    }

    update_search_filter_from_remarks(company_id) {
        let current_filter = this.state.search_filter;
        let company_id_list = [];
        company_id_list.push(company_id)
        current_filter.Company_id = company_id_list;
        current_filter.Type_Of = ['Customer', 'Prospect', 'Dormant'];
        current_filter.Unclaimed = 'Both';
        current_filter.Search_Input = '';
        this.setState({
            remark_company_id: company_id,
            search_filter: current_filter,
            index: 0
        })
    }

    async search_by_activity_remark() {
        const company_id = this.state.remark_company_id;
        const search_remark = this.state.search_by_remark
        if (company_id && search_remark.length > 2) {
            Userpilot.track("remarks_search", { value: search_remark });

            this.setState({
                search_clicked: true,
                wait_for_data: true,
                filter_changed: false,
                list_view: 'Search',
                result_message: ''
            });
            let current_filter = this.state.search_filter;
            let company_id_list = [];
            company_id_list.push(company_id)
            current_filter.Company_id = company_id_list;
            current_filter.Type_Of = ['Customer', 'Prospect', 'Dormant'];
            current_filter.Unclaimed= 'Both'             
            const data = await customerFunctions.SearchByRemark(company_id, search_remark);
            this.setState({
                search_clicked: false,
                wait_for_data: false,
                displayed_prospects: data,
                master_list: data,
                result_message: '',
                show_remarks_search:false
            })
        }
    
    }

    async searchPage() {
      
        this.setState({
            search_clicked: true,
            wait_for_data: true,
            filter_changed: false,
            list_view: 'Search',
            result_message: '',
            index: 0,
            search_by_remark: '',
            remark_company_id: '',
            show_remarks_search:false
        });
        try {
            let current_filter = this.state.search_filter;
            if (current_filter.Type_Of.length === 1 && current_filter.Type_Of.find((s) => 'D')) {
                current_filter.Dormant = 'DOR';
            } else {
                current_filter.Dormant = '';
            }
            if (current_filter.Search_Input) {
                current_filter.Search_Input = current_filter.Search_Input.replaceAll(`'s`, ``);
            }
          


            if (this.state.search_name_code === 'Code') {
                current_filter.Code_Only='Y'
            }


            const data = await searchFilterFunctions.Search(current_filter)
  

            this.setState({
                search_clicked: false,
                wait_for_data: false,
                displayed_prospects: data,
                master_list: data,
                result_message: '',
                search_filter: current_filter
            });
        } catch (e) {
            console.log(e);
            this.setState({
                search_clicked: true,
                wait_for_data: true,
                filter_changed: false,
                list_view: 'Search',
                result_message: '',
                search_by_remark: '',
                index: 0,
                search_by_remark: '',
                remark_company_id: '',
                show_remarks_search: false
            });
        }
      
    }

    cancel_search() {
        try {
            const current_filter = this.state.search_filter;
            const abortController = new AbortController();
            abortController.abort();
            this.setState({
                search_clicked: false,
                wait_for_data: false,
                displayed_prospects: [],
                master_list: [],
            });
        } catch (e) {
            console.log(e);
        }
    }

    async get_hot_prospects() {
        try {
            this.reset_search_filter();
            this.setState({ loading_hot_prospects: true, wait_for_data: true, set_page: 500 });
            const data = await customerFunctions.Get_Hot_Prospects(this.props.salesperson.company_id)
            this.setState({
                list_view: 'Hot',
                hot_prospects: { list: data, loaded: true, failed: false },
                wait_for_data: false,
                loading_hot_prospects: false,
                displayed_prospects: data,
                master_list: data,
            });
        } catch (e) {
            console.log(e);
            this.setState({
                list_view: 'Hot',
                hot_prospects: { list: [], loaded: true, failed: true },
                wait_for_data: false,
                loading_hot_prospects: false,
                displayed_prospects: [],
                master_list: [],
            });
        }
    }

    render() {
        const {
            wait_for_data,  
            displayed_prospects,
            state_ids,
            search_clicked,
            zone_ids,
            search_filter,     
            show_advanced,        
            master_list,
            user_loaded,    
            salesperson_list,
            search_name_code,
            set_page,
            list_view,
            loading_hot_prospects,
            result_message,       
            account_clicked,
            hide_dnu_result,
            search_by_remark,
            remark_company_id,
            show_remarks_search,
            selected_account,
            show_selected_account,
            show_salespersons_selector,
            index
        } = this.state;

        const requests = this.props.requests ? this.props.requests.list : [];

        //#region Search Bar
        const search_bar = (
            <SearchBar
                search_page={() => this.searchPage()}
                cancel_search={() => this.cancel_search()}
                search_type_change={(prop) => this.setState({ search_name_code: prop })}
                search_name_code={search_name_code}
                update_search_filter={(prop) => this.setState({ search_filter: prop })}
                search_filter={search_filter}
                displayed_prospects={displayed_prospects}
                filter_changed={() => this.setState({ filter_changed: true })}
                search_clicked={search_clicked}
                handle_key_press={(prop) => this.handleKeyPress(prop)}
            />
        );
        //#endregion Search Bar

        //#region Company Filter
        const company_filter = (
            <Container className={'p-1 '} fluid="xs">
                <Row>
                    <Col>
                        <div className="d-flex flex-row">
                            <label className={`${search_filter.Company_id.includes('TMS2') ? 'text-success' : 'opacity-50 text-secondary'} hover-over`}
                                onClick={() => this.handleCompanyCheckboxChange('TMS2')}
                            >{svg_icons.checkmark_icon}</label>
                            <bdi className="ps-2 text-sm">SLUSA</bdi>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="d-flex flex-row">
                            <label className={`${search_filter.Company_id.includes('TMS') ? 'text-success' : 'opacity-50 text-secondary'} hover-over`}
                                onClick={() => this.handleCompanyCheckboxChange('TMS')}
                            >{svg_icons.checkmark_icon}</label>
                            <bdi className="ps-2 text-sm">SLC</bdi>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
        //#endregion Company Filter

        //#region Status Filter
        const status_filter = (
            <Container fluid="xs" className={search_filter.Unclaimed === '' ? 'p-1 unchecked-fields' : 'p-1 '}>
                <Row>
                    <Col>
                        <div className="d-flex flex-row">
                            <input
                                id="claimed-checkbox"
                                type="checkbox"
                                onClick={(e) => this.handleStatusCheckboxChange(e)}
                                name="CLAIMD"
                                checked={search_filter.Unclaimed === 'CLAIMD' || search_filter.Unclaimed === 'Both'}
                            />
                            <bdi className="ps-2 text-sm">Claimed</bdi>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex flex-row">
                            <input
                                id="unclaimed-checkbox"
                                type="checkbox"
                                onClick={(e) => this.handleStatusCheckboxChange(e)}
                                name="UNCLAIMD"
                                checked={search_filter.Unclaimed === 'UNCLAIMD' || search_filter.Unclaimed === 'Both'}
                            />
                            <bdi className="ps-2 text-sm">Unclaimed</bdi>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
        //#endregion Company Filter

        //#region DNU Filter
        const dnu_filter =
            this.props.role === 'credit' || this.props.role === 'admin' ? (
                <Container fluid="xs" className={search_filter.Unclaimed === '' ? 'p-1 unchecked-fields' : 'p-1 '}>
                    <Row>
                        <Col>
                            <div className="d-flex flex-row">
                                <input
                                    id="hide-dnu-checkbox"
                                    type="checkbox"
                                    onClick={(e) => this.setState({ hide_dnu_result: !hide_dnu_result })}
                                    checked={hide_dnu_result}
                                />
                                <bdi className="ps-2 text-sm">Hide DNU's</bdi>
                            </div>
                        </Col>
                    </Row>
                </Container>
            ) : null;
        //#endregion DNU Filter

        //#region Type Filter
        const type_dropdown = (
            <Dropdown
                autoClose="outside"
                id="salesperson-id-dropdown-list"
                size="xl"
                className="d-flex flex-column w-100 h-100 border text-sm"
            >
                <Dropdown.Toggle
                    variant="light"
                    className="btn-op70 border text-sm"
                    style={search_filter.Type_Of.length === 0 ? { filter: 'opacity(0.5)' } : {}}
                >
                    {search_filter.Type_Of.length === 0
                        ? 'Select Type'
                        : 'Types Selected: ' + search_filter.Type_Of.map((d) => ' ' + d + ' ')}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-2 w-100" style={{ zIndex: '1' }}>
                    <Dropdown.Item
                        style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                        onClick={() => this.setTypeOf('Prospect')}
                        className="hover-row text-sm"
                        style={
                            search_filter.Type_Of.filter((s) => s === 'Prospect').length > 0
                                ? { backgroundColor: baseColors.light_green }
                                : {}
                        }
                    >
                        <Container fluid="xs">
                            <Row>
                                <Col xs={8}>
                                    <small className="align-self-center text-sm">Prospect</small>
                                </Col>
                            </Row>
                        </Container>
                    </Dropdown.Item>
                    <Dropdown.Item
                        style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                        onClick={() => this.setTypeOf('Customer')}
                        className="hover-row text-sm"
                        style={
                            search_filter.Type_Of.filter((s) => s === 'Customer').length > 0
                                ? { backgroundColor: baseColors.light_green }
                                : {}
                        }
                    >
                        <Container fluid="xs">
                            <Row>
                                <Col xs={8}>
                                    <small className="align-self-center text-sm">Customer</small>
                                </Col>
                            </Row>
                        </Container>
                    </Dropdown.Item>
                    <Dropdown.Item
                        style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                        onClick={() => this.setTypeOf('Dormant')}
                        className="hover-row text-sm"
                        style={
                            search_filter.Type_Of.filter((s) => s === 'Dormant').length > 0
                                ? { backgroundColor: baseColors.light_green }
                                : {}
                        }
                    >
                        <Container fluid="xs">
                            <Row>
                                <Col xs={8}>
                                    <small className="align-self-center text-sm">Dormant</small>
                                </Col>
                            </Row>
                        </Container>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
        const type_filter = <InputGroup id="type-input-group">{type_dropdown}</InputGroup>;
        //#endregion Type Filter

        //#region Location Filter
        const state_filter = state_ids ? (
            <InputGroup id="state-id-group" className={search_filter.State_id === '' ? 'opacity-75' : ''}>
                <FloatingLabel label="State/Province" className="text-sm">
                    <Form.Select
                        id="state-id-input"
                        onChange={this.handleFilterChange}
                        name="State_id"
                        value={search_filter.State_id}
                        className="text-sm"
                    >
                        {state_ids.map((d, index) => (
                            <option value={d.id} title={d.name} key={index}>
                                {d.id}
                            </option>
                        ))}
                        <option value="">Select</option>
                    </Form.Select>
                </FloatingLabel>
            </InputGroup>
        ) : null;

        const city_filter = (
            <InputGroup id="city-filter-group" className={search_filter.City === '' ? 'opacity-75' : ''}>
                <FloatingLabel label="City" className="text-sm">
                    <Form.Control
                        id="city-input"
                        onChange={this.handleFilterChange}
                        name="City"
                        value={search_filter.City}
                        className="text-sm"
                        onKeyDown={this.handleKeyPress}
                    />
                </FloatingLabel>
            </InputGroup>
        );

        const address_filter = (
            <InputGroup id="city-filter-group" className={search_filter.Address === '' ? 'opacity-75' : ''}>
                <FloatingLabel label="Address" className="text-sm">
                    <Form.Control
                        id="address-input"
                        onChange={this.handleFilterChange}
                        name="Address"
                        value={search_filter.Address}
                        className="text-sm"
                        onKeyDown={this.handleKeyPress}
                    />
                </FloatingLabel>
            </InputGroup>
        );

        const phone_filter = (
            <InputGroup id="city-filter-group" className={search_filter.Main_Phone === '' ? 'opacity-75' : ''}>
                <FloatingLabel label="Main Phone" className="text-sm">
                    <Form.Control
                        id="phone-input"
                        onChange={this.handleFilterChange}
                        name="Main_Phone"
                        value={search_filter.Main_Phone}
                        className="text-sm"
                        onKeyDown={this.handleKeyPress}
                    />
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion Location Filter

        //#region Salesperson Filter
        const salesperson_id_filter = (
            <InputGroup
                id="salesperson-input-group"
                className=""
            >
                <InputGroup.Text className="text-sm hover-over" onClick={() => this.setState({ show_salespersons_selector: true })}>Select LAMs / GMs</InputGroup.Text>
               
            </InputGroup>
        );
        //#endregion Salesperson Filter

        //#region Commodity Filter
        const commodity_id_filter = commodities ? (
            <InputGroup
                id="commodity-input-group"
                style={search_filter.Commodity === '' ? { filter: 'opacity(0.7)' } : {}}
            >
                <FloatingLabel label="Commodity" className="text-sm">
                    <Form.Select
                        onChange={this.handleFilterChange}
                        className="text-sm"
                        name="Commodity"
                        value={search_filter.Commodity}
                        id="commodity-input"
                        onKeyDown={this.handleKeyPress}
                    >
                        {commodities.map((d, index) => (
                            <option value={d.value} key={index}>
                                {d.label}
                            </option>
                        ))}
                        <option value="">Select</option>
                    </Form.Select>
                </FloatingLabel>
            </InputGroup>
        ) : null;
        //#endregion Commodity Filter

        //#region Zone ID Filter
        const zone_id_filter = (
            <InputGroup id="zone-input-group" style={search_filter.Zone_id === '' ? { filter: 'opacity(0.7)' } : {}}>
                <FloatingLabel label="Zone ID" className="text-sm">
                    {zone_ids.length ? (
                        <Form.Select
                            onChange={this.handleFilterChange}
                            className="text-sm"
                            name="Zone_id"
                            value={search_filter.Zone_id}
                            id="zone-input"
                            onKeyDown={this.handleKeyPress}
                        >
                            {zone_ids.map((d, index) => (
                                <option value={d.id} key={index}>
                                    {d.descr}
                                </option>
                            ))}
                            <option value="">Select</option>
                        </Form.Select>
                    ) : (
                        <Form.Select name="Zone_id" value="" id="zone-input" className="text-sm" disabled={true}>
                            <option value="">Loading...</option>
                        </Form.Select>
                    )}
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion Zone ID Filter
     
        //#region Remark Filter
        const remark_filter = (
            <InputGroup id="remark-search-input-group" className="">
                <InputGroup.Text className={`text-sm hover-over ${search_by_remark?'text-bold':''}`} onClick={() => this.setState({ show_remarks_search: true })}>
                    {search_by_remark ? `Remark: ${search_by_remark}` :'Search By Activity Remark'}
                </InputGroup.Text>
                {search_by_remark ?
                    <InputGroup.Text className="text-sm hover-over bg-white text-danger" onClick={() => this.setState({ search_by_remark: '', remark_company_id: '' })}>X</InputGroup.Text> :
                    null
                }
                
            </InputGroup>
        );
        //#endregion Remark Filter 

        //#region Orders Billed/Paid Filter
        const orders_billed_filter = (
            <NumericComparatorInput
                id_group="orders-billed"
                search_filter={search_filter}
                field_title="Orders Billed"
                handleFilterChange={(e) => this.handleFilterChange(e)}
                handleKeyPress={(e) => this.handleKeyPress(e)}
                comparator_name="Orders_Billed_Comparator"
                low_input_name="Low_Orders_Billed"
                high_input_name="High_Orders_Billed"
            />
        );
        const orders_paid_filter = (
            <NumericComparatorInput
                id_group="orders-paid"
                search_filter={search_filter}
                field_title="Orders Paid"
                handleFilterChange={(e) => this.handleFilterChange(e)}
                handleKeyPress={(e) => this.handleKeyPress(e)}
                comparator_name="Orders_Paid_Comparator"
                low_input_name="Low_Orders_Paid"
                high_input_name="High_Orders_Paid"
            />
        );

        //#endregion Orders Billed/Paid Filter

        //#region Credit Limit Filter
        const credit_limit_filter = (
            <NumericComparatorInput
                id_group="credit-limit"
                search_filter={search_filter}
                field_title="Credit Limit"
                handleFilterChange={(e) => this.handleFilterChange(e)}
                handleKeyPress={(e) => this.handleKeyPress(e)}
                comparator_name="Credit_Limit_Comparator"
                low_input_name="Low_Credit_Limit"
                high_input_name="High_Credit_Limit"
            />
        );
        //#endregion Credit Limit Filter

        //#region High Balance Filter
        const high_balance_filter = (
            <NumericComparatorInput
                id_group="high-balance"
                search_filter={search_filter}
                field_title="High Balance"
                handleFilterChange={(e) => this.handleFilterChange(e)}
                handleKeyPress={(e) => this.handleKeyPress(e)}
                comparator_name="High_Balance_Comparator"
                low_input_name="Low_High_Balance"
                high_input_name="High_High_Balance"
            />
        );
        //#endregion High Balance Filter

        //#region Market Plan Start Filter
        const start_date_filter = (
            <ComparatorInput
                id_group="market-start-date"
                search_filter={search_filter}
                field_title="Market Plan Start Date"
                handleFilterChange={(e) => this.handleFilterChange(e)}
                handleKeyPress={(e) => this.handleKeyPress(e)}
                comparator_name="Start_Date_Comparator"
                low_input_name="Low_Start_Date"
                high_input_name="High_Start_Date"
            />
        );
        //#endregion Market Plan Start Filter

        //#region Conversion Date Filter
        const conversion_date_filter = (
            <ComparatorInput
                id_group="conversion-date"
                search_filter={search_filter}
                field_title="Conversion Date"
                handleFilterChange={(e) => this.handleFilterChange(e)}
                handleKeyPress={(e) => this.handleKeyPress(e)}
                comparator_name="Conversion_Date_Comparator"
                low_input_name="Low_Conversion_Date"
                high_input_name="High_Conversion_Date"
            />
        );

        //#endregion Conversion Date Filter

        //#region Entered Date Filter
        const entered_date_filter = (
            <ComparatorInput
                id_group="entered-date"
                search_filter={search_filter}
                field_title="Entered Date"
                handleFilterChange={(e) => this.handleFilterChange(e)}
                handleKeyPress={(e) => this.handleKeyPress(e)}
                comparator_name="Entered_Date_Comparator"
                low_input_name="Low_Entered_Date"
                high_input_name="High_Entered_Date"
            />
        );

        //#endregion Entered Date Filter

        //#region Last Ship Date Filter
        const last_ship_date_filter = (
            <ComparatorInput
                id_group="last-ship-date"
                search_filter={search_filter}
                field_title="Last Ship Date"
                handleFilterChange={(e) => this.handleFilterChange(e)}
                handleKeyPress={(e) => this.handleKeyPress(e)}
                comparator_name="Last_Ship_Date_Comparator"
                low_input_name="Low_Last_Ship_Date"
                high_input_name="High_Last_Ship_Date"
            />
        );
        //#endregion Last Ship Date Filter

        //#region Filter Customers
        // let selected_list = list_view === 'Search' ? master_list : hot_prospects.list;

        let filtered_customers = master_list ? master_list : [];
        if (search_filter.Search_Input) {
            filtered_customers = this.compare_search_input(search_filter.Search_Input, filtered_customers)
        }
        if (search_filter.Company_id.length > 0) {
            filtered_customers = filtered_customers.filter((s) => search_filter.Company_id.find(t => t === s.company_id));
        }

        if (search_filter.Type_Of.length >0) {
            filtered_customers = filtered_customers.filter((s) => search_filter.Type_Of.find(t => t === s.type_of));
        } 


        if (hide_dnu_result) {
            filtered_customers = filtered_customers.filter((s) => s.crm_do_not_use !== 'Y');
        }

        //#region Filter By Address
        if (search_filter.Address !== '') {
            filtered_customers = filtered_customers.filter((s) =>
                s.address1.toLowerCase().includes(search_filter.Address.toLowerCase()),
            );
        }
        //#endregion Filter By Address

        //#region Filter By Main Phone
        if (search_filter.Main_Phone !== '') {
            filtered_customers = filtered_customers.filter((s) => s.main_phone.includes(search_filter.Main_Phone));
        }
        //#endregion Filter By Main Phone
 
        //#region Filter by Claimed/Unclaimed
        if (search_filter.Unclaimed === 'CLAIMD') {
            filtered_customers = filtered_customers.filter((s) => s.salesperson_id !== 'UNCLAIMD');
        } else if (search_filter.Unclaimed === 'UNCLAIMD') {
            filtered_customers = filtered_customers.filter((s) => s.salesperson_id === 'UNCLAIMD');
        } else {
            filtered_customers = filtered_customers;
        }
        //#endregion Filter by Claimed/Unclaimed

        //#region Filter by Salesperson
        if (search_filter.Salesperson_ids.length === 0) {
            filtered_customers = filtered_customers;
        } else {
            const new_list = [];
            if (search_filter.Salesperson_Type === 'salesperson_id') {
                for (let i = 0; i < filtered_customers.length; i++) {
                    if (
                        search_filter.Salesperson_ids.filter((s) => s.salesperson_id === filtered_customers[i].salesperson_id)
                            .length > 0
                    ) {
                        new_list.push(filtered_customers[i]);
                    }
                }
                filtered_customers = new_list;
            } else {
                for (let i = 0; i < filtered_customers.length; i++) {
                    if (
                        filtered_customers[i].sales_manager_id !== undefined &&
                        filtered_customers[i].sales_manager_id !== null
                    ) {
                        if (
                            search_filter.Salesperson_ids.filter(
                                (s) => s.salesperson_id.toLowerCase() === filtered_customers[i].sales_manager_id.toLowerCase(),
                            ).length > 0
                        ) {
                            new_list.push(filtered_customers[i]);
                        }
                    }
                }
                filtered_customers = new_list;
            }
        }
        //#endregion Filter by Salesperson

        //#region Filter by Commodity
        if (search_filter.Commodity === '') {
            filtered_customers = filtered_customers;
        } else {
            filtered_customers = filtered_customers.filter((s) => s.bill_format_flag === search_filter.Commodity);
        }
        //#endregion Filter by Commodity

        //#region Filter by Remark
        if (!search_by_remark) {
            filtered_customers = filtered_customers;
        } else {
            let new_filtered_customers = [];
            for (let i = 0; i < filtered_customers.length; i++) {
                if (filtered_customers[i].activity_remarks) {
                    if (filtered_customers[i].activity_remarks.toLowerCase().includes(search_by_remark.toLowerCase())){
                        new_filtered_customers.push(filtered_customers[i])
                    }
                }
                
            }
            filtered_customers = new_filtered_customers
        }
        //#endregion Filter by Remark

        //#region Filter by State
        if (search_filter.State_id !== '') {
            const new_list_by_state = [];
            for (let i = 0; i < filtered_customers.length; i++) {
                if (filtered_customers[i].state_id) {
                    if (filtered_customers[i].state_id.toLowerCase() === search_filter.State_id.toLowerCase()) {
                        new_list_by_state.push(filtered_customers[i]);
                    }
                }
            }
            filtered_customers = new_list_by_state;
        }
        //#endregion Filter by State

        //#region Filter by City
        if (search_filter.City !== '') {
            const new_list = [];
            const current_city_filter = search_filter.City ? search_filter.City.toLowerCase() : '';
            for (let i = 0; i < filtered_customers.length; i++) {
                if (filtered_customers[i].city !== undefined && filtered_customers[i].city !== null) {
                    if (filtered_customers[i].city.toLowerCase().includes(current_city_filter)) {
                        new_list.push(filtered_customers[i]);
                    }
                }
            }
            filtered_customers = new_list;
        }
        //#endregion Filter by City

        //#region Filter by Zone
        if (search_filter.Zone_id !== '') {
            const new_list = [];
            const current_zone_filter = search_filter.Zone_id ? search_filter.Zone_id.toLowerCase() : '';
            for (let i = 0; i < filtered_customers.length; i++) {
                if (filtered_customers[i].zone_id !== undefined && filtered_customers[i].zone_id !== null) {
                    if (filtered_customers[i].zone_id.toLowerCase().includes(current_zone_filter)) {
                        new_list.push(filtered_customers[i]);
                    }
                }
            }
            filtered_customers = new_list;
        }
        //#endregion Filter by Zone

        //#region Filter by Start Date
        if (search_filter.Start_Date_Comparator !== '') {
            if (search_filter.Start_Date_Comparator === 'between') {
                const new_list = [];
                const low_date = new Date(search_filter.Low_Start_Date);
                const high_date = new Date(search_filter.High_Start_Date);
                for (let i = 0; i < filtered_customers.length; i++) {
                    const current_customer = filtered_customers[i];
                    if (current_customer.start_date !== null && current_customer.start_date !== undefined) {
                        if (
                            isAfter(new Date(current_customer.start_date), low_date) &&
                            isBefore(new Date(current_customer.start_date), high_date)
                        ) {
                            new_list.push(current_customer);
                        }
                    }
                }
                filtered_customers = new_list;
            } else if (search_filter.Start_Date_Comparator === 'greater than') {
                const new_list = [];
                const low_date = new Date(search_filter.Low_Start_Date);
                for (let i = 0; i < filtered_customers.length; i++) {
                    const current_customer = filtered_customers[i];
                    if (current_customer.start_date !== null && current_customer.start_date !== undefined) {
                        if (isAfter(new Date(current_customer.start_date), low_date)) {
                            new_list.push(current_customer);
                        }
                    }
                }
                filtered_customers = new_list;
            } else if (search_filter.Start_Date_Comparator === 'less than') {
                const new_list = [];
                const low_date = new Date(search_filter.Low_Start_Date);
                for (let i = 0; i < filtered_customers.length; i++) {
                    const current_customer = filtered_customers[i];
                    if (current_customer.start_date !== null && current_customer.start_date !== undefined) {
                        if (isBefore(new Date(current_customer.start_date), low_date)) {
                            new_list.push(current_customer);
                        }
                    }
                }
                filtered_customers = new_list;
            }
        }
        //#endregion Filter by Start Date

        //#region Filter by Conversion Date
        if (search_filter.Conversion_Date_Comparator !== '') {
            if (search_filter.Conversion_Date_Comparator === 'between') {
                filtered_customers = filtered_customers.filter(
                    (s) =>
                        new Date(s.conversion_date) > new Date(search_filter.Low_Conversion_Date) &&
                        new Date(s.conversion_date) < new Date(search_filter.High_Conversion_Date),
                );
            } else if (search_filter.Conversion_Date_Comparator === 'greater than') {
                filtered_customers = filtered_customers.filter(
                    (s) => new Date(s.conversion_date) > new Date(search_filter.Low_Conversion_Date),
                );
            } else if (search_filter.Conversion_Date_Comparator === 'less than') {
                filtered_customers = filtered_customers.filter(
                    (s) => new Date(s.conversion_date) < new Date(search_filter.Low_Conversion_Date),
                );
            }
        }
        //#endregion Filter by Conversion Date

        //#region Filter by Last Ship Date
        if (search_filter.Last_Ship_Date_Comparator !== '') {
            if (search_filter.Last_Ship_Date_Comparator === 'between') {
                filtered_customers = filtered_customers.filter(
                    (s) =>
                        new Date(s.last_ship_date) > new Date(search_filter.Low_Last_Ship_Date) &&
                        new Date(s.last_ship_date) < new Date(search_filter.High_Last_Ship_Date),
                );
            } else if (search_filter.Last_Ship_Date_Comparator === 'greater than') {
                filtered_customers = filtered_customers.filter(
                    (s) => new Date(s.last_ship_date) > new Date(search_filter.Low_Last_Ship_Date),
                );
            } else if (search_filter.Last_Ship_Date_Comparator === 'less than') {
                filtered_customers = filtered_customers.filter(
                    (s) => new Date(s.last_ship_date) < new Date(search_filter.Low_Last_Ship_Date),
                );
            }
        }
        //#endregion Filter by Last Ship Date

        //#region Filter by Entered Date
        if (search_filter.Entered_Date_Comparator !== '') {
            if (search_filter.Entered_Date_Comparator === 'between') {
                filtered_customers = filtered_customers.filter(
                    (s) =>
                        new Date(s.entered_date) > new Date(search_filter.Low_Entered_Date) &&
                        new Date(s.entered_date) < new Date(search_filter.High_Entered_Date),
                );
            } else if (search_filter.Entered_Date_Comparator === 'greater than') {
                filtered_customers = filtered_customers.filter(
                    (s) => new Date(s.entered_date) > new Date(search_filter.Low_Entered_Date),
                );
            } else if (search_filter.Entered_Date_Comparator === 'less than') {
                filtered_customers = filtered_customers.filter(
                    (s) => new Date(s.entered_date) < new Date(search_filter.Low_Entered_Date),
                );
            }
        }
        //#endregion Filter by Entered Date

        //#region Filter by High Balance
        if (search_filter.High_Balance_Comparator !== '') {
            if (search_filter.High_Balance_Comparator === 'between') {
                filtered_customers = filtered_customers.filter(
                    (s) =>
                        s.high_balance > search_filter.Low_High_Balance &&
                        s.high_balance < search_filter.High_High_Balance,
                );
            } else if (search_filter.High_Balance_Comparator === 'greater than') {
                filtered_customers = filtered_customers.filter((s) => s.high_balance > search_filter.Low_High_Balance);
            } else if (search_filter.High_Balance_Comparator === 'less than') {
                filtered_customers = filtered_customers.filter((s) => s.high_balance < search_filter.Low_High_Balance);
            }
        }
        //#endregion Filter by High Balance

        //#region Filter by Credit Limit
        if (search_filter.Credit_Limit_Comparator !== '') {
            if (search_filter.Credit_Limit_Comparator === 'between') {
                filtered_customers = filtered_customers.filter(
                    (s) =>
                        s.credit_limit > search_filter.Low_Credit_Limit &&
                        s.credit_limit < search_filter.High_Credit_Limit,
                );
            } else if (search_filter.Credit_Limit_Comparator === 'greater than') {
                filtered_customers = filtered_customers.filter((s) => s.credit_limit > search_filter.Low_Credit_Limit);
            } else if (search_filter.Credit_Limit_Comparator === 'less than') {
                filtered_customers = filtered_customers.filter((s) => s.credit_limit < search_filter.Low_Credit_Limit);
            }
        }
        //#endregion Filter by Credit Limit

        //#region Filter by Orders Billed
        if (search_filter.Orders_Billed_Comparator !== '') {
            if (search_filter.Orders_Billed_Comparator === 'between') {
                filtered_customers = filtered_customers.filter(
                    (s) =>
                        s.billed_loads > search_filter.Low_Orders_Billed &&
                        s.billed_loads < search_filter.High_Orders_Billed,
                );
            } else if (search_filter.Orders_Billed_Comparator === 'greater than') {
                filtered_customers = filtered_customers.filter((s) => s.billed_loads > search_filter.Low_Orders_Billed);
            } else if (search_filter.Orders_Billed_Comparator === 'less than') {
                filtered_customers = filtered_customers.filter((s) => s.billed_loads < search_filter.Low_Orders_Billed);
            }
        }
        //#endregion Filter by Orders Billed

        //#region Filter by Orders Paid
        if (search_filter.Orders_Paid_Comparator !== '') {
            if (search_filter.Orders_Paid_Comparator === 'between') {
                filtered_customers = filtered_customers.filter(
                    (s) =>
                        s.paid_loads > search_filter.Low_Orders_Paid && s.paid_loads < search_filter.High_Orders_Paid,
                );
            } else if (search_filter.Orders_Paid_Comparator === 'greater than') {
                filtered_customers = filtered_customers.filter((s) => s.paid_loads > search_filter.Low_Orders_Paid);
            } else if (search_filter.Orders_Paid_Comparator === 'less than') {
                filtered_customers = filtered_customers.filter((s) => s.paid_loads < search_filter.Low_Orders_Paid);
            }
        }
        //#endregion Filter by Orders Paid

        filtered_customers = filtered_customers ? filtered_customers.slice(0, set_page) : [];

        //#endregion Filter Customers

        //#region Result List

        const result_list_table =
            filtered_customers.length > 0 ? (
                <ResultsTable
                    accounts={filtered_customers}
                    search_by_remark={search_by_remark}
                    view_account={(company_id, id) => this.view_account(company_id, id)}
                    title={list_view === 'Hot' ? `Hot Prospects (${filtered_customers.length})` : `Search Results (${filtered_customers.length}/${displayed_prospects.length})`}
                />
            ) : (
                <h6 className="no-results-header">
                    {list_view === 'Hot' ? 'There are currently no hot prospects' : 'No Results'}
                </h6>
            );

        //#endregion Result List

        //#region Show Result List
        const show_result_list = wait_for_data ? (
            <SearchLoading list_view={list_view} />
        ) : (
            <Card.Body className="d-flex flex-column p-0">
                <div style={{ maxHeight: '50rem', overflow: 'auto' }}>{result_list_table}</div>

                {displayed_prospects.length > 500 ? (
                    <Button
                        variant="link"
                        className="btn-op70 underlined"
                        onClick={() => this.setState({ set_page: set_page + 100 })}
                    >
                        Load More
                    </Button>
                ) : null}
            </Card.Body>
        );
        //#endregion Show Result List

        //#region Search Header Toast

        //#region Basic Filter Toast
        const company_toast =
            search_filter.Company_id.length>0 ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Company_id = ['TMS','TMS2'];
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Company:</strong>
                            {search_filter.Company_id.length === 1 ?
                                <bdi>{search_filter.Company_id[0]==='TMS2'?'SLUSA':'SLC'}</bdi> :
                                <bdi>{`${search_filter.Company_id[0] === 'TMS2' ? 'SLUSA' : 'SLC'}, ${search_filter.Company_id[1] === 'TMS2' ? 'SLUSA' : 'SLC'}`}</bdi>
                            } 
                            
                 
                           
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const unclaimed_toast =
            search_filter.Unclaimed !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Unclaimed = '';
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100 "
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Status:</strong>
                            <bdi>{search_filter.Unclaimed}</bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const input_toast =
            search_filter.Search_Input !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Search_Input = '';
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Name/Code:</strong>
                            <bdi>{search_filter.Search_Input}</bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const salesperson_toast =
            search_filter.Salesperson_ids.length > 0 ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Salesperson_ids = [];
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="salesperson-filter-tooltip">
                                    <div className="d-flex flex-column">
                                        {Array.isArray(search_filter.Salesperson_ids)
                                            ? search_filter.Salesperson_ids.map((d, index) => <bdi>{d.salesperson_id}</bdi>)
                                            : null}
                                    </div>
                                </Tooltip>
                            }
                        >
                            <Toast.Header className="w-100 border-0 rounded">
                                <strong className="me-auto">
                                    {search_filter.Salesperson_Type === 'sales_manager_id' ? 'General Manager' : 'LAM'}:
                                </strong>

                                <bdi>{search_filter.Salesperson_ids.length + ' Selected'}</bdi>
                            </Toast.Header>
                        </OverlayTrigger>
                    </Toast>
                </Col>
            ) : null;

        const type_toast =
            search_filter.Type_Of.length > 0 ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Type_Of = [];
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="typeof-filter-tooltip">
                                    <div className="d-flex flex-column">
                                        {search_filter.Type_Of.map((d, index) => {
                                            if (d === 'Prospect') {
                                                return <bdi>Prospect</bdi>;
                                            } else if (d === 'Customer') {
                                                return <bdi>Customer</bdi>;
                                            } else if (d === 'Dormant') {
                                                return <bdi>Dormant</bdi>;
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </div>
                                </Tooltip>
                            }
                        >
                            <Toast.Header className="w-100 border-0 rounded">
                                <strong className="me-auto">Type:</strong>
                                <bdi>{search_filter.Type_Of.length + ' Selected'}</bdi>
                            </Toast.Header>
                        </OverlayTrigger>
                    </Toast>
                </Col>
            ) : null;

        const commodity_toast =
            search_filter.Commodity !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Commodity = '';
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Commodity:</strong>
                            <bdi>{search_filter.Commodity}</bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const remark_toast =
            search_filter.Remark !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Remark = '';
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Remark:</strong>
                            <bdi>{search_filter.Remark}</bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const state_toast =
            search_filter.State_id !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.State_id = '';
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">State/Province:</strong>
                            <bdi>{search_filter.State_id}</bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const city_toast =
            search_filter.City !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.City = '';
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">City:</strong>
                            <bdi>{search_filter.City}</bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const zone_toast =
            search_filter.Zone_id !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Zone_id = '';
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Zone:</strong>
                            <bdi>{search_filter.Zone_id}</bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;
        //#endregion Basic Filter Toast

        //#region Advanced Filter Toasts
        const start_toast =
            search_filter.Start_Date_Comparator !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Start_Date_Comparator = '';
                            current_filter.Low_Start_Date = format(addDays(new Date(), -1), 'yyyy-MM-dd');
                            current_filter.High_Start_Date = format(new Date(), 'yyyy-MM-dd');
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Start Date:</strong>
                            <bdi>
                                {search_filter.Start_Date_Comparator === 'between'
                                    ? search_filter.Start_Date_Comparator +
                                      ' [' +
                                      search_filter.Low_Start_Date +
                                      '] & [' +
                                      search_filter.High_Start_Date +
                                      ']'
                                    : search_filter.Start_Date_Comparator + ' ' + search_filter.Low_Start_Date}
                            </bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const conversion_toast =
            search_filter.Conversion_Date_Comparator !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Conversion_Date_Comparator = '';
                            current_filter.Low_Conversion_Date = format(addDays(new Date(), -1), 'yyyy-MM-dd');
                            current_filter.High_Conversion_Date = format(new Date(), 'yyyy-MM-dd');
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Conversion Date:</strong>
                            <bdi>
                                {search_filter.Conversion_Date_Comparator === 'between'
                                    ? search_filter.Conversion_Date_Comparator +
                                      ' [' +
                                      search_filter.Low_Conversion_Date +
                                      '] & [' +
                                      search_filter.High_Conversion_Date +
                                      ']'
                                    : search_filter.Conversion_Date_Comparator +
                                      ' ' +
                                      search_filter.Low_Conversion_Date}
                            </bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const ship_toast =
            search_filter.Last_Ship_Date_Comparator !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Last_Ship_Date_Comparator = '';
                            current_filter.Low_Last_Ship_Date = format(addDays(new Date(), -1), 'yyyy-MM-dd');
                            current_filter.High_Last_Ship_Date = format(new Date(), 'yyyy-MM-dd');
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Last Ship Date:</strong>
                            <bdi>
                                {search_filter.Last_Ship_Date_Comparator === 'between'
                                    ? search_filter.Last_Ship_Date_Comparator +
                                      ' [' +
                                      search_filter.Low_Last_Ship_Date +
                                      '] & [' +
                                      search_filter.High_Last_Ship_Date +
                                      ']'
                                    : search_filter.Last_Ship_Date_Comparator + ' ' + search_filter.Low_Last_Ship_Date}
                            </bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const entered_toast =
            search_filter.Entered_Date_Comparator !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Entered_Date_Comparator = '';
                            current_filter.Low_Entered_Date = format(addDays(new Date(), -1), 'yyyy-MM-dd');
                            current_filter.High_Entered_Date = format(new Date(), 'yyyy-MM-dd');
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Entered Date:</strong>
                            <bdi>
                                {search_filter.Entered_Date_Comparator === 'between'
                                    ? search_filter.Entered_Date_Comparator +
                                      ' [' +
                                      search_filter.Low_Entered_Date +
                                      '] & [' +
                                      search_filter.High_Entered_Date +
                                      ']'
                                    : search_filter.Entered_Date_Comparator + ' ' + search_filter.Low_Entered_Date}
                            </bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const balance_toast =
            search_filter.High_Balance_Comparator !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.High_Balance_Comparator = '';
                            current_filter.Low_High_Balance = 0;
                            current_filter.High_High_Balance = 1;
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">High Balance:</strong>
                            <bdi>
                                {search_filter.High_Balance_Comparator === 'between'
                                    ? search_filter.High_Balance_Comparator +
                                      ' [' +
                                      search_filter.Low_High_Balance +
                                      '] & [' +
                                      search_filter.High_High_Balance +
                                      ']'
                                    : search_filter.High_Balance_Comparator + ' ' + search_filter.Low_High_Balance}
                            </bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const credit_toast =
            search_filter.Credit_Limit_Comparator !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Credit_Limit_Comparator = '';
                            current_filter.Low_Credit_Limit = 0;
                            current_filter.High_Credit_Limit = 1;
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Credit Limit:</strong>
                            <bdi>
                                {search_filter.Credit_Limit_Comparator === 'between'
                                    ? search_filter.Credit_Limit_Comparator +
                                      ' [' +
                                      search_filter.Low_Credit_Limit +
                                      '] & [' +
                                      search_filter.High_Credit_Limit +
                                      ']'
                                    : search_filter.Credit_Limit_Comparator + ' ' + search_filter.Low_Credit_Limit}
                            </bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const billed_toast =
            search_filter.Orders_Billed_Comparator !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Orders_Billed_Comparator = '';
                            current_filter.Low_Orders_Billed = 0;
                            current_filter.High_Orders_Billed = 1;
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Orders Billed:</strong>
                            <bdi>
                                {search_filter.Orders_Billed_Comparator === 'between'
                                    ? search_filter.Orders_Billed_Comparator +
                                      ' [' +
                                      search_filter.Low_Orders_Billed +
                                      '] & [' +
                                      search_filter.High_Orders_Billed +
                                      ']'
                                    : search_filter.Orders_Billed_Comparator + ' ' + search_filter.Low_Orders_Billed}
                            </bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;

        const paid_toast =
            search_filter.Orders_Paid_Comparator !== '' ? (
                <Col className="mb-3">
                    <Toast
                        onClose={() => {
                            const current_filter = search_filter;
                            current_filter.Orders_Paid_Comparator = '';
                            current_filter.Low_Orders_Paid = 0;
                            current_filter.High_Orders_Paid = 1;
                            this.setState({ search_filter: current_filter });
                        }}
                        className="w-100"
                    >
                        <Toast.Header className="w-100 border-0 rounded">
                            <strong className="me-auto">Orders Billed:</strong>
                            <bdi>
                                {search_filter.Orders_Paid_Comparator === 'between'
                                    ? search_filter.Orders_Paid_Comparator +
                                      ' [' +
                                      search_filter.Low_Orders_Paid +
                                      '] & [' +
                                      search_filter.High_Orders_Paid +
                                      ']'
                                    : search_filter.Orders_Paid_Comparator + ' ' + search_filter.Low_Orders_Paid}
                            </bdi>
                        </Toast.Header>
                    </Toast>
                </Col>
            ) : null;
        //#endregion Advanced Filter Toasts

        const search_header_toast = (
            <Container fluid="xs" className="w-100 p-2">
                <Row className="d-flex flex-row">
                    {company_toast}
                    {unclaimed_toast}
                    {input_toast}
                    {remark_toast}
                    {salesperson_toast}
                    {type_toast}
                    {commodity_toast}
                    {state_toast}
                    {city_toast}
                    {zone_toast}
                    {start_toast}
                    {conversion_toast}
                    {ship_toast}
                    {entered_toast}
                    {balance_toast}
                    {credit_toast}
                    {billed_toast}
                    {paid_toast}
                </Row>
            </Container>
        );
        //#endregion Search Header Toast

        //#region Advanced Options Tooltip
        const advanced_overlay_trigger = (
            <Button
                variant="link"
                className="underlined text-sm"
                onClick={() => this.setState({ show_advanced: true })}
            >
                Show Advanced Filters
            </Button>
        );
        //#endregion Advanced Options Tooltip  

        return (
            <Container
                fluid="xs"
                className="p-4 d-flex flex-column w-100 mb-3">
                {user_loaded? (
                    <Card className="p-0 d-flex flex-column w-100">
                        {search_header_toast}
                        <Card.Body className="d-flex flex-column w-100" style={{ alignSelf: 'center' }}>
                            <Container fluid="xs">
                                <Row>
                                    <Col lg={3}>
                                        <Row className="mb-1 px-2">
                                            <Col>
                                                <Button
                                                    onClick={() => this.get_hot_prospects()}
                                                    className="text-lg d-flex flex-row justify-content-between hot"
                                                    variant="danger"
                                                >
                                                    {svg_icons.fire_icon}
                                                    <label className="align-self-center px-1 text-sm">
                                                        {' '}
                                                        {loading_hot_prospects ? 'Loading...' : 'HOT PROSPECTS'}
                                                    </label>
                                                    {svg_icons.fire_icon}
                                                </Button>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3 p-2">
                                            <Col>{search_bar}</Col>
                                        </Row>

                                        <Row className="px-2">
                                            <Col sm={4} med={4} lg={4} xl={4} className="mb-3">
                                                {company_filter}
                                            </Col>
                                            <Col sm={4} med={4} lg={4} xl={4} className="mb-3">
                                                {status_filter}
                                            </Col>
                                            <Col sm={4} med={4} lg={4} xl={4} className="mb-3">
                                                {dnu_filter}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3 px-2">
                                            <Col>{type_filter}</Col>
                                        </Row>
                                        <Row className="mb-3 px-2">
                                            <Col>{salesperson_id_filter}</Col>
                                        </Row>
                                        <Row className="mb-3 px-2">
                                            <Col>{remark_filter}</Col>
                                        </Row>

                                        <Row className="mb-3 px-2">
                                            <Col xs={12} sm={12} med={6} lg={7} xl={8}>
                                                {city_filter}
                                            </Col>
                                            <Col xs={12} sm={12} med={6} lg={5} xl={4}>
                                                {state_filter}
                                            </Col>
                                        </Row>
                                 
                                        <Row className="mb-3 px-2">
                                            <Col>{commodity_id_filter}</Col>
                                        </Row>
                                        <Row className="mb-3 px-2">
                                            <Col>{zone_id_filter}</Col>
                                        </Row>
                                    
                                        <Row className="mb-3">
                                            <Col>
                                                {show_advanced ? (
                                                    <Button
                                                        variant="link"
                                                        className="underlined text-sm"
                                                        onClick={() => this.setState({ show_advanced: false })}
                                                    >
                                                        Hide Advanced Filters
                                                    </Button>
                                                ) : (
                                                    advanced_overlay_trigger
                                                )}
                                            </Col>
                                        </Row>
                                        {show_advanced ? (
                                            <Row className="mb-3 px-2">
                                                <Col>{address_filter}</Col>
                                            </Row>
                                        ) : null}
                                        {show_advanced ? (
                                            <Row className="mb-3 px-2">
                                                <Col>{phone_filter}</Col>
                                            </Row>
                                        ) : null}

                                        {show_advanced ? (
                                            <Row className="mb-3 px-2">
                                                <Col>{start_date_filter}</Col>
                                            </Row>
                                        ) : null}
                                        {show_advanced ? (
                                            <Row className="mb-3 px-2">
                                                <Col>{conversion_date_filter}</Col>
                                            </Row>
                                        ) : null}
                                        {show_advanced ? (
                                            <Row className="mb-3 px-2">
                                                <Col>{last_ship_date_filter}</Col>
                                            </Row>
                                        ) : null}
                                        {show_advanced ? (
                                            <Row className="mb-3 px-2">
                                                <Col>{entered_date_filter}</Col>
                                            </Row>
                                        ) : null}
                                        {show_advanced ? (
                                            <Row className="mb-3 px-2">
                                                <Col>{high_balance_filter}</Col>
                                            </Row>
                                        ) : null}
                                        {show_advanced ? (
                                            <Row className="mb-3 px-2">
                                                <Col>{credit_limit_filter}</Col>
                                            </Row>
                                        ) : null}
                                        {show_advanced ? (
                                            <Row className="mb-3 px-2">
                                                <Col>{orders_billed_filter}</Col>
                                            </Row>
                                        ) : null}
                                        {show_advanced ? (
                                            <Row className="mb-3 px-2">
                                                <Col>{orders_paid_filter}</Col>
                                            </Row>
                                        ) : null}
                                    </Col>

                                    <Col lg={9}>
                                        {result_message ? (
                                            <label className="text-danger text-lg text-bold">{result_message}</label>
                                        ) : null}
                                        {show_result_list}
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                ) : (
                    <div className="d-flex flex-row justify-content-center py-5">
                        <h3 style={{ color: baseColors.dark_gray }}>Loading</h3>
                        <LoadingIcons.ThreeDots
                            style={{ alignSelf: 'end', width: '2rem', marginLeft: '0.25rem' }}
                            fill={baseColors.dark_gray}
                            strokeOpacity={0.125}
                            speed={0.35}
                        />
                    </div>
                )}
                {
                    selected_account ?

                        <ViewAccountModal
                            show={show_selected_account}
                            onHide={() => this.setState({ show_selected_account: false, selected_account: null})}
                            current_user={this.props.salesperson}
                            msal_account={this.props.msal_account}
                            role={this.props.role}
                            accounts_list={filtered_customers}
                            prospects={this.props.prospects}
                            commodities={this.props.commodities}
                            salesperson_list={this.props.salesperson_list}
                            categories={this.props.categories}
                            selected_account_data={selected_account}
                            add_account={(prop) => this.props.add_account(prop)}
                            release_account={(prop) => this.props.release_account(prop)}
                            update_accounts_list={(prop) => this.setState({ master_list: prop })}
                            view_account={(account) => this.view_account(account.company_id, account.id)}
                            update_selected_account_data={(prop) => this.setState({ selected_account: prop })}
                            update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                            add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                            add_users_action={(prop) => this.props.add_users_action(prop)}
                            update_users_action={(prop) => this.props.add_users_action(prop)}
                        /> : null
                }
                <SalespersonSelector
                    show={show_salespersons_selector}
                    salesperson_list={salesperson_list}
                    onHide={() => this.setState({ show_salespersons_selector: false })}
                    search_filter={search_filter}
                    update_search_filter={(prop) => this.setState({ search_filter: prop })}
                    width={this.props.width}
                />
                <RemarksSearchModal
                    show={show_remarks_search}
                    onHide={() => this.setState({ show_remarks_search: false })}
                    clicked={wait_for_data}
                    search_by_remark={search_by_remark}
                    update_search_by_remark={(prop) => this.setState({ search_by_remark: prop })}
                    remark_company_id={remark_company_id}
                    update_remark_company_id={(prop) => this.update_search_filter_from_remarks(prop)}
                    get_list={() => this.search_by_activity_remark()}
                />

                <LoadingModal show={account_clicked} title="Account" />
            </Container>
        );
    }





    async view_account(company_id, id) {
        this.setState({  account_clicked: true, selected_account_loaded: false });
        const account_data = await customerFunctions.View_Account(company_id, id);
        this.setState({
            selected_account: account_data,
            show_selected_account: true,
            account_clicked: false
        })
    }
}
