
import React from 'react';
import { AdminRoutes } from './Routes/AdminRoutes';
import { CreditRoutes } from './Routes/CreditRoutes';
import { GMRoutes } from './Routes/GMRoutes';
import { LAMRoutes } from './Routes/LAMRoutes';

export const RouteGuard = ({ ...props }) => {
    if (props.role === 'admin') {
        return (<AdminRoutes
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}           
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            get_users_accounts={(company_id, salesperson_id) => props.get_users_accounts(company_id, salesperson_id)}
            set_gm_requests={(company_id, salesperson_id) => props.set_gm_requests(company_id, salesperson_id)}
            set_lam_requests={(company_id, salesperson_id) => props.set_lam_requests(company_id, salesperson_id)}
            set_credit_requests={() => props.set_credit_requests()}
            open_report_issue={() => props.open_report_issue()} />)
    }
    else if (props.role === 'credit' || props.role === 'commission') {
        return (<CreditRoutes
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()} />)
    }
    else if (props.role === 'gm') {
        return (<GMRoutes
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()} />)
    }
    else if (props.role === 'lam' || props.role === 'operations') {
        return (<LAMRoutes
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()} />)
    }  else {
        return (<div
            className="data-area-div"
            style={{ backgroundColor: 'black', height: '85vh', display: 'flex', justifyContent: 'center' }}
        >
            <h3 style={{ width: '100%', textAlign: 'center', color: 'white', alignSelf: 'center' }}>
                You are not authorized to view this content.
            </h3>
        </div>)
    }
};
