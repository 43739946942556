import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AddNewProspect } from '../components/AddProspect/AddNewProspect';
import { TimeoutSession } from '../components/Modals/TimeoutSession';
import { MyCustomers } from '../components/MyCustomers';
import { MyProspects } from '../components/MyProspects';
import { Salespersons } from '../components/Salespersons';
import { Reports } from '../components/Reports';
import { LAMPortal } from '../components/Portals/LAMPortal';
import { SearchProspects } from '../components/SearchPage/SearchProspects';
import useIdleTimeout from './idleTimeout';
import { Userpilot } from 'userpilot';
import { clarity } from 'react-microsoft-clarity';

export const LAMRoutes = ({ ...props }) => {
    const { instance, accounts } = useMsal();
    const [openTimeout, setOpenTimeout] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);


    //#region Idle Timer
    const handleIdle = () => {
        setOpenTimeout(true);
        instance.logoutRedirect().catch((e) => {
            localStorage.clear();
            console.error(e);
        });
    };
    const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 3 });
    const stay = () => {
        setOpenTimeout(false);
        idleTimer.reset();
    };
    const handleLogout = () => {
        //logout()

        setOpenTimeout(false);
    };
    //#endregion Idle Timer




    //#region Route
    const LAMportal =
        <LAMPortal
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            width={width}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()}
        />
    ;

    const myProspects =  (
        <MyProspects
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            width={width}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()}
        />
    ) 
    const myCustomers = (
        <MyCustomers
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            width={width}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()}
        />
    );

    const addNewProspect = (
        <AddNewProspect
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            width={width}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()}
        />
    );

    const searchPage = (
        <SearchProspects
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            width={width}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()}
        />
    );


    const reports = (
        <Reports
            salesperson={props.salesperson}
            msal_account={props.msal_account}
            role={props.role}
            width={width}
            salesperson_list={props.salesperson_list}
            prospects={props.prospects}
            customers={props.customers}
        />
    );

    const salespersonPage = props.role === 'operations'? (
        <Salespersons
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            readonly={props.impersonate === 'Y' ? 'Y' : 'N'}
            role={props.role}
            width={width}
            commodities={props.commodities}
            salesperson_list={props.salesperson_list}
        />
    ):null;

    let routes = [
        {
            path: '/',
            element: LAMportal,
        },
        {
            path: '/:id',
            element: LAMportal,
        },
        {
            path: '/dashboard',
            element: LAMportal,
        },
        {
            path: '/dashboard/:id',
            element: LAMportal,
        },
        {
            path: '/incomingPhone/:phone',
            element: LAMportal,
        },
        {
            path: '/addnewprospect',
            element: addNewProspect,
        },
        {
            path: '/search',
            element: searchPage,
        },
        {
            path: '/myprospects',
            element: myProspects,
        },
        {
            path: '/myprospects/:id',
            element: myProspects,
        },
        {
            path: '/myprospects/:id/:type',
            element: myProspects,
        },
        {
            path: '/mycustomers',
            element: myCustomers,
        },
        {
            path: '/mycustomers/:id',
            element: myCustomers,
        },
        {
            path: '/mycustomers/:id/:type',
            element: myCustomers,
        },
        {
            path: '/reports',
            element: reports,
        },
    ];

    if (props.role === 'operations') {
        routes.push({
            path: '/salespersons',
            element: salespersonPage,
        })
    }
    //#endregion Routes


    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props.msal_account && props.salesperson && process.env.REACT_APP_START_CLARITY === 'Y') {
            clarity.init(process.env.REACT_APP_CLARITY);
            if (clarity.hasStarted()) {
                clarity.identify('user_id', { userProperty: props.msal_account.username.replaceAll('@scotlynn.com', '') });
                clarity.consent();
                clarity.setTag('Name', props.msal_account.name);
                clarity.setTag('Role', props.role);
            }

            Userpilot.initialize(process.env.REACT_APP_USERPILOT);
            Userpilot.identify(props.msal_account.username, {
                name: props.msal_account.name,
                email: props.msal_account.username,
                role:props.role?props.role:'Unknown',
                rev_code: props.salesperson.revenue_code ? props.salesperson.revenue_code : 'Unknown',
                company: {
                    id: props.salesperson.company_id,
                    name: props.salesperson.company_id === 'TMS' ? 'SLC' : 'SLUSA',
                },
            });
            Userpilot.reload();
        }
    }, [props.salesperson, props.role, props.msal_account]);

    return (
        <div className="d-flex flex-column justify-content-between w-100">
            <div className="d-flex flex-column justify-content-start w-100">


                <Routes>
                    {routes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                    <Route path="*" element={<Navigate to="/dashboard" />}></Route>
                </Routes>
                <TimeoutSession show={openTimeout} onHide={() => setOpenTimeout(false)} />
            </div>

        </div>
    );
};
