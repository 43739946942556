import React, { Component } from 'react';
import { Button, InputGroup, Form, Alert, Container, Row, Col } from 'react-bootstrap';
import * as svg_icons from '../../Media/svg_exports';
import { format, addDays } from 'date-fns'
import * as requestFunctions from '../Functions/RequestFunctions';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as customerFunctions from '../Functions/CustomerFunctions'
import { MsalContext } from '@azure/msal-react';

export class PendingRequests extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            additional_remarks: '',
            clicked: false,
            processing_message: '',
            is_responding: false
        };
    }

    reset_vars() {
        this.setState({
            additional_remarks: '',
            clicked: false,
            processing_message: '',
            is_responding: false
        })
    }

    

    async escalate_account_request(request) {
        this.setState({ clicked: true, processing_message: 'Escalating request...' })
        try {
            const request_data = await requestFunctions.Escalate_Account_Request(request.company_id, request.id, request.requestor_sales_manager_id);
            console.log(request_data)
            let recipients = [];
            const subject_line = `Account Request For ${request.customer_id} Has Been Escalated`;
            let body = `${request.requestor_name} has escalated this account request to their General Manager (${request.requestor_sales_manager_name}) for review.`; 
            if (request.requestor_email_address) {
                recipients.push({ emailAddress: { address: request.requestor_email_address } })
            }
            if (request.requestee_email_address) {
                recipients.push({ emailAddress: { address: request.requestee_email_address } })
            }
            if (request.requestor_sales_manager_email) {
                recipients.push({ emailAddress: { address: request.requestor_sales_manager_email } })
            } 
            this.send_email(recipients, subject_line, body)       
            this.props.update_users_request('incoming', request_data);
            this.props.update_users_request('outgoing', request_data);
            this.props.update_index(0)
            this.reset_vars()
        } catch (e) {
            console.log(e)
            this.reset_vars()
        }
    }

    async resolve_account_request(request) {
        this.setState({ clicked: true, processing_message: 'Resolving request...' })
        try {
            const request_data = await requestFunctions.Resolve_Account_Request(request.company_id,request.id);
            let recipients = [];
            const subject_line = `Account Request For ${request.customer_id} Has Been Resolved`;
            let body = `${request.requestor_name} has resolved this account request for ${request.customer_name}.`; 
            if (request.requestor_email_address) {
                recipients.push({ emailAddress: { address: request.requestor_email_address } })
            }
            if (request.requestee_email_address) {
                recipients.push({ emailAddress: { address: request.requestee_email_address } })
            }
            this.send_email(recipients, subject_line, body) 
            this.props.update_index(0)
            this.props.update_users_request('incoming',request_data);
            this.reset_vars();
        } catch (e) {
            console.log(e)
            this.reset_vars()
        }
    }

    async switch_account_request(request) {
        this.setState({ clicked: true, processing_message: 'Switching account...' })
        try {
            const request_body = {
                company_id: request.company_id,
                crm_request_id: request.id,
                customer_id: request.customer_id,
                responder_id: request.responder_id,
                requestor_id: request.requestor_id,
                requestor_name: request.requestor_name,
                requestor_sales_manager_id: request.requestor_sales_manager_id
            }
            const request_data = await requestFunctions.Switch_Account_Request(request_body);
            let body = `Account request was approved and ${request.customer_name} was switched to ${request.requestor_name} by ${this.props.msal_account.name}.`;
            const subject_line = `Account (${request.customer_id}) Switched to ${request.requestor_id} by ${request.requestee_id}`;
            let recipients = [];
            if (request.requestor_email_address) {
                recipients.push({ emailAddress: { address: request.requestor_email_address } })
            }
            if (request.requestee_email_address) {
                recipients.push({ emailAddress: { address: request.requestee_email_address } })
            }
            this.props.release_account({company_id: request.company_id, id:request.customer_id})
            this.send_email(recipients, subject_line, body)
            this.props.update_index(0)
            this.props.update_users_request('incoming',request_data);
            this.reset_vars()
        } catch (e) {
            console.log(e)
            this.reset_vars()
        }
    }

    async cancel_request(request) {
        this.setState({ clicked: true, processing_message: 'Cancelling request...' })
        try {

            const request_data = await requestFunctions.Cancel_Request(request.id);
            let body = `${request.request_name} request was cancelled for ${request.customer_name} by ${this.props.msal_account.name}.`;
            const subject_line = `${request.request_name} Request Cancelled For ${request.customer_id}`;
            const recipients = [];
            if (request.requestor_email_address) {
                recipients.push({ emailAddress: { address: request.requestor_email_address } })
            }
            if (request.requestee_email_address) {
                recipients.push({ emailAddress: { address: request.requestee_email_address } })
            }

            this.send_email(recipients, subject_line, body)
            this.props.update_index(0)
            this.props.update_users_request('outgoing',request_data);
            this.reset_vars()
        } catch (e) {
            console.log(e)
            this.reset_vars()
        }
    }

    async respond_to_account_request(request) {
        this.setState({ clicked: true, processing_message: 'Responding to request...' })
        try {
            const request_data = await requestFunctions.Respond_To_Account_Request(request.company_id, request.id, this.state.additional_remarks);
            let recipients = [];
            const subject_line = `${request.requestee_id} Responded To Your Account Request`;
            let body = `${request.requestee_name} has responded to your account request for ${request.customer_name} with the following remarks: ${this.state.additional_remarks}`;
            if (request.requestor_email_address) {
                recipients.push({ emailAddress: { address: request.requestor_email_address } })
            }
            if (request.requestee_email_address) {
                recipients.push({ emailAddress: { address: request.requestee_email_address } })
            }
            await this.send_email(recipients, subject_line, body)
            this.props.update_index(0)
            this.props.update_users_request('outgoing', request_data);
            this.props.update_users_request('incoming', request_data);
            this.reset_vars()
        } catch (e) {
            console.log(e)
            this.reset_vars()
        }
    }

    async send_email(recipients, subject_line, body_text) {
        const email_company_id = this.props.current_user.company_id === 'TMS2' ? 'SLUSA' : 'SLC';
        const body = {
            message: {
                subject: `${email_company_id} ${process.env.REACT_APP_EMAIL_TITLE}: ${subject_line}` ,
                body: {
                    contentType: 'Text',
                    content: body_text,
                },
                toRecipients: recipients,
            },
        };
        const inst = this.context.instance;
        const acct = this.context.accounts;
        await utilityFunctions.Send_Email(inst, acct, body);
    }

    display_account_request_options(current_request) {
        if (this.props.role === 'lam') {
            const current_salesperson_id = this.props.current_user ? this.props.current_user.salesperson_id : '';
            const request_type = this.props.request_type;
            if (current_request && request_type) {
                if (request_type === 'ACCTREQ' && current_request.responder_id === current_salesperson_id) {
                    if (current_request.requestee_id === current_salesperson_id) {
                        return 'requestee'
                    } else if (current_request.requestor_id === current_salesperson_id) {
                        return 'requestor'
                    }
                } else {
                    return "";
                }
            } else {
                return "";
            }
        } else {
            return null;
        }
       
    }

    render() {
        const { additional_remarks, clicked, is_responding } = this.state;
        const index = this.props.index;
        const request_type = this.props.request_type;
        let current_requests_list = this.props.current_requests_list ? this.props.current_requests_list : [];
  
        const current_request = current_requests_list? current_requests_list.length > 0 ? current_requests_list[index] : null:null;
        const formatted_additional_remark = this.props.current_user && additional_remarks ? `\n${this.props.current_user.salesperson_id}: [${format(new Date(), 'MM-dd-yyyy HH:mm:ss')}] ${additional_remarks}` : '';
        const current_salesperson_id = this.props.current_user ? this.props.current_user.salesperson_id : '';

        let requestor_name = '';
        let requestee_name = '';
        let requestor_manager_name = '';
        if (current_request) {
            requestor_name = current_request.requestor_name;
            requestee_name = current_request.requestee_name;
            requestor_manager_name = current_request.requestor_sales_manager_name;
            if (current_request.requestor_rev_code) {
                requestor_name = `${requestor_name} (${current_request.requestor_rev_code})`
            }
            if (current_request.requestee_rev_code) {
                requestee_name = `${requestee_name} (${current_request.requestee_rev_code})`
            }
            if (request_type === 'ESTBCRED') {
                requestee_name = 'CREDIT'
            }
        }
        // Buttons
        //#region

        const respond_button =
            <Button
                className="text-xs p-1 align-self-center text-nowrap flex-grow-1 ms-1"
                variant={clicked ? `outline-success` : 'success'}
                disabled={clicked || !additional_remarks}
                onClick={() => this.respond_to_account_request(current_request)}>
                {clicked ?'Sending Response...': 'Send Response'}
            </Button>

        const cancel_respond_button =
            <Button
                className="text-xs p-1 align-self-center text-nowrap flex-grow-1 me-1"
                variant={clicked ? `outline-secondary` : 'secondary'}
                disabled={clicked}
                onClick={() => this.setState({ is_responding: false })}>
                {clicked ? 'Cancelling Response...' : 'Cancel Response'}
            </Button>


        const respond_button_toggle = is_responding ?
            cancel_respond_button :
            this.display_account_request_options(current_request) === 'requestee' ?
            <Button
                className="text-xs p-1 align-self-center text-nowrap flex-grow-1 me-1"
                variant={clicked ? `outline-warning` : 'warning'}
                disabled={clicked || is_responding}
                onClick={() => this.setState({ is_responding: true })}>
               Respond to Request
            </Button>:null
                
              
        const switch_button = is_responding ?
            respond_button :
            this.display_account_request_options(current_request) === 'requestee' ?
                <Button
                    className="text-xs p-1 align-self-center text-nowrap flex-grow-1 ms-1"
                    variant={clicked ? `outline-success` : 'success'}
                    disabled={clicked || is_responding}
                    onClick={() => this.switch_account_request(current_request)}>
                    {`Switch to ${current_request ? current_request.requestor_name : ''}`}
                </Button> : null;

        const escalate_button =
            this.display_account_request_options(current_request) === 'requestor' ?
                <Button
                    className="text-xs p-1 align-self-center text-nowrap flex-grow-1 me-1"
                    variant={clicked ? `outline-warning` : 'warning'}
                    disabled={clicked || is_responding}
                    onClick={() => this.escalate_account_request(current_request)}>
                    Escalate to My GM
                </Button> : null;

        const resolve_button =
            this.display_account_request_options(current_request) === 'requestor' ?
                <Button
                    className="text-xs p-1 align-self-center text-nowrap flex-grow-1 me-1"
                    variant={clicked ? `outline-success` : 'success'}
                    disabled={clicked || is_responding}
                    onClick={() => this.resolve_account_request(current_request)}>
                    Resolve Request
                </Button> : null;

        const cancel_button =
            <Button
                className="text-xs p-1 align-self-center text-nowrap flex-grow-1 "
                variant={clicked ? `outline-danger` : 'danger'}
                disabled={clicked || is_responding}
                onClick={() => this.cancel_request(current_request)}>
                Cancel Request
            </Button>;


        const options_button_div =
            current_request && request_type === 'ACCTREQ' ?
                current_request.responder_id === current_salesperson_id ?
                    <div className="d-flex flex-row justify-content-between w-100 ">
                    
                        {respond_button_toggle}
                        {switch_button}
                        {escalate_button}
                        {resolve_button}
                        {this.props.direction === 'Outgoing' ? cancel_button : null}
                    </div> :
                    <div className="d-flex flex-row justify-content-between w-100 mt-1">
                        <Alert variant="secondary" className="w-100">
                            <p className="text-xs">It is the other LAM's turn to respond. No action is required at this time.</p>
    
                        </Alert>
                    </div> :
                null;

        const show_cancel_button = current_request && request_type !== 'ACCTREQ' ?
            current_request.requestor_id === current_salesperson_id ?
                cancel_button :
                null :
            null;
    



        //Inputs
        //#region
        const disabled_fields = (field, val) =>
            <InputGroup className="mb-2">
                <InputGroup.Text className="text-xs" style={{width:'20%'}}>
                    {field}:
                </InputGroup.Text>
                <InputGroup.Text className="text-xs bg-white text-start" style={{ width: '80%', whiteSpace: 'pre-wrap' }}>
                    {val}
                </InputGroup.Text >
            </InputGroup>;

        const responder_field = (field, val) =>
            <InputGroup className="mb-2 ">
                <InputGroup.Text className="text-xs border border-danger text-bold" style={{ width: '20%' }}>
                    {field}:
                </InputGroup.Text>
                <InputGroup.Text className="text-xs bg-white text-start border border-danger text-bold" style={{ width: '80%', whiteSpace: 'pre-wrap' }}>
                    {val}
                </InputGroup.Text >
            </InputGroup>;

        const additional_remark_input = this.props.current_user ?
            <InputGroup className="my-3">
                <Form.Control
                    as="textarea"
                    isInvalid={!additional_remarks}
                    disabled={ clicked}
                    onChange={(e) => this.setState({ additional_remarks:e.target.value })}
                    className="text-xs bg-white text-start"
                    style={{ width: '100%', whiteSpace: 'pre-wrap' }}
                    value={additional_remarks}
                    placeholder="Enter response here..."
                />
        
            </InputGroup> :
            null;
        //#endregion

        let pending_body = current_request? <div className="d-flex flex-column flex-grow-1 justify-content-between px-1">
            <div className="d-flex flex-column pt-2">
                {disabled_fields('Requested', current_request.date_requested_str)}
                {current_request.responder_id === current_request.requestor_id ?
                    responder_field('Requestor', requestor_name) :
                    disabled_fields('Requestor', requestor_name)
                }
                {current_request.responder_id === current_request.requestee_id ?
                    responder_field('Requestee', requestee_name) :
                    disabled_fields('Requestee', requestee_name)
                }

                <InputGroup className="mb-2">
                    <InputGroup.Text className="text-xs" style={{ width: '20%' }}>
                        Account:
                    </InputGroup.Text>
                    <InputGroup.Text className="text-xs bg-white text-start " style={{ width: '80%', whiteSpace: 'pre-wrap' }}>
                        <bdi className="underlined hover-over text-primary" onClick={() => this.props.view_account(current_request.company_id, current_request.customer_id)}>
                            {`${current_request.customer_name} (${current_request.customer_id})`}
                        </bdi>

                    </InputGroup.Text >
                </InputGroup>
                {disabled_fields('Remarks', (current_request.remarks ? current_request.remarks.replaceAll('&#xA;', '\n\n') : '') + formatted_additional_remark)}
                {is_responding ? additional_remark_input : null}
                {options_button_div}
                {show_cancel_button}
            </div>
            <label className="w-100 text-center text-lg align-self-center my-3"> {index > -1 ? `${index + 1} / ${current_requests_list.length}` : null}</label>
        </div>:null



        return (current_request ?
            <div className="d-flex flex-row justify-content-between w-100 align-self-center" >
                <label className="hover-over align-self-center" style={{ width: '2rem' }}
                    onClick={() => this.props.update_index(index > 0 && !clicked ? (index - 1) : index)}>
                    {index > 0 ? svg_icons.left_chevron : null}
                </label>
                {pending_body}

                <label className="hover-over align-self-center" style={{ width: '2rem' }}
                    onClick={() => this.props.update_index(index < current_requests_list.length - 1 && !clicked ? (index + 1) : index)}>
                    {index < current_requests_list.length - 1 ? svg_icons.right_chevron : null}
                </label>
                
            </div> : null)
    }
}