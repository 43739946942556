import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton,  Form, InputGroup, Modal, ButtonGroup } from 'react-bootstrap';

import { format } from 'date-fns'
import * as svg_icons from '../../Media/svg_exports'
import { MsalContext } from '@azure/msal-react';

export class RemarksSearchModal extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            clicked:false
        };
    }

    update_remarks(e) {
        this.props.update_search_by_remark(e.target.value)
    }

    handleKeyPress(event) {
        if (!this.props.clicked) {
            if (event.key === 'Enter') {
                event.preventDefault();
               this.props.get_list();
            }
        }
    }


    render() {
        const { } = this.state;


        return (
            <Modal
                {...this.props}
                size="xl"
                className="bg-modal-focus"
                contentClassName="light-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                    <label className="hover-over w-25" onClick={() => this.props.onHide()}>{svg_icons.cancel_icon}</label>
                    <div className="d-flex flex-column justify-content-center w-50 text-center p-0 pt-2">
                        <h5 className="">Search By Activity Remark</h5>
                    </div>

                    <div className="w-25"></div>
                </Modal.Header>

                    <Modal.Body className="d-flex flex-column pb-3">
                    <small className="align-self-center mb-1">You can now search by activty remarks! Remark must be a minimum of 3 characters and contain no special characters (such as !?$%#).</small>
                    <div className="d-flex flex-column w-50 align-self-center mt-2">
                        <InputGroup className="overview-tab-input-group mb-3">
                            <InputGroup.Text className="text-wrap text-sm p-1 text-bold pe-3 w-25">
                                Company
                            </InputGroup.Text>
                            <Form.Select
                                disabled={this.props.clicked}
                                isInvalid={!this.props.remark_company_id }
                                onChange={(e) => this.props.update_remark_company_id(e.target.value)}
                                className="text-sm bg-white text-start w-75"
                                value={this.props.remark_company_id}
                            >
                                <option value="">Select</option>
                                <option value="TMS">SLC</option>
                            <option value="TMS2">SLUSA</option>
                            </Form.Select>

                        </InputGroup>
                            <InputGroup className="overview-tab-input-group mb-3">
                                <InputGroup.Text className="text-wrap text-sm p-1 text-bold pe-3 w-25">
                                    Search Remark
                                </InputGroup.Text>
                                <Form.Control
                                disabled={this.props.clicked}
                               
                                isInvalid={this.props.search_by_remark.length<3}
                                    onChange={(e) => this.props.update_search_by_remark(e.target.value)}
                                className="text-sm bg-white text-start w-75" 
                                max={ 25}
                                    placeholder="Must be min. 3 characters"
                                    value={this.props.search_by_remark}
                            />
                         
                        </InputGroup>
                        <Button
                            className="text-xs ign-self-center"
                            onClick={() => this.props.get_list()}
                            disabled={this.props.clicked}
                            variant={this.props.clicked ? 'outline-success' : 'success'} >
                            {this.props.clicked ? 'Searching...' : 'Search'}

                        </Button>
                        </div>
                    </Modal.Body> 

            </Modal>

        )
    }



}

