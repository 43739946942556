import React, { Component } from 'react';
import { Button,Form, InputGroup, Modal} from 'react-bootstrap';
import * as requestFunctions from '../../Functions/RequestFunctions';
import * as utilityFunctions from '../../Functions/UtilityFunctions';
import * as svg_icons from '../../../Media/svg_exports'
import { MsalContext } from '@azure/msal-react';

export class AccountRequestModal extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const current_user = this.props.current_user;
        this.state = {
            new_request: {
                company_id: selected_account ? selected_account.company_id : '',
                requestor_id: current_user ? current_user.salesperson_id : '',
                requestee_id: selected_account ? selected_account.salesperson_id : '',
                remarks: ``,
                customer_id: selected_account ? selected_account.id : ''
            },

            clicked: false

        };
    }


    onHide() {
        this.reset_new_request()
        this.props.onHide();
    }

    reset_new_request() {
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const current_user = this.props.current_user;
        if (selected_account && current_user) {
            this.setState({
                new_request: {
                    company_id: selected_account.company_id,
                    requestor_id: current_user.salesperson_id,
                    requestee_id: selected_account.salesperson_id,
                    remarks: ``,
                    customer_id: selected_account.id
                },
                clicked: false
            })
        } else {
            this.setState({
                new_request: {
                    company_id:  '',
                    requestor_id: '',
                    requestee_id: '',
                    remarks: ``,
                    customer_id:  ''
                },
                clicked: false
            })
        }
        
    }

    handle_remarks_update(e) {
        let new_request = this.state.new_request;
        new_request.remarks = e.target.value;
        this.setState({ new_request: new_request })
    }

    async post() {
        this.setState({ clicked: true });
        const selected_account_data = this.props.selected_account_data; 
        const new_request = this.state.new_request;
        try {
            let request_list = selected_account_data ? selected_account_data.requests : [];
            const request_data = await requestFunctions.Request_Account(new_request);
            await this.email(request_data, new_request.remarks);
            request_list.push(request_data)
            this.props.add_users_request('outgoing', request_data);
            this.props.update_selected_account_data('requests', request_list)
            this.onHide()
        } catch (e) {
            console.log(e)
            this.reset_new_request()
            this.setState({ issue :e.message})
        }
    }

    async email(request, remarks) {
        try {
            const email_company_id = this.props.current_user.company_id === 'TMS2' ? 'SLUSA' : 'SLC';
            const subject_line =
                process.env.REACT_APP_EMAIL_TITLE +
                ': ' +
                email_company_id +
                ' Account Request For ' +
                request.customer_id;
            let message_text = `Account requested by ${this.props.current_user.salesperson_name} with the following remarks: \n\n${remarks}`;



            message_text = `${message_text} \n\nView request at: ${process.env.REACT_APP_WEB_URL}/dashboard/${request.id}`
            let recipients = [];
            if (request.requestor_email_address) {
                recipients.push({
                    emailAddress: {
                        address: request.requestor_email_address
                    },
                })
            }
            if (request.requestee_email_address) {
                recipients.push({
                    emailAddress: {
                        address: request.requestee_email_address
                    },
                })
            }
    
          
            const message_body = {
                message: {
                    subject: subject_line,
                    body: {
                        contentType: 'Text',
                        content: message_text,
                    },
                    toRecipients: recipients,
                },
            }
            const inst = this.context.instance;
            const acct = this.context.accounts;
            await utilityFunctions.Send_Email(inst, acct, message_body)
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { new_request, clicked } = this.state;
        const current_user = this.props.current_user
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const requests = selected_account_data ? selected_account_data.requests ? selected_account_data.requests : [] : [];
        const pending_request = requests.length > 0 ? requests.find(s => s.status === 'P' && s.request_type === 'ACCTREQ') : null;

        let recipient_email = '';
        if (this.props.salesperson_list && selected_account) {
            let requestee_salesperson = this.props.salesperson_list.find(s => s.salesperson_id === selected_account.salesperson_id);
            if (requestee_salesperson) {
                recipient_email = requestee_salesperson.email_address
            }
        }


        const recipient = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold " style={{ width: '5rem' }}>
                Recipient
            </InputGroup.Text>
            <InputGroup.Text className="text-sm bg-white text-start flex-grow-1 ">
                {recipient_email}
            </InputGroup.Text>
        </InputGroup>;

        const requestor = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold " style={{ width: '5rem' }}>
                Requestor
            </InputGroup.Text>
            <InputGroup.Text className="text-sm bg-white text-start flex-grow-1 ">
                {current_user ? current_user.salesperson_id : 'No Salesperson Found'}
            </InputGroup.Text>
        </InputGroup>;

        const account = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold " style={{ width: '5rem' }}>
                Account
            </InputGroup.Text>
            <InputGroup.Text className="text-sm bg-white text-start flex-grow-1 ">
                {selected_account ? selected_account.name : 'No Account Found'}
            </InputGroup.Text>
        </InputGroup>;


        const additional_remarks_form = new_request? <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-wrap text-sm p-1 text-bold" style={{ width: '5rem' }}>
                Remarks
            </InputGroup.Text>
            <Form.Control
                disabled={clicked}
                isInvalid={!new_request.remarks}
                onChange={(e) => this.handle_remarks_update(e)}
                as="textarea"
                name='remarks'
                className="text-sm bg-white text-start flex-grow-1"
                placeholder="Required"
                value={new_request.remarks}
            />
        </InputGroup>:null



        return (
            <Modal
                {...this.props}
                size="xl"
                className="bg-modal-focus"
                fullscreen="lg-down"
                contentClassName="light-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                    <label className="hover-over w-25" onClick={() => this.onHide()}>{svg_icons.cancel_icon}</label>
                    <div className="d-flex flex-column justify-content-center w-50 text-center p-0 pt-2">
                        <h5 className="">Request Account</h5>
                        {pending_request ?
                            <small className="text-danger align-self-center fw-bold">**This account already has a pending request and cannot be requested at this time**</small>
                            : null}


                    </div>

                    <div className="w-25"></div>
                </Modal.Header>
                {selected_account ?
                    <Modal.Body className="d-flex flex-column">

                        <h6 className='align-self-center tile-title'>Submit Account Request for {selected_account.name}</h6>
                        {pending_request ?
                            <small className="align-self-center mb-2">{`[Account was requested on ${pending_request.date_requested_str} by ${pending_request.requestor_name}]`}</small>
                            : null}
                        <div className="d-flex flex-column w-75 align-self-center mt-2">
                            {recipient}
                            {requestor}
                            {account}
                            {additional_remarks_form}
                            {new_request ?
                                <Button
                                    className="text-sm w-25 align-self-center mt-3 mb-3"
                                    onClick={() => this.post()}
                                    disabled={clicked || !new_request.remarks}
                                    variant={clicked ? 'outline-success' : 'success'} >
                                    {clicked ? 'Sending...' : 'Send Request'}

                                </Button> :
                                null
                            }
                      
                        </div>
                    </Modal.Body> :
                    null
                }




            </Modal>

        )
    }



}

