import { Col, Container, Form, Row } from 'react-bootstrap';

import '../.././App.scss';

function ComparatorInput({ ...props }) {
    const order_comparator_list = [
        { label: 'Select', value: '' },
        { label: 'Greater than', value: 'greater than' },
        { label: 'Less than', value: 'less than' },
        { label: 'Between', value: 'between' },
    ];
    return (
        <Container
            id={props.id_group + '-input-group'}
            className="border rounded"
            style={
                props.search_filter[props.comparator_name] === ''
                    ? { filter: 'opacity(0.7)' }
                    : { filter: 'opacity(1)' }
            }
            fluid="xs"
        >
            <Row>
                <Col className="pt-1 pb-0">
                    <label className="text-muted text-xs ps-2 opacity-70">{props.field_title}</label>
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <Form.Select
                        onChange={props.handleFilterChange}
                        name={props.comparator_name}
                        className="shadow-none border-0 bg-transparent text-xs"
                        value={props.search_filter[props.comparator_name]}
                        id={props.comparator_name}
                    >
                        {order_comparator_list.map((d, index) => (
                            <option value={d.value} label={d.label} key={index} />
                        ))}
                    </Form.Select>
                </Col>
                {props.search_filter[props.comparator_name] === '' ? null : (
                    <Col xs={props.search_filter[props.comparator_name] === 'between' ? 4 : 8}>
                        <Form.Control
                            onChange={props.handleFilterChange}
                            name={props.low_input_name}
                            className="shadow-none border-0 bg-transparent text-xs"
                            isInvalid={
                                new Date(props.search_filter[props.low_input_name]) > new Date() ||
                                (new Date(props.search_filter[props.low_input_name]) >
                                    new Date(props.search_filter[props.high_input_name]) &&
                                    props.search_filter[props.comparator_name] === 'between')
                            }
                            title={
                                new Date(props.search_filter[props.low_input_name]) > new Date()
                                    ? 'Start Date cannot be in the future'
                                    : new Date(props.search_filter[props.low_input_name]) >
                                            new Date(props.search_filter[props.high_input_name]) &&
                                        props.search_filter[props.comparator_name] === 'between'
                                      ? 'Start Date cannot be after End Date'
                                      : ''
                            }
                            value={
                                props.search_filter[props.comparator_name] === ''
                                    ? ''
                                    : props.search_filter[props.low_input_name]
                            }
                            type="date"
                            onKeyPress={props.handleKeyPress}
                            id={props.low_input_name}
                        />
                    </Col>
                )}

                {props.search_filter[props.comparator_name] === 'between' ? (
                    <Col xs={4}>
                        <Form.Control
                            onChange={props.handleFilterChange}
                            className="shadow-none border-0 bg-transparent text-xs"
                            name={props.high_input_name}
                            title={
                                new Date(props.search_filter[props.high_input_name]) > new Date()
                                    ? 'End Date cannot be in the future'
                                    : new Date(props.search_filter[props.low_input_name]) >
                                        new Date(props.search_filter[props.high_input_name])
                                      ? 'Start Date cannot be after End Date'
                                      : ''
                            }
                            isInvalid={
                                new Date(props.search_filter[props.high_input_name]) > new Date() ||
                                new Date(props.search_filter[props.low_input_name]) >
                                    new Date(props.search_filter[props.high_input_name])
                            }
                            value={
                                props.search_filter[props.comparator_name] === ''
                                    ? ''
                                    : props.search_filter[props.high_input_name]
                            }
                            type="date"
                            onKeyPress={props.handleKeyPress}
                            id={props.high_input_name}
                        />
                    </Col>
                ) : null}
            </Row>
        </Container>
    );
}

export default ComparatorInput;
