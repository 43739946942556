import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import LoadingIcons from 'react-loading-icons';

export class TimeoutSession extends Component {
    constructor(props) {
        super(props);

        this.state = {
            additional_details: '',
        };
    }

    render() {
        const { additional_details } = this.state;

        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen="md-down"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName="light-modal"
                >
                    <Modal.Header className="w-100">
                        <Container fluid="xs" className="w-100">
                            <Row>
                                <Col xs={2}>
                                    <CloseButton title="Close" onClick={() => this.props.onHide()} />
                                </Col>
                                <Col xs={8}>
                                    <h4 className="w-100 text-center text-muted">Session Expired</h4>
                                </Col>
                                <Col xs={2}></Col>
                            </Row>
                        </Container>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Row>
                            <Col xl={12}>
                                <Alert variant="primary">Your session has expired due to inactivity.</Alert>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
