import React, { Component } from 'react';
import { Alert, Button, Card, Col, Container, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';

export class PossibleMatchesResults extends Component {
    static displayName = PossibleMatchesResults.name;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: 'L',
            sort_field:'Name'
        };
    }

    get_headers() {
        const headers = [
            'Verified',
            'Company',
            'Name',
            'Code',
            'LAM',
            'Type',
            'Address',
            'Zip',
            'Phone',
            'Website'
        ];
        let headers_list = [];
        headers.map(d => headers_list.push({ column_title: d, sort_direction: this.state.sort_direction, is_sortable: 'Y', header_style: 'text-xs' }))
        headers_list.push({ column_title: '', sort_direction: this.state.sort_direction, is_sortable: 'Y', header_style: '' })
        return headers_list;
    }

    get_entered_headers() {
        const headers = [
            'Company',
            'Name',
            'LAM',
            'Address',
            'Zip',
            'Phone',
            'Website'
        ];
        let headers_list = [];
        headers.map(d => headers_list.push({ column_title: d, sort_direction: this.state.sort_direction, is_sortable: 'Y', header_style: 'text-xs' }))
        return headers_list;
    }

    get_cell_style(cell_value, property) {
        const new_customer_property = this.props.new_customer ? this.props.new_customer[property]? this.props.new_customer[property].toLowerCase():'' : '';
        const new_cell_value = cell_value ? cell_value.toLowerCase() : '';
        const customer_property_split = new_customer_property ? new_customer_property.split(' ') : [];
 
        const no_match_alert =
            <Alert className="text-xs m-0 p-0 bg-transparent border-0 shadow-0" variant="light">
                <bdi>{cell_value}</bdi>
            </Alert>

        const match_alert = <Alert className="text-xs m-0 p-1" variant="warning">
            <bdi>{cell_value}</bdi>
        </Alert>


            if (new_customer_property && new_cell_value) {
                if (new_cell_value.includes(new_customer_property)) {
                    return match_alert;
                }
                else if (customer_property_split.length > 0 ) {
                    if (customer_property_split.find(s => new_cell_value.includes(s))) {
                        return (<Alert className="text-xs m-0 p-0 bg-transparent border-0 shadow-0" variant="light">
                            {cell_value.split(' ').map((d, index) =>
                                <bdi key={index} className={`${customer_property_split.includes(d.toLowerCase()) ? 'bg-warning-alert rounded p-1' : ''} me-1 `}>{d}</bdi>
                            )}
                          
                        </Alert>)
                    } else {
                        return no_match_alert;
                    }
                }
                else {
                    return no_match_alert;
                }
            } else {
                return no_match_alert;
            }


      


    }








    render() {
        const verified_matches = this.props.verified_matches ? this.props.verified_matches : [];
        let possible_matches = this.props.possible_matches ? this.props.possible_matches : [];
        const current_user = this.props.salesperson ? this.props.salesperson : null;
        const new_customer = this.props.new_customer ? this.props.new_customer : null;
        let list_to_verify = this.props.list_to_verify ? this.props.list_to_verify : [];
        let no_verify_list = this.props.no_verify_list ? this.props.no_verify_list : [];

        const verified_button = (d) =>
            <Form.Control
                className="bootstrap-matches-checkbox"
                type="checkbox"
                isInvalid={!verified_matches.includes(d)}
                isValid={verified_matches.includes(d)}
                onClick={(e) => this.props.add_to_verified_matches(e, d)}
            />

        const view_button = (d) =>
            <Button
                className="align-self-center text-xs"
                variant="secondary"
                size="sm"
                onClick={() => this.props.view_account(d.company_id, d.id)}
            >
                View
            </Button>


        let data = [];
        let entered_data=[]
        let cells_style = 'text-xs text-nowrap pe-2';
        if (new_customer) {
            entered_data.push({
                row_style: '',
                cells: [
                    { cell: new_customer.company_id === 'TMS2' ? 'SLUSA' : 'SLC', cell_style: 'text-sm' },
                    { cell: new_customer.name, cell_style: 'text-sm' },
                    { cell: new_customer.salesperson_id, cell_style: 'text-sm' },
                    { cell: `${new_customer.address1} ${new_customer.address2 ? `, ${new_customer.address2}` : ''}`, cell_style: 'text-sm' },
                    { cell: new_customer.zip_code, cell_style: 'text-sm' },
                    { cell: new_customer.main_phone, cell_style: 'text-sm' },
                    { cell: new_customer.website_url, cell_style: 'text-sm' },
                ],
                data: new_customer,
            })
        }
       
        list_to_verify.map(d =>
            data.push({
                row_style: current_user ? d.company_id === current_user.company_id ? '' : 'opacity-75':'',
                cells: [
                    { cell: verified_button(d), cell_style: cells_style },
                    { cell: d.company_id === 'TMS2' ? 'SLUSA' : 'SLC', cell_style: cells_style },
                    { cell: this.get_cell_style(d.name, 'name'), cell_style: '' },
                    { cell: this.get_cell_style(d.id, 'id'), cell_style: '' },
                    { cell: this.get_cell_style(d.salesperson_id, 'salesperson_id'), cell_style: '' },
                    { cell: d.type_of, cell_style: '' },
                    { cell: this.get_cell_style(d.address1, 'address1'), cell_style:'' },
                    { cell: this.get_cell_style(d.zip_code, 'zip_code'), cell_style: '' },
                    { cell: this.get_cell_style(d.main_phone, 'main_phone'), cell_style: '' },
                    { cell: this.get_cell_style(d.website_url, 'website_url'), cell_style:'' },
                    { cell: view_button(d), cell_style: '' },
                ],
                data: d,
            })
        );
        no_verify_list.map(d =>
            data.push({
                row_style:'opacity-75',
                cells: [
                    { cell: <div></div>, cell_style: cells_style },
                    { cell: d.company_id === 'TMS2' ? 'SLUSA' : 'SLC', cell_style: cells_style },
                    { cell: this.get_cell_style(d.name, 'id'), cell_style: '' },
                    { cell: this.get_cell_style(d.id, 'id'), cell_style: '' },
                    { cell: this.get_cell_style(d.salesperson_id, 'salesperson_id'), cell_style: '' },
                    { cell: d.type_of, cell_style: '' },
                    { cell: this.get_cell_style(d.address1, 'address1'), cell_style: '' },
                    { cell: this.get_cell_style(d.zip_code, 'zip_code'), cell_style: '' },
                    { cell: this.get_cell_style(d.main_phone, 'main_phone'), cell_style: '' },
                    { cell: this.get_cell_style(d.website_url, 'website_url'), cell_style: '' },
                    { cell: view_button(d), cell_style: '' },
                ],
                data: d,
            })
        );

            return (
                <div className='d-flex flex-column'>
                    <Alert variant={list_to_verify.length === verified_matches.length ? 'success' : 'danger'}>
                        {list_to_verify.length === verified_matches ?
                            <label className="text-sm">
                                `All accounts in the list below have been verified and you can now proceed to the next step.`
                            </label> :
                            <label className="text-sm d-flex flex-column">
                                <div className="d-flex flex-column mb-3">
                                    <label className='portal-tile-title'>Information Entered:</label>
                                    <SortableTable
                                        onSort={(prop) => null}
                                        sort_field={null}
                                        row_click={(prop) => null}
                                        table_headers={this.get_entered_headers()}
                                        table_data={entered_data}
                                    />
                                </div>
                                {list_to_verify.length ?
                                    <div className="d-flex flex-column">
                                        <bdi>{`Review the list below and verify that the prospect does not already exist by checking the red box to the left of the "Company" column.`}</bdi>

                                        <bdi>{`Verified Accounts: ${verified_matches.length} / ${list_to_verify.length}`}</bdi>
                                    </div> :
                                    <bdi>No possible matches exist</bdi>
                                }
                               
                             
                            </label>
                        }
                     
                       
                    </Alert>
                    {data.length ?
                        <div style={{ maxHeight: '35rem', overflow: 'auto' }}>
                            <SortableTable
                                onSort={(prop) =>
                                    this.setState({
                                        sort_field: prop,
                                        sort_direction: this.state.sort_direction === 'H' ? 'L' : 'H',
                                    })
                                }
                                sort_field={this.state.sort_field}
                                row_click={(prop) => null}
                                table_headers={this.get_headers()}
                                table_data={data}
                            />
                        </div> :
                        null
                    }
                   
                    
                </div>
               )
        }

       
    
}
