import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState, useRef } from 'react';
import {Stack, Overlay} from 'react-bootstrap';
import header_image from '../../Media/S_logo.png';
import * as svg_icons from '../../Media/svg_exports'
import AddProspectButton from './Components/AddProspectButton';
import LocationButton from './Components/LocationButton';
import LogoutButton from './Components/LogoutButton';
import NotificationButton from './Components/NotificationButton';
import { CreditNotifications } from './Components/CreditNotifications';
import { ProfilePic } from './Components/ProfilePic';

export const NavMenu = ({ ...props }) => {
    const { instance, accounts } = useMsal();
    const [width, setWidth] = useState(window.innerWidth);
    const [toggleMenu, setToggleMenu] = useState(false);


    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let request_notifications = [];
    let request_loaded = 'N'
    if (props.incoming_requests) {
        if (props.incoming_requests.list) {
            request_notifications = props.incoming_requests.list.filter(s => s.status === 'P');
           
        }
        if (props.action_requests) {
            if (props.action_requests.list && props.role === 'gm') {
                request_notifications = request_notifications.concat(props.action_requests.list.filter(s => s.status === 'P'));

            }
        }
        request_loaded = props.incoming_requests.loaded
    }
   
    


    const user_routes = props.user_routes;
    let routes = [];
    if (user_routes) {
        for (let i = 0; i < user_routes.length; i++) {
           const c_route = user_routes[i];
            if (c_route.name === 'My Prospects') {
                routes.push({ name: c_route.name, location: c_route.path, num: props.prospects.list.length, loaded: props.prospects.loaded })
            }
            else if (c_route.name === 'My Customers') {
                routes.push({ name: c_route.name, location: c_route.path, num: props.customers.list.length, loaded: props.customers.loaded })
            } else {
                routes.push({ name: c_route.name, location: c_route.path, num:null })
            }
        }
        if (props.salesperson && props.role) {
            if (props.role === 'gm' && props.salesperson.company_id === 'TMS') {
                routes.push({ name: 'Salespersons', location: '/salespersons', num: null })
            }
        }
    }
   

    const profile_pic = <ProfilePic
        profile_photo={props.profilephoto}
        role={ props.role}
        instance={instance}
        salesperson={props.salesperson}
        msal_account={props.msal_account}
        prospect_actions={props.prospect_actions}
    />;

    const full_name = <label className="text-sm text-white align-self-center d-flex flex-column">

        <bdi>{props.msal_account.name}</bdi> 
        <bdi className="italic text-xs">{props.salesperson.salesperson_id}</bdi>
    </label> 


    const add_prospect_button = AddProspectButton();


    const notification_button = <CreditNotifications
        requests={request_notifications}
        loaded={request_loaded}
    />

    const toggle_menu_button = width < 800 ? 

        <label
            className="text-white hover-over align-self-center fw-bold"
            style={{transform:'scale(1.5)'}}
            onClick={() => setToggleMenu(!toggleMenu)}>{toggleMenu ? svg_icons.right_chevron : svg_icons.down_chevron}</label>

    : null;

    const header_logo =
        <div className="d-flex flex-row justify-content-start align-self-start" style={{ width: '6rem', paddingBottom: '3px', paddingTop: '3px' }}>
        {toggle_menu_button}      
        <img src={header_image} style={{ width: '2rem', height: '2rem' }} className="align-self-end" />
        <h6 className="text-white align-self-end p-0 m-0 text-bold">CRM</h6>
    </div>;

    function MainNavbar() {
        const orientation = width < 1200 ? 'column' : 'row';
        const flex = width < 1200 ? 'start' : 'center';
 

        return (
 
            <div className={`bg-dark d-flex flex-${orientation} justify-content-between ps-3 pe-3 w-100`} style={{ paddingBottom: '5px' }}>
                <div className={`d-flex flex-${orientation} justify-content-start w-50 align-self-${flex}`}>
                    {header_logo}
                    {!toggleMenu ? routes.map((d,index) =>
                        <Stack gap={2} className="align-self-center pt-1" key={ index}>
                            {LocationButton(d)}
                        </Stack>
                    

                    ):null}
                </div>
                {!toggleMenu ?
                    <div className={`d-flex flex-${orientation} justify-content-end w-50 align-self-${flex}`}>
     
                        {add_prospect_button}
                        {notification_button}
                        {full_name}
                        {profile_pic}
         
                    </div> :
                    null
                }
              
              
            </div>
        );
    }

    return <MainNavbar />;
};
