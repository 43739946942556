import React, { Component } from 'react';
import { Card, Alert, Button, InputGroup, Form, ButtonGroup } from 'react-bootstrap';
import { Userpilot } from 'userpilot';
export class GMRequestsStatusSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true
        };
    }

    get_num_values_from_list(request_type) {
        let num = 0;
        if (request_type === 'NACCTREQ') {

            if (this.props.no_action_request_list) {
                num = this.props.no_action_request_list.length;
            }
        }
        else {
            if (this.props.incoming_requests_list && this.props.direction==='Incoming') {
                this.props.incoming_requests_list.map(d => {
                    if (d.request_type === request_type) {
                        num = num + 1;
                        return
                    }
                })
            }
            if (this.props.outgoing_requests_list && this.props.direction === 'Outgoing') {
                this.props.outgoing_requests_list.map(d => {
                    if (d.request_type === request_type) {
                        num = num + 1;
                        return
                    }
                })
            }
        }
 
 
    
        return num;
    }

    get_requests_nums() {
        let num_conversion = this.get_num_values_from_list('CREQUEST')
        let num_extensions = this.get_num_values_from_list('EREQUEST');
        let num_accounts =this.get_num_values_from_list('ACCTREQ');
        let num_prechecks = this.get_num_values_from_list('ESTBCRED');
        let num_no_action_accts = this.get_num_values_from_list('NACCTREQ');


        //const num_conversion = list.filter(s => s.request_type === 'CREQUEST').length;
        //const num_extensions = list.filter(s => s.request_type === 'EREQUEST').length;
        //const num_accounts = list.filter(s => s.request_type === 'ACCTREQ').length;
        //const num_prechecks = list.filter(s => s.request_type === 'ESTBCRED').length;
        //let num_no_action_accts = 0;
        //if ( this.props.no_action_request_list) {
        //    num_no_action_accts = this.props.no_action_request_list.filter(s => s.request_type === 'ACCTREQ' && s.responder_id !== this.props.current_user.salesperson_id).length;
        //}

        return { CREQUEST: num_conversion, EREQUEST: num_extensions, ACCTREQ: num_accounts, ESTBCRED: num_prechecks, NACCTREQ: num_no_action_accts }
    }

    check_selection_button(num, value) {
        const direction = this.props.direction;
        const status = this.props.status
        const request_type = this.props.request_type;
        let style = 'hover-over text-sm p-1';
        if (direction &&
            status ==='P' &&
            request_type &&
            num &&
            value) {
            if (value !== 'NACCTREQ' && direction ==='Incoming') {
                if (num > 0 && status!=='P') {
                    style = `${style} text-bold text-danger`;
                    if (request_type === value) {
                        style = `${style} bg-danger`;
                    }
                }
                
            }
        }
        return style
    }


    render() {
        const { status } = this.state;


        let requests_options = [
            { label: 'Accounts', value: 'ACCTREQ' },
          { label: 'Conversions', value: 'CREQUEST' },
          { label: 'Extensions', value: 'EREQUEST' }
        ]

        if (this.props.direction === 'Outgoing' ) {
            if ( this.props.direction === 'Outgoing') {
                requests_options.push({ label: 'Prechecks', value: 'ESTBCRED' })
            }
         
        }
        if ( this.props.direction === 'Incoming') {
            requests_options.push({ label: 'No Action Required Accounts', value: 'NACCTREQ' })
        }

        const requests_nums = this.get_requests_nums()

        const requests_options_button = (type) =>
            requests_nums ?
                <Button
                    className={this.check_selection_button(requests_nums[type.value], type.value)}
                    variant={type.value === this.props.request_type ? 'secondary' : 'outline-secondary'}
                    onClick={() => this.props.update_requests_type(type.value)}>
                    {`${type.label} (${requests_nums[type.value]})`}
                </Button> :
                null

        return (
            <div className="d-flex flex-column mb-2 flex-grow-1">
                <ButtonGroup>
                    {requests_options.map(d =>
                        requests_options_button(d)
                    )}
                </ButtonGroup>

                <InputGroup className="mt-2">
                    <Form.Select onChange={(e) => this.props.update_direction(e.target.value)} className="text-sm p-1" value={this.props.direction}>
                        <option value="Incoming">Incoming</option>
                        <option value="Outgoing">Outgoing</option>
                    </Form.Select>
                    <Form.Select onChange={(e) => this.props.update_status(e.target.value)} className="text-sm p-1" value={this.props.status}>
                        <option value="P">Pending</option>
                        <option value="C">Completed</option>
                    </Form.Select>

                </InputGroup>

            </div>

        );
    }
}