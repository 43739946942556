import React, { Component } from 'react';
import { Card, Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import * as customerFunctions from '../../Functions/CustomerFunctions';
import * as dataService from '../../../Dataservice/Endpoints';
import { ViewObjectModal } from '../../Modals/ViewObjectModal';
import { MsalContext } from '@azure/msal-react';
export class CustomerAuditViewer extends Component {
    static displayName = CustomerAuditViewer.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            entered_id: '',
            entered_company: '',
            show_view_obj: false,
            show_object: null,
            show_key: '',
            view_more: false,
        };
    }

    render_keys() {
        const sp_audit = this.props.sp_audit;

        if (sp_audit) {
            const initial_values = sp_audit.initial_values ? JSON.parse(sp_audit.initial_values) : null;
            const new_values = sp_audit.new_values ? JSON.parse(sp_audit.new_values) : null;
            const keys = Object.keys(new_values);
            const displayed_keys = [];
            for (let i = 0; i < keys.length; i++) {
                if (initial_values[keys[i]] !== new_values[keys[i]]) {
                    displayed_keys.push(keys[i]);
                }
            }
            return (
                <Container fluid="xs">
                    <div className="d-flex flex-container text-sm mt-3">
                        <div className="d-flex flex-row justify-content-between">
                            <strong>Changed Date:</strong>
                            <label>{sp_audit.changed_date_time}</label>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                            <strong>Changed By User:</strong>
                            <label>{sp_audit.changed_by_user}</label>
                        </div>
                    </div>
                    <Row>
                        <h6>Initial Values</h6>
                        {displayed_keys
                            .sort((a, b) => a.localeCompare(b))
                            .map((key) => (
                                <Col className="p-2">
                                    <div
                                        className="d-flex flex-row justify-content-between border p-1 bg-white rounded"
                                        style={{ width: '20rem' }}
                                    >
                                        <label className="text-nowrap text-sm p-1 text-bold">{key}:</label>
                                        <Form.Control
                                            name={key}
                                            disabled={true}
                                            value={initial_values[key]}
                                            className="border-0 outline-0 text-sm p-1"
                                        />
                                    </div>
                                </Col>
                            ))}
                    </Row>
                    <Row>
                        <h6>New Values</h6>
                        {displayed_keys
                            .sort((a, b) => a.localeCompare(b))
                            .map((key) => (
                                <Col className="p-2">
                                    <div
                                        className="d-flex flex-row justify-content-between border p-1 bg-white rounded"
                                        style={{ width: '20rem' }}
                                    >
                                        <label className="text-nowrap text-sm p-1 text-bold">{key}:</label>
                                        <Form.Control
                                            name={key}
                                            disabled={true}
                                            value={new_values[key]}
                                            className="border-0 outline-0 text-sm p-1"
                                        />
                                    </div>
                                </Col>
                            ))}
                    </Row>
                </Container>
            );
        } else {
            return null;
        }
    }

    render() {
        const { entered_id, entered_company, show_view_obj, show_object, show_key, view_more } = this.state;

        const sp_audit = this.props.sp_audit;
        const sp_audit_loaded = this.props.sp_audit_loaded;
        const sp_audit_clicked = this.props.sp_audit_clicked;

        return (
            <Card className="mt-4 bg-light p-3 d-flex flex-column" style={{ maxHeight: '80vh', overflow: 'auto' }}>
                <h6 className="tile-title text-muted">Customer Audit Log: {entered_id}</h6>
                <div className="d-flex flex-row justify-content-start">
                    <div className="d-flex flex-row justify-content-start w-25 me-3">
                        <label className="text-sm text-nowrap p-1 align-self-end">Company ID: </label>
                        <Form.Select
                            className="text-sm p-1"
                            value={entered_company}
                            onChange={(e) => this.setState({ entered_company: e.target.value })}
                        >
                            <option value="">Select</option>
                            <option value="TMS">SLC</option>
                            <option value="TMS2">SLUSA</option>
                        </Form.Select>
                    </div>
                    <div className="d-flex flex-row justify-content-start w-25">
                        <label className="text-sm text-nowrap p-1 align-self-end">Customer Code: </label>
                        <Form.Control
                            className="text-sm p-1"
                            value={entered_id}
                            onChange={(e) => this.setState({ entered_id: e.target.value })}
                        />
                    </div>
                    <Button
                        variant={sp_audit_clicked ? 'outline-success' : 'success'}
                        className="btn-op70 text-sm ms-3"
                        onClick={() => this.props.select_audit(entered_company, entered_id)}
                    >
                        Go
                    </Button>
                </div>

                <div className="d-flex flex-column">
                    {sp_audit_loaded && sp_audit ? (
                        <Container fluid="xs" className="p-2">
                            {this.render_keys()}
                            <Row className="d-flex flex-row justify-content-center mt-3">
                                <Col className="d-flex flex-column justify-content-center">
                                    {view_more ? (
                                        <Button
                                            variant="link"
                                            disabled={sp_audit_clicked}
                                            className="btn-op70 text-sm ms-3 align-self-center underlined"
                                            onClick={() => this.setState({ view_more: false })}
                                            style={{ width: '10rem' }}
                                        >
                                            View Less
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="link"
                                            disabled={sp_audit_clicked}
                                            className="btn-op70 text-sm ms-3 align-self-center underlined"
                                            onClick={() => this.setState({ view_more: true })}
                                            style={{ width: '10rem' }}
                                        >
                                            View More
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    ) : null}
                    {sp_audit_clicked && !sp_audit_loaded ? (
                        <div className="p-3">
                            <Alert variant="secondary">Loading...</Alert>
                        </div>
                    ) : null}
                </div>
                <ViewObjectModal
                    show={show_view_obj}
                    onHide={() => this.setState({ show_view_obj: false, show_object: null, show_key: '' })}
                    show_object={show_object}
                    show_key={show_key}
                />
            </Card>
        );
    }
}
