import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import * as customerFunctions from '../../Functions/CustomerFunctions';
import * as prospectActionFunctions from '../../Functions/ProspectActionFunctions';
import * as utilityFunctions from '../../Functions/UtilityFunctions';
import { format } from 'date-fns'
import * as svg_icons from '../../../Media/svg_exports'
import { MsalContext } from '@azure/msal-react';

export class ReleaseModal extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);

        this.state = {
            clicked: false,
            remarks: '',
        };
    }




    async post() {
        this.setState({ clicked: true })
        await this.props.release_account(this.state.remarks);
        this.setState({
            clicked: false,
            remarks: ''
        })
            this.props.onHide()
    }


    render() {
        const { clicked, remarks } = this.state;
        const selected_account = this.props.selected_account;

        return (
            <Modal
                {...this.props}
                size="med"
                className="bg-modal-focus"
                contentClassName="light-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                    <label className="hover-over w-25" onClick={() => this.props.onHide()}>{svg_icons.cancel_icon}</label>
                    <div className="d-flex flex-column justify-content-center w-50 text-center p-0 pt-2">
                        <h5 className="text-danger">RELEASE <bdi className="capitalize">{selected_account?selected_account.name.toLowerCase():''}</bdi></h5>

                    </div>

                    <div className="w-25"></div>
                </Modal.Header>
                {selected_account ?
                    <Modal.Body className="d-flex flex-column py-5">
              
  <label>Release Notes:</label>
                        <InputGroup >
                            <Form.Control as="textarea" onChange={(e) => this.setState({ remarks: e.target.value })} value={remarks} className="text-sm" placeholder="required" isInvalid={ !remarks} />
                         
                        </InputGroup>
                        <Button
                            className="text-sm mt-3"
                           
                            disabled={clicked || !remarks}
                            onClick={() => this.post()}
                            variant={clicked ? 'outline-danger' : 'danger'} >
                            {clicked ? 'Releasing...' : 'Release'}

                        </Button>
                    </Modal.Body> :
                    null
                }




            </Modal>

        )
    }



}

