import { MsalContext } from '@azure/msal-react';
import React, { Component } from 'react';
import {
    addDays,
    addHours,
    isSameISOWeek,
    isToday,
    toDate,
} from 'date-fns';

import {
    Col,
    Container,
    Row,
    Stack
} from 'react-bootstrap';

import * as customerFunctions from '../Functions/CustomerFunctions';
import * as salespersonFunctions from '../Functions/SalespersonFunctions';
import * as prospectActionFunctions from '../Functions/ProspectActionFunctions';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as baseColors from '../Functions/BaseColors';
import { ExpiredProspectsTile } from '../TableComponents/ExpiredProspectsTile';
import { DynamicPortalTile } from '../PageComponents/DynamicPortalTile';
import { RandomUnclaimedTile } from '../LAMComponents/RandomUnclaimedTile';
import { LoadingModal } from '../Modals/LoadingModal';
import { PendingFollowUps } from '../LAMComponents/PendingFollowUps';
import { AccountOverviewGraph } from '../LAMComponents/AccountOverviewGraph';
import { ActivitiesCompletedGraph } from '../LAMComponents/ActivitiesCompletedGraph';
import { NewProspectsGraph } from '../LAMComponents/NewProspectsGraph';
import { ReleasedActivitiesGraph } from '../LAMComponents/ReleasedActivitiesGraph';
import { RequestsTile } from '../LAMComponents/RequestsTile';
import { ViewAccountModal } from '../Modals/Account/ViewAccountModal';
import { ProspectActionReport } from '../LAMComponents/ProspectActionReport';
import { SalespersonViewer } from '../TableComponents/SalespersonViewer';
import { LAMProspects } from '../Modals/LAMProspects';

export class LAMPortal extends Component {
    static displayName = LAMPortal.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            //SET in componentDidMount || componentDidUpdate
            user_loaded: false,

            //SET in Collect_New_Prospects
            new_prospects_this_week: [],
            new_prospects_last_week: [],
            new_prospects_loaded: false,

            //SET in Populate_Random_Unclaimed 
            unclaimed_list: [],
            unclaimed_index: -1,
            unclaimed_loaded: false,
            unclaimed_failed: false,

            //SET in View_Account
            account_clicked: false,
            selected_account_data: null,
            show_selected_account: false,

            //SET in RequestTile
            view_request_status: 'Pending',
            expired_accounts:[],
            expired_accounts_loaded: false,

            stc_dashboard: [],
            stc_dashboard_loaded: false,
            filter_sales_team: '',
           

            lam_clicked: false,
            show_lam: false,
            selected_salesperson: null,
            selected_accounts_list: [],
            lam_activities: [],
            selected_salesperson_loaded: false,


            graph_view: 'activities',
            requests_loaded: false,
            request_index: 0,
            selected_request_type: 'ACCTREQ',
            current_accounts_list:[]

        };
    }

  

    async componentDidMount() {
        // this.timer = setInterval(() => this.get_portal(currentUser), 10000);
        try {
            if (this.props.salesperson) {
                if (this.props.salesperson.role === 'STC' && this.props.salesperson.company_id === 'TMS2') {
                    this.get_stc_dashboard(this.props.salesperson.company_id, this.props.salesperson.salesperson_id)
                }
             
                this.Collect_New_Prospects();
                this.Populate_Random_Unclaimed(this.props.salesperson.company_id);
                const expired_accounts = await salespersonFunctions.Get_Expiring_By_Salesperson(this.props.salesperson.company_id, this.props.salesperson.salesperson_id);
                this.setState({
                    expired_accounts: expired_accounts,
                    expired_accounts_loaded: true
                });
                this.setState({
                    user_loaded: true
                });
            }
            
        } catch (e) {
            console.log(e);
            this.setState({ user_loaded: false });
        }
    }

    componentDidUpdate() {
        if (!this.state.requests_loaded) {
            utilityFunctions.timeout(3000).then((res) => {
                if (this.props.incoming_requests) {
                    if (this.props.incoming_requests.loaded === 'Y') {
                        const pending_requests = this.props.incoming_requests.list.filter((s) => s.status === 'P');
                        this.set_param_index(pending_requests);
                        this.setState({ requests_loaded: true });
                    }
                }
            })         
        }
    }

    set_param_index(pending_requests) {
        let current_activity_id = '';
        if (window.location.pathname.split('/').length === 3) {
            current_activity_id = window.location.pathname.split('/')[2];
        }
        if (window.location.pathname.split('/').length === 4) {
            current_activity_id = window.location.pathname.split('/')[2];
        }
        if (current_activity_id && pending_requests) {
            const current_activity = pending_requests.find((s) => s.id === current_activity_id);
            const index = pending_requests.indexOf(current_activity);
            if (current_activity) {
                this.setState({ request_index: index > -1 ? index : 0, requests_loaded: true, selected_request_type: current_activity.request_type });
            }
          
        }
    }

    async get_stc_dashboard(company_id, salesperson_id) {
        const data = await salespersonFunctions.Get_STC_Dashboard(company_id, salesperson_id);
        this.setState({
            stc_dashboard: data,
            stc_dashboard_loaded: true})
    }

    componentWillUnmount() {
        // this.timer = null;
    }

    Collect_New_Prospects() {
        const prospects = this.props.prospects ? this.props.prospects.list : [];
        const prospects_this_week = prospects.filter((s) => isSameISOWeek(new Date(s.start_date), new Date()));
        const prospects_last_week = prospects.filter((s) => isSameISOWeek(new Date(s.start_date), addDays(new Date(), -7)));


        const currentHour = new Date().getHours();

        // Keep track of the number of prospects per day (Sunday is index 0).
        const countThisWeekByDay = [0, 0, 0, 0, 0, 0, 0];
        const countLastWeekByDay = [0, 0, 0, 0, 0, 0, 0];

        prospects_this_week.forEach((prospect) => {
            const currentStartDate = addHours(new Date(prospect.start_date), currentHour);
            const dayOfWeek = toDate(currentStartDate).getDay();
            countThisWeekByDay[dayOfWeek]++;
        });

        prospects_last_week.forEach((prospect) => {
            const lastStartDate = addHours(new Date(prospect.start_date), currentHour);
            const dayOfWeek = toDate(lastStartDate).getDay();

            countLastWeekByDay[dayOfWeek]++;
        });


        this.setState({
            new_prospects_this_week: prospects_this_week,
            new_prospects_last_week: prospects_last_week,           
            new_prospects_loaded: true,
        });
    }

    async setSalesperson(salesperson) {
        try {
            this.setState({ lam_clicked: true })
            const selected_accounts_list_data = await customerFunctions.Get_My_Accounts(salesperson.company_id, salesperson.salesperson_id);
            const lam_activity_data = await prospectActionFunctions.Get_By_Salesperson(salesperson.company_id, salesperson.salesperson_id);
            this.setState({
                lam_clicked: false,
                show_lam: true,
                selected_salesperson: salesperson,
                selected_accounts_list: selected_accounts_list_data,
                lam_activities: lam_activity_data,
                selected_salesperson_loaded: true
            });

        } catch (e) {
            console.log(e);
            this.setState({
                lam_clicked: false,
                show_lam: false,
                selected_salesperson: null,
                selected_accounts_list: [],
                lam_activities: [],
                selected_salesperson_loaded: true
            });
        }
    }

    async open_account_view_for_request(prop, list) {
        this.setState({ selected_accounts_list: list });
        await this.view_account(prop.company_id, prop.id);

    }

    update_selected_account_data(property, prop) {
        let c_selected_account_data = this.state.selected_account_data;
        c_selected_account_data[property] = prop;
        this.setState({ selected_account_data: c_selected_account_data })
    }

    render() {
        const {
            account_clicked,
            unclaimed_loaded,
            unclaimed_list,
            unclaimed_index,
            user_loaded,
            selected_account_data,
            show_selected_account,
            expired_accounts,
            expired_accounts_loaded,
            stc_dashboard,
            stc_dashboard_loaded,
            filter_sales_team,
            selected_salesperson,
            lam_clicked,
            show_lam,
            selected_accounts_list,
            lam_activities,
            selected_salesperson_loaded,
            graph_view,
            request_index,
            selected_request_type
        } = this.state;

        const current_user = this.props.salesperson ? this.props.salesperson : null;
        const width = this.props.width ? parseInt(this.props.width) : 0;
        const page_breakpoint = 1200
        const prospects_list = this.props.prospects ? this.props.prospects.loaded === 'Y' ? this.props.prospects.list : [] : [];
        const customers_list = this.props.customers ? this.props.customers.loaded === 'Y' ? this.props.customers.list : [] : [];

        const outgoing_requests_list = this.props.outgoing_requests ? this.props.outgoing_requests.loaded === 'Y' ? this.props.outgoing_requests.list : [] : [];
        const incoming_requests_list = this.props.incoming_requests ? this.props.incoming_requests.loaded === 'Y' ? this.props.incoming_requests.list : [] : [];
        const users_prospect_actions = this.props.prospect_actions ? this.props.prospect_actions.loaded === 'Y' ? this.props.prospect_actions.list : [] : [];

        let team_name = '';
        let current_stc_dashboard = stc_dashboard;
        if (current_user) {
            if (current_user.role === 'STC' && current_user.company_id === 'TMS2') {
                let split_name = current_user.salesperson_name.split(' ');
                team_name = `TEAM ${split_name[0].substring(0, 1)}${split_name[1].substring(0, 1)}`;
                if (filter_sales_team && stc_dashboard) {
                    current_stc_dashboard = stc_dashboard.filter(s => s.name.toLowerCase().includes(filter_sales_team.toLowerCase()))
                }
            }
        }

        const stc_dashboard_tile = <SalespersonViewer
            current_user={this.props.salesperson}
            salespersons={current_stc_dashboard}
            rev_code={team_name}
            updateFilter={(prop) => this.setState({ filter_sales_team: prop })}
            selected_salesperson={selected_salesperson}
            setSalesperson={(prop) => this.setSalesperson(prop)}
            all_completed_loaded={stc_dashboard_loaded}
        />
        const lam_prospects_modal = <LAMProspects
            show={show_lam}
            onHide={() =>
                this.setState({
                    lam_clicked: false,
                    show_lam: false,
                    selected_salesperson: null,
                    selected_accounts_list: [],
                    lam_activities: [],
                    selected_salesperson_loaded: false
                })
            }
            current_accounts={selected_accounts_list}
            current_user={current_user}
            current_accounts_loaded={selected_salesperson_loaded}
            current_accounts_failed={false}
            salesperson={selected_salesperson}
            set_sp_company={(prop) => this.open_account_view_for_request(JSON.parse(prop), selected_accounts_list)}
            lam_activities={lam_activities}
            lam_activities_loaded={selected_salesperson_loaded}
        />

        const pending_followups_tile =
            users_prospect_actions ?
                <PendingFollowUps
                    current_user={current_user}
                    users_prospect_actions={users_prospect_actions}
                    update_users_action={(prop) => this.props.update_users_action(prop)}
                    add_users_action={(prop) => this.props.add_users_action(prop)}
                    accounts={prospects_list.concat(customers_list)}
                /> :
                null;

        const account_overview_tile = <AccountOverviewGraph
            prospects_list={prospects_list}
            customers_list={customers_list}
            width={width}
            page_breakpoint={page_breakpoint }
        />

        const activities_completed_graph_tile = <ActivitiesCompletedGraph prospect_actions={users_prospect_actions} update_graph_view={(prop) => this.setState({ graph_view:prop })} />
        
        const new_prospects_graph_tile = <NewProspectsGraph prospects_list={prospects_list} update_graph_view={(prop) => this.setState({ graph_view: prop })} />

        const released_graph_tile = <ReleasedActivitiesGraph prospect_actions={users_prospect_actions} update_graph_view={(prop) => this.setState({ graph_view: prop })} />
     
        const incoming_requests_tile = <RequestsTile
            current_user={current_user}
            msal_account={this.props.msal_account}
            requests_list={incoming_requests_list}
            update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
            view_account={(prop, list) => this.open_account_view_for_request(prop, list)}
            role={this.props.role}
            request_type={selected_request_type}
            update_request_type={(prop) => this.setState({ selected_request_type: prop })}
            request_index={request_index}
            update_request_index={(prop) => this.setState({ request_index: prop })}
            direction='Incoming'
            release_account={(prop) => this.props.release_account(prop)}
        />

        const outgoing_requests_tile = <RequestsTile
            current_user={current_user}
            msal_account={this.props.msal_account}
            requests_list={outgoing_requests_list}
            update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
            view_account={(prop, list) => this.open_account_view_for_request(prop, list)}
            role={this.props.role}
            request_type={selected_request_type}
            update_request_type={(prop) => this.setState({ selected_request_type: prop })}
            request_index={request_index}
            update_request_index={(prop) => this.setState({ request_index: prop })}
            direction='Outgoing'
            release_account={(prop) => this.props.release_account(prop)}
        />

        const prospect_activity_report_tile = <ProspectActionReport prospect_actions={users_prospect_actions} />

        const number_completed_today_tile = users_prospect_actions ?
            <div className="number-completed-div">
                <h6 className="number-completed">{users_prospect_actions.filter(s => isToday(new Date(s.action_date_str))).length}</h6>
            </div> : null;

        const number_completed_week_tile = Array.isArray(users_prospect_actions) ?
            <div className="number-completed-div">
                <h6 className="number-completed">{users_prospect_actions.filter(s => isSameISOWeek(new Date(), new Date(s.action_date_str))).length}</h6>
            </div> : null;

        const released_unclaimed_card = (
            <RandomUnclaimedTile
                index={unclaimed_index}
                list={unclaimed_list}
                view_account={(prop, list) => this.open_account_view_for_request(prop, list)}
                update_index={(prop) => this.setState({ unclaimed_index: parseInt(prop) })}
            />
        );

        const expired_accounts_container =
            <ExpiredProspectsTile
                current_user={this.props.salesperson}
                role={this.props.role}
                expired_accounts={expired_accounts}
                view_account={(prop, list) => this.open_account_view_for_request(prop, list)}
            />

        const col_1 = <div className="d-flex flex-column justify-content-between m-2">
            <div className={`d-flex flex-${width < page_breakpoint?'column':'row'}`}>
                {pending_followups_tile}
                <div className="p-2"></div>
                <div className="d-flex flex-column">
                    <DynamicPortalTile
                        title={`Incoming Account Requests`}
                        height="40rem"
                        body={incoming_requests_tile}
                        loaded={this.props.incoming_requests ? this.props.incoming_requests.loaded === 'Y' : false}
                        loading_title="Incoming Requests"
                    />
                    <div className="p-2"></div>
                    <DynamicPortalTile
                        title={`Outgoing Requests`}
                        height="40rem"
                        body={outgoing_requests_tile}
                        loaded={this.props.outgoing_requests ? this.props.outgoing_requests.loaded === 'Y' : false}
                        loading_title="Outgoing Requests"
                    />
                    <div className="p-2"></div>
                    <DynamicPortalTile
                        title="Prospect Activity Report"
                        height="15rem"
                        body={prospect_activity_report_tile}
                        loaded={this.props.prospect_actions ? this.props.prospect_actions.loaded === 'Y' : false}
                        loading_title="Completed Activities"
                    />
                </div>
            </div>
            <div className="p-2"></div>
            {current_user.role === 'STC' && current_user.company_id === 'TMS2' ?
                <DynamicPortalTile
                    title=''
                    height="40rem"
                    body={stc_dashboard_tile}
                    loaded={stc_dashboard_loaded}
                    loading_title={`${team_name} Dashboard`}
                />

                :
                <div></div>
            }
           
        </div>

        const col_2 = <div className="d-flex flex-column justify-content-between m-2">
            <DynamicPortalTile
                title="Accounts Overview"
                height=""
                style={{ width: '400px', height: '400px' }}
                body={account_overview_tile}
                loaded={this.props.prospects ? this.props.prospects.loaded === 'Y' : false}
                loading_title="Accounts Overview"
            />
            <div className="p-2"></div>
            {graph_view === 'activities' ?
                <DynamicPortalTile
                    title=""
                    height="14rem"
                    body={activities_completed_graph_tile}
                    loaded={this.props.prospect_actions ? this.props.prospect_actions.loaded === 'Y' : false}
                    loading_title="Completed Activities"
                /> :
                null
            }
            {graph_view === 'new' ?
                <DynamicPortalTile
                    title=""
                    height="14rem"
                    body={new_prospects_graph_tile}
                    loaded={this.props.prospect_actions ? this.props.prospects.loaded === 'Y' : false}
                    loading_title="New Prospects"
                /> :
                null
            }
            {graph_view === 'released' ?
                <DynamicPortalTile
                    title=""
                    height="14rem"
                    body={released_graph_tile}
                    loaded={this.props.prospect_actions ? this.props.prospect_actions.loaded === 'Y' : false}
                    loading_title="Released Accounts"
                /> : null}
            <div className="p-2"></div>
            <DynamicPortalTile
                title="Released/Unclaimed"
                height="30rem"
                body={released_unclaimed_card}
                loaded={unclaimed_loaded}
                loading_title="Released/Unclaimed"
                onRefresh={() => this.Populate_Random_Unclaimed(current_user.company_id)}
            />
        </div>

        const col_3 = <div className="d-flex flex-column justify-content-between m-2">
            <div className="d-flex flex-row justify-content-between">
                <DynamicPortalTile
                    title="Completed Today"
                    height="5rem"
                    body={number_completed_today_tile}
                    loaded={this.props.prospect_actions ? this.props.prospect_actions.loaded === 'Y' : false}
                    loading_title="Prospect Activities"
                />
                <div className="p-2"></div>
                <DynamicPortalTile
                    title="Completed This Week"
                    height="5rem"
                    body={number_completed_week_tile}
                    loaded={this.props.prospect_actions ? this.props.prospect_actions.loaded === 'Y' : false}
                    loading_title="Prospect Activities"
                />
            </div>
            <div className="p-2"></div>
            <DynamicPortalTile
                title={''}
                height={'40rem'}
                body={expired_accounts_container}
                loaded={expired_accounts_loaded}
                loading_title="Expired Accounts"
            />
          
         
        </div>

        

        return (
            <div className={`d-flex flex-${width < page_breakpoint?'column':'row'} w-100 p-3`} >
                {col_1}
                {col_2}    
                {col_3}        
                {selected_account_data ?
                    
                    <ViewAccountModal
                        show={show_selected_account}
                        onHide={() => this.setState({
                            show_selected_account: false,
                            selected_account_data: null,
                            selected_accounts_list: []
                        })}
                        current_user={this.props.salesperson}
                        msal_account={this.props.msal_account}
                        role={this.props.role}
                        accounts_list={selected_accounts_list}
                        prospects={this.props.prospects}
                        commodities={this.props.commodities}
                        categories={this.props.categories}
                        salesperson_list={this.props.salesperson_list}
                        selected_account_data={selected_account_data}
                        add_account={(prop) => this.props.add_account(prop)}
                        release_account={(prop) => this.props.release_account(prop)}
                        update_accounts_list={(prop) => this.setState({ selected_accounts_list: prop })}
                        view_account={(prop) => this.view_account(prop.company_id, prop.id)}
                        update_selected_account_data={(prop) => this.setState({ selected_account_data: prop })}
                        update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                        add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                        add_users_action={(prop) => this.props.add_users_action(prop)}
                        update_users_action={(prop) => this.props.add_users_action(prop)}
                    /> :null   
                }

                {selected_salesperson ? lam_prospects_modal:null}

                <LoadingModal show={account_clicked} title="Account" />
                <LoadingModal show={lam_clicked} title="LAM" />
            
            </div>
        );
    }

    async view_account(company_id, customer_id) {
        this.setState({ account_clicked: true, });
        const account_data = await customerFunctions.View_Account(company_id, customer_id);
        this.setState({
            selected_account_data: account_data,
            show_selected_account: true,
            account_clicked: false
        })
    }

    async Populate_Random_Unclaimed(company_id) {
        try {       
            const data = await customerFunctions.Get_Random_Unclaimed_List(company_id);
            this.setState({
                unclaimed_list: data,
                unclaimed_index: 0,
                unclaimed_loaded: true,
                unclaimed_failed:false
            });
        } catch (e) {
            this.setState({
                unclaimed_loaded: false,
                unclaimed_list: [],
                unclaimed_index: 0,
                unclaimed_failed:true
            });
            console.log(e);
        }
    }

}
