import React, { Component } from 'react';
import { Button, InputGroup, Form, Alert, Container, Row, Col } from 'react-bootstrap';
import * as svg_icons from '../../Media/svg_exports';
import { format, addDays } from 'date-fns'
import * as requestFunctions from '../Functions/RequestFunctions';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as customerFunctions from '../Functions/CustomerFunctions'
import { MsalContext } from '@azure/msal-react';


export class GMPendingRequests extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            additional_remarks: '',
            clicked: false,
            message:'',
            processing_message: '',
            is_responding: false,
            gm_responding: false,
            gm_remarks: ''
        };
    }

    reset_vars() {
        this.setState({
            additional_remarks: '',
            clicked: false,
            processing_message: '',
            is_responding: false,
            gm_responding: false,
            gm_remarks: '',
            message:''
        })
    }

    async complete_account_request(request, decision, selected_account, current_user_id) {
        let body = {
            company_id: request.company_id,
            id: request.id,
            customer_id: request.customer_id,
            requestor_name: request.requestor_name,
            requestor_id: request.requestor_id,
            requestor_sales_manager_id: request.requestor_sales_manager_id,
            sales_manager_id: this.props.current_user.salesperson_id,
            remarks: this.state.gm_remarks
        }
        if (decision === 'Approved') {
            const request_data = await requestFunctions.Switch_Account_Request_As_GM(body);
            await this.props.update_requests(request_data)
            const audit_body = {
                company_id: request.company_id,
                customer_id: request.customer_id,
                changed_by_user: current_user_id,
                initial_values: JSON.stringify({
                    mark_plan_id: request.mark_plan_id,
                    start_date: request.customer_start_date,
                    salesperson_id: request.requestee_id,
                    sales_manager_id: request.requestee_sales_manager_id
                }),
                new_values: JSON.stringify({
                    mark_plan_id: 'PROS90D1',
                    start_date: format(new Date(), 'MM-dd-yyyy'),
                    salesperson_id: request.requestor_id,
                    sales_manager_id: request.requestor_sales_manager_id

                })
            }
            await customerFunctions.Insert_Customer_Audit(audit_body);
            if (selected_account) {
                selected_account.mark_plan_id = 'PROS90D1';
                selected_account.start_date = format(new Date(), 'MM-dd-yyyy');
                selected_account.sales_manager_id = request.requestor_sales_manager_id
                selected_account.salesperson_id = request.requestor_id;
            }
        } else {
            const request_data = await requestFunctions.Deny_Account_Request_As_GM(body);
            await this.props.update_requests(request_data)
        }
    }

    async complete_request(request, decision) {
        let new_message = decision === 'Denied' ? `Denying ${request.request_name} Request...` : `Approving ${request.request_name} Request...`
        this.setState({ clicked: true, message: new_message })
        try {
            const current_user_id = this.props.msal_account ? this.props.msal_account.username.replaceAll('@scotlynn.com', '') : 'Unknown';
            let selected_account = this.props.selected_account;
            await this.email_response(request, decision)


            if (request.request_type === 'ACCTREQ') {
                this.complete_account_request(request, decision, selected_account, current_user_id)
                       
            } else {
                let body = {
                    id: request.id,
                    company_id: request.company_id,
                    customer_id: request.customer_id,
                    user_id: current_user_id,
                    remarks: this.state.gm_remarks,
                    role: this.props.role,
                    category: request.salesperson_category
                }

                if (request.request_type === 'EREQUEST' && decision === 'Approved') {
                    if (request.customer_start_date) {
                        if (new Date(request.customer_start_date) < new Date()) {
                            body.start_date = format(addDays(new Date(request.customer_start_date), 91), 'MM-dd-yyyy');
                        } else {
                            body.start_date = format(new Date(), 'MM-dd-yyyy');
                        }
                    } else {
                        body.start_date = format(new Date(), 'MM-dd-yyyy');
                    }
                    const request_data = await requestFunctions.Approve_Extension(body);
                    await this.props.update_requests(request_data)
                    const audit_body = {
                        company_id: request.company_id,
                        customer_id: request.customer_id,
                        changed_by_user: current_user_id,
                        initial_values: JSON.stringify({
                            mark_plan_id: request.mark_plan_id,
                            start_date: request.customer_start_date
                        }),
                        new_values: JSON.stringify({
                            mark_plan_id: 'PROS90D2',
                            start_date: body.start_date

                        })
                    }
                    await customerFunctions.Insert_Customer_Audit(audit_body);
                    if (selected_account) {
                        selected_account.mark_plan_id = 'PROS90D2';
                        selected_account.start_date = body.start_date;
                    }
                }
                else if (request.request_type === 'EREQUEST' && decision === 'Denied') {
                    const request_data = await requestFunctions.Deny_Extension(body);
                    await this.props.update_requests(request_data)
                }
                else if (request.request_type === 'CREQUEST' && decision === 'Approved') {
                    const request_data = await requestFunctions.Approve_Conversion(body);
                    await this.props.update_requests(request_data)
                    const audit_body = {
                        company_id: request.company_id,
                        customer_id: request.customer_id,
                        changed_by_user: current_user_id,
                        initial_values: JSON.stringify({
                            mark_plan_id: request.mark_plan_id,
                            start_date: request.customer_start_date,
                            crm_convert_date: "",
                            conversion_date: request.customer_conversion_date,
                            type_of: request.type_of,
                            category: request.customer_category
                        }),
                        new_values: JSON.stringify({
                            mark_plan_id: 'CUSTNEW',
                            start_date: format(new Date(), 'MM-dd-yyyy'),
                            crm_convert_date: format(new Date(), 'MM-dd-yyyy'),
                            conversion_date: format(new Date(), 'MM-dd-yyyy'),
                            type_of: 'Customer',
                            category: this.props.role === 'credit' || this.props.role === 'admin' ? request.salesperson_category : request.customer_category
                        })
                    }
                    await customerFunctions.Insert_Customer_Audit(audit_body);
                    if (selected_account) {
                        selected_account.mark_plan_id = 'CUSTNEW';
                        selected_account.start_date = format(new Date(), 'MM-dd-yyyy');
                        selected_account.crm_convert_date = format(new Date(), 'MM-dd-yyyy');
                        selected_account.conversion_date = format(new Date(), 'MM-dd-yyyy');
                        selected_account.type_of = 'Customer';
                        if (this.props.role === 'credit' || this.props.role === 'admin') {
                            selected_account.category = selected_account.salesperson_category;
                        }
                    }
                }
                else if (request.request_type === 'CREQUEST' && decision === 'Denied') {   
                    const request_data = await requestFunctions.Deny_Conversion(body);
                    await this.props.update_requests(request_data)

                }
            }
            if (selected_account) {
                this.props.update_selected_account(selected_account);
            }
            this.reset_vars()
        } catch (e) {
            console.log(e)
        }
    }

    async email_response(request_data, decision) {
        try {
            const subject_line = process.env.REACT_APP_EMAIL_TITLE + `: ${request_data.request_name} Request ${decision} For ${request_data.customer_id}`;

            let message_text =
                `${request_data.request_name} request has been ${decision.toLowerCase()} for ${request_data.customer_name} by ${this.props.msal_account.name}`;
            if (this.state.additional_remarks) {
                message_text = `${message_text} with the following remarks: ${this.state.additional_remarks}`
            }
            if (this.state.gm_remarks) {
                message_text = `${message_text} with the following remarks: ${this.state.gm_remarks}`
            }

            let recipients = [
                {
                    emailAddress: {
                        address: request_data.requestor_email_address,
                    }
                }
            ];
            if (request_data.request_type === 'CREQUEST' && decision === 'Approved') {
                recipients.push({
                    emailAddress: {
                        address: process.env.REACT_APP_CREDIT_EMAIL
                    }
                })
            }


            const message_body = {
                message: {
                    subject: subject_line,
                    body: {
                        contentType: 'Text',
                        content: message_text,
                    },
                    toRecipients: recipients,
                },
            }
            const inst = this.context.instance;
            const acct = this.context.accounts;
            await utilityFunctions.Send_Email(inst, acct, message_body);


        } catch (e) {
            console.log(e);
        }
    }


    render() {
        const { additional_remarks, clicked, gm_remarks, message } = this.state;
        const index = this.props.index;
        const request_type = this.props.request_type;
        let current_requests_list = this.props.current_requests_list ? this.props.current_requests_list : [];
        if (request_type === 'NACCTREQ') {
            current_requests_list = this.props.no_action_request_list
        }
        const current_request = current_requests_list ? current_requests_list.length > 0 ? current_requests_list[index] : null : null;
        const formatted_additional_remark = this.props.current_user && additional_remarks ? `\n${this.props.current_user.salesperson_id}: [${format(new Date(), 'MM-dd-yyyy HH:mm:ss')}] ${additional_remarks}` : '';

        let requestor_name = '';
        let requestee_name = '';

        if (current_request) {
            requestor_name = current_request.requestor_name;
            requestee_name = current_request.requestee_name;

            if (current_request.requestor_rev_code) {
                requestor_name = `${requestor_name} (${current_request.requestor_rev_code})`
            }
            if (current_request.requestee_rev_code) {
                requestee_name = `${requestee_name} (${current_request.requestee_rev_code})`
            }
            if (request_type === 'ESTBCRED') {
                requestee_name = 'CREDIT'
            }
        }



        const disabled_fields = (field, val) =>
            <InputGroup className="mb-3 w-100 d-flex">
                <InputGroup.Text className="text-xs" style={{ width: '8rem' }}>
                    {field}:
                </InputGroup.Text>
                <InputGroup.Text className="text-xs bg-white text-start flex-grow-1" style={{ whiteSpace: 'pre-wrap' }}>
                    {val}
                </InputGroup.Text >
            </InputGroup>;

        const responder_field = (field, val) =>
            <InputGroup className="mb-3 w-100 d-flex">
                <InputGroup.Text className="text-xs border border-danger text-bold" style={{ width: '8rem' }}>
                    {field}:
                </InputGroup.Text>
                <InputGroup.Text className="text-xs bg-white text-start border border-danger text-bold flex-grow-1" style={{ whiteSpace: 'pre-wrap' }}>
                    {val}
                </InputGroup.Text >
            </InputGroup>;


        //#endregion



        return (current_requests_list ? current_requests_list.length > 0 ?
            <div className="d-flex flex-row justify-content-between w-100 align-self-center" >

                {index <= 0 || clicked? <div className="p-2"></div> :
                    <label className="hover-over align-self-center p-1" onClick={() => this.props.update_index(index - 1)}>
                        <div style={{ transform: 'scale(1.5)' }}>
                            {svg_icons.left_chevron}
                        </div>                     
                    </label>
                }
                {current_request ?
                    <div className="d-flex flex-column flex-grow-1 px-3 align-self-center">
                        <div className="d-flex flex-column pt-2">
                            {disabled_fields('Requested', current_request.date_requested_str)}
                            {current_request.responder_id === current_request.requestor_id ?
                                responder_field('Requestor', requestor_name) :
                                disabled_fields('Requestor', requestor_name)
                            }
                            {current_request.responder_id === current_request.requestee_id ?
                                responder_field('Requestee', requestee_name) :
                                disabled_fields('Requestee', requestee_name)
                            }

                            <InputGroup className="mb-3 w-100 d-flex">
                                <InputGroup.Text className="text-xs" style={{ width: '8rem' }}>
                                    Account:
                                </InputGroup.Text>
                                <InputGroup.Text className="text-xs bg-white text-start flex-grow-1" style={{whiteSpace: 'pre-wrap' }}>
                                    <bdi className="underlined hover-over text-primary" onClick={() => this.props.view_account(current_request)}>
                                        {`${current_request.customer_name} (${current_request.customer_id})`}
                                    </bdi>
                                </InputGroup.Text >
                            </InputGroup>
                            <InputGroup className="mb-3 w-100 d-flex align-self-center">
                                <InputGroup.Text className="text-xs" style={{ width: '8rem' }}>Remarks</InputGroup.Text>
                                <Form.Control
                                    disabled={true}
                                    as="textarea"
                                    className="text-xs bg-white flex-grow-1"
                                    rows={utilityFunctions.num_occurances_word_in_string('&#xA;', current_request.remarks) ? utilityFunctions.num_occurances_word_in_string('&#xA;', current_request.remarks) + 4 : 1}
                                    value={current_request.remarks ? current_request.remarks.replaceAll('&#xA;', '\n\n') : '' + formatted_additional_remark} />
                            </InputGroup>

                        </div>

                        {this.props.direction === 'Incoming' ?
                            <div className="d-flex flex-column w-100">
                                <InputGroup className="mb-3 w-100 d-flex">
                                    <InputGroup.Text className="text-xs" style={{ width: '8rem' }}>Additional Remarks</InputGroup.Text>
                                    <Form.Control as="textarea" className="text-xs" value={gm_remarks} onChange={(e) => this.setState({ gm_remarks: e.target.value })} placeholder="Enter additional remarks here..."/>
                                </InputGroup>
                                <div className="w-100 d-flex flex-row justify-content-around">
                                    <Button
                                        variant={clicked ? 'outline-danger' : 'danger'}
                                        className="text-xs w-25 text-bold"
                                        disabled={clicked}
                                        size="sm"
                                        onClick={() => this.complete_request(current_request, 'Denied')}
                                    >
                                        {`Deny ${current_request.request_name} Request`}
                                    </Button>
                                    <Button
                                        variant={clicked ? 'outline-success' : 'success'}
                                        className="text-xs w-25 text-bold"
                                        disabled={clicked}
                                        onClick={() => this.complete_request(current_request, 'Approved')}
                                        size="sm"
                                    >
                                        {`Approve ${current_request.request_name} Request`}
                                    </Button>
                                </div>                                               
                            </div> :
                            null}

                        <label className="w-100 align-self-center text-center mt-3 text-sm text-bold"> {index > -1 ? `${index + 1} / ${current_requests_list.length}` : null}</label>
                        {message ? <i className={ `text-sm w-100 text-center py-3 ${message.includes('Denying')?'text-danger':'text-success'}`}>{ message}</i>:null }
                        
                    </div> : null}
                
            
               
                {index === current_requests_list.length - 1 || clicked ? <div className="p-2"></div> :
                    <label className="align-self-center hover-over p-1" onClick={() => this.props.update_index(index + 1)}>
                        <div style={{ transform: 'scale(1.5)' }}>
                            {svg_icons.right_chevron}
                        </div> 
                    </label>
                }

            </div> :null : null);
    }
}