export const msalConfig = {
    auth: {
        clientId: 'b6181828-a638-4885-a4a5-eeedcb786f4a',
        authority: 'https://login.microsoftonline.com/6e656c29-cc78-41f6-a482-7968f203ef0d/',
        navigateToLoginRequestUrl: true,
        redirectUri: "/dashboard",
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const protectedResources = {
    adminResources: {
        scopes: ['api://b6181828-a638-4885-a4a5-eeedcb786f4a/User.Read'],
    },
};

export const loginRequest = {
    scopes: [...protectedResources.adminResources.scopes],
};

export const appRoles = {
    Admin: 'title.Admin',
    Credit: 'title.Credit',
    LAM: 'title.LAM',
    GM: 'title.GM',
};

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const graphConfigPhoto = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
};
