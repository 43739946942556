import React, { Component } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { format, isToday } from 'date-fns';
import * as generalManagerFunctions from '../Functions/GeneralManagerFunctions';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';
import { MsalContext } from '@azure/msal-react';
import * as utilityFunctions from '../Functions/UtilityFunctions'
import * as svg_icons from '../../Media/svg_exports';
export class GeneralManagerSelector extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            button_clicked: false,
            extension_selection: '',
            conversion_selection: '',
            switch_back_date: '',
            sort_field: '',
            sort_direction: '',
            switch_clicked: false,
            currently_selected_gm: '',
            current_assignment_pk: -1,
            current_assignment_type:''
        };
    }

   async update_gms_list() {
        const gms_list = await generalManagerFunctions.Get_All_General_Managers();
       this.props.update_gms_list(gms_list)
    }


    async update_gm_assignment(assignment, type) {
        this.setState({ button_clicked: true })
        const selected_gm = this.state.currently_selected_gm
        const body = {
            pk: assignment.PK,
            company_id: assignment.company_id,
            sales_manager_id: assignment.sales_manager_id,
            extension_manager_id: type === 'Extensions' ? selected_gm : assignment.extension_manager_id,
            conversion_manager_id: type === 'Conversions' ? selected_gm : assignment.conversion_manager_id,
            expire_date: this.state.switch_back_date ? this.state.switch_back_date : assignment.expire_date_str
        }
        const data = await generalManagerFunctions.Update_Manager_Assignment(body);
        this.update_gms_list()
        this.setState({
            button_clicked: false,
            switch_back_date: '',
            switch_clicked: false,
            currently_selected_gm: '',
            current_assignment_pk: -1,
            current_assignment_type: ''
        })
    }

    async cancel_gm_assignment(gm_assignment) {
        this.setState({ button_clicked: true })
        const body = {
            pk: gm_assignment.PK,
            company_id: gm_assignment.company_id,
            sales_manager_id: gm_assignment.sales_manager_id,
            extension_manager_id: gm_assignment.initial_extension_manager_id,
            conversion_manager_id: gm_assignment.sales_manager_id,
            expire_date:  gm_assignment.expire_date_str
        }
        const data = await generalManagerFunctions.Cancel_Manager_Assignment(body);
        this.update_gms_list()
        this.setState({
            button_clicked: false,
            switch_clicked: false,
            currently_selected_gm: '',
            current_assignment_pk: -1,
            current_assignment_type: '',
            switch_back_date: ''
        })
    }

    async send_email(recipients, subject_line, body_text) {
        const email_company_id = this.props.current_user.company_id === 'TMS2' ? 'SLUSA' : 'SLC';
        const body = {
            message: {
                subject: `${email_company_id} ${process.env.REACT_APP_EMAIL_TITLE}: ${subject_line}`,
                body: {
                    contentType: 'Text',
                    content: body_text,
                },
                toRecipients: recipients,
            },
        };
        const inst = this.context.instance;
        const acct = this.context.accounts;
        await utilityFunctions.Send_Email(inst, acct, body);
    }

    get_headers() {
        let header_labels = [
            { label: 'Request Type', property: 'company_id' },
            { label: 'Team', property: 'team_id' },
           ,
         
        ]
        if (!this.state.switch_clicked) {
            header_labels.push({ label: 'Manager', property: 'sales_manager_name' })
        }
        header_labels.push({ label: 'Expiry Date', property: 'expire_date_str' })
        header_labels.push({ label: '', property: '' })
        let headers = [];
        header_labels.map((d) =>
            headers.push({ column_title: d.label, property: d.property, sort_direction: this.state.sort_direction, is_sortable: 'Y' })
        )

        return headers;
    }

    get_outgoing_headers() {
        const header_labels = [
            { label: 'Request Type', property: 'company_id' },
            { label: 'Team', property: 'team_id' },
            { label: 'Manager', property: '' },
            { label: 'Expiry Date', property: 'expire_date_str' },
         
        ]
        let headers = [];
        header_labels.map((d) =>
            headers.push({ column_title: d.label, property: d.property, sort_direction: this.state.sort_direction, is_sortable: 'Y' })
        )

        return headers;
    }

    get_option_buttons(d, type, all_general_managers) {
        if (d) {
            const cancel_button = <Button className="text-xxs align-self-center opacity-75" size='sm' style={{ width: '7rem' }} variant="danger" onClick={() => this.cancel_gm_assignment(d)}>
                { `Cancel`}
            </Button>;
            const switch_button = this.state.switch_clicked ?
                this.state.current_assignment_pk === d.PK && this.state.current_assignment_type===type ?
                <InputGroup>
                    <Form.Select
                        disabled={this.state.button_clicked}
                        onChange={(e) => this.setState({ currently_selected_gm: e.target.value })}
                        name="conversion_manager_id"
                            className="text-xs py-0 flex-grow-1"
                            isInvalid={!this.state.currently_selected_gm}
                        value={this.state.currently_selected_gm}>
                        <option value=''>Select GM</option>
                        {all_general_managers.map((d) =>
                            <option value={d.sales_manager_id} key={d.sales_manager_id}>{d.sales_manager_name}</option>
                        )}
                        </Form.Select>
                        <Form.Control
                            type="date"
                            disabled={this.state.button_clicked}
                            isInvalid={!this.state.switch_back_date}
                            value={this.state.switch_back_date}
                            onChange={(e) => this.setState({ switch_back_date: e.target.value })}
                            className="text-xs p-0 flex-grow-1" />
                        <Button
                            className=" flex-grow-2"
                            size="sm"
                            onClick={() => this.update_gm_assignment(d, type)}
                            disabled={this.state.button_clicked || !this.state.switch_back_date || !this.state.currently_selected_gm}
                            variant={this.state.button_clicked ? 'outline-success' : 'success'}>
                            <div style={{ transform: 'scale(0.8)' }}>{svg_icons.save_icon}</div>
                        </Button>
                        <Button className="opacity-75 text-sm  flex-grow-2" size="sm" variant='danger' title='Cancel changes' onClick={() => this.setState({
                            switch_clicked: false,
                            current_assignment_pk: -1,
                            current_assignment_type: '',
                            currently_selected_gm:''
                        })}>X</Button>
                    </InputGroup> :
                null
                : <Button className="text-xxs align-self-center opacity-75" size='sm' style={{ width: '7rem' }} variant="secondary" onClick={() => this.setState({
                    switch_clicked: true,
                    current_assignment_pk: d.PK,
                    current_assignment_type:type
                })}>Add Temporary GM</Button>;
      
            if (type === 'Extensions' && d.initial_extension_manager_id === d.extension_manager_id) {
                return { button: switch_button, original:'Y' }
            }
            else if (type === 'Extensions' && d.initial_extension_manager_id !== d.extension_manager_id) {
                return { button: cancel_button, original: 'N' };
            }
            else if (type === 'Conversions' && d.conversion_manager_id === d.sales_manager_id) {
                return { button: switch_button, original: 'Y' };
            }
            else if (type === 'Conversions' && d.conversion_manager_id !== d.sales_manager_id) {
                return { button: cancel_button, original: 'N' };
            }

        } else {
            return { button: null, original: 'N' };
        }
    }

    get_data(all_general_managers) {
        let data = [];
        let cell_style = ' text-nowrap text-xs'
        if (all_general_managers) {
            const extension_assignments = all_general_managers.filter(s => s.extension_manager_id === this.props.current_user.salesperson_id || s.initial_extension_manager_id === this.props.current_user.salesperson_id);
            const conversion_assignments = all_general_managers.filter(s => s.conversion_manager_id === this.props.current_user.salesperson_id || s.sales_manager_id === this.props.current_user.salesperson_id)

            extension_assignments.map(d => {
                let orig_options = this.get_option_buttons(d, 'Extensions', all_general_managers);
                //                            this.state.switch_clicked ? null : { cell: d.extension_manager_name, cell_style: cell_style },
                // { cell: orig_options.original === 'Y' ? '' : d.expire_date_str, cell_style: cell_style },
               // { cell: orig_options.button, cell_style: cell_style }
                data.push(
                    {
                        row_style: this.state.current_assignment_pk === d.PK && this.state.current_assignment_type === 'Extensions' ? 'py-3 bg-light-blue text-bold' : '',
                        cells: [
                            { cell: 'Extensions', cell_style: cell_style },
                            { cell: d.team_id, cell_style: cell_style },
                            { cell: d.extension_manager_name, cell_style: cell_style },
                            { cell: orig_options.original === 'Y' ? '' : d.expire_date_str, cell_style: cell_style },
                            { cell: orig_options.button, cell_style: cell_style }
                        ],
                        data: d
                    }
                )
            
           
                
            
            })
            conversion_assignments.map(d => {
                let orig_options = this.get_option_buttons(d, 'Conversions', all_general_managers);
                data.push(
                    {
                        row_style: '',
                        cells: [
                            { cell: 'Conversions', cell_style: cell_style },
                            { cell: d.team_id, cell_style: cell_style },
                           { cell: d.conversion_manager_name, cell_style: cell_style },
                            { cell: orig_options.original === 'Y' ? '' : d.expire_date_str, cell_style: cell_style },
                            { cell: orig_options.button, cell_style: cell_style }
                        ],
                        data: d
                    }
                )

            })



            return data;
        }
    }

    get_outgoing_data(all_general_managers) {
        let data = [];
        let cell_style = ' text-nowrap text-xs'
        if (all_general_managers) {
            const my_assignment = all_general_managers.find(s => s.sales_manager_id === this.props.current_user.salesperson_id);
            if (my_assignment) {
                data.push(
                    {
                        row_style: this.state.current_assignment_pk === my_assignment.PK && this.state.current_assignment_type === 'Extensions' ? 'py-3 bg-light-blue text-bold' : '',
                        cells: [
                            { cell: 'Extensions', cell_style: cell_style },
                            { cell: my_assignment.team_id, cell_style: cell_style },
                            { cell: my_assignment.extension_manager_name, cell_style: cell_style },
                            { cell: my_assignment.initial_extension_manager_id === my_assignment.extension_manager_id ? '' : my_assignment.expire_date_str, cell_style: cell_style },
                         
                        ],
                        data: my_assignment
                    }
                )
                data.push(
                    {
                        row_style: this.state.current_assignment_pk === my_assignment.PK && this.state.current_assignment_type === 'Conversions' ? 'py-3 bg-light-blue text-bold' : '',
                        cells: [
                            { cell: 'Conversions', cell_style: cell_style },
                            { cell: my_assignment.team_id, cell_style: cell_style },
                            { cell: my_assignment.conversion_manager_name, cell_style: cell_style },
                            { cell: my_assignment.sales_manager_id === my_assignment.conversion_manager_id ? '' : my_assignment.expire_date_str, cell_style: cell_style },

                        ],
                        data: my_assignment
                    }
                )
            }
            return data;
        }
    }


    render() {
        const {
            button_clicked,
            extension_selection,
            conversion_selection,
            switch_back_date,
            sort_field,
            sort_direction
        } = this.state;
        const all_general_managers = this.props.all_general_managers ? this.props.all_general_managers.filter(s => s.company_id === this.props.current_user.company_id) : []

        //#region Table Data

        if (all_general_managers && this.props.current_user) {
           
      
            return (
                <div className="d-flex flex-column" style={{ maxHeight: '30rem', overflow: 'auto' }}>

                    <h6 className="tile-title">Manager Assignments</h6>
                    <div className="my-3 d-flex flex-column">
                    <label className="text-bold text-sm text-secondary">My Incoming Requests</label>
                        <SortableTable
                            onSort={(prop) =>
                                this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                            }
                            table_headers={this.get_headers()}
                            table_data={this.get_data(all_general_managers)}
                            row_click={(prop) => null}
                            table_title=""
                            sort_field={sort_field}
                        />
                    </div>

                    <div className="my-3 d-flex flex-column">
                        <label className="text-bold text-sm text-secondary">My Outgoing Requests</label>
                        <SortableTable
                            onSort={(prop) =>
                                this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                            }
                            table_headers={this.get_outgoing_headers()}
                            table_data={this.get_outgoing_data(all_general_managers)}
                            row_click={(prop) => null}
                            table_title=""
                            sort_field={sort_field}
                        />
                    </div>
  

               
   


        

                </div>
            );
        } else {
            return null;
        }


    }
}
