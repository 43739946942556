import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { isBefore, addDays, differenceInDays, addMonths, format } from 'date-fns';
import * as svg_icons from '../../../Media/svg_exports';
import * as requestFunctions from '../../Functions/RequestFunctions'
import * as utilityFunctions from '../../Functions/UtilityFunctions'
import SortableTable from '../../TableComponents/SortableTable';
import sortByStrategy from '../../../Domain/sorting-strategy';
import { MsalContext } from '@azure/msal-react';
export class ActivityHistoryTable extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            sort_field: 'Completed',
            sort_direction: 'L',
            current_action: null,
            selected_request: null,
            cancel_clicked: false,
            processing_message: ''
        };

    }

    async cancel_request(request) {
    
        try {
            if (window.confirm("Are you sure you want to cancel this request?")) {
                this.setState({ cancel_clicked: true }) 
                let requests = this.props.requests;
                if (request.requestor_id === this.props.current_user.salesperson_id) {
                    const request_data = await requestFunctions.Cancel_Request(request.id);
                    const index = requests.findIndex(s => s.id === request_data.id);
                    requests[index] = request_data;
                    this.props.update_selected_account_data('requests',requests)
                    this.props.update_users_request('outgoing',request_data);
                 
                } else {
                    let user_id = this.props.msal_account.username.replaceAll('@scotlynn.com','')
                    const request_data = await requestFunctions.Cancel_Request_For_LAM(request.id, user_id);
                    const index = requests.findIndex(s => s.id === request_data.id);
                    requests[index] = request_data;
                    this.props.update_selected_account_data('requests', requests)
                    this.props.update_users_request('outgoing', request_data);
                }

                let body = `${request.request_name} request was cancelled for ${request.customer_name} by ${this.props.msal_account.name}.`;
                const subject_line = `${request.request_name} Request Cancelled For ${request.customer_id}`;
                const recipients = [];
                if (this.props.role === 'credit' || this.props.role === 'admin') {
                    recipients.push({ emailAddress: { address: this.props.msal_account.username } })
                }
                if (request.requestor_email_address && request.requestor_is_active==='Y') {
                    recipients.push({ emailAddress: { address: request.requestor_email_address } })
                }
                if (request.requestee_email_address && request.requestee_is_active==='Y') {
                    recipients.push({ emailAddress: { address: request.requestee_email_address } })
                }
                this.send_email(recipients, subject_line, body);
              
                this.setState({ cancel_clicked: false })
             
            }


        } catch (e) {
            console.log(e)

        }
    }

    async send_email(recipients, subject_line, body_text) {
        const email_company_id = this.props.current_user.company_id === 'TMS2' ? 'SLUSA' : 'SLC';
        const body = {
            message: {
                subject: `${email_company_id} ${process.env.REACT_APP_EMAIL_TITLE}: ${subject_line}`,
                body: {
                    contentType: 'Text',
                    content: body_text,
                },
                toRecipients: recipients,
            },
        };
        const inst = this.context.instance;
        const acct = this.context.accounts;
        await utilityFunctions.Send_Email(inst, acct, body);
    }

    lam_cell(d) {
        if (d.requestor_id && this.props.current_user) {
            const users_salesperson_id = this.props.current_user.salesperson_id.toUpperCase();
            const cancel_clicked = this.state.cancel_clicked
            if (d.status === 'P' && (d.requestor_id.toUpperCase() === users_salesperson_id || this.props.role === 'admin')) {
                return (
                    <div className="d-flex flex-column" >
                        <bdi>{d.salesperson_id}</bdi>
                        <Button
                            variant={cancel_clicked ? 'outline-danger' : 'danger'}
                            onClick={() => this.cancel_request(d)}
                            disabled={cancel_clicked}
                            size='sm'
                            className="text-xs align-self-start py-0 my-1">
                            {cancel_clicked ? 'Cancelling' : 'Cancel'}
                        </Button>
                    </div>

                )
            }
            else if (d.requestor_sales_manager_id && d.status === 'P' && this.props.role==='gm') {
                if ( d.requestor_sales_manager_id.toUpperCase() === users_salesperson_id) {
                    return (
                        <div className="d-flex flex-column" >
                            <bdi>{d.salesperson_id}</bdi>
                            <Button
                                variant={cancel_clicked ? 'outline-danger' : 'danger'}
                                onClick={() => this.cancel_request(d)}
                                disabled={cancel_clicked}
                                size='sm'
                                className="text-xs align-self-start py-0 my-1">
                                {cancel_clicked ? 'Cancelling' : 'Cancel'}
                            </Button>
                        </div>

                    )
                } else {
                    return (<bdi>{d.salesperson_id}</bdi>)
                }
            }          
        } else {
            return (<bdi>{d.salesperson_id}</bdi>)
        }
     
    }

    get_actions_list() {
        const activity_status_type = this.props.activity_status_type;
        let requests = this.props.requests ? this.props.requests : []
        for (let i = 0; i < requests.length; i++) {
            requests[i].activity_id = requests[i].request_type;
            requests[i].date_due_str = requests[i].date_requested_str;
            requests[i].action_date_str = requests[i].date_completed_str
            requests[i].salesperson_id = requests[i].requestor_id
            requests[i].is_request = true;
        }
        let status_filter = 'C'
        if (activity_status_type === 'Future') {
            status_filter = 'P'
            requests = [];
        } else {
            status_filter = 'C'
        }
        let prospect_actions = this.props.prospect_actions ? this.props.prospect_actions.filter(s => s.status === status_filter):[];
     
      
        const current_action = this.state.current_action;
        const sort_field = this.state.sort_field;
        const sort_direction = this.state.sort_direction;
        
        if (prospect_actions) {
            prospect_actions = prospect_actions.concat(requests.filter(s=>s.status==='C'))
            const sortingStrategies = {
                Activity: (a, b) => a.activity_id.localeCompare(b.activity_id),
                Completed: (a, b) =>new Date(a.action_date_str) - new Date(b.action_date_str),
                Remarks: (a, b) => a.remarks.length - b.remarks.length,
                LAM: (a, b) =>a.salesperson_id.localeCompare(b.salesperson_id)
            };
            prospect_actions = prospect_actions.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
            if (requests.length > 0) {
                prospect_actions = requests.filter(s => s.status === 'P').sort((a,b)=>new Date(a.date_requested)-new Date(b.date_requested)).concat(prospect_actions)
            }
         
            const header_labels = [
                { label: 'Activity', property: 'activity_id' },
                { label: 'Completed', property: 'action_date_str' },
                { label: 'Remarks', property: 'remarks' },
                { label: 'LAM', property: 'salesperson_id' }
            ]
            let headers = [];
            header_labels.map((d) =>
                headers.push({ column_title: d.label, property: d.property, sort_direction: sort_direction, is_sortable: 'Y' })
            )
            let data = [];
            let cell_style ='py-1 text-nowrap pe-3 text-xs'
            prospect_actions.map(d =>
                data.push(
                    {
                        row_style: this.get_row_color(d),
                        cells: [
                            { cell: d.activity_id, cell_style: cell_style },
                            { cell: d.action_date_str, cell_style: cell_style },
                            {
                                cell: <p style={{ whiteSpace: 'pre-wrap' }} className="p-0 m-0">
                                    {d.remarks?d.remarks.replaceAll('&#xA;', '\n\n'):''}
                                </p>, cell_style: cell_style
                            },
                            { cell: this.lam_cell(d),cell_style: cell_style }
                        ],
                        data: d
                    }
                )

            )

            const activity_table =


                    <SortableTable
                        onSort={(prop) =>
                            this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                        }
                        table_headers={headers}
                        table_data={data}
                        row_click={(prop) => this.check_for_request(JSON.parse(prop))}
                        table_title=""
                        sort_field={sort_field}
                    />

               ;
            return (
                activity_table
               )

        } else {return null}
    }

    check_for_request(d) {
        if (d.request_type === 'CREQUEST' || d.request_type === 'ESTBCRED') {
            if (d.is_request && d.status === 'P') {
                this.props.update_selected_request(d)
            }
        }   
    }

    get_row_color(d) {
        if (this.props.selected_request) {
            if (d.is_request && d.id === this.props.selected_request.id) {
                return 'table-selected fw-bold'
            } else if (d.is_request && d.status==='P') {
                return 'bg-warning-pending'
            }
        } else if (d.is_request && d.status === 'P') {
            return 'bg-warning-pending'
        }
        
    }




    render() {
        const { open_tile } = this.state;
        const selected_account = this.props.selected_account;


        return selected_account ? (
            <div className="mt-2 w-100">
                {this.get_actions_list()}
            </div>

        ) : null;
    }
}