import React, { Component } from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    Modal,
    Row,
} from 'react-bootstrap';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';

export class ProspectActivityReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sort_direction: 'H',
            sort_field: 'Completed',
            lam_filter: '',
            activity_filter: '',
            remark_filter: '',
        };
    }

    onClose() {
        this.setState({
            sort_direction: 'H',
            sort_field: 'Completed',
            lam_filter: '',
            activity_filter: '',
            remark_filter: '',
        });
        this.props.onClose();
    }

    renderSalespersonsButtons(current_activities) {
        const new_list = [];
        for (let i = 0; i < current_activities.length; i++) {
            if (new_list.filter((s) => s.name === current_activities[i].name).length === 0) {
                new_list.push({
                    name: current_activities[i].name,
                    num: current_activities.filter((s) => s.name === current_activities[i].name).length,
                });
            }
        }
        const all_length =
            this.state.activity_filter !== ''
                ? this.props.pa_data.list.filter((s) => s.activity_id === this.state.activity_filter).length
                : this.props.pa_data.list.length;

        return (
            <ButtonGroup style={{ flexWrap: 'wrap' }}>
                <Button
                    className="btn-op70 border border-dark text-lg"
                    size="sm"
                    style={{ width: '8rem' }}
                    variant={this.state.lam_filter === '' ? 'secondary' : 'outline-secondary'}
                    onClick={() => this.setState({ lam_filter: '' })}
                >
                    {'All' + ' (' + all_length + ')'}
                </Button>
                {new_list
                    .sort((a, b) => a.num - b.num)
                    .map((d) => (
                        <Button
                            className="btn-op70 border border-dark text-lg"
                            size="sm"
                            style={{ width: '12rem' }}
                            variant={
                                this.state.lam_filter === d.name || this.state.lam_filter === ''
                                    ? 'secondary'
                                    : 'outline-secondary'
                            }
                            onClick={() => this.setState({ lam_filter: d.name })}
                        >
                            {d.name + ' (' + d.num + ')'}
                        </Button>
                    ))}
            </ButtonGroup>
        );
    }

    renderActivityButtons(current_activities) {
        const new_list = [];
        for (let i = 0; i < current_activities.length; i++) {
            if (new_list.filter((s) => s.activity_id === current_activities[i].activity_id).length === 0) {
                new_list.push({
                    activity_id: current_activities[i].activity_id,
                    num: current_activities.filter((s) => s.activity_id === current_activities[i].activity_id).length,
                });
            }
        }

        const all_length =
            this.state.lam_filter !== ''
                ? this.props.pa_data.list.filter((s) => s.name === this.state.lam_filter).length
                : this.props.pa_data.list.length;

        return (
            <ButtonGroup style={{ flexWrap: 'wrap' }}>
                <Button
                    className="btn-op70 border border-dark text-lg"
                    size="sm"
                    style={{ width: '8rem' }}
                    variant={this.state.activity_filter === '' ? 'warning' : 'outline-secondary'}
                    onClick={() => this.setState({ activity_filter: '' })}
                >
                    {'All' + ' (' + all_length + ')'}
                </Button>
                {new_list
                    .sort((a, b) => a.num - b.num)
                    .map((d) => (
                        <Button
                            className="btn-op70 border border-dark text-lg"
                            size="sm"
                            style={{ width: '12rem' }}
                            variant={
                                this.state.activity_filter === d.activity_id || this.state.activity_filter === ''
                                    ? 'warning'
                                    : 'outline-secondary'
                            }
                            onClick={() => this.setState({ activity_filter: d.activity_id })}
                        >
                            {d.activity_id + ' (' + d.num + ')'}
                        </Button>
                    ))}
            </ButtonGroup>
        );
    }

    render() {
        const { sort_direction, sort_field, lam_filter, activity_filter, remark_filter } = this.state;

        const start_date = this.props.pa_start_date;
        const end_date = this.props.pa_end_date;
        let current_activities = this.props.pa_data ? this.props.pa_data.list : [];
        if (lam_filter !== '') {
            current_activities = current_activities.filter((s) => s.name === lam_filter);
        }
        if (activity_filter !== '') {
            current_activities = current_activities.filter((s) => s.activity_id === activity_filter);
        }
        if (remark_filter !== '') {
            current_activities = current_activities.filter((s) =>
                s.remarks.toLowerCase().includes(remark_filter.toLowerCase()),
            );
        }
        //#region Sort
        if (Array.isArray(current_activities)) {
            const sortingStrategies = {
                Completed: (a, b) => new Date(a.action_date) - new Date(b.action_date),
                Activity: (a, b) => a.activity_id.localeCompare(b.activity_id),
                Account: (a, b) => a.customer_name.localeCompare(b.customer_name),
                LAM: (a, b) => a.name.localeCompare(b.name),
                Remark: (a, b) => a.remarks.length - b.remarks.length,
            };

            current_activities = current_activities.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
        }

        //#endregion Sort

        const start_date_title = start_date.month + '-' + start_date.day + '-' + start_date.year;
        const end_date_title = end_date.month + '-' + end_date.day + '-' + end_date.year;
        const file_name = `PA Report ${start_date_title} to ${end_date_title}`;
        const download_icon = (
            <div
                className="ps-3 align-self-center hover-over"
                style={{ transform: 'scale(1.2)' }}
                title="Download Report"
                onClick={() => utilityFunctions.Export_To_CSV(current_activities, file_name)}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg>
            </div>
        );

        const activity_headers = [
            { column_title: 'Completed', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Activity', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Account', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'LAM', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Remark', sort_direction: sort_direction, is_sortable: 'N' },
        ];
        const activity_data = [];
        if (current_activities) {
            for (let i = 0; i < current_activities.length; i++) {
                const remark_cell = (
                    <p style={{ whiteSpace: 'pre-wrap' }} className="p-0 m-0">
                        {current_activities[i].remarks ? current_activities[i].remarks.replaceAll('&#xA;', '\n\n') : ''}
                    </p>
                );

                const completed_date = (
                    <label style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        {current_activities[i].action_date ? current_activities[i].action_date : '-'}
                    </label>
                );
                const customer_cell = (
                    <label style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        {current_activities[i].customer_name}
                    </label>
                );
                const name_cell = (
                    <label style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>{current_activities[i].name}</label>
                );
                activity_data.push({
                    row_style: '',
                    cells: [
                        { cell: completed_date, cell_style: 'py-0' },
                        { cell: current_activities[i].activity_id, cell_style: 'py-0' },
                        { cell: customer_cell, cell_style: 'py-0' },
                        { cell: name_cell, cell_style: 'py-0' },
                        { cell: remark_cell, cell_style: 'py-0 w-50' },
                    ],
                    data: current_activities[i],
                });
            }
        }

        const activity_table = (
            <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                row_click={() => null}
                table_headers={activity_headers}
                table_data={activity_data}
                table_title={'Prospecting Activities (' + current_activities.length + ')'}
                sort_field={sort_field}
            />
        );

        return (
            <Modal
                {...this.props}
                size="xl"
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                contentClassName="light-modal"
            >
                <Modal.Header className="w-100">
                    <Container className="w-100" fluid="xs">
                        <Row>
                            <Col xs={2}>
                                <Button
                                    variant="outline-secondary"
                                    className="btn-op70 outline-0 opacity-50"
                                    onClick={() => this.onClose()}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-x-lg"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                    </svg>
                                </Button>
                            </Col>
                            <Col xs={8} className="d-flex flex-row justify-content-center">
                                <h4 className="text-muted">Prospect Activity Report </h4>
                                {download_icon}
                            </Col>
                            <Col xs={2}></Col>
                        </Row>
                        {start_date && end_date ? (
                            <Row>
                                <Col>
                                    <h6 className="w-100 text-center">
                                        {start_date.month +
                                            '/' +
                                            start_date.day +
                                            '/' +
                                            start_date.year +
                                            '   ->   ' +
                                            end_date.month +
                                            '/' +
                                            end_date.day +
                                            '/' +
                                            end_date.year}
                                    </h6>
                                </Col>
                            </Row>
                        ) : null}
                    </Container>
                </Modal.Header>

                <Modal.Body className="show-grid mb-5">
                    {this.props.pa_data.loaded ? (
                        <Container className="w-100" fluid="xs">
                            <Row>
                                <Col>{this.renderSalespersonsButtons(current_activities)}</Col>
                            </Row>
                            <Row>
                                <Col>{this.renderActivityButtons(current_activities)}</Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <div className="d-flex flex-row border rounded w-50 ps-2">
                                        <small className="text-lg text-nowrap align-self-center">
                                            Search By Remark:
                                        </small>
                                        <Form.Control
                                            value={remark_filter}
                                            onChange={(e) => this.setState({ remark_filter: e.target.value })}
                                            className="border-0 outline-0 text-lg align-self-center"
                                        />
                                        <Button
                                            className="btn-op70 border-0 outline-0"
                                            size="sm"
                                            onClick={() => this.setState({ remark_filter: '' })}
                                            variant="outline-secondary"
                                        >
                                            X
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ overflow: 'auto' }} className="d-flex flex-column p-2">
                                        {activity_table}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    ) : (
                        <Alert variant="secondary">
                            <h4 className="w-100 text-center">Loading...</h4>
                        </Alert>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}
