
const get_options_w_token = (token) => {
    return {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    }
};

const put_options = (action) => {
    return {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action),
    }
}
const post_options = (action) => {
    return {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action),
    }
}


export async function Get_By_Manager(company_id,sales_manager_id) {
    try {
        const response = await fetch(`ProspectAction/GetByManager/${company_id}/${sales_manager_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Get_By_Salesperson(company_id, salesperson_id) {
    try {
        const response = await fetch(`ProspectAction/GetBySalesperson/${company_id}/${salesperson_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Get_By_Customer(company_id, customer_id) {
    try {
        const response = await fetch(`ProspectAction/GetByCustomer/${company_id}/${customer_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Update(action) {
    try {
        const response = await fetch('ProspectAction', put_options(action));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
        
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Post(action) {
    
    try {
        const response = await fetch('ProspectAction', post_options(action));
        const data = await response.json();       
        if (data.length > 0) {
            return data[0]
        } else {
            return null
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Sendpack(action) {
    try {
        const response = await fetch('ProspectAction/Sendpack', post_options(action));
        const data = await response.json();
        if (data.length > 0) {
            return data[0]
        } else {
            return null
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Claim(action) {
    const response = await fetch('ProspectAction/Claim', post_options(action));
    const data = await response.json();
    if (data.length > 0) {
        return data[0]
    } else {
        return null
    }
}

export async function DNU(action) {
    try {
        const response = await fetch('ProspectAction/DNU', post_options(action));
        const data = await response.json();
        if (data.length > 0) {
            return data[0]
        } else {
            return null
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Gifted(action) {
    try {
        const response = await fetch('ProspectAction/Gifted', post_options(action));
        const data = await response.json();
        if (data.length > 0) {
            return data[0]
        } else {
            return null
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Release(action) {
    const response = await fetch('ProspectAction/Release', post_options(action));
    const data = await response.json();
    if (data.length > 0) {
        return data[0]
    } else {
        return null
    }
}

export async function Release_For_LAM(action) {
    try {
        const response = await fetch('ProspectAction/ReleaseForLam', post_options(action));
        const data = await response.json();
        if (data.length > 0) {
            return data[0]
        } else {
            return null
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}

