export async function Get_All_General_Managers() {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`generalmanager/GetAllGeneralManagers`, options);
        
        const data = await response.json();
        return data;

    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Get_General_Manager_By_ID(company_id, sales_manager_id) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`generalmanager/get_general_manager_by_id/${company_id}/${sales_manager_id}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Update_Manager_Assignment(assignment) {
    try {
        const options = {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(assignment),
        };
        const response = await fetch('generalmanager/UpdateGeneralManagerAssignment', options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Cancel_Manager_Assignment(assignment) {
    try {
        const options = {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(assignment),
        };
        const response = await fetch('generalmanager/CancelGeneralManagerAssignment', options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}