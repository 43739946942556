import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import * as requestFunctions from '../Functions/RequestFunctions';
import * as customerFunctions from '../Functions/CustomerFunctions';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import { format} from 'date-fns'
import * as svg_icons from '../../Media/svg_exports';
import { MsalContext } from '@azure/msal-react';
import NoDataAlert from '../PageComponents/NoDataAlert';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';

export class CreditRequests extends Component {
    static displayName = CreditRequests.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            additional_credit_remarks: '',
            selected_request: null,
            message: '',
            search_company: 'both',
            request_status_type: 'P',
            request_id_type: 'ESTBCRED',
            search_input: '',
            request_index: 0,
            sort_field: 'Completed',
            sort_direction: 'L',
            record_length:100
        };
    }


    update_requests(prop) {
        let users_requests = this.update_list(this.props.users_requests,prop);
        this.props.update_users_request('incoming',users_requests);
    }

    update_list(list, item) {
        let c_list = list
        let index = c_list.findIndex(s=>s.id===item.id)
        c_list[index] = item;
        return c_list;
    }

    async complete_request(request, decision) {
        let new_message = decision === 'Denied' ? `Denying ${request.request_name} Request...` : `Approving ${request.request_name} Request...`;
        this.setState({ clicked: true, message: new_message });

        const selected_account_data = this.props.selected_account_data;              
        const current_user_id = this.props.msal_account ? this.props.msal_account.username.replaceAll('@scotlynn.com', '') : 'Unknown';
       
        try {
            await this.email_response(request, decision);
            let selected_account = selected_account_data ? selected_account_data.account : null;      
            let requests_list = selected_account_data ? selected_account_data.requests ? selected_account_data.requests : [] : [];
            let customer_audit_list = selected_account_data ? selected_account_data.customer_audit ? selected_account_data.customer_audit : [] : [];          

            let body = {
                id: request.id,
                company_id: request.company_id,
                customer_id: request.customer_id,
                user_id: current_user_id,
                remarks: this.state.additional_credit_remarks,
                role: this.props.role,
                category: request.salesperson_category
            }


            if (request.request_type === 'ESTBCRED' && decision === 'Approved') {
                const request_data = await requestFunctions.Approve_Precheck(body);        
                this.props.update_users_request('incoming', request_data);

                const audit_body = {
                    company_id: request.company_id,
                    customer_id: request.customer_id,
                    changed_by_user: current_user_id,
                    initial_values: JSON.stringify({
                        credit_status: request.credit_status,
                        crm_credit_approval_date: request.crm_credit_approval_date,
                    }),
                    new_values: JSON.stringify({
                        credit_status: 'Approved',
                        crm_credit_approval_date: format(new Date(), 'MM-dd-yyyy')

                    })
                }
                const customer_audit_data = await customerFunctions.Insert_Customer_Audit(audit_body);
                if (selected_account) {
                    selected_account.credit_status = 'Approved';
                    selected_account.crm_credit_approval_date = format(new Date(), 'MM-dd-yyyy');
                    requests_list = this.update_list(requests_list, request_data);
                    customer_audit_list.push(customer_audit_data)                                      
                }
            }
            else if (request.request_type === 'ESTBCRED' && decision === 'Denied') {
                const request_data = await requestFunctions.Deny_Precheck(body);
                this.props.update_users_request('incoming', request_data);
                    if (selected_account) {
                        selected_account.credit_status = 'Denied';
                    }
            }
            else if (request.request_type === 'CREQUEST' && decision === 'Approved') {
                const request_data = await requestFunctions.Approve_Conversion(body);
                this.props.update_users_request('incoming', request_data);
                let audit_body = {
                    company_id: request.company_id,
                    customer_id: request.customer_id,
                    changed_by_user: current_user_id,
                    initial_values: {
                        mark_plan_id: request.mark_plan_id,
                        start_date: request.customer_start_date,
                        conversion_date: request.customer_conversion_date,
                        type_of: request.type_of
                    },
                    new_values: {
                        mark_plan_id: 'CUSTNEW',
                        start_date: format(new Date(), 'MM-dd-yyyy'),
                        conversion_date: format(new Date(), 'MM-dd-yyyy'),
                        type_of: 'Customer'
                    }
                }
                if (this.props.role === 'credit' || this.props.role === 'admin') {
                    audit_body.initial_values.category = request.category
                    audit_body.new_values.category = request.salesperson_category
                }
                audit_body.initial_values = JSON.stringify(audit_body.initial_values);
                audit_body.new_values = JSON.stringify(audit_body.new_values);
                const customer_audit_data = await customerFunctions.Insert_Customer_Audit(audit_body);
                    if (selected_account) {                        
                        selected_account.mark_plan_id = 'CUSTNEW';
                        selected_account.start_date = format(new Date(), 'MM-dd-yyyy');
                        selected_account.crm_convert_date = format(new Date(), 'MM-dd-yyyy');
                        selected_account.conversion_date = format(new Date(), 'MM-dd-yyyy');
                        selected_account.type_of = 'Customer';
                        if (this.props.role === 'credit' || this.props.role === 'admin') {
                            selected_account.category = request.salesperson_category;
                        }
                        requests_list = this.update_list(requests_list, request_data);
                        customer_audit_list.push(customer_audit_data) 
                    }
            }
            else if (request.request_type === 'CREQUEST' && decision === 'Denied') {
                const request_data = await requestFunctions.Deny_Conversion(body);
                this.props.update_users_request('incoming', request_data);
            }
            if (selected_account) {
                this.props.update_selected_account_data('account', selected_account);
                this.props.update_selected_account_data('requests', requests_list);
                this.props.update_selected_account_data('customer_audit', customer_audit_list);
            }
                this.setState({ clicked: false, additional_credit_remarks: '', selected_request: null, message:'' })
        }
        catch (e) {
            console.log(e)
            const error_body = {
                status: -1,
                message: e,
                company_id: request.company_id,
                function_name: 'CreditRequest -> complete_request',
                primary_pk: request.id,
                table_name: 'crm_requests',
                user_id: current_user_id
            }
            await utilityFunctions.Log_Error(error_body)
        }
       
    }

    async email_response( request_data, decision) {
        try {

            const subject_line = process.env.REACT_APP_EMAIL_TITLE + `: ${request_data.request_name} Request ${decision} For ${request_data.customer_id}`;

            let message_text =
                `${request_data.request_name} request has been ${decision.toLowerCase()} for ${request_data.customer_name} by ${this.props.msal_account.name}`;
            if (this.state.additional_remarks) {
                message_text = `${message_text} with the following remarks: ${this.state.additional_remarks}`
            }

            let recipients = [];
            if (request_data.requestor_email_address) {
                recipients.push({
                    emailAddress: {
                        address: request_data.requestor_email_address,
                    }
                })
            }
            if (request_data.requestee_email_address) {
                recipients.push({
                    emailAddress: {
                        address: request_data.requestee_email_address,
                    }
                })
            }

            if (!recipients.find(s => s.emailAddress === 'customercredit@scotlynn.com')) {
                recipients.push({
                    emailAddress: {
                        address: this.props.role === 'credit' ? process.env.REACT_APP_CREDIT_EMAIL : this.props.msal_account.username
                    }
                })
            }
            const message_body = {
                message: {
                    subject: subject_line,
                    body: {
                        contentType: 'Text',
                        content: message_text,
                    },
                    toRecipients: recipients,
                },
            }
            const inst = this.context.instance;
            const acct = this.context.accounts;
            await utilityFunctions.Send_Email(inst, acct, message_body)
        } catch (e) {
            console.log(e)
            const error_body = {
                status: -1,
                message: e,
                company_id: request_data.company_id,
                function_name: 'CreditRequest -> complete_request',
                primary_pk: request_data.id,
                table_name: 'crm_requests',
                user_id: this.props.msal_account.username.replaceAll('@scotlynn.com','')
            }
            await utilityFunctions.Log_Error(error_body)
        }
    }

    get_headers(sort_direction) {
        let headers_style = 'text-xs pe-2'
        const completed_headers = [
            { column_title: 'Company', sort_direction: sort_direction, is_sortable: 'Y', header_style: headers_style },
            { column_title: 'Account', sort_direction: sort_direction, is_sortable: 'Y', header_style: headers_style },
            { column_title: 'Requestor', sort_direction: sort_direction, is_sortable: 'Y', header_style: headers_style },
            { column_title: 'Completed', sort_direction: sort_direction, is_sortable: 'Y', header_style: headers_style },
            { column_title: 'Remarks', sort_direction: sort_direction, is_sortable: 'Y', header_style: headers_style }            
        ];
        return completed_headers;
    }

    get_completed_table(list, sort_field, sort_direction) {
        let requests = list;
        let data = [];
        let cells_style = 'text-xs text-nowrap p-1 pe-2'

        if (requests.length) {
            const sortingStrategies = {
                'Company': (a, b) => a.company_id.localeCompare(b.company_id),
                'Account': (a, b) => a.customer_id.localeCompare(b.customer_id),
                'Requestor': (a, b) => a.requestor_id.localeCompare(b.requestor_id),
                'Completed': (a, b) => new Date(a.date_completed_str) - new Date(b.date_completed_str),
                'Remarks': (a, b) => a.remarks-b.remarks               
            };
            requests = requests.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));


            requests.map((d) => {
                data.push({
                    row_style: '',
                    cells: [
                        { cell: d.company_id === 'TMS' ? 'SLC' : 'SLUSA', cell_style: cells_style },
                        { cell: d.customer_id, cell_style: cells_style },
                        { cell: d.requestor_id, cell_style: cells_style },
                        {
                            cell: d.date_completed_str,
                            cell_style: cells_style,
                        },
                        {
                            cell: <p style={{ whiteSpace: 'pre-wrap' }} className="p-0 m-0">
                                {d.remarks ? d.remarks.replaceAll('&#xA;', '\n\n') : ''}
                            </p>,
                            cell_style: cells_style,
                        }
                    ],
                    data: d,
                });
            })
            return (<SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                table_headers={this.get_headers(sort_direction)}
                table_data={data}
                row_click={(prop) => this.props.view_account({ company_id: JSON.parse(prop).company_id, id: JSON.parse(prop).customer_id }, requests)}
                table_title=""
                sort_field={sort_field}
            />)
        } else {
            return (<NoDataAlert title='No Requests' />)
        }
        
    }


    render() {
        const {
            clicked,
            additional_credit_remarks,
            message,
            search_company,
            request_status_type,
            request_id_type,
            request_index,
            search_input,
            sort_field,
            sort_direction,
            record_length
        } = this.state;

        let requests = this.props.users_requests ? this.props.users_requests: [];
        let master_request_list = this.props.users_requests ? this.props.users_requests : [];
      
        let current_request = null
        
        if (requests.length) {
            requests = requests.filter(s => s.status === request_status_type && s.request_type === request_id_type)   
            if (search_company !=='both') {
                requests = requests.filter(s => s.company_id === search_company);
                master_request_list = master_request_list.filter(s => s.company_id === search_company)
            }
            if (search_input) {
                let upper_search_input = search_input.toUpperCase();
                requests = requests.filter(s =>
                    s.customer_id.toUpperCase().includes(upper_search_input) ||
                    s.requestor_id.toUpperCase().includes(upper_search_input) ||
                    s.customer_name.toUpperCase().includes(upper_search_input)
                );
                
            }
            if (request_index > -1 && request_index < requests.length) {
                current_request = requests[request_index]
            }
        }


        const completed_requests =
            <div className="d-flex flex-column mt-3" style={{ maxHeight: '30rem', overflow: 'auto' }}>
            {this.get_completed_table(requests, sort_field, sort_direction)}
        </div>
        const width = this.props.width ? parseInt(this.props.width) : 0;
        const pending_requests = current_request ? <div className="d-flex flex-column justify-content-center p-0 mt-3 flex-grow-1">

            <InputGroup className="mb-3">
                <InputGroup.Text className="text-xs w-25">Requested:</InputGroup.Text>
                <InputGroup.Text className="text-xs bg-white w-75">
                    {current_request.date_requested_str}
                </InputGroup.Text>
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text className="text-xs w-25">LAM:</InputGroup.Text>
                <InputGroup.Text className="text-xs bg-white w-75">
                    {current_request.requestor_name +
                        ' (' +
                        current_request.requestor_rev_code +
                        ')'}
                </InputGroup.Text>
            </InputGroup>
            {width < 1200 ?
                <div className="d-flex flex-column w-100">
                    <InputGroup className="mb-3">
                        <InputGroup.Text className="text-xs w-25">Account:</InputGroup.Text>
                        <InputGroup.Text className="text-xs bg-white w-75">
                            <label className="hover-over underlined text-primary" onClick={() => this.props.view_account({ company_id: current_request.company_id, id: current_request.customer_id }, requests)}>
                                {current_request.customer_name}
                            </label>
                        </InputGroup.Text>
                    </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="text-xs w-25">Code:</InputGroup.Text>
                            <InputGroup.Text className="text-xs w-75 bg-white">{current_request.customer_id}</InputGroup.Text>
                        </InputGroup>
                </div>   :
                <InputGroup className="mb-3">
                    <InputGroup.Text className="text-xs w-25">Code:</InputGroup.Text>
                    <InputGroup.Text className="text-xs w-25 bg-white">{current_request.customer_id}</InputGroup.Text>
                    <InputGroup.Text className="text-xs w-25">Account:</InputGroup.Text>
                    <InputGroup.Text className="text-xs bg-white w-25">
                        <label className="hover-over underlined text-primary" onClick={() => this.props.view_account({ company_id: current_request.company_id, id: current_request.customer_id }, requests)}>
                            {current_request.customer_name}
                        </label>
                    </InputGroup.Text>

                </InputGroup>
            }
            
            <div className="d-flex flex-column border rounded p-1 mb-2 text-xs">
                <small className="text-muted ps-1">Remarks: </small>
                <p style={{ whiteSpace: 'pre-wrap', lineHeight: '12px' }} className="p-0 m-0 ps-1">
                    {current_request.remarks ? current_request.remarks.replaceAll('&#xA;', '\n\n') : ''}
                </p>
                <hr className="m-1 mx-0 p-1" />

                <Form.Control
                    as="textarea"
                    placeholder="Additional remarks..."
                    className="ps-1 border-0 text-xs"
                    onChange={(e) => this.setState({ additional_credit_remarks: e.target.value })}
                    value={additional_credit_remarks}
                />
            </div>
            <div className="d-flex flex-row justify-content-around mt-2">
                <Button
                    variant={clicked ? 'outline-danger' : 'danger'}
                    className=" text-xs btn-request-width"
                    disabled={clicked}
                    size="sm"
                    onClick={() => this.complete_request(current_request, 'Denied')}
                >
                    {current_request.request_type === 'CREQUEST' ? 'Deny Conversion' : 'Deny Precheck'}
                </Button>

                <Button
                    variant={clicked ? 'outline-success' : 'success'}
                    className=" text-xs btn-request-width"
                    disabled={clicked}
                    onClick={() => this.complete_request(current_request, 'Approved')}
                    size="sm"
                >
                    {current_request.request_type === 'CREQUEST' ? 'Approve Conversion' : 'Approve Precheck'}
                </Button>

            </div>
            <label className="w-100 align-self-center text-center mt-3 text-sm text-bold"> {`${request_index + 1} / ${requests.length}`}</label>
            {message ? <i className={`text-sm w-100 text-center py-3 ${message.includes('Denying') ? 'text-danger' : 'text-success'}`}>{message}</i> : null}
        </div> :
            <NoDataAlert title='No Pending Requests' />;

        const request_selector = <InputGroup className={ `justify-content-center flex-grow-1  d-flex flex-${width<1200?'column':'row'}`}>
            <Form.Select
                className="text-xs"
                style={width < 1200 ? { width: '100%', borderRadius: '4px', marginTop: '1rem' } : null}
                value={search_company}
                onChange={(e) => this.setState({ search_company: e.target.value, request_index: 0 })}
            >
                <option value="both">SLC/SLUSA</option>
                <option value="TMS">SLC</option>
                <option value="TMS2">SLUSA</option>
            </Form.Select>
            <Form.Select
                className="text-xs"
                style={width < 1200 ? { width: '100%', borderRadius: '4px', marginTop: '1rem' } : null}
                value={request_id_type}
                onChange={(e) => this.setState({ request_id_type: e.target.value, request_index: 0 })}
            >
                <option value="ESTBCRED">

                    {`Precheck Requests (${master_request_list.filter(s => s.request_type === 'ESTBCRED' && s.status === request_status_type).length})`}
                    
                </option>
                <option value="CREQUEST">
                    {`Conversion Requests (${master_request_list.filter(s => s.request_type === 'CREQUEST' && s.status === request_status_type).length})`}
                </option>
            </Form.Select>


            
            <Form.Select
                className="text-xs"
                style={width < 1200 ? { width: '100%', borderRadius: '4px', marginTop: '1rem' } : null}
                value={request_status_type}
                onChange={(e) => this.setState({ request_status_type: e.target.value, request_index: 0 })}
            >
                <option value="P">Pending</option>
                <option value="C">Completed</option>
            </Form.Select>
            <Form.Control
                className={`text-xs ${width < 1200 ? '' :'border-end-0'} `}
                style={width < 1200 ? { width: '100%', borderRadius: '4px', marginTop:'1rem'} : null}
                value={search_input}
                placeholder="Search"
                onChange={(e) => this.setState({ search_input: e.target.value, request_index: 0 })}
            />
            {width < 1200 ?
                null :
                <InputGroup.Text
                    title='Clear'
                    className='text-xs bg-white border-start-0'
                    onClick={() => this.setState({ search_input: '' })}>
                    <bdi className={search_input ? 'hover-over' : 'opacity-75'}>X</bdi>
                </InputGroup.Text>
            }
           
        </InputGroup>


        return (
            <div className="d-flex flex-column justify-content-start w-100 align-self-center" >
              
                <div className="d-flex flex-row justify-content-between w-100 align-self-center" >

                    <label className="hover-over align-self-center" style={{width:'2rem'}}
                        onClick={() => this.setState({ request_index: !clicked && request_index > 0 && request_status_type === 'P' ? request_index - 1 : request_index })}>
                        {request_index > 0 && request_status_type === 'P' ? svg_icons.left_chevron : null}
                    </label>
                    <div className="d-flex flex-column flex-grow-1">
                        {request_selector}
                        {request_status_type === 'P' ? pending_requests : completed_requests}
                        
                    </div>
                   

                    <label className="hover-over align-self-center" style={{ width: '2rem' }}
                        onClick={() => this.setState({ request_index: !clicked && request_index < requests.length && request_status_type === 'P' ? request_index + 1 : request_index })}>
                        {request_index < requests.length - 1 && request_status_type === 'P' ? svg_icons.right_chevron : null}
                    </label>
                </div>
            </div>
        
        );
    }
}
