import React, { Component } from 'react';
import { Button, Col, Container, Form, Modal, Row, InputGroup } from 'react-bootstrap';
import '../Styles/Datepicker.css'

export class ManagerAssignmentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clicked: false
        }

    }


    onClose() {
        this.setState({ clicked: false })
        this.props.onClose();
    }

    async put_assignment() {
        this.setState({ clicked: true })
        this.props.put_assignment().then((res) => {
            this.setState({ clicked: false })
        })
    }
    async cancel_assignment() {
        this.setState({ clicked: true })
        this.props.cancel_assignment().then((res) => {
            this.setState({ clicked: false })
        })
    }

    handle_edit_change(e) {
        let current_assignment = this.props.selected_manager_assignment;
        current_assignment[e.target.name] = e.target.value;
        this.props.update_assignment(current_assignment)
    }

    get_list() {
        if (this.props.selected_manager_assignment) {
            const general_manager_assignments = this.props.general_manager_assignments ? this.props.general_manager_assignments.filter(s => s.company_id === this.props.selected_manager_assignment.company_id) : [];
            let new_list = [];
            for (let i = 0; i < general_manager_assignments.length; i++) {
                if (!new_list.find(s => s.value === general_manager_assignments[i].sales_manager_id)) {
                    new_list.push({ label: `${general_manager_assignments[i].sales_manager_name}`, value: general_manager_assignments[i].sales_manager_id })
                }
            }
            return new_list;
        }
        return [];
    }

    render() {
        const { clicked } = this.state;
        const selected_manager_assignment = this.props.selected_manager_assignment;
        let expire_date = null;
        let manager_list = this.get_list();
        if (selected_manager_assignment) {
            if (selected_manager_assignment.expire_date) {
                expire_date = new Date(selected_manager_assignment.expire_date)
            }
        }

        return (<Modal
            {...this.props}
            size="lg"
            className="bg-transparent-gray"
            fullscreen="md-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="w-100 ">
                <Container fluid="xs" className="w-100 p-0">
                    <Row>
                        <Col>
                            <div className="d-flex flex-row justify-content-between">
                                <Button variant="outline secondary btn-op70 p-0" onClick={() => this.onClose()}>
                                    X
                                </Button>
                                {selected_manager_assignment ?
                                    <div>
                                        <h5 className="pt-2 mb-0">{selected_manager_assignment.sales_manager_name}</h5>
                                        <h6 className="pt-2 mb-0 opacity-50 text-center">{selected_manager_assignment.team_id}</h6>
                                    </div> :
                                    <h5 className="pt-2 mb-0">Loading...</h5>
                                }                                                        
                                <div></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body className="show-grid ">
                {selected_manager_assignment ?
                    <div className="d-flex flex-column justify-content-start">
                        <InputGroup className={'d-flex flex-row justify-content-between px-2 py-1'}>
                            <InputGroup.Text className="text-lg align-self-center w-25">Initial Conversions Recipient:</InputGroup.Text>
                            <Form.Control
                                disabled={true}
                                value={selected_manager_assignment.sales_manager_name ? selected_manager_assignment.sales_manager_name : ''}
                                className="text-lg p-1 w-25"
                            />
                            <InputGroup.Text className="text-lg align-self-center w-25">Assigned Conversions Recipient:</InputGroup.Text>
                            <Form.Select
                                onChange={(e) => this.handle_edit_change(e)}
                                name='conversion_manager_id'
                                value={selected_manager_assignment.conversion_manager_id ? selected_manager_assignment.conversion_manager_id : ''}
                                className="text-lg p-1 w-25"
                            >
                                {manager_list.sort((a, b) => a.label.localeCompare(b.label)).map((d) => (
                                    <option value={d.value} key={d.value}>
                                        {d.value === selected_manager_assignment.sales_manager_id ? 'Initial' : d.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                        <InputGroup className={'d-flex flex-row justify-content-between px-2 py-1'}>
                            <InputGroup.Text className="text-lg align-self-center w-25">Initial Extensions Recipient:</InputGroup.Text>
                            <Form.Control
                                disabled={true}
                                value={selected_manager_assignment ?
                                    selected_manager_assignment.initial_extension_manager_name ? `${selected_manager_assignment.initial_extension_manager_name}` : ''
                                    : ''}
                                className="text-lg p-1 w-25"
                            />
                            <InputGroup.Text className="text-lg align-self-center w-25">Assigned Extensions Recipient:</InputGroup.Text>
                            <Form.Select
                                onChange={(e) => this.handle_edit_change(e)}
                                name='extension_manager_id'
                                value={selected_manager_assignment.extension_manager_id ? selected_manager_assignment.extension_manager_id : ''}
                                className="text-lg p-1 w-25"
                            >
                                {manager_list.sort((a, b) => a.label.localeCompare(b.label)).map((d) => (
                                    <option value={d.value} key={d.value}>
                                        {d.value === selected_manager_assignment.initial_extension_manager_id?'Initial': d.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </InputGroup>


                 

                        <InputGroup className={'d-flex flex-row justify-content-between px-2 py-1'}>
                            <InputGroup.Text className="text-lg align-self-center w-25">Effective Date:</InputGroup.Text>
                            <Form.Control
                            type="date"
                                disabled={true}
                                value={selected_manager_assignment.effective_date ? selected_manager_assignment.effective_date : ''}
                                className="text-lg p-1 w-25"
                            />
                            <InputGroup.Text className="text-lg align-self-center w-25">Expire Date:</InputGroup.Text>
                            <Form.Control
                                type="date"
                                onChange={(e) => this.handle_edit_change(e)}
                                name='expire_date'
                                isInvalid={expire_date < Date.now()}
                                title={expire_date < Date.now()?'Expire date must be a future date':''}
                                value={selected_manager_assignment.expire_date}
                                className="text-lg p-1 w-25"
                            />
                        </InputGroup>
                        </div>
                        :
                        null}

            </Modal.Body>
            <Modal.Footer>

                {selected_manager_assignment ?
                    <div className="d-flex flex-row justify-content-center w-100" title={expire_date < Date.now() ? 'Expire date must be a future date' : 'Update'}>
                      
                        <Button
                            variant={clicked ? 'outline-success' : 'success'}
                            disabled={clicked || expire_date < Date.now()}
                            className="btn-op70 align-self-center text-lg"
                            onClick={() => this.put_assignment()}
                        >
                            {clicked ? 'Saving...' : 'Update'}
                        </Button>
                        {selected_manager_assignment.expire_date ?<Button
                            variant={clicked ? 'outline-danger' : 'danger'}
                            disabled={clicked || !selected_manager_assignment.expire_date}
                            className="btn-op70 align-self-center text-lg ms-4"
                            onClick={() => this.cancel_assignment()}
                        >
                            {clicked ? 'Cancelling...' : 'Cancel'}
                        </Button>:null}
                    </div>
                    :
                    null}


            </Modal.Footer>
        </Modal>)
    }
}