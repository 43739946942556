import React, { Component } from 'react';
import { isAfter, addDays, isBefore, addHours } from 'date-fns';
import * as baseColors from '../Functions/BaseColors';
import {
    PieChart,
    Pie,
    Cell,
} from 'recharts';

export class AccountOverviewGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account_type: 'Prospect',
        };
    }



    get_prospect_age_data(prospects_list) {
        const less_than_15_days_old = prospects_list.filter(s => isAfter(new Date(s.start_date), addDays(new Date(), -15)));
        const more_than_15_days = prospects_list.filter(s => isBefore(new Date(s.start_date), addDays(new Date(), -15)));
        let data_list = [];
        data_list.push(
            { "name": "Less than 15 days old", value: less_than_15_days_old.length },
            { "name": "More than 15 days old", value: more_than_15_days.length }
        )

        return data_list;
    }

    get_prospect_expiry_data(prospects_list, prospects_num) {
        const expires_10_days = prospects_list.filter(s => isBefore(new Date(s.expiry_date), addDays(new Date(), 10)) && isAfter(new Date(s.expiry_date), addHours(new Date(),24)));
        const expires_24_hours = prospects_list.filter(s => isBefore(new Date(s.expiry_date), addHours(new Date(), 24)) && isAfter(new Date(s.expiry_date), new Date()));
        const num_not_expire_10_days = prospects_num - (expires_10_days.length) - (expires_24_hours.length);
        let data_list = [];
        data_list.push(
            { "name": "Not expiring within 10 days", value: num_not_expire_10_days },
            { "name": "Expiring within 10 days", value: expires_10_days.length },
            { "name": "Expiring within 24 hours", value: expires_24_hours.length }
        )

        return data_list;
    }

    render() {
        const { account_type } = this.state;
        const prospects_list = this.props.prospects_list ? this.props.prospects_list : []
        const customers_list = this.props.customers_list ? this.props.customers_list : []

        let selected_list = account_type === 'Prospect' ? prospects_list : customers_list;

        const less_than_15_days_old = selected_list.filter(s => isAfter(new Date(s.start_date), addDays(new Date(), -15)));
        const more_than_15_days = selected_list.filter(s => isBefore(new Date(s.start_date), addDays(new Date(), -15)));
        const expires_10_days = selected_list.filter(s => isBefore(new Date(s.expiry_date), addDays(new Date(), 10)) && isAfter(new Date(s.expiry_date), addHours(new Date(), 24)));
        const expires_24_hours = selected_list.filter(s => isBefore(new Date(s.expiry_date), addHours(new Date(), 24)) && isAfter(new Date(s.expiry_date), new Date()));
        const num_not_expire_10_days = selected_list.length - (expires_10_days.length) - (expires_24_hours.length);


        const AGE_COLORS = [baseColors.dark_green, baseColors.dark_blue];
        const EXPIRY_COLORS = [baseColors.light_gray, baseColors.dark_yellow, baseColors.dark_red];
        const age_data = [
            { "name": "Less than 15 days old", value: less_than_15_days_old.length },
            { "name": "More than 15 days old", value: more_than_15_days.length }]
        const expiry_data = [
            { "name": "Not expiring within 10 days", value: num_not_expire_10_days },
            { "name": "Expiring within 10 days", value: expires_10_days.length },
            { "name": "Expiring within 24 hours", value: expires_24_hours.length }
        ]

        const circles = (color) => <div className="align-self-center border" style={{ borderRadius: '50%', backgroundColor: color, width: '1rem', height: '1rem' }}></div>
        const labels = (val) => <label className="text-sm ps-2 align-self-center">{val}</label>
        const legend_item =(color,val)=>
            <div className="d-flex justify-content-start w-50 p-1">
                {circles(color)}
                {labels(val)}
        </div>

        const legend =
        <div className="d-flex flex-column justify-content-center w-100">
                <div className="d-flex flex-row justify-content-between w-100">
                    {legend_item(baseColors.dark_blue, `Active > 15 Days (${more_than_15_days.length})`)}
                    {legend_item(baseColors.dark_green, `Active < 15 Days (${less_than_15_days_old.length})`)}
                </div>
                <div className="d-flex flex-row justify-content-between  w-100">
                    {legend_item(baseColors.light_gray, `Expires > 10 Days (${num_not_expire_10_days})`)}
                    {legend_item(baseColors.dark_yellow, `Expires < 10 Days (${expires_10_days.length})`)}
                </div>
                <div className="d-flex flex-row justify-content-between w-100">
                    {legend_item(baseColors.dark_red, `Expires < 24 hours (${expires_24_hours.length})`)}
                </div>
        </div>


       

        return (
            <div className="p-3 h-100">
                <div className="d-flex flex-row justify-content-start">
                    <label
                        onClick={() => this.setState({ account_type:'Prospect'})}
                        className={`text-sm hover-over ${account_type === 'Prospect' ? 'underlined' : 'opacity-75'}`}>
                       {`Prospects (${prospects_list.length})`}
                    </label>
                    <label className="px-3 text-sm">/</label>
                    <label
                        onClick={() => this.setState({ account_type: 'Customer' })}
                        className={`text-sm hover-over ${account_type === 'Customer' ? 'underlined' : 'opacity-75'}`}>
                        {`Customers (${customers_list.length})`}
                    </label>
                </div>
                    <div className="d-flex flex-column justify-content-center w-100 h-100">
                        <PieChart width={275} height={200} className="align-self-center">
                            <Pie
                            
                                data={age_data}
                                cx="50%"
                                cy="50%"
                                outerRadius={50}

                                dataKey="value">

                                {age_data.map((entry, index) => (
                                    <Cell key={`cell-${entry}`} fill={AGE_COLORS[index % AGE_COLORS.length]} />
                                ))}
                            </Pie>
                            <Pie
                                data={expiry_data}
                                cx="50%"
                                cy="50%"
                                innerRadius={51}
                                outerRadius={80}
                                dataKey="value"
                            >
                                <Cell fill={EXPIRY_COLORS[0]} />
                                <Cell fill={EXPIRY_COLORS[1]} />
                                <Cell fill={EXPIRY_COLORS[2]} />
                            </Pie>

                        </PieChart>
                    {legend}
                    </div>                                    
            </div>
        );
    }
}