const get_options_w_token = (token) => {
    return {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    }
};

export async function Get() {
    try {
        const response = await fetch(`Commodity`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}


export async function Post(id, name) {
    try {
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
            body: JSON.stringify({id:id,name:name})
        };

        const response = await fetch(`Commodity`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}