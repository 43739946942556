import { MsalContext } from '@azure/msal-react';
import { format, addDays, addMonths, isAfter, differenceInDays, addHours, getHours} from 'date-fns';
import React, { Component } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import * as customerFunctions from './Functions/CustomerFunctions';
import SortableTable from './TableComponents/SortableTable';
import { LoadingModal } from './Modals/LoadingModal';
import { ViewAccountModal } from './Modals/Account/ViewAccountModal';
import sortByStrategy from '../Domain/sorting-strategy';


export class MyProspects extends Component {
    static displayName = MyProspects.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            search_result_entry: '',
            prospects: [],
            displayed_prospects: [],
            selected_row: -1,
            google_api_key: '',
            alternate_slusa_gm: '',
            current_user: null,
            sp_activity: null,

            view_tab: 'activities',

            activities_selection: 'All',
            multi_button: '',
            root_customers: [],
            root_customers_loaded: false,
            root_activities: [],
            root_activities_loaded: false,
            sort_field: 'Last Contacted',
            sort_direction: 'L',

            selected_account_data: null,
            show_selected_account: true,
            account_clicked: false
        };

    }

    //#region handleInputChange
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    componentDidMount() {
        if (this.props.salesperson) {
             // this.get_url_params();
         
        }
    }

    componentDidUpdate() {
        if (!this.state.user_loaded && this.props.salesperson && this.props.prospects) {
            this.get_url_params();
        }
    }

    get_url_params() {
        this.setState({ user_loaded: true });
        if (this.props.prospects.list) {
            if (window.location.pathname.split('/').length === 3) {
                const customer_id = window.location.pathname.split('/')[2].toUpperCase();
                this.view_account(this.props.salesperson.company_id, customer_id);
            }
        }

    }

 

    get_cell(value) {
    
        return (
            <Alert variant="light" className="align-self-center p-1 m-0 bg-transparent border-0">
                <small className="text-sm text-nowrap">{value}</small>
            </Alert>)
    }

    get_cell_color(days) {
        const label = (val) => <small className="text-sm text-nowrap">{val}</small>
        if (days >= 0) {
            if (days < 10) {
                return (
                    <Alert variant="light" className="align-self-center p-1 m-0 bg-transparent border-0">
                        {label(`${days} Days`)}
                    </Alert>
                )
            }
            else if (days >= 10 && days < 15) {
                return (
                    <Alert variant="warning" className="align-self-center p-1 m-0">
                        {label(`${days} Days`)}
                    </Alert>
                )
            }
            else if (days === -1) {
                return (
                    <Alert variant="danger" className="align-self-center p-1 m-0">
                        {label(`Never`)}
                    </Alert>
                )
            }
            else {
                return (
                    <Alert variant="danger" className="align-self-center p-1 m-0">
                        {label(`${days} Days`)}
                    </Alert>
                )
            }
        } else {
            return (
                <Alert variant="danger" className="align-self-center p-1 m-0">
                    {label(`Never`)}
                </Alert>
            )
        }
    }

    get_expiry_color(expiry_date) {
        const label = (val) => <small className="text-sm text-nowrap">{val}</small>
        let days = differenceInDays(new Date(expiry_date), new Date())
            if (days < 10) {
                return (
                    <Alert variant="danger" className="align-self-center p-1 m-0">
                        {label(`${expiry_date}`)}
                    </Alert>
                )
            }
            else if (days >= 10 && days < 15) {
                return (
                    <Alert variant="warning" className="align-self-center p-1 m-0">
                        {label(`${expiry_date}`)}
                    </Alert>
                )
            }
            else {
                return (
                    <Alert variant="light" className="align-self-center p-1 m-0 bg-transparent border-0">
                        {label(`${expiry_date}`)}
                    </Alert>
                )
            }
         
    }

    get_last_contacted_date(account) {
        if (account) {
            if (account.prev_salesperson_id && account.salesperson_id) {
                if (account.prev_salesperson_id.toUpperCase() === account.salesperson_id.toUpperCase()) {
                    const last_c_date = new Date(account.action_date_str);
                    return addHours(new Date(last_c_date),- getHours(last_c_date))
                } else {
                    return null;
                }
            }
      
        } else {
            return null;
        }
    }




    render() {
        const {
            search_result_entry,
            sort_field,
            sort_direction,
            account_clicked,
            selected_account_data,
            show_selected_account
        } = this.state;

        const selected_account = selected_account_data ? selected_account_data.account : null;

        //#region Search Bar
        const search_bar = (
            <div className="mb-3 d-flex flex-row w-100 justify-content-center border rounded">
                <Form.Control
                    type="text"
                    style={{ border: 'solid transparent 0.1rem' }}
                    className="text-sm"
                    placeholder="Search by code or name"
                    name="search_result_entry"
                    value={search_result_entry}
                    onChange={this.handleInputChange}
                />
                <Button
                    onClick={() => this.setState({ search_result_entry: '' })}
                    className="btn-op70 align-self-center"
                    variant="light"
                    size="sm"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-x-lg"
                        viewBox="0 0 16 16"
                    >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                    </svg>
                </Button>
            </div>
        );
        //#endregion Search Bar

        //#region Sort Prospects
        let customers = this.props.prospects ? this.props.prospects.loaded === 'Y' ? this.props.prospects.list : [] : [];
        if (customers.length > 1) {
            const sortingStrategies = {
                Name: (a, b) => a.name.localeCompare(b.name),
                Code: (a, b) => a.id.localeCompare(b.id),
                'Last Contacted': (a, b) => this.get_last_contacted_date(a) - this.get_last_contacted_date(b),
                'Expiry Date': (a, b) =>new Date(a.expiry_date)-new Date(b.expiry_date),
                City: (a, b) => a.city.localeCompare(b.city),
                State: (a, b) => a.state_id.localeCompare(b.state_id),
            };

            customers = customers.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
        }
        //#endregion Sort Prospects

        if (customers.length > 1 && search_result_entry.length > 0) {
            customers = customers.filter(
                (s) =>
                    s.name.toUpperCase().includes(search_result_entry.toUpperCase()) ||
                    s.id.toUpperCase().includes(search_result_entry.toUpperCase()),
            );
        }


        //#region All Customers Table
        let header_styles = 'text-sm text-nowrap'
        const customer_headers = [
            { column_title: 'Name', sort_direction: sort_direction, is_sortable: 'Y', header_style: header_styles },
            { column_title: 'Code', sort_direction: sort_direction, is_sortable: 'Y', header_style: header_styles },
            {
                column_title: 'Last Contacted',
                sort_direction: sort_direction,
                is_sortable: 'Y',
                header_style: header_styles,
            },
            {
                column_title: 'Expiry Date',
                sort_direction: sort_direction,
                is_sortable: 'Y',
                header_style: header_styles,
            },
            {
                column_title: 'City',
                sort_direction: sort_direction,
                is_sortable: 'Y',
                header_style: header_styles,
            },
            {
                column_title: 'State',
                sort_direction: sort_direction,
                is_sortable: 'Y',
                header_style: header_styles,
            }
        ];


        let customer_data = [];
        customers.map(d => {
            customer_data.push({
                row_style: `${selected_account ? (selected_account.id === d.id ? 'table-selected' : '') : ''
                    } active-table`,
                cells: [
                    { cell: this.get_cell(d.name), cell_style: '' },
                    { cell: this.get_cell(d.id), cell_style: '' },
                    { cell: this.get_cell_color(differenceInDays(new Date(), this.get_last_contacted_date(d))), cell_style: '' },
                    { cell: this.get_expiry_color(d.expiry_date), cell_style: '' },
                    { cell: this.get_cell(d.city), cell_style: '' },
                    { cell: this.get_cell(d.state_id), cell_style: '' },
                ],
                data: d,
            })
        }

        )


        const customer_table = customers.length ? (
            <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                sort_field={sort_field}
                          row_click={(prop) => this.view_account(JSON.parse(prop).company_id, JSON.parse(prop).id)}

                table_headers={customer_headers}
                table_data={customer_data}
                table_title="My Prospects"
            />
        ) : null;

        const customer_table_div =
            <div className="p-3 d-flex flex-column justify-content-start bg-white rounded h-100">
                {search_bar}
                <h6 className="portal-tile-title">My Prospects</h6>
                {customer_table}

            </div>

        //#endregion All Customers Table


        return (
            <Container className="d-flex flex-row justify-content-between p-3 w-100" fluid='xs'>
         
                <Row className="d-flex flex-row">
                    <Col>{customer_table_div}</Col>
                    {
                        selected_account && show_selected_account ?

                            <ViewAccountModal
                                show={show_selected_account}
                                onHide={() => this.setState({
                                    show_selected_account: false,
                                    selected_account_data: null
                                })}
                                current_user={this.props.salesperson}
                                msal_account={this.props.msal_account}
                                role={this.props.role}
                                accounts_list={customers}
                                prospects={this.props.prospects}
                                commodities={this.props.commodities}
                                categories={this.props.categories}
                                salesperson_list={this.props.salesperson_list}  
                                selected_account_data={selected_account_data}
                                add_account={(prop) => this.props.add_account(prop)}
                                release_account={(prop) => this.props.release_account(prop)}
                                update_accounts_list={(prop) =>null}
                                view_account={(account) => this.view_account(account.company_id, account.id)}
                                update_selected_account_data={(prop) => this.setState({ selected_account_data: prop })}
                                update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                                add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                                add_users_action={(prop) => this.props.add_users_action(prop)}
                                update_users_action={(prop) => this.props.add_users_action(prop)}
                                />
                            : null
                    }
                </Row>




                <LoadingModal show={this.props.prospects && this.props.prospects.loaded==='N'} title="My Prospects" />
                <LoadingModal show={account_clicked} title="Prospect" />
            </Container>
        );
    }


    async view_account(company_id, customer_id) {
        this.setState({ account_clicked: true });
        const account_data = await customerFunctions.View_Account(company_id, customer_id);
        this.setState({
            selected_account_data: account_data,
            show_selected_account: true,
            account_clicked: false
        })
    }
}
