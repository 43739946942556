import { Dropdown, Badge, DropdownButton } from 'react-bootstrap';

const notification_icon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-bell"
        viewBox="0 0 16 16"
    >
        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
    </svg>
);



function NotificationButton(overdueReminders) {
    return (
        <Dropdown autoClose="outside" className="d-flex align-self-center justify-content-center" >
            <Dropdown.Toggle variant="dark" className="d-flex flex-row slow-transition">
                <div style={{ transform: 'scale(1.3)' }} className="me-1 mt-2">
                    {notification_icon}
                </div>
                <Badge bg="error" pill className="text-sm">
                    {overdueReminders.length}
                </Badge>
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark" className="overdue-dropdown-container p-2">
                <Dropdown.ItemText header className="text-white">
                    Overdue Reminders
                </Dropdown.ItemText>
                <Dropdown.Divider className="bg-white" variant="light" />
                {overdueReminders
                    .sort((a, b) => new Date(a.date_due) - new Date(b.date_due))
                    .map((d, index) => (
                        <Dropdown.Item
                            key={d.id}
                            className="bg-dark-gray rounded mb-1"
                            onClick={() =>
                                d.mark_plan_id.includes('PRO')
                                    ? (window.location.pathname = '/myprospects/' + d.customer_id_row.id + '/future')
                                    : (window.location.pathname = '/mycustomers/' + d.customer_id_row.id + '/future')
                            }
                        >
                            <div className="mb-0 text d-flex flex-column">
                                <div className="d-flex flex-row justify-content-between">
                                    <strong className="text-sm text-danger">DUE: {d.date_due}</strong>
                                    <strong className="text-sm text-white">
                                        {d.company_id === 'TMS2' ? 'SLUSA' : 'SLC'}
                                    </strong>
                                </div>
                                <div className="d-flex flex-row mt-2 ">
                                    <strong className="text-sm align-self-center">{d.customer_name}</strong>
                                    <i className="ps-1 text-sm align-self-center">{d.type_of}</i>
                                </div>

                                <p className="text-sm mt-2 text-wrap">{d.remarks?d.remarks.replaceAll('&#xA;','\n'):''}</p>
                            </div>
                        </Dropdown.Item>
                    ))}
            </Dropdown.Menu>
 </Dropdown>
    );
}

export default NotificationButton;
