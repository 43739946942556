import React, { Component } from 'react';
import { Button, Col, Container, Form, Modal, Row, InputGroup } from 'react-bootstrap';
import '../../Styles/Datepicker.css';

export class AddSalespersonModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clicked: false,
            generate_clicked: false,
        };
    }

    async generate_salesperson_id() {
        this.setState({ generate_clicked: true });
        const current_salesperson = this.props.new_salesperson;
        const names = current_salesperson.salesperson_name.split(' ');
        let first_name = '';
        let last_name = '';
        if (names.length === 2) {
            first_name = names[0];
            last_name = names[1];
        } else if (names.length === 3) {
            first_name = names[0];
            last_name = names[2];
        }
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(
            `Salesperson/GenerateSalespersonID/${current_salesperson.company_id}/${first_name}/${last_name}`,
            options,
        );
        const data = await response.json();
        if (data.length > 0) {
            current_salesperson.salesperson_id = data[0].generated_id;
        }
        this.props.update_salesperson(current_salesperson);
        this.setState({ generate_clicked: false });
    }

    onClose() {
        this.clean_salesperson_template();
        this.props.onClose();
    }

    clean_salesperson_template() {
        this.props.update_salesperson({
            company_id: '',
            salesperson_id: '',
            salesperson_name: '',
            salesperson_title: '',
            user_id: '',
            hierarchy: '',
            role: '',
            revenue_code: '',
            salesperson_start_date: '',
            commission_start_date: '',
            sales_manager_id: '',
            sales_team_captain_id: '',
            primary_salesperson_id: '',
            primary_salesperson_start_date: '',
            primary_LC_gifted_perc: 0,
            primary_LC_gifted_perc_start_date: '',
            floating_LC: '',
            training_under: '',
            inactive_date: '',
            change_user: '',
            inactive_relationship_date: '',
            primary_LC_gifted: '',
        });
    }

    async insert_salesperson() {
        this.setState({ clicked: true });
        this.props.insert_salesperson().then((res) => {
            this.setState({ clicked: false });
        });
    }

    handle_select_change(e) {
        const current_salesperson = this.props.new_salesperson;
        if (e.target.name === 'sales_manager_id') {
            const current_gm = this.props.general_managers.find((s) => s.user_id === e.target.value);
            current_salesperson.sales_manager_id = e.target.value;
            current_salesperson.company_id = current_gm.company_id;
            current_salesperson.revenue_code = current_gm.revenue_code;
        } else if (e.target.name === 'role') {
            current_salesperson.role = e.target.value;
            current_salesperson.hierarchy = e.target.value === 'STC' ? 'LAM' : e.target.value;
        } else if (e.target.name === ' primary_LC_gifted_perc') {
            if (current_salesperson.primary_LC_gifted_perc > 0) {
                current_salesperson.primary_LC_gifted = 'Y';
            } else {
                current_salesperson.primary_LC_gifted = 'N';
            }
        } else if (e.target.name === 'user_id') {
            let selected_user = this.props.users_w_sales_id.find(s => s.user_id === e.target.value && s.company_id === current_salesperson.company_id)
            current_salesperson.user_id = e.target.value;
            current_salesperson.salesperson_name = selected_user.name;

        }
        else {
            current_salesperson[e.target.name] = e.target.value;
        }
        this.props.update_salesperson(current_salesperson);
    }

    handle_edit_change(e) {
        const current_salesperson = this.props.new_salesperson;
        current_salesperson[e.target.name] = e.target.value;
        this.props.update_salesperson(current_salesperson);
    }

    //#region Render Functions
    render_no_edit(title, field) {
        const current_salesperson = this.props.new_salesperson;
        return (
            <div className={'d-flex flex-row justify-content-between px-2 py-1 border'}>
                <label className="text-lg align-self-center">{title}:</label>
                <Form.Control
                    value={current_salesperson ? (current_salesperson[field] ? current_salesperson[field] : '') : ''}
                    className="text-lg w-50 p-1"
                    disabled={true}
                />
            </div>
        );
    }

    render_salesperson_id() {
        const current_salesperson = this.props.new_salesperson;
        return (
            <div className={'d-flex flex-row justify-content-between px-2 py-1 border'}>
                <label className="text-lg align-self-center">
                    Salesperson ID:
                    {!current_salesperson.salesperson_id ? (
                        <bdi className="text-danger ps-3">Click the 'Generate ID' button</bdi>
                    ) : null}
                </label>
                <InputGroup className="text-lg w-50 p-1">
                    <Form.Control
                        value={
                            current_salesperson
                                ? current_salesperson.salesperson_id
                                    ? current_salesperson.salesperson_id
                                    : ''
                                : ''
                        }
                        className="text-lg w-75 p-1"
                        disabled={true}
                    />
                    <Button
                        size="sm"
                        className="text-lg w-25 p-1 btn-op70"
                        variant="success"
                        onClick={() => this.generate_salesperson_id()}
                    >
                        Generate ID
                    </Button>
                </InputGroup>
            </div>
        );
    }

    render_edit(title, field, max) {
        const current_salesperson = this.props.new_salesperson;
        return (
            <div
                className={'d-flex flex-row justify-content-between px-2 py-1 border'}
                title={
                    !current_salesperson.sales_manager_id
                        ? 'A Sales Manager must be selected before you can edit this field'
                        : ''
                }
            >
                <label className="text-lg align-self-center">{title}:</label>
                <Form.Control
                    onChange={(e) => this.handle_edit_change(e)}
                    maxLength={max}
                    disabled={!current_salesperson.sales_manager_id}
                    name={field}
                    value={current_salesperson[field] ? current_salesperson[field] : ''}
                    className="text-lg w-50 p-1"
                    isInvalid={current_salesperson.sales_manager_id ? !current_salesperson[field] : false}
                />
            </div>
        );
    }

    render_edit_date(title, field, required) {
        const current_salesperson = this.props.new_salesperson;
        return (
            <div className={'d-flex flex-row justify-content-between px-2 py-1 border'}>
                <label className="text-lg align-self-center">{title}:</label>
                <Form.Control
                    onChange={(e) => this.handle_edit_change(e)}
                    type="date"
                    disabled={!current_salesperson.sales_manager_id}
                    name={field}
                    value={current_salesperson ? (current_salesperson[field] ? current_salesperson[field] : '') : ''}
                    className="text-lg w-50 p-1"
                    isInvalid={current_salesperson.sales_manager_id && required ? !current_salesperson[field] : false}
                />
            </div>
        );
    }

    render_select(title, field, values, required) {
        const current_salesperson = this.props.new_salesperson;
        return (
            <div
                className={'d-flex flex-row justify-content-between px-2 py-1 border'}
                title={
                    !current_salesperson.sales_manager_id && field !== 'sales_manager_id'
                        ? 'A Sales Manager must be selected before you can edit this field'
                        : ''
                }
            >
                <label className="text-lg align-self-center">{title}:</label>
                <Form.Select
                    onChange={(e) => this.handle_select_change(e)}
                    name={field}
                    disabled={!current_salesperson.sales_manager_id && field !== 'sales_manager_id'}
                    value={current_salesperson ? (current_salesperson[field] ? current_salesperson[field] : '') : ''}
                    isInvalid={
                        (field === 'sales_manager_id' || current_salesperson.sales_manager_id) && required
                            ? !current_salesperson[field]
                            : false
                    }
                    className="text-lg w-50 p-1"
                >
                    <option value="">Select</option>
                    {values
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((d) => (
                            <option value={d.value} key={d.value}>
                                {d.label}
                            </option>
                        ))}
                </Form.Select>
            </div>
        );
    }
    //#endregion Render Functions

    check_salesperson() {
        const current_salesperson = this.props.new_salesperson;
        if (!current_salesperson.sales_manager_id) {
            return false;
        }
        if (!current_salesperson.company_id) {
            return false;
        }
        if (!current_salesperson.revenue_code) {
            return false;
        }
        if (!current_salesperson.salesperson_id) {
            return false;
        }
        if (!current_salesperson.salesperson_name) {
            return false;
        }
        if (!current_salesperson.role) {
            return false;
        }
        if (!current_salesperson.user_id) {
            return false;
        }
        if (!current_salesperson.salesperson_start_date) {
            return false;
        }
        if (current_salesperson.role === 'LC') {
            if (!current_salesperson.floating_LC) {
                return false;
            } else {
                if (current_salesperson.floating_LC === 'N') {
                    if (!current_salesperson.primary_salesperson_id) {
                        return false;
                    }
                  
                }
            }
        }
        return true;
    }

    render() {
        const { clicked } = this.state;
        const current_salesperson = this.props.new_salesperson;
        const gm_list = [];
        const titles_list = [];
        const trainers_list = [];
        const captains_list = [];
        const salesperson_list = [];
        const users_list = [];
        const float_lc_options = [
            { label: 'Yes', value: 'Y' },
            { label: 'No', value: 'N' },
        ];
        const gifted_perc_options = [
            { label: 'None', value: 0 },
            { label: '0.25 %', value: 0.25 },
            { label: '0.5 %', value: 0.5 },
            { label: '1 %', value: 1 },
            { label: '1.5 %', value: 1.5 },
        ];

        if (this.props.general_managers) {
            this.props.general_managers.map((s) =>
                gm_list.push({ label: `${s.salesperson_name}   (${s.revenue_code})`, value: s.user_id }),
            );
        }
        if (this.props.titles) {
            this.props.titles.map((s) => titles_list.push({ label: s.name, value: s.id }));
        }
        if (this.props.trainers) {
            this.props.trainers
                .filter((d) => (current_salesperson ? d.revenue_code === current_salesperson.revenue_code : true))
                .map((s) => trainers_list.push({ label: s.salesperson_name, value: s.salesperson_id }));
        }
        if (this.props.sales_team_captains) {
            this.props.sales_team_captains
                .filter((d) => (current_salesperson ? d.revenue_code === current_salesperson.revenue_code : true))
                .map((s) => captains_list.push({ label: s.salesperson_name, value: s.salesperson_id }));
        }
        if (this.props.salespersons) {
            this.props.salespersons
                .filter((d) => (d.sales_manager_id === current_salesperson.sales_manager_id))
                .map((s) => salesperson_list.push({ label: s.salesperson_name, value: s.salesperson_id }));
        }
        if (this.props.users_w_sales_id) {
            this.props.users_w_sales_id.filter(s => s.company_id === current_salesperson.company_id && !s.salesperson_id && s.is_active==='Y').map((s) => users_list.push({ label: `${s.user_id.toUpperCase()} (${s.name})`, value: s.user_id }));
        }

        return (
            <Modal
                {...this.props}
                size="lg"
                className="bg-transparent-gray"
                fullscreen="md-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="w-100 ">
                    <Container fluid="xs" className="w-100">
                        <Row>
                            <Col>
                                <div className="d-flex flex-row justify-content-between">
                                    <Button variant="outline secondary btn-op70" onClick={() => this.onClose()}>
                                        X
                                    </Button>
                                    <h4 className="pt-2">Add Salesperson</h4>
                                    <div></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    {current_salesperson ? (
                        <div className="d-flex flex-column justify-content-start">
                            {gm_list ? this.render_select('Sales Manager', 'sales_manager_id', gm_list, true) : null}
                            {this.render_no_edit('Company ID', 'company_id')}
                            {this.render_no_edit('Rev Code', 'revenue_code')}
                            {this.render_select('User ID', 'user_id', users_list, true)}
                            {this.render_no_edit('Salesperson Name', 'salesperson_name')}
                            {current_salesperson.salesperson_name
                                ? this.render_salesperson_id('Salesperson ID', 'salesperson_id')
                                : null}
                            
                            {this.render_edit_date('Salesperson Start Date', 'salesperson_start_date', true)}
                            {this.render_edit_date('Commission Start Date', 'commission_start_date', false)}
                            {this.render_edit_date('Inactive Date', 'inactive_date', false)}
                            {captains_list
                                ? this.render_select(
                                      'Sales Team Captain',
                                      'sales_team_captain_id',
                                      captains_list,
                                      false,
                                  )
                                : null}
                            {trainers_list
                                ? this.render_select('Training Under', 'training_under', trainers_list, false)
                                : null}
                            {titles_list ? this.render_select('Title', 'role', titles_list, true) : null}
                            {current_salesperson.role ? this.render_no_edit('Role', 'role') : null}
                            {current_salesperson.hierarchy ? this.render_no_edit('Hierarchy', 'hierarchy') : null}
                            {current_salesperson.hierarchy === 'LC'
                                ? this.render_select('Floating LC', 'floating_LC', float_lc_options, true)
                                : null}
                            {current_salesperson.hierarchy === 'LC' && current_salesperson.floating_LC !== 'Y'
                                ? this.render_select('Primary LAM', 'primary_salesperson_id', salesperson_list, true)
                                : null}
                            {current_salesperson.hierarchy === 'LC' && current_salesperson.floating_LC !== 'Y'
                                ? this.render_edit_date(
                                      'Start Date with Primary LAM',
                                      'primary_salesperson_start_date',
                                      true,
                                  )
                                : null}
                            {current_salesperson.hierarchy === 'LC' && current_salesperson.floating_LC !== 'Y'
                                ? this.render_select(
                                      'Gifted Percent',
                                      'primary_LC_gifted_perc',
                                      gifted_perc_options,
                                      false,
                                  )
                                : null}
                            {current_salesperson.hierarchy === 'LC' &&
                            current_salesperson.floating_LC !== 'Y' &&
                            current_salesperson.primary_LC_gifted_perc
                                ? this.render_edit_date(
                                      'Gifted Percent Start Date',
                                      'primary_LC_gifted_perc_start_date',
                                      true,
                                  )
                                : null}
                            {current_salesperson.hierarchy === 'LC' && current_salesperson.floating_LC !== 'Y'
                                ? this.render_edit_date(
                                      'Inactive Relationship Date',
                                      'inactive_relationship_date',
                                      false,
                                  )
                                : null}
                        </div>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
            
                    {current_salesperson ? (
                        <div
                            className="d-flex flex-row justify-content-center w-100"
                            title={!this.check_salesperson() ? 'Missing required fields' : 'Add Salesperson'}
                        >
                            <Button
                                variant={clicked ? 'outline-success' : 'success'}
                                disabled={clicked || !this.check_salesperson()}
                                className="btn-op70 align-self-center text-lg"
                                onClick={() => this.insert_salesperson()}
                            >
                                {clicked ? 'Adding...' : 'Add'}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        );
    }
}
