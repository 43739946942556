import * as dataService from '../../Dataservice/Endpoints';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as svg_icons from '../../Media/svg_exports'
import blank_icon from '../../Media/blank_profile_icon.png';
import * as fetchFunctions from '../Functions/FetchFunctions';
import { useMsal } from "@azure/msal-react";
import ConvertApi from 'convertapi-js'
import { Button, InputGroup, Form } from 'react-bootstrap';

export const currency_format_props = {
    decimalScale: 0,
    prefix: '$ ',
    thousandSeparator: true
}
export const percent_format_props = {
    decimalScale: 0,
    suffix: ' %',
    thousandSeparator: true
}
export const number_format_props = {
    decimalScale: 0,
    thousandSeparator: true
}

export const hours_array = Array.from({ length: 25 }, (x, i) => ({
    val: 24 - i < 10 ? '0' + (24 - i).toString() : (24 - i).toString(),
}));
export const minutes_array = Array.from({ length: 60 }, (x, i) => ({
    val: 0 + i < 10 ? '0' + (0 + i).toString() : (0 + i).toString(),
}));

export const num_occurances_word_in_string = (word, c_string) => {
    let count = 0;
    if (c_string && word) {
        let c_split = c_string.split(' ')
        c_split.map(w => {
            if (w.includes(word)) {
                count = count + 1;
            }
        })
    }
    return count;
}

export const remove_special_characters = (word) => {
    if (word) {
        return word.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    } else {
        return '';
    }
}

export const timeout = (ms) => {
        return new Promise((resolve) => {
            setTimeout(() => resolve('Ready'), ms);
        });
};

export function Format_Phone_Number(input) {
    let output = '';
    try {
        if (input !== null && input !== undefined) {
            input.split('').forEach((char) => {
                if (/^\d+$/.test(char)) {
                    output = output + char;
                }
            });
        }
        if (output.length === 11) {
            if (output[0] === '1') {
                return '1-' + output.substring(1, 4) + '-' + output.substring(4, 7) + '-' + output.substring(7, 11);
            } else {
                return (
                    output.substring(0, 3) +
                    '-' +
                    output.substring(3, 6) +
                    '-' +
                    output.substring(6, 10) +
                    ' ,' +
                    output.substring(10)
                );
            }
        } else if (output.length > 11) {
            if (output[0] === '1') {
                return (
                    '1-' +
                    output.substring(1, 4) +
                    '-' +
                    output.substring(4, 7) +
                    '-' +
                    output.substring(7, 11) +
                    ' ,' +
                    output.substring(11)
                );
            } else {
                return (
                    output.substring(0, 3) +
                    '-' +
                    output.substring(3, 6) +
                    '-' +
                    output.substring(6, 10) +
                    ' ,' +
                    output.substring(10)
                );
            }
        } else if (output.length === 10) {
            return output.substring(0, 3) + '-' + output.substring(3, 6) + '-' + output.substring(6, 10);
        } else if (output.length === 9) {
            return output.substring(0, 3) + '-' + output.substring(3, 6) + '-' + output.substring(6, 9);
        } else if (output.length <= 8) {
            return output;
        }
    } catch (e) {
        console.log(e);
        return output;
    }
}

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export async function Convert_To_PDF(file, company_id, file_name, user_id) {
    try {
        const convertApi = ConvertApi.auth({ secret: 'xH4v9b4lGOJMoxlv' })
        const params = convertApi.createParams();
        params.add('file', file);
        params.add('Presets', 'web');
        params.add('StoreFile', 'True');
        const result = await convertApi.convert('pdf', 'compress', params);
        const blob = await fetch(result.dto.Files[0].Url)
            .then((r) => r.blob())
            .then((blobFile) => new File([blobFile], file.name, { type: file.type }));
          return blob;
        return null;
    } catch (e) {
        console.log(e)
        const error_body = {
            status: e.Code,
            message: `${e.Message}, ${e.InvalidParameters ? e.InvalidParameters.File[0] : ''}, File Name: ${file_name}`,
            company_id: company_id,
            function_name: 'UtilityFunctions -> Convert_To_PDF',
            primary_pk: user_id,
            table_name: 'Convert File',
            user_id: user_id
        }
        Log_Error(error_body).then((error_log) => {
            console.log(error_log)
        })
        return null;
    }
}

export function Check_File_Sizes(file) {
   
    const size_in_mb = (file.size / 1024) / 1024;
    const filename_length = file.name.length;

    if (size_in_mb < 5) {
        if (filename_length < 80) {
            return file;
        } else {
            alert('File name exceeds number of characters (80). Please rename file with shorter name.')
        }
    } else {
        alert(
            'The file you are trying to upload is still too large after compression. \nMax Size: 5MB \nFile size after compression: ' +
            size_in_mb +
            'MB',
        );
    }
}

export async function Validate_User(account) {
    try {
        if (account) {
            const current_account = {
                isAuthenticated: false,
                tenantId: account.tenantId,
                roles: account.idTokenClaims.roles,
            };
            const response = await fetch('api/Login', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(current_account),
            });
            const data = await response.json();
            if (data.status === 401) {
                return 'Unauthorized';
            } else {
                sessionStorage.setItem('token', data.token);
                timeout(1000);
                return data.token;
            }
        } else {
            return 'Unauthorized';
        }
    } catch (e) {
        return 'Unauthorized';
    }
}

export async function Get_User_Routes(role) {
    try {
        if (sessionStorage.getItem('token') !== undefined && sessionStorage.getItem('token') !== null) {
            const options = { method: 'GET', headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') } };
            const response = await fetch(`User/GetUserRoutes/${role}`, options);

            const data = await response.json();
            if (data.length > 0) {
                return data[0]
            } else {
                return null
            }
        }
    }
    catch (e) {
        console.log(e);
        return null;
    }

}

export async function Get_All_Users_W_Sales_ID() {
    try {
        const options = { method: 'GET', headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') } };
        const response = await fetch(`User/GetAllUsersWSalesID`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Get_States() {
    try {
        const options = { method: 'GET', headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') } };
        const response = await fetch(`StateZone/GetStates`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Get_Cities_From_State(state_id) {
    try {
        const options = { method: 'GET', headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') } };
        const response = await fetch(`StateZone/GetCitiesFromState/${state_id}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Get_Zones() {
    try {
        const options = { method: 'GET', headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') } };
        const response = await fetch(`StateZone/GetZones`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Get_Add_Prospect_Audit(prop) {
    try {
        const options = { method: 'GET', headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') } };
        const response = await fetch(
            `PossibleMatch/AuditLog/${prop.company_id}/${prop.start_date}/${prop.end_date}`,
            options,
        );
        const data = await response.json();
        const final_data = data;
        return final_data;
    } catch (e) {
        console.log(e);
        return [];
    }
}
export function Export_To_CSV(csvData, fileName) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
}

export async function Log_Error(error) {
    try {
        const options = {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(error),
        };
        const response = await fetch('ErrorLog', options);
        const data = await response.json();
        return null;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Send_Email(instance, accounts, email) {
    let email_json = email
    const request = {
        account: accounts[0],
        scopes: ['Mail.Send'],
    };
    
    const claims = accounts[0].idTokenClaims;
    const login_id = claims.email.replaceAll('@scotlynn.com', '');
    let company_id = claims.ctry === 'US' ? 'TMS2' : 'TMS';
    if (process.env.REACT_APP_ENVIRONMENT === 'DEV') {
        
        email_json.message.toRecipients = [{ emailAddress: { address: accounts[0].username } }]
    }

    await instance.acquireTokenSilent(request)
        .then((response) => {
            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;
            headers.append('Authorization', bearer);
            headers.append('Content-Type', 'application/json');
            headers.append('client-request-id', process.env.REACT_APP_MSAL_CLIENT_ID);
            headers.append('cache-control', 'no-cache');
            const options = { method: 'POST', headers: headers, body: JSON.stringify(email_json) };
            fetch('https://graph.microsoft.com/v1.0/me/sendMail', options)
                .then((o) => {
                    if (o.status !== 202) {
                        console.log(o);
                        const error_body = {
                            status: o.status,
                            message: `Email failed. Expiration: ${claims.exp}, Current: ${Math.floor(Date.now() / 1000)}, Message Content: ${email_json.message.body.content}, Recipients: ${JSON.stringify(email_json.message.toRecipients)}`,
                            company_id: company_id,
                            function_name: 'UtilityFunctions -> Send_Email',
                            primary_pk: login_id,
                            table_name: 'email',
                            user_id: login_id
                        }
                        Log_Error(error_body).then((error_log) => {
                            console.log(error_log)
                        })
                    }
                    return o;
                })
           
        })
        .catch((error) => {
            console.log(error);
        });
}

export async function Send_Notification(instance, accounts) {
    const request = {
        account: accounts[0],
        scopes: ['TeamsActivity.Send'],
    };

    const claims = accounts[0].idTokenClaims;
    const login_id = claims.email.replaceAll('@scotlynn.com', '');
    let company_id = claims.ctry === 'US' ? 'TMS2' : 'TMS';

    const body = {
        "topic": {
            "source": "entityUrl",
            "value": "https://graph.microsoft.com/v1.0/teams/431589a2-15d2-448b-84ad-12fc1c224627"
        },
        "activityType": "taskCreated",
        "previewText": {
            "content": "New Task Created"
        },
        "recipient": {
            "@odata.type": "microsoft.graph.aadUserNotificationRecipient",
            "userId": "569363e2-4e49-4661-87f2-16f245c5d66a"
        },
        "templateParameters": [
            {
                "name": "taskId",
                "value": "12322"
            }
        ]
    }


    await instance.acquireTokenSilent(request)
        .then((response) => {
            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;
            headers.append('Authorization', bearer);
            headers.append('Content-Type', 'application/json');
            headers.append('client-request-id', process.env.REACT_APP_MSAL_CLIENT_ID);
            headers.append('cache-control', 'no-cache');
            const options = { method: 'POST', headers: headers, body: JSON.stringify(body) };
            fetch('https://graph.microsoft.com/v1.0/teams/431589a2-15d2-448b-84ad-12fc1c224627/sendActivityNotification', options)
                .then((o) => {
                    if (o.status !== 202) {
                        console.log(o);
                    
                    }
                    return o;
                })

        })
        .catch((error) => {
            console.log(error);
        });
}

export async function Get_Profile_Photo(instance, acct) {
    if (instance && acct) {
        const request = { scopes: ['User.Read'], account: acct };
        const token = await instance.acquireTokenSilent(request);
        const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token.accessToken}`,
                'Content-Type': 'application/json',
                'client-request-id': process.env.REACT_APP_CLIENT_REQUEST_ID,
                'cache-control': 'no-cache',
            }),
        });
        const blob_data = await response.blob();
        if (isBlob(blob_data)) {
            return URL.createObjectURL(blob_data);
        } else {
            return blank_icon;
        }
    }
}

export function format_date_from_input(date) {
    if (date) {
        let year = date.substring(0, 4);
        let month = date.substring(5, 7);
        let day = date.substring(8, 10);
        return `${month}-${day}-${year}`
    }
}

export function format_date_for_input(date) {
    if (date) {
        let month = date.substring(0, 2);
        let day = date.substring(3, 5);
        let year = date.substring(6, 10);
        
        
        return `${year}-${month}-${day}`
    }
}

export function format_datetime_from_input(date) {
    if (date) {
        let year = date.substring(0, 4);
        let month = date.substring(5, 7);
        let day = date.substring(8, 10);
        let hour = date.substring(11, 13);
        let min = date.substring(14, 16);
        return `${month}-${day}-${year} ${hour}:${min}`
    }
}


function isBlob(obj) {
    return obj instanceof Blob;
}

