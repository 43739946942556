import React, { Component } from 'react';
import { Container, Row, Col, Card, Alert, Form, InputGroup, Button, FormCheck, ButtonGroup, Dropdown} from 'react-bootstrap';
import SortableTable from '../TableComponents/SortableTable';
import { NumericFormat } from 'react-number-format';
import { OrderAdjDescriptionModal } from '../Modals/OrderAdjDescriptionModal';
import * as commissionFunctions from '../Functions/CommissionFunctions'
import * as svg_icons from '../../Media/svg_exports';
import { isToday, addMinutes, addDays, format, addMonths} from 'date-fns'
import sortByStrategy from '../../Domain/sorting-strategy';


export class OrdersView extends Component {
    static displayName = OrdersView.name;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: '',
            sort_field: '',
            edit_field: '',
            show_add: false,
            offset: 0,
            edited_list: [],
            show_adj_desc: false,
            search_by: 'order_id',
            search_value: '',
            search_clicked: false,
            shipped_start: format(addMonths(new Date(),-1),'yyyy-MM-dd'),
            shipped_end: format(new Date(), 'yyyy-MM-dd'),
            more_clicked: false,
            set_page: 500,
            show_customer_dropdown: false,
            show_salesperson_drowndown: false,
            customer_dropdown_filter: '',
            salesperson_dropdown_filter:''

        };
    }

    componentDidMount() {
        this.setState({ edited_list: this.props.orders.list });
    }

    handle_order_commission_update(e) {
        const current_list = this.state.edited_list;
        //   let current_edited = current_list.find(e.)
    }

    async update_orders() {
        try {
            const options = {
                method: 'PUT',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.state.edited_list),
            };
            const response = await fetch('Commission/UpdateOrderCommissionsTest', options);
            const data = await response.json();
           
        } catch (e) {
            console.log(e);
        }
    }

    get_row_color(d) {
        const selected_commission = this.props.selected_commission
        let row_color = '';
        if (selected_commission) {
            if (selected_commission.pk === d.pk) {
                row_color = 'selected-commission';
            }
        }
        else if (new Date(d.change_user_date_str) >= addMinutes(new Date(),-2)) {
            row_color = 'bg-light-blue';
        }
        return `text-med ${row_color}`
    }

    click_checkbox(val, property,d) {
        const value = val.target.checked?'Y':'N'
        this.props.update_orders_varchar(value, property, d)
    }

    open_adj_descr(d) {
        this.props.set_selected_commission(d)
        this.setState({ show_adj_desc:true })
    }

    handleKeyPress(event) {
        if (!this.state.search_clicked) {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.search_orders(this.state.search_by, this.state.search_value)
            }
        }
    }


    async search_orders(search_by, search_value) {
        this.setState({search_clicked:true})
        const search_company_id = this.props.search_company_id;
        const body = {
            company_id: search_company_id,
            search_value: search_value,
            shipped_start: this.state.shipped_start,
            shipped_end:this.state.shipped_end
        }
        if (search_by === 'order_id') {
            const data = await commissionFunctions.SearchByOrder(search_company_id, search_value)
            this.props.update_orders_list(data)
        }
        else if (search_by === 'customer_id') {
           
            const data = await commissionFunctions.SearchByCustomer(body)
            this.props.update_orders_list(data)
        }
        else if (search_by === 'salesperson_id') {
            const data = await commissionFunctions.SearchBySalesperson(body)
            this.props.update_orders_list(data)
        }
        this.setState({ search_clicked: false })
    }

    async get_more_orders() {
        this.setState({ more_clicked:true })
        let current_list = this.props.orders.list;

        const new_list = await this.props.get_more_orders(this.props.search_company_id, this.props.orders.list.length)
        current_list = current_list.concat(new_list);
        this.props.update_orders_list(current_list)
        this.setState({ more_clicked: false })
    }

    render() {
        const {
            sort_direction,
            sort_field,
            show_add,
            search_salesperson_id,
            show_adj_desc,
            search_by,
            search_value,
            search_clicked,
            shipped_start,
            shipped_end,
            set_page,
            show_customer_dropdown,
            show_salesperson_drowndown,
            customer_dropdown_filter,
            salesperson_dropdown_filter
        } = this.state;

        let current_orders = this.props.orders ? this.props.orders.list : [];
        current_orders = Array.isArray(current_orders) ? current_orders.length >= set_page ? current_orders.slice(0, set_page) : current_orders : [];
        const update_clicked = this.props.update_clicked;
        const selected_commission = this.props.selected_commission
        let customer_ord_list = this.props.customer_ord_list ? this.props.customer_ord_list.filter(s => s.company_id === this.props.search_company_id) : [];
        if (customer_dropdown_filter) {
            customer_ord_list = customer_ord_list.filter(s => s.customer_id.toLowerCase().includes(customer_dropdown_filter.toLowerCase()) || s.customer_name.toLowerCase().includes(customer_dropdown_filter.toLowerCase()))
        }
        let salesperson_list = this.props.salesperson_list ? this.props.salesperson_list.filter(s => s.company_id === this.props.search_company_id) : [];

        //#region Cells



        const adj_desc_cell = (d) =>
            <Button
                onClick={() => this.open_adj_descr(d)}
                title={d.adj_description}
                size="sm"
                className={`border-0 ${d.adj_description ? '' : 'opacity-50'}`}
                variant={d.adj_description ? 'outline-success' : 'outline-secondary'}>
                {d.adj_description ?
                    svg_icons.filled_file_icon :
                    svg_icons.file_icon}

            </Button>



        const check_cell = (val, property, d) =>
            <FormCheck
                label=""
                className="allow-edit danger-check"
                disabled={selected_commission ? selected_commission.pk === d.pk || update_clicked ? false : true : true}
                onClick={(e) => this.click_checkbox(e, property, d)}
                checked={val === 'Y'}
            />

        const exempt_cell = (val, property, d) =>
            <FormCheck
                label=""
                className="allow-edit"
                disabled={selected_commission ? selected_commission.pk === d.pk || update_clicked ? false : true : true}
                onClick={(e) => this.click_checkbox(e, property, d)}
                checked={val === 'Y'}
            />

        const paid_check_cell = (val) =>
            <FormCheck
                label=""
                className="success-check no-edit"
                disabled={true}
                checked={val === 'Y'}
            />


        const text_cell = (value, title) => (
            <bdi className="text-nowrap no-edit" title={title}>
                {value}
            </bdi>
        );

        const currency = (val, title) => (
            <NumericFormat
                value={val ? val : 0}
                title={title}
                decimalScale={2}
                prefix="$ "
                allowNegative
                thousandSeparator=","
                displayType={'text'}
                disabled={true}
                className="outline-0 border-0 no-edit"
                style={{ width: '5rem' }}
                fixedDecimalScale
                renderText={(formattedValue) => <label className="ps-1">{formattedValue}</label>}
            />
        );

        const update_currency = (val, property, order_id, title) => (
            <NumericFormat
                value={val ? val : 0}
                onValueChange={(e) => this.props.update_orders(e, property, order_id)}
                decimalScale={2}
                prefix="$ "
                allowNegative
                displayType={'input'}
                thousandSeparator=","
                fixedDecimalScale
                disabled={update_clicked}
                className={`outline-0 border-0 allow-edit`}
                style={{ width: '5rem' }}
                title={title}
            />
        );

        const percent = (val) => (
            <NumericFormat
                value={val ? val*100 : 0}
                decimalScale={2}
                suffix=" %"
                allowNegative
                displayType={'text'}
                disabled={true}
                thousandSeparator=","
                fixedDecimalScale
                className="outline-0 border-0 no-edit"
                style={{ width: '5rem' }}
                fixedDecimalScale
                renderText={(formattedValue) => <label className="ps-1">{formattedValue}</label>}
            />
        );
        //#endregion Cells

        //#region Headers
        const header = (value,property, type_of, sequence) => ({
            column_title: value,
            sort_direction: sort_direction,
            is_sortable: 'Y',
            header_style: 'px-2 text-nowrap text-xs',
            property: property,
            type_of: type_of,
            sequence: sequence
        });

        const headers = [
            header('Order ID','order_id','string',1),
            header('Ship Date','shipped_date_str','date',3),
            header('Delivery Date','delivery_date_str','date',5),
            header('Customer ID','customer_id','string',2),
            header('Primary LAM','salesperson_id','string',4),
            header('Margin','margin','number',6),
            header('Commission', 'commission_amount', 'number', 7),
            header('Commission %', 'commission_percent', 'number', 8),
            header('Claim?','is_claim','string',9),
            header('Paid?','commission_paid','string',10),
            header('Exempt?','commission_exempt','string',11),
            header('Total Charge Override','total_charge_override','number',12),
            header('Carrier Expense Override','carrier_expense_override','number',13),
            header('Sold To Assets Override','sold_to_assets_override','number',14),
            header('Order Adjustment Date','order_adj_date_str','date',15),
            header('Adjustment Description','adj_description','string',16),
        ];
        //#endregion Headers


        //#region Data
        const data = [];
        const cell_styling='py-0 text-xs'
        if (current_orders) {
            if (search_value && Array.isArray(current_orders)) {

                current_orders = current_orders.filter(s => s[search_by].toLowerCase().includes(search_value.toLowerCase()))
             
            }
            const sortingStrategies = {
                'Order ID': (a, b) => a.order_id.localeCompare(b.order_id),
                'Ship Date': (a, b) => new Date(a.shipped_date_str) - new Date(b.shipped_date_str),
                'Delivery Date': (a, b) => new Date(a.delivery_date_str) - new Date(b.delivery_date_str),
                'Customer ID': (a, b) => a.customer_id.localeCompare(b.customer_id),
                'Primary LAM': (a, b) => a.salesperson_id.localeCompare(b.salesperson_id),
                'Margin': (a, b) => a.margin - b.margin,
                'Commission': (a, b) => a.commission_amount - b.commission_amount,
                'Commission %': (a, b) => a.commission_percent - b.commission_percent,
                'Claim?': (a, b) => a.is_claim - b.is_claim,
                'Paid?': (a, b) => a.commission_paid - b.commission_paid,
                'Exempt?': (a, b) => a.commission_exempt - b.commission_exempt,
                'Total Charge Override': (a, b) => a.total_charge_override - b.total_charge_override,
                'Carrier Expense Override': (a, b) => a.carrier_expense_override - b.carrier_expense_override,
                'Sold To Assets Override': (a, b) => a.sold_to_assets_override - b.sold_to_assets_override,
                'Order Adjustment Date': (a, b) => new Date(a.order_adj_date_str) - new Date(b.order_adj_date_str),
                'Adjustment Description': (a, b) => a.adj_description - b.adj_description,
            };
            current_orders = current_orders.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
          
            current_orders.filter(s=>s.title_id==='LAM').map(d =>
                data.push({
                    row_style: this.get_row_color(d),
                    cells: [
                        { cell: text_cell(d.order_id, ''), cell_style: `${cell_styling} ${data.find(s=>s.data.order_id===d.order_id)?'bg-danger':''}` },
                        { cell: text_cell(d.shipped_date_str, ''), cell_style: cell_styling },
                        { cell: text_cell(d.delivery_date_str, ''), cell_style: cell_styling },
                        { cell: text_cell(d.customer_id, d.customer_name), cell_style: cell_styling },
                        { cell: text_cell(`${d.salesperson_name} (${d.title_id})`, `${d.salesperson_id} (${d.title_id})`), cell_style: cell_styling },
                        { cell: currency(d.margin, ''), cell_style: cell_styling },
                        { cell: currency(d.commission_amount, `${d.commission_percent} %`), cell_style: cell_styling },
                        { cell: percent(d.commission_percent), cell_style: cell_styling },
                        { cell: check_cell(d.is_claim, 'is_claim', d), cell_style: cell_styling },
                        { cell: paid_check_cell(d.commission_paid), cell_style: cell_styling },
                        { cell: exempt_cell(d.commission_exempt, 'commission_exempt', d), cell_style: cell_styling },
                        { cell: update_currency(d.total_charge_override, 'total_charge_override', d, `Original Total Charge: $ ${d.total_charge}`), cell_style: cell_styling },
                        { cell: update_currency(d.carrier_expense_override, 'carrier_expense_override', d, `Original Carrier Expense: $ ${d.carrier_expense}`), cell_style: cell_styling },
                        { cell: update_currency(d.sold_to_assets_override, 'sold_to_assets_override', d, `Original Carrier Expense: $ ${d.carrier_expense}`), cell_style: cell_styling },
                        { cell: text_cell(d.order_adj_date, ''), cell_style: cell_styling },
                        { cell: adj_desc_cell(d), cell_style: `${cell_styling} ` },
                    ],
                    data:d
                })
            )
        }

        //#endregion Data

    
        const commissions_table = search_clicked ? <Alert variant="secondary" style={{width:'60rem'}}>Searching...</Alert>: (
            <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                table_headers={headers}
                table_data={data}
                row_click={(prop) => this.props.set_selected_commission(JSON.parse(prop))}
                table_title=""
                sort_field={sort_field}
                sort_direction={sort_direction}
                width={ this.props.width}
            />
        );



        const customer_dropdown =
            <Dropdown className="d-flex flex-column justify-content-start">
                <Dropdown.Toggle id="dropdown-custom-components" bsPrefix="text-sm border bg-white" variant="light" onClick={() => this.setState({ show_customer_dropdown: !show_customer_dropdown })}>
                    {search_value ? search_value : 'Select Customer'}
                </Dropdown.Toggle>
                {show_customer_dropdown ?
                    <Dropdown.Menu
                        show={show_customer_dropdown}
                        bsPrefix="text-sm p-1 d-flex flex-column justify-content-start bg-white rounded opacity-100 border bg-light-gray"
                        style={{ zIndex: 3, overflow: 'auto', maxHeight: '30rem' }}
                        align={ "start"}
                    >
                        <Form.Control
                            autoFocus
                            className="text-sm"
                            placeholder="Type to filter..."
                            onChange={(e) => this.setState({ customer_dropdown_filter: e.target.value })}
                            value={customer_dropdown_filter}
                            style={{ zIndex: 4 }}
                        />
                        {customer_ord_list.slice(0, 50).sort((a, b) => a.customer_name.localeCompare(b.customer_name)).map(d =>
                            <Dropdown.Item
                                eventKey={d.customer_id}
                                key={d.customer_id}
                        
                                onClick={() => this.setState({ search_value: d.customer_id, show_customer_dropdown: false })}>
                                {d.customer_name}
                            </Dropdown.Item>
                        )}


                    </Dropdown.Menu> :
                    null
                }
             
            </Dropdown>
   

        const company_id_search = (
            <InputGroup className="rounded p-1 w-25">
                <Form.Select
                    className="p-0 ps-1  outline-0 text-xs shadow-0"
                    name="search_company_id"
                    value={this.props.search_company_id}
                    onChange={(e) => this.props.update_search_company_id(e.target.value)}
                >

                    <option value="TMS">SLC</option>
                    <option value="TMS2">SLUSA</option>
                </Form.Select>
            </InputGroup>
        );

        const order_id_search = (
            <InputGroup className="rounded p-1 w-100">
                <Form.Select
                    type="text"
                    className="p-0 ps-1 outline-0 text-xs shadow-0"
                    name="search_salesperson_id"
                    placeholder="Salesperson ID"
                    value={search_by}
                    onChange={(e) => this.setState({
                        search_by: e.target.value,
                        search_value: '',
                        shipped_start: format(addMonths(new Date(), -1), 'yyyy-MM-dd'),
                        shipped_end: format(new Date(), 'yyyy-MM-dd')
                    })}
                >
                    <option value=''>Search By</option>
                    <option value='order_id'>Order ID</option>
                    <option value='customer_id'>Customer</option>
                    <option value='salesperson_id'>Salesperson ID</option>
                </Form.Select>
                {search_by !== 'salesperson_id' ?
                    <Form.Control
                        type="text"
                        className="p-0 ps-1  outline-0 text-med shadow-0"
                        name="search_value"
                        disabled={update_clicked || search_clicked}
                        placeholder={search_by}
                        value={search_value}
                        onChange={(e) => this.setState({ search_value: e.target.value })}
                    /> :
                    <Form.Select
                        type="text"
                        className="p-0 ps-1  outline-0 text-med shadow-0"
                        name="search_value"
                        disabled={update_clicked || search_clicked}
                        placeholder={search_by}
                        value={search_value}
                        onChange={(e) => this.setState({ search_value: e.target.value })}
                    >
                        <option value=''>Select salesperson</option>
                        {salesperson_list.map(d =>
                            <option value={d.salesperson_id} kay={d.salesperson_id}>{`${d.salesperson_id}`}</option>
                        )}
                    </Form.Select>}
               
              
             
                {search_by !== 'order_id' ?
                    <Form.Control
                        type="date"
                        className="p-0 ps-1  outline-0 text-med shadow-0"
                        name="shipped_start"
                        disabled={update_clicked || search_clicked}
                        value={shipped_start}
                        onChange={(e) => this.setState({ shipped_start: e.target.value })}
                    /> :
                    null
                }
                {search_by !== 'order_id' ?
                    <InputGroup.Text className="p-0 px-1  outline-0 text-med shadow-0">To</InputGroup.Text> :
                    null
                }
                {search_by !== 'order_id' ?
                    <Form.Control
                        type="date"
                        className="p-0 ps-1  outline-0 text-med shadow-0"
                        name="shipped_end"
                        disabled={update_clicked || search_clicked}
                        value={shipped_end}
                        onChange={(e) => this.setState({ shipped_end: e.target.value })}
                    /> :
                    null
                }
                
                <Button
                    className="text-xs"
                    style={{ borderRadius: '0rem 0.2rem 0.2rem 0rem' }}
                    variant="success"
                    disabled={update_clicked || search_clicked || (!search_value && search_by === 'order_id') || (search_by !== 'order_id' && (!shipped_start || !shipped_end))}
                    onClick={() => this.search_orders(search_by, search_value)}
                    onKeyDown={(e)=>this.handleKeyPress(e)}
                >
                    Go
                </Button>
            </InputGroup>
        );

        const sum_margin = current_orders.length > 0 ? current_orders.reduce((total, currentValue) => total = total + currentValue.margin, 0) : 0;




        return (
            <div style={{ overflow: 'auto' }} className="d-flex flex-column">
     
                <div className="d-flex flex-row" style={{ width: '50vw' }}>
                        {company_id_search}
                        {order_id_search}
                </div>
                {search_clicked ?
                    null :
                    <div className="d-flex flex-row my-2 justify-content-start" style={{ width: '80rem', overflow: 'auto' }}>
                
                        <InputGroup className="align-self-center flex-nowrap">
                            <InputGroup.Text className="text-sm text-bold">Total Orders:</InputGroup.Text>
                            <InputGroup.Text className="text-sm text-bold bg-white">{current_orders.length}</InputGroup.Text>
                            <InputGroup.Text className="text-sm text-bold">Total Margin:</InputGroup.Text>
                            <InputGroup.Text className="text-sm text-bold bg-white">{currency(sum_margin, 'Total Margin')}</InputGroup.Text>
                        </InputGroup>
                 
           
                    </div>
                }
            
                {this.props.orders ?
                    <div style={{ maxHeight: '30rem', width: '100%', overflow: 'auto' }}>
                        {commissions_table}
                      
                    </div> :
                    null
                }
          
  
                {this.props.orders?this.props.orders.list.length > 500 ? (
                    <Button
                        variant="link"
                        size="sm"
                        className="hover-over text-sm underlined"
                        onClick={() => this.setState({ set_page: set_page + 100 })}
                    >
                        Load More
                    </Button>
                ) : null:null}
              
                <Button
                    disabled={update_clicked}
                    variant={update_clicked ? 'outline-success' : 'success'}
                    className="text-sm  align-self-center mt-3"
                    size="sm"
                    onClick={() => this.props.put_order_commissions()}>
                    {update_clicked ? 'Updating...' : 'Update'}
                </Button>
                
                {selected_commission ?
                    <OrderAdjDescriptionModal
                        show={show_adj_desc}
                        onHide={() => this.setState({ show_adj_desc: false })}
                        on_update={(value, pk) => this.props.update_orders_varchar(value, 'adj_description', pk)}
                        selected_commission={selected_commission}
                    /> :
                null}
        
            </div>
        );
    }




}
