import {  Table, Accordion, Card, Collapse} from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import TableHeader from './TableHeader';
import sortByStrategy from '../../Domain/sorting-strategy';
import { useState, useContext} from 'react'
/**
 * TableHeader
 * @param {function} onSort Sorts the column
 * @param {string} table_title Title of the table.
 * @param {object[]} table_headers A list of header objects {column_title, sort_direction}
 * @param {object[]} table_data A list of data objects { cells, row_style, row_click()}
 * @returns
 */






function SortableTable({ ...props }) {
    const [selectedRow, setSelectedRow] = useState();
    function CustomToggle({ children, eventKey, callback }) {
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );
        setSelectedRow(eventKey)
        return (
            <div
                className="text-sm d-flex flex-row justify-content-around"
                onClick={decoratedOnClick}
            >
                {children}
            </div>
        );
    }

   
    const table_header_row = props.table_headers ? (
        <thead className="">
            <tr className="header-row">
                {props.table_headers.map((d, index) => (
                    <TableHeader
                        key={index}
                        header_style={d.header_style}
                        table_header={d}
                        sorted_by={props.sort_field === d.column_title ? 'Y' : 'N'}
                        onSort={() => props.onSort(d.column_title)}
                    />
                ))}
            </tr>
        </thead>
    ) : null;
    let current_data = props.table_data ? props.table_data : [];
    //let sorting_strategies = { direction: props.sort_direction }
    //if (current_data.length > 1 && props.table_headers) {
    //    props.table_headers.map(d => {
    //        if (d.property) {
    //            if (d.type_of === 'string') {
    //                sorting_strategies[d.property] = (a, b) => a.data[d.property].localeCompare(b.data[d.property])
    //            }
    //            if (d.type_of === 'date') {
    //                sorting_strategies[d.property] = (a, b) => new Date(a.data[d.property])-new Date(b.data[d.property])
    //            }
    //            if (d.type_of === 'number') {
    //                sorting_strategies[d.property] = (a, b) => a.data[d.property] - b.data[d.property]
    //            }
    //        }
            
    //    })
    //    current_data = current_data.sort(sortByStrategy(sorting_strategies, props.sort_field, props.sort_direction))


    //}
    const table_data_rows = current_data ? (
        <tbody className='tbody'>
            {current_data.map((d, index) => (
                <tr
                    onClick={() => props.row_click(JSON.stringify(d.data))}
                    key={d.id ? d.id : index}
                    className={`body-row ${d.row_style}`}
                >
                    {d.cells.map((cell, index) => (
                        <td className={'cell ' + cell.cell_style} key={index}>
                            <bdi>{cell.cell}</bdi>
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    ) : null;

    //const accordion = <Accordion defaultActiveKey="0" >
    //    {current_data.map((d, index) => (
    //        <Card key={d.data.id ? d.data.id : d.data.pk} eventKey={index}>
    //        <Card.Header>
    //                <CustomToggle eventKey={index.toString()}>
    //                    {props.table_headers.find((x)=>x.sequence===1)?
    //                        <div className="d-flex flex-column text-sm">
    //                            <bdi className="text-bold pe-2">{props.table_headers.find((x) => x.sequence === 1).column_title}</bdi>
    //                            <bdi>{d.data[props.table_headers.find((x) => x.sequence === 1).property]}</bdi>                              
    //                        </div>
    //                        :
    //                        null}
    //                    {props.table_headers.find((x) => x.sequence === 2) ?
    //                        <div className="d-flex flex-column text-sm">
    //                            <bdi className="text-bold pe-2">{props.table_headers.find((x) => x.sequence === 2).column_title}</bdi>
    //                            <bdi>{d.data[props.table_headers.find((x) => x.sequence === 2).property]}</bdi>
    //                        </div>
    //                        :
    //                        null}
    //                    {props.table_headers.find((x) => x.sequence === 3) ?
    //                        <div className="d-flex flex-column text-sm">
    //                            <bdi className="text-bold pe-2">{props.table_headers.find((x) => x.sequence === 3).column_title}</bdi>
    //                            <bdi>{d.data[props.table_headers.find((x) => x.sequence === 3).property]}</bdi>
    //                        </div>
    //                        :
    //                        null}
                       
    //                </CustomToggle>
    //        </Card.Header>
    //            <Accordion.Collapse eventKey={index.toString()} >
    //                <Card.Body className="d-flex flex-column">
    //                    {props.table_headers.length === d.cells.length ?
    //                        <div className=''>
    //                            {d.cells.map((cell, index) => (
    //                                <div className={'cell ' + cell.cell_style} key={index}>
    //                                    <bdi className="text-bold pe-2">{props.table_headers[index].column_title}:</bdi>
    //                                    <bdi>{cell.cell}</bdi>
    //                                </div>
    //                            ))}
    //                        </div> :
    //                        null
    //                    }                    
    //                </Card.Body>
    //            </Accordion.Collapse>
    
    //    </Card>
        

    //    ))}     
    //</Accordion>

    return (
        <div className="d-flex flex-column justify-content-center w-100 table-round p-0  m-0 bg-white">
            <Table className="table m-0" size="sm" responsive>
                {table_header_row}
                {table_data_rows}
            </Table>
        </div>
    );
}

export default SortableTable;
