import React, { Component } from 'react';
import { Button, Form, InputGroup,   ListGroup } from 'react-bootstrap';
import * as contactFunctions from '../Functions/ContactFunctions';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as svg_icons from '../../Media/svg_exports';

export class ContactsTab extends Component {
    static displayName = ContactsTab.name;
    payable_contact
    constructor(props) {
        super(props);
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        this.state = {
            contact_event: '',
            contact_button_clicked: false,
            edit_contact_button_clicked: false,
           
            
            edit_contact: false,
            
            error_message: '',
            new_contact_no_email: false,
            copy_icon: '',

            add_new_contact: false,
            new_contact: {
                __type: 'contact',
                company_id: selected_account ? selected_account.company_id : '',
                id: '',
                email: '',
                is_active: 'Y',
                mobile_phone: '',
                name: '',
                parent_row_id: selected_account ? selected_account.id : '',
                payable_contact: 'N',
                phone: '',
                sequence: 1,
                title: '',
                type_of: 'B',
                is_primary: 'N',
                fax: '',
                comments:''
            },
            clicked: false,
            message: '',
            selected_contact: null
        };

    }

    update_new_contact(name, value) {
        let new_contact = this.state.new_contact;
        new_contact[name] = value;
        this.setState({ new_contact: new_contact })
    }

    update_selected_contact(name, value) {
        let selected_contact = this.state.selected_contact;
        selected_contact[name] = value;
        this.setState({ selected_contact: selected_contact })
    }

    reset_new_contact() {
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        this.setState({
            add_new_contact: false,
            new_contact: {
                __type: 'contact',
                company_id: selected_account ? selected_account.company_id : '',
                id: '',
                email: '',
                is_active: 'Y',
                mobile_phone: '',
                name: '',
                parent_row_id: selected_account ? selected_account.id : '',
                payable_contact: 'N',
                phone: '',
                sequence: 1,
                title: '',
                type_of: 'B',
                is_primary: 'N',
                fax: '',
                comments:''
            }
            })
    }

    new_contact_form() {
        const clicked = this.state.clicked;
        const new_contact = this.state.new_contact;

        const close_button = <Button
            variant="light"
            className="btn-op70 bg-transparent border-0 hover-over p-0 align-self-center"
            onClick={() => this.reset_new_contact()}
            size="sm">
            {svg_icons.close_icon}
        </Button>;

        const text_input = (label, property, maxLength, placeholder) =>
            <InputGroup className=" d-flex flex-row mt-3" style={{ width: '28rem' }} title={`${label} cannot exceed (${maxLength}) characters`}>
                <InputGroup.Text className="text-sm flex-grow-2" style={{ width: '8rem' }}>{label}</InputGroup.Text>
                <Form.Control
                    type="text"
                    maxLength={maxLength}
                    id={`contact-${property}-value`}
                    value={new_contact[property]}
                    placeholder={placeholder}
                    className="text-sm flex-grow-1"
                    onChange={(e) => this.update_new_contact(property, e.target.value)}
                />
            </InputGroup>

        const required_text_input = (label, property, maxLength, placeholder) =>
            <InputGroup className=" d-flex flex-row mt-3" style={{ width: '28rem' }} title={`${label} cannot exceed (${maxLength}) characters`}>
                <InputGroup.Text className="text-sm flex-grow-2" style={{ width: '8rem' }}>{label}</InputGroup.Text>
                <Form.Control
                    type="text"
                    maxLength={maxLength}
                    id={`contact-${property}-value`}
                    value={new_contact[property]}
                    placeholder={placeholder}
                    isValid={new_contact[property]}
                    isInvalid={!new_contact[property]}
                    className="text-sm flex-grow-1"
                    onChange={(e) => this.update_new_contact(property, e.target.value)}
                />
            </InputGroup>

        const primary_contact_input =
            <InputGroup className=" d-flex flex-row" style={{ width: '28rem' }} title={'Set as primary contact'}>
                <InputGroup.Text className="text-sm flex-grow-2" style={{ width: '8rem' }}>Primary Contact?</InputGroup.Text>
                <Button
                    onClick={() => this.update_new_contact('is_primary', 'Y')}
                    variant={new_contact.is_primary === 'Y' ? 'success' : 'outline-secondary'}
                    className="btn-op70 text-sm flex-grow-1 opacity-75"
                    style={{ border: 'solid rgba(0,0,0,0.2) 0.1rem' }}
                    disabled={clicked}
                >
                    Yes
                </Button>
                <Button
                    onClick={() => this.update_new_contact('is_primary', 'N')}
                    variant={new_contact.is_primary === 'N' ? 'danger' : 'outline-secondary'}
                    className="btn-op70 text-sm flex-grow-1 opacity-75"
                    style={{ border: 'solid rgba(0,0,0,0.2) 0.1rem' }}
                    disabled={clicked}
                >
                    No
                </Button>
            </InputGroup>

        const payable_contact_input =
            <InputGroup className="d-flex flex-row mt-3" style={{ width: '28rem' }} title={'Set as payable contact'}>
                <InputGroup.Text className="text-sm flex-grow-2" style={{ width: '8rem' }}>Payable Contact?</InputGroup.Text>
                <Button
                    onClick={() => this.update_new_contact('payable_contact', 'Y')}
                    variant={new_contact.payable_contact === 'Y' ? 'success' : 'outline-secondary'}
                    className="btn-op70 text-sm flex-grow-1 opacity-75"
                    style={{ border: 'solid rgba(0,0,0,0.2) 0.1rem' }}
                    disabled={clicked}
                >
                    Yes
                </Button>
                <Button
                    onClick={() => this.update_new_contact('payable_contact', 'N')}
                    variant={new_contact.payable_contact === 'N' ? 'danger' : 'outline-secondary'}
                    className="btn-op70 text-sm flex-grow-1 opacity-75"
                    style={{ border: 'solid rgba(0,0,0,0.2) 0.1rem' }}
                    disabled={clicked}
                >
                    No
                </Button>
            </InputGroup>

        return this.state.add_new_contact ? (
            <div fluid="xs" className="p-3 border rounded d-flex flex-column">
                <div className="d-flex flex-row justify-content-between">
                    <h6 className="ps-2 tile-title align-self-center opacity-75">Add New Contact</h6>
                    {close_button}
                </div>
                <hr />
                <div className="d-flex flex-column w-100">
                    {primary_contact_input}
                    {payable_contact_input}
                    {required_text_input('Contact Name', 'name', 40, 'required')}
                    {required_text_input('Contact Title', 'title', 30, 'required')}
                    {text_input('Main Phone', 'phone', 20, 'optional (ex: 1-800-555-5555, 1234)')}
                    {text_input('Cell Phone', 'mobile_phone', 20, 'optional (ex: 555-555-5555)')}
                    {text_input('Email', 'email', 60, 'optional (ex: example@scotlynn.com)')}
                    {text_input('Fax Number', 'fax', 20, 'optional (ex: 1-800-555-5555)')}
                    {text_input('Notes', 'comments', 50, 'optional (ex: Retiring in June 2025')}
                    <Button
                        variant={clicked ? 'outline-success' : 'success'}
                        disabled={clicked || !new_contact.name || !new_contact.title}
                        onClick={() => this.post_contact()}
                        className="btn-op70 w-25 text-sm mt-3"
                    >
                        {clicked ? 'Adding...' : 'Add'}
                    </Button>
                </div>
            </div>
        ) :
            <Button
                variant="outline-success"
                className="btn-op70 d-flex flex-row justify-content-center text-sm"
                onClick={() => this.setState({ add_new_contact: true })}
            >
                {svg_icons.new_contact_icon}
                <label className="ps-2 align-self-center">Add New Contact</label>
            </Button>;
    }

    edit_contact_form() {
        const clicked = this.state.clicked;
        const selected_contact = this.state.selected_contact

        const close_button = <Button
            variant="light"
            className="btn-op70 bg-transparent border-0 hover-over p-0 align-self-center"
            onClick={() => this.setState({ selected_contact: null })}
            size="sm">
            {svg_icons.close_icon}
        </Button>;

        const text_input = (label, property, maxLength, placeholder) =>
            <InputGroup className=" d-flex flex-row mt-3" style={{ width: '28rem' }} title={`${label} cannot exceed (${maxLength}) characters`}>
                <InputGroup.Text className="text-sm flex-grow-2" style={{ width: '8rem' }}>{label}</InputGroup.Text>
                <Form.Control
                    type="text"
                    maxLength={maxLength}
                    id={`contact-${property}-value`}
                    value={selected_contact[property]}
                    placeholder={placeholder}
                    className="text-sm flex-grow-1"
                    onChange={(e) => this.update_selected_contact(property, e.target.value)}
                />
            </InputGroup>

        const required_text_input = (label, property, maxLength, placeholder) =>
            <InputGroup className=" d-flex flex-row mt-3" style={{ width: '28rem' }} title={`${label} cannot exceed (${maxLength}) characters`}>
                <InputGroup.Text className="text-sm flex-grow-2" style={{ width: '8rem' }}>{label}</InputGroup.Text>
                <Form.Control
                    type="text"
                    maxLength={maxLength}
                    id={`contact-${property}-value`}
                    value={selected_contact[property]}
                    placeholder={placeholder}
                    isValid={selected_contact[property]}
                    isInvalid={!selected_contact[property]}
                    className="text-sm flex-grow-1"
                    onChange={(e) => this.update_selected_contact(property, e.target.value)}
                />
            </InputGroup>

        const is_active_input =
            <InputGroup className=" d-flex flex-row mb-3" style={{ width: '28rem' }} title={'Set as primary contact'}>
                <InputGroup.Text className="text-sm flex-grow-2" style={{ width: '8rem' }}>Is Active?</InputGroup.Text>
                <Button
                    onClick={() => this.update_selected_contact('is_active', 'Y')}
                    variant={selected_contact.is_active === 'Y' ? 'success' : 'outline-secondary'}
                    className="btn-op70 text-sm flex-grow-1 opacity-75"
                    style={{ border: 'solid rgba(0,0,0,0.2) 0.1rem' }}
                    disabled={clicked}
                >
                    Yes
                </Button>
                <Button
                    onClick={() => this.update_selected_contact('is_active', 'N')}
                    variant={selected_contact.is_active === 'N' ? 'danger' : 'outline-secondary'}
                    className="btn-op70 text-sm flex-grow-1 opacity-75"
                    style={{ border: 'solid rgba(0,0,0,0.2) 0.1rem' }}
                    disabled={clicked}
                >
                    No
                </Button>
            </InputGroup>

        const primary_contact_input =
            <InputGroup className=" d-flex flex-row" style={{ width: '28rem' }} title={'Set as primary contact'}>
                <InputGroup.Text className="text-sm flex-grow-2" style={{ width: '8rem' }}>Primary Contact?</InputGroup.Text>
                <Button
                    onClick={() => this.update_selected_contact('is_primary', 'Y')}
                    variant={selected_contact.is_primary === 'Y' ? 'success' : 'outline-secondary'}
                    className="btn-op70 text-sm flex-grow-1 opacity-75"
                    style={{ border: 'solid rgba(0,0,0,0.2) 0.1rem' }}
                    disabled={clicked}
                >
                    Yes
                </Button>
                <Button
                    onClick={() => this.update_selected_contact('is_primary', 'N')}
                    variant={selected_contact.is_primary === 'N' ? 'danger' : 'outline-secondary'}
                    className="btn-op70 text-sm flex-grow-1 opacity-75"
                    style={{ border: 'solid rgba(0,0,0,0.2) 0.1rem' }}
                    disabled={clicked}
                >
                    No
                </Button>
            </InputGroup>

        const payable_contact_input =
            <InputGroup className="d-flex flex-row mt-3" style={{ width: '28rem' }} title={'Set as payable contact'}>
                <InputGroup.Text className="text-sm flex-grow-2" style={{ width: '8rem' }}>Payable Contact?</InputGroup.Text>
                <Button
                    onClick={() => this.update_selected_contact('payable_contact', 'Y')}
                    variant={selected_contact.payable_contact === 'Y' ? 'success' : 'outline-secondary'}
                    className="btn-op70 text-sm flex-grow-1 opacity-75"
                    style={{ border: 'solid rgba(0,0,0,0.2) 0.1rem' }}
                    disabled={clicked}
                >
                    Yes
                </Button>
                <Button
                    onClick={() => this.update_selected_contact('payable_contact', 'N')}
                    variant={selected_contact.payable_contact === 'N' ? 'danger' : 'outline-secondary'}
                    className="btn-op70 text-sm flex-grow-1 opacity-75"
                    style={{ border: 'solid rgba(0,0,0,0.2) 0.1rem' }}
                    disabled={clicked}
                >
                    No
                </Button>
            </InputGroup>

        return selected_contact ? (
            <div fluid="xs" className="p-3 border rounded d-flex flex-column">
                <div className="d-flex flex-row justify-content-between">
                    <h6 className="ps-2 tile-title align-self-center opacity-75">Edit Contact</h6>
                    {close_button}
                </div>
                <hr />
                <div className="d-flex flex-column w-100">
                    {is_active_input}
                    {primary_contact_input}
                    {payable_contact_input}
                    {required_text_input('Contact Name', 'name', 40, 'required')}
                    {required_text_input('Contact Title', 'title', 30, 'required')}
                    {text_input('Main Phone', 'phone', 20, 'optional (ex: 1-800-555-5555, 1234)')}
                    {text_input('Cell Phone', 'mobile_phone', 20, 'optional (ex: 555-555-5555)')}
                    {text_input('Email', 'email', 60, 'optional (ex: example@scotlynn.com)')}
                    {text_input('Fax Number', 'fax', 20, 'optional (ex: 1-800-555-5555)')}
                    {text_input('Notes', 'comments', 50, 'optional (ex: Retiring in June 2025')}
                    <Button
                        variant={clicked ? 'outline-success' : 'success'}
                        disabled={clicked || !selected_contact.name || !selected_contact.title}
                        onClick={() => this.put_contact()}
                        className="btn-op70 w-25 text-sm mt-3"
                    >
                        {clicked ? 'Saving...' : 'Save'}
                    </Button>
                </div>
            </div>
        ) :
            null;
    }

    async post_contact() {
        this.setState({ clicked: true, message: 'Adding Contact...' })
        const selected_account_data = this.props.selected_account_data;
        let selected_account = selected_account_data ? selected_account_data.account : null;
        try {
            const new_contact = this.state.new_contact
            let contacts = selected_account_data ? selected_account_data.contacts ? selected_account_data.contacts : [] : [];
            const contact_data = await contactFunctions.Post(new_contact);
            contacts.push(contact_data);
            this.props.update_selected_account_data('contacts', contacts);
            if (new_contact.is_primary === 'Y' && contact_data) {
                selected_account.primary_contact = contact_data.name;
                this.props.update_selected_account_data('account', selected_account);
            }
            this.setState({ clicked: false, message: '' })
            this.reset_new_contact();

        } catch (e) {
            console.log(e);
            this.setState({ clicked: false, message: '' })
        } 
    }

    async put_contact() {
        this.setState({ clicked: true, message: 'Saving contact...' })
        const selected_account_data = this.props.selected_account_data;
        let selected_account = selected_account_data ? selected_account_data.account : null;

        try {
            let contacts = selected_account_data ? selected_account_data.contacts ? selected_account_data.contacts : [] : [];
            const selected_contact = this.state.selected_contact;
            const contact_data = await contactFunctions.Put(selected_contact);
            const idx = contacts.findIndex(s => s.id === selected_contact.id);
            contacts[idx] = contact_data;
            this.props.update_selected_account_data('contacts', contacts);
            if (selected_contact.is_primary === 'Y' && contact_data) {
                selected_account.primary_contact = contact_data.name;
                this.props.update_selected_account_data('account', selected_account);
            }
            this.setState({ clicked: false, message: '', selected_contact: null })
        } catch (e) {
            console.log(e)
            this.setState({ clicked: false, message: '' })
        }
    }



    get_phone_property(contact, property, label) {
        let phone = '';
        if (contact) {
            if (contact[property] && contact.is_active === 'Y') {
                phone = contact[property] ? `tel:+${utilityFunctions.Format_Phone_Number(contact[property])}` : '';
                return (
                    <InputGroup className="d-flex flex-row " style={{ width: '20rem' }}>
                        <InputGroup.Text className="text-nowrap text-sm p-1 text-bold" style={{ width: '12rem' }}>
                            {`[${label}]: ${ contact[property]}`}
                        </InputGroup.Text>

                        {phone ? <Button onClick={() => window.open(phone, '_blank', 'noreferrer')} className="bg-phone flex-grow-3">{svg_icons.phone_icon}</Button> : null}
                    </InputGroup>
                )
            } else {return null;}           
        } else {return null;}
    }

    get_email_property(contact, property, label) {
        let email = '';
        if (contact) {
            if (contact[property] && contact.is_active==='Y') {
                email = contact[property] ? `mailto:${contact[property]}` : '';
                return (
                    <InputGroup className="d-flex flex-row" style={{ width: '20rem' }}>
                        <InputGroup.Text className="text-nowrap text-sm p-1 text-bold" style={{ width: '12rem' }}>
                            {`${contact[property]}`}
                        </InputGroup.Text>

                        {email ? <Button onClick={() => window.open(email, '_blank', 'noreferrer')} className="bg-email flex-grow-3">{svg_icons.email_icon}</Button> : null}
                    </InputGroup>
                )
            } else { return null; }
        } else { return null; }
    }

    get_contacts_list() {
        const selected_account_data = this.props.selected_account_data;
        let contacts = selected_account_data ? selected_account_data.contacts ? selected_account_data.contacts : [] : [];
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const selected_contact = this.state.selected_contact;
        if (contacts.length) {
            let primary_contacts = contacts.filter(s => s.is_primary === 'Y')
            let non_primary_contacts = contacts.filter(s => s.is_primary !== 'Y').sort((a, b) => a.sequence - b.sequence)
            primary_contacts = primary_contacts.concat(non_primary_contacts.filter(s=>s.is_active === 'Y'))
            contacts = primary_contacts.concat(non_primary_contacts.filter(s=>s.is_active !== 'Y'))
        }
       
        const options_button = (d) =>  <Button
            size="sm"
            className="bg-transparent"
            variant="light"
            onClick={() => this.setState({ selected_contact:d })}
        >
            {svg_icons.edit_icon}
        </Button>;


        const active_status = (d) => d.is_active === 'Y' ?
            <small className="fw-bold opacity-75 text-sm text-success">Active</small> :
            <small className="fw-bold opacity-50 text-sm">Inactive</small>;

        const payable_contact = (d) => d.payable_contact === 'Y' ?
            <small className="fw-bold opacity-75 text-sm text-success">
                <bdi>  Payable Contact</bdi>
                {svg_icons.simple_checkmark_icon}
              
            </small> :
            null;

        const notes = (d) => d.comments ?
            <small className="fw-bold opacity-75 text-sm">{`Notes: ${d.comments}`}</small> :
          null;

        const name_title = (d) => selected_account?
            <div className="d-flex flex-row justify-content-between" >
                <div className="fw-bold d-flex flex-row text-lg">

                    {selected_account.primary_contact===d.name ? <div className="align-self-center pe-1" title="Primary contact">{svg_icons.start_icon}</div> : null}
                    <bdi className="align-self-center">{d.name ? d.name : 'No Name'}</bdi>
                    {d.title ? <bdi className="ps-1 opacity-75">({d.title})</bdi> : null}

                </div>
                {active_status(d) }
        </div>:null;





        if (contacts) {
            return (
                <ListGroup className="d-flex flex-column w-100">
                    {contacts.map(d =>
                        <ListGroup.Item key={d.id} className="pb-3 w-100">
                            <div className="d-flex flex-column justify-content-start w-100">
                                {name_title(d)}
                                <div className="d-flex flex-row justify-content-bewteen mt-2">
                                    {this.get_phone_property(d, 'phone', 'Main')}
                                    {this.get_email_property(d, 'email', 'Email')}
                                </div>
                                <div className="d-flex flex-row justify-content-bewteen mt-2">
                                    {this.get_phone_property(d, 'mobile_phone', 'Mobile')}
                                    {payable_contact(d)}
                                </div>
                                <div className="d-flex flex-row justify-content-bewteen mt-2">
                                    {this.get_phone_property(d, 'fax', 'Fax')}
                                    {notes(d)}
                                </div> 
                               
                                
                          
                                
                                <div className="d-flex flex-row justify-content-end w-100">
                                    {options_button(d)}
                                </div>
                                {selected_contact ?
                                    selected_contact.id === d.id ?
                                        this.edit_contact_form()
                                        :
                                        null :
                                    null}
                            </div>

                        </ListGroup.Item>
                    )}

                </ListGroup>)
        } else {
            return null
        }

    }





    render() {
        const { message } = this.state;


        //#region Contacts Container
        const contacts_container = (
            <div className=" p-0 d-flex flex-column">
                <div className="d-flex flex-row">
                    <h6 className="text-muted tile-title">Contacts</h6>
                   
                    {message ? <small className="ps-2">{message}</small>:null}
                   
                </div>
               
                <hr className="mt-0" />
                <br />
               
                {this.new_contact_form()}
                <br />
                {this.get_contacts_list()}
                
            </div>
        );
        //#endregion Contacts Container

        return <div className="d-flex flex-column px-2 w-100 pb-5">{contacts_container}</div>;
    }
}
