import React, { Component } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import SortableTable from './SortableTable';
import { format, isToday } from 'date-fns';

import { MsalContext } from '@azure/msal-react';
import sortByStrategy from '../../Domain/sorting-strategy';
import * as svg_icons from '../../Media/svg_exports'
export class ExpiredProspectsTile extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: 'H',
            sort_field: 'Days Left',
            type_selected:'Expiring Today',
            button_clicked: false,
            lam_selected:''
            
        };
    }




    render() {
        const { sort_direction, sort_field, show_pop, button_clicked, type_selected, lam_selected } = this.state;


        //#region Table Data
        let expired_account_list = this.props.expired_accounts ? this.props.expired_accounts : [];
        const header_style = 'text-xs text-nowrap'
        const expired_headers = [
            {
                column_title: 'Account',
                sort_direction: sort_direction,
                is_sortable: 'Y',
                header_style: header_style,
            },
            {
                column_title: 'LAM',
                sort_direction: sort_direction,
                is_sortable: 'Y',
                header_style: header_style,
            },
            {
                column_title: 'Expiry Date',
                sort_direction: sort_direction,
                is_sortable: 'Y',
                header_style: header_style,
            },
            {
                column_title: 'Type',
                sort_direction: sort_direction,
                is_sortable: 'Y',
                header_style: header_style,
            },
            {
                column_title: 'Extension Pending?',
                sort_direction: sort_direction,
                is_sortable: 'Y',
                header_style: header_style,
            },
      
        ];
        const sortingStrategies = {
            Account: (a, b) => a.customer_name.localeCompare(b.customer_name),
            LAM: (a, b) => a.salesperson_id.localeCompare(b.salesperson_id),
            'Expiry Date': (a, b) => new Date(a.expiry_date) - new Date(b.expiry_date),
            'Type': (a, b) => a.type_of.localeCompare(b.type_of),
            'Extension Pending': (a, b) => a.remarks-b.remarks,
        };
        if (expired_account_list) {
            if (type_selected === 'Expiring Today') {
                expired_account_list = expired_account_list.filter(s => s.short_type_of === 'P' && isToday(new Date(s.expiry_date)))
            } else {
                expired_account_list = expired_account_list.filter(s => s.type_of.includes(type_selected))
            }
            if (lam_selected) {
                expired_account_list = expired_account_list.filter(s => s.salesperson_id===lam_selected)
            }

            expired_account_list = expired_account_list.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
        }
      


        const expired_data = [];

        let lam_list = [];
        for (let i = 0; i < expired_account_list.length; i++) {
            if (!lam_list.find(s => s.salesperson_id === expired_account_list[i].salesperson_id)) {
                lam_list.push({ salesperson_id: expired_account_list[i].salesperson_id, salesperson_name: expired_account_list[i].salesperson_name })
            }
            let current_account = expired_account_list[i];
            let row_style = ''
            if (current_account.short_type_of === 'P' && isToday(new Date(current_account.expiry_date)) && !current_account.remarks) {
                row_style='bg-danger fw-bold'
            }
            let is_pending = <label className="opacity-25  w-100 text-center"><bdi className="border">{svg_icons.simple_checkmark_icon}</bdi></label>
            if (current_account.remarks) {
                is_pending = <label className="text-success w-100 text-center" title={current_account.date_requested_str}>{svg_icons.checkmark_icon}</label>
            }

            let cell_style ='py-1'

            expired_data.push({
                row_style: row_style,
                cells: [
                    { cell: current_account.customer_name, cell_style: cell_style },
                    { cell: current_account.salesperson_id, cell_style: cell_style },
                    { cell: current_account.expiry_date, cell_style: cell_style },
                    { cell: current_account.type_of, cell_style: cell_style },
                    { cell: is_pending, cell_style: `${cell_style}` }
                ],
                data: current_account,
            });
        }



        const expired_accounts_table = (
            <SortableTable
                onSort={(prop) =>
                    this.setState({
                        sort_field: prop,
                        sort_direction: sort_direction === 'H' ? 'L' : 'H',
                    })
                }
                table_headers={expired_headers}
                table_data={expired_data}
                table_title=""
                row_click={(prop) => this.props.view_account({ company_id: JSON.parse(prop).company_id, id: JSON.parse(prop).customer_id }, expired_account_list)}
                sort_field={sort_field}
            />
        );
        //#endregion Table Data

        return (
            <div className="d-flex flex-column p-1" style={{ maxHeight: '30rem', overflow: 'auto' }}>
                <h6 className="tile-title">Expiring Accounts</h6>
                {this.props.expired_accounts ?
                    <InputGroup className="mb-3">
                        <InputGroup.Text className="text-xs">Type</InputGroup.Text>
                        <Form.Select value={type_selected} onChange={(e) => this.setState({ type_selected: e.target.value })} className="text-xs">
                            <option value=''>{`All (${this.props.expired_accounts.length})`}</option>
                            <option value='Expiring Today' className="fw-bold">{`Expiring Today (${this.props.expired_accounts.filter(s => s.short_type_of === 'P' && isToday(new Date(s.expiry_date))).length})`}</option>
                            <option value='Customer'>{`Customer (${this.props.expired_accounts.filter(s => s.type_of === 'Customer').length})`}</option>
                            <option value='Dormant'>{`Dormant (${this.props.expired_accounts.filter(s => s.type_of === 'Dormant').length})`}</option>
                            <option value='Prospect'>{`Prospect (${this.props.expired_accounts.filter(s => s.type_of === 'Prospect').length})`}</option>
                        </Form.Select>
                    </InputGroup> :
                    null
                }

                {this.props.expired_accounts && this.props.role==='gm' ?
                    <InputGroup className="mb-3">
                        <InputGroup.Text className="text-xs">LAM</InputGroup.Text>
                        <Form.Select value={lam_selected} onChange={(e) => this.setState({ lam_selected: e.target.value })} className="text-xs">
                            <option value=''>Select</option>
                            {lam_list.map(d =>
                                <option value={d.salesperson_id}>{ d.salesperson_name}</option>
                            )}
                        </Form.Select>
                    </InputGroup> :
                    null
                }
              
                {expired_accounts_table}
            </div>
        );
    }
}
