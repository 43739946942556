import '../.././App.scss';
import * as svg_icons from '../../Media/svg_exports';

/**
 * TableHeader
 * @param {function} onSort Sorts the column
 * @param {object} table_header Contains {column_title, sort_direction, is_sortable, header_style}
 * @returns
 */
function TableHeader({ ...props }) {
    if (props.table_header) {

        return (
            <th
                scope="col"
                onClick={() => props.onSort()}
                className={'th ' + props.header_style}
                style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}
            >
                {props.table_header.column_title ? (
                    props.sorted_by === 'N' ? (
                        <bdi>{props.table_header.column_title}</bdi>
                    ) : (
                        <div className="d-flex flex-row  justify-content-start">
                                <bdi className="align-self-end p-0">{props.table_header.column_title}</bdi>
                                <label
                                    style={{transform:'scale(0.65)'}}
                                    className="hover-over text-dark align-self-end p-0">{props.table_header.sort_direction === 'H' ? svg_icons.down_chevron : svg_icons.up_chevron}</label>
                         
                        </div>
                    )
                ) : null}
            </th>
        );
    } else {
        return null;
    }
}

export default TableHeader;
