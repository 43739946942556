import { Alert } from 'react-bootstrap';


function NoDataAlert({ ...props }) {
    return (
        <Alert variant='secondary' className="d-flex flex-row w-100 justify-content-center mt-3">
            <h6 className="m-0 p-0 text-xs text-bold text-secondary">{ props.title}</h6>
            
        </Alert>
    );
}

export default NoDataAlert;
