import { Button } from 'react-bootstrap';
import { getYear } from 'date-fns';

function PageFooter({ ...props }) {
    return (
        <div className="footer-div d-flex flex-column justify-content-center w-100 align-self-end">
            <div className="d-flex flex-row justify-content-center shadow p-2">
                <label className="align-self-center me-3 text-xs">Having an issue?</label>
                <Button
                    onClick={() => props.setShowReportIssue()}
                    variant="outline-light"
                    size="sm"
                    className="text-xs"
                >
                    Report Issue
                </Button>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <h6 className="w-100 text-center text-xs">
                    &copy; {getYear(new Date())} Scotlynn Group. All Rights Reserved
                </h6>
            </div>
        </div>
    );
}

export default PageFooter;
