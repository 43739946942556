import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
    const idleTimeout = 3600000 * idleTime;
    const [isIdle, setIdle] = useState(false);
    const { instance, accounts } = useMsal();

    const handleIdle = () => {
        setIdle(true);
        instance.logoutRedirect().catch((e) => {
            localStorage.clear();
            console.error(e);
        });
    };

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptBeforeIdle: true,
        onPrompt: onIdle,
        onIdle: handleIdle,
        debounce: 500,
    });
    return {
        isIdle,
        setIdle,
        idleTimer,
    };
};
export default useIdleTimeout;
