import {  Button, ButtonGroup, Form } from 'react-bootstrap';

export function ProspectActivityInput({ ...props }) {
    const check_pa_dates = () => {
        const start_date = props.pa_start_date;
        const end_date = props.pa_end_date;
        if (
            start_date.day !== '' &&
            start_date.month !== '' &&
            start_date.year !== '' &&
            end_date.day !== '' &&
            end_date.month !== '' &&
            end_date.year !== ''
        ) {
            return false;
        } else {
            return true;
        }
    };

    const check_start_date = () => {
        const start_date = props.pa_start_date;
        if (start_date.day !== '' && start_date.month !== '' && start_date.year !== '') {
            return false;
        } else {
            return true;
        }
    };

    const quarterly = (
        <div className="d-flex flex-row justify-content-center mb-2 w-100">
            <div className="d-flex flex-column justify-content-center p-1 border me-3 rounded w-50">
                <small className="text-xs opacity-75">Quarter</small>
                <Form.Select
                    className="outline-0 border-0 text-sm shadow-0"
                    name="pa_quarterly"
                    onChange={(e) => props.on_update_quarterly(e)}
                    value={props.pa_start_date.month}
                >
                    <option value="">Select Quarter</option>
                    <option value="01">Q1</option>
                    <option value="04">Q2</option>
                    <option value="07">Q3</option>
                    <option value="10">Q4</option>
                </Form.Select>
            </div>

            <div className="d-flex flex-column justify-content-center p-1 border rounded w-50">
                <small className="text-xs opacity-75">Year</small>
                <Form.Select
                    className="outline-0 border-0 text-sm shadow-0 "
                    name="pa_year"
                    onChange={(e) => props.on_update_quarterly(e)}
                    value={props.pa_start_date.year}
                >
                    <option value="">Select Year</option>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                </Form.Select>
            </div>
        </div>
    );

    const monthly = (
        <div className="d-flex flex-row justify-content-center mb-2 w-100">
            <div className="d-flex flex-column justify-content-center p-1 border me-3 rounded w-50">
                <small className="text-xs opacity-75">Month</small>
                <Form.Select
                    className="outline-0 border-0 text-sm shadow-0"
                    value={props.pa_start_date.month}
                    name="pa_monthly"
                    onChange={(e) => props.on_update_monthly(e)}
                >
                    <option value="">Select Month</option>
                    <option value="01">JAN</option>
                    <option value="02">FEB</option>
                    <option value="03">MAR</option>
                    <option value="04">APR</option>
                    <option value="05">MAY</option>
                    <option value="06">JUN</option>
                    <option value="07">JUL</option>
                    <option value="08">AUG</option>
                    <option value="09">SEP</option>
                    <option value="10">OCT</option>
                    <option value="11">NOV</option>
                    <option value="12">DEC</option>
                </Form.Select>
            </div>

            <div className="d-flex flex-column justify-content-center p-1 border rounded w-50">
                <small className="text-xs opacity-75">Year</small>
                <Form.Select
                    className="outline-0 border-0 text-sm shadow-0 "
                    value={props.pa_start_date.year}
                    name="pa_year"
                    onChange={(e) => props.on_update_monthly(e)}
                >
                    <option value="">Select Year</option>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                </Form.Select>
            </div>
        </div>
    );

    const custom = (
        <div className="d-flex flex-row justify-content-center mb-2 w-100">
            <div className="d-flex flex-column justify-content-center p-1 border me-3 rounded w-50">
                <small className="text-xs opacity-75">Start Date</small>
                <Form.Control
                    className="outline-0 border-0 text-sm shadow-0"
                    type="date"
                    name="start"
                    value={props.pa_start_date.year + '-' + props.pa_start_date.month + '-' + props.pa_start_date.day}
                    onChange={(e) => props.on_update_custom(e)}
                />
            </div>

            <div
                className="d-flex flex-column justify-content-center p-1 border rounded w-50"
                title={check_start_date ? 'Enter start date first' : ''}
            >
                <small className="text-xs opacity-75">End Date</small>
                <Form.Control
                    className="outline-0 border-0 text-sm shadow-0"
                    disable={check_start_date}
                    name="end"
                    value={props.pa_end_date.year + '-' + props.pa_end_date.month + '-' + props.pa_end_date.day}
                    onChange={(e) => props.on_update_custom(e)}
                    type="date"
                />
            </div>
        </div>
    );

    return (
        <div className=" d-flex flex-column justify-content-center px-2">
            <ButtonGroup className="shadow-0 mb-2">
                <Button
                    className="btn-op70 text-sm"
                    size="sm"
                    variant={props.pa_date_type === 'quarter' ? 'secondary' : 'outline-secondary'}
                    onClick={() => props.update_pa_type('quarter')}
                >
                    Quarterly
                </Button>
                <Button
                    className="btn-op70 text-sm"
                    size="sm"
                    variant={props.pa_date_type === 'month' ? 'secondary' : 'outline-secondary'}
                    onClick={() => props.update_pa_type('month')}
                >
                    Monthly
                </Button>
                <Button
                    className="btn-op70 text-sm"
                    size="sm"
                    variant={props.pa_date_type === 'custom' ? 'secondary' : 'outline-secondary'}
                    onClick={() => props.update_pa_type('custom')}
                >
                    Custom
                </Button>
            </ButtonGroup>

            {props.pa_date_type === 'quarter' ? quarterly : null}
            {props.pa_date_type === 'month' ? monthly : null}
            {props.pa_date_type === 'custom' ? custom : null}

            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="success"
                    className="btn-op70 my-2 text-sm"
                    size="sm"
                    onClick={() => props.onSave()}
                    disabled={check_pa_dates()}
                >
                    Generate Prospect Activity Report
                </Button>
            </div>
        </div>
    );
}
