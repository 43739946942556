import React, { Component } from 'react';
import { Container, Row, Col, Card, Alert, Form, InputGroup, Button, FormCheck, ButtonGroup } from 'react-bootstrap';

export class OrdersSearch extends Component {
    static displayName = OrdersSearch.name;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: '',
            sort_field: '',
            edit_field: '',
            search_filter: {
                input: '',
                ship_date: '',
                delivery_date: '',
                margin: null,
                claim: '',
                paid: '',
                exempt: '',
                carrier_expence: null,
                sold_to_assets: null,
                adj_after_paid: null,
                adj_date: '',
                adj_description: '',
                slusa_checked: true,
                slc_checked: true,
            },

            // Searchable Fields
            search_company_id: '',
            search_order_id: '',
            search_shipped_start: '',
            search_shipped_end: '',
            search_delivery_start: '',
            search_delivery_end: '',
            search_customer_id: '',
            search_salesperson_id: '',
            search_margin_low: 0,
            search_margin_high: 1,
            search_commission_low: 0,
            search_commission_high: 1,
        };
    }

    reset_filter() {
        this.setState({
            search_filter: {
                input: '',
                ship_date: '',
                delivery_date: '',
                margin: null,
                claim: '',
                paid: '',
                exempt: '',
                carrier_expence: null,
                sold_to_assets: null,
                adj_after_paid: null,
                adj_date: '',
                adj_description: '',
                company_id: '',
                slusa_checked: true,
                slc_checked: true,
            },
            search_company_id: '',
            search_order_id: '',
            search_shipped_start: '',
            search_shipped_end: '',
            search_delivery_start: '',
            search_delivery_end: '',
            search_customer_id: '',
            search_salesperson_id: '',
            search_margin_low: 0,
            search_margin_high: 1,
            search_commission_low: 0,
            search_commission_high: 1,
        });
    }

    update_search_filter(e) {
        const current_filter = this.state.search_filter;
        current_filter[e.target.name] = e.target.value;
        this.setState({ search_filter: current_filter });
    }

    update_checkbox(field) {
        const current_filter = this.state.search_filter;
        current_filter[field] = !this.state.search_filter[field];
        this.setState({ search_filter: current_filter });
    }

    render() {
        const {
            sort_direction,
            sort_field,
            edit_field,
            search_filter,
            search_company_id,
            search_order_id,
            search_shipped_start,
            search_shipped_end,
            search_delivery_start,
            search_delivery_end,
            search_customer_id,
            search_salesperson_id,
            search_margin_low,
            search_margin_high,
            search_commission_low,
            search_commission_high,
        } = this.state;

        const search_bar = (
            <div className=" mb-3 d-flex flex-row p-1">
                <Form.Control
                    className="border outline-0 text-sm p-1 rounded"
                    placeholder="Search"
                    onChange={(e) => this.update_search_filter(e)}
                    name="input"
                    value={search_filter.input}
                />
            </div>
        );

        const order_id_search = (
            <div className="d-flex flex-column p-1 w-25 border w-100 rounded mb-3">
                <small className="text-xs opacity-75">Order ID</small>
                <Form.Control
                    type="text"
                    className="p-0 ps-1 border-0 outline-0 text-sm w-100 shadow-0"
                    name="search_order_id"
                    value={search_order_id}
                    onChange={(e) => this.setState({ search_order_id: e.target.value })}
                />
            </div>
        );

        const customer_id_search = (
            <div className="d-flex flex-column p-1 w-25 border w-100 rounded mb-3">
                <small className="text-xs opacity-75">Customer ID</small>
                <Form.Control
                    type="text"
                    className="p-0 ps-1 border-0 outline-0 text-sm w-100 shadow-0"
                    name="search_customer_id"
                    value={search_customer_id}
                    onChange={(e) => this.setState({ search_customer_id: e.target.value })}
                />
            </div>
        );

        const checkboxes = (
            <div className="d-flex flex-row justify-content-between mb-3">
                <div className="d-flex flex-column p-1 text-sm">
                    <FormCheck
                        label="SLUSA"
                        onClick={() => this.update_checkbox('slusa_checked')}
                        checked={search_filter.slusa_checked}
                    />
                    <FormCheck
                        label="SLC"
                        onClick={() => this.update_checkbox('slc_checked')}
                        checked={search_filter.slc_checked}
                    />
                </div>
                <div className="d-flex flex-column p-1 text-sm">
                    <FormCheck label="Not Paid" />
                    <FormCheck label="Paid" />
                </div>
            </div>
        );

        const date_field = (label, field) => (
            <div className="d-flex flex-column p-1 w-25 border w-100 rounded mb-3">
                <small className="text-xs opacity-75">{label}</small>
                <Form.Control
                    type="date"
                    className="p-0 ps-1 border-0 outline-0 text-sm w-100 shadow-0"
                    name={field}
                />
            </div>
        );

        const ship_date = (
            <div className="d-flex flex-column p-1 w-25 border w-100 rounded mb-3">
                <small className="text-xs opacity-75">Ship Date</small>
                <Form.Control type="date" className="p-0 ps-1 border-0 outline-0 text-sm w-100 shadow-0" />
            </div>
        );

        const delivery_date = (
            <div className="d-flex flex-column p-1 w-25 border w-100 rounded mb-3">
                <small className="text-sm opacity-75">Delivery Date</small>
                <Form.Control type="date" className="p-1 border-0 outline-0 text-sm w-100 shadow-0" />
            </div>
        );

        //#region Search Container
        const search_container = (
            <div className="d-flex flex-column" style={{ width: '12rem', overflow: 'auto' }}>
                {order_id_search}
                {customer_id_search}
                {checkboxes}
                {ship_date}
                {delivery_date}
            </div>
        );

        //#endregion Search Container

        return search_container;
    }

    //#region Search Functions
    async Get_By_Order_ID(company_id, order_id) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(`Commission/GetOrderCommissionsOrder/${company_id}/${order_id}`, options);
            const data = await response.json();
            return data;
        } catch (e) {
            console.log(e);
            return e;
        }
    }

    async Get_By_Shipped(company_id, start_date, end_date) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsShipped/${company_id}/${start_date}/${end_date}`,
                options,
            );
            const data = await response.json();
            return data;
        } catch (e) {
            console.log(e);
            return e;
        }
    }

    async Get_By_Delivery(company_id, start_date, end_date) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsDelivery/${company_id}/${start_date}/${end_date}`,
                options,
            );
            const data = await response.json();
            return data;
        } catch (e) {
            console.log(e);
            return e;
        }
    }

    async Get_By_Customer(company_id, customer_id) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsCustomer/${company_id}/${customer_id}`,
                options,
            );
            const data = await response.json();
            return data;
        } catch (e) {
            console.log(e);
            return e;
        }
    }

    async Get_By_Salesperson(company_id, salesperson_id) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsSalesperson/${company_id}/${salesperson_id}`,
                options,
            );
            const data = await response.json();
            return data;
        } catch (e) {
            console.log(e);
            return e;
        }
    }

    async Get_By_Margin(company_id, low, high) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(`Commission/GetOrderCommissionsMargin/${company_id}/${low}/${high}`, options);
            const data = await response.json();
            return data;
        } catch (e) {
            console.log(e);
            return e;
        }
    }

    async Get_By_Commission(company_id, low, high) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsCommission/${company_id}/${low}/${high}`,
                options,
            );
            const data = await response.json();
            return data;
        } catch (e) {
            console.log(e);
            return e;
        }
    }

    //#endregion Search Functions
}
