import React, { Component } from 'react';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import { isValid } from 'date-fns';
import SortableTable from './SortableTable';
export class ManagerAssignmentTable extends Component {
    static displayName = ManagerAssignmentTable.name;
    constructor(props) {
        super(props);
        this.state = {
            status:'active'
        };

    }

    get_headers() {

        const headers_list = [
            'Company ID',
            'General Manager',
            'Team ID',
            'Extension Requests Recipient',
            'Conversion Requests Recipient',
            'Effective Date',
            'Expire Date'
        ]
        let headers=[]
        headers_list.map(s => headers.push({
            column_title: s,
            sort_direction: this.props.general_manager_asc ?'H':'L',
            is_sortable: 'Y',
            header_style: 'px-0 py-1 text-med text-nowrap opacity-75'
        }))
        return headers;
    }

    check_is_active(expire_date) {
        if (expire_date) {
            if (new Date(expire_date) > Date.now()) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    get_cells() {
        let data = [];
        let list = [];
        const general_manager_assignments = this.props.general_manager_assignments? this.sort_data():[];
        if (this.props.general_manager_assignments) {
            if (this.state.status === 'expired') {
                
                list = general_manager_assignments.filter(s => !this.check_is_active(s.expire_date))
            } else {
                list = general_manager_assignments.filter(s => this.check_is_active(s.expire_date))
            }
        }
        const cell_styling = 'p-0 text-med text-nowrap';
        list.map(d => data.push({
            row_style: d.expire_date ? new Date(d.expire_date) < Date.now()?'opacity-50':'' : '',
            cells: [
                { cell: d.company_id, cell_style: cell_styling },
                { cell: d.sales_manager_name, cell_style: cell_styling },
                { cell: d.team_id, cell_style: cell_styling },
                { cell: d.extension_manager_id !== d.initial_extension_manager_id ? `${d.initial_extension_manager_name} -> ${d.extension_manager_name}` : d.extension_manager_name, cell_style: d.extension_manager_id !== d.initial_extension_manager_id ? `${cell_styling} bg-warning` : cell_styling },
                { cell: d.conversion_manager_id !== d.sales_manager_id ? `${d.sales_manager_name} -> ${d.conversion_manager_name}` : d.conversion_manager_name, cell_style: d.conversion_manager_id !== d.sales_manager_id ? `${cell_styling} bg-warning` : cell_styling },
                { cell: d.effective_date, cell_style: cell_styling },
                { cell: d.expire_date, cell_style: cell_styling }
            ],
            data: d
        }))
        return data;
    }


    sort_data() {
        const general_manager_assignments = this.props.general_manager_assignments ? this.props.general_manager_assignments : [];
        const sort_property = this.props.general_manager_sort;
        const sort_direction = this.props.general_manager_asc;
        if (sort_property === 'Company ID') {
            return general_manager_assignments.sort((a, b) => sort_direction ? a.company_id.localeCompare(b.company_id) : b.company_id.localeCompare(a.company_id))
        } else if (sort_property === 'General Manager') {
            return general_manager_assignments.sort((a, b) => sort_direction ? a.sales_manager_name.localeCompare(b.sales_manager_name) : b.sales_manager_name.localeCompare(a.sales_manager_name))
        } else if (sort_property === 'Team ID') {
            return general_manager_assignments.sort((a, b) => sort_direction ? a.team_id.localeCompare(b.team_id) : b.team_id.localeCompare(a.team_id))
        } else if (sort_property === 'Extension Requests Recipient') {
            return general_manager_assignments.sort((a, b) => sort_direction ? a.extension_manager_name.localeCompare(b.extension_manager_name) : b.extension_manager_name.localeCompare(a.extension_manager_name))
        } else if (sort_property === 'Conversion Requests Recipient') {
            return general_manager_assignments.sort((a, b) => sort_direction ? a.conversion_manager_name.localeCompare(b.conversion_manager_name) : b.conversion_manager_name.localeCompare(a.conversion_manager_name))
        } else if (sort_property === 'Effective Date') {
            return general_manager_assignments.sort((a, b) => sort_direction ? new Date(a.effective_date) - new Date(b.effective_date) : new Date(b.effective_date) - new Date(a.effective_date))
        } else if (sort_property === 'Expire Date') {          
            return general_manager_assignments.sort((a, b) => sort_direction ? new Date(a.expire_date) - new Date(b.expire_date) : new Date(b.expire_date) - new Date(a.expire_date))
        } else {
            return general_manager_assignments;
        }
    }



    render() {
        const {  status } = this.state;
        const table = <SortableTable
            onSort={(prop) => this.props.update_sort(prop)}
            row_click={(prop) => this.props.set_manager_assignment(JSON.parse(prop))}
            table_headers={this.get_headers()}
            table_data={this.get_cells()}
            table_title=""
            sort_field={this.props.general_manager_sort}
        />
        return <div className="d-flex flex-column justify-content-start">
            <ButtonGroup className="opacity-75 mb-2">
                <Button className="text-med p-1" size="sm" variant={status === 'active' ? 'secondary' : 'outline-secondary'} onClick={() => this.setState({status:'active'})}>Active</Button>
                <Button className="text-med p-1" size="sm" variant={status === 'expired' ? 'secondary' : 'outline-secondary'} onClick={() => this.setState({ status: 'expired' })}>Expired</Button>
            </ButtonGroup>
            {table}
        </div>;
    }
}
