import React, { Component } from 'react';
import { MsalContext } from '@azure/msal-react';
import { Button, Form, InputGroup} from 'react-bootstrap';
import * as svg_icons from '../../Media/svg_exports';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as prospectActionFunctions from '../Functions/ProspectActionFunctions';
import * as requestFunctions from '../Functions/RequestFunctions';
import { format, addDays } from 'date-fns';
import { AccountSummary } from './ActionComponents/AccountSummary'
import { ActivityHistoryTable } from './ActionComponents/ActivityHistoryTable'
import { PrecheckButton } from './ActionComponents/PrecheckButton'
import { SendpackButton } from './ActionComponents/SendpackButton'
import { PrecheckModal } from './ActionComponents/PrecheckModal'
import { SendpackModal } from './ActionComponents/SendpackModal';
import { ExtensionModal } from './ActionComponents/ExtensionModal'
import { ConversionModal } from './ActionComponents/ConversionModal'
import { ReleaseModal } from './ActionComponents/ReleaseModal'
export class ProspectActionsTab extends Component {
    static displayName = ProspectActionsTab.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        
        this.state = {
            sort_field: 'Completed',
            sort_direction: 'H',
            new_action:null,
            current_action:null,
            clicked:false,
            message: '',
            activity_status_type: 'All',
            show_precheck: false,
            show_sendpack: false,
            show_extension: false,
            show_conversion:false,
            selected_request: null,
            additional_remarks: ''
           
        };
        
    }
    componentDidUpdate() {
        if (!this.state.new_action) {
            this.reset_new_action();
        }
       
    }

    reset_new_action() {
        const selected_account_data = this.props.selected_account_data
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const current_user = this.props.current_user;
        this.setState({
            new_action: {
                company_id: selected_account ? selected_account.company_id : '',
                activity_id: '',
                salesperson_id:current_user ?current_user.salesperson_id : '',
                remarks: '',
                date_due: '',
                customer_id: selected_account ? selected_account.id : '',
                status: 'C' 
            },
            current_action: null,
            clicked: false,
            message: '',
            show_precheck: false,
            show_sendpack: false,
            show_extension: false,
            show_conversion: false,
            selected_request: null,
            additional_remarks: ''

        })
    }

    componentDidMount() {
        this.reset_new_action()
    }



    update_new_action(property, value) {
        let new_action = this.state.new_action;
        new_action[property] = value;
        this.setState({ new_action: new_action })
    }

    update_current_action(property, value) {
        let current_action = this.state.current_action;
        current_action[property] = value;
        this.setState({ current_action: current_action })
    }

    async post_action(activity_id) {
        this.setState({ clicked: true, message: 'Adding activity...' });
        const selected_account_data = this.props.selected_account_data;
        let prospect_actions = selected_account_data ? selected_account_data.prospect_actions ? selected_account_data.prospect_actions : [] : [];
        try {
            let new_activity = this.state.new_action;
            if (this.state.activity_status_type === 'Future') {
                new_activity.status = 'P';
            } else {
                new_activity.status = 'C';
            }
            if (new_activity) {
                new_activity.activity_id = activity_id;
                const action_data = await prospectActionFunctions.Post(new_activity);
                this.props.add_users_action(action_data);
                prospect_actions.push(action_data)
                this.props.update_selected_account_data('prospect_actions', prospect_actions)
                this.reset_new_action();
            }
        } catch (e) {
            console.log(e)
            this.setState({ clicked: false, message: 'Failed to add activity' });
        }
    }

    async put_action() {
        this.setState({ clicked: true, message: 'Saving activity...' });
        const selected_account_data = this.props.selected_account_data;
        let prospect_actions = selected_account_data ? selected_account_data.prospect_actions ? selected_account_data.prospect_actions : [] : [];
        try {
            const action_data = prospectActionFunctions.Update(this.state.current_action)
            this.props.update_users_action(action_data);
            let find_action = prospect_actions.find(s => s.id === action_data.id);
            let index = prospect_actions.indexOf(find_action);
            prospect_actions[index] = action_data;
            this.props.update_selected_account_data('prospect_actions', prospect_actions)
        } catch (e) {
            console.log(e)
            this.setState({ clicked: false, message: 'Failed to update activity' });
        }
    }

    check_options(action) {
        const options_button = <Button
            size="sm"
            className="bg-transparent"
            variant="light"
            onClick={() => this.setState({current_action:action})}
        >
            {svg_icons.edit_icon}
        </Button>;

        const cancel_button = <Button
            size="sm"
            className="opacity-75 me-3 bg-transparent text-secondary"
            title="Cancel"
            variant="secondary"
            onClick={() => this.setState({ current_action: null })}
        >
            {svg_icons.cancel_icon}
        </Button>

        const save_button = <Button
            size="sm"
            className="opacity-75 "
            variant="success"
            title="Save"
            onClick={() => this.put_action()}
        >
            {svg_icons.save_icon}
        </Button>

        const save_cancel_div =
            <div className="d-flex flex-row justify-content-end pt-3">
                {cancel_button}
                {save_button}
            </div>
        if (this.is_users_account()) {
            if (this.state.current_action) {
                if (this.state.current_action.id === action.id) {
                    return save_cancel_div;
                } else {
                    return options_button;
                }
            } else {
                return options_button;
            }
        }
    }

    is_users_account() {
        const current_user = this.props.current_user;
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;

        if (current_user && selected_account) {
            if (current_user.salesperson_id && selected_account.salesperson_id) {
                if (current_user.salesperson_id.toUpperCase() == selected_account.salesperson_id.toUpperCase()) {
                    return true;
                } else if (this.props.role === 'admin' || this.props.role === 'credit') {
                    return true;
                } else {
                    return false;
                }
            }      
        }
    }

    new_action_form() {
        const new_action = this.state.new_action;
        const clicked = this.state.clicked
        const current_user = this.props.current_user;
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
 
        const action_form =(action_type)=> new_action?
            <div className="d-flex flex-column flex-grow-1 justify-content-between h-50 my-2">
                <div className="d-flex flex-column flex-grow-1">
                    <h6 className="portal-tile-title">{`Add ${action_type}`}</h6>

                    <InputGroup className="d-flex flex-row justify-content-between mb-0">
                        <Form.Control
                            value={new_action.remarks}
                            placeholder="Enter remark here..."
                            disabled={clicked}
                            onChange={(e) => this.update_new_action('remarks',e.target.value)}
                            as="textarea"
                            name="remarks"
                            className="text-sm bg-white text-start flex-grow-1 w-75"
                        />
                        <Button
                            className="text-sm flex-grow-3"
                            size="sm" variant={clicked ? 'outline-success' : 'success'}
                            disabled={clicked || !new_action.remarks}
                            onClick={() => this.post_action(action_type)}>
                            {clicked?'Adding...':'Add'}
                        </Button>
                    </InputGroup>

                </div>

            </div> : null;

        if (current_user && selected_account) {
            if (selected_account.salesperson_id) {
                if (selected_account.salesperson_id.toUpperCase() === "UNCLAIMD") {
                    return action_form('REINTRO')
                }
                else if (this.is_users_account()) {
                    return action_form('FOLLOWUP')
                } else {
                    return null
                }
            } else {
                return action_form('REINTRO')
            }
        }
    }

    get_activity_status_options() {
        const activity_status_type = this.state.activity_status_type;
        if (this.is_users_account()) {
            return (
                <div className="d-flex flex-row justify-content-between mb-2" style={{ width: '10rem' }}>
                    <Button
                        className="text-sm"
                        style={{ width: '4.5rem' }}
                        variant={activity_status_type === 'All' ? 'secondary' : 'outline-secondary'} size="sm" onClick={() => this.setState({ activity_status_type: 'All' })}>All</Button>
                    <Button className="text-sm" style={{ width: '4.5rem' }} variant={activity_status_type === 'Future' ? 'secondary' : 'outline-secondary'} size="sm" onClick={() => this.setState({ activity_status_type: 'Future' })}>Future</Button>
                </div>
            )
        } else {
            return null;
        }
    }

    get_precheck_sendpack_buttons() {
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const requests = selected_account_data ? selected_account_data.requests ? selected_account_data.requests : [] : [];
        const prospect_actions = selected_account_data ? selected_account_data.prospect_actions ? selected_account_data.prospect_actions : [] : [];

        if (this.is_users_account() && selected_account) {
            return (
                <div className="d-flex flex-row justify-content-end">
                    <SendpackButton
                        selected_account={selected_account}
                        requests={requests}
                        prospect_actions={prospect_actions}
                        onClick={() => this.setState({ show_sendpack: true })}
                    />            
                    <PrecheckButton
                        selected_account={selected_account}
                        requests={requests}
                        prospect_actions={prospect_actions}
                        onClick={() => this.setState({ show_precheck: true })}
                    />

                </div>
            )
        } else {
            return null;
        }
    }



    get_extension_button(current_user, requests) {
        if (current_user && requests) {
            if (requests.find(s => s.status === 'P' && s.request_type === 'EREQUEST' && s.requestor_id.toUpperCase() === current_user.salesperson_id.toUpperCase())) {
                const pending_request = requests.find(s => s.status === 'P' && s.request_type === 'EREQUEST' && s.requestor_id.toUpperCase() === current_user.salesperson_id.toUpperCase());
                return (<Button
                    className={`text-sm d-flex flex-row justify-content-center p-1 ms-3`}
                    onClick={() => this.setState({ show_extension: true })}
                    size="sm"
                    variant="outline-success"
                    title={`Request pending since: ${pending_request.date_requested_str}`}
                    style={{ width: '10rem' }}>
                    <bdi className="align-self-center ">Extension Pending</bdi>
                </Button>)
            }
            else if (requests.find(s => s.status === 'C' && s.request_type === 'EREQUEST' && s.requestor_id.toUpperCase() === current_user.salesperson_id.toUpperCase())) {
                return (<Button
                    className={`text-sm d-flex flex-row justify-content-center p-1 ms-3`}
                    onClick={() => this.setState({ show_extension: true })}
                    size="sm"
                    variant="success"
                    title="Submit 2nd extension request"
                    style={{ width: '10rem' }}>
                    <bdi className="align-self-center ">
                        Request 2nd Extension
                    </bdi>
                    
                </Button>)
            } else {
                return (<Button
                    className={`text-sm d-flex flex-row justify-content-center p-1 ms-3`}
                    onClick={() => this.setState({ show_extension: true })}
                    size="sm"
                    variant="success"
                    title="Submit extension request"
                    style={{ width: '10rem' }}>
                    <bdi className="align-self-center ">Request Extension</bdi>
                </Button>)
            }
        }
    }

    get_conversion_button(current_user, requests) {
        if (current_user && requests) {
            if (requests.find(s => s.status === 'P' && s.request_type === 'CREQUEST' && s.requestor_id.toUpperCase() === current_user.salesperson_id.toUpperCase())) {
                const pending_request = requests.find(s => s.status === 'P' && s.request_type === 'CREQUEST' && s.requestor_id.toUpperCase() === current_user.salesperson_id.toUpperCase());
                return (<Button
                    className={`text-sm d-flex flex-row justify-content-center p-1`}
                    onClick={() => this.setState({ show_conversion: true })}
                    size="sm"
                    variant="outline-success"
                    title={`Request pending since: ${pending_request.date_requested_str}`}
                    style={{ width: '10rem' }}>
                    <bdi className="align-self-center ">Conversion Pending</bdi>
                </Button>)
            }
            else {
                return (<Button
                    className={`text-sm d-flex flex-row justify-content-center p-1`}
                    onClick={() => this.setState({ show_conversion: true })}
                    size="sm"
                    variant="success"
                    title="Submit conversion request"
                    style={{ width: '10rem' }}>
                    <bdi className="align-self-center ">Request Conversion</bdi>
                </Button>)
            }
        }
    }

    render() {
        const {
            activity_status_type,
            show_precheck,
            show_sendpack,
            show_extension,
            show_conversion,
            selected_request,
            show_release
        } = this.state;
        const current_user = this.props.current_user;
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const requests = selected_account_data ? selected_account_data.requests ? selected_account_data.requests : [] : [];
        const prospect_actions = selected_account_data ? selected_account_data.prospect_actions ? selected_account_data.prospect_actions : [] : [];
        const contacts = selected_account_data ? selected_account_data.contacts ? selected_account_data.contacts : [] : [];

        return (
            <div className="d-flex flex-column px-2 w-100 pb-5" >
      
                {this.is_users_account() && selected_account ?
                    <div>                       
                        <ExtensionModal
                            show={show_extension}
                            onHide={() => this.setState({ show_extension: false })}
                            current_user={current_user}
                            selected_account={selected_account}
                            requests={requests}
                            update_selected_account_data={(property, prop) => this.props.update_selected_account_data(property, prop)}
                            add_users_request={(type, prop) => this.props.add_users_request(type,prop)}
                        />                      
                        <SendpackModal
                            show={show_sendpack}
                            onHide={() => this.setState({ show_sendpack: false })}
                            current_user={current_user}
                            selected_account={selected_account}
                            prospect_actions={prospect_actions}
                            contacts={contacts}
                            add_users_action={(prop) => this.props.add_users_action(prop)}
                            update_selected_account_data={(property, prop) => this.props.update_selected_account_data(property, prop)}
                        />

                        <ReleaseModal
                            show={show_release}
                            onHide={() => this.setState({ show_release: false })}
                            release_account={(prop) => this.props.release_account(prop)}
                            current_user={current_user}
                            selected_account={selected_account}
                        />
                        <AccountSummary
                            selected_account={selected_account}
                            requests={requests}
                            prospect_actions={prospect_actions}

                        />
                    </div> :
                null
                }
                {this.is_users_account() && selected_account ?
                    <div>
                        <PrecheckModal
                            show={show_precheck}
                            onHide={() => this.setState({ show_precheck: false })}
                            current_user={current_user}
                            selected_account={selected_account}
                            requests={requests}
                            update_selected_account_data={(property, prop) => this.props.update_selected_account_data(property, prop)}
                            add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                        />
                        <ConversionModal
                            show={show_conversion}
                            onHide={() => this.setState({ show_conversion: false })}
                            current_user={current_user}
                            selected_account={selected_account}
                            requests={requests}
                            update_selected_account_data={(property, prop) => this.props.update_selected_account_data(property,prop)}
                            add_users_request={(type,prop) => this.props.add_users_request(type, prop)}
                            salesperson_list={this.props.salesperson_list ? this.props.salesperson_list.filter(s => s.company_id === selected_account.company_id):[]}
                        />
                    </div> :
                    null
            }
             

                <h6 className="text-muted tile-title">Activity History</h6>
                <hr className="mt-0" />
                <div className="d-flex flex-column justify-content-start w-100" >
                    <div className="d-flex flex-row justify-content-between">
                        {this.get_activity_status_options()}
                        {this.get_precheck_sendpack_buttons()}
                    </div>
                   
                    {this.new_action_form()}
                    {this.is_users_account() ?
                        <div className="my-2 d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row">
                          
                                {this.get_conversion_button(current_user, requests)}
                                {this.get_extension_button(current_user, requests)}
                                
                            </div>
                           
                            <Button
                                className={`text-sm d-flex flex-row justify-content-center p-1`}
                                onClick={() => this.setState({ show_release: true })}
                                size="sm"
                                variant="danger"
                                title="Release Account"
                                style={{ width: '10rem' }}>
                                <bdi className="align-self-center">Release</bdi>
                            </Button>
                        </div> :
                    null
                    }
                        <ActivityHistoryTable
                        selected_account={selected_account}
                        role={this.props.role}
                        current_user={current_user}
                        msal_account={this.props.msal_account}
                        requests={requests}
                        prospect_actions={prospect_actions}
                        activity_status_type={activity_status_type}
                        selected_request={selected_request}
                        update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                        update_selected_request={(prop) => this.setState({ selected_request: !selected_request ? prop : selected_request.id === prop.id ? null : prop })}
                        update_selected_account_data={(property, type) => this.props.update_selected_account_data(property, type)}
                        />
                
                
                </div>
            
          
            </div>
        );
    }
}
