
import { MsalContext } from '@azure/msal-react';
import React, { Component } from 'react';
import { Alert, Button, Col, Container, Row, ListGroup, InputGroup,Form } from 'react-bootstrap';
import * as dataService from '../../Dataservice/Endpoints';
import * as svg_icons from '../../Media/svg_exports';
import * as commentFunctions from '../Functions/CommentFunctions';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import sortByStrategy from '../../Domain/sorting-strategy';
import { differenceInDays } from 'date-fns';
import { CreditApplicationUpload } from '../Inputs/CreditApplicationUpload';
import { AttachmentInput } from '../Inputs/AttachmentInput';

export class CreditTab extends Component {
    static displayName = CreditTab.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            show_popup: false,
            new_comment:null,
            clicked_attachment:'',
            new_attachment: null,
            current_thumbnail: null,
            current_thumbnail_name: '',
            initial_comment: '',
            show_audit_log: false,
            selected_comment_audit: '',
            sort_field: 'Entered Date',
            sort_direction:'L'
        };

    }

    async add_comment_audit(new_comment, method) {
        const selected_account_data = this.props.selected_account_data;
        try {
            let comment_audit_list = selected_account_data ? selected_account_data.comment_audit ? selected_account_data.comment_audit : [] : [];
            const current_user_id = this.props.msal_account.username.replaceAll('@scotlynn.com', '')
            let new_comment_format = '';
            if (method === 'Updated') {
                new_comment_format = `Modified by ${current_user_id} [${utilityFunctions.format_datetime_from_input(new Date().toISOString())}]: ${new_comment.comments}`
            }
            else if (method === 'Initial') {
                new_comment_format = `[Initial] ${current_user_id} [${utilityFunctions.format_datetime_from_input(new Date().toISOString())}]: ${new_comment.comments}`
            }
            else if (method === 'Deleted') {
                new_comment_format = `Deleted by ${current_user_id} [${utilityFunctions.format_datetime_from_input(new Date().toISOString())}]`
            }
            const body = {
                company_id: new_comment.company_id,
                comment_id: new_comment.id,
                customer_id: new_comment.parent_row_id,
                changed_by_user: current_user_id,
                initial_comment: this.state.initial_comment,
                new_comment: new_comment_format
            }
            const audit_data = await commentFunctions.Insert_Comment_Audit(body);
            comment_audit_list.push(audit_data)
            this.props.update_selected_account_data('comment_audit', comment_audit_list)
            this.setState({ initial_comment: '' })
        } catch (e) {
            console.log(e)
        }
    }

    get_audit_log(d) {
        const selected_account_data = this.props.selected_account_data;
        let comment_audit_list = selected_account_data ? selected_account_data.comment_audit ? selected_account_data.comment_audit : [] : [];
       
        if (comment_audit_list && !this.state.current_comment) {
            if (comment_audit_list.find(s => s.comment_id === d.id)) {
                if (this.state.selected_comment_audit === d.id) {
                    return null
                } else {
                    return (<Button
                        size="sm"
                        title="View historical of this comment"
                        className="btn-op70 bg-transparent"
                        variant="light"
                        onClick={() => this.setState({ show_audit_log: true, selected_comment_audit: d.id })}
                    >
                        {svg_icons.clock}
                    </Button>)
                }

            } else {
                return null;
            }

        } else {
            return null;
        }

    }

    is_users_account() {
        const current_user = this.props.current_user;
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;

        if (current_user && selected_account) {
            if (current_user.salesperson_id && selected_account.salesperson_id) {
                if (current_user.salesperson_id.toUpperCase() == selected_account.salesperson_id.toUpperCase()) {
                    return true;
                } else if (this.props.role === 'admin' || this.props.role === 'credit') {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    get_credit_app(new_comment) {
        const current_user = this.props.current_user;
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const comments = selected_account_data ? selected_account_data.comments ? selected_account_data.comments : [] : [];       

        if (this.is_users_account() && new_comment) {
            return (<CreditApplicationUpload
                selected_account={selected_account}
                new_comment={new_comment}
                current_user={current_user}
                comments={comments}
                update_selected_account_data={(property, prop) => this.props.update_selected_account_data(property, prop)}
                send_email={(prop) => this.email(prop)}
                reset_new_comment={()=>this.reset_new_comment()}
                add_comment_audit={(prop, type) => this.add_comment_audit(prop, type)}
            />);
        } else {
            return null;
        }
    }


    reset_new_comment() {
        const current_user = this.props.current_user;
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        this.setState({
            
            new_comment:
            {
                __type: "comments",
                company_id: selected_account ? selected_account.company_id : '',
                attach_filename: "",
                comment_type_id: this.props.role === 'credit' ? 'INFO' : 'CUSCREDI',
                comments: "",
                entered_date: "",
                entered_date_str: "",
                entered_user_id: current_user ? current_user.user_id : '',
                id: "",
                parent_row_id: selected_account ? selected_account.id : '',
                parent_row_type: "C"

            }
        })
    }

    componentDidMount() {
        this.reset_new_comment()
    }

    componentDidUpdate() {
        if (!this.state.new_comment && this.props.selected_account_data && this.props.current_user) {
            this.reset_new_comment()
        }
    }

    update_new_comment(e) {
        let new_comment = this.state.new_comment;
        new_comment[e.target.name] = e.target.value;
        this.setState({ new_comment: new_comment })
    }

    update_current_comment(e) {
        let current_comment = this.state.current_comment;
        current_comment[e.target.name] = e.target.value;
        this.setState({ current_comment: current_comment })
    }

    async getThumbnail(comment) {
        try {
            this.setState({
                clicked: true,
                clicked_attachment:comment.id,
                current_thumbnail: null,
                show_popup: false,
                current_thumbnail_name: '',
            });
            const fetch_string = dataService.getAttachment(comment.company_id, comment.id);

            const headers = dataService.fileHeader(comment.company_id);
            const options = { method: 'GET', headers: headers };
            await fetch(fetch_string, options)
                .then((res) => {
                    return res.blob();
                })
                .then((data) => {
                    const file = window.URL.createObjectURL(data);
                    this.setState({
                        clicked: false,
                        clicked_attachment: '',
                        current_thumbnail: file,
                        show_popup: true,
                        current_thumbnail_name: comment.attach_filename
                    });
                })
         
        } catch (e) {
            console.log(e);
            this.setState({
                clicked: false,
                current_thumbnail: null,
                clicked_attachment:'',
                show_popup: false,
                current_thumbnail_name: '',
            });
        }
    }

    new_comment_form() {
        const new_comment = this.state.new_comment;
        const clicked = this.state.clicked
        const file_uploading = this.state.file_uploading;

        let button_label = "Add";
        if (clicked) {
            button_label = "Adding..."
        }
        if (file_uploading) {
            button_label = "Checking attachment..."
        } else {
            button_label = "Add";
        }

        const new_comment_form = new_comment?
            <div className="d-flex flex-column flex-grow-1 justify-content-between p-0 mt-2">
                <div className="d-flex flex-column flex-grow-1">
                    <h6 className="text-muted text-sm mt-1 text-bold">Add INFO Comment</h6>

                    <InputGroup className="d-flex flex-row justify-content-between mb-0">

                        <Form.Control
                            value={new_comment.comments ? new_comment.comments : ''}
                            placeholder="Enter new comment here..."
                            disabled={clicked || file_uploading}

                            onChange={(e) => this.update_new_comment(e)}
                            as="textarea"
                            name="comments"
                            className="text-sm bg-white text-start flex-grow-1 w-75"

                        />
                        <Button
                            className="btn-op70 text-sm flex-grow-3"

                            size="sm" variant={clicked ? 'outline-success' : 'success'}
                            disabled={clicked || file_uploading}
                            onClick={() => this.post_info_comment()}>
                            {button_label}
                        </Button>
                    </InputGroup>


                    <AttachmentInput
                        current_comment={new_comment}
                        current_attachment={this.state.new_attachment}
                        update_message={(prop) => this.setState({ message: prop })}
                        update_clicked={(prop) => this.setState({ file_uploading: prop })}
                        update_attachment={(prop) => this.setState({ new_attachment: prop })}
                    />


                </div>

            </div>:null;
        if (this.is_users_account()) {
            return (new_comment_form)
        } else {
            return null;
        }

    }

    async post_info_comment() {
        this.setState({ clicked: true, message: 'Adding comment...' })
        const selected_account_data = this.props.selected_account_data;
        let comments = selected_account_data ? selected_account_data.comments ? selected_account_data.comments : [] : [];
        try {
            const new_attachment = this.state.new_attachment;
            let new_comment = this.state.new_comment;
            new_comment.comment_type_id = 'INFO'
            
            const comment_data = await commentFunctions.Post(new_comment);
            this.add_comment_audit(comment_data, 'Initial');
            let updated_comment = comment_data;
            if (new_attachment) {
                commentFunctions.Upload(comment_data.company_id, comment_data.parent_row_id, comment_data.id, new_attachment);
                updated_comment.attachment = new_attachment;
                updated_comment.attach_filename = new_attachment.name
            }
            comments.push(updated_comment);
            this.props.update_selected_account_data('comments', comments);
            this.setState({ clicked: false, message: '', new_attachment: null })
            this.reset_new_comment();

        } catch (e) {
            console.log(e)
            this.setState({ clicked: false, message: '', new_attachment: null })
        }

    }

    async email(selected_account) {
        try {
            const email_company_id = this.props.current_user.company_id === 'TMS2' ? 'SLUSA' : 'SLC';
            const subject_line = process.env.REACT_APP_EMAIL_TITLE + ': Credit Application Uploaded for ' + email_company_id;
            let message_text =
                'A new credit application has been uploaded for customer code: ' +
                selected_account.id.toUpperCase() +
                ' by ' +
                this.props.current_user.salesperson_id;

            message_text = `${message_text} \n\nView application at: https://crm.scotlynn.app`
            const recipients = [
                {
                    emailAddress: {
                        address: process.env.REACT_APP_CREDIT_EMAIL,
                    },
                },
            ];
            const message_body = {
                message: {
                    subject: subject_line,
                    body: {
                        contentType: 'Text',
                        content: message_text,
                    },
                    toRecipients: recipients,
                },
            }
            const inst = this.context.instance;
            const acct = this.context.accounts;
            await utilityFunctions.Send_Email(inst, acct, message_body)
        } catch (e) {
            console.log(e);
        }
    }

    get_credit_overview() {
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const row_style = 'd-flex flex-row justify-content-between px-2 py-1 text-sm overview-item text-nowrap';

        let credit_status_icon = null;
        if (selected_account.credit_status === 'Approved') {
            credit_status_icon = <bdi className="text-success align-self-center">{svg_icons.checkmark_icon}</bdi>
        } else if (selected_account.credit_status === 'Pending') {
            credit_status_icon = <bdi className="text-warning bg-dark p-0 align-self-center" style={{borderRadius:'50%', height:'1rem',width:'1rem'}}>{svg_icons.warning_icon}</bdi>
        } else {
            credit_status_icon = <bdi className="text-danger align-self-center">{svg_icons.missing_icon}</bdi>
        }

        const row = (label, value,title) =>
            <div className={row_style} title={this.props.role==='lam'?'':title}>
                <label>{label}:</label>
                <label>{value}</label>
            </div>;

        const row_Y_or_N = (label, value, title) =>
            <div className={row_style} title={this.props.role === 'lam' ? '' : title}>
                <label>{label}:</label>
                {value === 'Y' ?
                    <label className="d-flex flex-row justify-content-end">
                        <bdi className="align-self-center me-2">Yes</bdi>
                        <bdi className="align-self-center text-success">
                            {svg_icons.checkmark_icon}
                        </bdi>
                    </label> :
                    <label className="d-flex flex-row justify-content-end">
                        <bdi className="align-self-center me-2">No</bdi>
                        <bdi className="align-self-center text-danger">
                            {svg_icons.missing_icon}
                        </bdi>
                    </label>
            }              
            </div>

        if (selected_account) {
            return(
                <div className='credit-overview flex-grow-2 align-self-start mb-3 w-100' >
                    <h6 className="tile-title p-2 pb-0">Credit Overview</h6>
                    <hr className="m-0" />
                    {row('Code', selected_account.id, 'Table: customer\nField: id')}
                    {row('Address', `${selected_account.address1}, ${selected_account.city}, ${selected_account.state_id}`, 'Table: customer\nField: address1, city, state_id')}
                    {selected_account.main_phone ? row('Phone', selected_account.main_phone, 'Table: customer\nField: main_phone'):null}
                    {row_Y_or_N('Precheck Submitted', selected_account.crm_credit_precheck, 'Table: customer\nField: crm_credit_precheck')}
                    {selected_account.crm_credit_precheck === 'Y' ? row('Precheck Request Date', selected_account.crm_credit_precheck_request, 'Table: customer\nField: crm_credit_precheck_request') : null}
                    {selected_account.crm_credit_precheck === 'Y' ? row('Precheck Approval Date', selected_account.crm_credit_approval_date, 'Table: customer\nField: crm_credit_approval_date') : null}
                    {row_Y_or_N('Application Submitted', selected_account.credit_application, 'Table: customer\nField: credit_application')}
                    {selected_account.credit_application === 'Y' ? row('Credit Application Date', selected_account.credit_check_date, 'Table: customer\nField: credit_check_date'):null}
                    <div className={row_style} title={this.props.role === 'lam' ? '' : 'Table: customer\nField: credit_status'}>
                        <label>Credit Status:</label>
                            <label className="d-flex flex-row justify-content-end">
                            <bdi className="align-self-center me-2">{selected_account.credit_status}</bdi>
                            {credit_status_icon}
                            </label> 
                    </div>         
                    {row('Last Approval Date', selected_account.crm_credit_approval_date, 'Table: customer\nField: crm_credit_approval_date')}  
                </div>
            )
        } else {
            return null
        }
    }

    get_credit_history() {
        const selected_account_data = this.props.selected_account_data;
        let comments = selected_account_data ? selected_account_data.comments ? selected_account_data.comments.filter(s => s.comment_type_id === 'INFO') : [] : [];

        const sort_field = this.state.sort_field;
        const sort_direction = this.state.sort_direction;

        if (comments.length > 0) {
            const sortingStrategies = {
                Type: (a, b) => a.comment_type_id.localeCompare(b.comment_type_id),
                Comment: (a, b) => a.comments.length - b.comments.length,
                'Entered User': (a, b) => a.entered_user_id.localeCompare(b.entered_user_id),
                Attachment: (a, b) => a.attach_filename.localeCompare(b.attach_filename),
                'Entered Date': (a, b) => differenceInDays(new Date(a.entered_date_str), new Date(b.entered_date_str)),
            };
            comments = comments.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
        }


        const comments_block = (d) =>
            <div className="text-sm mt-2">
                <small className="fw-bold text-sm bg-light-gray rounded px-2" title={this.props.role === 'lam' ? '' : 'Table: comment\nField: comment_type_id'}>{d.comment_type_id}</small>
                <small className="fw-bold text-sm opacity-75 ps-1" title={this.props.role === 'lam' ? '' : 'Table: comment\nField: comments'}>{d.comments}</small>
            </div>


        return (<div className="d-flex flex-column align-self-center w-100" >
            <h6 className="tile-title">Credit History</h6>
            {comments.length > 0 ?
                
                <ListGroup>
                    {comments.map(d =>
                        <ListGroup.Item key={d.id} className="pb-3 w-100">
                            <div className="d-flex flex-column justify-content-start text-dark">
                                <div className="d-flex flex-row justify-content-between ">
                                    <div className="d-flex flex-column" title={this.props.role === 'lam' ? '' : 'Table: comments\nField: entered_user_name'}>
                                        <div className="fw-bold text-sm ">{d.entered_user_name ? d.entered_user_name : 'Unknown'}</div>

                                    </div>

                                    <div className="d-flex flex-row justify-content-end">
                                        <small className="fw-bold opacity-75 text-xs" title={this.props.role === 'lam' ? '' : 'Table: comments\nField: entered_date'}>Modified: {d.entered_date_str}</small>
                                    </div>
                                </div>
                                {comments_block(d)}
                                <div className="d-flex flex-row justify-content-between w-100 mt-2">
                                    {d.attach_filename ?
                                        <Button
                                            variant="link"
                                            disabled={this.state.clicked}
                                            size="sm"
                                            title={this.props.role === 'lam' ? '' : 'Table: comments\nField: attach_filename'}
                                            className="text-xs underlined text-start p-0"
                                            onClick={() => this.getThumbnail(d)}>
                                            {svg_icons.attachment_icon}
                                            {this.state.clicked_attachment===d.id ? 'Opening attachment...' : d.attach_filename}
                                        </Button> :
                                        <div></div>}
                                </div>
             
                          
                            </div>
                        </ListGroup.Item>
                    )}
            
                </ListGroup>
                :
                <Alert className="d-flex flex-column w-100 opacity-75" variant="secondary">
                    <h6 className="tile-title p-0 m-0">No Credit History Exists</h6>
                </Alert>}
            {this.new_comment_form()}
                </div>
               )
        } 
    
    get_credit_application_history() {
        const selected_account_data = this.props.selected_account_data;
        let comments = selected_account_data ? selected_account_data.comments ? selected_account_data.comments.filter(s => s.comment_type_id === 'CUSCREDI') : [] : [];

        const sort_field = this.state.sort_field;
        const sort_direction = this.state.sort_direction;

        if (comments.length > 0) {
            const sortingStrategies = {
                Type: (a, b) => a.comment_type_id.localeCompare(b.comment_type_id),
                Comment: (a, b) => a.comments.length - b.comments.length,
                'Entered User': (a, b) => a.entered_user_id.localeCompare(b.entered_user_id),
                Attachment: (a, b) => a.attach_filename.localeCompare(b.attach_filename),
                'Entered Date': (a, b) => differenceInDays(new Date(a.entered_date_str), new Date(b.entered_date_str)),
            };
            comments = comments.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
        }


        const comments_block = (d) =>
            <div className="text-sm mt-2">
                <small className="fw-bold text-sm bg-light-gray rounded px-2">{d.comment_type_id}</small>
                <small className="fw-bold text-sm opacity-75 ps-1">{d.comments}</small>
            </div>


        return (<div className="d-flex flex-column align-self-center mt-4 w-100" >
            <h6 className="tile-title">Credit Application History</h6>
            {comments.length > 0 ?

                <ListGroup>
                    {comments.map(d =>
                        <ListGroup.Item key={d.id} className="pb-3 w-100">
                            <div className="d-flex flex-column justify-content-start text-dark">
                                <div className="d-flex flex-row justify-content-between ">
                                    <div className="d-flex flex-column">
                                        <div className="fw-bold text-sm ">{d.entered_user_name ? d.entered_user_name : 'Unknown'}</div>

                                    </div>

                                    <div className="d-flex flex-row justify-content-end">
                                        <small className="fw-bold opacity-75 text-xs">Modified: {d.entered_date_str}</small>
                                    </div>
                                </div>
                                {comments_block(d)}
                                <div className="d-flex flex-row justify-content-between w-100 mt-2">
                                    {d.attach_filename ?
                                        <Button variant="link" disabled={this.state.clicked} size="sm" className="text-xs underlined text-start p-0" onClick={() => this.getThumbnail(d)}>
                                            {svg_icons.attachment_icon}
                                            {this.state.clicked_attachment === d.id ? 'Opening attachment...' : d.attach_filename}
                                        </Button> :
                                        <div></div>}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
                :
                <Alert className="d-flex flex-column w-100 opacity-75" variant="secondary">
                    <h6 className="tile-title p-0 m-0">No Credit History Exists</h6>
                </Alert>}

        </div>
        )
    }

    render() {
        const {
            show_popup,
            current_thumbnail,
            current_thumbnail_name,
            new_comment
        } = this.state;



        return (
            <Container fluid="xs" className="w-100 pt-3">
                <Row className="d-flex flex-row justify-content-between w-100">
                    <Col className="d-flex flex-column align-self-start" xs={12} xl={4}>
                        {this.get_credit_app(new_comment)}
                        {this.is_users_account()}
         
                        {this.get_credit_overview()}
                        <Alert variant="info">
                        <label className="text-sm">Please note: Credit Prechecks over 6-months old are considered outdated and a new request must be completed.</label>
                        </Alert>
                    </Col>
                    <Col className="d-flex flex-column align-self-center " xs={12} xl={7}>
                   
                        {show_popup ?
                            <div className="d-flex flex-column w-100">
                                <Button
                                    className="btn-op70 my-3 align-self-start"
                                    id="back-to-comments-btn"
                                    variant="light"
                                    onClick={() =>
                                        this.setState({
                                            current_thumbnail: null,
                                            show_popup: false,
                                            current_thumbnail_name: '',
                                        })
                                    }
                                >
                                    {svg_icons.back_arrow}
                                </Button>

                                {current_thumbnail !== null ? (
                                    <iframe src={current_thumbnail} title={current_thumbnail_name} className="iframe" />
                                ) : null}
                            </div> :
                            <div className="d-flex flex-column w-100">
                                {this.get_credit_history()}
                                {this.get_credit_application_history()}
                            </div>
                        }
                    </Col>
                </Row>
                
              
               

            </Container>
        );
    }
}
