import { MsalContext } from '@azure/msal-react';
import {   getDate, getYear, lastDayOfMonth } from 'date-fns';
import React, { Component } from 'react';
import { Col, Container, Row, Alert, Stack} from 'react-bootstrap';
import LoadingIcons from 'react-loading-icons';

import { SalespersonViewer } from '../TableComponents/SalespersonViewer';
import { InactiveLamAccounts } from '../GMComponents/InactiveLamAccounts';
import { LAMProspects } from '../Modals/LAMProspects';

import { ExpiredProspectsTile } from '../TableComponents/ExpiredProspectsTile';
import { GeneralManagerSelector } from '../GMComponents/GeneralManagerSelector';
import { ProspectActivityReport } from '../Modals/ProspectActivityReport';
import { DynamicPortalTile } from '../PageComponents/DynamicPortalTile';
import { ProspectActivityInput } from '../GMComponents/ProspectActivityInput';

import * as generalManagerFunctions from '../Functions/GeneralManagerFunctions';
import * as prospectActionFunctions from '../Functions/ProspectActionFunctions';
import * as baseColors from '../Functions/BaseColors';
import * as customerFunctions from '../Functions/CustomerFunctions';

import { LoadingModal } from '../Modals/LoadingModal';
import { GMRequestsTile } from '../GMComponents/GMRequestsTile';
import { ViewAccountModal } from '../Modals/Account/ViewAccountModal';
import { GiftAccountModal } from '../Tabs/ActionComponents/GiftAccountModal';



export class GMPortal extends Component {
    static displayName = GMPortal.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            //ComponentDidMount
            user_loaded: false,

            //PopulateDashboardData
            gm_dashboard_data: [],
            gm_dashboard_data_loaded: false,
            gm_dashboard_data_failed: false,

            //populateSalespersons
            salespersons:[],
            salespersons_loaded: false,
            salespersons_failed: false,

            //PopulatePAData
            pa_start_date: { day: '', month: '', year: getYear(new Date()) },
            pa_end_date: { day: '', month: '', year: getYear(new Date()) },
            pa_data: { list: [], loaded: true, failed: false },
            pa_date_type: 'quarter',
            show_report: false,

            show_lam: false,
            lam_clicked:false,

            account_clicked: false,
            view_request_status: 'P',
            filter_sales_team: '',
            selected_salesperson: null,

            selected_accounts_list: [],
            selected_accounts_loaded: false,
            selected_accounts_failed: false,
            sort_direction: '',
            sort_field: '',

            lam_activities: [],
            lam_activities_loaded: false,

            selected_account_data: null,
            show_selected_account: true,
            accounts_list:[],

            request_index: 0,

            expired_accounts: [],
            expired_accounts_loaded: false,

            gm_assignment: null,
            gm_assignment_loaded: false,
            all_general_managers: [],
            inactive_lam_accounts: [],
            inactive_lam_accounts_loaded: false,
            requests_loaded: false,

            selected_request_type: 'ACCTREQ',

            gifted_clicked: false,
            show_gifted: false,
            selected_gift_account: null,
            selected_gift_prospect_actions: [],
            selected_gift_account_loaded: false,
            show_phone: false,
            phone_results_list: [],
            phone_results_loaded: false,
            phone_number_searching: false,
            phone: '',
        };

    }

    update_selected_account_data(field, prop) {
        let c_selected_account_data = this.state.selected_account_data;
        c_selected_account_data[field] = prop;
        this.setState({ selected_account_data: c_selected_account_data })
       
    }



    async componentDidMount() {
        try {
            if (this.props.salesperson) {
                this.get_portal(this.props.salesperson);
                // this.timer = setInterval(() => this.get_portal(currentUser), 10000);
            }

        } catch (e) {
            console.log(e);
            this.setState({ current_user: null, user_loaded: false });
        }
    }

    componentDidUpdate() {
        if (!this.state.requests_loaded) {
            if (this.props.action_required_requests && this.props.incoming_requests) {
                if (this.props.action_required_requests.loaded === 'Y' && this.props.incoming_requests.loaded==='Y') {
                    const pending_action_requests = this.props.action_required_requests.list.filter((s) => s.status === 'P');
                    const pending_incoming_requests = this.props.incoming_requests.list.filter((s) => s.status === 'P');
                    let final_list = []
                    if (pending_action_requests) {
                        final_list = pending_action_requests
                    }
                    if (pending_incoming_requests) {
                        final_list = final_list.concat(pending_incoming_requests)
                    }
                    this.set_param_index(final_list);
                    this.setState({ requests_loaded: true});
                }
            }
        }
    }

    set_param_index(pending_requests) {
        if (!window.location.pathname.includes('incomingPhone')) {
            let current_activity_id = '';
            if (window.location.pathname.split('/').length === 3) {
                current_activity_id = window.location.pathname.split('/')[2];
            }
            if (window.location.pathname.split('/').length === 4) {
                current_activity_id = window.location.pathname.split('/')[2];
            }
            if (current_activity_id && pending_requests) {
                const current_activity = pending_requests.find((s) => s.id === current_activity_id);
                const index = pending_requests.indexOf(current_activity);
                this.setState({ request_index: index > -1 ? index : 0, requests_loaded: true, selected_request_type: current_activity? current_activity.request_type:'' });
            }
        }
     
    }

    componentWillUnmount() {
        //  this.timer = null;
    }

    update_inactive_list(account) {
        try {
            let current_inactive_accounts = this.state.inactive_lam_accounts;
            let dashboard_data = this.state.gm_dashboard_data;
            if (current_inactive_accounts) {
                current_inactive_accounts = current_inactive_accounts.filter(s => s.id !== account.id)
            }
            if (dashboard_data) {
                let current_salesperson = dashboard_data.find(s => s.salesperson_id === account.salesperson_id);
                if (current_salesperson) {
                    let c_index = dashboard_data.indexOf(current_salesperson)
                    if (account.type_of === 'Customer') {
                        current_salesperson.num_customers = current_salesperson.num_customers + 1;
                    } else {
                        current_salesperson.num_prospects = current_salesperson.num_prospects + 1;
                    }
                    dashboard_data[c_index] = current_salesperson
                }

            }
            this.setState({
                inactive_lam_accounts: current_inactive_accounts,
                gm_dashboard_data: dashboard_data,
                show_gifted: false,
                selected_gift_account: null,
                selected_gift_prospect_actions: [],
                selected_gift_account_loaded: false
            })
        } catch (e) {
            console.log(e)
        }
   
    }

    async get_portal(currentUser) {
        if (currentUser) {
            const gm_assignment = await generalManagerFunctions.Get_General_Manager_Assignment_By_GM(currentUser.company_id, currentUser.salesperson_id);
            const gms_list = await generalManagerFunctions.Get_All_General_Managers();

            const dashboard = await generalManagerFunctions.Get_GM_Dashboard(currentUser.company_id, currentUser.salesperson_id);
            const expired_accounts = await generalManagerFunctions.Get_Expiring_By_General_Manager(currentUser.company_id, currentUser.salesperson_id);
            const inactive_accounts = await generalManagerFunctions.Get_Inactive_LAM_Accounts(currentUser.company_id, currentUser.salesperson_id)
           

            this.setState({
                gm_dashboard_data: dashboard,
                gm_dashboard_data_loaded: true,
                gm_dashboard_data_failed: false,
                expired_accounts: expired_accounts,
                expired_accounts_loaded:true,
                current_user: currentUser,
                user_loaded: true,
                gm_assignment: gm_assignment,
                gm_assignment_loaded: true,
                all_general_managers: gms_list,
                inactive_lam_accounts: inactive_accounts,
                inactive_lam_accounts_loaded: true
            });
        }
    }



    async setSalesperson(salesperson) {
        try {
            this.setState({ lam_clicked:true })
            const selected_accounts_list_data = await customerFunctions.Get_My_Accounts(salesperson.company_id, salesperson.salesperson_id);
            const lam_activity_data = await prospectActionFunctions.Get_By_Salesperson(salesperson.company_id, salesperson.salesperson_id);
            this.setState({
                    selected_salesperson: salesperson,
                    show_lam: true,
                selected_accounts_list: selected_accounts_list_data,
                selected_accounts_loaded: true,
                selected_accounts_failed: false,
                lam_activities: lam_activity_data,
                lam_activities_loaded: true,
                lam_clicked:false
            });

        } catch (e) {
            console.log(e);
            this.setState({
                selected_salesperson: null,
                show_lam: false,
                completed_task_week_loaded: false,
                new_prospects_loaded: false,
                released_prospects_loaded: false,
            });
        }
    }

    async reloadSalespersons(currentUser) {
        try {
            await this.PopulateDashboardData(currentUser.company_id, currentUser.user_id);
        } catch (e) {
            console.log(e);
        }
    }

    update_pa_activity_date_quartly(e) {
        try {
            const current_start_date = this.state.pa_start_date;
            const current_end_date = this.state.pa_end_date;
            if (e.target.name === 'pa_quarterly') {
                current_start_date.day = '01';
                const month = Number.parseInt(e.target.value);
                const last_day_month = lastDayOfMonth(new Date(2023, month + 1, 1));
                current_start_date.month = e.target.value;
                current_end_date.day = getDate(last_day_month);
                current_end_date.month = month + 2 > 9 ? month + 2 : '0' + (month + 2);
            } else {
                current_start_date.year = e.target.value;
                current_end_date.year = e.target.value;
            }

            this.setState({ pa_start_date: current_start_date, pa_end_date: current_end_date });
        } catch (e) {
            this.setState({
                pa_start_date: { day: 0, month: 0, year: getYear(new Date()) },
                pa_end_date: { day: 0, month: 0, year: getYear(new Date()) },
            });
        }
    }

    update_pa_activity_date_montly(e) {
        try {
            const current_start_date = this.state.pa_start_date;
            const current_end_date = this.state.pa_end_date;

            if (e.target.name === 'pa_monthly') {
                const month = Number.parseInt(e.target.value) - 1;
                const last_day_month = lastDayOfMonth(new Date(2023, month, 1));
                current_start_date.day = '01';
                current_start_date.month = e.target.value;
                current_end_date.month = e.target.value;
                current_end_date.day = getDate(last_day_month);
            } else {
                current_start_date.year = e.target.value;
                current_end_date.year = e.target.value;
            }
            this.setState({ pa_start_date: current_start_date, pa_end_date: current_end_date });
        } catch (e) {
            this.setState({
                pa_start_date: { day: '', month: '', year: getYear(new Date()) },
                pa_end_date: { day: '', month: '', year: getYear(new Date()) },
            });
        }
    }

    update_pa_activity_date_custom(e) {
        try {
            const year = e.target.value.substring(0, 4);
            const month = e.target.value.substring(5, 7);
            const day = e.target.value.substring(8, 10);
            if (e.target.name === 'start') {
                this.setState({ pa_start_date: { day: day, month: month, year: year } });
            } else {
                this.setState({ pa_end_date: { day: day, month: month, year: year } });
            }
        } catch (e) {}
    }

    async open_account_view_for_request(prop, list) {
        this.setState({ selected_accounts_list: list });
        await this.view_account(prop.company_id, prop.id);

    }

    render() {
        const {
            gm_dashboard_data,
            gm_dashboard_data_loaded,
            user_loaded,
            salespersons_failed,

            selected_accounts_list,
            selected_accounts_loaded,
            selected_accounts_failed,
            view_request_status,
            selected_salesperson,
            show_lam,
            lam_clicked,
            lam_activities,
            lam_activities_loaded,
            pa_date_type,
            pa_data,
            pa_start_date,
            pa_end_date,
            show_report,
            account_clicked,
            selected_account_data,
            show_selected_account,
            expired_accounts,
            expired_accounts_loaded,
            gm_assignment,
            gm_assignment_loaded,
            all_general_managers,

            inactive_lam_accounts,
            inactive_lam_accounts_loaded,
            selected_request_type,
            request_index,

            gifted_clicked,
            show_gifted,
            selected_gift_account,
            selected_gift_account_loaded,

   
        } = this.state;

        //#region Salespersonscurrent_gm_dashboard
        let current_gm_dashboard =
            gm_dashboard_data_loaded && Array.isArray(gm_dashboard_data) ? gm_dashboard_data : [];
        const outgoing_requests_list = this.props.outgoing_requests ? this.props.outgoing_requests.loaded === 'Y' ? this.props.outgoing_requests.list : [] : [];
        const incoming_requests_list = this.props.incoming_requests ? this.props.incoming_requests.loaded === 'Y' ? this.props.incoming_requests.list : [] : [];
        const action_requests_list = this.props.action_requests ? this.props.action_requests.loaded === 'Y' ? this.props.action_requests.list : [] : [];
        const no_action_requests_list = this.props.no_action_requests ? this.props.no_action_requests.loaded === 'Y' ? this.props.no_action_requests.list : [] : [];
        const current_user = this.props.salesperson;
        const rev_code = current_user ? current_user.revenue_code : '';


        const salesperson_col = (
            <SalespersonViewer
                current_user={current_user}
                salespersons={current_gm_dashboard}
                rev_code={rev_code}
                updateFilter={(prop) => this.setState({ filter_sales_team: prop })}
                selected_salesperson={selected_salesperson}
                setSalesperson={(prop) => this.setSalesperson(prop)}
                all_completed_loaded={gm_dashboard_data_loaded}
            />
        );

        const inactive_accounts_col = (
            <InactiveLamAccounts
                current_user={current_user}
                view_account={(company_id, customer_id) => this.open_account_view_for_request({ company_id: company_id, id: customer_id }, inactive_lam_accounts)}
                inactive_lam_accounts={inactive_lam_accounts}
                inactive_lam_accounts_loaded={inactive_lam_accounts_loaded}
                salesperson_list={this.props.salesperson_list}
                current_gm_dashboard={current_gm_dashboard}
                show_gifted={(prop) => this.Get_Account_To_Gift(prop.company_id, prop.id)}
            />
        );


        const gm_request_container =
            <GMRequestsTile
                current_user={current_user}
                msal_account={this.props.msal_account}
                role={this.props.role}
                view_request_status={view_request_status}
                update_view_request_status={(prop) => this.setState({ view_request_status: prop })}
                outgoing_requests_list={outgoing_requests_list}
                incoming_requests_list={incoming_requests_list}
                action_requests_list={action_requests_list}
                no_action_request_list={no_action_requests_list}
                
                view_account={(account, list) => this.open_account_view_for_request(account, list)}                                    
                request_type={selected_request_type}
                update_request_type={(prop) => this.setState({ selected_request_type: prop })}
                request_index={request_index}
                update_request_index={(prop) => this.setState({ request_index: prop })}
                update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
            />
          
        const expired_accounts_container =
            <ExpiredProspectsTile
                current_user={this.props.salesperson}
                role={this.props.role}
                expired_accounts={expired_accounts}
                view_account={(company_id, id) => this.view_account(company_id, id)}
            />

        const manager_assignment_selector = (
            <GeneralManagerSelector
                current_user={this.props.salesperson}
                role={this.props.role}
                gm_assignment={gm_assignment}
                all_general_managers={all_general_managers}
                update_gm_assignment={(prop) => this.setState({ gm_assignment: prop })}
                update_gms_list={(prop) => this.setState({ all_general_managers:prop }) }
            />
        );


        const pa_report = (
            <ProspectActivityInput
                pa_date_type={pa_date_type}
                pa_start_date={pa_start_date}
                pa_end_date={pa_end_date}
                on_update_quarterly={(e) => this.update_pa_activity_date_quartly(e)}
                on_update_monthly={(e) => this.update_pa_activity_date_montly(e)}
                on_update_custom={(e) => this.update_pa_activity_date_custom(e)}
                update_pa_type={(prop) =>
                    this.setState({
                        pa_date_type: prop,
                        pa_start_date: { day: '', month: '', year: getYear(new Date()) },
                        pa_end_date: { day: '', month: '', year: getYear(new Date()) },
                    })
                }
                onSave={() => this.populatePAData()}
            />
        );


        //#region Modals
        const modals = (
            <>
                {selected_account_data ?
                    <ViewAccountModal
                        show={show_selected_account}
                        onHide={() => this.setState({
                            show_selected_account: false,
                            selected_account_data: null,
                            selected_accounts_list: []
                        })}
                        current_user={this.props.salesperson}
                        msal_account={this.props.msal_account}
                        role={this.props.role}
                        accounts_list={selected_accounts_list}
                        prospects={this.props.prospects}
                        commodities={this.props.commodities}
                        categories={this.props.categories}
                        salesperson_list={this.props.salesperson_list}
                        selected_account_data={selected_account_data}
                        add_account={(prop) => this.props.add_account(prop)}
                        release_account={(prop) => this.props.release_account(prop)}
                        update_accounts_list={(prop) => this.setState({ selected_accounts_list: prop })}
                        view_account={(prop) => this.view_account(prop.company_id, prop.id)}
                        update_selected_account_data={(prop) => this.setState({ selected_account_data: prop })}
                        update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                        add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                        add_users_action={(prop) => this.props.add_users_action(prop)}
                        update_users_action={(prop) => this.props.add_users_action(prop)}
                    /> : null}
                <LoadingModal show={account_clicked} title="Customer" />
                {pa_data ? (
                    <ProspectActivityReport
                        show={show_report}
                        onClose={() =>
                            this.setState({
                                show_report: false,
                                pa_date_type: 'quarter', 
                                pa_start_date: { day: '', month: '', year: getYear(new Date()) },
                                pa_end_date: { day: '', month: '', year: getYear(new Date()) },
                                pa_data: { list: [], loaded: true, failed: false }
                            })
                        }
                        pa_start_date={pa_start_date}
                        pa_end_date={pa_end_date}
                        pa_data={pa_data}
                    />
                ) : null}
                <LoadingModal show={lam_clicked} title="LAM" />
                {selected_salesperson ? (
                    <LAMProspects
                        show={show_lam}
                        onHide={() =>
                            this.setState({
                                selected_salesperson: null,
                                show_lam: false,
                                selected_accounts_list: [],
                                selected_accounts_loaded: false,
                                lam_activities: [],
                                lam_activities_loaded: false,
                            })
                        }
                        current_accounts={selected_accounts_list}
                        current_user={current_user}
                        current_accounts_loaded={selected_accounts_loaded}
                        current_accounts_loaded={selected_accounts_loaded}
                        current_accounts_failed={selected_accounts_failed}
                        salesperson={selected_salesperson}
                        set_sp_company={(prop) => this.open_account_view_for_request(JSON.parse(prop), selected_accounts_list)}
                        lam_activities={lam_activities}
                        lam_activities_loaded={lam_activities_loaded}
                    />
                ) : null}
            </>
        );
        //#endregion Modals

        const flex = this.props.width < 600 ? 'column' : 'row';



        return user_loaded ? (
            <div className="d-flex flex-column align-self-center pt-3 w-100">  
                {modals}
                <Stack></Stack>
                <Container className="pb-4 pt-3 w-100" fluid="xs">
                   
                    <Row className="w-100 ps-2">
                        <Col className="p-0 ps-2 pe-1" xs={8}>
                            <DynamicPortalTile
                                title={'General Manager Requests'}
                                height={'40rem'}
                                body={gm_request_container}
                                loaded={true}
                                failed={false}
                                loading_title="General Manager Requests"
                            />
                            <br />
                            <DynamicPortalTile
                                title={''}
                                height={'50rem'}
                                body={salesperson_col}
                                loaded={gm_dashboard_data_loaded}
                                failed={salespersons_failed}
                                onRefresh={() => null}
                                loading_title={rev_code}
                            />
                        </Col>
                        <Col className="p-0 px-1">
                            <DynamicPortalTile
                                title={''}
                                height={'30rem'}
                                body={manager_assignment_selector}
                                loaded={gm_assignment_loaded}
                                failed={false}
                                loading_title="General Manager Assignments"
                            />
                            <br/>
                            <DynamicPortalTile
                                title={''}
                                height={'40rem'}
                                body={expired_accounts_container}
                                loaded={expired_accounts_loaded}
                                failed={false}
                                loading_title="Expired Accounts"
                            />
                            <br />
                            <DynamicPortalTile
                                title={''}
                                height={'40rem'}
                                body={inactive_accounts_col}
                                loaded={inactive_lam_accounts_loaded}
                                failed={false}
                                loading_title="Inactive LAM Accounts"
                            />
                            <br />
                            <DynamicPortalTile
                                title={'Prospect Activity Reports'}
                                height={'30rem'}
                                body={pa_report}
                                loaded={pa_data.loaded}
                                failed={pa_data.failed}
                                onRefresh={() => null}
                                loading_title="Prospect Activity Data"
                            />
 

                        </Col>
                    </Row>
                    {selected_gift_account && selected_gift_account_loaded ?
                        <GiftAccountModal
                            show={show_gifted}
                            onHide={() => this.setState({
                                show_gifted: false,
                                selected_gift_account: null,
                                selected_gift_prospect_actions: [],
                                selected_gift_account_loaded: false
                            })}
                            msal_account={this.props.msal_account}
                            selected_account_data={selected_gift_account}
                            update_selected_account_data={(property, prop) => this.update_inactive_list( prop)}
                            salesperson_list={this.props.salesperson_list}
                        /> :
                        null
                    }
                    
                    <LoadingModal show={gifted_clicked} title="Account to gift" />

                  
                </Container>
            </div>
        ) : (
                <div className="p-4 d-flex flex-column w-100" >
                    <Alert className="d-flex flex-row" variant="secondary">
                        <h6 style={{ color: baseColors.dark_gray }}>Loading</h6>
                        <LoadingIcons.ThreeDots
                            style={{ alignSelf: 'end', width: '1rem', marginLeft: '0.25rem' }}
                            fill={baseColors.dark_gray}
                            strokeOpacity={0.125}
                            speed={0.35}
                        />
                    </Alert>
             
            </div>
        );
    }




    async view_account(company_id, customer_id) {

        this.setState({ account_clicked: true});
        const account_data = await customerFunctions.View_Account(company_id, customer_id);
        this.setState({
            selected_account_data: account_data,
            show_selected_account: true,
            account_clicked: false
        })
    }


    async Get_Account_To_Gift(company_id, customer_id) {

        this.setState({
            gifted_clicked: true,
            show_gifted:false,
            selected_gift_account_loaded: false,
            selected_gift_prospect_actions:[],
            selected_gift_account: null
        });
        const account = await customerFunctions.Get_By_ID(company_id, customer_id);
        const body = {
            account: account,
            prospect_actions: [],
            requests: [],
            contacts: [],
            comments: [],
            orders: [],
            comment_audit: [],
            customer_audit: []
        }
        this.setState({
            selected_gift_account: body,
            selected_gift_prospect_actions: [],
            selected_gift_account_loaded: true,
            gifted_clicked: false,
            show_gifted:true
        })
    }


    //#region Component Did Mount
    async PopulateDashboardData(company_id, sales_manager_id) {
        try {
            const gm_dashboard_data = await generalManagerFunctions.Get_GM_Dashboard(company_id, sales_manager_id);
            this.setState({
                gm_dashboard_data: gm_dashboard_data,
                gm_dashboard_data_loaded: true,
                gm_dashboard_data_failed: false,
            });
        } catch (e) {
            console.log(e);
            this.setState({
                gm_dashboard_data: [],
                gm_dashboard_data_loaded: true,
                gm_dashboard_data_failed: true,
            });
        }
    }

    async populatePAData() {
        try {
            this.setState({ pa_data: { list: [], loaded: false, failed: false }, show_report: false });
            const general_manager = this.state.current_user;
            const start_date = this.state.pa_start_date;
            const end_date = this.state.pa_end_date;

            const formatted_start_date = start_date.year + '-' + start_date.month + '-' + start_date.day;
            const formatted_end_date = end_date.year + '-' + end_date.month + '-' + end_date.day;
            const data = await generalManagerFunctions.Get_PA_Data(general_manager.company_id, general_manager.user_id, formatted_start_date, formatted_end_date);
            this.setState({ pa_data: { list: data, loaded: true, failed: false }, show_report: true });
   
        } catch (e) {
            console.log(e);
            this.setState({ pa_data: { list: [], loaded: true, failed: true } });
        }
    }



}


/**
 * PROPS
 salesperson
msal_account
role
width
commodities
prospects
salesperson_list
outgoing_requests
incoming_requests
action_required_requests
no_action_required_requests
update_my_requests(type,list)
add_account(account)
release_account(account)
update_users_request
 */
