import React, { Component } from 'react';
import { Card,ListGroup} from 'react-bootstrap';
import { isSameISOWeek, addDays, subDays, format, getDay, isToday, isBefore, getYear } from 'date-fns';
import * as baseColors from '../Functions/BaseColors';
import * as prospectActionFunctions from '../Functions/ProspectActionFunctions';
import * as svg_icons from '../../Media/svg_exports';
import { PendingFollowUpModal } from '../Modals/PendingFollowUpModal';
import { NewPendingFollowupModal } from './NewPendingFollowupModal';

export class PendingFollowUps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view_date: new Date(),
            show_followup:false,
            current_followup: null,
            clicked: false,
            show_new_follow: false,
            clicked_date:''
        };
    }

    async complete_followup() {
        this.setState({ clicked: true })
        try {
            let body = this.state.current_followup;
            body.status='C'
            const followup_data = await prospectActionFunctions.Update(body);

            this.props.update_users_action(followup_data);
            this.setState({ clicked: false, current_followup: null, show_followup :false})

        } catch (e) {
            console.log(e)
        }
    }

    getCalendar(date, list) {
       
        const daysOfWeek = [
            undefined, // Sunday is not used
            { name: 'Monday', color: baseColors.dark_blue },
            { name: 'Tuesday', color: baseColors.dark_yellow },
            { name: 'Wednesday', color: baseColors.dark_orange },
            { name: 'Thursday', color: baseColors.dark_red },
            { name: 'Friday', color: baseColors.dark_green },
            undefined, // Saturday is not used
        ];

        const currentDayOfWeek = getDay(date);

        // Calendar displayed goes from Monday (day 1) to Friday (day 5).
        const calendarWeek = [];

        for (let i = 1; i < 6; i++) {
            // Calculate the offset from the current day.  This fills in the dates for the entire week based on `date`.
            // For example, if `date` is a Wednesday, then we need to calculate the dates for Monday, Tuesday, Thursday,
            // and Friday.
            const offset = i - currentDayOfWeek;

            let weekDay;
            if (offset >= 0) {
                weekDay = addDays(date, offset);
            } else {
                weekDay = subDays(date, Math.abs(offset));
            }

            // Select tasks for the day of the week.
            const data = list.filter((s) => getDay(new Date(s.date_due)) === i);

            const style = Object.assign(
                { borderLeft: `${daysOfWeek[i].color} solid 0.5rem` },
                offset !== 0 ? { filter: 'opacity(0.6)' } : {}, // Dim days that are not `date`.
            );

            calendarWeek.push({
                header: daysOfWeek[i].name,
                data: data,
                date: format(weekDay, 'MM/dd'),
                style: style,
            });
        }

        return calendarWeek;
    }

    render() {
        const { view_date, show_followup, current_followup, clicked, show_new_follow, clicked_date } = this.state;


        const pending_follow_ups = this.props.users_prospect_actions ? this.props.users_prospect_actions.filter(s => s.status === 'P' && s.activity_id === 'FOLLOWUP' && isSameISOWeek(new Date(s.date_due), view_date)) : [];
        const current_calendar = this.getCalendar(view_date, pending_follow_ups);
        const monday_start =
            current_calendar.filter((s) => s.header === 'Monday').length > 0
                ? current_calendar.filter((s) => s.header === 'Monday')[0]
                : '';
        const friday_end =
            current_calendar.filter((s) => s.header === 'Friday').length > 0
                ? current_calendar.filter((s) => s.header === 'Friday')[0]
                : '';

        return (
            <Card className="p-2 d-flex align-self-start" style={{width:'400px', height:'400px'}}>
                <Card.Body>
                    <div className="d-flex flex-row justify-content-between align-self-center mt-2 mb-2">
                        <label className="align-self-center hover-over" onClick={() => this.setState({ view_date: addDays(view_date, -7) })}>{svg_icons.back_arrow}</label>
                        {isToday(view_date) ? <div></div> : (
                            <small
                                className="hover-over text-muted underlined"
                                onClick={() => this.setState({ view_date: new Date() })}
                            >
                                Current
                            </small>
                        )}
                        <label className="align-self-center hover-over" onClick={() => this.setState({ view_date: addDays(view_date, 7) })}>{svg_icons.forward_arrow}</label>

                    </div>


            
                    <Card.Title as="h6" className="tile-title text-secondary text-nowrap">Activities Due ({monday_start.date + ' - ' + friday_end.date})</Card.Title>

                    {current_calendar.map((c, index) => (
                        <ListGroup style={c.style} className="mb-2" key={index}>
                            <ListGroup.Item className="mb-0 d-flex flex-row justify-content-between">
                                <h6 className="align-self-center pt-1 text-med">{c.header + ' ' + c.date}</h6>
                                <label
                                    className="text-success hover-over align-self-center"
                                    title={`Add New Reminder for ${c.date.replaceAll('/', '-')}-2024`}
                                    onClick={() => this.setState({ show_new_follow: true, clicked_date: `${c.date.replaceAll('/','-')}-2024` })}
                                >
                                    {svg_icons.plus_icon}</label>
                             
                                
                            </ListGroup.Item>
                            {c.data.map((d) => (
                                <ListGroup.Item
                                    className="mt-0 d-flex flex-column hover-background"
                                    onClick={() =>
                                        this.setState({
                                            show_followup: true,
                                            current_followup: d
                                        })
                                    }
                                    key={d.id}
                                    style={
                                        isBefore(new Date(d.date_due), new Date())
                                            ? { backgroundColor: baseColors.light_red }
                                            : {}
                                    }
                                    action={true}
                                >
                                    <label className="text-sm pb-1">
                                        <strong className="pe-1 underlined text-dark">
                                            {d.customer_name + ', '}
                                        </strong>
                                        <bdi className="font-italic">{d.type_of}</bdi>
                                    </label>
                                    <small className="text-sm me-2 text-bold text-dark">Due: {d.date_due_str}</small>
                                    <small className="text-sm text-muted">{d.remarks}</small>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    ))}
                </Card.Body>
                <PendingFollowUpModal
                    show={show_followup}
                    current_user={ this.props.current_user}
                    onHide={() => this.setState({ show_followup: false, current_followup: null })}
                    current_followup={current_followup}
                    update_followup={(prop) => this.setState({ current_followup: prop })}
                    complete_followup={() => this.complete_followup()}
                    clicked={clicked}
                />
                <NewPendingFollowupModal
                    show={show_new_follow}
                    current_user={this.props.current_user}
                    clicked_date={clicked_date}
                    onHide={() => this.setState({ show_new_follow: false })}
                    prospect_actions={this.props.users_prospect_actions}
                    accounts={this.props.accounts}
                    add_users_action={(prop) => this.props.add_users_action(prop)}
                />
            </Card>
        );
    }
}