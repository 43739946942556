import React, { Component } from 'react';
import { Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';

export class PendingFollowUpModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            additional_details: '',
        };
    }


    handleInputChange = (e) => {
        let current_followup = this.props.current_followup;
        current_followup[e.target.name] = e.target.value;
        this.props.update_followup(current_followup);
    };


    render() {
        const { additional_details } = this.state;
        const current_followup = this.props.current_followup;
  
        

        return (
            this.props.current_user && current_followup ?
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen="md-down"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName="light-modal"
                >
                    <Modal.Header className="w-100">
                        <Container fluid="xs" className="w-100">
                            <Row>
                                <Col xs={2}>
                                    <CloseButton title="Close" onClick={() => this.props.onHide()} />
                                </Col>
                            <Col xs={8}>
    
                                    <h4 className="w-100 text-center text-muted">
                                        {this.props.clicked ? `Completing ${current_followup.type_of} FOLLOWUP...` : `${current_followup.customer_name}`}
                                    </h4> 
                                     
                                </Col>
                                <Col xs={2}></Col>
                            </Row>
                        </Container>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Row>
                            <Col xl={12}>
                                { this.props.current_user.impersonate==='Y' ? (
                                    <div className="d-flex flex-row justify-content-center w-100">
                                        <h5 className="w-100 text-center py-5">
                                            This feature is not available in impersonation mode. 
                                        </h5>
                                    </div>
                                ) : (
                                            <div className="mb-3 p-3 d-flex flex-column">
                                                <InputGroup className="mb-3" controlId="date_due">
                                                    <InputGroup.Text id="date-due" className="text-sm" style={{width:'6rem'}}>
                                                        Date Due
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        className="text-sm bg-light"
                                                        placeholder={current_followup.date_due_str}
                                                        id="date-due"
                                                        disabled
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3" controlId="customer_id">
                                                    <InputGroup.Text id="customer-id" className="text-sm" style={{ width: '6rem' }}>
                                                        Customer ID
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        className="text-sm bg-light"
                                                        placeholder={current_followup.customer_id}
                                                        id="customer_id"
                                                        disabled
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3" controlId="followup_remark">
                                                    <InputGroup.Text
                                                        id="followup-remark"
                                                        style={{ width: '6rem' }}
                                                        className=" d-flex flex-column justify-content-start"
                                                    >
                                                        <bdi className="align-self-start text-sm">Remark</bdi>
                                                       
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        placeholder="Required"
                                                        name="remarks"
                                                        aria-label="Followup Remark"
                                                        value={current_followup.remarks}
                                                        aria-describedby="followup-remark"
                                                        className="text-sm"
                                                        as="textarea"
                                                        rows={ 3}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </InputGroup>
                                                <Button
                                                    disabled={current_followup.remarks === '' || this.props.clicked}
                                                    size="sm"
                                                    className="w-50 btn-op70 align-self-center"
                                                    variant={current_followup.remarks === '' || this.props.clicked ? 'outline-success' : 'success'}
                                                    onClick={() => this.props.complete_followup()}
                                                >
                                                    {this.props.clicked ? 'Completing...' : 'Complete'}
                                                </Button>
                                            </div>
                                )}
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>:null
        );
    }
}
