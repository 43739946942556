import React, { Component } from 'react';
import { isSameISOWeek, toDate, addDays, addHours } from 'date-fns';
import * as baseColors from '../Functions/BaseColors';
import {
    Tooltip,
    XAxis,
    YAxis,
    BarChart,
    Bar,
    LabelList,
} from 'recharts';
import { BarGraphToolTip } from './BarGraphToolTip';
import * as svg_icons from '../../Media/svg_exports'

export class NewProspectsGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true,
        };
    }

    get_data() {
        const prospects = this.props.prospects_list ? this.props.prospects_list : [];
        const prospects_this_week = prospects.filter((s) => isSameISOWeek(new Date(s.start_date), new Date()));
        const prospects_last_week = prospects.filter((s) => isSameISOWeek(new Date(s.start_date), addDays(new Date(), -7)));


        const currentHour = new Date().getHours();

        // Keep track of the number of prospects per day (Sunday is index 0).
        const countThisWeekByDay = [0, 0, 0, 0, 0, 0, 0];
        const countLastWeekByDay = [0, 0, 0, 0, 0, 0, 0];

        prospects_this_week.forEach((prospect) => {
            const currentStartDate = addHours(new Date(prospect.start_date), currentHour);
            const dayOfWeek = toDate(currentStartDate).getDay();
            countThisWeekByDay[dayOfWeek]++;
        });

        prospects_last_week.forEach((prospect) => {
            const lastStartDate = addHours(new Date(prospect.start_date), currentHour);
            const dayOfWeek = toDate(lastStartDate).getDay();

            countLastWeekByDay[dayOfWeek]++;
        });

        const returnList = [
            { Day: 'MON', Num: countThisWeekByDay[1], Num_Last: countLastWeekByDay[1] },
            { Day: 'TUE', Num: countThisWeekByDay[2], Num_Last: countLastWeekByDay[2] },
            { Day: 'WED', Num: countThisWeekByDay[3], Num_Last: countLastWeekByDay[3] },
            { Day: 'THU', Num: countThisWeekByDay[4], Num_Last: countLastWeekByDay[4] },
            { Day: 'FRI', Num: countThisWeekByDay[5], Num_Last: countLastWeekByDay[5] },
        ];
        return returnList;
    }

    render() {
        const { open_tile } = this.state;
        const bar_chart_data = this.get_data();


        return (
            <div className="d-flex flex-column justify-content-center w-100 h-100">
                <BarChart
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    width={window.innerWidth * 0.24}
                    height={window.innerHeight * 0.12}
                    data={bar_chart_data}
                    style={{ alignSelf: 'center' }}
                >
                    <XAxis dataKey="Day" className="text-sm" />
                    <YAxis hide={true} />
                    <Tooltip content={<BarGraphToolTip color="primary" />} />
                    <Bar dataKey="Num_Last" fill={baseColors.light_blue}>
                        <LabelList dataKey="Num_Last" position="top" />
                    </Bar>
                    <Bar dataKey="Num" fill={baseColors.dark_blue}>
                        {' '}
                        <LabelList dataKey="Num" position="top" />
                    </Bar>
                </BarChart>
                <div className="d-flex flex-row justify-content-between">
                    <label onClick={() => this.props.update_graph_view('activities')} className='hover-over'>{svg_icons.back_arrow}</label>
                    <label className='text-bold text-med text-primary'>New Prospects</label>
                    <label onClick={() => this.props.update_graph_view('released')} className='hover-over'>{svg_icons.forward_arrow}</label>
                </div>
            </div>
        );
    }
}