import React, { Component } from 'react';
import { MsalContext } from '@azure/msal-react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';

import * as prospectActionFunctions from '../../Functions/ProspectActionFunctions';
import * as commentFunctions from '../../Functions/CommentFunctions';
import * as utilityFunctions from '../../Functions/UtilityFunctions';
import * as customerFunctions from '../../Functions/CustomerFunctions';
import * as svg_icons from '../../../Media/svg_exports'
import { AccountTab } from '../../Tabs/AccountTab';
import { CommentsTab } from '../../Tabs/CommentsTab';
import { ContactsTab } from '../../Tabs/ContactsTab';
import { OrdersTab } from '../../Tabs/OrdersTab';
import { ProspectActionsTab } from '../../Tabs/ProspectActionsTab';
import { CreditTab } from '../../Tabs/CreditTab';
import { format, addDays } from 'date-fns'
import { AuditModal } from '../AuditModal';
import { AccountRequestModal } from './AccountRequestModal';
export class ViewAccountModal extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);

        this.state = {
            view_property: 'prospect_actions',
            account_request_remarks: '',
            requesting_account: false,
            request_account_clicked: false,
            claim_account_clicked: false,
            dnu_remarks: '',
            open_dnu_remarks: false,
            dnu_clicked: false,
            current_account: null,
            new_comment_template: null,
            show_audit: false,
            view_results_list:false
        };
    }

    componentDidMount() {
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const current_user_id = this.props.current_user ? this.props.current_user.user_id:'';
        if (selected_account) {
            this.get_new_comment_template(selected_account.company_id, selected_account.id, current_user_id)
        }
    }

    componentDidUpdate(prevProps, prevState) {
   

        const prev_selected_account_data = prevProps.selected_account_data;
        const selected_account_data = this.props.selected_account_data;
        const current_user_id = this.props.current_user ? this.props.current_user.user_id : '';
        if (prev_selected_account_data && selected_account_data) {
            if (prev_selected_account_data.account && selected_account_data.account) {
                if (prev_selected_account_data.account.id !== selected_account_data.account.id) {
                    this.get_new_comment_template(selected_account_data.account.company_id, selected_account_data.account.id, current_user_id)
                    this.setState({ current_account: selected_account_data.account.id })
                }
            }
          
        }
    }

    update_selected_account_data(property, prop) {
        let selected_account_data = this.props.selected_account_data;
        selected_account_data[property] = prop;
        if (property === 'account') {
            this.update_result_list(prop)
        }
        this.props.update_selected_account_data(selected_account_data)
    }

    async get_new_comment_template(company_id, customer_id, user_id) {
        const empty_comment = await commentFunctions.Get_Template(company_id, customer_id, user_id);
        this.setState({ new_comment_template: empty_comment })
    }

    async claim_account() {
        this.setState({ claim_account_clicked: true })
        const selected_account_data = this.props.selected_account_data
        const current_user = this.props.current_user;
        try {
            
            if (selected_account_data) {              
                let selected_account = selected_account_data.account;
                let prospect_actions = selected_account_data.prospect_actions ? selected_account_data.prospect_actions:[];
                
                const body = {
                    company_id: selected_account.company_id,
                    customer_id: selected_account.id,
                    salesperson_id: current_user.salesperson_id,
                    sales_manager_id: current_user.sales_manager_id
                }
                const new_action = await prospectActionFunctions.Claim(body);
                prospect_actions.push(new_action);

                selected_account.salesperson_id = new_action.salesperson_id;
                selected_account.sales_manager_id = new_action.sales_manager_id;
                selected_account.start_date = format(new Date(), 'MM-dd-yyyy');
                selected_account.mark_plan_id = 'PROS90D1';


                selected_account.salesperson_name = new_action.salesperson_name;
                selected_account.salesperson_is_active = 'Y'
                selected_account.salesperson_phone = new_action.salesperson_phone;
                selected_account.salesperson_email_address = new_action.salesperson_email_address;
                this.update_result_list(selected_account)

                const audit_body = {
                    company_id: selected_account.company_id,
                    customer_id: selected_account.id,
                    changed_by_user: this.props.msal_account.username.replaceAll('@scotlynn.com', ''),
                    initial_values: JSON.stringify({
                        salesperson_id: selected_account.salesperson_id,
                        sales_manager_id: selected_account.sales_manager_id,
                        start_date: selected_account.start_date,
                        mark_plan_id: selected_account.mark_plan_id
                    }),
                    new_values: JSON.stringify({
                        salesperson_id: new_action.salesperson_id,
                        sales_manager_id: new_action.sales_manager_id,
                        start_date: format(new Date(), 'MM-dd-yyyy'),
                        mark_plan_id: 'PROS90D1'
                    })
                }
                let customer_audit = selected_account_data.customer_audit ? selected_account_data.customer_audit : [];
                const audit_data = await customerFunctions.Insert_Customer_Audit(audit_body);
                customer_audit.push(audit_data);
                
                
                if (this.props.role === 'lam' || this.props.role === 'gm') {
                    this.props.add_account(selected_account)
                    this.props.add_users_action(new_action)
                }
                this.props.update_selected_account_data('customer_audit', customer_audit);
                this.props.update_selected_account_data('account', selected_account);
                this.props.update_selected_account_data('prospect_actions', prospect_actions);
               
                this.onHide();
            }
        } catch (e) {
            console.log(e)
            if (selected_account_data) {
                const body = {
                    status: 500,
                    message: `${e.message}`,
                    company_id: selected_account_data.account.company_id,
                    function_name: 'ViewAccountModal -> claim_account',
                    primary_pk: selected_account_data.account.id,
                    table_name: 'customer',
                    user_id: this.props.msal_account.username.replaceAll('@scotlynn.com', '')
                }

                await utilityFunctions.Log_Error(body);
            } else {
                const body = {
                    status: -1,
                    message: `Selected account data is null. ${e.message}`,
                    company_id: current_user.company_id,
                    function_name: 'ViewAccountModal -> claim_account',
                    primary_pk: 'Unknown',
                    table_name: 'customer',
                    user_id: this.props.msal_account.username.replaceAll('@scotlynn.com', '')
                }
                await utilityFunctions.Log_Error(body);
            }
            this.onHide()
        }
    }

    async release_account(remarks) {
        const selected_account_data = this.props.selected_account_data
        const current_user = this.props.current_user;
        try {
            if (selected_account_data) {
                let selected_account = selected_account_data.account;
                let prospect_actions = selected_account_data.prospect_actions;
                const body =
                {
                    company_id: selected_account ? selected_account.company_id : '',
                    customer_id: selected_account ? selected_account.id : '',
                    salesperson_id: current_user ? current_user.salesperson_id : '',
                    sales_manager_id: current_user ? current_user.sales_manager_id : '',
                    remarks:remarks
                }
                const new_action = await prospectActionFunctions.Release(body);
                if (new_action) {
                    prospect_actions.push(new_action);                   
                }
                const audit_body = {
                    company_id: selected_account.company_id,
                    customer_id: selected_account.id,
                    changed_by_user: this.props.msal_account.username.replaceAll('@scotlynn.com', ''),
                    initial_values: JSON.stringify({
                        salesperson_id: selected_account.salesperson_id,
                        sales_manager_id: selected_account.sales_manager_id,
                        start_date: selected_account.start_date,
                        mark_plan_id: selected_account.mark_plan_id
                    }),
                    new_values: JSON.stringify({
                        salesperson_id: 'UNCLAIMD',
                        sales_manager_id: '',
                        start_date: format(new Date(), 'MM-dd-yyyy'),
                        mark_plan_id: 'RESET'
                    })
                }
                let customer_audit = selected_account_data.customer_audit ? selected_account_data.customer_audit:[];
                const audit_data = await customerFunctions.Insert_Customer_Audit(audit_body);
                customer_audit.push(audit_data);
                

                selected_account.salesperson_id = 'UNCLAIMD';
                selected_account.salesperson_name = 'Unclaimed';
                selected_account.salesperson_is_active = 'Y'
                selected_account.sales_manager_id = '';
                selected_account.start_date = format(new Date(), 'MM-dd-yyyy');
                selected_account.mark_plan_id = 'RESET';
                selected_account.salesperson_phone = '';
                selected_account.salesperson_email_address = '';
                this.update_result_list(selected_account)

                if (this.props.role === 'lam' || this.props.role === 'gm' ) {
                    this.props.release_account(selected_account)
                    this.props.add_users_action(new_action)
                }

                this.props.update_selected_account_data('account', selected_account);
                this.props.update_selected_account_data('prospect_actions', prospect_actions);
                this.props.update_selected_account_data('customer_audit', customer_audit);
                this.onHide()
            }
        } catch (e) {
            if (selected_account_data) {
                const body = {
                    status: 500,
                    message: `${e.message}`,
                    company_id: selected_account_data.account.company_id,
                    function_name: 'ViewAccountModal -> release_account',
                    primary_pk: selected_account_data.account.id,
                    table_name: 'customer',
                    user_id: this.props.msal_account.username.replaceAll('@scotlynn.com', '')
                }

                await utilityFunctions.Log_Error(body);
            } else {
                const body = {
                    status: -1,
                    message: `Selected account data is null. ${e.message}`,
                    company_id:current_user.company_id,
                    function_name: 'ViewAccountModal -> release_account',
                    primary_pk: 'Unknown',
                    table_name: 'customer',
                    user_id: this.props.msal_account.username.replaceAll('@scotlynn.com', '')
                }
                await utilityFunctions.Log_Error(body);
            }
            this.onHide()
        }
       
       
        
   
       //this.onHide()
    }

    onHide() {
        this.setState({
            view_property: 'prospect_actions',
            account_request_remarks: '',
            requesting_account: false,
            request_account_clicked: false,
            claim_account_clicked: false,
            dnu_remarks: '',
            open_dnu_remarks: false,
            dnu_clicked: false
        })
        this.props.onHide();
    }
   


    update_result_list(account) {
        if (this.props.accounts_list) {
            let result_list = this.props.accounts_list
            const index = result_list.findIndex(s => s.id === account.id);
            result_list[index] = account
            this.props.update_accounts_list(result_list)
        }
 
    }

    async send_email(recipients, subject_line, body_text) {
        const current_user = this.props.current_user
        const email_company_id = current_user.company_id === 'TMS2' ? 'SLUSA' : 'SLC';
        const body = {
            message: {
                subject: `${email_company_id} ${process.env.REACT_APP_EMAIL_TITLE}: ${subject_line}`,
                body: {
                    contentType: 'Text',
                    content: body_text,
                },
                toRecipients: recipients,
            },
        };
        const inst = this.context.instance;
        const acct = this.context.accounts;
        await utilityFunctions.Send_Email(inst, acct, body);
    }

    async set_dnu_status() {
        this.setState({ dnu_clicked: true })
        const current_user = this.props.current_user;
        const selected_account_data = this.props.selected_account_data;
        try {
           
            let selected_account = selected_account_data.account;
            const status =selected_account.crm_do_not_use === 'Y' ? 'N' : 'Y';
            let prospect_actions = selected_account_data.prospect_actions;
            const body = {
                company_id: selected_account.company_id,
                customer_id: selected_account.id,
                salesperson_id:current_user.salesperson_id,
                remarks: this.state.dnu_remarks,
                status:status
            }
            const new_action = await prospectActionFunctions.DNU(body)
            selected_account.crm_do_not_use = status;
            
            prospect_actions.push(new_action);
            this.props.update_selected_account_data('account', selected_account);
            this.props.update_selected_account_data('prospect_actions', prospect_actions);
            
            this.setState({
                dnu_remarks: '',
                open_dnu_remarks: false,
                dnu_clicked: false
            })
        } catch (e) {
            console.log(e)
            if (selected_account_data) {
                const body = {
                    status: 500,
                    message: `${e.message}`,
                    company_id: selected_account_data.account.company_id,
                    function_name: 'ViewAccountModal -> set_dnu_status',
                    primary_pk: selected_account_data.account.id,
                    table_name: 'customer',
                    user_id: this.props.msal_account.username.replaceAll('@scotlynn.com', '')
                }

                await utilityFunctions.Log_Error(body);
            } else {
                const body = {
                    status: -1,
                    message: `Selected account data is null. ${e.message}`,
                    company_id: current_user.company_id,
                    function_name: 'ViewAccountModal -> set_dnu_status',
                    primary_pk: 'Unknown',
                    table_name: 'customer',
                    user_id: this.props.msal_account.username.replaceAll('@scotlynn.com', '')
                }
                await utilityFunctions.Log_Error(body);
            }
            this.setState({
                dnu_remarks: '',
                open_dnu_remarks: false,
                dnu_clicked: false
            })
        }
    }

    get_claim_button() {
        const claim_account_clicked = this.state.claim_account_clicked
        const prospects_num = this.props.prospects ? this.props.prospects.list ? this.props.prospects.list.length : 0 : 0;
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const prospect_actions = selected_account_data ? Array.isArray(selected_account_data.prospect_actions) ? selected_account_data.prospect_actions.filter(s => s.activity_id === 'RELEASE' && s.status === 'C') : [] : [];
        const requests = selected_account_data.requests ? selected_account_data.requests : [];
        const current_user = this.props.current_user;

        if (selected_account && current_user) {
            const release_action = prospect_actions.find(s => s.salesperson_id.toUpperCase() === current_user.salesperson_id.toUpperCase() && new Date(s.action_date_str) >= addDays(new Date(), -31));
            const pending_request = requests.find(s=>s.request_type==='ACCTREQ' && s.status==='P')
            if (prospects_num < 50 &&
                !release_action &&
                !pending_request &&
                selected_account.salesperson_id === 'UNCLAIMD' &&
                selected_account.crm_do_not_use !== 'Y' &&
                selected_account.type_of !== 'Customer' &&
                selected_account.company_id === current_user.company_id) {
                return(<Button
                    className="text-sm w-25"
                    variant={claim_account_clicked ? 'outline-success' : 'success'}
                    size="sm"
                    onClick={() => this.claim_account()}
                    disabled={claim_account_clicked}>
                    {claim_account_clicked ? 'Claiming...' : 'Claim'}
                </Button>)
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    get_account_request_button() {
        const request_account_clicked = this.state.request_account_clicked;
        const selected_account_data = this.props.selected_account_data;
        const prospects_num = this.props.prospects ? this.props.prospects.list? this.props.prospects.list.length:0 : 0;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const pending_request = selected_account_data ? selected_account_data.requests ? selected_account_data.requests.find(s => s.request_type === 'ACCTREQ' && s.status === 'P') : [] : [];
        const current_user = this.props.current_user ? this.props.current_user : null;

        const req_button = <Button
            className="text-sm w-25" disabled={request_account_clicked} variant='secondary' size="sm" onClick={() => this.setState({ requesting_account: true })}>
            Request Account
        </Button>

        if (pending_request) {
            return (<Button
                className="text-sm w-25" disabled={request_account_clicked} variant='secondary' size="sm" disabled={ true}>
                {`Pending Account Request`}
            </Button>)
        } else {

            if (selected_account && current_user) {
                if (prospects_num < 50 &&
                    selected_account.salesperson_id !== current_user.salesperson_id &&
                    selected_account.salesperson_id !== 'UNCLAIMD' &&
                    selected_account.crm_do_not_use !== 'Y' &&
                    (selected_account.type_of === 'Dormant' || selected_account.type_of === 'Prospect') &&
                    selected_account.company_id === current_user.company_id) {
                    return req_button;
                } else {
                    return null;
                }

            } else {
                return null;
            }
        }



    }

    render_tab(tab, title) {
        return (                   
            <Button variant={this.state.view_property === tab
                            ? `secondary`
                            : `outline-secondary`
                    }
                className="portal-tile-title text-nowrap flex-grow-1"
                style={{ borderRadius: '0%', height:'2rem' }}
                onClick={() => this.setState({ view_property: tab })}>
                    {title}
                </Button>
        );
    }
    
    is_do_not_use(selected_account) {
        if (selected_account) {
            if (selected_account.crm_do_not_use === 'Y' || selected_account.is_active === 'N' || selected_account.salesperson_id === 'DONOTUSE') {
                return true;
            } else {
                return false;
            }
        }
      
    }

    view_account(d) {
        if (d.__type === 'request') {
            
            this.props.view_account({ company_id: d.company_id, id: d.customer_id })
        } else {
            this.props.view_account({ company_id: d.company_id, id: d.id })
        }
    }

    render() {
        const {
            view_property,
            dnu_clicked,
            dnu_remarks,
            new_comment_template,
            show_audit,
            view_results_list,
            requesting_account
            
        } = this.state;
        const commodities = this.props.commodities;
        const current_user = this.props.current_user;
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const orders = selected_account_data ? selected_account_data.orders : [];
        const accounts_list = this.props.accounts_list ? this.props.accounts_list : [];
        const index = accounts_list && selected_account ? accounts_list.findIndex(s => (s.id === selected_account.id || s.customer_id === selected_account.id) && s.company_id === selected_account.company_id) : 0;

        return (
            selected_account ?
             
                    <Modal
                    {...this.props}
                    size="xl"
                    fullscreen={true}
                    contentClassName="light-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    className='w-100 h-100 bg-white'
                    centered
                  
                    >
                    <Modal.Header className={`w-100 p-0 d-flex flex-row m-0 justify-content-between ${this.is_do_not_use(selected_account) ? 'do-not-use' : ''}`}>
                  
                        <div className="flex-grow-2 p-2 align-self-start">
                                <h5 onClick={() => this.onHide()} className="opacity-75 hover-over p-0 m-0">
                                    X
                                </h5>
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-grow-1">
                            <div className="d-flex flex-row justify-content-center w-100">
                                {accounts_list.length && index > 0 ?
                                    <label className="hover-over me-3 align-self-center" onClick={() => this.view_account(accounts_list[index - 1])}>{svg_icons.left_chevron}</label> :
                                    <label className="me-3 p-1"></label>
                                }
                                <h5 className="text-center p-0 pt-2">  {`${selected_account.name}`}</h5>


                                {accounts_list.length && index < accounts_list.length - 1 ?
                                    <label className="hover-over ms-3 align-self-center" onClick={() => this.view_account(accounts_list[index + 1])}>{svg_icons.right_chevron}</label> :
                                    <label className="me-3 p-1"></label>
                                }
                            </div>
                               
                        
                                <h6 className="w-100 text-center opacity-75 text-sm">{selected_account.company_id === 'TMS2' ? 'SLUSA' :'SLC' }</h6>
                            {this.is_do_not_use(selected_account) ?
                                <h6 className="w-100 text-center text-danger">**{selected_account.salesperson_id === 'INACTIVE' || selected_account.is_active==='N'?'INACTIVE':'DO NOT USE'}**</h6> :
                                null
                            }
                            </div>

                            <div className="flex-grow-2"></div>
                    </Modal.Header>
                    <Modal.Body className={`d-flex flex-row justify-content-start w-100 p-0`}>
                        {view_results_list ?
                            <div className="d-flex flex-column border">
                                <div className="d-flex flex-row justify-content-between mb-2">
                                    <label className="text-xs text-bold pt-2 px-1 text-nowrap">Results List: ({accounts_list.length})</label>
                                    <Button
                                        className="p-1 border-0 text-dark pt-2"
                                        onClick={() => this.setState({ view_results_list: false })}
                                        variant='outline-light'
                                        style={{ borderRadius: '0%' }}>
                                        {svg_icons.down_chevron}
                                    </Button>
                                </div>
                                {accounts_list.map((d, index) =>
                                    <label
                                        onClick={() => this.view_account(d)}
                                        key={index}
                                        className={`px-1 text-xs hover-over text-nowrap ${selected_account.id === d.id || selected_account.id === d.customer_id ? 'bg-warning-yellow' : ''} ${index % 2 !== 0 ? 'alternate-container-row' : ''}`}>
                                        {d.__type === 'request' ? d.customer_name : d.name}
                                    </label>
                                )}
                            </div> :
                            null
                        }
                       

                        
                        <div className={`d-flex flex-column justify-content-start flex-grow-1 p-0 ${this.is_do_not_use(selected_account) ? 'do-not-use' : ''}`} style={{ overflow: 'auto' }}>

                            <div className="d-flex flex-row w-100 align-self-center mb-3" style={{ borderRadius: '0%' }}>
                                {!view_results_list ?
                                    <Button
                                        className="p-1"
                                        id="results-list-chevron"
                                        onClick={() => this.setState({ view_results_list:true })}
                                        variant='dark'
                                        style={{ borderRadius: '0%' }}>
                                        {view_results_list ? svg_icons.down_chevron : svg_icons.left_chevron}
                                </Button> : null}
                                {this.render_tab('prospect_actions', `Activities (${selected_account_data.prospect_actions.length})`)}
                                {this.render_tab('comments', `Comments (${selected_account_data.comments.length})`)}
                                {this.render_tab('contacts', `Contacts  (${selected_account_data.contacts.length})`)}
                                {this.render_tab('credit', `Credit`)}
                                {this.render_tab('orders', `Orders  (${selected_account_data.orders.length})`)}
                                {this.render_tab('account', `Overview`)}
                            </div>

                            {this.props.role === 'gm' ||
                                this.props.role === 'admin' ||
                                this.props.role === 'credit' ?
                                <div className="w-75 align-self-center mb-3">
                                    <InputGroup id="do-not-use-form">
                                        <InputGroup.Text className="text-sm">Switch DNU Status</InputGroup.Text>
                                        <Form.Control value={dnu_remarks} onChange={(e) => this.setState({ dnu_remarks: e.target.value })} className="text-sm" disabled={dnu_clicked} placeholder="Remark required" />
                                        <Button className="text-sm" variant={dnu_clicked ? 'outline-success' : 'success'} disabled={dnu_clicked || !dnu_remarks} onClick={() => this.set_dnu_status()}>Switch Status</Button>
                                    </InputGroup>
                                </div>
                                :
                                null
                            }



                            <div className={`d-flex flex-column align-self-center ${view_property === 'account'?'w-100':'w-75'}`}>
                                {view_property === 'account' ?
                                    <AccountTab
                                        selected_account_data={selected_account_data}
                                        current_user={current_user}
                                        msal_account={this.props.msal_account}
                                        update_selected_account_data={(type,prop) => this.update_selected_account_data(type, prop)}      
                                        commodities={commodities}
                                        categories = { this.props.categories}
                                        salesperson_list={this.props.salesperson_list}
                                        role={this.props.role}
                                    /> : null}
                                {view_property === 'comments' ?
                                    <CommentsTab
                                        new_comment_template={new_comment_template}
                                        selected_account_data={selected_account_data}
                                        msal_account={this.props.msal_account}
                                        current_user={this.props.current_user}
                                        role={this.props.role}
                                        update_selected_account_data={(type, prop) => this.update_selected_account_data(type, prop)} 
                                    /> :
                                    null}
                                {view_property === 'contacts' ?
                                    <ContactsTab
                                        selected_account_data={selected_account_data}
                                        update_selected_account_data={(type, prop) => this.update_selected_account_data(type, prop)} 
                                    /> :
                                    null}
                                {view_property === 'orders' ?
                                    <OrdersTab
                                        orders={orders}
                                        role={this.props.role}
                                    /> :
                                    null}
                                {view_property === 'prospect_actions' ?
                                    <ProspectActionsTab
                                        current_user={this.props.current_user}
                                        selected_account_data={selected_account_data}
                                        msal_account={this.props.msal_account}
                                        role={this.props.role}
                                        salesperson_list={this.props.salesperson_list}
                                        update_selected_account_data={(property, prop) => this.update_selected_account_data(property, prop)}
                                        add_users_action={(prop) => this.props.add_users_action(prop)}
                                        update_users_action={(prop) => this.props.update_users_action(prop)}
                                        add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                                        update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                                        release_account={(prop) => this.release_account(prop)}
                                        add_account={ (prop)=>this.props.add_account(prop)}
                                    /> :
                                    null}
                                {view_property === 'credit' ?
                                    <CreditTab
                                        current_user={this.props.current_user}
                                        msal_account={this.props.msal_account}
                                        role={this.props.role}
                                        selected_account_data={selected_account_data}
                                        update_selected_account_data={(property, prop) => this.update_selected_account_data(property, prop)}          
                                    /> :
                                    null}


                            </div>
                            <AuditModal
                                show={show_audit}
                                onHide={() => this.setState({
                                    audit_log: null,
                                    show_audit: false,
                                    audit_clicked: false
                                })}
                                title={selected_account ? `Audit Log: ${selected_account.name}` : ''}
                                audit_log={selected_account_data.customer_audit ? selected_account_data.customer_audit : []}
                            />
                            <AccountRequestModal
                                show={requesting_account}
                                onHide={() => this.setState({ requesting_account: false })}
                                selected_account_data={selected_account_data}
                                salesperson_list={this.props.salesperson_list}
                                current_user={this.props.current_user}
                                add_users_request={(type, request) => this.props.add_users_request(type, request)}
                                update_selected_account_data={(property, prop) => this.update_selected_account_data(property, prop)}
                            />
                        </div>
                 
                     

                        </Modal.Body>
                    <Modal.Footer className="d-flex flex-row justify-content-between">
                  <div></div>
                   
                        {this.get_claim_button()}
                        {this.get_account_request_button()}
                       
                        <label className="hover-over pe-2" id='audit-log-clock' title='Audit Log' onClick={() => this.setState({ show_audit: true })}><div style={{ transform: 'scale(1.2)' }}>{svg_icons.clock}</div></label>
                    </Modal.Footer>
                 
                    </Modal>
               : null
        );
    }
}



/**
 * show
 * onHide
 * current_user
 * msal_account
 * role
 * width
 * accounts_list
 * update_accounts_list(list)
 * view_account(account)
 * selected_account
 * update_selected_account_data(select_account_data)
 * commodities
 * salesperson_list
 * add_account(selected_account)
 * release_account(selected_account)
 * update_users_request(type,list)
 * update_users_actions(list)

 */


