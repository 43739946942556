import * as utilityFunctions from './UtilityFunctions'


export async function Get_All() {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`Salesperson/GetAll`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Commission_Salespersons() {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`Commission/GetCommissionSalespersons`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Salesperson_Roles() {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`Commission/GetCommissionSalespersonRoles`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Login(company_id, login_id, token) {
    try {
        const options = { method: 'GET', headers: { Authorization: `Bearer ${token}` } };
        const response = await fetch(`Salesperson/GetSalespersonLogin/${company_id}/${login_id}`, options);
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
       // window.location.reload();
        console.log(e);
        return null;
    }
}


export async function Get_Expiring_By_Salesperson(company_id, salesperson_id) {
    try {
        const options = { method: 'GET', headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } };
        const response = await fetch(`Salesperson/GetExpiringBySalesperson/${company_id}/${salesperson_id}`, options);
        const data = await response.json();
       return data
    } catch (e) {

        console.log(e);
        return [];
    }
}


export async function Get_STC_Dashboard(company_id, salesperson_id) {
    try {
        const options = { method: 'GET', headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } };
        const response = await fetch(`Salesperson/GetSTCDashboard/${company_id}/${salesperson_id}`, options);
        const data = await response.json();
        return data
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_User_By_ID(company_id, user_id) {
    try {
        const options = { method: 'GET', headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } };
        const response = await fetch(`User/GetByUserID/${company_id}/${user_id}`, options);
        const data = await response.json();
        if (data.length > 0) {
            return data[0]
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Template() {
    try {
        const options = { method: 'GET', headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } };
        const response = await fetch(`Commission/GetSalespersonTemplate`, options);
        const data = await response.json();
        return data;

    } catch (e) {
        console.log(e);
        return null;
    }
}