const get_options_w_token = (token) => {
    return {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    }
};

export async function Get() {
    try {
        const response = await fetch(`RevenueCode`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}


export async function Add_Revenue_Code(code) {
    try {
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(code),
        };
        const response = await fetch(`RevenueCode`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}