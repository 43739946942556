import { useMsal } from '@azure/msal-react';
//#region Imports
import React from 'react';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AddNewProspect } from '../components/AddProspect/AddNewProspect';
import { Commissions } from '../components/Commisions';
import { TimeoutSession } from '../components/Modals/TimeoutSession';
import { CreditPortal } from '../components/Portals/CreditPortal';
import { Salespersons } from '../components/Salespersons';
import { Reports } from '../components/Reports';
import { SearchProspects } from '../components/SearchPage/SearchProspects';
import useIdleTimeout from './idleTimeout';
import { Userpilot } from 'userpilot';
import { clarity } from 'react-microsoft-clarity';
//#endregion Imports

export const CreditRoutes = ({ ...props }) => {
    const { instance, accounts } = useMsal();
    const [openTimeout, setOpenTimeout] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    //#region Idle Timer
    const handleIdle = () => {
        setOpenTimeout(true);
        instance.logoutRedirect().catch((e) => {
            localStorage.clear();
            console.error(e);
        });
    };
    const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 1 });
    const stay = () => {
        setOpenTimeout(false);
        idleTimer.reset();
    };
    const handleLogout = () => {
        //logout()

        setOpenTimeout(false);
    };
    //#endregion Idle Timer


    //#region Routes
    const creditPortal = (
        <CreditPortal
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            width={width}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()}
        />
    );

    const addNewProspect = (
        <AddNewProspect
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            width={width}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()}
        />
    );

    const searchPage = (
        <SearchProspects
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            width={width}
            user_routes={props.user_routes}
            salesperson_list={props.salesperson_list}
            commodities={props.commodities}
            categories={props.categories}
            prospects={props.prospects}
            customers={props.customers}
            add_account={(prop) => props.add_account(prop)}
            release_account={(prop) => props.release_account(prop)}
            prospect_actions={props.prospect_actions}
            add_users_action={(prop) => props.add_users_action(prop)}
            update_users_action={(prop) => props.update_users_action(prop)}
            incoming_requests={props.incoming_requests}
            outgoing_requests={props.outgoing_requests}
            no_action_requests={props.no_action_requests}
            action_requests={props.action_requests}
            update_users_request={(type, prop) => props.update_users_request(type, prop)}
            add_users_request={(type, prop) => props.add_users_request(type, prop)}
            open_report_issue={() => props.open_report_issue()}
        />
    );

    const commissionsPage = (
        <Commissions
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            width={width}
            salesperson_list={props.salesperson_list}
        />
    );

    const salespersonPage = (
        <Salespersons
            msal_account={props.msal_account}
            salesperson={props.salesperson}
            role={props.role}
            width={width}
            salesperson_list={props.salesperson_list}
        />
    );

    const reports = (
        <Reports
            salesperson={props.salesperson}
            msal_account={props.msal_account}
            role={props.role}
            width={width}
        />
    );


    let routes = [
        {
            path: '/',
            element: creditPortal,
        },
        {
            path: '/dashboard',
            element: creditPortal,
        },
        {
            path: '/dashboard/:id',
            element: creditPortal,
        },
        {
            path: '/addnewprospect',
            element: addNewProspect,
        },
        {
            path: '/search',
            element: searchPage,
        },

        {
            path: '/salespersons',
            element: salespersonPage,
        },
        {
            path: '/reports',
            element: reports,
        },
        {
            path: '/commissions',
            element: commissionsPage,
        }
    ];
    //#endregion Routes


    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        try {
            if (props.msal_account && props.salesperson && process.env.REACT_APP_START_CLARITY === 'Y') {
                clarity.init(process.env.REACT_APP_CLARITY);
                if (clarity.hasStarted()) {
                    clarity.identify('user_id', { userProperty: props.msal_account.username.replaceAll('@scotlynn.com', '') });
                    clarity.consent();
                    clarity.setTag('Name', props.msal_account.name);
                    clarity.setTag('Role', props.role);
                }
       
                Userpilot.identify(props.msal_account.username, {
                    name: props.msal_account.name,
                    email: props.msal_account.username,
                    role: 'Credit',
                    rev_code: 'Credit',
                    company: {
                        id: props.salesperson.company_id,
                        name: props.salesperson.company_id === 'TMS' ? 'SLC' : 'SLUSA',
                    },
                });
                Userpilot.reload();
            }

        } catch (e) {
            console.log(e)
        }
        
    }, []);

    return (<div className="d-flex flex-column justify-content-between w-100">
        <div className="d-flex flex-column justify-content-start w-100">
            <Routes>
                {routes.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} element={element} />;
                })}
                <Route path="*" element={<Navigate to="/dashboard" />}></Route>
            </Routes>

            <TimeoutSession show={openTimeout} onHide={() => setOpenTimeout(false)} />
        </div>
    </div>)
};
