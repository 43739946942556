import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import LoadingIcons from 'react-loading-icons';

export class ViewObjectModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            additional_details: '',
        };
    }

    render() {
        const { additional_details } = this.state;

        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen="md-down"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                        <div style={{ width: '10rem' }}>
                            <Button
                                variant="outline-secondary"
                                onClick={() => this.props.onHide()}
                                className="btn-op70"
                            >
                                X
                            </Button>
                        </div>
                        <h4 className="w-100 text-center p-0 pt-2">
                            {this.props.show_key ? this.props.show_key.toUpperCase() : ''}
                        </h4>
                        <div style={{ width: '10rem' }}></div>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.show_object ? (
                            <Container>
                                <Row>
                                    {Object.keys(this.props.show_object).map((key) => (
                                        <Col>
                                            <div
                                                className="d-flex flex-row justify-content-between border p-1 bg-white rounded"
                                                style={{ width: '20rem' }}
                                            >
                                                <label className="text-nowrap text-sm p-1 text-bold">{key}:</label>
                                                <Form.Control
                                                    value={this.props.show_object[key]}
                                                    className="border-0 outline-0 text-sm p-1"
                                                    disabled={true}
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        ) : null}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
