import React, { Component } from 'react';
import { Container, Row, Col, Card, Alert, Form, InputGroup, Button, FormCheck, ButtonGroup } from 'react-bootstrap';

export class ReportsSearch extends Component {
    static displayName = ReportsSearch.name;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: '',
            sort_field: '',
            edit_field: '',
        };
    }

    render() {
        const { sort_direction, sort_field, edit_field } = this.state;

        //#region Search Container
        const search_container = (
            <Container fluid="xs">
                <Row>
                    <Col>
                        <InputGroup className="p-0 border rounded mb-3">
                            <div className="d-flex flex-column p-1 w-25">
                                <small className="text-sm opacity-75">Search By</small>
                                <Form.Select className="p-1 border-0 outline-0 text-sm w-100 shadow-0">
                                    <option>Salesperson</option>
                                </Form.Select>
                            </div>

                            <Form.Control className="p-1 border-0 outline-0" />
                            <Button className="btn-op70" variant="success">
                                Go
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex flex-column p-1">
                            <FormCheck label="SLUSA" />
                            <FormCheck label="SLC" />
                            <FormCheck label="Paid" />
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex flex-column">
                            <FormCheck label="Live" />
                            <FormCheck label="Term'd" />
                            <FormCheck label="Unpaid" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div className="d-flex flex-column p-1 w-25 border w-100 rounded">
                            <small className="text-sm opacity-75">Pay Period</small>
                            <Form.Control type="date" className="p-1 border-0 outline-0 text-sm w-100 shadow-0" />
                        </div>
                    </Col>
                </Row>
            </Container>
        );

        //#endregion Search Container

        return <div>{search_container}</div>;
    }
}
