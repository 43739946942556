import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import * as customerFunctions from '../../Functions/CustomerFunctions';
import * as prospectActionFunctions from '../../Functions/ProspectActionFunctions';
import * as utilityFunctions from '../../Functions/UtilityFunctions';
import { format } from 'date-fns'
import * as svg_icons from '../../../Media/svg_exports'
import { MsalContext } from '@azure/msal-react';

export class SendpackModal extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);

        this.state = {
            clicked: false,
            selected_contact: '',
            selected_date:''
        };
    }




    async post() {
        this.setState({ clicked: true })
        const new_action = 
         {
            company_id: this.props.selected_account ? this.props.selected_account.company_id : '',
            customer_id: this.props.selected_account ? this.props.selected_account.id : '',
            salesperson_id: this.props.current_user ? this.props.current_user.salesperson_id : '',
            remarks: `Pack was sent to ${this.state.selected_contact} on ${utilityFunctions.format_date_from_input(this.state.selected_date)}`,
            action_date: this.state.selected_date
        }

        await prospectActionFunctions.Sendpack(new_action).
            then((res) => {
                let prospect_actions = this.props.prospect_actions;
               this.props.add_users_action(res)
                prospect_actions.push(res);
                this.props.update_selected_account_data('prospect_actions',prospect_actions);
                this.setState({
                    clicked: false,
                    selected_contact: '',
                    selected_date: ''
                })
                this.props.onHide();
            })
      
    }


    render() {
        const {clicked, selected_contact, selected_date } = this.state;
        const selected_account = this.props.selected_account;
        let prospect_actions = this.props.prospect_actions ? this.props.prospect_actions.filter(s =>s.activity_id==='SENDPACK').sort((b, a) => new Date(a.action_date_str) - new Date(b.action_date_str)) : [];
        let last_sendpack = prospect_actions.length > 0 ? prospect_actions[0] : null
        let contacts = this.props.contacts ? this.props.contacts.filter(s=>s.email) : [];


        const recipient = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold w-25">
                Recipient
            </InputGroup.Text>
            <Form.Select
                className="text-sm bg-white text-start"
                value={selected_contact}
                onChange={(e) => this.setState({ selected_contact: e.target.value })}>
                <option value=''>Select Contact</option>
                {contacts.map(d =>
                    <option value={d.name}>{d.name}</option>
                )}
            </Form.Select>
        </InputGroup>

        const date_sent = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold w-25">
               Date Sent
            </InputGroup.Text>
            <Form.Control
                className="text-sm bg-white text-start"
                max={format(new Date(),'yyyy-MM-dd')}
                type="date"
                value={selected_date}
                onChange={(e) => this.setState({ selected_date: e.target.value })} />

        </InputGroup>

        const additional_remarks = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold w-25">
                Remarks
            </InputGroup.Text>
            <InputGroup.Text className="text-wrap text-sm bg-white p-1 text-bold pe-3 w-75">
                {'Pack was sent'}
                {selected_contact ? ` to ${selected_contact}` : null}
                {selected_date ? ` on ${utilityFunctions.format_date_from_input(selected_date)}` : null}
            </InputGroup.Text>
        </InputGroup>



        return (
            <Modal
                {...this.props}
                size="xl"
                className="bg-modal-focus"
                contentClassName="light-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                    <label className="hover-over w-25" onClick={() => this.props.onHide()}>{svg_icons.cancel_icon}</label>
                    <div className="d-flex flex-column justify-content-center w-50 text-center p-0 pt-2">
                        <h5 className="">SENDPACK</h5>

                    </div>

                    <div className="w-25"></div>
                </Modal.Header>
                {selected_account ?
                    <Modal.Body className="d-flex flex-column">
                        <h6 className='align-self-center tile-title'>Complete SENDPACK activity for {selected_account.name}</h6>
                        {last_sendpack ?
                            <small className="align-self-center mb-2">{`A welcome packet was previously sent on ${last_sendpack.action_date_str} by ${last_sendpack.salesperson_name}`}</small>
                            : null}
                        <div className="d-flex flex-column w-50 align-self-center mt-2">
                            {contacts.length === 0 ? null:recipient}
                            {contacts.length === 0 ?
                                <Alert variant="danger" className="w-100">
                                    <h6 className="text-center text-danger">Activity cannot be completed</h6>
                                <hr className="m-0"/>
                                    <small className="text-danger my-3 ">There are no email addresses saved for this account.
                                        Please create a contact with an email address to complete this activity.</small>
                                </Alert> :
                                null}
                            {contacts.length === 0 ? null : date_sent}
                            {contacts.length === 0 ? null : additional_remarks}
                            {contacts.length === 0 ? null :
                                <Button
                                    className="text-sm w-25 align-self-center mt-3 mb-3"
                                    disabled={!selected_contact || !selected_date}
                                onClick={() => this.post()}
                                variant={clicked ? 'outline-success' : 'success'} >
                                {clicked ? 'Saving...' : 'Save'}

                            </Button>}
                       
                        </div>
                    </Modal.Body> :
                    null
                }




            </Modal>

        )
    }



}

