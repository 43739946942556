import * as prospectActionFunctions from '../Functions/ProspectActionFunctions';
import * as requestFunctions from '../Functions/RequestFunctions';
import * as contactFunctions from '../Functions/ContactFunctions';
import * as commentFunctions from '../Functions/CommentFunctions';
import * as orderFunctions from '../Functions/OrderFunctions';


const get_options_w_token=(token) => {
    return {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    }
};

const put_options = (body) => {
    return {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    }
}

export async function Get_By_ID(company_id, customer_id) {
    try {
        const response = await fetch(`Customer/GetByID/${company_id}/${customer_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();

        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}
export async function Get_My_Accounts(company_id, salesperson_id) {
    try {
        const response = await fetch(`Customer/MyAccounts/${company_id}/${salesperson_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}
export async function Get_Categories() {
    try {
        const response = await fetch(`Customer/GetCategories`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}


export async function Credit_App_Uploaded(company_id, customer_id) {
    try {
        const response = await fetch(`Customer/CreditAppUploaded/${company_id}/${customer_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Info_Update(customer) {
    try {
        const response = await fetch('Customer/InfoUpdate', put_options(customer));
        
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Change_Start_Date(customer) {
    try {
        const response = await fetch('Customer/ChangeStartDate', put_options(customer));

        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}
export async function Get_By_Entered_Date(customer) {
    try {
        const response = await fetch(
            `Customer/ByEnteredDate`,
            put_options(customer),
        );
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Add_Customer(customer) {
    try {
        const response = await fetch(
            `Customer/AddCustomer`,
            put_options(customer),
        );
        const data = await response.json();
        return data;
     
    } catch (e) {
        console.log(e);
        return null;
    }
}
export async function Get_Random_Unclaimed_List(company_id) {
    try {
        const response = await fetch(`Customer/RandomUnclaimed/${company_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}
export async function Possible_Matches(customer) {
    try {
        const response = await fetch(`Customer/PossibleMatches`, put_options(customer));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Hot_Prospects(company_id) {
    try {
        const response = await fetch(`Customer/HotProspects/${company_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function SearchByRemark(company_id, remarks) {
    try {
        const body = { company_id: company_id, remarks: remarks }
        const options = {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        };
        
        const response = await fetch('Customer/SearchByRemark', options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_By_Phone(company_id, phone) {
    try {
        console.log(`company_id: ${company_id}`)
        console.log(`phone: ${phone}`)
        const response = await fetch(`Customer/GetByPhone/${company_id}/${phone}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Template(company_id, salesperson_id, sales_manager_id) {
    try {
        const response = await fetch(`Customer/GetTemplate/${company_id}/${salesperson_id}/${sales_manager_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

//#region Customer Audit Functions
export async function Insert_Customer_Audit(prop) {
    try {
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(prop),
        };
        const response = await fetch('CustomerAudit', options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}
export async function Get_Audit_By_Date(start_date, end_date) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`CustomerAudit/GetByDate/${start_date}/${end_date}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Get_Audit_By_ID(company_id, id) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`CustomerAudit/GetByID/${company_id}/${id}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}
//#endregion Customer Audit Functions


export async function View_Account(company_id, customer_id) {
    try {
        const account = await Get_By_ID(company_id, customer_id);
        const prospect_actions = await prospectActionFunctions.Get_By_Customer(company_id, customer_id);
        const requests = await requestFunctions.Get_By_Customer(company_id, customer_id);
        const contacts = await contactFunctions.Get_By_Customer(company_id, customer_id);
        const comments = await commentFunctions.Get_By_Customer(company_id, customer_id);
        const orders = await orderFunctions.Get_By_Customer(company_id, customer_id);
        const comment_audit = await commentFunctions.Get_Audit_By_ID(company_id, customer_id);
        const customer_audit = await Get_Audit_By_ID(company_id, customer_id);

        return {
            account: account,
            prospect_actions: prospect_actions,
            requests: requests,
            contacts: contacts,
            comments: comments,
            orders: orders,
            comment_audit: comment_audit,
            customer_audit: customer_audit
        }
    } catch (e) {
        return {
            account: null,
            prospect_actions: [],
            requests: [],
            contacts: [],
            comments: [],
            orders: [],
            comment_audit: [],
            customer_audit: []
        }
    }
  
}






