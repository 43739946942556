import React, { Component } from 'react';
import { Card, Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import * as customerFunctions from '../../Functions/CustomerFunctions';
import { ViewObjectModal } from '../../Modals/ViewObjectModal';
import { MsalContext } from '@azure/msal-react';
export class AdminEdit extends Component {
    static displayName = AdminEdit.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            sp_company: null,
            sp_company_clicked: false,
            sp_company_loaded: false,
            entered_id: '',
            entered_company: '',
            new_field: '',
            new_field_value: '',
            show_view_obj: false,
            show_object: null,
            show_key: '',
            view_more: false,
            initial_company: null,
        };
    }

    add_field() {
        const current_company = this.state.sp_company;
        current_company[this.state.new_field] = this.state.new_field_value;
        this.setState({ sp_company: current_company, new_field: '', new_field_value: '' });
    }

    update_field(e) {
        const current_company = this.state.sp_company;
        current_company[e.target.name] = e.target.value;
        this.setState({ sp_company: current_company });
    }

    render_keys(keys) {
        const sp_company = this.state.sp_company;
        const restricted_fields = [
            '__categoryDescr',
            '__marketingPlanDescr',
            '__type',
            'add_empty_distance_occ',
            'add_finance_charge',
            'allow_inc_in_frt',
            'average_bill',
            'average_bill_c',
            'average_bill_d',
            'average_bill_n',
            'average_bill_r',
            'balance',
            'balance_c',
            'balance_d',
            'balance_n',
            'balance_r',
            'billed_loads',
            'brokerage_filter',
            'collections_ok',
            'company_id',
            'contract_cbc:',
            'contract_ccc',
            'contract_gbc',
            'contract_gcc',
            'contract_sbc',
            'contract_scc:',
            'credit_limit',
            'credit_limit_c',
            'credit_limit_d',
            'credit_limit_n',
            'credit_limit_r',
            'credit_status',
            'credit_warning_pct',
            'enforce_dep_dates',
            'entered_date',
            'factor_receivables',
            'google_place_id',
            'gp_exclude',
            'gp_exported',
            'high_balance',
            'high_balance_c',
            'high_balance_d',
            'high_balance_n',
            'high_balance_r',
            'id',
            'include_empty_distance',
            'include_fuel_surcharge',
            'is_active',
            'is_consignee',
            'is_distribution_center',
            'is_drop_yard',
            'is_geocoded',
            'is_pallets_required',
            'is_shipper',
            'is_trailer_pool_required',
            'last_bill_date',
            'manual_exchange',
            'pay_method',
            'pd1_np_auto',
            'pd1_sp_auto',
            'pd2_np_auto',
            'pd2_sp_auto',
            'pd3_np_auto',
            'pd3_sp_auto',
            'pd4_np_auto',
            'pd4_sp_auto',
            'percentage',
            'plc_uid',
            'post_summary_dtl',
            'primary_sort',
            'print_containers',
            'summary_billing',
            'type_of',
            'use_local_mile',
            'vm_part_update',
        ];

        const extra_fields = [
            'add_empty_distance_occ',
            'add_finance_charge',
            'allow_inc_in_frt',
            'average_bill_d',
            'average_bill_n',
            'average_bill_r',
            'balance_d',
            'balance_n',
            'balance_r',
            'brokerage_filter',
            'contract_cbc:',
            'contract_ccc',
            'contract_gbc',
            'contract_gcc',
            'contract_sbc',
            'contract_scc:',
            'credit_limit_c',
            'credit_limit_n',
            'credit_limit_r',
            'credit_status',
            'credit_warning_pct',
            'enforce_dep_dates',
            'entered_date',
            'factor_receivables',
            'gp_exclude',
            'gp_exported',
            'high_balance_c',
            'high_balance_n',
            'high_balance_r',
            'include_empty_distance',
            'include_fuel_surcharge',
            'is_consignee',
            'is_distribution_center',
            'is_drop_yard',
            'is_geocoded',
            'is_pallets_required',
            'is_shipper',
            'is_trailer_pool_required',
            'manual_exchange',
            'pay_method',
            'pd1_np_auto',
            'pd1_sp_auto',
            'pd2_np_auto',
            'pd2_sp_auto',
            'pd3_np_auto',
            'pd3_sp_auto',
            'pd4_np_auto',
            'pd4_sp_auto',
            'plc_uid',
            'post_summary_dtl',
            'primary_sort',
            'print_containers',
            'summary_billing',
            'use_local_mile',
            'vm_part_update',
        ];

        const displayed_keys = this.state.view_more
            ? keys
            : keys.filter((s) => !extra_fields.find((extra) => extra === s));

        return displayed_keys
            .sort((a, b) => a.localeCompare(b))
            .map((key) => (
                <Col className="p-2">
                    <div
                        className="d-flex flex-row justify-content-between border p-1 bg-white rounded"
                        style={{ width: '20rem' }}
                    >
                        <label className="text-nowrap text-sm p-1 text-bold">{key}:</label>
                        {Object.isExtensible(sp_company[key]) ? (
                            <Button
                                variant="secondary"
                                className="btn-op70 text-sm"
                                size="sm"
                                onClick={() =>
                                    this.setState({ show_view_obj: true, show_object: sp_company[key], show_key: key })
                                }
                            >
                                View
                            </Button>
                        ) : (
                            <Form.Control
                                name={key}
                                disabled={restricted_fields.find((s) => s === key)}
                                value={sp_company[key]}
                                className="border-0 outline-0 text-sm p-1"
                                onChange={(e) => this.update_field(e)}
                            />
                        )}
                    </div>
                </Col>
            ));
    }

    render() {
        const {
            entered_id,
            entered_company,
            sp_company,
            sp_company_loaded,
            sp_company_clicked,
            new_field,
            new_field_value,
            show_view_obj,
            show_object,
            show_key,
            view_more,
        } = this.state;

        return (
            <Card className="mt-4 bg-light p-3 d-flex flex-column" style={{ maxHeight: '80vh', overflow: 'auto' }}>
                <h6 className="tile-title text-success">
                    Edit Customer: {entered_id}{' '}
                    {entered_company ? ` (${entered_company === 'TMS2' ? 'SLUSA' : 'SLC'})` : null}
                </h6>
                <div className="d-flex flex-row justify-content-start">
                    <div className="d-flex flex-row justify-content-start w-25 me-3">
                        <label className="text-sm text-nowrap p-1 align-self-end">Company ID: </label>
                        <Form.Select
                            className="text-sm p-1"
                            value={entered_company}
                            onChange={(e) => this.setState({ entered_company: e.target.value })}
                        >
                            <option value="">Select</option>
                            <option value="TMS">SLC</option>
                            <option value="TMS2">SLUSA</option>
                        </Form.Select>
                    </div>
                    <div className="d-flex flex-row justify-content-start w-25">
                        <label className="text-sm text-nowrap p-1 align-self-end">Customer Code: </label>
                        <Form.Control
                            className="text-sm p-1"
                            value={entered_id}
                            onChange={(e) => this.setState({ entered_id: e.target.value })}
                        />
                    </div>
                    <Button
                        variant={sp_company_clicked ? 'outline-success' : 'success'}
                        className="btn-op70 text-sm ms-3"
                        onClick={() => this.select_prospect()}
                    >
                        Go
                    </Button>
                </div>

                <div className="d-flex flex-column">
                    {sp_company_loaded && sp_company ? (
                        <Container fluid="xs" className="p-2">
                            <Row className="d-flex flex-row justify-content-start">
                                <Col className="d-flex flex-column justify-content-start">
                                    <div className="d-flex flex-row justify-content-start border p-1 bg-white mt-3 rounded">
                                        <Form.Control
                                            className="text-sm p-1 me-2"
                                            value={new_field}
                                            onChange={(e) => this.setState({ new_field: e.target.value })}
                                            placeholder="New Field"
                                            style={{ width: '20rem' }}
                                        />
                                        <Form.Control
                                            className="text-sm p-1"
                                            value={new_field_value}
                                            onChange={(e) => this.setState({ new_field_value: e.target.value })}
                                            placeholder="Value"
                                            style={{ width: '20rem' }}
                                        />
                                        <Button
                                            variant={sp_company_clicked ? 'outline-success' : 'success'}
                                            className="btn-op70 text-sm ms-3"
                                            onClick={() => this.add_field()}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>{this.render_keys(Object.keys(sp_company))}</Row>
                            <Row className="d-flex flex-row justify-content-center mt-3">
                                <Col className="d-flex flex-column justify-content-center">
                                    {view_more ? (
                                        <Button
                                            variant="link"
                                            disabled={sp_company_clicked}
                                            className="btn-op70 text-sm ms-3 align-self-center underlined"
                                            onClick={() => this.setState({ view_more: false })}
                                            style={{ width: '10rem' }}
                                        >
                                            View Less
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="link"
                                            disabled={sp_company_clicked}
                                            className="btn-op70 text-sm ms-3 align-self-center underlined"
                                            onClick={() => this.setState({ view_more: true })}
                                            style={{ width: '10rem' }}
                                        >
                                            View More
                                        </Button>
                                    )}
                                </Col>
                            </Row>

                            <Row className="d-flex flex-row justify-content-center mt-3">
                                <Col className="d-flex flex-column justify-content-center">
                                    <Button
                                        variant={sp_company_clicked ? 'outline-success' : 'success'}
                                        disabled={sp_company_clicked}
                                        className="btn-op70 text-sm ms-3 align-self-center"
                                        onClick={() => this.save_prospect()}
                                        style={{ width: '10rem' }}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    ) : null}
                    {sp_company_clicked && !sp_company_loaded ? (
                        <div className="p-3">
                            <Alert variant="secondary">Loading...</Alert>
                        </div>
                    ) : null}
                </div>
                <ViewObjectModal
                    show={show_view_obj}
                    onHide={() => this.setState({ show_view_obj: false, show_object: null, show_key: '' })}
                    show_object={show_object}
                    show_key={show_key}
                />
            </Card>
        );
    }

    async select_prospect() {
        this.setState({
            sp_company: null,
            initial_company: null,
            sp_company_clicked: true,
            sp_company_loaded: false,
        });
        try {
            //const prospect = await customerFunctions.Get_Updated_Prospect(
            //    this.state.entered_company,
            //    this.state.entered_id,
            //);
            //this.setState({
            //    sp_company: prospect,
            //    initial_company: prospect,
            //    sp_company_clicked: false,
            //    sp_company_loaded: true,
            //});
        } catch (e) {
            console.log(e);
            this.setState({
                sp_company: null,
                initial_company: null,
                sp_company_clicked: false,
                sp_company_loaded: true,
            });
        }
    }

    async save_prospect() {
        this.setState({ sp_company_clicked: true });
        try {
            //if (this.context.accounts.length > 0) {
            //    const initial_customer = this.state.initial_company;
            //    const prospect = this.state.sp_company;
            //    const audit_body = {
            //        company_id: initial_customer.company_id,
            //        customer_id: initial_customer.id,
            //        changed_by_user: this.context.accounts[0].username.replaceAll('@scotlynn.com', ''),
            //        initial_values: JSON.stringify(initial_customer),
            //        new_values: JSON.stringify(prospect),
            //    };
            //    const audit_log = await customerFunctions.Insert_Customer_Audit(audit_body);
            //    const headers = dataService.getHeader(prospect.company_id);
            //    const options = { method: 'PUT', headers: headers, body: JSON.stringify(prospect) };
            //    const fetch_string = dataService.putProspect(prospect.company_id);
            //    const response = await fetch(fetch_string, options);
            //    const data = await response.json();
            //    this.setState({ sp_company_clicked: false });
            //} else {
            //    this.setState({ sp_company_clicked: false });
            //}
        } catch (e) {
            console.log(e);
            this.setState({ sp_company_clicked: false });
        }
    }
}
