import { format } from 'date-fns';
import React, { Component } from 'react';
import {
    Alert,
    Button,
    CloseButton,
    Col,
    Container,
    FloatingLabel,
    Form,
    InputGroup,
    Modal,
    Row,
} from 'react-bootstrap';
export class PossibleMatchesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lam_name: '',
            button_clicked: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ button_clicked: true });
        var currentName = this.state.lam_name;
        this.props.onSave(currentName);
        this.setState({ button_clicked: false, lam_name: '' });
    }

    render() {
        const { lam_name, button_clicked } = this.state;

        const display_name = this.props.msal_account ? this.props.msal_account.name.toLowerCase() : '';
        const typed_name = lam_name ? lam_name.toLowerCase() : '';

        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen="md-down"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName="light-modal"
                    backdrop="static"
                >
                    <Modal.Header className="p-3">
                        <Modal.Title
                            id="contained-modal-title-vcenter"
                            className="text-muted text-center w-100 pt-3 m-0"
                        >
                            <Container>
                                <Row>
                                    <Col xs={1}>
                                        <CloseButton onClick={() => this.props.onHide()} className="btn-op70" />
                                    </Col>
                                    <Col xs={10}>
                                        <h4 className="">{this.props.num_possible_matches} POSSIBLE MATCHES FOUND</h4>
                                    </Col>
                                    <Col xs={1}></Col>
                                </Row>
                            </Container>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="show-grid pb-5">
                        <Container>
                      
                            <Row>
                                <Col sm={12}>
                                    {display_name ? (
                                        <Alert variant={lam_name.toLowerCase() === display_name ? 'success' : 'danger'}>
                                            If a prospect already exist, a new profile should NOT be created for that
                                            prospect. The information you have entered is similar to existing prospects.
                                            Before you can add this prospect, you must verify that the prospect does not
                                            already exist. Type your full name below to agree that you will verify the
                                            prospect does not currently exist by checking the information you have
                                            entered with the list of possible matches. You can confirm that you checked
                                            an existing prospect by marking the checkbox in the prospect's row.
                                        </Alert>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <div
                                        className="d-flex flex-column justify-content-center w-100 px-3"
                                        style={{ borderRadius: '0.5rem', border: 'solid rgba(0,0,0,0.1) 0.1rem' }}
                                    >
                                        <label className="text-muted py-3">
                                            Enter your full name below if you agree to the statement above:
                                        </label>
                                        {this.props.msal_account ? (
                                            <InputGroup className="mb-3 opacity70">
                                                <FloatingLabel
                                                    controlId="floatingSearchLabel"
                                                    label={this.props.msal_account.name}
                                                    className="text-muted"
                                                >
                                                    <Form.Control
                                                        variant="outline-secondary"
                                                        style={{ fontStyle: 'italic' }}
                                                        type="text"
                                                        value={lam_name}
                                                        isValid={lam_name.toLowerCase() === display_name}
                                                        isInvalid={lam_name.toLowerCase() !== display_name}
                                                        name="lam_name"
                                                        onChange={this.handleInputChange}
                                                    />
                                                </FloatingLabel>
                                            </InputGroup>
                                        ) : null}

                                        {lam_name === display_name ? (
                                            <Alert variant="secondary">
                                                <small className="text-muted text-center w-100">
                                                    {display_name +
                                                        " agreed to verify their new prospect's data at "}
                                                    <bdi style={{ fontStyle: 'italic' }}>
                                                        {format(new Date(), 'MM/dd/yyyy HH:mm:ss')}
                                                    </bdi>
                                                </small>
                                            </Alert>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                            {typed_name === display_name ? (
                                <Row>
                                    <Col sm={12}>
                                        <div className="d-flex flex-row justify-content-center w-100 mt-4">
                                            <Button
                                                variant={button_clicked ? 'outline-success' : 'success'}
                                                disabled={button_clicked}
                                                className="btn-op70 w-50"
                                                onClick={(e) => this.handleSubmit(e)}
                                            >
                                                I AGREE
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            ) : null}
                        </Container>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
