import React, { Component } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, InputGroup, Card } from 'react-bootstrap';
import { isAfter, addDays, isBefore, addHours } from 'date-fns';
import * as baseColors from '../components/Functions/BaseColors';
import * as orderFunctions from '../components/Functions/OrderFunctions'
import truck_logo from '../Media/scot-logo.png'

import {
    PieChart,
    Pie,
    Cell,
} from 'recharts';


export class Reports extends Component {
    static displayName = Reports.name;
    constructor(props) {
        super(props);
        this.state = {
            company: '',
            report: '',
            report_selected: 'Not Paid',
            report_title:'Not Paid Report'
        };
    }
    render() {
        const { company, report, report_selected, report_title } = this.state;
        const salesperson = this.props.salesperson


        return <div className="d-flex flex-column justify-content-between p-3 w-100 opacity-50" >
        <h5>COMING SOON...</h5>
            <div className="d-flex flex-row">
                <div className="d-flex">
                    <Card  className='w-100'>
                        <Card.Header className="d-flex flex-row justify-content-center">
                            <div className="d-flex flex-column">
                                <img src={truck_logo} alt="truck logo" style={{ maxWidth: '10rem' }} />
                                <hr />
                                <h5 className="text-center text-bold">{report_title}</h5>
                                <hr />
                                <h6 className="text-center text-bold">{salesperson ? salesperson.salesperson_name : ''}</h6>
                                <h6 className="text-center text-bold text-sm">Commission</h6>
                            </div>
                          
                        </Card.Header>
                        <Card.Body>
                           
                        </Card.Body>
                    </Card>
                </div>
                <div className="d-flex flex-grow-1">
                    <Card fluid='xs' className='bg-white'>

                    </Card>
                </div>
            </div>
            <div>

            </div>
       
     
     
          
        </div>;
    }
}
