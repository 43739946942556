import { Dropdown, Badge, Form, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';

const notification_icon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-bell"
        viewBox="0 0 16 16"
    >
        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
    </svg>
);

function get_company(request) {

   if (request.company_id === 'TMS') {
            return `SLC ${request.request_name} Request`;
    } else {
            return `SLUSA ${request.request_name} Request`;
    }
}

export const CreditNotifications = ({ ...props }) => {
    const [tms, setTMS] = useState('');
    let requests = props.requests ? props.requests : [];
    if (tms) {
        requests = props.requests.filter((s) => s.company_id === tms);
    }

    return (
        <Dropdown autoClose="outside" className="d-flex align-self-center" title="Pending conversion requests">
            <Dropdown.Toggle variant="dark" className="d-flex flex-row">
                <div style={{ transform: 'scale(1.3)' }} className="me-1 mt-2">
                    {notification_icon}
                </div>
                {props.loaded === 'Y' ? (
                    <Badge bg="error" pill className="text-sm">
                        {props.requests.length}
                    </Badge>
                ) : (
                        <Spinner animation="grow" variant="danger" className="ms-1" style={{ width: '0.8rem', height: '0.8rem' }} />
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark" className="overdue-dropdown-container p-2">
                <Dropdown.ItemText header className="text-white d-flex flex-row justify-content-between">
                    <label className="text-med align-self-center">Pending Requests</label>
                    <Form.Select
                        className="p-0 bg-transparent text-white text-sm w-25  outline-0 p-1"
                        onChange={(e) => setTMS(e.target.value)}
                    >
                        <option className="bg-dark" value="">{`All (${props.requests.length})`}</option>
                        <option className="bg-dark" value="TMS">{`SLC (${
                            props.requests.filter((s) => s.company_id === 'TMS').length
                        })`}</option>
                        <option className="bg-dark" value="TMS2">{`SLUSA (${
                            props.requests.filter((s) => s.company_id === 'TMS2').length
                        })`}</option>
                    </Form.Select>
                </Dropdown.ItemText>
                <Dropdown.Divider className="bg-white" variant="light" />
                {requests
                    .filter((s) => s.company_id.includes(tms))
                    .sort((a, b) => new Date(a.date_requested) - new Date(b.date_requested))
                    .map((d, index) => (
                        <Dropdown.Item
                            key={d.id}
                            className="bg-dark-gray rounded mb-1"
                            onClick={() => (window.location.pathname = `/dashboard/${d.id}`)}
                        >
                            <div className="mb-0 text d-flex flex-column">
                                <div className="d-flex flex-row justify-content-between">
                                
                                    <bdi className="text-sm text-white">
                                        {get_company(d)}
                                    </bdi>
                                    <bdi className="text-sm fw-bold text-danger">{d.date_requested_str}</bdi>
                                </div>
                                <div className="d-flex flex-row mt-2 ">
                                    <strong className="text-sm align-self-center">{d.customer_name}</strong>
                                    <i className="ps-1 text-sm align-self-center">{d.revenue_code_id}</i>
                                </div>

                                <p style={{ whiteSpace: 'pre-wrap' }} className="text-sm mt-2">
                                    {d.remarks?d.remarks.replaceAll('&#xA;', '\n'):''}
                                </p>
                            </div>
                        </Dropdown.Item>
                    ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
