import React, { Component } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import * as svg_icons from '../../Media/svg_exports';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as fetchFunctions from '../Functions/FetchFunctions';
import { numericFormatter } from 'react-number-format';


export class AuditLogResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort_field: 'Company',
            sort_direction: 'H'
        };
    }

    set_selected_audit(prop) {

        if (this.props.selected_audit && prop) {
            if (this.props.selected_audit.id === prop.id) {
                this.props.set_selected_audit(null, 'N')
            } else {
                this.props.set_selected_audit(prop, 'Y')
            }
        } else {
            this.props.set_selected_audit(prop, 'Y')
        }
    }



    get_results_list() {
        let audit_log = this.props.audit_log ? this.props.audit_log : [];
        const selected_audit = this.props.selected_audit ? this.props.selected_audit : null;

        const sort_field = this.state.sort_field;
        const sort_direction = this.state.sort_direction;

        if (audit_log.length > 0) {
            const sortingStrategies = {
                Company: (a, b) => a.company_id.localeCompare(b.company_id),
                'Entered User': (a, b) => a.name.localeCompare(b.name),
                'Entered Name': (a, b) => a.entered_customer_name.localeCompare(b.entered_customer_name),
                'Entered DBA': (a, b) => a.entered_dba.localeCompare(b.entered_dba),
                'Entered Phone': (a, b) => a.entered_main_phone.localeCompare(b.entered_main_phone),
                'Entered Address': (a, b) => a.entered_address.localeCompare(b.entered_address),
                'Entered Zip': (a, b) => a.entered_zip_code.localeCompare(b.entered_zip_code),

            };
            audit_log = audit_log.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));


            let header_labels = [
                { label: 'Company', property: 'company_id' },
                { label: 'Entered User', property: 'name' },
                { label: 'Entered Name', property: 'entered_customer_name' },
                { label: 'Entered DBA', property: 'entered_dba' },
                { label: 'Entered Phone', property: 'entered_main_phone' },
                { label: 'Entered Address', property: 'entered_address' },
                { label: 'Entered Zip', property: 'entered_zip_code' }
            ]
            let headers = [];
            header_labels.map((d) =>
                headers.push({ column_title: d.label, property: d.property, sort_direction: sort_direction, is_sortable: 'Y', header_style: 'pe-3' })
            )
            let data = [];
            let cell_style = 'py-1 text-nowrap pe-3 text-xs'
            audit_log.map((d, index) =>
                data.push(
                    {
                        row_style: '',
                        cells: [
                            { cell: d.company_id, cell_style: cell_style },
                            { cell: d.name, cell_style: cell_style },
                            { cell: d.entered_customer_name, cell_style: cell_style },
                            { cell: d.entered_dba, cell_style: cell_style },
                            { cell: d.entered_main_phone, cell_style: cell_style },
                            { cell: d.entered_address, cell_style: cell_style },
                            { cell: d.entered_zip_code, cell_style: cell_style },                         
                        ],
                        data: d
                    }
                )

            )

            const table = <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                table_headers={headers}
                table_data={data}
                row_click={(prop) => this.set_selected_audit(JSON.parse(prop))}
                table_title=""
                sort_field={sort_field}
            />
    
            return table
                
        } else { return null }
    }

    render() {
        const { open_tile } = this.state;

        return this.props.audit_log ? (
            <div className="mt-2 w-100 d-flex flex-column pb-3">
                <InputGroup className="d-flex w-100">
                    <Form.Select
                        value={this.props.company}
                        onChange={(e) => this.props.update_field('company', e.target.value)}
                        className="text-xs"

                    >
                        <option value=''>Select Company</option>
                        <option value='TMS'>SLC</option>
                        <option value='TMS2'>SLUSA</option>
                    </Form.Select>
                    <Form.Control
                        value={this.props.start_date}
                        type='date'
                        onChange={(e) => this.props.update_field('start_date', e.target.value)}
                        className="text-xs"

                    />
                    <Form.Control
                        value={this.props.end_date}
                        type='date'
                        onChange={(e) => this.props.update_field('end_date', e.target.value)}
                        className="text-xs"

                    />
                    <Button onClick={() => this.props.get_audit_log()}>GO</Button>
                </InputGroup>

                {this.get_results_list()}
            </div>

        ) : null;
    }
}