import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { isBefore, addDays, differenceInDays, addMonths, format } from 'date-fns';
import * as svg_icons from '../../../Media/svg_exports';

export class PrecheckButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true,
        };
    }



    precheck_status_msg(selected_account) {
        let summary = { message: null, icon: null, icon_color: '' }
        const precheck_requests = this.props.requests ?
            this.props.requests.filter(s => s.request_type === 'ESTBCRED' && s.date_completed).sort((b, a) => new Date(a.date_completed) - new Date(b.date_completed))
            : [];
        const last_precheck_completed_date = precheck_requests.length > 0 ? new Date(precheck_requests[0].date_completed) : null;
        const crm_credit_precheck_request_date = selected_account.crm_credit_precheck_request ? new Date(selected_account.crm_credit_precheck_request) : null;
        let date_to_check = null;
        if (last_precheck_completed_date < crm_credit_precheck_request_date) {
            date_to_check = crm_credit_precheck_request_date;
        } else {
            date_to_check = last_precheck_completed_date;
        }
        const precheck_expiration_date = addMonths(new Date(), -6);

 
            if (date_to_check >= precheck_expiration_date) {
                return (
                    <Button
                        className={`text-sm d-flex flex-row justify-content-center p-1`}
                        onClick={() => this.props.onClick()}
                        size="sm"
                        variant="success"
                        title={`A CREDIT PRECHECK has been submitted for this account within the last 6 months: ${format(date_to_check, 'MM-dd-yyyy')}`}
                        style={{ width: '10rem' }}>
                        <bdi className="align-self-center ">Precheck Requested</bdi>

                        <div className={`text-white ms-1 align-self-center`} >
                            {svg_icons.simple_checkmark_icon}
                        </div>
                    </Button>
                )
       

            } else {
                let message = `A CREDIT PRECHECK has NOT been submitted for this account within the last 6 months.`;
                if (date_to_check) {
                    message = `${message} Last credit precheck submitted: ${format(date_to_check, 'MM-dd-yyyy')}`
                }
                return (
                    <Button
                        className={`text-sm d-flex flex-row justify-content-center p-1`}
                        onClick={() => this.props.onClick()}
                        size="sm"
                        variant="secondary"
                        title={message}
                        style={{ width: '10rem' }}>
                        <bdi className="align-self-center ">Request Precheck</bdi>

                        <div className={`text-danger ms-1 bg-white align-self-center`} style={{ borderRadius: '50%' }}>
                            {svg_icons.missing_icon}
                        </div>
                    </Button>
                )


            }
            return (
                <Button className="text-sm d-flex flex-row justify-content-center p-1 ms-2" size="sm" variant={summary.button_variant} title={summary.message} style={{ width: '8rem' }} onClick={ ()=>this.props.onClick()}>
                    <bdi className="align-self-center ">Request Precheck</bdi>

                    <div className={`text-${summary.icon_color} ms-1 bg-white align-self-center`} style={{ borderRadius: '50%' }}>
                        {summary.icon}
                    </div>
                </Button>
            )
        
    }



    render() {
        const { open_tile } = this.state;
        const selected_account = this.props.selected_account;
        let num_days = -1;
        if (selected_account) {
            if (selected_account.type_of === 'Customer') {
                if (selected_account.last_ship_date) {
                    num_days = differenceInDays(new Date(), new Date(selected_account.last_ship_date));
                }
            } else {
                if (selected_account.last_contacted_date) {
                    num_days = differenceInDays(new Date(), new Date(selected_account.last_contacted_date));
                }
            }

        }


        return selected_account ? (
             this.precheck_status_msg(selected_account) 

        ) : null;
    }
}