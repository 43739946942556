import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { differenceInDays, addMonths, format,addHours, getHours} from 'date-fns';
import * as svg_icons from '../../../Media/svg_exports';

export class AccountSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true,
        };
    }

    render_statement(summary) {
        return (
            <label className={`mt-2 text-sm fw-bold d-flex text-${summary.icon_color}`}>
                {summary.icon}
                {summary.message}
            </label>
        )
        
    }

    get_customer_variant(num_days) {
        if (num_days >= 0 && num_days < 100) {
            return "info"
        }
        else if (num_days >= 100 && num_days < 245) {
            return "warning"
        }
        else if (num_days >= 245 || num_days ===-1) {
            return "danger"
        }    
    }

    get_prospect_variant(num_days) {
        if (num_days >= 0 && num_days < 10) {
            return "info"
        }
        else if (num_days >= 10 && num_days < 15) {
            return "warning"
        }
        else if (num_days >= 15 || num_days === -1) {
            return "danger"
        }
    }

    get_phone_link(phone) {
        if (phone) {
            return <Button onClick={() => window.open(phone, '_blank')} className="underlined text-sm p-0" variant="link" size="sm">call</Button>
        } else {
            return null;
        }
    }

    get_prospect_summary(num_days, selected_account) {  
        const underline_days = (num) => <bdi className="underlined">{`${num} day${num > 1 ? 's' : ''}`}</bdi>;
       
        let summary = { message:null, icon: null, icon_color: 'secondary' }

        if (num_days >= 0 && num_days < 10) {
           
            summary = {
                message:
                    <bdi className={`ps-1 align-self-center text-secondary`}>
                        It has been {underline_days(num_days)} days since you last contacted {selected_account.name}.
                    </bdi>,
                icon: svg_icons.checkmark_icon,
                icon_color: 'success'
            }        
        }
        else if (num_days >= 10 && num_days < 15) {
            summary = {
                message:
                    <bdi className={`ps-1 align-self-center text-secondary`}>
                        It has been {underline_days(num_days)} days since you last contacted {selected_account.name}. You must contact your accounts at least once every {underline_days(15)} to avoid losing the account.
                    </bdi>,
                icon: svg_icons.missing_icon,
                icon_color: 'warning'
            } 
        }
        else if (num_days >= 15) {
            summary = {
                message:
                    <bdi className={`ps-1 align-self-center text-danger`}>
                        You have not made contact with this account in over {underline_days(15)}. You must reach out at least once every {underline_days(15)} or the account can be requested by another LAM.
                    </bdi>,
                icon: svg_icons.missing_icon,
                icon_color: 'danger'
            } 
        }
        else if (num_days === -1) {
            summary = {
                message:
                    <bdi className={`ps-1 align-self-center text-danger`}>
                        You have not made contact with this account yet. You must reach out within the first {underline_days(15)} of claiming this account to have a valid claim to it.
                    </bdi>,
                icon: svg_icons.missing_icon,
                icon_color: 'danger'
            } 
        }
        return this.render_statement(summary)
        
    }

    get_customer_summary(num_days, selected_account) {
        const underline_days = (num) => <bdi className="underlined">{`${num} day${num>1?'s':''}`}</bdi>;
        let summary = { message: null, icon: null, icon_color: '' }
        let expiry_days = 0;
        if (selected_account.expiry_date) {
            expiry_days = differenceInDays(
                new Date(selected_account.expiry_date),
                new Date(),
            );
        }
        let expiry_message = selected_account.expiry_date ? `There are (${expiry_days}) days left to ship an order for this account before it goes dormant.` :
            'This account does not currently have an expiry date.';
        let status_message = ``;
        if (num_days >=0 && num_days < 100) {
            summary = {
                message:
                    <bdi className={`ps-1 align-self-center text-secondary`}>
                        It has been {underline_days(num_days)} since your last shipped order for this account.
                    </bdi>,
                icon: svg_icons.checkmark_icon,
                icon_color: 'success'
            }    
        }
        else if (num_days >= 100 && num_days < 245) {
            summary = {
                message:
                    <bdi className={`ps-1 align-self-center text-secondary`}>
                       It has been {underline_days(num_days)} since your last shipped order for this account. There are {underline_days(expiry_days)} left to ship an order for this account before it goes dormant.
                    </bdi>,
                icon: svg_icons.missing_icon,
                icon_color: 'warning'
            } 
        }
        else if (num_days >= 245) {
            summary = {
                message:
                    <bdi className={`ps-1 align-self-center text-danger`}>
                        It has been {underline_days(num_days)} since your last shipped order for this account. There are {underline_days(expiry_days)} left to ship an order for this account before it goes dormant.
                    </bdi>,
                icon: svg_icons.missing_icon,
                icon_color: 'danger'
            } 
        }
        else if (num_days === -1) {
            summary = {
                message:
                    <bdi className={`ps-1 align-self-center text-secondary`}>
                        An order has never been shipped for this account. If you recently had this account converted to a customer, your last ship date will populate once it's posted. 
                    </bdi>,
                icon: svg_icons.missing_icon,
                icon_color: 'danger'
            } 
        }
       return this.render_statement(summary)        
    }

    sendpack_status_msg(selected_account) {
        let summary = { message: null, icon: null, icon_color: '' }
        const sendpack_actions = this.props.prospect_actions ?
            this.props.prospect_actions.filter(s => s.activity_id === 'SENDPACK' && s.status==='C').sort((b, a) => new Date(a.action_date_str) - new Date(b.date_completed))
            : [];
        if (selected_account.type_of === 'Customer') {
            return null;
        } else {
            if (sendpack_actions.length > 0) {
                summary = {
                    message:
                        <bdi className="ps-1 align-self-center text-dark">
                            SENDPACK activity has been completed for this account: {format(sendpack_actions[0].action_date_str, 'MM-dd-yyyy')}
                        </bdi>,
                    icon: svg_icons.checkmark_icon,
                    icon_color: 'success'
                } 

            } else {
                summary = {
                    message:
                        <bdi className="ps-1 align-self-center text-danger">
                            SENDPACK activity has NOT been completed for this account.
                        </bdi>,
                    icon: svg_icons.missing_icon,
                    icon_color: 'danger'
                } 
               
            }
            return (
                this.render_statement(summary)
            )
        }
        
    }

    precheck_status_msg(selected_account) {
        let summary = { message: null, icon: null, icon_color: '' }
        const precheck_requests = this.props.requests ?
            this.props.requests.filter(s => s.request_type === 'ESTBCRED' && s.date_completed).sort((b,a) => new Date(a.date_completed) - new Date(b.date_completed))
            : [];
        const last_precheck_completed_date = precheck_requests.length > 0 ? new Date(precheck_requests[0].date_completed) : null;
        const crm_credit_precheck_request_date = selected_account.crm_credit_precheck_request ? new Date(selected_account.crm_credit_precheck_request) : null;
        let date_to_check = null;
        if (last_precheck_completed_date < crm_credit_precheck_request_date) {
            date_to_check = crm_credit_precheck_request_date;
        } else {
            date_to_check = last_precheck_completed_date;
        } 
        const precheck_expiration_date = addMonths(new Date(), -6);

        if (selected_account.type_of === 'Customer') {
            return null;
        } else {
            if (date_to_check >= precheck_expiration_date) {
                summary = {
                    message:
                        <bdi className="ps-1 align-self-center text-dark">
                            A CREDIT PRECHECK has been submitted for this account within the last 6 months: {format(date_to_check, 'MM-dd-yyyy')}
                        </bdi>,
                    icon: svg_icons.checkmark_icon,
                    icon_color: 'success'
                }
     
            } else {
                let message = `A CREDIT PRECHECK has NOT been submitted for this account within the last 6 months.`;
                if (date_to_check) {
                    message = `${message} Last credit precheck submitted: ${format(date_to_check, 'MM-dd-yyyy')}`
                }
                summary = {
                    message:
                        <bdi className="ps-1 align-self-center text-danger">
                            {message}
                        </bdi>,
                    icon: svg_icons.missing_icon,
                    icon_color: 'danger'
                }
                           
            }
            return (
                this.render_statement(summary)
            )
        }
    }



    render() {
        const { open_tile } = this.state;
        const selected_account = this.props.selected_account;
        let num_days = -1;
        if (selected_account) {
            if (selected_account.type_of === 'Customer') {
                if (selected_account.last_ship_date) {
                    num_days = differenceInDays(new Date(), addHours(new Date(selected_account.last_ship_date), -getHours(new Date(selected_account.last_ship_date))));
                }
            } else {
                if (selected_account.action_date_str) {
                    num_days = differenceInDays(new Date(), addHours(new Date(selected_account.action_date_str), -getHours(new Date(selected_account.action_date_str))));
                }
            }
          
        }


        return selected_account ? (
            <Alert variant={selected_account.type_of === 'Customer' ? this.get_customer_variant(num_days) : this.get_prospect_variant(num_days)}>
                <div className="d-flex flex-row justify-content-between w-100">    
                        <h6 className="align-self-end pb-0 mb-0 me-2">{selected_account.name}</h6>                                        
                    <div className="d-flex flex-row fw-bold">
                        <small className="text-sm opacity-75 align-self-end mb-0">
                            {selected_account.type_of === 'Customer' ? `Last Shipped: ${selected_account.last_ship_date ? selected_account.last_ship_date:'Pending'}` : `Claimed: ${selected_account.start_date}`}
                        </small>
                    </div>                    
                </div>               
                <hr className="mt-0" />

                <div className="d-flex flex-column">
                    {this.precheck_status_msg(selected_account)}                    
                    {this.sendpack_status_msg(selected_account)}                  
                    {selected_account.type_of === 'Customer' ? this.get_customer_summary(num_days, selected_account) : this.get_prospect_summary(num_days, selected_account)}
                </div>

            </Alert>

        ):null;
    }
}