
const get_options_w_token = (token) => {
    return {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    }
};

const put_options = (body) => {
    return {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    }
}

export async function Log_Possible_Matches(match) {
    try {
        const response = await fetch(`PossibleMatch`, put_options(match));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Log(company_id, start_date, end_date) {
    try {
        const response = await fetch(`PossibleMatch/AuditLog/${company_id}/${start_date}/${end_date}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}