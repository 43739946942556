import React, { Component } from 'react';
import { Card, Alert, Button, InputGroup, Form, ButtonGroup } from 'react-bootstrap';
import { Userpilot } from 'userpilot';
export class RequestsStatusSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true
        };
    }

    get_requests_nums(list) {
        const num_conversion = list.filter(s => s.request_type === 'CREQUEST').length;
        const num_extensions = list.filter(s => s.request_type === 'EREQUEST').length;
        const num_accounts = list.filter(s => s.request_type === 'ACCTREQ').length;
        const num_prechecks = list.filter(s => s.request_type === 'ESTBCRED').length;
        let num_no_action_accts = 0;
        if (this.props.role === 'gm' && this.props.no_action_request_list) {
            num_no_action_accts = this.props.no_action_request_list.filter(s => s.request_type === 'ACCTREQ' && s.no_action === 'Y').length;        
        }
        
        return { CREQUEST: num_conversion, EREQUEST: num_extensions, ACCTREQ: num_accounts, ESTBCRED: num_prechecks, NACCTREQ: num_no_action_accts }
    }


    render() {
        const { status } = this.state;
  

        let requests_options = [
            { label: 'Accounts', value: 'ACCTREQ' }
        ]
       
        if (this.props.direction === 'Outgoing' || this.props.role === 'gm') {
            if (this.props.role !== 'gm' && this.props.direction === 'Outgoing') {
                requests_options.push({ label: 'Prechecks', value: 'ESTBCRED' })
            }         
            requests_options.push({ label: 'Conversions', value: 'CREQUEST' })
            requests_options.push({ label: 'Extensions', value: 'EREQUEST' })          
        }
        if (this.props.role === 'gm' && this.props.direction === 'Incoming') {
            requests_options.push({ label: 'No Action Required Accounts', value: 'NACCTREQ' })
        }

        const requests_nums = this.get_requests_nums(this.props.current_requests_list)

        const requests_options_button = (type) =>
            requests_nums?
            <Button
                    className={`hover-over text-sm p-1 opacity-75 ${requests_nums[type.value] > 0 && this.props.direction === 'Incoming' && this.props.status ==='Pending'?'fw-bold':''}`}
                    variant={type.value === this.props.request_type ? 'secondary' : 'outline-secondary'}
                onClick={() => this.props.update_requests_type(type.value)}>
                {`${type.label} (${requests_nums[type.value]})`}
                </Button> :
            null

        return (
            <div className="d-flex flex-column mb-2 flex-grow-1">
                <ButtonGroup>
                    {requests_options.map(d =>
                        requests_options_button(d)
                    )}
                </ButtonGroup>

                <InputGroup className="mt-2">
                    <Form.Select onChange={(e) => this.props.update_status(e.target.value)} className="text-sm p-1" value={this.props.status}>
                        <option value="P">Pending</option>
                        <option value="C">Completed</option>
                    </Form.Select>

                </InputGroup>
        
            </div>
           
        );
    }
}