import { Col, Container, Form, Row } from 'react-bootstrap';

import '../.././App.scss';

function NumericComparatorInput({ ...props }) {
    const order_comparator_list = [
        { label: 'Select', value: '' },
        { label: 'Greater than', value: 'greater than' },
        { label: 'Less than', value: 'less than' },
        { label: 'Between', value: 'between' },
    ];
    return (
        <Container
            id={props.id_group + '-input-group'}
            className="border rounded"
            style={
                props.search_filter[props.comparator_name] === ''
                    ? { filter: 'opacity(0.7)' }
                    : { filter: 'opacity(1)' }
            }
            fluid="xs"
        >
            <Row>
                <Col className="pt-1 pb-0">
                    <label className="text-muted text-xs ps-2 opacity-70">{props.field_title}</label>
                </Col>
            </Row>
            <Row className="pe-2">
                <Col xs={4}>
                    <Form.Select
                        onChange={props.handleFilterChange}
                        className="shadow-none border-0 bg-transparent text-xs"
                        name={props.comparator_name}
                        value={props.search_filter[props.comparator_name]}
                        id="props.comparator_name"
                    >
                        {order_comparator_list.map((d, index) => (
                            <option value={d.value} label={d.label} key={index} />
                        ))}
                    </Form.Select>
                </Col>
                {props.search_filter[props.comparator_name] === '' ? null : (
                    <Col xs={4} className="pb-2">
                        <Form.Control
                            onChange={props.handleFilterChange}
                            name={props.low_input_name}
                            className="shadow-none border-0 bg-transparent text-xs"
                            isInvalid={
                                props.search_filter[props.low_input_name] <= -1 ||
                                (props.search_filter[props.low_input_name] >
                                    props.search_filter[props.high_input_name] &&
                                    props.search_filter[props.comparator_name] === 'between')
                            }
                            title={
                                props.search_filter[props.low_input_name] <= -1
                                    ? 'Low input must be greater than or equal to 0'
                                    : props.search_filter[props.low_input_name] >
                                            props.search_filter[props.high_input_name] &&
                                        props.search_filter[props.comparator_name] === 'between'
                                      ? 'Low input cannot be greater than high input'
                                      : ''
                            }
                            value={
                                props.search_filter[props.comparator_name] === ''
                                    ? ''
                                    : props.search_filter[props.low_input_name]
                            }
                            type="number"
                            onKeyPress={props.handleKeyPress}
                            id={props.low_input_name}
                        />
                    </Col>
                )}

                {props.search_filter[props.comparator_name] === 'between' ? (
                    <Col xs={4} className="pb-2">
                        <Form.Control
                            onChange={props.handleFilterChange}
                            name={props.high_input_name}
                            className="shadow-none border-0 bg-transparent text-xs"
                            title={
                                props.search_filter[props.high_input_name] <= 0
                                    ? 'High input must be greater than or equal to 1'
                                    : props.search_filter[props.low_input_name] >
                                        props.search_filter[props.high_input_name]
                                      ? 'High input cannot be less than low input'
                                      : ''
                            }
                            isInvalid={
                                props.search_filter[props.high_input_name] <= 0 ||
                                props.search_filter[props.low_input_name] > props.search_filter[props.high_input_name]
                            }
                            value={
                                props.search_filter[props.comparator_name] === ''
                                    ? ''
                                    : props.search_filter[props.high_input_name]
                            }
                            type="number"
                            onKeyPress={props.handleKeyPress}
                            id={props.high_input_name}
                        />
                    </Col>
                ) : null}
            </Row>
        </Container>
    );
}

export default NumericComparatorInput;
