import { MsalContext } from '@azure/msal-react';
import React, { Component } from 'react';
import { format} from 'date-fns'
import {  Button, Col, Container,Form, InputGroup, Row } from 'react-bootstrap';
import * as svg_icons from '../../Media/svg_exports';
import * as fetchFunctions from '../Functions/FetchFunctions';
import * as customerFunctions from '../Functions/CustomerFunctions';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import { numericFormatter } from 'react-number-format';
import { GoogleAutocompleteInput } from '../Inputs/GoogleAutocompleteInput';
import { GiftAccountModal } from './ActionComponents/GiftAccountModal'


export class AccountTab extends Component {
    static displayName = AccountTab.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            message: '',
            show_gifted: false,
            alter_phone:false
        };
    }



    get_website_property(selected_account) {
        let website = '';
        if (selected_account) {
            website = selected_account.website_url ? selected_account.website_url : '';
            return (
                <Col xl={4} className="my-2" title={this.props.role === 'lam' ? '' :'Table: customer\nField: website_url'}>
                    <InputGroup className="overview-tab-input-group">
                        <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                            Website
                        </InputGroup.Text>
                        {this.is_users_account() ?
                            <Form.Control
                                value={selected_account.website_url}
                                className="text-xs bg-white text-start"
                                disabled={this.state.clicked}
                                name="website_url"
                                onChange={(e) => this.update_account(e)}
                            /> : <InputGroup.Text className="text-nowrap text-xs p-1 flex-grow-1 bg-transparent">
                                {selected_account.website_url}
                            </InputGroup.Text>}
                        {website ? <Button
                            onClick={() => window.open(website, '_blank')} variant="secondary" title="Open website in new tab" className="opacity-75">
                            {svg_icons.website_icon}</Button> : null}
                    </InputGroup>
                </Col>
                
            )

        } else {
            return null;
        }
    }

    get_phone_property(selected_account) {
        let phone = '';
        if (selected_account) {
            phone = selected_account.main_phone ? `tel:+${fetchFunctions.formatPhoneNumber(selected_account.main_phone)}` : '';
            return (
                <Col xl={4} className="my-2" title={this.props.role === 'lam' ? '' : 'Table: customer\nField: main_phone'}>
                    <InputGroup className="overview-tab-input-group">
                        <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                            Main Phone
                        </InputGroup.Text>
                        {this.is_users_account() ?
                            <Form.Control
                                value={selected_account.main_phone}
                                className="text-xs bg-white text-start"
                                disabled={this.state.clicked}
                                name="main_phone"
                                onChange={(e) => this.update_account(e)}
                            /> :
                            <InputGroup.Text className="text-nowrap text-xs p-1 flex-grow-1 bg-transparent">
                                {selected_account.main_phone}
                            </InputGroup.Text>}
                        {phone && selected_account.is_active === 'Y' && !this.state.alter_phone ? <Button onClick={() => window.open(phone, '_blank')} className="bg-phone">{svg_icons.phone_icon}</Button> : null}
                    </InputGroup>
                </Col>)

        } else {
            return null;
        }
    }

    display_gift_button(selected_account) {
        const current_user = this.props.current_user;
        if (selected_account && current_user) {
            if (this.props.role === 'credit') {
                return true;
            } else if (this.props.role === 'admin') {
                return true;
            } else if (this.props.role === 'gm') {
                if (selected_account.sales_manager_id && current_user.salesperson_id) {
                    if (selected_account.sales_manager_id.toUpperCase() === current_user.salesperson_id.toUpperCase()) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }

            } else {
                return false;
            }
        }
    
    }

    get_lam_property(selected_account) {
        if (selected_account) {
            let email = '';
            let teams = '';
            let phone = '';
            if (selected_account.salesperson_id === 'UNCLAIMD') {
                email = selected_account.prev_salesperson_email_address? `mailto:${selected_account.prev_salesperson_email_address}?subject=Inquiring about ${selected_account.id}`:'';
                teams = selected_account.prev_salesperson_email_address?`MSTeams:/l/chat/0/0?users=${selected_account.prev_salesperson_email_address}`:'';
                phone = selected_account.prev_salesperson_phone?`tel:+${fetchFunctions.formatPhoneNumber(selected_account.prev_salesperson_phone)}`:'';
            } else {
                email = selected_account.salesperson_email_address?`mailto:${selected_account.salesperson_email_address}?subject=Inquiring about ${selected_account.id}`:'';
                teams = selected_account.salesperson_email_address?`MSTeams:/l/chat/0/0?users=${selected_account.salesperson_email_address}`:'';
                phone = selected_account.salesperson_phone?`tel:+${fetchFunctions.formatPhoneNumber(selected_account.salesperson_phone)}`:'';
            }
            return (
                <Col xl={4} className="my-2">
                    <InputGroup className="overview-tab-input-group">
                  
                        <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">

                            {selected_account.salesperson_id === 'UNCLAIMD' ? 'Prev. LAM' : 'LAM'}
                        </InputGroup.Text>
                        <Form.Control
                            title={this.props.role === 'lam' ? '' : 'Table: commission_dim_salesperson\nField: salesperson_name'}
                            value={selected_account.salesperson_id === 'UNCLAIMD' ? `${selected_account.prev_salesperson_name ? selected_account.prev_salesperson_name:'None'}${selected_account.prev_salesperson_is_active === 'Y' ? '' : ' (Inactive)'}`
                                : `${selected_account.salesperson_name}${selected_account.salesperson_is_active === 'Y' ? '' : ' (Inactive)'}`}
                            className="text-xs bg-white text-start p-1"
                            disabled={true}
                        />
                        {this.display_gift_button(selected_account) ?
                        <Button onClick={() => this.setState({ show_gifted: true })} className="bg-secondary" id="gift-account-button">{svg_icons.edit_icon}</Button>:
                        null
                    }
                        {teams && selected_account.prev_salesperson_is_active === 'Y' ?
                            <Button onClick={() => window.open(teams, '_blank')} className="bg-purple" title={this.props.role === 'lam' ? '' : 'Table: users\nField: login_id+@scotlynn.com'}>{svg_icons.teams_icon}</Button>
                            : null}
                        {phone && selected_account.prev_salesperson_is_active === 'Y' ?
                            <Button onClick={() => window.open(phone, '_blank')} className="bg-phone" title={this.props.role === 'lam' ? '' : 'Table: users\nField: phone'}>{svg_icons.phone_icon}</Button> : null}
                        {email && selected_account.prev_salesperson_is_active === 'Y' ?
                            <Button onClick={() => window.open(email, '_blank')} className="bg-email" title={this.props.role === 'lam' ? '' : 'Table: users\nField: login_id+@scotlynn.com'}>{svg_icons.email_icon}</Button> : null}
                    </InputGroup>
                </Col>
               
            )
        } else {
            return null;
        }
    }

    get_gm_property(selected_account) {
        if (selected_account) {
            let email = '';
            let teams = '';
            if (selected_account.salesperson_id === 'UNCLAIMD') {
                email = selected_account.prev_sales_manager_email?`mailto:${selected_account.prev_sales_manager_email}?subject=Inquiring about ${selected_account.id}`:'';
                teams = selected_account.sales_manager_email?`MSTeams:/l/chat/0/0?users=${selected_account.sales_manager_email}`:'';

            } else {
                email = selected_account.prev_sales_manager_email?`mailto:${selected_account.prev_sales_manager_email}?subject=Inquiring about ${selected_account.id}`:'';
                teams = selected_account.sales_manager_email? `MSTeams:/l/chat/0/0?users=${selected_account.sales_manager_email}`:'';
            }
            return (
                <Col xl={4} className="my-2">
                    <InputGroup className="overview-tab-input-group">
                        <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                            {selected_account.salesperson_id === 'UNCLAIMD' ? 'Prev. Manager' : 'Manager'}
                        </InputGroup.Text>
                        <Form.Control
                            value={selected_account.salesperson_id === 'UNCLAIMD' ? selected_account.prev_sales_manager_name : selected_account.sales_manager_name}
                            className="text-xs bg-white text-start p-1"
                            disabled={true}
                        />
                        {teams ? <Button onClick={() => window.open(teams, '_blank')} className="bg-purple">{svg_icons.teams_icon}</Button> : null}
                        {email ? <Button onClick={() => window.open(email, '_blank')} className="bg-email">{svg_icons.email_icon}</Button> : null}
                    </InputGroup>
                </Col>
                
            )
        } else {
            return null;
        }
    }

    update_account(e) {
        const selected_account_data = this.props.selected_account_data;
        let selected_account = selected_account_data ? selected_account_data.account : null;
        if (selected_account) {
            if (e.target.name === 'main_phone') {
                this.setState({ alter_phone: true })
            }
            if (e.target.name.includes('date')) {
                selected_account[e.target.name] = utilityFunctions.format_date_from_input(e.target.value);
            } else {
                selected_account[e.target.name] = e.target.value;
            }

            
            this.props.update_selected_account_data('account', selected_account)
        }
    }


    async put_account() {
        this.setState({ clicked: true, message: 'Saving account information...' });
        const selected_account_data = this.props.selected_account_data;
        let selected_account = selected_account_data ? selected_account_data.account : null;
        try {
            await this.check_updated_field_differences(selected_account);
            const updated_customer = await customerFunctions.Info_Update(selected_account);
            this.setState({ clicked: false, message: '', alter_phone: false })
        } catch (e) {
            this.setState({ clicked: false, message: 'Failed to update account', alter_phone: false })
        }
       
    }

    async check_updated_field_differences(selected_account) {
        const current_account = await customerFunctions.Get_By_ID(selected_account.company_id, selected_account.id)
        if (current_account && selected_account) {
            let initial_values = {}
            let new_values = {};
            Object.keys(current_account).map((key) => {
                if (current_account[key] !== selected_account[key]) {
                    initial_values[key] = current_account[key];
                    new_values[key] = selected_account[key]
                }
            })
           const body = {
                company_id: selected_account.company_id,
               customer_id: selected_account.id,
               changed_by_user: this.props.msal_account.username.replaceAll('@scotlynn.com', ''),
                initial_values: JSON.stringify(initial_values),
                new_values: JSON.stringify(new_values)
        }
        await customerFunctions.Insert_Customer_Audit(body);
        } else {
            return null;
        }     
    }

    is_users_account() {
        const current_user = this.props.current_user;
        const selected_account_data = this.props.selected_account_data;
        const selected_account = selected_account_data ? selected_account_data.account : null;

        if (current_user && selected_account) {
            if (current_user.salesperson_id && selected_account.salesperson_id) {
                if (current_user.salesperson_id.toUpperCase() == selected_account.salesperson_id.toUpperCase()) {
                    return true;
                } else if (this.props.role === 'admin' || this.props.role === 'credit') {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    get_info_update_button() {
        const update_info_button =
            <Row className="mt-4">
                <Col xl={4} className="my-2"></Col>
                <Col xl={4} className="my-2">
                    <Button
                        className="text-xs w-100"
                        variant={this.state.clicked ? 'outline-success' : 'success'}
                        size="sm"
                        disabled={this.state.clicked}
                        onClick={() => this.put_account()}>
                        {this.state.clicked ? this.state.message : 'Save'}
                    </Button>
                </Col>
                <Col xl={4} className="my-2"></Col>
        </Row>
           
            
        if (this.is_users_account()) {
            return update_info_button;
        } else {
            return null
        }
       
    }


    render() {
        const { clicked, show_gifted } = this.state;
        const selected_account_data = this.props.selected_account_data; 
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const current_user = this.props.current_user;
        const commodities = this.props.commodities ? this.props.commodities : [];
        const categories = this.props.categories ? this.props.categories : [];

        const disabled_property_view = (field, value) =>
            <Col lg={ 5} xl={4} className="my-2">
                <InputGroup className="overview-tab-input-group">
                    <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                        {field}
                    </InputGroup.Text>
                    <InputGroup.Text className="text-nowrap text-xs p-1 bg-white flex-grow-1">
                        {value}
                    </InputGroup.Text>
                </InputGroup>
        </Col>;

        const enabled_property_view = (field, name, value) =>
            <Col xl={4} className="my-2">
                <InputGroup className="overview-tab-input-group">
                    <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                        {field}
                    </InputGroup.Text>
                    {this.is_users_account() ?
                        <Form.Control
                            value={value}
                            name={name}
                            onChange={(e) => this.update_account(e)}
                            className="text-xs bg-white text-start"
                            disabled={clicked || !this.is_users_account()} /> :
                        <InputGroup.Text className="text-nowrap text-xs p-1 w-75 bg-transparent">
                            {value}
                        </InputGroup.Text>
                    }
                </InputGroup>
        </Col>;

        const address_property_view = <Col xl={4} className="my-2">
            <InputGroup className="overview-tab-input-group">
                <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                    Address
                </InputGroup.Text>
                {this.is_users_account() ?
                    <GoogleAutocompleteInput
                        className="text-xs bg-white text-start w-100"
                        width="flex-grow-1"
                        update_account={(name, value) => this.update_account({ target: { name: name, value: value } })}
                        address1={selected_account.address1}
                        address2={selected_account.address2}
                        city={selected_account.city}
                        state_id={selected_account.state_id}
                        zip_code={selected_account.zip_code}
                        current_property='address1'
                        default_value={selected_account.address1}
                        disabled={false}

                    /> :
                    <InputGroup.Text className="text-nowrap text-xs p-1 flex-grow-1 bg-transparent">
                        {selected_account.address1}
                    </InputGroup.Text>
                }

                <InputGroup.Text className="text-nowrap text-xs p-1 text-bold" style={{width:'10%'}} title="Unit/Suite #">
                    Suite #
                </InputGroup.Text>
                {this.is_users_account() ?
                    <Form.Control
                        value={selected_account.address2}
                        name='address2'
                        onChange={(e) => this.update_account(e)}
                        className="text-xs bg-white text-start "
                        style={{ width: '10%' }}
                        disabled={clicked || !this.is_users_account()}
                    /> : <InputGroup.Text className="text-nowrap text-xs p-1 bg-transparent " style={{ width: '15%' }}>
                        {selected_account.address2}
                    </InputGroup.Text>}
            </InputGroup>
        </Col>;

        const location_property_view =
            <Col xl={4} className="my-2">
                <InputGroup className="overview-tab-input-group">
                    <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                        City/State/Zip
                    </InputGroup.Text>
                    {this.is_users_account() ?
                        <GoogleAutocompleteInput
                            className="text-xs bg-white text-start w-100"
                            width="w-75"
                            update_account={(name, value) => this.update_account({ target: { name: name, value: value } })}
                            address1={selected_account.address1}
                            address2={selected_account.address2}
                            city={selected_account.city}
                            state_id={selected_account.state_id}
                            zip_code={selected_account.zip_code}
                            current_property='city-state-zip'
                            default_value={`${selected_account.city}, ${selected_account.state_id}, ${selected_account.zip_code}`}
                            disabled={false}
                        /> :
                        <InputGroup.Text className="text-nowrap text-xs p-1 w-75 bg-transparent">
                            {`${selected_account.city}, ${selected_account.state_id}, ${selected_account.zip_code}`}
                        </InputGroup.Text>}
                </InputGroup>
            </Col>;

        const commodity_property_view =
            <Col xl={4} className="my-2">
                <InputGroup className="overview-tab-input-group">
                    <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                        Commodity
                    </InputGroup.Text>
                    {this.is_users_account() ?
                        <Form.Select
                            value={selected_account.bill_format_flag}
                            className="text-xs bg-white text-start"
                            name="bill_format_flag"
                            disabled={clicked}
                            onChange={(e) => this.update_account(e)}
                        >
                            <option value=''>Select Commodity</option>
                            {commodities.map(d =>
                                <option key={d.id} value={d.id}>{d.name}</option>
                            )}
                        </Form.Select> :
                        <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-75 bg-transparent">
                            {`${selected_account.commodity ? selected_account.commodity:'-'}`}
                        </InputGroup.Text>}
                </InputGroup>
            </Col>;

        const categories_property_view =
            <Col xl={4} className="my-2">
                <InputGroup className="overview-tab-input-group">
                    <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                        Category
                    </InputGroup.Text>
                    {this.is_users_account() ?
                        <Form.Select
                            value={selected_account.category}
                            id='category-input'
                            className="text-xs bg-white text-start"
                            name="category"
                            disabled={clicked}
                            onChange={(e) => this.update_account(e)}
                        >
                            <option value=''>Select Category</option>
                            {categories.map((d,index)=>
                                <option key={index} value={d.category} title={ d.descr}>{d.category}</option>
                            )}
                        </Form.Select> :
                        <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-75 bg-transparent">
                            {`${selected_account.category ? selected_account.category : '-'}`}
                        </InputGroup.Text>}
                </InputGroup>
            </Col>;

        const start_date_property_view =
            <Col xl={4} className="my-2">
                <InputGroup className="overview-tab-input-group">
                    <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                        Start Date
                    </InputGroup.Text>
                    {this.props.role==='credit' || this.props.role==='admin' ?
                        <Form.Control
                            value={utilityFunctions.format_date_for_input(selected_account.start_date)}
                            id='start-date-input'
                            type="date"
                            className="text-xs bg-white text-start"
                            name="start_date"
                            disabled={clicked}
                            onChange={(e) => this.update_account(e)}
                        />
                           :
                        <InputGroup.Text className="text-nowrap text-xs p-1 w-75 bg-transparent">
                            {`${selected_account.start_date ? selected_account.start_date : '-'}`}
                        </InputGroup.Text>}
                </InputGroup>
            </Col>;

        const conversion_date_property_view =
            <Col xl={4} className="my-2">
                <InputGroup className="overview-tab-input-group">
                    <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                        Conversion Date
                    </InputGroup.Text>
                    {(this.props.role === 'credit' || this.props.role === 'admin') && selected_account.conversion_date && selected_account.type_of==='Customer' ?
                        <Form.Control
                            value={utilityFunctions.format_date_for_input(selected_account.conversion_date)}
                            type="date"
                            className="text-xs bg-white text-start"
                            name="conversion_date"
                            id='conversion-date-input'
                            disabled={clicked}
                            onChange={(e) => this.update_account(e)}
                        />
                        :
                        <InputGroup.Text className="text-nowrap text-xs p-1 w-75 bg-transparent">
                            {`${selected_account.conversion_date ? selected_account.conversion_date : ''}`}
                        </InputGroup.Text>}
                </InputGroup>
            </Col>;

        const request_date_property_view =
            <Col xl={4} className="my-2">
                <InputGroup className="overview-tab-input-group">
                    <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                        Precheck Requested
                    </InputGroup.Text>
                    {(this.props.role === 'credit' || this.props.role === 'admin') && selected_account.crm_credit_precheck_request ?
                        <Form.Control
                            value={utilityFunctions.format_date_for_input(selected_account.crm_credit_precheck_request)}
                            type="date"
                            className="text-xs bg-white text-start"
                            id='crm-request-date-input'
                            name="crm_credit_precheck_request"
                            disabled={clicked}
                            onChange={(e) => this.update_account(e)}
                        />
                        :
                        <InputGroup.Text className="text-nowrap text-xs p-1 w-75 bg-transparent">
                            {`${selected_account.crm_credit_precheck_request ? selected_account.crm_credit_precheck_request : 'N/A'}`}
                        </InputGroup.Text>}
                </InputGroup>
            </Col>;

        const precheck_date_property_view =
            <Col xl={4} className="my-2">
                <InputGroup className="overview-tab-input-group">
                    <InputGroup.Text className="text-nowrap text-xs p-1 text-bold w-25">
                        Precheck Approved
                    </InputGroup.Text>
                    {(this.props.role === 'credit' || this.props.role === 'admin') && selected_account.crm_credit_approval_date ?
                        <Form.Control
                            value={utilityFunctions.format_date_for_input(selected_account.crm_credit_approval_date)}
                            type="date"
                            id='precheck-approve-date-input'
                            className="text-xs bg-white text-start"
                            name="crm_credit_approval_date"
                            disabled={clicked}
                            onChange={(e) => this.update_account(e)}
                        />
                        :
                        <InputGroup.Text className="text-nowrap text-xs p-1 w-75 bg-transparent">
                            {`${selected_account.crm_credit_approval_date ? selected_account.crm_credit_approval_date : 'N/A'}`}
                        </InputGroup.Text>}
                </InputGroup>
            </Col>;

        const last_activity_view =
            <Col xl={12} className="my-2">
                <InputGroup className="d-flex flex-row">
                    <InputGroup.Text className="text-nowrap text-xs p-1 text-bold pe-3">
                        <bdi className="align-self-start">Last Activity</bdi>
                    </InputGroup.Text>
                    {selected_account.last_activity_remarks ?
                        <Form.Control
                            disabled={true}
                            className="text-xs bg-white text-start opacity-75"
                            as="textarea"
                            style={{ whiteSpace: 'pre-wrap' }}
                            value= {`${selected_account.activity_id}: [${selected_account.action_date_str}] ${selected_account.prev_salesperson_id}: ${selected_account.last_activity_remarks}`}
                        />
                        :
                        <Form.Control
                            disabled={true}
                            className="text-xs bg-white text-start opacity-75"
                            style={{ whiteSpace: 'pre-wrap' }}
                            value="No activities have been completed on this account yet."
                        />}
                </InputGroup>
            </Col>
            



     

  


        return (selected_account ?
            <Container fluid="xs" className="d-flex flex-column px-4">
                <Row>
                    {enabled_property_view('Name', 'name', selected_account.name)}
                    {disabled_property_view('Code', selected_account.id)}
                    {disabled_property_view('Type', selected_account.salesperson_id === 'UNCLAIMD' ? `Unclaimed ${selected_account.type_of}` : selected_account.type_of)}
                </Row>

                <Row>
                    {enabled_property_view('DBA/CO', 'remarks', selected_account.remarks)}
                    {this.get_lam_property(selected_account)}
                    {(this.props.role === 'admin' || this.props.role === 'credit') ? categories_property_view :disabled_property_view('Category', selected_account.category)}
                </Row>

                <Row>
                    {commodity_property_view}
                    {this.get_gm_property(selected_account)}
                    {disabled_property_view('Entered Date', selected_account.entered_date)}
                </Row>

                <Row>
                    {this.get_phone_property(selected_account)}
                    {start_date_property_view}
                    {disabled_property_view('Credit Limit', numericFormatter(selected_account.credit_limit.toString(), utilityFunctions.currency_format_props))}
                </Row>

                <Row>
                    {this.get_website_property(selected_account)}
                    {conversion_date_property_view}
                   
                    {disabled_property_view('High Balance', numericFormatter(selected_account.high_balance.toString(), utilityFunctions.currency_format_props))}
                </Row>

                <Row>
                    {address_property_view}
                  
                    {disabled_property_view('Last Ship Date', selected_account.last_ship_date)}
                    {disabled_property_view('Orders Billed', numericFormatter(selected_account.billed_loads.toString(), utilityFunctions.number_format_props))}
                </Row>

                <Row>
                    {location_property_view}
                    
                    {disabled_property_view('Expiry Date', selected_account.expiry_date)}
                    {disabled_property_view('Orders Paid', numericFormatter(selected_account.paid_loads.toString(), utilityFunctions.number_format_props))}   
                </Row>
                <Row>
                    <div>
                        
                    </div>
                    {request_date_property_view}
                    {precheck_date_property_view}
                </Row>
          
                <Row>{last_activity_view}</Row>
                {this.get_info_update_button()}
                {this.display_gift_button(selected_account)?
                    <GiftAccountModal
                        show={show_gifted}
                        onHide={() => this.setState({ show_gifted: false })}
                        msal_account={this.props.msal_account}
                        selected_account_data={selected_account_data}
                        update_selected_account_data={(property, prop) => this.props.update_selected_account_data(property, prop)}
                        salesperson_list={this.props.salesperson_list}
                    /> :
                null}
               
            </Container>
            
           :null
        )
    }
}