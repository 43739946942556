import React, { Component } from 'react';
import {Form, InputGroup, Modal, Table} from 'react-bootstrap';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';

export class ViewProspectReportModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activity_id: '',
            customer_name: '',
            customer_type:'',
            sort_direction: 'L',
            sort_field: 'Completed',
            drill_down:''
        };
    }
    get_customer_types() {
        const prospect_actions = this.props.prospect_actions
        let customer_types = [];
        if (prospect_actions.length > 0) {
            prospect_actions.map((d) => {
                if (!customer_types.includes(d.type_of)) {
                    customer_types.push(d.type_of)
                }
            })
            return customer_types
        }
    }

    get_activity_types() {
        const prospect_actions = this.props.prospect_actions 
        let activity_types = [];
        if (prospect_actions.length > 0) {
            prospect_actions.map((d) => {
                if (!activity_types.includes(d.activity_id)) {
                    activity_types.push(d.activity_id)
                }
            })
            return activity_types
        }
    }

    get_customers() {
        const prospect_actions = this.props.prospect_actions 
        let customer_names = [];
        if (prospect_actions.length > 0) {
            prospect_actions.map((d) => {
                if (!customer_names.includes(d.customer_name)) {
                    customer_names.push(d.customer_name)
                }
            })
            return customer_names
        }
    }

    get_overview_headers() {
        let style = 'text-sm py-1'
        const headers_list = ['Activity', '# Prospect', '# Customer', 'Total'];
        let headers = [];
        headers_list.map(d => headers.push({
            column_title: d,
            sort_direction: this.state.sort_direction,
            is_sortable: 'Y',
            header_style: style
        }))
        return headers;
    }

    get_headers() {
        const headers_list = ['Activity', 'Completed', 'Customer', 'Type','Remark'];
        let headers = [];
        headers_list.map(d => headers.push({
            column_title:d,
            sort_direction: this.state.sort_direction,
            is_sortable: 'Y',
            header_style: 'py-0 pe-3 text-sm'
        }))
        return headers;
    }

    get_data(list) {
        const sort_field = this.state.sort_field
        const sort_direction = this.state.sort_direction
        let data = [];
        let style = 'py-0 text-sm text-nowrap';
        let new_list = list;
        if (Array.isArray(new_list)) {
            const sortingStrategies = {
                Completed: (a, b) => new Date(a.action_date) - new Date(b.action_date),
                Activity: (a, b) => a.activity_id.localeCompare(b.activity_id),
                Account: (a, b) => a.customer_name.localeCompare(b.customer_name),
                LAM: (a, b) => a.name.localeCompare(b.name),
                Remark: (a, b) => a.remarks.length - b.remarks.length,
            };

            new_list = new_list.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
        }
        new_list.map(d => data.push(
            {
                row_style: '',
                cells: [
                    { cell: d.activity_id, cell_style: style },
                    { cell: d.action_date_str, cell_style: style },
                    { cell: d.customer_name, cell_style: style },
                    { cell: d.type_of, cell_style: style },
                    { cell: d.remarks, cell_style: 'py-0 text-sm' },
                ],
                data:d,
            }
        ))
        return data;
    }

    get_overview_data(activity_ids) {
        const list = this.props.prospect_actions;
        let data = [];
        let style = 'text-sm py-1'
        if (activity_ids) {
            activity_ids.map(d => data.push(
                {
                    row_style: '',
                    cells: [
                        { cell: d, cell_style: style },
                        { cell: list.filter(s => s.activity_id === d && s.type_of !== 'Customer').length, cell_style: style },
                        { cell: list.filter(s => s.activity_id === d && s.type_of === 'Customer').length, cell_style: style },
                        { cell: list.filter(s => s.activity_id === d).length, cell_style: style }
                    ],
                    data: d,
                }
            ))
        }
    
        return data;
    }

    render() {
        const {customer_name, sort_direction, sort_field, customer_type, drill_down } = this.state;
        let prospect_actions = this.props.prospect_actions ? this.props.prospect_actions : [];
        if (drill_down) {
            prospect_actions = prospect_actions.filter(s => s.activity_id === drill_down)
        }
        if (customer_type) {
            prospect_actions = prospect_actions.filter(s => s.customer_name === customer_name)
        }
        if (customer_name) {
            prospect_actions = prospect_actions.filter(s => s.type_of === customer_type)
        }

        const activity_types = this.get_activity_types();
        const customers = this.get_customers();
        const customer_types = this.get_customer_types();
        const date_range = this.props.date_range;

        const overview_table = 
            <div className="my-2">
                <SortableTable
                    onSort={(prop) =>
                        this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                    }
                    row_click={(d) => this.setState({ drill_down: JSON.parse(d) })}
                    table_headers={this.get_overview_headers()}
                    table_data={this.get_overview_data(activity_types)}
                    table_title={`Activities (${prospect_actions.length})`}
                    sort_field={sort_field}
                />
            </div>

        return (

                <div>
                    <Modal
                        {...this.props}
                        size="xl"
                        fullscreen="md-down"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                            <div className="flex-grow-2">
                                <h5 onClick={() => this.props.onHide()} className="opacity-75 hover-over p-2">
                                    X
                                </h5>
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-grow-1">
                            <h4 className="w-100 text-center p-0 pt-2">Prospect Activity Report</h4>
                            <h6 className="text-center opacity-75">{date_range ? `[${date_range.start_date}] -> [${date_range.end_date}]`:''}</h6>
                            </div>

                            <div className="flex-grow-2"></div>
                        </Modal.Header>
                    <Modal.Body style={{ height: '70vh', overflow: 'auto' }}>
                        <div className="d-flex flex-column">
           
                            {overview_table}
                           
                            {drill_down ?
                                <div className="d-flex flex-column">
                                    <InputGroup className="mb-2">
                                <InputGroup.Text className="text-sm">Type</InputGroup.Text>
                                <Form.Select value={customer_type} onChange={(e) => this.setState({ customer_type: e.target.value })} className="text-sm">
                                    <option value=''>{`All`}</option>
                                    {customer_types.map(d =>
                                        <option value={d} key={d}>{d}</option>
                                    )}
                                </Form.Select>
                                <InputGroup.Text className="text-sm">Customer</InputGroup.Text>
                                <Form.Select value={customer_name} onChange={(e) => this.setState({ customer_name: e.target.value })} className="text-sm">
                                    <option value=''>Any</option>
                                    {customers.map(d =>
                                        <option value={d} key={d}>{d}</option>
                                    )}
                                </Form.Select>
                                    </InputGroup>
                                    <SortableTable
                                        onSort={(prop) =>
                                            this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                                        }
                                        row_click={() => null}
                                        table_headers={this.get_headers()}
                                        table_data={this.get_data(prospect_actions)}
                                        table_title={drill_down}
                                        sort_field={sort_field}
                                    />
                                </div>
                                 :
                                null
                            }
                           
                        </div>
                        
                        </Modal.Body>
                    </Modal>
                </div> 
        );
    }
}
