import { useState, useRef } from 'react'
import { Form, InputGroup, Button } from 'react-bootstrap'
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as commentFunctions from '../Functions/CommentFunctions';
import * as customerFunctions from '../Functions/CustomerFunctions';
import * as svg_icons from '../../Media/svg_exports';
import { format} from 'date-fns'

export const CreditApplicationUpload = (prop) => {
    const inputRef = useRef();
    const [clicked, setClicked] = useState(false)
    const [checking, setChecking] = useState(false)


    async function post_comment(file) {
        setClicked(true)
        try {
            let selected_account = prop.selected_account
            let comments = prop.comments ? prop.comments : [];
            let new_comment = prop.new_comment;
            if (new_comment) {
                new_comment.comment_type_id = 'CUSCREDI';
                new_comment.attach_filename = file.name;
                new_comment.comments = 'Credit application attached';
            }
            const comment_data = await commentFunctions.Post(new_comment);
            await prop.add_comment_audit(comment_data, 'Initial')
            await commentFunctions.Upload(comment_data.company_id, comment_data.parent_row_id, comment_data.id, file);
            let updated_comment = comment_data;
            updated_comment.attachment = file;
            updated_comment.attach_filename = file.name;
            comments.push(updated_comment);
            
            
            if (selected_account) {
                selected_account.credit_application = 'Y'
                selected_account.credit_check_date = format(new Date(), 'MM-dd-yyyy')
            }
            await customerFunctions.Credit_App_Uploaded(selected_account.company_id, selected_account.id);
            prop.update_selected_account_data('comments', comments);
            prop.update_selected_account_data('account', selected_account);
            prop.send_email(selected_account);
            prop.reset_new_comment();
            setClicked(false)

        } catch (e) {
            console.log(e)
        }
    }

    async function handle_file_change(e) {
        if (e.target.files) {
            setChecking(true)
            const file = e.target.files[0];
            const converted_pdf = await utilityFunctions.Convert_To_PDF(file, prop.selected_account.company_id, file.name, prop.current_user.user_id);
            const checked_pdf = utilityFunctions.Check_File_Sizes(converted_pdf);
            setChecking(false)
            if (checked_pdf) {
                await post_comment(converted_pdf)
            }
     
     
        }
    }

    let button_label = 'Upload Credit Application';
    if (clicked) {
        button_label='Uploading...'
    }
    if (checking) {
        button_label = 'Checking attachment...'
    }

    const attach_file_form =
        <Form.Control
            type="file"
            accept=".pdf"
            disabled={checking || clicked}
            onChange={handle_file_change}
            className="p-0 m-0"
            ref={inputRef}
            style={{ visibility: 'hidden', width: '0%', width:'0px', height:'0px' }}
        />

    return(<div className="mb-3 w-100">
        <Button
            className="text-sm d-flex justify-content-center w-100"
            onClick={() => inputRef.current.click()}
            size="sm"
            variant={checking || clicked ?'outline-success':'success'}

            disabled={checking || clicked}>
            {checking || clicked ?null:svg_icons.upload_icon}
            <bdi className="ms-2">{button_label}</bdi>
        </Button>
        {attach_file_form}
        </div>)

}


