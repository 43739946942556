export const background_color = '#fff3e8';

export const light_orange = '#FFF3E0';
export const med_orange = '#FFAB40';
export const dark_orange = '#E65100';

export const light_red = '#FFEBEE';
export const dark_red = '#dc3545';

export const light_green = '#E8F5E9';
export const dark_green = '#28a745';

export const light_yellow = '#FFFDE7';
export const dark_yellow = '#ffc107';
export const bright_yellow = '#ffdf02';

export const light_purple = '#F3E5F5';
export const dark_purple = '#4A148C';

export const light_blue = '#E3F2FD';
export const dark_blue = '#007bff';

export const light_gray = '#F5F5F5';
export const dark_gray = '#332D2D';

export const border_color = '#dde2eb';
