import React, { Component } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import { format, getYear, isBefore, isAfter} from 'date-fns';
import { ViewProspectReportModal } from './ViewProspectActionReportModal';


export class ProspectActionReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report_type: 'Q',
            year: getYear(Date.now()),
            month:1,
            quarter:1,
            date_range: { start_date: `${getYear(Date.now())}-01-01`, end_date: `${getYear(Date.now())}-03-31` },
            show_report:false
        };
    }

    update_report_type(e) {
        let date_range = this.state.date_range;
        const year = this.state.year;
        if (e.target.value === 'Q') {
            date_range = { start_date: `${year}-01-01`, end_date: `${year}-03-31` }
        }
        else if (e.target.value === 'M') {
            date_range = { start_date: `${year}-01-01`, end_date: `${year}-01-31` }
        }
        this.setState({ report_type: e.target.value, quarter: 1, month: 1, date_range: date_range })
    }

    update_quarter_range(e) {
        const quarter=parseInt(e.target.value)
        let date_range = this.state.date_range;
        const year = this.state.year;
        if (quarter === 1) {
            date_range = { start_date: `${year}-01-01`, end_date: `${year}-03-31` }
        }
        else if (quarter === 2) {
            date_range = { start_date: `${year}-04-01`, end_date: `${year}-06-30` }
        }
        else if (quarter === 3) {
            date_range = { start_date: `${year}-07-01`, end_date: `${year}-09-30` }
        } else {
            date_range = { start_date: `${year}-10-01`, end_date: `${year}-12-31` }
        }
        this.setState({ date_range: date_range, quarter: quarter, month:1})
    }

    update_monthly_range(e) {
        const month = parseInt(e.target.value);
        const year = this.state.year;
        let date_range = this.state.date_range;
        if (month < 10) {
            date_range = { start_date: `${year}-0${month}-01`, end_date: `${year}-0${month}-31` }
        } else {
            date_range = { start_date: `${year}-${month}-01`, end_date: `${year}-${month}-31` }
        }   
        this.setState({ date_range: date_range, quarter: 1, month:month })
    }

    update_custom_range(e) {
        let date_range = this.state.date_range;
        date_range[e.target.name]=e.target.value
        this.setState({ date_range: date_range, quarter: 1, month: 1 })
    }





    render() {
        const { report_type, date_range, year, quarter, show_report } = this.state;
        const months_array = Array.from({ length: 12 }, (_, i) => i + 1);
        const years_array = Array.from({ length: 16 }, (_, i) => (getYear(Date.now()) - 14) + i);
        const prospect_actions = this.props.prospect_actions ?
            this.props.prospect_actions.filter(s => isAfter(new Date(s.action_date), new Date(date_range.start_date)) && isBefore(new Date(s.action_date), new Date(date_range.end_date)))
            : [];

        const view_date =
            <InputGroup className="mb-2">
                <InputGroup.Text className="text-sm w-25">Start Date</InputGroup.Text>
                <InputGroup.Text className="text-sm bg-white w-25">{date_range.start_date}</InputGroup.Text>
                <InputGroup.Text className="text-sm w-25">End Date</InputGroup.Text>
                <InputGroup.Text className="text-sm bg-white w-25">{date_range.end_date}</InputGroup.Text>
            </InputGroup>


        const select_type=
            <InputGroup className="mb-2">
                <Form.Select value={report_type} onChange={(e) => this.update_report_type(e)} className="text-sm">
                    <option value="Q">Quarterly</option>
                    <option value="M">Monthly</option>
                    <option value="C">Custom</option>
                </Form.Select>
            </InputGroup>;

        const select_quarter=
        <InputGroup className="mb-2">
                <Form.Select  onChange={(e) => this.update_quarter_range(e)} className="text-sm" value={quarter}>
                    {Array.from({ length: 4 }, (_, i) => i + 1).map(d =>
                        <option value={d} key={ d}>{ `Q${d}`}</option>
                    )}
                </Form.Select>
                <Form.Select value={year} onChange={(e) => this.setState({ year: e.target.value })} className="text-sm">
                    {years_array.map(d =>
                        <option value={d} key={ d}>{ d}</option>
                    )}
                </Form.Select>
            </InputGroup>;

        const select_month =
            <InputGroup className="mb-2">
                <Form.Select onChange={(e) => this.update_monthly_range(e)} className="text-sm" value={quarter}>
                    {months_array.map(d =>
                        <option value={d} key={d}>{format(new Date(`2024-${d}-15`), 'MMMM')}</option>
                    )}
                </Form.Select>
                <Form.Select value={year} onChange={(e) => this.setState({ year: e.target.value })} className="text-sm">
                    {years_array.map(d =>
                        <option value={d} key={d}>{d}</option>
                    )}
                </Form.Select>
            </InputGroup>;

        const select_custom =
            <InputGroup className="mb-2">
                <InputGroup.Text className="text-sm w-25">Start Date</InputGroup.Text>
                <Form.Control
                    value={date_range.start_date}
                    className="text-sm w-25"
                    onChange={(e) => this.update_custom_range(e)}
                    name="start_date"
                    type="date" />
                <InputGroup.Text className="text-sm w-25">End Date</InputGroup.Text>
                <Form.Control
                    value={date_range.end_date}
                    min={date_range.start_date }
                    className="text-sm w-25"
                    onChange={(e) => this.update_custom_range(e)}
                    name="end_date"
                    type="date" />
            </InputGroup>;

        return (
            <div className="d-flex flex-column justify-content-start" style={{ maxHeight: '30vh', overflow: 'auto' }}>
               
                {select_type}
                {report_type === 'Q' ? select_quarter : null}
                {report_type === 'M' ? select_month : null}
                {report_type==='C'?select_custom: view_date}
                <Button className="btn-op70 text-sm my-2" variant="success" size="sm" onClick={() => this.setState({ show_report: true })}>View Report</Button>
                <ViewProspectReportModal
                    show={show_report}
                    onHide={() => this.setState({ show_report: false })}
                    date_range={date_range }
                    prospect_actions={prospect_actions} />
            </div>
        );
    }
}