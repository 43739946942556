import React, { Component } from 'react';
import { isSameISOWeek, toDate, addDays} from 'date-fns';
import * as baseColors from '../Functions/BaseColors';
import {
    Tooltip,
    XAxis,
    YAxis,
    PieChart,
    Pie,
    Cell,
    BarChart,
    Legend,
    Bar,
    LabelList,
    ResponsiveContainer,
} from 'recharts';
import { BarGraphToolTip } from './BarGraphToolTip';
import * as svg_icons from '../../Media/svg_exports'

export class ActivitiesCompletedGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true,
        };
    }

    get_data() {
        const prospect_actions = this.props.prospect_actions ? this.props.prospect_actions.filter(s => s.status === 'C') : [];
        // Track the daily counts where index 0 is Sunday.
        const countThisWeekByDay = [0, 0, 0, 0, 0, 0, 0];
        const countLastWeekByDay = [0, 0, 0, 0, 0, 0, 0];

        prospect_actions.forEach((item) => {
            const current_completed_date = new Date(item.action_date);
            const dayOfWeek = toDate(current_completed_date).getDay();

            if (isSameISOWeek(current_completed_date, new Date())) {
                countThisWeekByDay[dayOfWeek]++;
            } else if (isSameISOWeek(current_completed_date, addDays(new Date(), -7))) {
                countLastWeekByDay[dayOfWeek]++;
            }
        });

        const returnList = [
            { Day: 'MON', Num: countThisWeekByDay[1], Num_Last: countLastWeekByDay[1] },
            { Day: 'TUE', Num: countThisWeekByDay[2], Num_Last: countLastWeekByDay[2] },
            { Day: 'WED', Num: countThisWeekByDay[3], Num_Last: countLastWeekByDay[3] },
            { Day: 'THU', Num: countThisWeekByDay[4], Num_Last: countLastWeekByDay[4] },
            { Day: 'FRI', Num: countThisWeekByDay[5], Num_Last: countLastWeekByDay[5] },
        ];
        return returnList;
    }

    render() {
        const { open_tile } = this.state;
        const bar_chart_data = this.get_data();


        return (
                <div className="d-flex flex-column justify-content-center w-100 h-100">
                    <BarChart
                        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                        width={window.innerWidth * 0.24}
                        height={window.innerHeight * 0.12}
                        data={bar_chart_data}
                        style={{ alignSelf: 'center' }}
                    >
                        <XAxis dataKey="Day" className="text-sm" />
                        <YAxis hide={true} />
                        <Tooltip content={<BarGraphToolTip color="success" />} />
                        <Bar dataKey="Num_Last" fill={baseColors.light_green}>
                            <LabelList dataKey="Num_Last" position="top" />
                        </Bar>
                        <Bar dataKey="Num" fill={baseColors.dark_green}>
                            {' '}
                            <LabelList dataKey="Num" position="top" />
                        </Bar>
                </BarChart>
                <div className="d-flex flex-row justify-content-between">
                    <label onClick={() => this.props.update_graph_view('released')} className='hover-over'>{svg_icons.back_arrow}</label>
                    <label className='text-bold text-med text-success'>Activities Completed</label>
                    <label onClick={() => this.props.update_graph_view('new')} className='hover-over'>{svg_icons.forward_arrow}</label>
                </div>
                
                </div> 
        );
    }
}