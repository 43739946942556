import { differenceInWeeks, differenceInDays } from 'date-fns';
import React, { Component } from 'react';
import { Card, Form, Table, Button } from 'react-bootstrap';
import SortableTable from './SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';

export class SalespersonViewer extends Component {
    static displayName = SalespersonViewer.name;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: 'L',
            sort_field: 'Activities (Week)'
        };
    }

    get_headers() {

        const headers_list = [
            'LAM',
            'ID',
            'Start Date',
            'Activities (Today)',
            'Activities (Week)',
            '# Prospects',
            '# Customers',
            '# Expiring 14 Days',
            '# Dormant 90 Days'
        ]
        let headers = []
        headers_list.map(s => headers.push({
            column_title: s,
            sort_direction: this.state.sort_direction,
            is_sortable: 'Y',
            header_style: 'py-1 text-xs text-nowrap opacity-75'
        }))
        return headers;
    }

    get_table() {
        let current_salespersons = this.props.salespersons ? this.props.salespersons : [];
        const salesperson_data = [];
        let cell_style = 'text-xs text-nowrap'
        current_salespersons.map((d) =>
            salesperson_data.push(
                {
                    row_style: this.props.selected_salesperson ? this.props.selected_salesperson.salesperson_id === d.salesperson_id ? 'table-selected' : '' : '',
                    cells: [
                        { cell: d.name, cell_style: cell_style },
                        { cell: d.salesperson_id, cell_style: cell_style },
                        { cell: d.sales_start_date ? `${d.sales_start_date} (${differenceInWeeks(new Date(), d.sales_start_date)} weeks)` : '0 weeks', cell_style: cell_style },
                        { cell: d.completed_today, cell_style: cell_style },
                        { cell: d.completed_this_week, cell_style: cell_style },
                        { cell: d.num_prospects, cell_style: cell_style },
                        { cell: d.num_customers, cell_style: cell_style },
                        { cell: d.num_expiring_soon, cell_style: cell_style },
                        { cell: d.num_dormant_soon, cell_style: cell_style },
                    ],
                    data: d,

                })
        )

        if (salesperson_data.length) {
            return (<SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: this.state.sort_direction === 'H' ? 'L' : 'H' })
                }
                table_headers={this.get_headers()}
                table_data={salesperson_data}
                row_click={(prop) => this.props.setSalesperson(JSON.parse(prop))}
                table_title=""
                sort_field={this.state.sort_field}
            />)
        } else {
            return null;
        }
    }




    render() {
        const { sort_field, sort_direction, display_type } = this.state;
        let current_salespersons = this.props.salespersons ? this.props.salespersons : [];

        //#region Sort Salespersons
        if (current_salespersons.length > 0) {
            const sortingStrategies = {
                LAM: (a, b) => a.name.localeCompare(b.name),
                ID: (a, b) => a.salesperson_id.localeCompare(b.salesperson_id),
                'Start Date': (a, b) => differenceInDays(new Date(a.sales_start_date), new Date(b.sales_start_date)),
                'Weeks Active': (a, b) => differenceInDays(new Date(a.sales_start_date), new Date(b.sales_start_date)),
                'Activities (Today)': (a, b) => a.completed_today - b.completed_today,
                'Activities (Week)': (a, b) => a.completed_this_week - b.completed_this_week,
                '# Prospects': (a, b) => a.num_prospects - b.num_prospects,
                '# Customers': (a, b) => a.num_customers - b.num_customers,
                '# Expiring 14 Days': (a, b) => a.num_expiring_soon - b.num_expiring_soon,
                '# Dormant 90 Days': (a, b) => a.num_dormant_soon - b.num_dormant_soon,
            };
            current_salespersons = current_salespersons.sort(
                sortByStrategy(sortingStrategies, sort_field, sort_direction),
            );
        }
        //#endregion Sort Salespersons

        //#region Salesperson Data



        const salesperson_table = (
            <div className="d-flex flex-column justify-content-center w-100" >
               
        
                    <Form.Control
                        type="text"
                        className="mb-3 rounded"
                        style={{ fontSize: '0.75rem' }}
                        placeholder="Search by name..."
                        onChange={(e) => this.props.updateFilter(e.target.value)}
                    />
     
                <div className="d-flex flex-column justify-content-center w-100 table-round p-0  m-0 bg-white">


                    { this.get_table()}
                </div>
       
            </div>
        );
        //#endregion Salesperson Table
        let button_style ='underlined hover-over text-secondary text-bold p-0 text-start'


        return (
            <div className="d-flex flex-column w-100 justify-content-start p-2 h-100" >
                <h6 className="text-muted tile-title">
                    {this.props.rev_code + ' (' + current_salespersons.length + ')'}
                </h6>
        

                <div style={{ overflow: 'auto', height: '30rem', resize: 'vertical' }}>{salesperson_table}</div>
            </div>
        );
    }
}
