import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import LoadingIcons from 'react-loading-icons';

export class LoadingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            additional_details: '',
        };
    }

    render() {
        const { additional_details } = this.state;

        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="w-100 ">
                        <h4 className="w-100 text-center p-0 pt-2">Loading {this.props.title}...</h4>
                    </Modal.Header>
                </Modal>
            </div>
        );
    }
}
