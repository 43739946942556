import React, { Component } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, InputGroup, Row, Card } from 'react-bootstrap';
import { ViewAccountModal } from './Modals/Account/ViewAccountModal';
import * as customerFunctions from './Functions/CustomerFunctions';
import { LoadingModal } from './Modals/LoadingModal';
import { ViewAccountDiv } from './Modals/Account/ViewAccountDiv';
import * as svg_icons from '../Media/svg_exports'
import { AddNewProspect } from '../components/AddProspect/AddNewProspect'
export class PhonePage extends Component {
    static displayName = PhonePage.name;
    constructor(props) {
        super(props);
        this.state = {
            show_phone: false,
            phone_results_list: [],
            phone_results_loaded: false,
            phone_number_searching: false,
            phone: '',
            selected_account: null,
            selected_account_loaded: false,
            show_selected_account: false,
            account_clicked: false,
            hide_numbers: false,
            show_add_prospect: false,
            selected_accounts_list:[]
        };
    }


    async componentDidMount() {
        try {
        
            if (window.location.pathname.includes('incomingPhone')) {
                this.setState({
                    show_phone: false,
                    phone_results_list: [],
                    phone_results_loaded: false,
                    phone_number_searching: true,
                    hide_numbers: true,
                    selected_account: null,
                    selected_account_loaded: false,
                    show_selected_account: false
                })
                if (window.location.pathname.split('/').length > 2) {
                    const phone = window.location.pathname.split('/')[2].replace('+', '')
                    const phone_results = await customerFunctions.Get_By_Phone(this.props.salesperson.company_id, phone);
                    console.log(phone_results)
                    if (phone_results.length > 1) {
                        this.setState({
                            show_phone: true,
                            phone_results_list: phone_results,
                            phone_results_loaded: true,
                            phone_number_searching: false,
                            phone: phone,
                            hide_numbers: false,
                            selected_accounts_list: phone_results
      
                        })
                    } else if (phone_results.length === 1) {
                        this.setState({
                            show_phone: false,
                            phone_results_list: phone_results,
                            phone_results_loaded: true,
                            phone_number_searching: false,
                            phone: phone,
                            selected_accounts_list: phone_results
                        })
                        this.View_Account(phone_results[0].company_id, phone_results[0].parent_row_id)
                    } else {
                        this.setState({
                            show_phone: false,
                            phone_results_list: [],
                            phone_results_loaded: true,
                            phone_number_searching: false,
                            phone: phone,
                            show_add_prospect: true,
                            selected_accounts_list: []
                        })
                    }

                }

            }
        } catch (e) {
            console.log(e);
            this.setState({ current_user: null, user_loaded: false });
        }
    }


    render() {
        const { show_phone,
            phone_results_list,
            phone_results_loaded,
            phone_number_searching,
            phone,
            selected_account,
            selected_account_loaded,
            show_selected_account,
            account_clicked,
            hide_numbers,
            show_add_prospect,
            selected_accounts_list

        } = this.state;
        const results_list = phone_results_list && this.props.salesperson ? phone_results_list.filter(s => s.salesperson_id !== this.props.salesperson.salesperson_id) : [];
        const likely_result = phone_results_list && this.props.salesperson ? phone_results_list.find(s => s.salesperson_id === this.props.salesperson.salesperson_id) : null;

        const add_prospect_page = show_add_prospect ? <AddNewProspect
            msal_account={this.props.msal_account}
            salesperson={this.props.salesperson}
            role={this.props.role}
            width={this.props.width}
            user_routes={this.props.user_routes}
            salesperson_list={this.props.salesperson_list}
            commodities={this.props.commodities}
            categories={this.props.categories}
            prospects={this.props.prospects}
            customers={this.props.customers}
            add_account={(prop) => this.props.add_account(prop)}
            release_account={(prop) => this.props.release_account(prop)}
            prospect_actions={this.props.prospect_actions}
            add_users_action={(prop) => this.props.add_users_action(prop)}
            update_users_action={(prop) => this.props.update_users_action(prop)}
            incoming_requests={this.props.incoming_requests}
            outgoing_requests={this.props.outgoing_requests}
            no_action_requests={this.props.no_action_requests}
            action_requests={this.props.action_requests}
            update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
            add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
            open_report_issue={() => this.props.open_report_issue()}
        /> : null;

        return <div className={`d-flex w-100 justify-content-center h-100 p-3`} >
            <Card className="w-75 mt-2" style={{ overflow: 'auto' }}>
                <Card.Header className="p-2 d-flex flex-row">
                    <label className="ps-2"></label>
                    <h5 className="tile-title text-bold text-secondary p-0 m-0 w-100 text-start">{selected_account ? `${selected_account.account.name}` : `Select Customer For ${phone}`} </h5>
                    {hide_numbers && results_list ?
                        <label className="hover-over ps-2" onClick={() => this.setState({ hide_numbers: false })}>{svg_icons.left_chevron}</label> :
                        <label className="hover-over ps-2" onClick={() => this.setState({ hide_numbers: true })}>{svg_icons.down_chevron}</label>
                    }
                   
                </Card.Header>
                <Card.Body>
                    {hide_numbers ? null :
                        <div className="d-flex flex-column">
            
                            {results_list ? (
                                <div className="d-flex flex-column">
                                    {likely_result ?
                                        <InputGroup className="mb-2 w-100 d-flex border border-success rounded">
                                            <InputGroup.Text className="text-xs" style={{ width: '8rem' }}>
                                                Account
                                            </InputGroup.Text>
                                            <InputGroup.Text className="text-xs bg-white text-start flex-grow-1" style={{ whiteSpace: 'pre-wrap' }} style={{ width: '10rem' }}>
                                                {likely_result.customer_name}
                                            </InputGroup.Text >
                                            <InputGroup.Text className="text-xs" style={{ width: '8rem' }}>
                                                Contact
                                            </InputGroup.Text>
                                            <InputGroup.Text className="text-xs bg-white text-start flex-grow-1" style={{ whiteSpace: 'pre-wrap' }} style={{ width: '10rem' }}>
                                                {`${likely_result.name} ${likely_result.title ? `(${likely_result.title})` : ''}`}
                                            </InputGroup.Text >
                                            <InputGroup.Text className="text-xs" style={{ width: '8rem' }}>
                                                LAM
                                            </InputGroup.Text>
                                            <InputGroup.Text className="text-xs bg-white text-start flex-grow-1" style={{ whiteSpace: 'pre-wrap' }} style={{ width: '10rem' }}>
                                                {`${likely_result.salesperson_name}`}
                                            </InputGroup.Text >

                                            <Button className="text-xs" variant='success' size="sm" onClick={() => this.View_Account(likely_result.company_id, likely_result.parent_row_id)}>Open</Button>
                                        </InputGroup> :
                                        null
                                    }
                                    {results_list.sort((a, b) => a.customer_name.localeCompare(b.customer_name)).map(d =>
                                        <div key={d.parent_row_id} className="d-flex flex-column justify-content-between " >
                                            <InputGroup className="mb-2 w-100 d-flex">
                                                <InputGroup.Text className="text-xs" style={{ width: '8rem' }}>
                                                    Account
                                                </InputGroup.Text>
                                                <InputGroup.Text className="text-xs bg-white text-start flex-grow-1" style={{ whiteSpace: 'pre-wrap' }} style={{ width: '10rem' }}>
                                                    {d.customer_name}
                                                </InputGroup.Text >
                                                <InputGroup.Text className="text-xs" style={{ width: '8rem' }}>
                                                    Contact
                                                </InputGroup.Text>
                                                <InputGroup.Text className="text-xs bg-white text-start flex-grow-1" style={{ whiteSpace: 'pre-wrap' }} style={{ width: '10rem' }}>
                                                    {`${d.name} ${d.title ? `(${d.title})` : ''}`}
                                                </InputGroup.Text >
                                                <InputGroup.Text className="text-xs" style={{ width: '8rem' }}>
                                                    LAM
                                                </InputGroup.Text>
                                                <InputGroup.Text className="text-xs bg-white text-start flex-grow-1" style={{ whiteSpace: 'pre-wrap' }} style={{ width: '10rem' }}>
                                                    {`${d.salesperson_name}`}
                                                    {d.salesperson_is_active === 'N' ? <i className="opacity-75 ps-2">{`(Inactive since: ${d.salesperson_inactive_date})`}</i> : ''}
                                                </InputGroup.Text >
                                                <Button className="text-xs" variant='success' size="sm" onClick={() => this.View_Account(d.company_id, d.parent_row_id)}>Open</Button>
                                            </InputGroup>


                                        </div>
                                    )}
                                    <Button className="my-3 text-sm" size="sm" variant={'success'} onClick={() => this.setState({ show_add_prospect: true, hide_numbers: true })}>Add New Prospect {svg_icons.plus_icon}</Button>
                                    
                                   
                                </div>
                            ) : null}
                        </div>
                    }
                    {show_add_prospect ?
                        <div className="d-flex flex-row justify-content-end">
                            <label className="hover-over" onClick={() => this.setState({ show_add_prospect: false, hide_numbers: false })}>{svg_icons.cancel_icon}</label>
                        </div> :
                        null
                    }
                    {add_prospect_page}
                   
                    {show_selected_account ?
                        <ViewAccountDiv
                            show={show_selected_account}
                            onHide={() => this.setState({
                                show_selected_account: false,
                                selected_account: null,
                                selected_accounts_list: []
                            })}
                            current_user={this.props.salesperson}
                            msal_account={this.props.msal_account}
                            role={this.props.role}
                            accounts_list={selected_accounts_list}
                            prospects={this.props.prospects}
                            commodities={this.props.commodities}
                            categories={this.props.categories}
                            salesperson_list={this.props.salesperson_list}
                            selected_account_data={selected_account}
                            add_account={(prop) => this.props.add_account(prop)}
                            release_account={(prop) => this.props.release_account(prop)}
                            update_accounts_list={(prop) => this.setState({ selected_accounts_list: prop })}
                            view_account={(prop) => this.view_account(prop.company_id, prop.id)}
                            update_selected_account_data={(prop) => this.setState({ selected_account: prop })}
                            update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                            add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                            add_users_action={(prop) => this.props.add_users_action(prop)}
                            update_users_action={(prop) => this.props.add_users_action(prop)}
                        /> :
                        null
                    }
                   
                </Card.Body>

            </Card>

    
            <LoadingModal show={account_clicked} title="Customer" />
            <LoadingModal show={phone_number_searching} title={`Incoming Call`} />

          
        </div>;
    }


    async View_Account(company_id, customer_id) {
        this.setState({ account_clicked: true, selected_account_loaded: false, hide_numbers:true });
        const account_data = await customerFunctions.View_Account(company_id, customer_id);
        this.setState({
            selected_account: account_data,
            selected_account_loaded: true,
            show_selected_account: true,
            account_clicked: false
        })
    }
}
