import * as utilityFunctions from './UtilityFunctions';

export async function Get_By_Customer(company_id, customer_id) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`Contact/GetByCustomer/${company_id}/${customer_id}`, options);
        const data = await response.json();
        return data;        
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Put(contact) {
    try {
        console.log(contact)
        const options = {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contact),
        };
        const response = await fetch('Contact', options);

        const data = await response.json();
        console.log(data)
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Post(contact) {
    try {
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contact),
        };
        const response = await fetch('Contact', options);

        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Delete(company_id, id) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`Contact/Delete/${company_id}/${id}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Set_Active_Status(company_id, id, status) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`Contact/SetActiveStatus/${company_id}/${id}/${status}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}