import { AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate } from '@azure/msal-react';
import React, { Component } from 'react';
import { Alert, Button,Image, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import LoadingIcons from 'react-loading-icons';
import './App.scss';
import ErrorBoundary from './ErrorBoundary';
import { loginRequest } from './authConfig';
import { RouteGuard } from './RouteGuard';
import scotlynn_header from './Media/scotlynn-header.png';
import './components/Styles/Unauthorized.css';
import * as baseColors from './components/Functions/BaseColors';
import { NavMenu } from './components/Navbars/NavMenu';
import PageFooter from './components/PageComponents/PageFooter';
import { ReportIssueModal } from './components/Modals/ReportIssueModal';
import * as utilityFunctions from './components/Functions/UtilityFunctions';
import * as commodityFunctions from './components/Functions/CommodityFunctions';
import * as salespersonFunctions from './components/Functions/SalespersonFunctions';
import * as requestFunctions from './components/Functions/RequestFunctions';
import * as customerFunctions from './components/Functions/CustomerFunctions'
import * as prospectActionFunctions from './components/Functions/ProspectActionFunctions';
import blank_icon from './Media/blank_profile_icon.png';
import * as svg_icons from './Media/svg_exports'
import { LoadingModal } from './components/Modals/LoadingModal';


import UnauthPageFooter from './components/PageComponents/UnauthPageFooter';

export default class App extends Component {
    static displayName = App.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            salesperson: null,
            msal_account: null,
            user_loaded: false,
            impersonate: false,
            role: null,
            user_routes: [],
            show_report_issue: false,
            salespersons_list: [],
            stop_update: false,
            profile_photo: { photo: blank_icon, loaded: 'Y', failed: 'N' },
            incoming_requests: { list: [], loaded: 'N', failed: 'N' },
            outgoing_requests: { list: [], loaded: 'N', failed: 'N' },
            action_requests: { list: [], loaded: 'N', failed: 'N' },
            no_action_requests: { list: [], loaded: 'N', failed: 'N' },
           
            prospect_actions: { list: [], loaded: 'N', failed: 'N' },
            prospects: { num: 0, list:[], loaded: 'N', failed: 'N' },
            customers: { num: 0, list: [], loaded: 'N', failed: 'N' },
            page_loaded: false,
            commodities: [],
            categories: [],

            show_account:false,
            selected_account_data: null,
            account_clicked: false,
            accounts_list: [],
            entered_login_id: '',
            error_message:''
        };
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.Login_User = this.Login_User.bind(this);

    }

    async componentDidMount() {
        try {
            if (this.context.accounts) {
                let account = this.context.accounts.length ? this.context.accounts[0] : null;
                if (account) {
                    //localStorage.setItem('scopes', JSON.stringify(["User.Read", "Mail.Read"]))
                    //localStorage.setItem('login_hint', account.username)
                    this.get_user_data()
                }


            }
            //else if (localStorage.getItem('scopes') && localStorage.getItem('login_hint') && !this.state.msal_account) {
            //    this.get_user_data()
            //} 
        } catch (e) {
            console.log(e)
        }
   

            

    }     

    async componentDidUpdate() { 
        try {
            if (!this.state.stop_update) {
               if (this.context.accounts && !this.state.msal_account) {
                    this.get_user_data()
                }


                //if (!localStorage.getItem('scopes') && !localStorage.getItem('login_hint') && this.context.accounts) {
                //    let account = this.context.accounts.length ? this.context.accounts[0] : null;
                //    if (account) {
                //        localStorage.setItem('scopes', JSON.stringify(["User.Read", "Mail.Read"]))
                //        localStorage.setItem('login_hint', account.username)
                //        this.get_user_data()
                //    }

                //}

                
            }
        } catch (e) {
            console.log(e)
        }
        
    }

    async get_user_data() {
        //if (localStorage.getItem("login_hint")) {
            //const silentRequest = {
            //    scopes: JSON.parse(localStorage.getItem("scopes")),
            //    loginHint: localStorage.getItem("login_hint")
            //};
        const accounts = this.context.accounts;
        if (accounts) {
            if (accounts.length) {
                const request = { scopes: ['User.Read'], account: accounts[0] };
                try {
                    await this.context.instance.acquireTokenSilent(request).then((res) => {
                        const bearer = `Bearer ${res.accessToken}`;
                        fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
                            method: 'GET',
                            headers: new Headers({
                                Authorization: bearer,
                                'Content-Type': 'application/json',
                                'client-request-id': process.env.REACT_APP_CLIENT_REQUEST_ID,
                                'cache-control': 'no-cache',
                            }),
                        }).then((blob_response) => {
                            return blob_response.blob();
                        }).then((blob_data) => {
                            if (blob_data) {
                                if (blob_data.size < 300) {
                                    this.setState({ profile_photo: { photo: blank_icon, loaded: 'Y', failed: 'N' } })

                                } else {
                                    this.setState({ profile_photo: { photo: URL.createObjectURL(blob_data), loaded: 'Y', failed: 'N' } })
                                }
                            } else {
                                this.setState({ profile_photo: { photo: blank_icon, loaded: 'Y', failed: 'Y' } })
                            }
                        }).catch((e) =>
                            this.setState({ error_message: e.message })
                        )
                        if (res.account) {
                            this.setState({ msal_account: res.account, stop_update: true });
                            let roles = res.account.idTokenClaims['roles'];

                            utilityFunctions.Validate_User(res.account).then((token) => {
                                this.set_user_role(roles).then((returned_role) => {
                                    this.Login_User(res.account, token, returned_role)
                                    commodityFunctions.Get().then((commod) => {
                                        this.setState({ commodities: commod })
                                    })
                                }


                                )


                            })
                        }

                    })

                } catch (err) {
                    this.setState({ error_message: err.message })
                    this.setState({
                        salesperson: null,
                        msal_account: null,
                        user_loaded: true,
                        role: 'unauthorized',
                        user_routes: [],
                        show_report_issue: false,
                        salespersons_list: [],
                        stop_update: true
                    })
                    const error_body = {
                        status: 401,
                        message: `A silent sign-in request was sent but none of the currently signed in user(s) match the requested login hint.`,
                        company_id: 'NA',
                        function_name: 'App -> componentDidUpdate',
                        primary_pk: 'msal',
                        table_name: 'ssoSilent',
                        user_id: localStorage.getItem("login_hint")
                    }
                    await utilityFunctions.Log_Error(error_body)
                }
            }
        }
            
      //  }

    }

    async set_user_role(roles) {
        let set_role = 'unauthorized'
        if (roles.includes('title.Developer')) {
            set_role = 'developer'
        }
        else if (roles.includes('title.Admin')) {
            set_role = 'admin'
        } else if (roles.includes('title.Credit')) {
            set_role = 'credit'
        } else if (roles.includes('title.Commission')) {
            set_role = 'commission'
        } else if (roles.includes('title.GM')) {
            set_role = 'gm'
        }  else if (roles.includes('title.STC')) {
            set_role = 'stc'
        } else if (roles.includes('title.Operations')) {
            set_role = 'operations'
        } else if (roles.includes('title.LAM')) {
            set_role = 'lam'
        } else {
            set_role = 'unauthorized'
        }
  
        let routes = [];
        const route_data = await utilityFunctions.Get_User_Routes(set_role);

        if (route_data) {
            routes = JSON.parse(route_data.routes).routes
        }
        this.setState({ role: set_role, user_routes: routes });
        return set_role;
    }

    async handleLogin() {
        try {

            const inst = this.props.instance;
            const response = await inst.loginRedirect(loginRequest);
            const data = response.json();
        } catch (e) {

            console.log(e);
        }
    }

    async handleLogout() {
        try {
            const inst = this.props.instance;
            const response = await inst.logoutRedirect(loginRequest);
        } catch (e) {
            console.log(e);
        }
    }

    add_account(account) {
        if (account) {
            if (account.type_of === 'Customer') {
                let list = this.state.customers.list
                list.push(account)
                this.setState({ customers: { num: list.length, list: list, loaded: 'Y', failed: 'N' } })
            } else {
                let list = this.state.prospects.list
                list.push(account)
                this.setState({ prospects: { num: list.length, list: list, loaded: 'Y', failed: 'N' } })
            }
        }
    }

    release_account(account) {
        if (account) {
            if (account.type_of === 'Customer') {
                let list = this.state.customers.list.filter(s=>s.id!==account.id)
                this.setState({ customers: { num: list.length, list: list, loaded: 'Y', failed: 'N' } })
            } else {
                let list = this.state.prospects.list.filter(s => s.id !== account.id)
                this.setState({ prospects: { num: list.length, list: list, loaded: 'Y', failed: 'N' } })
            }
        }
    }

    add_users_action(action) {
        let list = this.add_to_list(this.state.prospect_actions.list, action);
        this.setState({ prospect_actions: { list: list, loaded: 'Y', failed: 'N' } })   
    }

    update_users_action(action) {
        let list = this.update_list(this.state.prospect_actions.list, action);
        this.setState({ prospect_actions: { list: list, loaded: 'Y', failed: 'N' } })           
    }

    update_users_request(type, request) {
        try {
            if (type === 'incoming') {
                const update_request_list = this.update_list(this.state.incoming_requests.list, request);
                this.setState({ incoming_requests: { list: update_request_list, loaded: 'Y', failed: 'N' } })
            }
            else if (type === 'outgoing') {
                const update_request_list = this.update_list(this.state.outgoing_requests.list, request)
                this.setState({ outgoing_requests: { list: update_request_list, loaded: 'Y', failed: 'N' } })
            }
            else if (type === 'action') {
                const update_request_list = this.update_list(this.state.action_requests.list, request)
                this.setState({ action_requests: { list: update_request_list, loaded: 'Y', failed: 'N' } })
            }
            else if (type === 'no action') {
                const update_request_list = this.update_list(this.state.no_action_requests.list, request)
                this.setState({ no_action_requests: { list: update_request_list, loaded: 'Y', failed: 'N' } })
            }                     
        } catch (e) {
            console.log(e)
        }
   
    }

    add_users_request(type, request) {
        try {
                if (type === 'incoming') {
                    const update_request_list = this.add_to_list(this.state.incoming_requests.list, request);
                    this.setState({ incoming_requests: { list: update_request_list, loaded: 'Y', failed: 'N' } })
                }
                else if (type === 'outgoing') {
                    const update_request_list = this.add_to_list(this.state.outgoing_requests.list, request)
                    this.setState({ outgoing_requests: { list: update_request_list, loaded: 'Y', failed: 'N' } })
                }
                else if (type === 'action') {
                    const update_request_list = this.add_to_list(this.state.action_requests.list, request)
                    this.setState({ action_requests: { list: update_request_list, loaded: 'Y', failed: 'N' } })
                }
                else if (type === 'no action') {
                    const update_request_list = this.add_to_list(this.state.no_action_requests.list, request)
                    this.setState({ no_action_requests: { list: update_request_list, loaded: 'Y', failed: 'N' } })
                }
        } catch (e) {
            console.log(e)
        }

        let list = this.state.incoming_requests.list;
        list.push(request)
        this.setState({ incoming_requests: { list: list, loaded: 'Y', failed: 'N' } })
    }

    update_list(list, item) {
        if (item && Array.isArray(list)) {
            let c_list = list
            let index = c_list.findIndex(s=>s.id===item.id);
            c_list[index] = item;
            return c_list;
        } else {
            return [];
        }
        
    }

    add_to_list(list, item) {
        if (item && Array.isArray(list)) {
            let c_list = list;
            c_list.push(item);
            return c_list;
        } else {
            return [];
        }
        
    }

    async get_users_accounts(company_id, salesperson_id) {
        const account_data = await customerFunctions.Get_My_Accounts(company_id, salesperson_id);
        if (Array.isArray(account_data)) {
            this.setState({
                prospects: { list: account_data.filter(s => s.type_of !== 'Customer'), loaded: 'Y', failed: 'N' },
                customers: { list: account_data.filter(s => s.type_of === 'Customer'), loaded: 'Y', failed: 'N' }, })
        }
       
    }

    async set_credit_requests() {
        const incoming_data = await requestFunctions.Get_Credit_Requests();
        const category_data = await customerFunctions.Get_Categories();
        this.setState({
            incoming_requests: { list: incoming_data, loaded: 'Y', failed: 'N' },
            categories: category_data
        })

    }

    async set_gm_requests(company_id, salesperson_id) {
        const incoming_data = await requestFunctions.Get_By_Requestee(company_id, salesperson_id);
        const outgoing_data = await requestFunctions.Get_By_Requestor(company_id, salesperson_id);
        const action_data = await requestFunctions.Get_By_Responder(company_id, salesperson_id);
        const no_action_data = await requestFunctions.Get_No_Action_ACCT(company_id, salesperson_id);
        await this.get_users_accounts(company_id, salesperson_id)
        this.setState({
            incoming_requests: { list: incoming_data, loaded: 'Y', failed: 'N' },
            outgoing_requests: { list: outgoing_data, loaded: 'Y', failed: 'N' },
            action_requests: { list: action_data, loaded: 'Y', failed: 'N' },
            no_action_requests: { list: no_action_data, loaded: 'Y', failed: 'N' }
        })
      

    }

    async set_lam_requests(company_id, salesperson_id) {
        const incoming_data = await requestFunctions.Get_By_Requestee(company_id, salesperson_id);
        const outgoing_data = await requestFunctions.Get_By_Requestor(company_id, salesperson_id);
        const pa_data = await prospectActionFunctions.Get_By_Salesperson(company_id, salesperson_id);
        await this.get_users_accounts(company_id, salesperson_id)
        this.setState({
            incoming_requests: { list: incoming_data, loaded: 'Y', failed: 'N' },
            outgoing_requests: { list: outgoing_data, loaded: 'Y', failed: 'N' },
            prospect_actions: { list: pa_data, loaded: 'Y', failed: 'N' },
        })
    }

    render() {
        const {
            msal_account,
            user_loaded,
            salesperson,
            user_routes,
            show_report_issue,
            salesperson_list,
            role,
            profile_photo,
            page_loaded,
            prospects,
            customers,
            prospect_actions,
            incoming_requests,
            outgoing_requests,
            no_action_requests,
            action_requests,
            commodities,
            categories,
            account_clicked
        } = this.state;

        const routes =
            salesperson !== null ? (
                <RouteGuard
                    msal_account={msal_account}
                    salesperson={salesperson}
                    role={role}
                    user_routes={user_routes}
                    salesperson_list={salesperson_list}
                    open_report_issue={() => this.setState({ show_report_issue: true })}
                    prospects={prospects}
                    customers={customers}
                    commodities={commodities}
                    categories={categories}
                    add_account={(prop) => this.add_account(prop)}
                    release_account={(prop) => this.release_account(prop)}
                    prospect_actions={prospect_actions}
                    add_users_action={(prop) => this.add_users_action(prop)}
                    update_users_action={(prop) => this.update_users_action(prop)}
                    incoming_requests={incoming_requests}
                    outgoing_requests={outgoing_requests}
                    no_action_requests={no_action_requests}
                    action_requests={action_requests}
                    add_users_request={(type, prop) => this.add_users_request(type, prop)}
                    update_users_request={(type, prop) => this.update_users_request(type, prop)}

                    //Admin Only Props
                    get_users_accounts={(company_id, salesperson_id) => this.get_users_accounts(company_id, salesperson_id)}
                    set_gm_requests={(company_id, salesperson_id) => this.set_gm_requests(company_id, salesperson_id)}
                    set_lam_requests={(company_id, salesperson_id) => this.set_lam_requests(company_id, salesperson_id)}
                    set_credit_requests={() => this.set_credit_requests()}
                    
                ></RouteGuard>
            ) : null;

        const error_page = <div className="d-flex flex-column">
            <Alert variant="danger">
                <h6 className="portal-tile-title">Something went wrong</h6>
                <hr />
                <p>Something went wrong when loading this page. <bdi className='underlined text-primary hover-over' onClick={() => window.location.reload()}>Click here</bdi> to refresh the page.</p>

            </Alert>
        </div>

        const error_boundary = msal_account ? (
            <ErrorBoundary fallback={error_page}>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PAGE_TITLE}`}</title>
                    <script type="text/javascript">
                        {`(function (c, l, a, r, i, t, y) { c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }; t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i; y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);window.clarity('set', "Display Name", "${msal_account?msal_account.name:''}");})(window, document, "clarity", "script", "fkhl38kh2q");`}
                    </script>
                </Helmet>
                <AuthenticatedTemplate as="div" >
                    <div className="d-flex flex-column justify-content-between h-100 w-100">
                        <div>
                            <NavMenu
                                salesperson={salesperson}
                                msal_account={msal_account}
                                profilephoto={profile_photo}
                                incoming_requests={incoming_requests}
                                action_requests={action_requests}
                                user_routes={user_routes}
                                role={role}
                                prospects={prospects}
                                customers={customers}
                                prospect_actions={prospect_actions}
                            />
                            {process.env.REACT_APP_VERSION.toString() !== '1.2.0' ?
                                <Alert className="" variant="danger" style={{ borderRadius: 0 }}>
                                    <label className="text-xs">
                                        You are currently using an old version of the CRM ({process.env.REACT_APP_VERSION}). Please<bdi className="px-1 underlined hover-over text-primary" cnClick={() => window.location.reload()}>refresh</bdi>the page for the most up-to-date version.
                                    </label>
                                </Alert> : null
                            }

                            {user_loaded ? (
                                <React.StrictMode>
                                    {routes}
                                </React.StrictMode>
                            ) : (
                                <div className="d-flex flex-column justify-content-start h-100 w-100 p-5 m-0">
                                    <Alert variant="secondary" className="d-flex flex-row justify-content-center">
                                        <h5 className="pt-3">
                                            Loading
                                            <LoadingIcons.ThreeDots
                                                style={{ alignSelf: 'end', width: '2rem', marginLeft: '0.25rem' }}
                                                fill={baseColors.dark_gray}
                                                strokeOpacity={0.125}
                                                speed={0.35}
                                            />
                                        </h5>
                                    </Alert>
                                </div>
                            )}
                        </div>

                        <ReportIssueModal
                            show={show_report_issue}
                            msal_account={msal_account}
                            salesperson={salesperson}
                            role={role}
                            onHide={() => this.setState({ show_report_issue: false })}
                        />
                        <PageFooter setShowReportIssue={() => this.setState({ show_report_issue: true })} />
                    </div>



                    <LoadingModal show={account_clicked} title="Account" />
                </AuthenticatedTemplate>
            </ErrorBoundary>
        ) : null;


            return (
                
                <div className="d-flex flex-column justify-content-start h-100 w-100 p-0 m-0">
  
                  
                    {salesperson && msal_account ?
                        error_boundary :
                        null
                    }
                   

                    <UnauthenticatedTemplate>
                        <div className="unauth-background">
                            <div className="sign-in-div">
                                <div className="w-100 align-self-center d-flex flex-row justify-content-center">
                                    <div className="align-self-center d-flex flex-row justify-content-center px-2">
                                        <Image
                                            fluid
                                            src={scotlynn_header}
                                            alt="Scotlynn Header"
                                            style={{ transform: 'scale(0.8)' }}
                                        />
                                    </div>
                                    <label className="scotlynn-header-label">CRM</label>
                                </div>

                                <Button
                                    variant="warning"
                                    onClick={this.handleLogin}
                                    style={{ width: '10rem' }}
                                    className="btn-op70 align-self-center mt-5 text-bold"
                                >
                                    Log In
                                </Button>

                            </div>

                            <UnauthPageFooter />
                        </div>

                    </UnauthenticatedTemplate>

                    <LoadingModal show={!page_loaded && msal_account} title="" />
                </div>

            );
        }
    
    


    async Login_User(account, token, role) {
        if (!this.state.user_loaded && !this.state.salesperson) {
            if (account) {
                try {
                    const claims = account.idTokenClaims;
                    const login_id = claims.email.replaceAll('@scotlynn.com', '');

                    this.setState({ entered_login_id: login_id })
                    let company_id = claims.ctry === 'US' ? 'TMS2' : 'TMS';


                    if (token === 'Unauthorized') {
                        this.setState({ salesperson: null, user_loaded: true, role: 'unauthorized' });
                    } else {
                        let salesperson_id = '';
                        const salesperson_data = await salespersonFunctions.Login(company_id, login_id, token);
                        salesperson_id = salesperson_data ? salesperson_data.salesperson_id : '';


                        if (role === 'credit') {
                            await this.set_credit_requests()
                        }
                        else if (role === 'gm') {
                            await this.set_gm_requests(company_id, salesperson_id);
                        }
                        else if (role === 'lam') {
                            await this.set_lam_requests(company_id, salesperson_id)

                        }

                        const salesperson_list_data = await salespersonFunctions.Get_All();
                        this.setState({
                            salesperson: salesperson_data,
                            salesperson_list: salesperson_list_data,
                            user_loaded: true,
                            page_loaded: true
                        });
                    }

                } catch (e) {
                    console.log(e);
                    this.setState({ salesperson: null, user_loaded: true, role: 'unauthorized', page_loaded: true, error_message: e.message });
                }
            }

        } else {
            this.setState({ salesperson: null, user_loaded: true, role: 'unauthorized', page_loaded: true });
        }
    }

}
