import { getYear } from 'date-fns';

function UnauthPageFooter({ ...props }) {
    return (
        <div className="unauth-footer pb-3">
            <div
                className="footer-div d-flex flex-column justify-content-center w-100 align-self-center"
                style={{ fontSize: '90%' }}
            >
                <div className="d-flex flex-row justify-content-center">
                    <h6 className="w-100 text-center" style={{ fontSize: '90%' }}>
                        &copy; {getYear(new Date())} Scotlynn Group. All Rights Reserved
                    </h6>
                </div>
            </div>
        </div>
    );
}

export default UnauthPageFooter;
