import React, { Component } from 'react';
import { Card, Alert, Button, InputGroup, Form, ButtonGroup } from 'react-bootstrap';
import { RequestsStatusSelection } from './RequestStatusSelection';
import { PendingRequests } from './PendingRequests';
import { CompletedRequests } from './CompletedRequests';

export class RequestsTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 'P',
            request_type: 'ACCTREQ', 
            index:0
        };
    }



    update_request_type(prop) {
        this.setState({ request_type :prop})
        this.props.update_request_index(0)
    }

    update_direction(prop) {
        this.setState({ direction: prop })
        this.props.update_request_index(0)
    }


    render() {
        const { status, request_type, index  } = this.state;
        let current_requests_list = this.props.requests_list.filter(s => s.status === status);
        const filtered_requests_list = current_requests_list.filter(s => s.request_type === request_type)        

        return (
            <div className="d-flex flex-column p-2 h-100">
                {this.props.direction === 'Incoming' ? null :
                    <RequestsStatusSelection
                        status={status}
                        update_status={(prop) => this.setState({ status: prop })}
                        direction={this.props.direction}
                        request_type={request_type}
                        update_requests_type={(prop) => this.update_request_type(prop)}
                        current_requests_list={current_requests_list}

                    />
            }
                
                {status === 'P' ?
                    <PendingRequests
                        current_requests_list={filtered_requests_list}
                        current_user={this.props.current_user}
                        direction={this.props.direction}
                        request_type={request_type}
                        update_index={(prop) => this.setState({ index: prop })}
                        index={index}
                        view_account={(company_id, id) => this.props.view_account({ company_id: company_id, id:id }, filtered_requests_list)}
                        msal_account={this.props.msal_account}
                        role={this.props.role}
                        update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                        release_account={(prop) => this.props.release_account(prop)}
   

                    /> :
                    <CompletedRequests
                        current_requests_list={filtered_requests_list}
                        direction={this.props.direction}
                        view_account={(company_id, id) => this.props.view_account({ company_id: company_id, id: id }, filtered_requests_list)}
                    />}
            </div>
        );
    }
}