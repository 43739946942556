import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import LoadingIcons from 'react-loading-icons';
import * as svg_icons from '../../Media/svg_exports';
import  SortableTable  from '../TableComponents/SortableTable'
import sortByStrategy from '../../Domain/sorting-strategy';
import * as salespersonFunctions from '../Functions/SalespersonFunctions';

export class AuditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected_log: null,
            user_sort_field: 'Date Change',
            user_sort_direction: 'H',
            log_sort_field: 'Property',
            log_sort_direction: 'H',
            selected_user:null
        };
    }

    onHide() {
        this.setState({
            selected_log: null,
            user_sort_field: 'Date Change',
            user_sort_direction: 'H',
            log_sort_field: 'Property',
            log_sort_direction: 'H',
            selected_user: null
        })
        this.props.onHide()
    }

    async get_user_info(audit_log) {
        let selected_log = this.state.selected_log;
        if (selected_log) {
            if (selected_log.id === audit_log.id) {
                this.setState({ selected_log: null, selected_user: null })
            } else {
                if (audit_log.changed_by_user === 'Auto Dropped') {
                    const data = { name: 'Auto Dropped', is_active: 'Y', revenue_code: 'AUTO DROP SCRIPT', active_date_str: '02-12-2024' }
                    this.setState({ selected_log: audit_log, selected_user: data })
                } else {
                    const data = await salespersonFunctions.Get_User_By_ID(audit_log.company_id, audit_log.changed_by_user)
                    this.setState({ selected_log: audit_log, selected_user: data })
                }
                
            }
        } else {
            if (audit_log.changed_by_user === 'Auto Dropped') {
                const data = { name: 'Auto Dropped', is_active: 'Y', revenue_code: 'AUTO DROP SCRIPT', active_date_str: '02-02-2024' }
                this.setState({ selected_log: audit_log, selected_user: data })
            } else {
                const data = await salespersonFunctions.Get_User_By_ID(audit_log.company_id, audit_log.changed_by_user)
                this.setState({ selected_log: audit_log, selected_user: data })
            }
        }

    }

    get_user_headers() {
        const header_titles = [
            'Changed User',
            'Date Change'
        ]
        let headers = [];
        header_titles.map(d => headers.push({
            column_title: d,
            sort_direction: this.state.user_sort_direction,
            is_sortable: 'Y',
            header_style: 'text-xs align-self-end',
        }))
        return (headers)
    }

    get_user_data(log) {
        const selected_log = this.state.selected_log
        let audit_log = log;
        const sortingStrategies = {
            'Changed User': (a, b) => a.changed_by_user.localeCompare(b.changed_by_user),
            'Date Change': (a, b) => new Date(a.changed_date_time) - new Date(b.changed_date_time)
        };
        audit_log = audit_log.sort(sortByStrategy(sortingStrategies, this.state.user_sort_field, this.state.user_sort_direction));

        const data = [];
        let cell_style = 'text-xs text-nowrap';
        audit_log.map(d =>
            data.push({
                row_style: selected_log? selected_log.id===d.id?'bg-yellow':'':'',
                cells: [
                    { cell: d.changed_by_user, cell_style: cell_style },
                    { cell: d.changed_date_time, cell_style: cell_style }
                ],
                data: d

            })
        )

        return (<SortableTable
            onSort={(prop) =>
                this.setState({ user_sort_field: prop, user_sort_direction: this.state.user_sort_direction === 'H' ? 'L' : 'H' })
            }
            row_click={(prop) => this.get_user_info(JSON.parse(prop))}
            table_headers={this.get_user_headers()}
            table_data={data}
            table_title=""
            sort_field={this.state.user_sort_field}
        />)
    }

    get_audit_headers() {
        const header_titles = [
            'Property',
            'Prev. Value',
            'New Value'
        ]
        let headers = [];
        header_titles.map(d => headers.push({
            column_title: d,
            sort_direction: this.state.log_sort_direction,
            is_sortable: 'Y',
            header_style: 'p-1 text-xs',
        }))
        return (headers)
    }

    get_audit_data(d) {

        const data = [];
        let cell_style = 'text-xs text-nowrap';
        let initial_values = JSON.parse(d.initial_values);
        let new_values = JSON.parse(d.new_values)

        Object.keys(initial_values).map((key) =>
            data.push({
                row_style: '',
                cells: [
                    { cell: key, cell_style: cell_style },
                    { cell: initial_values[key], cell_style: cell_style },
                    { cell: new_values[key], cell_style: cell_style }
                ],
                data: d

            })
        )
        return (<SortableTable
            onSort={(prop) =>
                this.setState({ log_sort_field: prop, log_sort_direction: this.state.log_sort_direction === 'H' ? 'L' : 'H' })
            }
            row_click={(prop) => console.log(prop)}
            table_headers={this.get_audit_headers()}
            table_data={data}
            table_title=""
            sort_field={this.state.log_sort_field}
        />)
    }

    render() {
        const { selected_log, selected_user } = this.state;
        const audit_log = this.props.audit_log ? this.props.audit_log : [];
        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen="md-down"
                    className="bg-modal-focus"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="w-100 d-flex flex-row justify-content-between m-0 p-2 pb-1">
                        <h6 className="text-start p-0 m-0 text-med text-bold">
                            {this.props.title}
                        </h6>
                        <div className="d-flex flex-row justify-content-end">
                            <label
                                variant="outline-secondary"
                                onClick={() => this.onHide()}
                                className="hover-over"
                            >
                                {svg_icons.close_icon}
                            </label>
                        </div>
                  
                     
                    </Modal.Header>
                    <Modal.Body style={{minHeight:'20rem'}}>
    
                        {audit_log.length ? (
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column border rounded flex-grow-1" style={{maxWidth:'50%'}}>
                                    {this.get_user_data(audit_log)}

                                </div>
                                {selected_user ?
                                    <div className="d-flex flex-column border rounded text-xs ms-3 p-2 flex-grow-1">
                                        <div className="d-flex flex-row mb-2">
                                            <label className="pe-1">Name:</label>
                                            <label className="pe-1 opacity-75">{`${selected_user.name} (${selected_user.is_active==='Y'?'active':'inactive'})`}</label>
                                        </div>
                                        <div className="d-flex flex-row mb-2">
                                            <label className="pe-1">Active Date:</label>
                                            <label className="pe-1 opacity-75">{selected_user.active_date_str}</label>
                                        </div>
                                        <div className="d-flex flex-row mb-2">
                                            <label className="pe-1">Rev Code:</label>
                                            <label className="pe-1 opacity-75">{selected_user.revenue_code}</label>
                                        </div>

                                    </div> :
                                    null
                                }
                               
                            </div>
                            
                        ) : null}
                        <br />
                        {selected_log ?
                            <div className="opacity-75 p-2 bg-secondary rounded">
                                {this.get_audit_data(selected_log)}
                            </div> :
                            null
                        }
                     
                        
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
