import React, { Component } from 'react';
import { Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import * as prospectActionFunctions from '../Functions/ProspectActionFunctions';
export class NewPendingFollowupModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clicked: false,
            selected_account_id:'',
            new_action:
            {
                company_id: this.props.selected_account ? this.props.selected_account.company_id : '',
                activity_id:'FOLLOWUP',
                customer_id:'',
                salesperson_id: this.props.current_user ? this.props.current_user.salesperson_id : '',
                remarks: ``,
                status:'P',
                date_due: this.props.clicked_date
            }
        };
    }


    handleInputChange = (e) => {
        let current_action = this.state.new_action;
        current_action[e.target.name] = e.target.value;
        this.setState({ new_action: current_action })
    };

    reset_new_action() {
        this.setState({
            new_action:
            {
                company_id: this.props.selected_account ? this.props.selected_account.company_id : '',
                activity_id: 'FOLLOWUP',
                customer_id: '',
                salesperson_id: this.props.current_user ? this.props.current_user.salesperson_id : '',
                remarks: ``,
                status: 'P',
                date_due: this.props.clicked_date
            },
            clicked: false,
            selected_account_id:''
        })
    }

    async post() {
        this.setState({ clicked: true })
        let new_action = this.state.new_action;
        new_action.customer_id = this.state.selected_account_id;
        await prospectActionFunctions.Post(new_action).
            then((res) => {
                this.props.add_users_action(res)
                this.reset_new_action()
                this.props.onHide();
            })

    }


    render() {
        const { new_action, clicked, selected_account_id } = this.state;
        const current_user = this.props.current_user;
        const accounts = this.props.accounts ? this.props.accounts:[];

        return (
            current_user?
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen="md-down"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName="light-modal"
                >
                    <Modal.Header className="w-100">
                        <Container fluid="xs" className="w-100">
                            <Row>
                                <Col xs={2}>
                                    <CloseButton title="Close" onClick={() => this.props.onHide()} />
                                </Col>
                                <Col xs={8}>

                                    <h4 className="w-100 text-center text-muted">
                                        {clicked ? `Saving FOLLOWUP Reminder...` : `Create FOLLOWUP Reminder`}
                                    </h4>

                                </Col>
                                <Col xs={2}></Col>
                            </Row>
                        </Container>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Row>
                            <Col xl={12}>
                                {current_user.impersonate === 'Y' ? (
                                    <div className="d-flex flex-row justify-content-center w-100">
                                        <h5 className="w-100 text-center py-5">
                                            This feature is not available in impersonation mode.
                                        </h5>
                                    </div>
                                ) : (
                                        <div className="mb-3 p-3 d-flex flex-column">
                                            <InputGroup className="mb-3" controlId="date_due">
                                                <InputGroup.Text id="date-due" className="text-sm" style={{ width: '6rem' }}>
                                                    Account
                                                </InputGroup.Text>
                                                <Form.Select
                                                    className="text-sm"
                                                    onChange={(e) => this.setState({ selected_account_id: e.target.value })}>
                                                    <option value=''>Select</option>
                                                    {accounts.map(d =>
                                                        <option key={ d.id} value={ d.id}>{d.name }</option>
                                                    )}
                                                </Form.Select>
                                            </InputGroup>
                                        <InputGroup className="mb-3" controlId="date_due">
                                            <InputGroup.Text id="date-due" className="text-sm" style={{ width: '6rem' }}>
                                                Date Due
                                            </InputGroup.Text>
                                            <Form.Control
                                                    className="text-sm bg-light"
                                                    name="date_due"
                                                    onChange={(e) => this.handleInputChange(e)}
                                                    value={this.props.clicked_date}
                                                    id="date-due"
                                            />
                                        </InputGroup>
                                      
                                        <InputGroup className="mb-3" controlId="followup_remark">
                                            <InputGroup.Text
                                                id="followup-remark"
                                                style={{ width: '6rem' }}
                                                className=" d-flex flex-column justify-content-start"
                                            >
                                                <bdi className="align-self-start text-sm">Remark</bdi>

                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder="Required"
                                                name="remarks"
                                                    aria-label="Followup Remark"
                                                    value={new_action.remarks}
                                                aria-describedby="followup-remark"
                                                className="text-sm"
                                                as="textarea"
                                                rows={3}
                                                onChange={(e) => this.handleInputChange(e)}
                                            />
                                        </InputGroup>
                                        <Button
                                                disabled={!new_action.remarks || clicked}
                                            size="sm"
                                            className="w-50 btn-op70 align-self-center"
                                                variant={!new_action.remarks|| clicked ? 'outline-success' : 'success'}
                                            onClick={() => this.post()}
                                        >
                                            {clicked ? 'Saving...' : 'Save'}
                                        </Button>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal> : null
        );
    }
}
