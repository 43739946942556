import { MsalContext } from '@azure/msal-react';
import React, { Component } from 'react';
import { Button, Col, Container, Row, ButtonGroup} from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';
import { differenceInDays } from 'date-fns';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import { numericFormatter } from 'react-number-format';

export class OrdersTab extends Component {
    static displayName = OrdersTab.name;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: '',
            sort_field: 'Ship Date',
            type_of: 'Delivered',
            offset: 50,
        };
    }
    static contextType = MsalContext;

    renderSelectType(type, title) {

        return (

                <Button
                className={`text-sm flex-grow-1`}
                variant={this.state.type_of===type?'secondary':'outline-secondary' }
                    size="sm"
                    onClick={() => this.setState({ type_of: type })}
                >
                    {title}
                </Button>
 
        );
    }

    render() {
        const { sort_direction, sort_field, type_of, offset } = this.state;

        //#region Sort table
        const all_orders = this.props.orders ? this.props.orders : [];
        let current_orders = this.props.orders ? this.props.orders : [];

        const sortingStrategies = {
            'Actual PU Date': (a, b) => differenceInDays(new Date(a.last_ship_date), new Date(b.last_ship_date)),
            'Carrier Total Pay': (a, b) => a.carrier_total_pay - b.carrier_total_pay,
            Commodity: (a, b) => a.commodity.localeCompare(b.commodity),
            'DEL Location': (a, b) => a.del_location.localeCompare(b.del_location),
            'Margin %': (a, b) => a.margin_percent - b.margin_percent,
            Margin: (a, b) => a.margin - b.margin,
            'Order #': (a, b) => a.order_id - b.order_id,
            'Order Total Cost': (a, b) => a.total_charge_n - b.total_charge_n,
            'PU Location': (a, b) => a.pu_location.localeCompare(b.pu_location),
            Rate: (a, b) => a.rate - b.rate,
            'Salesperson 2': (a, b) => a.salesperson_id2.localeCompare(b.salesperson_id2),
            Salesperson: (a, b) => a.salesperson_id.localeCompare(b.salesperson_id),
            Status: (a, b) => a.__statusDescr.localeCompare(b.__statusDescr),
        };

        current_orders = current_orders.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));

        //#region Filter table
        if (type_of !== '') {
            current_orders = current_orders.filter((s) => s.status === type_of);
        }
        current_orders = current_orders.slice(0, offset);

        //#endregion Filter table

        //#region Build Table
        const header_titles = [
            'Order #',
            'Commodity',
            'Actual PU Date',
            'Salesperson',
            'Salesperson 2',
            'Status',
            'PU Location',
            'DEL Location',
            'Rate',
            'Order Total Cost',
            'Carrier Total Pay',
            'Margin',
            'Margin %'
        ]
        let headers = [];
        header_titles.map(d => headers.push({
            column_title:d,
            sort_direction: sort_direction,
            is_sortable: 'Y',
            header_style: 'p-1 text-sm',
        }))

        const data = [];
        let cell_style = 'py-0 text-sm text-nowrap';
        current_orders.map(d =>
            data.push({
                row_style: '',
                cells: [
                    { cell: d.order_id, cell_style: cell_style },
                    { cell: d.commodity ? d.commodity : '-', cell_style: cell_style },
                    {cell: d.last_ship_date ? d.last_ship_date : '-', cell_style: cell_style,},

                    { cell: d.salesperson_id ? d.salesperson_id : '-', cell_style: cell_style },
                    { cell: d.salesperson_id2 ? d.salesperson_id2 : '-', cell_style: cell_style },
                    { cell: d.status ? d.status : '-', cell_style: cell_style },
                    { cell: d.pu_location ? d.pu_location : '-', cell_style: cell_style },
                    { cell: d.del_location ? d.del_location : '-', cell_style: cell_style, },
                    { cell: d.rate ? numericFormatter(d.rate.toString(), utilityFunctions.currency_format_props) : '-', cell_style: cell_style },
                    { cell: d.order_total_cost ? numericFormatter(d.order_total_cost.toString(), utilityFunctions.currency_format_props) : '-', cell_style: cell_style },
                    { cell: d.carrier_total_pay ? numericFormatter(d.carrier_total_pay.toString(), utilityFunctions.currency_format_props) : '-', cell_style: cell_style },
                    { cell: d.margin ? numericFormatter(d.margin.toString(), utilityFunctions.currency_format_props) : '-', cell_style: cell_style },
                    { cell: d.margin_percent ? numericFormatter(d.margin_percent.toString(), utilityFunctions.percent_format_props) : '-', cell_style: cell_style }
                ],
                data: d

            })
        )


        const orders_table = (
            <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: this.state.sort_direction === 'H' ? 'L' : 'H' })
                }
                onRowClick={() => null}
                table_headers={headers}
                table_data={data}
                table_title=""
                sort_field={this.state.sort_field}
            />
        );
        //#endregion Build Table

        const total_length = (
            <NumericFormat
                className="text-lg text-bold"
                style={{ border: 'none', outline: 'none', alignSelf: 'center', backgroundColor: 'transparent' }}
                value={all_orders.length}
                decimalScale={0}
                fixedDecimalScale={true}
                thousandSeparator={true}
            />
        );

        const display_total_div = (
            <div className="d-flex flex-row">
                <label className="text-lg align-self-center text-bold">{`Displaying: ${current_orders.length} / `}</label>
                {total_length}
            </div>
        );

        return (
            <Container fluid="xs">
                {display_total_div}
                <ButtonGroup className="d-flex flex-row w-100 mb-3">
                    {this.renderSelectType('Available', 'Available')}
                    {this.renderSelectType('In-Progress', 'In-Progress')}
                    {this.renderSelectType('Delivered', 'Delivered')}
                    {this.renderSelectType('', 'All')}
                </ButtonGroup>

                <Row>
                    <Col>
                        <div style={{ overflow: 'auto' }}>{orders_table}</div>
                    </Col>
                </Row>
                {all_orders.length <= offset ? null : (
                    <Row className="d-flex w-100 justify-content-center">
                        <Col className="d-flex flex-column w-100 justify-content-center">
                            <Button
                                className="btn-op70 underlined align-self-center"
                                variant="link"
                                title="Load more orders"
                                onClick={() => this.setState({ offset: offset + 50 })}
                            >
                                Load More
                            </Button>
                        </Col>
                    </Row>
                )}
            </Container>
        );
    }
}
