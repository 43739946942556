import { useState, useEffect, useRef } from 'react'
import {Form } from 'react-bootstrap'
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';


export const GoogleAutocompleteInput = (props) => {
    const [location, setLocation] = useState(props.default_value);
    const [displayGoogle, setDisplayGoogle] = useState(false);
    const inputRef = useRef();

    const handleLocation = (place) => setLocation(place)

    function updateLocation(e) {
        handleLocation(e) 
    }

    useEffect(() => {
        setLocation(props.default_value)
        setDisplayGoogle(false)
    }, [props.default_value]);


    function setValue(e) {
        if (e) {
            geocodeByAddress(e.label)
                .then((result) => {
                    if (result.length > 0) {
                       
                        const terms = e.value.terms;     
                        const address_components = result[0].address_components;
                        if (props.current_property === 'address1' || props.current_property==='name') {
                            if (props.current_property === 'name') {
                                props.update_account('name', e.value.structured_formatting.main_text)
                            }
                            const street_number = address_components.find(s => s.types.includes('street_number'));
                            const street_name = address_components.find(s => s.types.includes('route'));
                            const address1 = `${street_number ? street_number.short_name : terms[0]} ${street_name ? street_name.short_name : (terms.length > 1 ? terms[1] : '')}`;
                            if (props.current_property === 'address1') {
                                handleLocation(address1)
                            }
                           
                            props.update_account('address1', address1);
                            const address2 = address_components.find(s => s.types.includes('subpremise'))
                            props.update_account('address2', address2 ? address2.short_name : props.address2)
                            props.update_account('google_place_id', e.value.place_id);
                        }
                       
                        const city = address_components.find(s => s.types.includes('locality'))
                        props.update_account('city', city ? city.short_name : props.city)                       

                        const state_id = address_components.find(s => s.types.includes('administrative_area_level_1'))
                        props.update_account('state_id', state_id ? state_id.short_name : props.state_id)
                        const zip_code_prefix = address_components.find(s => s.types.includes('postal_code'))
                        const zip_code_suffix = address_components.find(s => s.types.includes('postal_code_suffix'))
                        if (zip_code_prefix) {
                            const zip_code = `${zip_code_prefix ? zip_code_prefix.short_name : ''}${zip_code_suffix ? `-${zip_code_suffix.short_name}` : ''}`
                            props.update_account('zip_code', zip_code)
                        }
                        setDisplayGoogle(false)
                    }
                   
                })
            
        }     
    }

    const google_places_form =
        <div className={`${props.width}`} data-1p-ignore onClick={() => console.log(inputRef)} onControlMouseDown={ ()=>console.log('mouse down')}>
            <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_KEY}
                
                fetchDetails={true}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: ['us', 'ca', 'pr'],
                    },
                }}

                selectProps={{
                    isDisabled: props.disabled,
                    ref: inputRef,
                    onChange: setValue,
                    className: props.className,
                    onInputChange: updateLocation,
                    inputValue: location,
                    placeholder: location,
                    isClearable: true,
                    onMenuClose: () => setDisplayGoogle(false),
                    styles: {
                        control: (provided) => ({
                            ...provided,
                            borderRadius: props.current_property === 'address1' ? '0' : '0 4px 4px 0',
                            borderColor: '#dee2e6',
                            margin:0
                       
                        }),
                        indicatorsContainer: (provided) => ({
                            ...provided,
                            margin: 0,
                            padding: '0',

                      
                            alignSelf: 'center',
                            
                        }),
                    },

                }}
            />
    </div>


    const readonly_form = <div className={`${props.width}`} onMouseEnter={() => setDisplayGoogle(true)} style={{ borderRadius: props.current_property === 'address1' ? '0' : '0 4px 4px 0' }}>
        <Form.Control value={props.default_value} className={props.className} style={{ borderRadius: props.current_property === 'address1' ? '0' : '0 4px 4px 0' }} data-1p-ignore />
    </div> 


    return (displayGoogle ? google_places_form : readonly_form);
}


