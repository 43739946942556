import * as prospectActionFunctions from './ProspectActionFunctions';
import * as customerFunctions from './CustomerFunctions';
import { format } from 'date-fns';
const get_options_w_token = (token) => {
    return {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    }
};
const get_options = {
    method: 'GET',
    headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
    },
};

const put_options = (body) => {
    return {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }
};

export async function Get_All_General_Managers() {
    try {
        const response = await fetch(
            `GeneralManager/GetAllGeneralManagers`,
            get_options_w_token(sessionStorage.getItem('token')),
        );
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);

    }
}

export async function Get_GM_Dashboard(company_id, sales_manager_id) {
    try {
        const response = await fetch(
            `GeneralManager/GetGMDashboard/${company_id}/${sales_manager_id}`,
            get_options_w_token(sessionStorage.getItem('token')),
        );
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
 
    }
}

export async function Get_PA_Data(company_id, sales_manager_id, start_date, end_date) {
    try {
        const body = {
            company_id: company_id,
            sales_manager_id: sales_manager_id,
            start_date: start_date,
            end_date: end_date
        }
        const response = await fetch(
            `GeneralManager/GetPAData`,
            put_options(body),
        );
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
    }
}

export async function Update_Manager_Assignment(body) {
    try {
        const response = await fetch('GeneralManager/UpdateGeneralManagerAssignment', put_options(body));
        const data = await response.json();
        if (data.length > 0) {
            return data[0]
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
    }
}

export async function Cancel_Manager_Assignment(body) {
    try {
        const response = await fetch(
            `GeneralManager/CancelGeneralManagerAssignment`,put_options(body),
        );
        const data = await response.json();
        if (data.length > 0) {
            return data[0]
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);

    }
}

export async function Get_Expiring_By_General_Manager(company_id, sales_manager_id) {
    try {
        const response = await fetch(
            `GeneralManager/GetExpiringByGeneralManager/${company_id}/${sales_manager_id}`,
            get_options_w_token(sessionStorage.getItem('token')),
        );
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
    
    }
}

export async function Get_General_Manager_Assignment_By_GM(company_id, sales_manager_id) {
    try {
        const response = await fetch(
            `GeneralManager/GetGeneralManagerAssignmentByGM/${company_id}/${sales_manager_id}`,
            get_options_w_token(sessionStorage.getItem('token')),
        );
        const data = await response.json();
        if (data.length > 0) {
            return data[0]
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);

    }
}

export async function Get_Inactive_LAM_Accounts(company_id, sales_manager_id) {
    try {
        const response = await fetch(
            `GeneralManager/GetInactiveLAMAccounts/${company_id}/${sales_manager_id}`,
            get_options_w_token(sessionStorage.getItem('token')),
        );
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);

    }
}




