import { useState,  useRef } from 'react'
import { Form, InputGroup, Button } from 'react-bootstrap'
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as commentFunctions from '../Functions/CommentFunctions';
import * as svg_icons from '../../Media/svg_exports';


export const AttachmentInput = (prop) => {
    const inputRef = useRef();
    const[modify, setModify]=useState(false)




    async function handle_file_change(e) {
        if (e.target.files) {
            prop.update_clicked(true)
            const file = e.target.files[0];           
            const current_comment = prop.current_comment;
            const converted_pdf = await utilityFunctions.Convert_To_PDF(file, current_comment.company_id, file.name, current_comment.entered_user_id);
            const checked_pdf = utilityFunctions.Check_File_Sizes(file);
            prop.update_attachment(checked_pdf);
            prop.update_clicked(false);
        }
    }
    const edit_button = <Button className="btn-op70 text-sm" variant="warning" size="sm" style={{ width: '3rem' }} onClick={() => setModify(true)}>{svg_icons.attachment_icon}</Button>;
    const save_button = <Button className="btn-op70 text-sm" variant="warning" size="sm" style={{ width: '3rem' }} onClick={() => setModify(false)}>{svg_icons.save_icon}</Button>;

    const attach_file_form =
        <Form.Control
            type="file"
            disabled={prop.clicked}
            accept=".pdf"
            onChange={handle_file_change}
            className="text-sm bg-white text-start"
            ref={inputRef}
            style={{ visibility: 'hidden', width: '0%' }}
        />

    const attachment_icon = <label className="hover-over m-0" size="sm" onClick={() => inputRef.current.click()}>{svg_icons.attachment_icon}</label>;
  



    return prop.current_comment? (<div className="py-2">
       
        <div className="d-flex flex-row">

            {prop.current_attachment ?
                <div className="d-flex flex-row bg-light-gray p-1 rounded">
                    <small className="opacity-75 align-self-center hover-over p-0" onClick={() => prop.update_attachment(null)}>{svg_icons.cancel_icon}</small>
                    <small className="text-sm align-self-center">{prop.current_attachment ? `Attached: ${prop.current_attachment.name}` : 'No file attached'}</small>
                </div> :
                <div className="d-flex flex-row p-1">
                    {attachment_icon}
                    <small className="text-sm align-self-center">No file attached</small>
                </div>
                }
     
            
          
           
        </div>
        {attach_file_form}
       
    </div>):null;
}


