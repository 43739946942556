import { format } from 'date-fns';
import React, { Component } from 'react';
import { MsalContext } from '@azure/msal-react';
import {
    Accordion,
    Alert,
    Button,
    Card,
    CloseButton,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
} from 'react-bootstrap';
import * as utilityFunctions from '../Functions/UtilityFunctions'

export class ReportIssueModal extends Component {
    static displayName = ReportIssueModal.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);

        this.state = {
            additional_details: '',
            current_tab: '0',
            button_clicked: false,
            attachment:null
        };
        this.input_ref = React.createRef();
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    async get_byte_array_from_file(e) {
        if (e.target.files) {
           
            this.setState({ attachment: e.target.files[0] })
        }
        //const get_file_array = (file) => {
        //    return new Promise((acc, err) => {
        //        const reader = new FileReader();
        //        reader.onload = (event) => { acc(event.target.result) };
        //        reader.onerror = (err) => { err(err) };
        //        reader.readAsArrayBuffer(file);
        //    });
        //}
        //const temp = await get_file_array(files[0])
        //const fileb = new Uint8Array(temp)
        //this.setState({ attachment: fileb })

    }

    async send_error() {
 
        try {
            this.setState({ button_clicked :true})
            //const file = await utilityFunctions.convertBase64(this.state.attachment)  
            const subject = process.env.REACT_APP_EMAIL_TITLE + ' Error Reported for ' + this.props.salesperson.company_id;
            var messageText = 'Salesperson: ' + this.props.salesperson.salesperson_id + '\n';
            messageText = messageText + 'User: ' + this.props.msal_account.name + '\n';
            messageText = messageText + 'Login: ' + this.props.msal_account.username.replaceAll('@scotlynn.com', '') + '\n';
            messageText = messageText + 'Issue: \n\n' + this.state.additional_details;
            const recipients = [
                {
                    emailAddress: {
                        address: process.env.REACT_APP_DEV_EMAIL
                    },
                },
            ];
            let attachments = []
            //if (this.state.attachment) {
            //    attachments.push({
            //        "@odata.type": "#microsoft.graph.fileAttachment",
            //        "name": this.state.attachment.name,
            //        "contentType": this.state.attachment.type,
            //        "contentBytes": file
            //    })
            //}

            const body = {
                "message": {
                    "subject": subject,
                    "body": {
                        "contentType": "Text",
                        "content": messageText
                    },
                    "toRecipients": recipients,
                    "attachments": attachments
                }
            }
            const inst = this.context.instance;
            const acct = this.context.accounts;
            await utilityFunctions.Send_Email(inst, acct, body)

            this.setState({ attachment: null, button_clicked: false, additional_details:'' })
            this.props.onHide()
        } catch (e) {
            this.setState({ attachment: null })
            console.log(e)
        }
    }




    render() {
        const { additional_details, current_tab, button_clicked } = this.state;

        //#region User Info
        const company_id = this.props.currentUser ? this.props.currentUser.company_id : '';
        const salesperson_id = this.props.currentUser ? this.props.currentUser.salesperson_id : '';
        const full_name = this.props.accounts ? this.props.accounts.salesperson_name : '';
        const email_address = this.props.accounts ? this.props.accounts.username : '';

        const user_info = this.props.currentUser ? (
            <Card bg="light" className="mb-3 p-3">
                <Card.Header
                    onClick={() => this.setState({ current_tab: current_tab === '1' ? '' : '1' })}
                    className="pb-1 bg-light hover-over"
                    style={{ border: 'none', outline: 'none' }}
                >
                    User Information
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Form>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="user-company-id" className="w-30 text-sm">
                                Company ID
                            </InputGroup.Text>
                            <Form.Control
                                className="text-sm"
                                value={company_id === 'TMS' ? 'SLC' : 'SLUSA'}
                                aria-label="Company ID"
                                aria-describedby="user-company-id"
                                disabled
                            />
                        </InputGroup>

                        <InputGroup className="mb-3" controlId="salesperson_id">
                            <InputGroup.Text id="user-salesperson-id" className="w-30 text-sm">
                                Salesperson ID
                            </InputGroup.Text>
                            <Form.Control value={salesperson_id} disabled className="text-sm" />
                        </InputGroup>
                        <InputGroup className="mb-3" controlId="display_name">
                            <InputGroup.Text id="user-full-name" className="w-30 text-sm">
                                Full Name
                            </InputGroup.Text>
                            <Form.Control value={full_name} disabled className="text-sm" />
                        </InputGroup>
                        <InputGroup className="mb-3" controlId="user_email">
                            <InputGroup.Text id="user-email" className="w-30 text-sm">
                                Email
                            </InputGroup.Text>
                            <Form.Control value={email_address} disabled className="text-sm" />
                        </InputGroup>
                    </Form>
                </Accordion.Collapse>
            </Card>
        ) : null;
        //#endregion User Info

        //#region Issue Info
        const issue_found = (
            <Card className="mb-3" bg="light mb-3 p-3">
                <Card.Header
                    onClick={() => this.setState({ current_tab: current_tab === '0' ? '' : '0' })}
                    className="bg-light mb-1 hover-over"
                    style={{ border: 'none', outline: 'none' }}
                >
                    <label>Issue Information</label>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Form>
                        <InputGroup className="mb-3" controlId="occurred_at">
                            <InputGroup.Text id="user-email" className="w-30 text-sm">
                                Occurred At
                            </InputGroup.Text>
                            <Form.Control
                                placeholder={format(new Date(), 'MM-dd-yyyy HH:mm:ss')}
                                aria-label="Occurred At"
                                aria-describedby="occurred_at"
                                disabled
                                className="text-sm"
                            />
                        </InputGroup>
                        <InputGroup className="mb-3" controlId="additional_details">
                            <InputGroup.Text id="user-email" style={{ height: '5rem' }} className="w-30 text-sm">
                                Issue
                            </InputGroup.Text>
                            <Form.Control
                                placeholder={button_clicked ? '' : 'Optional'}
                                name="additional_details"
                                aria-label="Additional Details"
                                aria-describedby="additional_details"
                                style={{ height: '5rem', resize: 'none' }}
                                className="text-sm"
                                disabled={button_clicked}
                                as="textarea"
                                onChange={this.handleInputChange}
                            />
                        </InputGroup>

                        {/*<InputGroup className="mb-3" controlId="additional_details">*/}
                        {/*    <InputGroup.Text id="screen-shot-label"  className="w-30 text-sm">*/}
                        {/*        Attach Screenshot*/}
                        {/*    </InputGroup.Text>*/}
                        {/*    <Form.Control*/}
                        {/*        name="attachment"*/}
                        {/*        id="screen-shot-input"*/}
                        {/*        className="text-sm"*/}
                        {/*        disabled={button_clicked}*/}
                        {/*        ref={this.input_ref}*/}
                        {/*        type="file"*/}
                        {/*        inputProps={{ accept: 'image/*' }}*/}
                        {/*        onChange={(e)=>this.get_byte_array_from_file(e)}*/}
                        {/*    />*/}
                        {/*</InputGroup>*/}
                    </Form>
                </Accordion.Collapse>
            </Card>
        );
        //#endregion Issue Info

        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen="md-down"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName="light-modal"
                >
                    <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <CloseButton style={{ width: '1.25rem' }} onClick={() => this.props.onHide()} />

                        <Modal.Title
                            id="contained-modal-title-vcenter"
                            className="align-items-center"
                            style={{ fontWeight: '600', textAlign: 'center', width: '100%', filter: 'opacity(0.7)' }}
                        >
                            <h4 style={{ alignSelf: 'end' }}>Report Issue</h4>
                        </Modal.Title>
                        <div style={{ width: '1.25rem' }}></div>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container fluid="xs">
                            <Row className="mb-3">
                                <Col xs={12}>
                                    <Accordion activeKey={current_tab}>
                                        {user_info}
                                        {issue_found}
                                    </Accordion>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={6}>
                                    <Button
                                        type="button"
                                        className="text-sm w-100"
                                        variant="outline-secondary"
                                        size="sm"
                                        disabled={button_clicked}
                                        onClick={() => this.props.onHide()}
                                    >
                                        Ignore
                                    </Button>
                                </Col>
                                <Col xs={6}>
                                    <Button
                                        variant={button_clicked ? 'outline-success' : 'success'}
                                        className="text-sm w-100"
                                        size="sm"
                                        onClick={async () => await this.send_error()}
                                        disabled={button_clicked}
                                    >
                                        {button_clicked ? 'Sent.' : 'Send'}
                                    </Button>
                                </Col>
                            </Row>
                            {button_clicked ? (
                                <Alert variant="success" className="d-flex flex-row justify-content-center p-1">
                                    <h6 className="align-self-center text-muted pt-1">
                                        This issue has been reported. You can now close this window.
                                    </h6>
                                </Alert>
                            ) : null}
                        </Container>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
