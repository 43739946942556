import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import * as customerFunctions from '../../Functions/CustomerFunctions';
import * as requestFunctions from '../../Functions/RequestFunctions';
import * as utilityFunctions from '../../Functions/UtilityFunctions';
import { format } from 'date-fns'
import * as svg_icons from '../../../Media/svg_exports'
import { MsalContext } from '@azure/msal-react';

export class ConversionModal extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);

        this.state = {
            new_request: null,
            
            clicked: false,
            expected_loads: 0,
            average_load_value: 0,
            awarded_bid: '',

            pick_up_date: '',
            additional_remarks: ''
        };
    }
    componentDidUpdate(prevProp, nextProp) {
        
        if (prevProp) {
            if (prevProp.selected_account) {
                if (prevProp.selected_account.id !== this.props.selected_account.id) {
                    this.reset_new_request()
                }
            }
        }

    }

    disable_button() {
        if (this.state.expected_loads > 0 &&
            this.state.average_load_value > 0 &&
            this.state.awarded_bid !== '' &&
            this.state.pick_up_date !== '' &&
            !this.state.clicked
        ) {
            return false;
        } else {
            return true;
        }
    }

    onHide() {
        this.reset_new_request()
        this.props.onHide();
    }

    reset_new_request() {
        this.setState({
            new_request: {
                company_id: this.props.selected_account ? this.props.selected_account.company_id : '',
                requestor_id: this.props.selected_account ? this.props.selected_account.salesperson_id : '',
                sales_manager_id: this.props.selected_account ? this.props.selected_account.sales_manager_id : '',
                remarks: ``,
                customer_id: this.props.selected_account ? this.props.selected_account.id : ''
            },
            expected_loads: 0,
            average_load_value: 0,
            awarded_bid: '',
            pick_up_date: '',
            additional_remarks:'',
            clicked: false
        })
    }

    componentDidMount() {
         this.reset_new_request()
    }

    handle_remarks_update(e) {
        let new_request = this.state.new_request;
        new_request.remarks = e.target.value;
        this.setState({ new_request: new_request })
    }

    async post() {
        this.setState({ clicked: true })
        let request = this.state.new_request;
        request.remarks = `&#xA;Expected Loads/Week: ${this.state.expected_loads}`;
        request.remarks = `${request.remarks}&#xA;Average Freight Rate: $${this.state.average_load_value}`;
        request.remarks = `${request.remarks}&#xA;Awarded via Bid or RFP?: ${this.state.awarded_bid}`;
        request.remarks = `${request.remarks}&#xA;Pick Up Date: ${this.state.pick_up_date}`;
        if (this.state.additional_remarks) {
            request.remarks = `${request.remarks}&#xA;Additional notes from LAM: ${this.state.additional_remarks}`;
        }
       
        const request_data = await requestFunctions.Request_Conversion(request);
        await this.email(request_data);
        let list = this.props.requests;
        list.push(request_data)
        this.props.add_users_request('outgoing',request_data);
        this.props.update_selected_account_data('requests',list)
        this.onHide()
    }

    async email(request) {
        try {
            const email_company_id = this.props.current_user.company_id === 'TMS2' ? 'SLUSA' : 'SLC';
            const subject_line =
                process.env.REACT_APP_EMAIL_TITLE +
                ': ' +
                email_company_id +
                ' Conversion Request For ' +
                request.customer_id;
            let message_text = `Conversion requested for ${this.props.selected_account.name}. \n${request.remarks.replaceAll('&#xA;','\n\n')}`;



            message_text = `${message_text} \n\nView request at: ${process.env.REACT_APP_WEB_URL}/dashboard/${request.id}`
            let recipients = [];
            if (this.props.salesperson_list) {
                let requestor_salesperson = this.props.salesperson_list.find(s => s.salesperson_id === request.requestor_id);
                if (requestor_salesperson) {
                    recipients.push({
                        emailAddress: {
                            address: requestor_salesperson.conversion_manager_email
                        },
                    })
                }
            }
           
            recipients.push({
                emailAddress: {
                    address: process.env.REACT_APP_CREDIT_EMAIL
                },
            })
            const message_body = {
                message: {
                    subject: subject_line,
                    body: {
                        contentType: 'Text',
                        content: message_text,
                    },
                    toRecipients: recipients,
                },
            }
            const inst = this.context.instance;
            const acct = this.context.accounts;
            await utilityFunctions.Send_Email(inst, acct, message_body)
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { new_request, clicked, expected_loads, average_load_value, awarded_bid, pick_up_date, additional_remarks } = this.state;
        const selected_account = this.props.selected_account;
        let requests = this.props.requests ? this.props.requests.sort((b, a) => new Date(a.date_requested) - new Date(b.date_requested)) : [];
        let pending_request = requests.length > 0 ? requests.find(s => s.status === 'P' && s.request_type === 'CREQUEST') : null;
        let last_request = requests.length > 0 ? requests[0] : null;
        let recipient_email = '';
        if (this.props.salesperson_list && selected_account) {
            let requestor_salesperson = this.props.salesperson_list.find(s => s.salesperson_id === selected_account.salesperson_id);
            if (requestor_salesperson) {
                recipient_email = requestor_salesperson.conversion_manager_email
            }


        }


        const recipient = this.props.current_user ? <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold " style={{ width: '5rem' }}>
                Recipient
            </InputGroup.Text>
            <Form.Control
                disabled={true}
                className="text-sm bg-white text-start"
                value={recipient_email}
            />
        </InputGroup> : null;

        const requestor = this.props.selected_account ? <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold" style={{ width: '5rem' }}>
                Requestor
            </InputGroup.Text>
            <Form.Control
                disabled={true}
                className="text-sm bg-white text-start"
                value={this.props.selected_account.salesperson_id}
            />
        </InputGroup> : null;

        const account = this.props.selected_account ? <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold" style={{ width: '5rem' }}>
                Account
            </InputGroup.Text>
            <Form.Control
                disabled={true}
                className="text-sm bg-white text-start"
                value={this.props.selected_account.id}
            />
        </InputGroup> : null;

        const expected_loads_form = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-wrap text-sm p-1 text-bold pe-3 w-25">
                Expected Loads/Week:
            </InputGroup.Text>
            <Form.Control
                disabled={clicked}
                onChange={(e) => this.setState({ expected_loads: e.target.value })}
                isInvalid={expected_loads <= 0}
                isValid={expected_loads>0 }
                className="text-sm bg-white text-start w-75"
                type="number"
                value={expected_loads}
            />
        </InputGroup>

        const avg_freight_form = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-wrap text-sm p-1 text-bold pe-3 w-25">
                Average Freight Rate:
            </InputGroup.Text>
            <Form.Control
                disabled={clicked}
                onChange={(e) => this.setState({ average_load_value: e.target.value })}
                className="text-sm bg-white text-start w-75"
                isInvalid={average_load_value <= 0}
                isValid={average_load_value > 0}
                type="number"
                value={average_load_value}
            />
        </InputGroup>

        const awarded_bid_form = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-wrap text-sm p-1 text-bold pe-3 w-25">
                Awarded via Bid or RFP?:
            </InputGroup.Text>
            <Button className="text-sm flex-grow-1" size="sm" variant={awarded_bid === 'Yes' ? 'success' : 'outline-success'} onClick={() => this.setState({ awarded_bid :'Yes'})}>Yes</Button>
            <Button className="text-sm flex-grow-1" size="sm" variant={awarded_bid === 'No' ? 'danger' : 'outline-danger'} onClick={() => this.setState({ awarded_bid: 'No' })}>No</Button>
        </InputGroup>

        const pickup_date_form = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-wrap text-sm p-1 text-bold pe-3 w-25">
                Pick Up Date:
            </InputGroup.Text>
            <Form.Control
                disabled={clicked}
                isInvalid={!pick_up_date}
                isValid={pick_up_date}
                onChange={(e) => this.setState({ pick_up_date: e.target.value })}
                className="text-sm bg-white text-start w-75"
                type="date"
                min={format(new Date(),'yyyy-MM-dd')}
                value={pick_up_date}
            />
        </InputGroup>

        const additional_remarks_form = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-wrap text-sm p-1 text-bold pe-3 w-25">
                Additional Remarks
            </InputGroup.Text>
            <Form.Control
                disabled={clicked}
                onChange={(e) => this.setState({ additional_remarks :e.target.value})}
                as="textarea"
                className="text-sm bg-white text-start w-75"
                placeholder="Optional"
                value={additional_remarks}
            />
        </InputGroup>



        return (
            <Modal
                {...this.props}
                size="xl"
                className="bg-modal-focus"
                fullscreen="lg-down"
                contentClassName="light-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                    <label className="hover-over w-25" onClick={() => this.onHide()}>{svg_icons.cancel_icon}</label>
                    <div className="d-flex flex-column justify-content-center w-50 text-center p-0 pt-2">
                        <h5 className="">Request Conversion</h5>
                        {pending_request ?
                            <small className="text-danger align-self-center fw-bold">**This account already has a pending Conversion request**</small>
                            : null}


                    </div>

                    <div className="w-25"></div>
                </Modal.Header>
                {selected_account ?
                    <Modal.Body className="d-flex flex-column">
    
                        <h6 className='align-self-center tile-title'>Submit Conversion Request for {selected_account.name}</h6>
                        {last_request ?
                            <small className="align-self-center mb-2">{`[A Conversion request was previously submitted on ${last_request.date_requested_str} by ${last_request.requestor_name}]`}</small>
                            : null}
                        <div className="d-flex flex-column w-75 align-self-center mt-2">
                            {recipient}
                            {requestor}
                            {account}
                            {expected_loads_form}
                            {avg_freight_form}
                            {awarded_bid_form}
                            {pickup_date_form}
                            {additional_remarks_form}
                            <Button
                                className="text-sm w-25 align-self-center mt-3 mb-3"
                                onClick={() => this.post()}
                                disabled={this.disable_button()}
                                variant={clicked ? 'outline-success' : 'success'} >
                                {clicked ? 'Sending...' : 'Send Request'}

                            </Button>
                        </div>
                    </Modal.Body> :
                    null
                }




            </Modal>

        )
    }



}

