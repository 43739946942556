import { MsalContext } from '@azure/msal-react';
import { addDays, format, isAfter, isBefore } from 'date-fns';
import React, { Component } from 'react';
import {
    Alert,
    Form,
    InputGroup,
    Button
} from 'react-bootstrap';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as managerFunctions from '../Functions/ManagerFunctions';
import { SearchProspects } from '../SearchPage/SearchProspects';
import { MyCustomers } from '../MyCustomers';
import { MyProspects } from '../MyProspects';
import { CreditPortal } from './CreditPortal';
import { GMPortal } from './GMPortal';
import { LAMPortal } from './LAMPortal';
import { PhonePage } from '../PhonePage';
import { Clock } from '../PageComponents/Clock';
import { AdminEdit } from '../TableComponents/Beta/AdminEdit';
import { CustomerAuditViewer } from '../TableComponents/Beta/CustomerAuditViewer';
import { ManagerAssignmentTable } from '../TableComponents/ManagerAssignmentTable';
import { ManagerAssignmentModal } from '../Modals/ManagerAssignmentModal';



export class AdminPortal extends Component {
    static displayName = AdminPortal.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {

            company_id: '',
            selected_role: 'credit',

            page:'dashboard',
            salesperson_clicked:false,
            selected_salesperson:null,   
            company_id: '',

            show_apps_menu: false,
            sp_audit: null,
            sp_audit_clicked: false,
            sp_aduit_loaded: false,
            master_audit: [],
            idx: 0,
            general_manager_assignments: [],
            general_manager_assignments_loaded: false,
            selected_manager_assignment: null,
            show_update_manager_assignemnt: false,
            general_manager_sort: 'General Manager',
            general_manager_asc: true,

            requests_loaded: false,
  
        };

    }


    async test_email() {
        try {


            const subject_line = process.env.REACT_APP_EMAIL_TITLE + `: Test Email`;

            let message_text ='This email is a test'

            let recipients = [
                {
                    emailAddress: {
                        address: 'brhodes@scotlynn.com',
                    }
  
                },
            ];


            const message_body = {
                message: {
                    subject: subject_line,
                    body: {
                        contentType: 'Text',
                        content: message_text,
                    },
                    toRecipients: recipients,
                },
            }
            const inst = this.context.instance;
            const acct = this.context.accounts;
            await utilityFunctions.Send_Notification(inst, acct);
        } catch (e) {
            console.log(e);
        }
    }

   async swap_role(role) {
       
        this.setState({
            page: 'dashboard',
            salesperson_clicked: false,
            selected_salesperson: null,
            selected_salesperson_loaded: false,
            selected_salesperson: null,
            company_id: '',
            selected_role:role
        })
       if (role === 'credit') {
           await this.props.set_credit_requests()
       }
    }

    swap_company_id(company_id) {
        this.setState({
            page: 'dashboard',
            salesperson_clicked: false,
            selected_salesperson_loaded: false,
            selected_salesperson: null,
            company_id: company_id
        })
    }

    async componentDidMount() {

       // this.props.set_credit_requests()
    }

    componentDidUpdate() {

        if (!this.state.requests_loaded) {
            if (this.props.requests) {
                if (this.props.requests.loaded === 'Y') {

                    const pending_requests = this.props.requests.list.filter((s) => s.status === 'P');
                    if (!window.location.pathname.includes('incomingPhone')) {
                        this.set_param_index(pending_requests);
                    }
                  
                    this.setState({ requests_loaded: true, selected_role:'credit' });
                }
            }
        }
    }

    set_param_index(pending_requests) {
        let current_activity_id = '';
            if (window.location.pathname.split('/').length === 3) {
                current_activity_id = window.location.pathname.split('/')[2];
            }
            if (window.location.pathname.split('/').length === 4) {
                current_activity_id = window.location.pathname.split('/')[2];
            }
            if (current_activity_id && pending_requests) {
                const current_activity = pending_requests.find((s) => s.id === current_activity_id);
                const index = pending_requests.indexOf(current_activity);
                this.setState({ request_index: index > -1 ? index : 0, requests_loaded: true, request_id_type: current_activity.request_type });
            }
        
  
    }

    async select_salesperson(salesperson_id) {
        const company_id = this.state.company_id;
        this.setState({ salesperson_clicked: true, page: 'dashboard', selected_salesperson:null })
        const salesperson_list = this.props.salesperson_list ? this.props.salesperson_list : [];
        const find_salesperson = salesperson_list.find(s => s.company_id === company_id && s.salesperson_id === salesperson_id);
        if (find_salesperson) {
            await this.props.get_users_accounts(company_id, salesperson_id)
            if (this.state.selected_role === 'gm') {
                await this.props.set_gm_requests(company_id, salesperson_id)
            } else {
                await this.props.set_lam_requests(company_id, salesperson_id)
            }

            this.setState({ salesperson_clicked: false,  selected_salesperson: find_salesperson })
        } else {
            this.setState({ salesperson_clicked: false, selected_salesperson: null })
        }
        
     
     }
      


    render() {
        const {
            company_id, 
            general_manager_assignments,
            show_update_manager_assignemnt,
            selected_manager_assignment,
            selected_role,
            selected_salesperson,
            salesperson_clicked,
            page
        } = this.state;
        let prospects_list = this.props.prospects ? this.props.prospects.loaded === 'Y' ? this.props.prospects.list : [] : [];
        let customers_list = this.props.customers ? this.props.customers.loaded === 'Y' ? this.props.customers.list : [] : [];
        let current_salesperson_list = this.props.salesperson_list ? this.props.salesperson_list.filter(s => s.company_id === company_id && s.salesperson_is_active==='Y') : [];
        if (selected_role === 'gm') {
            current_salesperson_list = current_salesperson_list.filter(s => s.role === 'GM' || s.role==='DOO')
        } else {
            current_salesperson_list = current_salesperson_list.filter(s => s.role !== 'GM')
        }

        const credit_portal = <CreditPortal
            msal_account={this.props.msal_account}
            salesperson={this.props.salesperson}
            role={this.props.role}
            width={ this.props.width}
            salesperson_list={this.props.salesperson_list}
            commodities={this.props.commodities}
            categories={this.props.categories}
            prospects={this.props.prospects}
            customers={this.props.customers}
            add_account={(prop) => this.props.add_account(prop)}
            release_account={(prop) => this.props.release_account(prop)}
            prospect_actions={this.props.prospect_actions}
            add_users_action={(prop) => this.props.add_users_action(prop)}
            update_users_action={(prop) => this.props.update_users_action(prop)}
            incoming_requests={this.props.incoming_requests}
            outgoing_requests={this.props.outgoing_requests}
            no_action_requests={this.props.no_action_requests}
            action_requests={this.props.action_requests}
            update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
            add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
            open_report_issue={() => this.props.open_report_issue()}
        />

        const lam_portal = selected_salesperson ?
            <LAMPortal
                msal_account={this.props.msal_account}
                salesperson={selected_salesperson}
                role={this.props.role}
                width={this.props.width}
                salesperson_list={this.props.salesperson_list}
                commodities={this.props.commodities}
                categories={this.props.categories}
                prospects={this.props.prospects}
                customers={this.props.customers}
                add_account={(prop) => this.props.add_account(prop)}
                release_account={(prop) => this.props.release_account(prop)}
                prospect_actions={this.props.prospect_actions}
                add_users_action={(prop) => this.props.add_users_action(prop)}
                update_users_action={(prop) => this.props.update_users_action(prop)}
                incoming_requests={this.props.incoming_requests}
                outgoing_requests={this.props.outgoing_requests}
                no_action_requests={this.props.no_action_requests}
                action_requests={this.props.action_requests}
                update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                open_report_issue={() => this.props.open_report_issue()}
            /> : null;

        const gm_portal = selected_salesperson ?
            <GMPortal
                msal_account={this.props.msal_account}
                salesperson={selected_salesperson}
                role={this.props.role}
                width={this.props.width}
                salesperson_list={this.props.salesperson_list}
                commodities={this.props.commodities}
                categories={this.props.categories}
                prospects={this.props.prospects}
                customers={this.props.customers}
                add_account={(prop) => this.props.add_account(prop)}
                release_account={(prop) => this.props.release_account(prop)}
                prospect_actions={this.props.prospect_actions}
                add_users_action={(prop) => this.props.add_users_action(prop)}
                update_users_action={(prop) => this.props.update_users_action(prop)}
                incoming_requests={this.props.incoming_requests}
                outgoing_requests={this.props.outgoing_requests}
                no_action_requests={this.props.no_action_requests}
                action_requests={this.props.action_requests}
                update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                open_report_issue={() => this.props.open_report_issue()}
            /> : null;


        const search_page = selected_salesperson ? <SearchProspects
            msal_account={this.props.msal_account}
            salesperson={selected_salesperson}
            role={this.props.role}
            width={this.props.width}
            salesperson_list={this.props.salesperson_list}
            commodities={this.props.commodities}
            categories={this.props.categories}
            prospects={this.props.prospects}
            customers={this.props.customers}
            add_account={(prop) => this.props.add_account(prop)}
            release_account={(prop) => this.props.release_account(prop)}
            prospect_actions={this.props.prospect_actions}
            add_users_action={(prop) => this.props.add_users_action(prop)}
            update_users_action={(prop) => this.props.update_users_action(prop)}
            incoming_requests={this.props.incoming_requests}
            outgoing_requests={this.props.outgoing_requests}
            no_action_requests={this.props.no_action_requests}
            action_requests={this.props.action_requests}
            update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
            add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
            open_report_issue={() => this.props.open_report_issue()}
        /> : null;


        const my_prospects_page = selected_salesperson ?
            <MyProspects
                msal_account={this.props.msal_account}
                salesperson={selected_salesperson}
                role={this.props.role}
                width={this.props.width}
                salesperson_list={this.props.salesperson_list}
                commodities={this.props.commodities}
                categories={this.props.categories}
                prospects={this.props.prospects}
                customers={this.props.customers}
                add_account={(prop) => this.props.add_account(prop)}
                release_account={(prop) => this.props.release_account(prop)}
                prospect_actions={this.props.prospect_actions}
                add_users_action={(prop) => this.props.add_users_action(prop)}
                update_users_action={(prop) => this.props.update_users_action(prop)}
                incoming_requests={this.props.incoming_requests}
                outgoing_requests={this.props.outgoing_requests}
                no_action_requests={this.props.no_action_requests}
                action_requests={this.props.action_requests}
                update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                open_report_issue={() => this.props.open_report_issue()}
            /> : null;


        const my_customers_page = selected_salesperson ?
            <MyCustomers
                msal_account={this.props.msal_account}
                salesperson={selected_salesperson}
                role={this.props.role}
                width={this.props.width}
                salesperson_list={this.props.salesperson_list}
                commodities={this.props.commodities}
                categories={this.props.categories}
                prospects={this.props.prospects}
                customers={this.props.customers}
                add_account={(prop) => this.props.add_account(prop)}
                release_account={(prop) => this.props.release_account(prop)}
                prospect_actions={this.props.prospect_actions}
                add_users_action={(prop) => this.props.add_users_action(prop)}
                update_users_action={(prop) => this.props.update_users_action(prop)}
                incoming_requests={this.props.incoming_requests}
                outgoing_requests={this.props.outgoing_requests}
                no_action_requests={this.props.no_action_requests}
                action_requests={this.props.action_requests}
                update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                open_report_issue={() => this.props.open_report_issue()}
        /> : null;

        let lam_page = lam_portal;
        let gm_page = gm_portal;
        if (page === 'search') {
            lam_page = search_page
            gm_page = search_page
        } else if (page === 'myprospects') {
            lam_page = my_prospects_page
            gm_page = my_prospects_page
        } else if (page === 'mycustomers') {
            lam_page = my_customers_page
            gm_page = my_customers_page
        }


        return (
            <div className="d-flex w-100 justify-content-center flex-column h-100 p-2">
                <div className="p-0 ps-2 pe-1">
                    <Clock />
                </div>
     
                {this.props.incoming_requests ? this.props.incoming_requests.loaded === 'Y' ?
                    <div className="px-3">
                        <InputGroup>
                            <Form.Select value={selected_role} onChange={(e) => this.swap_role(e.target.value)} className={`text-sm`}>
                                <option value=''>Select View</option>
                                <option value='credit'>Credit View</option>
                                <option value='gm'>GM View</option>
                                <option value='lam'>LAM View</option>
                            </Form.Select>
                            {selected_role === 'credit' ? null :
                                <Form.Select value={company_id} onChange={(e) => this.swap_company_id(e.target.value)} disabled={!selected_role || salesperson_clicked} className={`text-sm ${!selected_role ? 'opacity-75' : ''}`}>
                                    <option value=''>Select Company</option>
                                    <option value='TMS'>SLC</option>
                                    <option VALUE='TMS2'>SLUSA</option>
                                </Form.Select>
                            }
                            {selected_role === 'credit' ? null :
                                <Form.Select value={selected_salesperson ? selected_salesperson.salesperson_id : ''} onChange={(e) => this.select_salesperson(e.target.value)} disabled={!company_id || salesperson_clicked} className={`text-sm ${!selected_role ? 'opacity-75' : ''}`}>
                                    <option value=''>{salesperson_clicked ? 'Loading User...' : 'Select User'}</option>
                                    {current_salesperson_list.sort((a, b) => a.salesperson_name.localeCompare(b.salesperson_name)).map(d =>
                                        <option value={d.salesperson_id}>{d.salesperson_name}</option>
                                    )}
                                </Form.Select>
                            }

                            {selected_salesperson && selected_role !== 'credit' ?
                                <Form.Select value={page} onChange={(e) => this.setState({ page: e.target.value })} disabled={!selected_salesperson || salesperson_clicked} className={`text-sm ${!selected_salesperson ? 'opacity-75' : ''}`}>
                                    <option value=''>Select Page</option>
                                    <option value='dashboard'>Dashboard</option>
                                    <option value='search'>Search</option>
                                    <option value='myprospects'>{`My Prospects (${prospects_list ? prospects_list.length : '0'})`}</option>
                                    <option value='mycustomers'>{`My Customers (${customers_list ? customers_list.length : '0'})`}</option>
                                </Form.Select> :
                                null
                            }

                        </InputGroup>
                    </div>
                    :
                    <div className="px-3">
                        <Alert className="w-100 text-center mt-3" variant='secondary'>Loading Credit Portal...</Alert>
                    </div>
                     :
                    null
                }
                {window.location.pathname.includes('incomingPhone') ?
                    <PhonePage
                        msal_account={this.props.msal_account}
                        salesperson={this.props.salesperson}
                        role={this.props.role}
                        width={this.props.width}
                        user_routes={this.props.user_routes}
                        salesperson_list={this.props.salesperson_list}
                        commodities={this.props.commodities}
                        categories={this.props.categories}
                        prospects={this.props.prospects}
                        customers={this.props.customers}
                        add_account={(prop) => this.props.add_account(prop)}
                        release_account={(prop) => this.props.release_account(prop)}
                        prospect_actions={this.props.prospect_actions}
                        add_users_action={(prop) => this.props.add_users_action(prop)}
                        update_users_action={(prop) => this.props.update_users_action(prop)}
                        incoming_requests={this.props.incoming_requests}
                        outgoing_requests={this.props.outgoing_requests}
                        no_action_requests={this.props.no_action_requests}
                        action_requests={this.props.action_requests}
                        update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                        add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                        open_report_issue={() => this.props.open_report_issue()}
                    /> :
                    null
            }
               
                {selected_role === 'lam' ? lam_page : null}
                {selected_role === 'gm' ? gm_page : null}
                {selected_role === 'credit' ? credit_portal : null}
                {salesperson_clicked?<Alert className="w-100 text-center mt-3" variant='secondary'>Loading...</Alert>:null}
                <ManagerAssignmentModal
                    show={show_update_manager_assignemnt}
                    onClose={() => this.setState({ show_update_manager_assignemnt: false, selected_manager_assignment: null })}
                    general_manager_assignments={general_manager_assignments}
                    set_manager_assignment={(prop) => this.set_manager_assignment(prop)}
                    selected_manager_assignment={selected_manager_assignment}
                    put_assignment={() => this.update_manager_assignment()}
                    cancel_assignment={() => this.cancel_manager_assignment()}
                    update_assignment={(prop) => this.setState({ selected_manager_assignment: prop })}
                   
                />
            </div>
        );
    }

    async get_general_managers() {
        try {
            const data = await managerFunctions.Get_All_General_Managers();
            this.setState({
                general_manager_assignments: data,
                general_manager_assignments_loaded: true
            })
        } catch (e) {
            console.log(e);
            this.setState({
                general_manager_assignments: [],
                general_manager_assignments_loaded: true
            })
        }
    }

}
