import React, { Component } from 'react';
import { Button, Col, Container, Form, Modal, Row, InputGroup } from 'react-bootstrap';
import '../../Styles/Datepicker.css';

export class UpdateSalespersonModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clicked: false,
            display_secondary: false,
            cancelling: false,
        };
    }

    onClose() {
        this.clean_salesperson_template();
        this.props.onClose();
    }

    clean_salesperson_template() {
        this.props.update_salesperson({
            commission_start_date: '',
            company_id: '',
            floating_LC: '',
            hierarchy: '',
            inactive_date: '',
            inactive_relationship_date: '',
            inactive_relationship_date_2: '',
            primary_salesperson_id: '',
            primary_salesperson_id_2: '',
            primary_LC_gifted_perc: 0,
            primary_LC_gifted_perc_2: 0,
            primary_LC_gifted_perc_start_date: '',
            primary_LC_gifted_perc_start_date_2: '',
            primary_salesperson_start_date: '',
            primary_salesperson_start_date_2: '',
            revenue_code: '',
            role: '',
            sales_manager_id: '',
            sales_team_captain_id: '',
            salesperson_id: '',
            salesperson_name: '',
            salesperson_start_date: '',
            salesperson_title: '',
            training_under: '',
            user_id: '',
            change_user: '',
            multi_lc: 'N',
        });
    }

    async update_salesperson() {
        this.setState({ clicked: true });
        this.props.put_salesperson().then((res) => {
            this.setState({ clicked: false });
        });
    }

    async revert_second_primary_lam() {
        this.setState({ cancelling: true });
        try {
            const current_salesperson = this.props.selected_salesperson;
            const options = { method: 'GET', headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') } };
            const response = await fetch(
                `Commission/GetCommissionSalespersonsByID/${current_salesperson.company_id}/${current_salesperson.salesperson_id}`,
                options,
            );
            const data = await response.json();
            if (data.length > 0) {
                current_salesperson.inactive_relationship_date_2 = data[0].inactive_relationship_date_2;
                current_salesperson.primary_salesperson_id_2 = data[0].primary_salesperson_id_2;
                current_salesperson.primary_LC_gifted_perc_2 = data[0].primary_LC_gifted_perc_2;
                current_salesperson.primary_LC_gifted_perc_start_date_2 = data[0].primary_LC_gifted_perc_start_date_2;
                current_salesperson.primary_salesperson_start_date_2 = data[0].primary_salesperson_start_date_2;
                current_salesperson.multi_lc = data[0].multi_lc;
            }

            this.props.update_salesperson(current_salesperson);
            this.setState({ cancelling: false, display_secondary: false });
        } catch (e) {
            console.log(e);
            this.setState({ cancelling: false, display_secondary: false });
        }
    }

    handle_select_change(e) {
        const current_salesperson = this.props.selected_salesperson;
        if (e.target.name === 'sales_manager_id') {
            const current_gm = this.props.general_managers.find((s) => s.user_id === e.target.value);
            current_salesperson.sales_manager_id = e.target.value;
            current_salesperson.company_id = current_gm.company_id;
            current_salesperson.revenue_code = current_gm.revenue_code;
        } else if (e.target.name === 'role') {
            current_salesperson.role = e.target.value;
            current_salesperson.hierarchy = e.target.value === 'STC' ? 'LAM' : e.target.value;
        } else if (e.target.name === 'primary_salesperson_id_2') {
            if (e.target.value) {
                current_salesperson.multi_lc = 'Y';
                current_salesperson[e.target.name] = e.target.value;
            } else {
                current_salesperson.multi_lc = 'N';
                current_salesperson[e.target.name] = e.target.value;
            }
        } else {
            current_salesperson[e.target.name] = e.target.value;
        }

        this.props.update_salesperson(current_salesperson);
    }

    handle_edit_change(e) {
        const current_salesperson = this.props.selected_salesperson;
        current_salesperson[e.target.name] = e.target.value;
        this.props.update_salesperson(current_salesperson);
    }

    //#region Render Functions

    render_no_edit(title, field) {
        const current_salesperson = this.props.selected_salesperson;
        return (
            <div className={'d-flex flex-row justify-content-between px-2 py-1 border'}>
                <label className="text-lg align-self-center">{title}:</label>
                <Form.Control
                    value={current_salesperson ? (current_salesperson[field] ? current_salesperson[field] : '') : ''}
                    className="text-lg w-50 p-1"
                    disabled={true}
                />
            </div>
        );
    }

    render_edit(title, field, max) {
        const current_salesperson = this.props.selected_salesperson;
        return (
            <div
                className={'d-flex flex-row justify-content-between px-2 py-1 border'}
                title={
                    !current_salesperson.sales_manager_id
                        ? 'A Sales Manager must be selected before you can edit this field'
                        : ''
                }
            >
                <label className="text-lg align-self-center">{title}:</label>
                <Form.Control
                    onChange={(e) => this.handle_edit_change(e)}
                    maxLength={max}
                    disabled={!current_salesperson.sales_manager_id}
                    name={field}
                    value={current_salesperson[field] ? current_salesperson[field] : ''}
                    className="text-lg w-50 p-1"
                    isInvalid={current_salesperson.sales_manager_id ? !current_salesperson[field] : false}
                />
            </div>
        );
    }

    render_edit_date(title, field, required) {
        const current_salesperson = this.props.selected_salesperson;
        return (
            <div className={'d-flex flex-row justify-content-between px-2 py-1 border'}>
                <label className="text-lg align-self-center">{title}:</label>
                <Form.Control
                    onChange={(e) => this.handle_edit_change(e)}
                    type="date"
                    disabled={!current_salesperson.sales_manager_id}
                    name={field}
                    value={current_salesperson ? (current_salesperson[field] ? current_salesperson[field] : '') : ''}
                    className="text-lg w-50 p-1"
                    isInvalid={current_salesperson.sales_manager_id && required ? !current_salesperson[field] : false}
                />
            </div>
        );
    }

    render_select(title, field, values, required) {
        const current_salesperson = this.props.selected_salesperson;
        if (current_salesperson) {
            return (
                <div
                    className={'d-flex flex-row justify-content-between px-2 py-1 border'}
                    title={
                        !current_salesperson.sales_manager_id && field !== 'sales_manager_id'
                            ? 'A Sales Manager must be selected before you can edit this field'
                            : ''
                    }
                >
                    <label className="text-lg align-self-center">{title}:</label>
                    <Form.Select
                        onChange={(e) => this.handle_select_change(e)}
                        name={field}
                        disabled={!current_salesperson.sales_manager_id && field !== 'sales_manager_id'}
                        value={current_salesperson[field] ? current_salesperson[field] : ''}
                        isInvalid={
                            (field === 'sales_manager_id' || current_salesperson.sales_manager_id) && required
                                ? !current_salesperson[field]
                                : false
                        }
                        className="text-lg w-50 p-1"
                    >
                        <option value="">Select</option>
                        {values
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map((d) => (
                                <option value={d.value} key={d.value}>
                                    {d.label}
                                </option>
                            ))}
                    </Form.Select>
                </div>
            );
        } else {
            return null;
        }
       
    }

    render_rev_code_select(values, current_salesperson) {
        if (current_salesperson) {
            return (
                <div
                    className={'d-flex flex-row justify-content-between px-2 py-1 border'}
                    title={'Revenue Code is required. If it is not in this list, it may be created in the Revenue Codes modal'}
                >

                    <label className="text-lg align-self-center">Rev Code:</label>
                    <Form.Select
                        onChange={(e) => this.handle_select_change(e)}
                        disabled={this.state.clicked}
                        name='revenue_code'
                        value={current_salesperson.revenue_code}
                        isInvalid={!current_salesperson.revenue_code}
                        className="text-lg w-50 p-1"
                    >
                        <option value="">Select</option>
                        {values.filter(s => s.company_id === current_salesperson.company_id)
                            .sort((a, b) => a.id.localeCompare(b.id))
                            .map((d, index) => (
                                <option value={d.id} key={index} title={ d.descr}>
                                    {d.id}
                                </option>
                            ))}
                    </Form.Select>
                </div>
            );
        } else {
            return null;
        }
            
        

    }
    //#endregion Render Functions

    check_salesperson() {
        const current_salesperson = this.props.selected_salesperson;

        if (!current_salesperson.sales_manager_id) {
            return false;
        }
        if (!current_salesperson.company_id) {
            return false;
        }
        if (!current_salesperson.revenue_code) {
            return false;
        }
        if (!current_salesperson.salesperson_id) {
            return false;
        }
        if (!current_salesperson.salesperson_name) {
            return false;
        }
        if (!current_salesperson.role) {
            return false;
        }
        if (!current_salesperson.user_id) {
            return false;
        }
        if (!current_salesperson.salesperson_start_date) {
            return false;
        }
        if (current_salesperson.role === 'LC') {
            if (current_salesperson.floating_LC === 'Y') {
                return true;
            } else {
                if (!current_salesperson.primary_salesperson_id) {
                    return false;
                }
                if (!current_salesperson.primary_salesperson_start_date) {
                    return false;
                }
                if (
                    !current_salesperson.primary_LC_gifted_perc > 0 &&
                    !current_salesperson.primary_LC_gifted_perc_start_date
                ) {
                    return false;
                }
                if (current_salesperson.multi_lc === 'Y') {
                    if (!current_salesperson.primary_salesperson_id_2) {
                        return false;
                    }
                    if (!current_salesperson.primary_salesperson_start_date_2) {
                        return false;
                    }
                    if (
                        !current_salesperson.primary_LC_gifted_perc_2 > 0 &&
                        !current_salesperson.primary_LC_gifted_perc_start_date_2
                    ) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    render() {
        const { clicked, display_secondary, cancelling } = this.state;
        const revenue_codes = this.props.revenue_codes ? this.props.revenue_codes:[]
        const current_salesperson = this.props.selected_salesperson ? this.props.selected_salesperson : null;
        const gm_list = [];
        const titles_list = [];
        const trainers_list = [];
        const captains_list = [];
        const salesperson_list = [];
        const float_lc_options = [
            { label: 'Yes', value: 'Y' },
            { label: 'No', value: 'N' },
        ];
        const gifted_perc_options = [
            { label: 'None', value: 0 },
            { label: '0.25 %', value: 0.25 },
            { label: '0.5 %', value: 0.5 },
            { label: '1 %', value: 1 },
            { label: '1.5 %', value: 1.5 },
        ];

        if (current_salesperson) {
            if (this.props.general_managers) {
                this.props.general_managers.map((s) =>
                    gm_list.push({ label: `${s.salesperson_name}   (${s.revenue_code})`, value: s.user_id }),
                );
            }
            if (this.props.titles) {
                this.props.titles.map((s) => titles_list.push({ label: s.name, value: s.id }));
            }
            if (this.props.trainers) {
                this.props.trainers
                    .filter((d) => (d.sales_manager_id === current_salesperson.sales_manager_id))
                    .map((s) => trainers_list.push({ label: s.salesperson_name, value: s.salesperson_id }));
            }
            if (this.props.sales_team_captains) {
                this.props.sales_team_captains
                    .filter((d) => (d.sales_manager_id === current_salesperson.sales_manager_id))
                    .map((s) => captains_list.push({ label: s.salesperson_name, value: s.salesperson_id }));
            }
            if (this.props.salespersons) {
                this.props.salespersons
                    .filter((d) => (d.sales_manager_id === current_salesperson.sales_manager_id))
                    .map((s) => salesperson_list.push({ label: s.salesperson_name, value: s.salesperson_id }));
            }
        }
       

        return (
            <Modal
                {...this.props}
                size="lg"
                className="bg-transparent-gray"
                fullscreen="md-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="w-100 ">
                    <Container fluid="xs" className="w-100 p-0">
                        <Row>
                            <Col>
                                <div className="d-flex flex-row justify-content-between">
                                    <Button variant="outline secondary btn-op70 p-0" onClick={() => this.onClose()}>
                                        X
                                    </Button>
                                    <h5 className="pt-2 mb-0">
                                        {current_salesperson ? current_salesperson.salesperson_name : 'Loading...'}
                                    </h5>
                                    <div></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
   
                </Modal.Header>
                <Modal.Body className="show-grid ">
               
                    {current_salesperson ? (
                        <div className="d-flex flex-column justify-content-start">
                            {gm_list ? this.render_select('Sales Manager', 'sales_manager_id', gm_list, true) : null}
                            {this.render_no_edit('Company ID', 'company_id')}

                            {revenue_codes.length ? this.render_rev_code_select(revenue_codes, current_salesperson) : null}
                            {this.render_edit('Salesperson Name', 'salesperson_name', 100)}
                            {current_salesperson.salesperson_name
                                ? this.render_no_edit('Salesperson ID', 'salesperson_id')
                                : null}
                            {this.render_edit('User ID', 'user_id', 25)}
                            {this.render_edit_date('Salesperson Start Date', 'salesperson_start_date', true)}
                            {this.render_edit_date('Commission Start Date', 'commission_start_date', false)}
                            {this.render_edit_date('Inactive Date', 'inactive_date', false)}
                            {captains_list
                                ? this.render_select(
                                      'Sales Team Captain',
                                      'sales_team_captain_id',
                                      captains_list,
                                      false,
                                  )
                                : null}
                            {trainers_list
                                ? this.render_select('Training Under', 'training_under', trainers_list, false)
                                : null}
                            {titles_list ? this.render_select('Title', 'role', titles_list, true) : null}
                            {current_salesperson.hierarchy === 'LC'
                                ? this.render_select('Floating LC', 'floating_LC', float_lc_options, true)
                                : null}
                            {current_salesperson.hierarchy === 'LC' && current_salesperson.floating_LC !== 'Y' ? (
                                <div className="d-flex flex-column justify-content-start w-100 border p-2 mt-1">
                                    <h6 className="text-secondary">Primary Saleperson #1</h6>
                                    {this.render_select(
                                        'Primary LAM',
                                        'primary_salesperson_id',
                                        salesperson_list,
                                        true,
                                    )}
                                    {this.render_edit_date(
                                        'Start Date with Primary LAM',
                                        'primary_salesperson_start_date',
                                        true,
                                    )}
                                    {this.render_select(
                                        'Gifted Percent',
                                        'primary_LC_gifted_perc',
                                        gifted_perc_options,
                                        false,
                                    )}
                                    {current_salesperson.primary_LC_gifted_perc
                                        ? this.render_edit_date(
                                              'Gifted Percent Start Date',
                                              'primary_LC_gifted_perc_start_date',
                                              true,
                                          )
                                        : null}
                                    {this.render_edit_date(
                                        'Inactive Relationship Date',
                                        'inactive_relationship_date',
                                        false,
                                    )}
                                </div>
                            ) : null}
                            {current_salesperson.hierarchy === 'LC' &&
                            current_salesperson.floating_LC !== 'Y' &&
                            (current_salesperson.multi_lc === 'Y' || display_secondary) ? (
                                <div className="d-flex flex-column justify-content-start w-100 border p-2 mt-1">
                                    <div className="d-flex flex-row justify-content-between w-100">
                                        <h6 className="text-secondary">Primary Saleperson #2</h6>
                                        <label
                                            onClick={() => this.revert_second_primary_lam()}
                                            className="text-danger hover-over underlined text-lg"
                                            disabled={cancelling}
                                        >
                                            Reset
                                        </label>
                                    </div>

                                    {this.render_select(
                                        'Primary LAM',
                                        'primary_salesperson_id_2',
                                        salesperson_list.filter(
                                            (s) => s.value !== current_salesperson.primary_salesperson_id,
                                        ),
                                        true,
                                    )}
                                    {this.render_edit_date(
                                        'Start Date with Primary LAM',
                                        'primary_salesperson_start_date_2',
                                        true,
                                    )}
                                    {this.render_select(
                                        'Gifted Percent',
                                        'primary_LC_gifted_perc_2',
                                        gifted_perc_options,
                                        false,
                                    )}
                                    {current_salesperson.primary_LC_gifted_perc_2
                                        ? this.render_edit_date(
                                              'Gifted Percent Start Date',
                                              'primary_LC_gifted_perc_start_date_2',
                                              true,
                                          )
                                        : null}
                                    {this.render_edit_date(
                                        'Inactive Relationship Date',
                                        'inactive_relationship_date_2',
                                        false,
                                    )}
                                </div>
                            ) : (
                                <Button
                                    variant="link"
                                    className="text-lg underlined p-0 pt-1"
                                    onClick={() => this.setState({ display_secondary: true })}
                                >
                                    Add Primary Salesperson #2
                                </Button>
                            )}
                        </div>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                
                    {current_salesperson ? (
                        <div
                            className="d-flex flex-row justify-content-center w-100"
                            title={!this.check_salesperson() ? 'Missing required fields' : 'Add Salesperson'}
                        >
                            <Button
                                variant={clicked ? 'outline-success' : 'success'}
                                disabled={clicked || !this.check_salesperson() || cancelling}
                                className="btn-op70 align-self-center text-lg"
                                onClick={() => this.update_salesperson()}
                            >
                                {clicked ? 'Saving...' : 'Update'}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        );
    }
}
