import React, { Component } from 'react';
import { ViewRequestModal } from '../Modals/ViewRequestModal';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';

export class CompletedRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: 'L',
            sort_field: 'Completed',
            selected_request: null,
            show_request:false
        };
    }

    get_request_list() {

        let requests = this.props.current_requests_list ? this.props.current_requests_list : [];
        const sort_field = this.state.sort_field;
        const sort_direction = this.state.sort_direction;

        if (requests) {

            const sortingStrategies = {
                Completed: (a, b) => new Date(a.date_completed_str) - new Date(b.date_completed_str),
                Requestor: (a, b) => a.requestor_id.localeCompare(b.requestor_id),
                Requestee: (a, b) => a.requestee_id.localeCompare(b.requestee_id),
                Account: (a, b) => a.customer_name.localeCompare(b.customer_name)
            };
            requests = requests.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
         
            let data = [];
            let cell_style = 'py-0'
            requests.map(d =>
                data.push(
                    {
                        row_style: '',
                        cells: [
                            { cell: d.date_completed_str, cell_style: cell_style },                           
                            { cell: d.customer_name, cell_style: cell_style },
                            { cell: this.props.direction === 'Outgoing' ? d.requestee_id : d.requestor_id, cell_style: cell_style }
                        ],
                        data: d
                    }
                )

            )

            const table = <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                table_headers={this.get_headers()}
                table_data={data}
                row_click={(prop) => this.setState({ selected_request: JSON.parse(prop), show_request: true })}
                table_title=""
                sort_field={sort_field}
            />;
            return (
                table
            )

        } else { return null }
    }

    get_headers() {
        const header_labels = [
            { label: 'Completed', property: 'date_completed' },
            { label: 'Account', property: 'customer_id' }
        ]
        if (this.props.direction === 'Outgoing') {
            header_labels.push({ label: 'Requestee', property: 'requestee_id' })
        } else {
            header_labels.push({ label: 'Requestor', property: 'requestor_id' })
        }
        let headers = [];
        header_labels.map((d) =>
            headers.push({ column_title: d.label, property: d.property, sort_direction: this.state.sort_direction, is_sortable: 'Y' })
        )
        return headers;
    }



    render() {
        const { sort_direction, sort_field, selected_request, show_request } = this.state;
      

        return (
            <div className="d-flex flex-column justify-content-start" style={{maxHeight:'30vh', overflow:'auto'}}>
                {this.get_request_list()}
                <ViewRequestModal
                    show={show_request}
                    onHide={() => this.setState({ show_request: false, selected_request: null })}
                    selected_request={selected_request}
                    view_account={(company_id, customer_id) => this.props.view_account(company_id, customer_id)}
                />
            </div>
        );
    }
}