import React, { Component } from 'react';
import { Alert, Button, Card, Col, Container, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap';


export class Footer extends Component {
    static displayName = Footer.name;
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    check_required_fields(new_customer) {
        if (new_customer.name &&
            new_customer.address1 &&
            new_customer.city &&
            new_customer.state_id &&
            new_customer.zip_code &&
            new_customer.bill_format_flag
        ) {
            return true
        } else {
            return false;
        }
    }





    render() {
        const step_number = this.props.step_number ? parseInt(this.props.step_number):0;
        const verified_matches = this.props.verified_matches ? this.props.verified_matches : [];
        const possible_matches = this.props.possible_matches ? this.props.possible_matches : [];
        const agreement_signed = this.props.agreement_signed ? this.props.agreement_signed : false;
        const add_button_clicked = this.props.add_button_clicked ? this.props.add_button_clicked : false;
        const new_customer = this.props.new_customer ? this.props.new_customer : null
        const searching = this.props.searching ? this.props.searching : false;


        return step_number>1 ? (
            <Card.Footer className="d-flex flex-row w-100 justify-content-end">
        


                {step_number === 2 ?
                    <Button
                        variant={searching ? 'outline-success' : 'success'}
                        onClick={() => this.props.get_possible_matches()}
                        style={{ width: '10rem' }}
                        className="text-med my-3 align-self-center"
                        size="sm"
                        disabled={searching || !this.check_required_fields(new_customer)}
                    >{searching ? 'Checking...' : 'Check'}</Button> :
                    null
                }
                {step_number === 3 && verified_matches.length === possible_matches.length ?
                    <div className="d-flex flex-row align-self-end">
                        {agreement_signed ?
                            <Button disabled={add_button_clicked} onClick={() => this.props.add_prospect()} variant={add_button_clicked ? 'outline-success' : 'success'} className="text-xs" style={{ width: '10rem' }}>
                                {add_button_clicked ? 'Adding Prospect...' : 'Add Prospect'}
                            </Button> :
                            <Button onClick={() => this.props.on_next()} variant="secondary" className="text-xs" style={{ width: '10rem' }}>
                                Next
                            </Button>
                        }
                    
                       
                    </div> :
                    null
                }
              

            </Card.Footer>) :
            null;
    }
}
