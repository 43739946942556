import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import * as customerFunctions from '../../Functions/CustomerFunctions';
import * as prospectActionFunctions from '../../Functions/ProspectActionFunctions';
import * as salespersonFunctions from '../../Functions/SalespersonFunctions';
import * as utilityFunctions from '../../Functions/UtilityFunctions';

import { format } from 'date-fns'
import * as svg_icons from '../../../Media/svg_exports'
import { MsalContext } from '@azure/msal-react';

export class GiftAccountModal extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);

        this.state = {
            clicked: false,
            selected_salesperson: null,
            salesperson_filter:''

        };
    }

    onHide() {
        this.setState({ selected_salesperson :null, clicked:false})
        this.props.onHide();
    }

    async post(salespersons) {
        this.setState({ clicked: true })
        const selected_account_data = this.props.selected_account_data;
        let selected_account = selected_account_data ? selected_account_data.account : null;
        let prospect_actions = selected_account_data ? selected_account_data.prospect_actions ? selected_account_data.prospect_actions:[]:[];
        try {
            if (this.state.selected_salesperson) {
                const current_salesperson = salespersons.find(s => s.salesperson_id.toUpperCase() === this.state.selected_salesperson.toUpperCase());
                const audit_body = {
                    company_id: selected_account.company_id,
                    customer_id: selected_account.id,
                    changed_by_user: this.props.msal_account.username.replaceAll('@scotlynn.com', ''),
                    initial_values: JSON.stringify({
                        salesperson_id: selected_account.salesperson_id,
                        sales_manager_id: selected_account.sales_manager_id,
                        start_date: selected_account.start_date,
                        mark_plan_id: selected_account.mark_plan_id
                    }),
                    new_values: JSON.stringify({
                        salesperson_id: current_salesperson ? current_salesperson.salesperson_id : 'UNCLAIMD',
                        sales_manager_id: current_salesperson ? current_salesperson.sales_manager_id : '',
                        start_date: format(new Date(), 'MM-dd-yyyy'),
                        mark_plan_id: current_salesperson ? 'PROS90D1' : 'RESET'
                    })
                }
                await customerFunctions.Insert_Customer_Audit(audit_body);
                if (current_salesperson && selected_account) {
                    selected_account.salesperson_id = current_salesperson.salesperson_id;
                    selected_account.salesperson_name = current_salesperson.salesperson_name;
                    selected_account.salesperson_is_active = 'Y'
                    selected_account.sales_manager_id = current_salesperson.sales_manager_id;
                    selected_account.sales_manager_name = current_salesperson.sales_manager_name;
                    selected_account.start_date = format(new Date(), 'MM-dd-yyyy');
                    selected_account.mark_plan_id = 'PROD90D1';
                    selected_account.salesperson_phone = current_salesperson.phone;
                    selected_account.salesperson_email_address = current_salesperson.email_address;
                } else {
                    selected_account.prev_salesperson_id = selected_account.salesperson_id;
                    selected_account.salesperson_id = 'UNCLAIMD';
                    selected_account.prev_salesperson_name = selected_account.salesperson_name
                    selected_account.salesperson_name = 'Unclaimed';
                    selected_account.salesperson_is_active = 'Y'
                    selected_account.prev_sales_manager_id = selected_account.sales_manager_id;
                    selected_account.sales_manager_id = '';
                    selected_account.prev_sales_manager_name = selected_account.sales_manager_name;
                    selected_account.sales_manager_name = '';

                    selected_account.start_date = format(new Date(), 'MM-dd-yyyy');
                    selected_account.mark_plan_id = 'RESET';
                    selected_account.prev_salesperson_phone = '';
                    selected_account.salesperson_phone = selected_account.salesperson_phone;
                    selected_account.prev_salesperson_email_address = selected_account.salesperson_email_address;
                    selected_account.salesperson_email_address = '';
                  
                }
 
                this.props.update_selected_account_data('account', selected_account);

                const body = {
                    company_id: selected_account.company_id,
                    customer_id: selected_account.id,
                    salesperson_id: current_salesperson ? current_salesperson.salesperson_id : "UNCLAIMD",
                    sales_manager_id: current_salesperson ? current_salesperson.sales_manager_id : "",
                    user_id: this.props.msal_account.username.replaceAll('@scotlynn.com', '')
                }

                const gifted_data = await prospectActionFunctions.Gifted(body);
                if (gifted_data) {
                    prospect_actions.push(gifted_data);
                }
                this.props.update_selected_account_data('prospect_actions', prospect_actions);
            }
            
            
           
         

            
            this.onHide()

        } catch (e) {
            console.log(e)
            if (selected_account) {
                const body = {
                    status: 500,
                    message: e.message,
                    company_id: selected_account.company_id,
                    function_name: 'GiftAccountModal -> post',
                    primary_pk: selected_account.id,
                    table_name: 'customer',
                    user_id: this.props.msal_account.username.replaceAll('@scotlynn.com', '')
                }

                await utilityFunctions.Log_Error(body);
                this.onHide();
            }
            
        }
    }

    render() {
        const { clicked, selected_salesperson } = this.state;
        const selected_account_data = this.props.selected_account_data;
        let selected_account = selected_account_data ? selected_account_data.account : null;


        let current_salespersons = this.props.salesperson_list ? this.props.salesperson_list:[];
        if (selected_account) {
            current_salespersons = current_salespersons.filter(s => s.company_id === selected_account.company_id && s.salesperson_is_active==='Y')
        }
        const account =selected_account ? <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold" style={{ width: '5rem' }}>
                Account
            </InputGroup.Text>
            <Form.Control
                disabled={true}
                className="text-sm bg-white text-start"
                value={selected_account.id}
            />
        </InputGroup> : null;


        const salesperson_selector = selected_account && current_salespersons ? <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold" style={{ width: '5rem' }}>
                LAM
            </InputGroup.Text>
            <Form.Select className="text-sm bg-white text-start" onChange={(e) => this.setState({ selected_salesperson: e.target.value })} value={selected_salesperson}>
                
                <option value=''>Select</option>
                <option value='UNCLAIMD'>UNCLAIMED</option>
                {current_salespersons.sort((a,b)=>a.salesperson_name.localeCompare(b.salesperson_name)).map((d) =>
                    <option value={ d.salesperson_id}>{d.salesperson_name}</option>
                )}
            </Form.Select>

        </InputGroup> : null;






        return (
            <Modal
                {...this.props}
                size="xl"
                className="bg-modal-focus"
                contentClassName="light-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                    <label className="hover-over w-25" onClick={() => this.onHide()}>{svg_icons.cancel_icon}</label>
                    <div className="d-flex flex-column justify-content-center w-50 text-center p-0 pt-2">
                        <h5 className="">Gift Account</h5>
                    </div>
                    <div className="w-25"></div>
                </Modal.Header>
                {selected_account ?
                    <Modal.Body className="d-flex flex-column">
          
                        <h6 className='align-self-center tile-title'>Change LAM for {selected_account.name}</h6>           
                        <div className="d-flex flex-column w-50 align-self-center mt-2">                
                            {account}         
                            {salesperson_selector}
                            <Button
                                className="text-sm w-25 align-self-center mt-3 mb-3"
                                onClick={() => this.post(current_salespersons)}
                                disabled={clicked}
                                variant={clicked ? 'outline-success' : 'success'} >
                                {clicked ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                    </Modal.Body> :
                    null
                }
            </Modal>

        )
    }



}

