import React, { Component } from 'react';
import { Card, Alert, Button, Form } from 'react-bootstrap';
import LoadingIcons from 'react-loading-icons';

export class SearchLoading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true,
        };
    }

    render() {
        const view = this.props.list_view;
        return (
            <Card.Body className="d-flex flex-column w-60" style={{ alignSelf: 'center' }}>
                <Card.Title as="h6">{view !== 'Hot' ? 'Loading Results...' : 'Loading Hot Prospects...'}</Card.Title>
                {view !== 'Hot' ? (
                    <Alert className="d-flex flex-column" variant="info">
                        <h6 className="p-0">Is this search taking too long?</h6>
                        <hr className="p-0 m-0 pb-2" />
                        <label className="text-sm">Try these things:</label>
                        <ul className="text-sm">
                            <li>Select 1 company (SLC/SLUSA). Loading 1 company will be faster.</li>
                            <li>
                                Make search query more specific. Enter a word or even a letter into the search textbox.
                            </li>
                            <li>Select a state, city or zone to search</li>
                        </ul>
                    </Alert>
                ) : null}

                <LoadingIcons.SpinningCircles
                    stroke="#332D2D"
                    strokeWidth={0.7}
                    strokeOpacity={0.125}
                    speed={0.45}
                    fill="#9FA6B2"
                    style={{
                        alignSelf: 'center',
                        width: '15%',
                        height: '15%',
                        paddingTop: '1.5rem',
                        paddingBottom: '1.5rem',
                    }}
                />
            </Card.Body>
        );
    }
}
