import React, { Component } from 'react';
import { Card, Alert, Button, InputGroup, Form, ButtonGroup } from 'react-bootstrap';
import { GMRequestsStatusSelection } from './RequestStatusSelection';
import { GMPendingRequests } from './GMPendingRequests';
import { CompletedRequests } from '../LAMComponents/CompletedRequests';

export class GMRequestsTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: 'Incoming'
        };
    }


    update_requests(request) {
        if (request && this.props.current_user) {
            const salesperson_id = this.props.current_user.salesperson_id.toUpperCase();
            const requestor_id = request.requestor_id ? request.requestor_id.toUpperCase() : '';
            const responder_id = request.responder_id ? request.responder_id.toUpperCase() : '';
            if (requestor_id === salesperson_id) {
                this.props.update_users_request('outgoing', request);
            }
            else if (responder_id !== salesperson_id && request.request_type === 'ACCTREQ') {
                this.props.update_users_request('no action', request);
            }
            else if (responder_id === salesperson_id && request.request_type === 'ACCTREQ') {
                this.props.update_users_request('action', request);
            } else {
                this.props.update_users_request('incoming', request);
            } 
            this.props.update_request_index(0)
        }
    }

    update_request_type(prop) {
        this.props.update_request_type(prop)
        this.props.update_request_index(0)
    }

    update_direction(prop) {
        this.setState({ direction: prop })
        this.props.update_request_index(0)
    }

    render() {
        const {  direction} = this.state;
        const status = this.props.view_request_status;
        const action_requests_list = this.props.action_requests_list ? this.props.action_requests_list.filter(s => s.status === status && s.request_type==='ACCTREQ') : [];
        const no_action_request_list = this.props.no_action_request_list ? this.props.no_action_request_list.filter(s => s.status === status) : [];
        let incoming_requests_list = this.props.incoming_requests_list ? this.props.incoming_requests_list.filter(s => s.status === status && s.request_type === 'EREQUEST' || s.request_type === 'CREQUEST') : [];
        if (status === 'P') {
            incoming_requests_list = incoming_requests_list.filter(s =>s.responder_id === this.props.current_user.salesperson_id)
        }
       
        const outgoing_requests_list = this.props.outgoing_requests_list ? this.props.outgoing_requests_list.filter(s => s.status === status ) : [];
        let current_requests_list = direction === 'Outgoing' ? outgoing_requests_list : incoming_requests_list;
       
        if (direction === 'Outgoing') {
            current_requests_list = outgoing_requests_list
        } else {
            if (this.props.request_type === 'ACCTREQ') {
                current_requests_list = action_requests_list
            } else if (this.props.request_type === 'NACCTREQ') {
                current_requests_list = no_action_request_list
            } else {
                current_requests_list = incoming_requests_list.filter(s=>s.request_type===this.props.request_type)
            }
        }


        return (
            <div className="d-flex flex-column p-2 h-100">
                <GMRequestsStatusSelection
                    status={this.props.view_request_status}
                    update_status={(prop) => this.props.update_view_request_status(prop)}
                    current_user={this.props.current_user}
                    direction={direction}
                    update_direction={(prop) => this.update_direction(prop)}
                    request_type={this.props.request_type}
                    update_requests_type={(prop) => this.props.update_request_type(prop)}
                    role={this.props.role}
                    action_requests_list={action_requests_list}
                    incoming_requests_list={incoming_requests_list} 
                    outgoing_requests_list={outgoing_requests_list}
                    no_action_request_list={no_action_request_list}

                />
                {status === 'P' ?
                    <GMPendingRequests
                        current_requests_list={current_requests_list}
                        current_user={this.props.current_user}
                        update_requests={(prop) => this.update_requests(prop)}
                        direction={direction}
                        request_type={this.props.request_type}
                        update_index={(prop) => this.props.update_request_index(prop)}
                        index={this.props.request_index}
                        view_account={(current_request) => this.props.view_account({ company_id: current_request.company_id, id: current_request.customer_id }, current_requests_list)}
                        msal_account={this.props.msal_account}
                        role={this.props.role}
                        no_action_request_list={no_action_request_list}

                    /> :
                    <CompletedRequests
                        current_requests_list={current_requests_list}
                        direction={direction}
                        view_account={(company_id, id, list) => this.props.view_account({ company_id: company_id, id: id }, current_requests_list)}
                    />}
            </div>
        );
    }
}