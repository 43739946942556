import { addDays, addHours, addMonths, differenceInDays, differenceInWeeks, format, isAfter, isBefore } from 'date-fns';

export function formatSalespersonsCommissions(input) {
    const output = [];
    try {
        if (input !== null) {
            for (let i = 0; i < input.length; i++) {
                const current_salesperson = input[i];
                current_salesperson.salesperson_start_date = input[i].salesperson_start_date
                    ? input[i].salesperson_start_date
                    : '';
                current_salesperson.commission_start_date = input[i].commission_start_date
                    ? input[i].commission_start_date
                    : '';
                current_salesperson.sales_manager_id = input[i].sales_manager_id
                    ? input[i].sales_manager_id.trim()
                    : '';
                current_salesperson.sales_team_captain_id = input[i].sales_team_captain_id
                    ? input[i].sales_team_captain_id.trim()
                    : '';
                current_salesperson.weeks_in_sales = input[i].salesperson_start_date
                    ? differenceInWeeks(new Date(), new Date(input[i].salesperson_start_date))
                    : 0;
                current_salesperson.primary_salesperson_id = input[i].primary_salesperson_id
                    ? input[i].primary_salesperson_id.trim()
                    : '';
                current_salesperson.primary_LC_gifted_perc = input[i].primary_LC_gifted_perc
                    ? input[i].primary_LC_gifted_perc
                    : 0;
                output.push(current_salesperson);
            }
        }
        return output;
    } catch (e) {
        console.log(e);
        return output;
    }
}


//#region Format Single Record



//#region Helper Functions
export function formatPhoneNumber(input) {
    let output = '';
    try {
        if (input !== null && input !== undefined) {
            input.split('').forEach((char) => {
                if (/^\d+$/.test(char)) {
                    output = output + char;
                }
            });
        }
        if (output.length === 11) {
            if (output[0] === '1') {
                return '1-' + output.substring(1, 4) + '-' + output.substring(4, 7) + '-' + output.substring(7, 11);
            } else {
                return (
                    output.substring(0, 3) +
                    '-' +
                    output.substring(3, 6) +
                    '-' +
                    output.substring(6, 10) +
                    ' ,' +
                    output.substring(10)
                );
            }
        } else if (output.length > 11) {
            if (output[0] === '1') {
                return (
                    '1-' +
                    output.substring(1, 4) +
                    '-' +
                    output.substring(4, 7) +
                    '-' +
                    output.substring(7, 11) +
                    ' ,' +
                    output.substring(11)
                );
            } else {
                return (
                    output.substring(0, 3) +
                    '-' +
                    output.substring(3, 6) +
                    '-' +
                    output.substring(6, 10) +
                    ' ,' +
                    output.substring(10)
                );
            }
        } else if (output.length === 10) {
            return output.substring(0, 3) + '-' + output.substring(3, 6) + '-' + output.substring(6, 10);
        } else if (output.length === 9) {
            return output.substring(0, 3) + '-' + output.substring(3, 6) + '-' + output.substring(6, 9);
        } else if (output.length <= 8) {
            return output;
        }
    } catch (e) {
        console.log(e);
        return output;
    }
}
export function getMostRecentActivity(input) {
    let output = null;
    try {
        if (input !== null) {
            for (let i = 0; i < input.length; i++) {
                const current_activity = input[i];
                if (current_activity.action_date !== undefined && current_activity.action_date !== null) {
                    if (output === null) {
                        output = current_activity;
                    } else {
                        if (isAfter(new Date(current_activity.action_date), new Date(output.action_date))) {
                            output = current_activity;
                        }
                    }
                }
            }
        }
        return output;
    } catch (e) {
        console.log(e);
        return null;
    }
}
export function getCreditStatus(input) {
    if (input !== null && input !== undefined) {
        if (input === 'A') {
            return 'Approved';
        } else if (input === 'T') {
            return 'Pending';
        } else if (input === 'R') {
            return 'Rejected';
        } else {
            return 'None';
        }
    } else {
        return 'None';
    }
}
export function getTypeOf(type_of, category) {
    if (category) {
        if (category === 'DOR') {
            return 'Dormant';
        } else {
            if (type_of) {
                if (type_of === 'C') {
                    return 'Customer';
                } else if (type_of === 'P') {
                    return 'Prospect';
                } else {
                    return 'Unknown';
                }
            }
        }
    } else {
        if (type_of) {
            if (type_of === 'C') {
                return 'Customer';
            } else if (type_of === 'P') {
                return 'Prospect';
            } else {
                return 'Unknown';
            }
        } else {
            return 'Unknown';
        }
    }
}
export function formatAddressFromComponents(input) {
    const output = {
        street_number: '',
        address: '',
        unit: '',
        city: '',
        zip: '',
        zip_suffix: '',
        state_id: '',
    };

    try {
        if (input !== null && input !== undefined) {
            for (let i = 0; i < input.length; i++) {
                if (input[i].types.length > 0) {
                    const current_type = input[i].types[0];
                    if (current_type === 'street_number') {
                        output.street_number = input[i].short_name;
                    } else if (current_type === 'route') {
                        output.address = input[i].short_name;
                    } else if (current_type === 'locality' || current_type === 'administrative_area_level_3') {
                        output.city = input[i].short_name;
                    } else if (current_type === 'administrative_area_level_1') {
                        output.state_id = input[i].short_name;
                    } else if (current_type === 'postal_code') {
                        output.zip = input[i].short_name;
                    } else if (current_type === 'postal_code_suffix') {
                        output.zip_suffix = input[i].short_name;
                    } else if (current_type === 'subpremise') {
                        output.unit = input[i].short_name;
                    } else if (current_type === 'sublocality_level_1') {
                        output.city = input[i].short_name;
                    }
                }
            }

            return output;
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}
export function CreateMessageBody(subject_line, body_text, recipients) {
    return JSON.stringify({
        message: {
            subject: subject_line,
            body: {
                contentType: 'Text',
                content: body_text,
            },
            toRecipients: recipients,
        },
    });
}

export function CheckNullFieldsCustomer(input) {
    if (input) {
        const output = input;
        output.address1 = input.address1 ? input.address1 : '';
        output.address2 = input.address2 ? input.address2 : '';
        output.bill_format_flag = input.bill_format_flag ? input.bill_format_flag : '';
        output.category = input.category ? input.category : '';
        output.city = input.city ? input.city : '';
        output.conversion_date = input.conversion_date ? input.conversion_date : '';
        output.credit_application = input.credit_application ? input.credit_application : '';
        output.credit_check_date = input.credit_check_date ? input.credit_check_date : '';
        output.credit_status = input.credit_status ? input.credit_status : '';
        output.crm_credit_approval_date = input.crm_credit_approval_date ? input.crm_credit_approval_date : '';
        output.crm_credit_precheck = input.crm_credit_precheck ? input.crm_credit_precheck : '';
        output.crm_credit_precheck_request = input.crm_credit_precheck_request ? input.crm_credit_precheck_request : '';
        output.crm_do_not_use = input.crm_do_not_use ? input.crm_do_not_use : '';
        output.google_place_id = input.google_place_id ? input.google_place_id : '';
        output.main_phone = input.main_phone ? input.main_phone : '';
        output.mark_plan_id = input.mark_plan_id ? input.mark_plan_id : '';
        output.name = input.name ? input.name : '';
        output.primary_contact = input.primary_contact ? input.primary_contact : '';
        output.remarks = input.remarks ? input.remarks : '';
        output.sales_manager_id = input.sales_manager_id ? input.sales_manager_id : '';
        output.salesperson_id = input.salesperson_id ? input.salesperson_id : '';
        output.start_date = input.start_date ? input.start_date : '';
        output.state_id = input.state_id ? input.state_id : '';
        output.type_of = input.type_of ? input.type_of : '';
        output.website_url = input.website_url ? input.website_url : '';
        output.zip_code = input.zip_code ? input.zip_code : '';
        output.pay_method = input.pay_method ? input.pay_method : '';
        output.pay_method2 = input.pay_method2 ? input.pay_method2 : '';
        output.percentage = input.percentage ? input.percentage : 0;
        output.percentage2 = input.percentage2 ? input.percentage2 : 0;
        output.expire_date = input.expire_date ? input.expire_date : '';
        output.expire_date2 = input.expire_date2 ? input.expire_date2 : '';
        output.last_ship_date = input.last_ship_date ? input.last_ship_date : '';
        output.order_last_ship_date = input.order_last_ship_date ? input.order_last_ship_date : '';
        return output;
    } else {
        return null;
    }
}
//#endregion Helper Functions

//#region Date Parsers
const utcDateRegexp = /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/;
const localDateRegexp = /^(\d{4}).(\d{2}).(\d{2}).(\d{2}).(\d{2})/;

const parseToDatetime = (dateString, regexp) => {
    const matches = dateString.match(regexp);
    if (matches === null) {
        return '';
    }
    const [year, month, day, hour, minute] = matches.slice(1);
    return `${month}-${day}-${year} ${hour}:${minute}`;
};

const parseToDate = (dateString, regexp) => {
    const matches = dateString.match(regexp);
    if (matches === null) {
        return '';
    }
    const [year, month, day] = matches.slice(1);
    return `${month}-${day}-${year}`;
};

const parseToUTC = (dateString, regexp, timezone) => {
    if (new Date(dateString) === 'Invalid Date') {
        return 'Date was not valid';
    } else {
        const formatted_date = format(dateString ? new Date(dateString) : new Date(), 'yyyy-MM-dd HH:mm');
        const matches = formatted_date.match(regexp);
        if (matches === null) {
            return '';
        }
        const [year, month, day, hour, minute] = matches.slice(1);
        return `${year}${month}${day}${hour}${minute}00${timezone}`;
    }
};

export const parseUTCDate = (input_date) => parseToDate(input_date, utcDateRegexp);

export const parseUTCDatetime = (input_date) => parseToDatetime(input_date, utcDateRegexp);

export const parseDatetimeToUTC = (input_date) => parseToUTC(input_date, localDateRegexp, get_offset());

function get_offset() {
    const date_string = new Date().toString();
    const idx = date_string.indexOf('-');
    return date_string.substring(idx, idx + 5);
}

//#endregion Date Parsers

export function Abort_Controller() {
    const abortController = new AbortController();
    abortController.abort();
}
