import React, { Component } from 'react';
import { Container, Row, Col, Card, Alert, Form, InputGroup, Button, FormCheck, ButtonGroup, FloatingLabel } from 'react-bootstrap';

import SortableTable from '../TableComponents/SortableTable';
import { NumericFormat, numericFormatter } from 'react-number-format';
import { OrderAdjDescriptionModal } from '../Modals/OrderAdjDescriptionModal';
import * as commissionFunctions from '../Functions/CommissionFunctions'
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as svg_icons from '../../Media/svg_exports';


export class ReportsView extends Component {
    static displayName = ReportsView.name;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: '',
            sort_field: '',
            edit_field: '',
            show_add: false,
            search_company_id: 'Both',
            offset: 0,
            edited_list: [],
            show_adj_desc: false,
            search_by: '',
            search_value: '',
            selected_lam: '',
            cutoff_date: '',
            unpaid_commissions: [],
            unpaid_commissions_master:[],
            unpaid_commissions_loaded: false,
            lam_unpaid_commissions: [],
            lam_unpaid_commissions_master:[],
            lam_unpaid_commissions_loaded: false,
            clicked: false,
            company_id_filter: ['TMS','TMS2'],
            status_filter: ['Live', 'Term'],
            search_by_type: '',
            changed_unpaid_lams: [],
            change_unpaid_orders: [],
            update_complete:false

        };
    }

    reset(){
        this.setState({
          search_by: '',
            search_value: '',
            selected_lam: '',
            cutoff_date: '',
            unpaid_commissions: [],
            unpaid_commissions_master:[],
            unpaid_commissions_loaded: false,
            lam_unpaid_commissions: [],
            lam_unpaid_commissions_master:[],
            lam_unpaid_commissions_loaded: false,
            clicked: false,
            company_id_filter: ['TMS','TMS2'],
            status_filter: ['Live', 'Term'],
            search_by_type: ''
        })
    }
     
    set_company_id(e) {
        const filter = this.state.company_id_filter;
        let next_filter = this.state.company_id_filter;
        if (e.target.checked && filter.find(s => s === e.target.name)) {
            next_filter = filter.filter(s => s !== e.target.name)
        } else if (e.target.checked && !filter.find(s => s === e.target.name)) {
            next_filter.push(e.target.name)
        } else if (!e.target.checked && filter.find(s => s === e.target.name)) {
            next_filter = filter.filter(s => s !== e.target.name)
        }

        this.setState({ company_id_filter: next_filter })         
    }

    set_status_filter(e) {
        const filter = this.state.status_filter;
        let next_filter = this.state.status_filter;
        if (e.target.checked && filter.find(s => s === e.target.name)) {
            next_filter = filter.filter(s => s !== e.target.name)
        } else if (e.target.checked && !filter.find(s => s === e.target.name)) {
            next_filter.push(e.target.name)
        } else if (!e.target.checked && filter.find(s => s === e.target.name)) {
            next_filter = filter.filter(s => s !== e.target.name)
        }

        this.setState({ status_filter: next_filter })
    }

    async get_unpaid_order_commissions() {
        try {
            const data = await commissionFunctions.Get_Unpaid(this.state.cutoff_date);
            this.setState({
                unpaid_commissions: data,
                unpaid_commissions_master:data,
                unpaid_commissions_loaded: true
            });
        } catch (e) {
            console.log(e);
            this.setState({
                unpaid_commissions: [],
                unpaid_commissions_master:[],
                unpaid_commissions_loaded: true
            });
        }
    }

    async get_unpaid_order_lams() {
        try {
            this.setState({ clicked :true})
            const data = await commissionFunctions.Get_Unpaid_LAMS(this.state.cutoff_date);
            this.setState({
                lam_unpaid_commissions: data,
                lam_unpaid_commissions_master:data,
                lam_unpaid_commissions_loaded: true,
                clicked:false
            });
            await this.get_unpaid_order_commissions()
        } catch (e) {
            console.log(e);
            this.setState({
                lam_unpaid_commissions: [],
                lam_unpaid_commissions_master:[],
                lam_unpaid_commissions_loaded: true,
                clicked:false
            });
        }

    }

    async update_orders() {
        try {
            const options = {
                method: 'PUT',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.state.edited_list),
            };
            const response = await fetch('Commission/UpdateOrderCommissionsTest', options);
            const data = await response.json();
            console.log(data);
        } catch (e) {
            console.log(e);
        }
    }

    get_row_color(pk) {
        const selected_commission = this.props.selected_commission
        let row_color = '';
        if (selected_commission) {
            if (selected_commission.pk === pk) {
                row_color = 'selected-commission';
            }
        }
        return `text-med ${row_color}`
    }

    get_margin(d) {

        let margin = parseFloat(d.margin);
        let carrier_expense = d.carrier_expense_override > 0 ? parseFloat(d.carrier_expense_override) : parseFloat(d.carrier_expense);
        let total_charge = d.total_charge_override > 0 ? parseFloat(d.total_charge_override) : parseFloat(d.total_charge);
        margin = total_charge - carrier_expense;


        return parseFloat(margin);
    }

    get_commission(d) {
        let margin = this.get_margin(d);
        let percent = parseFloat(d.commission_percent);
        return (margin * percent)
    }

    get_paid_headers() {
        const header = (value) => ({
            column_title: value,
            sort_direction: this.state.sort_direction,
            is_sortable: 'Y',
            header_style: 'px-2 text-nowrap text-sm',
        });

        const headers = [
            header('Current Pay Period'),
            header('Order ID'),
            header('Customer ID'),
            header('Customer Paid'),
            header('Delivery Date'),
            header('Total Charges'),
            header('Shipper Location'),
            header('Consignee Location'),
            header('Order Payable'),
            header('Profit'),
            header('Commission %'),
            header('Commission Pay')
        ];
        return headers;
    }

    get_paid_term_headers() {
        const header = (value) => ({
            column_title: value,
            sort_direction: this.state.sort_direction,
            is_sortable: 'Y',
            header_style: 'px-2 text-nowrap text-sm',
        });

        const headers = [
            header('Current Pay Period'),
            header('Order ID'),
            header('Customer Paid'),
            header('Delivery Date'),
            header('Total Charges'),
            header('Order Payable'),
            header('Profit'),
            header('Commission %'),
            header('Commission Pay')
        ];
        return headers;
    }

    get_unpaid_lam_headers() {   
        const header = (value) => ({
            column_title: value,
            sort_direction: this.state.sort_direction,
            is_sortable: 'Y',
            header_style: 'px-2 text-nowrap text-sm',
        });

        const headers = [
            header('Company'),
            header('Salesperson'),
            header('Role'),
            header('Orders Paid'),
            header('Commission'),
            header('Draw'),
            header('LC Cost'),
            header('Order Adjustments'),
            header('Advance (Repay)'),
            header('Advance Balance'),
            header('Carry Forward'),
            header('Hold Back'),
            header('Net Pay'),
            header('Running Balance'),
            header('Notes'),
        ];
        return headers;
    }

    get_cell(type, val, title) {
        if (type === 'text') {
            return (<bdi className="text-nowrap no-edit" title={title}>
                {val}
            </bdi>)
        }
        else if (type === 'currency') {
            return (<NumericFormat
                value={val ? val : 0}
                title={title}
                decimalScale={2}
                prefix="$ "
                allowNegative
                thousandSeparator=","
                displayType={'text'}
                disabled={true}
                className="outline-0 border-0 no-edit"
                style={{ width: '5rem' }}
                fixedDecimalScale
                renderText={(formattedValue) => <label>{formattedValue}</label>}
            />)
        }

    }

    get_unpaid_lam_table() {
        const cell_styling = 'py-0'
        let unpaid_lam_data = [];
        let lam_total_row = [];
        let lam_unpaid_commissions = this.state.lam_unpaid_commissions ? this.state.lam_unpaid_commissions : []
        const company_filter = this.state.company_id_filter;
        const status_filter = this.state.status_filter;
        if (company_filter.length === 1) {
            lam_unpaid_commissions = lam_unpaid_commissions.filter(s => s.company_id === company_filter[0])
        }
        if (status_filter.length === 1) {
            if (status_filter[0] === 'Term') {
                lam_unpaid_commissions = lam_unpaid_commissions.filter(s => !s.commission_start_date)
            } else {
                lam_unpaid_commissions = lam_unpaid_commissions.filter(s => s.commission_start_date !== undefined && s.commission_start_date !== null)
            }
        }
        if (this.state.search_by_type && this.state.search_value) {
            lam_unpaid_commissions = lam_unpaid_commissions.filter(s => s[this.state.search_by_type].toLowerCase().includes(this.state.search_value.toLowerCase()))  
        }
        if (lam_unpaid_commissions.length > 0) {

            lam_unpaid_commissions.map(d =>
                unpaid_lam_data.push({
                    row_style: this.get_row_color(d.pk),
                    cells: [
                        { cell: this.get_cell('text',d.company_id, ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', d.salesperson_id, ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', d.role, ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', d.orders_paid, ''), cell_style: cell_styling },
                        { cell: this.get_cell('currency',d.commission, ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling }
                    ],
                    data: d
                })
            )
            unpaid_lam_data.push(
                {
                    row_style: '',
                    cells: [
                        { cell: this.get_cell('text', '', ''), cell_style: `${cell_styling}` },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', `Total Orders: ${lam_unpaid_commissions.map(datum => datum.orders_paid).reduce((a, b) => a + b) }`, ''), cell_style: cell_styling+' fw-bold' },
                        { cell: this.get_cell('text','Total Commission: ' + numericFormatter(lam_unpaid_commissions.map(datum => datum.commission).reduce((a, b) => a + b).toString(), utilityFunctions.currency_format_props), ''), cell_style: cell_styling+' fw-bold' },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '', ''), cell_style: cell_styling }
                    ],
                    data: null
                })
            return (<div className="d-flex flex-column" >
                <div className="d-flex flex-row justify-content-end w-100 pb-2">
                    <Button variant="outline-success" className="" size="sm" onClick={() => utilityFunctions.Export_To_CSV(lam_unpaid_commissions, `Unpaid_Commission_LAM_${this.state.cutoff_date}`)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-download"
                            viewBox="0 0 16 16"
                        >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                        </svg>
                    </Button>
                        
                </div>
                <div style={{ height: '20rem', overflow: 'auto' }}>

                    <SortableTable
                        onSort={(prop) =>
                            this.setState({ sort_field: prop, sort_direction: this.state.sort_direction === 'H' ? 'L' : 'H' })
                        }
                        table_headers={this.get_unpaid_lam_headers()}
                        table_data={unpaid_lam_data}
                        row_click={(prop) => this.setState({ selected_lam: JSON.parse(prop).salesperson_id })}
                        table_title=""
                        sort_field={this.state.sort_field}
                    />
                </div>
            </div>)
        } else {
            return null;
        }
      
    }

    get_selected_lam_table() {
        const cell_styling = 'py-0'
        let unpaid_lam_data = [];

        let unpaid_commissions = this.state.unpaid_commissions ? this.state.unpaid_commissions : []

        const company_filter = this.state.company_id_filter;
        const status_filter = this.state.status_filter;
        if (company_filter.length===1) {
          unpaid_commissions=  unpaid_commissions.filter(s => s.company_id === company_filter)
        }
        if (status_filter.length === 1) {
            if (status_filter[0] === 'Term') {
                unpaid_commissions = unpaid_commissions.filter(s => !s.commission_start_date)
            } else {
                unpaid_commissions = unpaid_commissions.filter(s => s.commission_start_date !== undefined && s.commission_start_date !== null)
            }
        }
        if (this.state.search_by_type==='salesperson_id' && this.state.search_value) {
            unpaid_commissions = unpaid_commissions.filter(s => s[this.state.search_by_type].toLowerCase().includes(this.state.search_value.toLowerCase()))
        }
        if (this.state.selected_lam && unpaid_commissions.length > 0) {
            unpaid_commissions.filter(s => s.salesperson_id === this.state.selected_lam).map(d =>
                unpaid_lam_data.push({
                    row_style: this.get_row_color(d.pk),
                    cells: [
                        { cell: this.get_cell('text', d.current_pay_period, ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', d.order_id, ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', d.customer_id, ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', d.zero_balance_date, ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', d.delivery_date, ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', numericFormatter(d.total_charge.toString(), utilityFunctions.currency_format_props), ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '-', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', '-', ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', numericFormatter(d.carrier_expense.toString(), utilityFunctions.currency_format_props), ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', numericFormatter(d.margin.toString(), utilityFunctions.currency_format_props), ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', numericFormatter(d.commission_percent.toString(), utilityFunctions.percent_format_props), ''), cell_style: cell_styling },
                        { cell: this.get_cell('text', numericFormatter(d.commission_amount.toString(), utilityFunctions.currency_format_props), ''), cell_style: cell_styling }
                    ],
                    data: d
                })
            )
            return (<div className="d-flex flex-column mt-3" >
                <div className="d-flex flex-row justify-content-end w-100 pb-2">
                    <Button variant="outline-success" className="" size="sm" onClick={() => utilityFunctions.Export_To_CSV(unpaid_lam_data, `Unpaid_Commission_${this.state.selected_lam}_${this.state.cutoff_date}`)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-download"
                            viewBox="0 0 16 16"
                        >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                        </svg>
                    </Button>

                </div>
                <div style={{ height: '20rem', overflow: 'auto' }}>

                    <SortableTable
                        onSort={(prop) =>
                            this.setState({ sort_field: prop, sort_direction: this.state.sort_direction === 'H' ? 'L' : 'H' })
                        }
                        table_headers={this.get_paid_headers()}
                        table_data={unpaid_lam_data}
                        row_click={(prop) => console.log(JSON.parse(prop))}
                        table_title=""
                        sort_field={this.state.sort_field}
                    />
                </div>
            </div>)           
        } else {
            return null;
        }

    }

    async put_unpaid_commissions() {
        this.setState({
            update_clicked: true,
            changed_unpaid_lams: [],
            change_unpaid_orders: [],
            update_complete: false        })
        if (window.confirm("Are you ready to process these orders?")) {
            let commissions_list = this.state.unpaid_commissions.filter(s => s.commission_start_date !== undefined && s.commission_start_date !== null);
           for(let i=0;i<commissions_list.length;i++){
               commissions_list[i].change_user=this.props.msal_account.username.replaceAll('@scotlynn.com','');
           }         
            const data = await commissionFunctions.Mark_Paid(commissions_list);
            this.reset();
            this.props.update_commissions()
            if (data.length > 0) {
              //  const change_user = data[0].change_user;
               // const change_user_date = data[0].change_user_date;
              //  const lam_data = await commissionFunctions.Get_Changed_Unpaid_LAMS(change_user, change_user_date)
             //   const order_data = await commissionFunctions.Get_Changed_Unpaid(change_user, change_user_date)

                this.setState({
                    changed_unpaid_lams: [],
                    change_unpaid_orders: [],
                    update_complete: true,
                    update_clicked: false
                })
            } else {
                this.setState({
                    changed_unpaid_lams: [],
                    change_unpaid_orders: [],
                    update_complete: false,
                    update_clicked: false
                })
            }
           

        } else {
            this.setState({
                changed_unpaid_lams: [],
                change_unpaid_orders: [],
                update_complete: false,
                update_clicked: false
            })
        } 
     
    }


    render() {
        const {
            search_by,
            search_value,
            cutoff_date,
            unpaid_commissions,
            unpaid_commissions_loaded,
            lam_unpaid_commissions,
            lam_unpaid_commissions_loaded,
            clicked,
            company_id_filter,
            status_filter,
            search_by_type
        } = this.state;

        const update_clicked = this.props.update_clicked;

        const unpaid_lams_table = lam_unpaid_commissions_loaded ? this.get_unpaid_lam_table() : clicked ? <Alert className="w-100 mt-3" variant="secondary">Loading...</Alert> : null;

        const company_id_checkbox =
            <div className="d-flex flex-column">
                <div className="d-flex flex-row">
                    <input type="checkbox"
                        onClick={(e) => this.set_company_id(e)}
                        className="align-self-center"
                        name="TMS2"
                        defaultChecked={company_id_filter.find(s => s === 'TMS2')} />
                    <label className="text-sm ps-2 align-self-center">SLUSA</label>
                </div>
                <div className="d-flex flex-row">
                    <input type="checkbox"
                        onClick={(e) => this.set_company_id(e)}
                        className="align-self-center"
                        name="TMS"
                        defaultChecked={company_id_filter.find(s => s === 'TMS')} />
                    <label className="text-sm ps-2 align-self-center">SLC</label>
                </div>
            </div>;

        const status_checkbox =
            <div className="d-flex flex-column ms-3">
                <div className="d-flex flex-row">
                    <input type="checkbox"
                        onClick={(e) => this.set_status_filter(e)}
                        className="align-self-center"
                        name="Live"
                        defaultChecked={status_filter.find(s => s === 'Live')} />
                    <label className="text-sm ps-2 align-self-center">Live</label>
                </div>
                <div className="d-flex flex-row">
                    <input type="checkbox"
                        onClick={(e) => this.set_status_filter(e)}
                        className="align-self-center"
                        name="Term"
                        defaultChecked={status_filter.find(s => s === 'Term')} />
                    <label className="text-sm ps-2 align-self-center">Term'd</label>
                </div>
            </div>


        const order_id_search = (
            <InputGroup className="mt-3" style={{ width: '25rem' }}>
                <Form.Select value={search_by_type} onChange={(e) => this.setState({ search_by_type: e.target.value })} className="text-sm flex-grow-2">
                    <option value=''>Select Filter By</option>
                    <option value='salesperson_id'>Salesperson</option>
                    <option value='order_id'>Order</option>
                    <option value='customer_id'>Customer</option>
                </Form.Select>
                <Form.Control
                    type="text"
                    className="text-sm flex-grow-1"
                    name="search_value"
                    disabled={update_clicked}
                    placeholder={search_by}
                    value={search_value}
                    onChange={(e) => this.setState({ search_value: e.target.value })}
                />
           
            </InputGroup>
        );

        const cutoff_date_search =
            <InputGroup className="mt-3">
                <FloatingLabel label="Commission Cut Off Date" className="text-sm p-0 m-0">
                    <Form.Control type="date" className="text-sm" value={cutoff_date} onChange={(e) => this.setState({ cutoff_date:e.target.value })} />
                </FloatingLabel>
                <Button
                    className="text-sm flex-grow-3"
                    style={{ borderRadius: '0rem 0.2rem 0.2rem 0rem' }}
                    variant="success"
                    disabled={update_clicked}
                    onClick={() => this.get_unpaid_order_lams()}
                >
                    Go
                </Button>
            </InputGroup>




        return (
            <div style={{ width: '95vw', height: '78vh', overflow: 'auto' }}>
            
                <div className="d-flex flex-row mb-2 justify-content-between pe-2">
                    <div className="d-flex flex-column">
                        {cutoff_date_search}
                        
                        <div className="d-flex flex-row ps-1 mt-3">
                            {company_id_checkbox}
                            {status_checkbox}

                        </div>
                        {order_id_search}
                    </div>

                    <Button
                        disabled={update_clicked}
                        variant={update_clicked ? 'outline-success' : 'success'}
                        className="text-med  align-self-center"
                        size="sm"
                        onClick={() => this.put_unpaid_commissions()}>
                        {update_clicked ? 'Processing...' : 'Process'}
                    </Button>
                </div>

                {unpaid_lams_table}
                {this.get_selected_lam_table()}
    
            </div>
        );
    }

    //#region Search Functions

    async search_orders(search_by, search_value) {
        const search_company_id = this.state.search_company_id;
        if (search_by === 'order_id') {
            const data = await commissionFunctions.SearchByOrder(search_company_id, search_value, 1)
            this.props.update_orders_list(data)
        }
        else if (search_by === 'customer_id') {
            const data = await commissionFunctions.SearchByCustomer(search_company_id, search_value, 1)
            this.props.update_orders_list(data)
        }
        else if (search_by === 'salesperson_id') {
            const data = await commissionFunctions.SearchBySalesperson(search_company_id, search_value, 1)
            this.props.update_orders_list(data)
        }
    }



    async Get_By_Shipped(company_id, start_date, end_date) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsShipped/${company_id}/${start_date}/${end_date}/${this.state.offset}`,
                options,
            );
            const data = await response.json();
            this.props.update_orders(data);
        } catch (e) {
            console.log(e);
        }
    }

    async Get_By_Delivery(company_id, start_date, end_date) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsDelivery/${company_id}/${start_date}/${end_date}/${this.state.offset}`,
                options,
            );
            const data = await response.json();
            this.props.update_orders(data);
        } catch (e) {
            console.log(e);
        }
    }

    async Get_By_Customer(company_id, customer_id) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsCustomer/${company_id}/${customer_id}/${this.state.offset}`,
                options,
            );
            const data = await response.json();
            this.props.update_orders(data);
        } catch (e) {
            console.log(e);
        }
    }

    async Get_By_Salesperson(company_id, salesperson_id) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsSalesperson/${company_id}/${salesperson_id}/${this.state.offset}`,
                options,
            );
            const data = await response.json();
            this.props.update_orders(data);
        } catch (e) {
            console.log(e);
        }
    }

    async Get_By_Margin(company_id, low, high) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsMargin/${company_id}/${low}/${high}/${this.state.offset}`,
                options,
            );
            const data = await response.json();
            this.props.update_orders(data);
        } catch (e) {
            console.log(e);
        }
    }

    async Get_By_Commission(company_id, low, high) {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            };
            const response = await fetch(
                `Commission/GetOrderCommissionsCommission/${company_id}/${low}/${high}/${this.state.offset}`,
                options,
            );
            const data = await response.json();
            this.props.update_orders(data);
        } catch (e) {
            console.log(e);
        }
    }

    //#endregion Search Functions
}
