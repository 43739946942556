import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import LoadingIcons from 'react-loading-icons';
import * as svg_icons from '../../Media/svg_exports';
import SortableTable from '../TableComponents/SortableTable'
import sortByStrategy from '../../Domain/sorting-strategy';
import * as revenueCodeFunctions from '../Functions/RevenueCodeFunctions';
export class RevenueCodes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sort_direction: 'H',
            sort_field: 'Code',
            show_add_form: true,
            rev_code_id: '',
            rev_descr: '',
            company_id: '',
            clicked: false,
            message: '',
            filter_company: '',
            filter_asset:''
        };
    }

    async add_rev_code() {
        this.setState({ clicked: true, message:'Adding rev code...' })
        const body = {
            __type: 'revenue_code',
            company_id: this.state.company_id,
            id: this.state.rev_code_id.toUpperCase(),
            descr: this.state.rev_descr
        }
       
        const data = await revenueCodeFunctions.Add_Revenue_Code(body)        
        let list = this.props.revenue_codes;
        list.push(body);
        this.props.update_revenue_codes(list);
        this.setState({
            sort_direction: 'H',
            sort_field: 'Code',
            show_add_form: true,
            rev_code_id: '',
            rev_descr: '',
            company_id: '',
            message: '',
            filter_company: '',
            filter_asset: '',
            clicked: false})
    }

    onHide() {
        this.setState({
            sort_direction: 'H',
            sort_field: 'Code',
            show_add_form: true,
            rev_code_id: '',
            rev_descr: '',
            company_id: '',
            filter_company: '',
            filter_asset: '',
            clicked: false,
            message:''
        })
            this.props.onHide()
    }

    get_headers() {
        const header_titles = [
            'Company',
            'Code',
            'Description'
        ]
        let headers = [];
        header_titles.map(d => headers.push({
            column_title: d,
            sort_direction: this.state.user_sort_direction,
            is_sortable: 'Y',
            header_style: 'text-xs align-self-end',
        }))
        return (headers)
    }

    get_data() {
        let revenue_codes = this.props.revenue_codes ? this.props.revenue_codes : [];
        if (this.state.filter_company) {
            revenue_codes = revenue_codes.filter(s => s.company_id === this.state.filter_company)
        }
        if (this.state.filter_asset) {
            revenue_codes = revenue_codes.filter(s => s.id.toUpperCase().includes(this.state.filter_asset.toUpperCase()))
        }
        if (revenue_codes.length) {
            const sortingStrategies = {
                'Company': (a, b) => a.company_id.localeCompare(b.company_id),
                'Code': (a, b) => a.id.localeCompare(b.id),
                'Description': (a, b) => a.descr - b.descr
            };
            revenue_codes = revenue_codes.sort(sortByStrategy(sortingStrategies, this.state.sort_field, this.state.sort_direction));

            const data = [];
            let cell_style = 'text-xs text-nowrap';
            revenue_codes.map(d =>
                data.push({
                    row_style: '',
                    cells: [
                        { cell: d.company_id, cell_style: cell_style },
                        { cell: d.id, cell_style: cell_style },
                        { cell: d.descr, cell_style: cell_style }
                    ],
                    data: d

                })
            )

            return (<SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: this.state.sort_direction === 'H' ? 'L' : 'H' })
                }
                row_click={(prop) => null}
                table_headers={this.get_headers()}
                table_data={data}
                table_title=""
                sort_field={this.state.sort_field}
            />)
        } else {
            return null;
        }
 
    }

    render() {
        const {
            show_add_form,
            rev_code_id,
            rev_descr,
            company_id,
            clicked,
            message,
            filter_company,
            filter_asset
        } = this.state;

        let form_style='text-xs'

        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen="md-down"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName="light-modal"
                >
                    <Modal.Header className="w-100">
                        <div className="d-flex flex-row justify-content-between w-100">
                            <label className="lg-page-title hover-over" title="Close" onClick={() => this.onHide()}>X</label>
                            <label className="lg-page-title ">Revenue Codes</label>
                            <label></label>
                        </div>

                    </Modal.Header>
                    <Modal.Body className="d-flex flex-column">
                        <div className="d-flex flex-column" style={{ maxHeight: '30rem', overflow: 'auto' }}>
                            <InputGroup className="mb-2">
                                <InputGroup.Text className={form_style}>Search</InputGroup.Text>
                                <Form.Select
                                    className={form_style}
                                    value={filter_company}
                                    onChange={(e) => this.setState({ filter_company: e.target.value })}>
                                    <option value=''>Select Company</option>
                                    <option value='TMS'>SLC</option>
                                    <option value='TMS2'>SLUSA</option>
                                </Form.Select>
                                <Form.Control
                                    value={filter_asset}
                                    className={form_style}
                                    onChange={(e) => this.setState({ filter_asset: e.target.value })}
                                    placeholder="Search by rev code..."
                                />
                            </InputGroup>
                            {this.get_data()}
                        </div>
                        <div className="d-flex flex-row justify-content-center w-100">
                           
                            
                            {show_add_form ?
                                <div className="d-flex flex-column w-100 mt-3 border rounded p-1">
                                    <h6 className="portal-tile-title m-0 p-0 mb-1">Add New Revenue Code to McLeod</h6>
                                    <InputGroup className="mb-3">
                                        <Button
                                            className="text-xs light-gray-border"
                                            title="Cancel"
                                            variant="light"
                                            onClick={() => this.setState({
                                                show_add_form: false,
                                                company_id: '',
                                                rev_code_id: '',
                                                rev_descr: ''
                                            })}
                                            size="sm">
                                            X
                                        </Button>
                                        <Form.Select
                                            className={form_style}
                                            value={company_id}
                                            isInvalid={!company_id}
                                            onChange={(e) => this.setState({ company_id: e.target.value })}>
                                            <option value=''>Select Company</option>
                                            <option value='TMS'>SLC</option>
                                            <option value='TMS2'>SLUSA</option>
                                        </Form.Select>
                                        <Form.Control
                                            maxLength={5}
                                            className={form_style}
                                            value={rev_code_id}
                                            isInvalid={!rev_code_id}
                                            placeholder="Rev Code ID (max 5 chars)"
                                            onChange={(e) => this.setState({ rev_code_id: e.target.value })}
                                        />
                                        <Form.Control
                                            maxLength={15}
                                            className={form_style}
                                            value={rev_descr}
                                            isInvalid={!rev_descr}
                                            placeholder="Description (max 15 chars)"
                                            onChange={(e) => this.setState({ rev_descr: e.target.value })} />
                                        <Button
                                            className="text-xs"
                                            title='Save Rev Code'
                                            variant={clicked ? 'outline-success' : 'success'}
                                            disabled={clicked || !rev_descr || !rev_code_id || !company_id}
                                            onClick={() => this.add_rev_code()}
                                            size="sm">
                                            {svg_icons.save_icon}
                                        </Button>
                                    </InputGroup>
                                </div> :
                                <Button
                                    className="text-xs my-3 align-self-center"
                                    variant="success"
                                    
                                    style={{ width: '10rem' }}
                                    onClick={() => this.setState({ show_add_form: true })}
                                    size="sm">
                                    {svg_icons.plus_icon}Add Rev Code
                                </Button>
                            }
                          
                        </div>
                        {message ?
                            <i className="text-xs text-success w-100 text-center">{message}</i> :
                            null}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
