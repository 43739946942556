import { Button } from 'react-bootstrap';
import * as svg_icons from '../../../Media/svg_exports';

function check_disabled() {
    if (window.location.pathname.toLowerCase().includes('addnewprospect')) {
        return true;
    } else {
        return false;
    }

}

function navigate_to() {
    if (!check_disabled()) {
        window.location.pathname =`/addnewprospect`
    } 
}

function AddProspectButton() {
    return (
        <label
           
            className={`text-nowrap ${check_disabled() ? 'add-prospect-button-disabled' :'add-prospect-button'}`}
            onClick={() => navigate_to()}
        >
            <div className="align-self-center">{svg_icons.plus_icon}</div>
            <bdi className="align-self-center">  Add Prospect</bdi>
          
        </label>
    );
}

export default AddProspectButton;
