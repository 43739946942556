import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { isBefore, addDays, differenceInDays, addMonths, format } from 'date-fns';
import * as svg_icons from '../../../Media/svg_exports';

export class SendpackButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true,
        };
    }


    sendpack_status_msg(selected_account) {
        let summary = { message: null, icon: null, icon_color: '' }
        const sendpack_actions = this.props.prospect_actions ?
            this.props.prospect_actions.filter(s => s.activity_id === 'SENDPACK' && s.status === 'C').sort((b, a) => new Date(a.action_date_str) - new Date(b.date_completed))
            : [];
        if (selected_account.type_of === 'Customer') {
            return null;
        } else {
            if (sendpack_actions.length > 0) {
                return (
                    <Button
                        className={`text-sm d-flex flex-row justify-content-center p-1 me-2`}
                        onClick={ ()=>this.props.onClick()}
                        size="sm"
                        variant="success"
                        title={`SENDPACK activity has been completed for this account: ${format(sendpack_actions[0].action_date_str, 'MM-dd-yyyy')}`}
                        style={{ width: '10rem' }}>
                        <bdi className="align-self-center ">Pack Sent</bdi>


                        <div className={`text-white ms-1 align-self-center`} >
                            {svg_icons.simple_checkmark_icon}
                        </div>
                    </Button>
                )               
            } else {
                return (
                    <Button
                        className={`text-sm d-flex flex-row justify-content-center p-1 me-2`}
                        onClick={() => this.props.onClick()}
                        size="sm"
                        variant="secondary"
                        title={`SENDPACK activity has NOT been completed for this account.`}
                        style={{ width: '10rem' }}>
                        <bdi className="align-self-center ">Send Pack Now</bdi>

                        <div className={`text-danger ms-1 bg-white align-self-center`} style={{ borderRadius: '50%' }}>
                            {svg_icons.missing_icon}
                        </div>
                    </Button>
                )
            }

        }

    }



    render() {
        const { open_tile } = this.state;
        const selected_account = this.props.selected_account;
        let num_days = -1;
        if (selected_account) {
            if (selected_account.type_of === 'Customer') {
                if (selected_account.last_ship_date) {
                    num_days = differenceInDays(new Date(), new Date(selected_account.last_ship_date));
                }
            } else {
                if (selected_account.last_contacted_date) {
                    num_days = differenceInDays(new Date(), new Date(selected_account.last_contacted_date));
                }
            }

        }


        return selected_account ? (
            this.sendpack_status_msg(selected_account)

        ) : null;
    }
}