import { Button, Spinner, Badge } from 'react-bootstrap';

function NumberBadge(prop) {
    if (prop.num) {
        if (prop.loaded === 'Y') {
            return (
                <Badge bg={prop.num >= 50 ? 'error' : 'secondary'} size="lg" className="text-xs ms-1">
                    {prop.num}
                </Badge>
            );
        } else {
            return <Spinner animation="grow" variant="secondary" className="ms-1" style={{ width: '0.8rem', height: '0.8rem' }} />;
        }
    } else {
        return null;
    }
  
}

function ChangeLocation(thisPath, nextPath) {
    if (thisPath !== nextPath) {
        window.location.pathname = nextPath
    }
}

function LocationButton(prop) {
    return (
        <label
            variant="dark"
            className={`location-button${window.location.pathname.toLowerCase().includes(prop.location) || (prop.location === '/dashboard' && window.location.pathname === '/') ? '-active' : ''}`}
            onClick={() => ChangeLocation(window.location.pathname, prop.location)}
        >
            {prop.name}
            {NumberBadge(prop)}
        </label>
    );
}

export default LocationButton;
