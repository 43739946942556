import { MsalContext } from '@azure/msal-react';
import { addHours, format, getHours, differenceInDays } from 'date-fns';
import React, { Component } from 'react';
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row, Toast } from 'react-bootstrap';
import { AddSalespersonModal } from './Modals/Salespersons/AddSalespersonModal';
import { UpdateSalespersonModal } from './Modals/Salespersons/UpdateSalespersonModal';
import { RevenueCodes } from './Modals/RevenueCodes'
import SortableTable from './TableComponents/SortableTable';
import sortByStrategy from '../Domain/sorting-strategy';
import * as utilityFunctions from './Functions/UtilityFunctions'
import * as salespersonFunctions from './Functions/SalespersonFunctions'
import * as revenueCodeFunctions from './Functions/RevenueCodeFunctions'

export class Salespersons extends Component {
    static displayName = Salespersons.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: '',
            sort_field: '',
            edit_field: '',
            search_filter: {
                company_id: ['TMS', 'TMS2'],
                is_active: ['Y'],
                role: '',
                salesperson_start_date: '',
                commission_start_date: '',
                name: '',
                code: '',
                sales_team_captain: '',
                trainer: '',
                general_manager: '',
            },
            search_by: 'name',
            salespersons: [],
            salespersons_loaded: false,
            sales_team_captains: [],
            general_managers: [],
            titles: [],
            trainers: [],
            selected_salesperson: null,
            show_edit_salesperson: false,
            new_salesperson: null,
            show_add_salesperson: false,
            update_clicked: false,
            update_message: '',
            current_user: null,
            users_w_sales_id: [],
            users_w_sales_id_loaded: false,
            revenue_codes: [],
            show_revenue_codes:false
        };
        this.update_search_filter = this.update_search_filter.bind(this);
    }

    async componentDidMount() {
        const salesperson_template = await salespersonFunctions.Get_Template();
        const users = await utilityFunctions.Get_All_Users_W_Sales_ID();
        const revenue_code_data = await revenueCodeFunctions.Get();
        const account = this.context.accounts;
        const login_id = account ? (account.length > 0 ? account[0].username.replaceAll('@scotlynn.com', '') : '') : '';
        this.setState({
            current_user: login_id,
            users_w_sales_id: users,
            users_w_sales_id_loaded: true,
            new_salesperson: salesperson_template,
            selected_salesperson: salesperson_template,
            revenue_codes: revenue_code_data
        });
        this.get_salesperson_roles();
        this.get_commission_salespersons();
    }

    async reset_salesperson() {
        const salesperson_template = await salespersonFunctions.Get_Template();
        this.setState({
            new_salesperson: salesperson_template,
            selected_salesperson: salesperson_template,
            show_add_salesperson: false,
            show_edit_salesperson: false,
        });
    }

    async get_commission_salespersons() {
        try {
            const data = await salespersonFunctions.Get_Commission_Salespersons();    
            this.set_list(data);
           
        } catch (e) {
            console.log(e);
            this.setState({
                salespersons: [],
                sales_team_captains: [],
                general_managers: [],
                trainers: [],
                salespersons_loaded: true,
            });
        }
    }

    async get_salesperson_roles() {
        try {
            const data = await salespersonFunctions.Get_Salesperson_Roles();
            this.setState({ titles: data });
        } catch (e) {
            console.log(e);
            this.setState({
                titles: [],
            });
        }
    }

    set_list(data) {
        try {
            let captains = data.filter((s) => s.role === 'STC');
            let gms = data.filter((s) => s.role === 'GM' || s.role === 'DOO');
            let trainers = data.filter((s) => s.hierarchy === 'LAM');
            let new_data = [];
    
            if (this.props.role === 'gm') {
                const user_id = this.props.salesperson.user_id.toLowerCase();
                for (let i = 0; i < data.length; i++) {
                    const c_salesperson = data[i];
                    if (c_salesperson.sales_manager_id) {
                        if (c_salesperson.sales_manager_id.toLowerCase() === user_id) {
                            new_data.push(c_salesperson)
                            if (c_salesperson.role === 'STC') {
                                captains.push(c_salesperson)
                            } else if (c_salesperson.role === 'GM' || c_salesperson.role === 'DOO') {
                                gms.push(c_salesperson)
                            } else if (c_salesperson.hierarchy === 'LAM') {
                                trainers.push(c_salesperson)
                            }
                        }
                    }
                }
            } else {
                captains = data.filter((s) => s.role === 'STC');
                gms = data.filter((s) => s.role === 'GM' || s.role === 'DOO');
                trainers = data.filter((s) => s.hierarchy === 'LAM');
                new_data=data
            }

           
            this.setState({
                salespersons: new_data,
                sales_team_captains: captains,
                general_managers: gms,

                trainers: trainers,
                salespersons_loaded: true,
                show_add_salesperson: false,
            });
        } catch (e) {
            console.log(e);
        }
    }

    update_search_filter(field, value) {
        const current_filter = this.state.search_filter;
        if (field === 'company_id') {
            if (current_filter.company_id.filter((s) => s === value).length > 0) {
                current_filter.company_id = current_filter.company_id.filter((s) => s !== value);
            } else {
                current_filter.company_id.push(value);
            }
        } else if (field === 'is_active') {
            if (current_filter.is_active.filter((s) => s === value).length > 0) {
                current_filter.is_active = current_filter.is_active.filter((s) => s !== value);
            } else {
                current_filter.is_active.push(value);
            }
        } else if (field.includes('date')) {
            current_filter[field] = format(addHours(new Date(value), 5), 'MM-dd-yyyy');
        } else {
            current_filter[field] = value;
        }

        this.setState({ search_filter: current_filter });
    }

    update_search_by(value) {
        const current_filter = this.state.search_filter;
        current_filter.name = '';
        current_filter.code = '';
        current_filter.sales_team_captain = '';
        current_filter.trainer = '';
        current_filter.general_manager = '';
        this.setState({ search_filter: current_filter, search_by: value });
    }

    get_headers() {
        const header_text = 'px-2 text-nowrap text-sm';
        const headers_list = [];
        const headers = [
            { label: 'Salesperson Name', property: 'salesperson_name' },
            { label: 'Salesperson Start Date', property: 'salesperson_start_date' },
            { label: 'Commission Start Date', property: 'commission_start_date' },
            { label: 'General Manager', property: 'sales_manager_id' },
            { label: 'Sales Team Captain', property: 'sales_team_captain_id' },
            { label: 'Weeks In Sales', property: 'weeks_in_sales' },
            { label: 'Primary Salesperson', property: 'primary_salesperson_id' },
            { label: 'Gifted Percentage', property: 'primary_LC_gifted_perc' },
        ];
        headers.map((s) =>
            headers_list.push({
                column_title: s.label,
                sort_direction: this.state.sort_direction,
                is_sortable: 'Y',
                header_style: header_text,
            }),
        );
        return headers_list;
    }

    render() {
        const {
            sort_direction,
            sort_field,
            edit_field,
            search_filter,
            search_by,
            salespersons,
            sales_team_captains,
            general_managers,
            salespersons_loaded,
            titles,
            trainers,
            selected_salesperson,
            show_edit_salesperson,
            update_clicked,
            update_message,
            new_salesperson,
            show_add_salesperson,
            current_user,
            users_w_sales_id,
            users_w_sales_id_loaded,
            revenue_codes,
            show_revenue_codes
        } = this.state;


        let current_salespersons = salespersons ? salespersons : [];
        const current_gms = general_managers ? general_managers : [];
        let current_captains = sales_team_captains ? sales_team_captains : [];

        //#region Filter
        if (search_filter && current_salespersons) {
            if (search_filter.company_id.length === 1) {
                current_salespersons = current_salespersons.filter((s) => s.company_id === search_filter.company_id[0]);
            }
            if (search_filter.is_active.length===1) {
                current_salespersons = current_salespersons.filter((s) => s.is_active === search_filter.is_active[0]);
            }
            if (search_filter.role !== '') {
                current_salespersons = current_salespersons.filter((s) => s.role === search_filter.role);
            }
            if (search_filter.salesperson_start_date !== '') {
                current_salespersons = current_salespersons.filter(
                    (s) => s.salesperson_start_date === search_filter.salesperson_start_date,
                );
            }
            if (search_filter.commission_start_date !== '') {
                current_salespersons = current_salespersons.filter(
                    (s) => s.commission_start_date === search_filter.commission_start_date,
                );
            }
            if (search_filter.name !== '') {
                current_salespersons = current_salespersons.filter((s) =>
                    s.salesperson_name.toUpperCase().includes(search_filter.name.toUpperCase()),
                );
            }
            if (search_filter.code !== '') {
                current_salespersons = current_salespersons.filter((s) =>
                    s.salesperson_id.includes(search_filter.code.toUpperCase()),
                );
            }
            if (search_filter.sales_team_captain !== '') {
                current_salespersons = current_salespersons.filter(
                    (s) => s.sales_team_captain_name === search_filter.sales_team_captain,
                );
            }
            if (search_filter.trainer !== '') {
                current_salespersons = current_salespersons.filter(
                    (s) => s.trained_by === search_filter.trainer.toUpperCase(),
                );
            }
            if (search_filter.general_manager !== '') {
                current_salespersons = current_salespersons.filter(
                    (s) => s.sales_manager_name === search_filter.general_manager,
                );
            }
        }
       
        //#endregion Filter

        //#region Commisions Table

        let salesperson_data = [];
        if (current_salespersons) {
            const sortingStrategies = {
                'Salesperson Name': (a, b) => a.salesperson_name.localeCompare(b.salesperson_name),
                'Salesperson Start Date': (a, b) => differenceInDays(new Date(a.salesperson_start_date), new Date(b.salesperson_start_date)),
                'Commission Start Date': (a, b) => new Date(a.commission_start_date) - new Date(b.commission_start_date),
                'General Manager': (a, b) => a.sales_manager_name.localeCompare(b.sales_manager_name),
                'Sales Team Captain': (a, b) => a.sales_team_captain_name.localeCompare(b.sales_team_captain_name),
                'Weeks In Sales': (a, b) => a.weeks_in_sales - b.weeks_in_sales,
                'Gifted Percentage': (a, b) => a.primary_LC_gifted_perc - b.primary_LC_gifted_perc,
            };
            current_salespersons = current_salespersons.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
        }
        for (let i = 0; i < current_salespersons.length; i++) {

            const current_primary = salespersons.find(
                (s) =>
                    s.salesperson_id === current_salespersons[i].primary_salesperson_id &&
                    s.company_id === current_salespersons[i].company_id,
            );
            let primary_salesperson_name = '';
            if (current_salespersons[i].multi_lc === 'Y') {
                primary_salesperson_name = 'Multiple';
            } else if (current_primary) {
                primary_salesperson_name = current_primary.salesperson_name;
            }
            let cell_style = 'text-sm'

   

            salesperson_data.push({
                row_style: '',
                cells: [
                    { cell: current_salespersons[i].salesperson_name, cell_style: cell_style },
                    { cell: current_salespersons[i].salesperson_start_date, cell_style: cell_style },
                    { cell: current_salespersons[i].commission_start_date, cell_style: cell_style },
                    { cell: current_salespersons[i].sales_manager_name, cell_style: cell_style },
                    { cell: current_salespersons[i].sales_team_captain_name, cell_style: cell_style },
                    { cell: current_salespersons[i].salesperson_start_date? current_salespersons[i].weeks_in_sales:'-', cell_style: cell_style },
                    { cell: primary_salesperson_name, cell_style: cell_style },
                    { cell: current_salespersons[i].primary_LC_gifted_perc, cell_style: cell_style },
                ],
                data: current_salespersons[i],
            });

        }

    

        const commissions_table = (
            <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                table_headers={this.get_headers()}
                table_data={salesperson_data}
                row_click={(prop) =>
                    this.setState({ selected_salesperson: JSON.parse(prop), show_edit_salesperson: true })
                }
                table_title=''
                sort_field={sort_field}
            />
        );

        //#endregion Commissions Table

        //#region Search Container
        const selectable_filter = (list) =>
            salespersons_loaded ? (
                <Form.Select
                    onChange={(e) => this.update_search_filter(search_by, e.target.value)}
                    value={search_filter[search_by]}
                    className="text-sm"
                >
                    <option value="">Select</option>
                    {list.map((d) => (
                        <option value={d.salesperson_name}>{d.salesperson_name}</option>
                    ))}
                </Form.Select>
            ) : null;

        const titles_select = salespersons_loaded ? (
            <Form.Select
                onChange={(e) => this.update_search_filter('role', e.target.value)}
                className="text-sm"
            >
                <option value=''>Select</option>
                {titles.map((d, index) => (
                    <option value={d.id} key={index}>
                        {d.name}
                    </option>
                ))}
            </Form.Select>
        ) : null;

        const search_container = (
                <div className="d-flex flex-column">
                    <InputGroup>
                        <InputGroup.Text className="text-sm">Search By</InputGroup.Text>
                        <Form.Select
                        className="text-sm"
                        value={search_by}
                            onChange={(e) => this.setState({ search_by: e.target.value })}
                        >
                            <option value="name">Name</option>
                            <option value="code">Code</option>
                            <option value="general_manager">General Manager</option>
                            <option value="sales_team_captain">Sales Team Capt.</option>
                            <option value="trainer">Trainer</option>                            
                        </Form.Select>
                        {search_by === 'sales_team_captain' ? selectable_filter(sales_team_captains) : null}
                        {search_by === 'general_manager' ? selectable_filter(general_managers) : null}
                        {search_by === 'trainer' ? selectable_filter(trainers) : null}
                        {search_by === 'name' || search_by === 'code' ? (
                            <Form.Control
                            className="text-sm"
                            value={search_filter[search_by]}
                                onChange={(e) => this.update_search_filter(search_by, e.target.value)}
                            />
                        ) : null}
                        <Button
                            className="text-sm"
                            size="sm"
                            variant="success"
                            onClick={() => console.log(search_filter)}
                        >
                            Go
                        </Button>
                    </InputGroup>
                    <div className="d-flex flex-row mt-3">
                        <div className="d-flex flex-column me-4">
                            <Form.Check
                                label={salespersons ? `SLUSA (${salespersons.filter(s => s.company_id === 'TMS2').length})` : 'SLUSA'}
                                onClick={() => this.update_search_filter('company_id', 'TMS2')}
                                checked={search_filter.company_id.filter((s) => s === 'TMS2').length > 0}
                            />
                            <Form.Check
                                label={salespersons ? `SLC (${salespersons.filter(s => s.company_id === 'TMS').length})` : 'SLC'}
                                onClick={() => this.update_search_filter('company_id', 'TMS')}
                                checked={search_filter.company_id.filter((s) => s === 'TMS').length > 0}
                            />
                        </div>
                        <div className="d-flex flex-column">
                            <Form.Check
                                label={salespersons ? `Active (${salespersons.filter(s => s.is_active === 'Y').length})` : 'Active'}
                                onClick={() => this.update_search_filter('is_active', 'Y')}
                                checked={search_filter.is_active.filter((s) => s === 'Y').length > 0}
                            />
                            <Form.Check
                                label={salespersons ? `Inactive (${salespersons.filter(s => s.is_active === 'N').length})` : 'Inactive'}
                                onClick={() => this.update_search_filter('is_active', 'N')}
                                checked={search_filter.is_active.filter((s) => s === 'N').length > 0}
                            />
                        </div>
                    </div>
                    <InputGroup className="mt-3">
                        <InputGroup.Text className="text-sm">User Type</InputGroup.Text>
                        {titles_select}
                    </InputGroup>
                    <div className="d-flex flex-column p-1 border w-100 rounded mt-3">
                        <small className="text-sm opacity-75">Salesperson Start Date</small>
                        <Form.Control
                            onChange={(e) => this.update_search_filter('salesperson_start_date', e.target.value)}
                            type="date"
                            className="p-1 border-0 outline-0 text-sm w-100 shadow-0"
                            value={search_filter.salesperson_start_date}
                        />
                    </div>
                    <div className="d-flex flex-column p-1 border rounded my-3">
                        <small className="text-sm opacity-75">Commission Start Date</small>
                        <Form.Control
                            onChange={(e) => this.update_search_filter('commission_start_date', e.target.value)}
                            type="date"
                            className="p-1 border-0 outline-0 text-sm w-100 shadow-0"
                            value={
                                search_filter.commission_start_date
                                    ? format(new Date(search_filter.commission_start_date), 'yyyy-MM-dd')
                                    : null
                            }
                        />
                    </div>
                </div>
        );

        //#endregion Search Container

        //#region Filter Toast
        const filter_toast = (title, field, value) => (
            <Toast
                className="shadow-0 m-1"
                onClose={() => {
                    const current_filter = search_filter;
                    if (Array.isArray(search_filter[field])) {
                        current_filter[field] = [];
                    } else {
                        current_filter[field] = '';
                    }
                    this.setState({ search_filter: current_filter });
                }}
            >
                <Toast.Header className="w-100 border-0 rounded ">
                    <div className="d-flex flex-row justify-content-start me-auto text-sm">
                        <strong className=" align-self-start opacity-75 pe-2">{title}:</strong>
                        <div className="d-flex flex-row justify-content-start">
                            {Array.isArray(value) ? (
                                value.map((d, index) => (
                                    <bdi className="text-wrap align-self-start pe-1" key={index}>
                                        {index === value.length - 1 ? d : `${d},`}
                                    </bdi>
                                ))
                            ) : (
                                <bdi className="text-wrap align-self-start">{value}</bdi>
                            )}
                        </div>
                    </div>
                </Toast.Header>
            </Toast>
        );
        //#endregion Filter Toast

        let width = this.props.width;
        let display= width<800?'column':'row'

        return (
            <div className={ `d-flex w-100 justify-content-center h-100 p-3`}>
                <AddSalespersonModal
                    show={show_add_salesperson}
                    new_salesperson={new_salesperson}
                    update_salesperson={(prop) => this.setState({ new_salesperson: prop })}
                    onClose={() => this.reset_salesperson()}
                    current_user={current_user}
                    salespersons={salespersons}
                    general_managers={general_managers}
                    titles={titles}
                    trainers={trainers}
                    sales_team_captains={sales_team_captains}
                    insert_salesperson={() => this.insert_salesperson()}
                    users_w_sales_id={users_w_sales_id}
                    users_w_sales_id_loaded={users_w_sales_id_loaded}
                    update_users_w_sales_id={(prop) => this.setState({ users_w_sales_id: prop })}
                    revenue_codes={revenue_codes}
                />
                <UpdateSalespersonModal
                    show={show_edit_salesperson}
                    selected_salesperson={selected_salesperson}
                    update_salesperson={(prop) => this.setState({ selected_salesperson: prop })}
                    onClose={() => this.reset_salesperson()}
                    current_user={current_user}
                    salespersons={salespersons}
                    general_managers={general_managers}
                    titles={titles}
                    trainers={trainers}
                    sales_team_captains={sales_team_captains}
                    put_salesperson={() => this.update_salesperson()}
                    revenue_codes={revenue_codes}
                />
                {salespersons_loaded ?
                    <Card className="p-3 d-flex flex-column">
                       
                        <div className="d-flex flex-row">
                            {search_filter.company_id
                                ? filter_toast('Company ID', 'company_id', search_filter.company_id)
                                : null}
                            {search_filter.is_active
                                ? filter_toast('Is Active?', 'is_active', search_filter.is_active)
                                : null}
                            {search_filter.name ? filter_toast('Name', 'name', search_filter.name) : null}
                            {search_filter.code ? filter_toast('Code', 'code', search_filter.code) : null}
                            {search_filter.title ? filter_toast('Title', 'title', search_filter.title) : null}
                            {search_filter.sales_team_captain
                                ? filter_toast('Sales Team Capt.', 'sales_team_captain', search_filter.sales_team_captain)
                                : null}
                            {search_filter.trainer ? filter_toast('Trainer', 'trainer', search_filter.trainer) : null}
                            {search_filter.general_manager
                                ? filter_toast('General Manager', 'general_manager', search_filter.general_manager)
                                : null}
                            {search_filter.salesperson_start_date
                                ? filter_toast(
                                    'Salesperson Start Date',
                                    'salesperson_start_date',
                                    search_filter.salesperson_start_date,
                                )
                                : null}
                            {search_filter.commission_start_date
                                ? filter_toast(
                                    'Commission Start Date',
                                    'commission_start_date',
                                    search_filter.commission_start_date,
                                )
                                : null}
                        </div>
                        <div className="d-flex flex-row w-100 justify-content-end mb-2">
                            <Button
                                className="text-sm align-self-end me-2"
                                style={{width:'10rem'}}
                                size="sm"
                                variant="warning"
                                onClick={() => this.setState({ show_revenue_codes: true })}
                            >Rev Codes</Button>
                            <Button
                                className="text-sm align-self-end"
                                style={{ width: '10rem' }}
                                size="sm"
                                variant="success"
                                onClick={() => this.setState({ show_add_salesperson: true })}
                            >
                                Add Salesperson
                            </Button>
                        </div>
                        <div className={ `d-flex flex-${display} justify-content-between w-100`}>
                            {search_container}
                            <div className={ `d-flex flex-column ${width<800?'':'ps-3'}`}>
                                <h6 className="tile-title opacity-75">{salespersons && current_salespersons ? `${current_salespersons.length} / ${salespersons.length}` : ''}</h6>
                                <div style={{ overflow: 'auto', height: '75vh' }} className="border rounded">
                                    {salespersons_loaded ? (
                                        commissions_table
                                    ) : (
                                        <Alert variant="secondary">Loading...</Alert>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card> :
                    <Alert className="w-100 mt-3 text-center" variant="secondary">Loading...</Alert>
                }
                <RevenueCodes
                    show={show_revenue_codes}
                    onHide={() => this.setState({ show_revenue_codes: false })}
                    revenue_codes={revenue_codes}
                    update_revenue_codes={(prop) => this.setState({ revenue_codes :prop})}
                />
            </div>
        );
    }

    async insert_salesperson() {
        try {
            const account = this.context.accounts;
            const login_id = account
                ? account.length > 0
                    ? account[0].username.replaceAll('@scotlynn.com', '')
                    : ''
                : '';
            const current_salesperson = this.state.new_salesperson;
            current_salesperson.change_user = login_id;
            const response = await fetch('Commission/InsertCommissionDimSalesperson', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(current_salesperson),
            });
            const data = await response.json();
            this.set_list(data);
            this.reset_salesperson();
        } catch (e) {
            this.setState({ show_add_salesperson: false });
        }
    }

    async update_salesperson() {
        try {
            const account = this.context.accounts;
            const login_id = account
                ? account.length > 0
                    ? account[0].username.replaceAll('@scotlynn.com', '')
                    : ''
                : '';
            const current_salesperson = this.state.selected_salesperson;
            current_salesperson.change_user = login_id;
     
            const response = await fetch('Commission/UpdateCommissionDimSalesperson', {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(current_salesperson),
            });
            const data = await response.json();
            this.set_list(data);
            this.reset_salesperson();
        } catch (e) {
            this.setState({ show_edit_salesperson: false });
        }
    }
}
