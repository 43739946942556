
const put_options = (body) => {
    return {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    }
}
const post_options = (body) => {
    return {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    }
}

export async function Search(filter) {
    try {
        const response = await fetch('SearchFilter', put_options(filter));

        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }


}

export async function Search_For_Matches(new_customer) {
    try {
        const response = await fetch('PossibleMatch/SearchForMatches', post_options(new_customer));

        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}