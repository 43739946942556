
const put_options = (body) => {
    return {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    }
};
export async function Get(company_id, offset) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`OrderCommission/GetInitial/${company_id}/${offset}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Unpaid(cutoff_date) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`OrderCommission/GetUnpaid/${cutoff_date}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Unpaid_LAMS(cutoff_date) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`OrderCommission/GetUnpaidLAMs/${cutoff_date}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Changed_Unpaid_LAMS(change_user, change_user_date) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`OrderCommission/GetChangedUnpaidLAMs/${change_user}/${change_user_date}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Changed_Unpaid(change_user, change_user_date) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`OrderCommission/GetChangedUnpaid/${change_user}/${change_user_date}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function SearchByOrder(company_id,order_id) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`OrderCommission/SearchByOrder/${company_id}/${order_id}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}
export async function SearchBySalesperson(search) {
    try {
        const response = await fetch(`OrderCommission/SearchBySalesperson`, put_options(search));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function SearchByCustomer(search) {
    try {
        const response = await fetch(`OrderCommission/SearchByCustomer`, put_options(search));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}



export async function Update(body) {
    try {
        const options = {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        };
        const response = await fetch('OrderCommission', options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Mark_Paid(body) {
    try {
        const options = {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        };
        const response = await fetch('OrderCommission/MarkPaid', options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}


export async function Get_Customers() {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`OrderCommission/GetCustomers`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function Get_Salespersons() {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`OrderCommission/GetSalespersons`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}