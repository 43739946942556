import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import LoadingIcons from 'react-loading-icons';

export class OrderAdjDescriptionModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            additional_details: '',
        };
    }

    render() {
        const { additional_details } = this.state;
        const selected_commission = this.props.selected_commission;
        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen="md-down"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="p-3">
                        <Modal.Title
                            id="contained-modal-title-vcenter"
                            className="text-muted text-center w-100 pt-3 m-0"
                        >
                            <Container>
                                <Row>
                                    <Col xs={1}>
                                        <h5 className="hover-over" onClick={() => this.props.onHide()}>X</h5>
                                    </Col>
                                    <Col xs={10} className="d-flex flex-column">
                                        <h5 className="">Adjustment Decription</h5>
                                        <h6 className="opacity-75">#{selected_commission.order_id}</h6>
                                    </Col>
                                    <Col xs={1}></Col>
                                </Row>
                            </Container>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid pb-5">
                        <div className="">
                            <Form.Control
                                as="textarea"
                                onChange={(e) => this.props.on_update(e.target.value, selected_commission.pk)}
                                value={selected_commission.adj_description}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
