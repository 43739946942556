import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import * as customerFunctions from '../../Functions/CustomerFunctions';
import * as requestFunctions from '../../Functions/RequestFunctions';
import * as utilityFunctions from '../../Functions/UtilityFunctions';
import { format } from 'date-fns'
import * as svg_icons from '../../../Media/svg_exports'
import { MsalContext } from '@azure/msal-react';

export class ExtensionModal extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);

        this.state = {
            new_request: {
                company_id: this.props.selected_account ? this.props.selected_account.company_id : '',
                requestor_id: this.props.current_user ? this.props.current_user.salesperson_id : '',
                sales_manager_id: this.props.current_user ? this.props.current_user.extension_manager_id : '',
                remarks: ``,
                customer_id: this.props.selected_account ? this.props.selected_account.id : ''

            },
            clicked: false
        };
    }

    onHide() {
        this.setState({
            new_request: {
                company_id: this.props.selected_account ? this.props.selected_account.company_id : '',
                requestor_id: this.props.current_user ? this.props.current_user.salesperson_id : '',
                sales_manager_id: this.props.current_user ? this.props.current_user.extension_manager_id : '',
                remarks: ``,
                customer_id: this.props.selected_account ? this.props.selected_account.id : ''

            },
            clicked: false
        })
        this.props.onHide();
    }

    reset_new_request() {
        this.setState({
            new_request: {
                company_id: this.props.selected_account ? this.props.selected_account.company_id : '',
                requestor_id: this.props.current_user ? this.props.current_user.salesperson_id : '',
                sales_manager_id: this.props.current_user ? this.props.current_user.extension_manager_id : '',
                remarks: ``,
                customer_id: this.props.selected_account ? this.props.selected_account.id : ''
            },
            clicked: false
        })
    }

    componentDidUpdate(prevProp, nextProp) {

        if (prevProp) {
            if (prevProp.selected_account) {
                if (prevProp.selected_account.id !== this.props.selected_account.id) {
                    this.reset_new_request()
                }
            }
        }

    }

    componentDidMount() {
        //   this.reset_new_request()
    }

    handle_remarks_update(e) {
        let new_request = this.state.new_request;
        new_request.remarks = e.target.value;
        this.setState({ new_request: new_request })
    }

    async post() {
        this.setState({ clicked: true })
        const selected_acount = this.props.selected_account
        const request_data = await requestFunctions.Request_Extension(this.state.new_request);
        await this.email(this.state.new_request, selected_acount, request_data.id);
        let requests = this.props.requests;
        requests.push(request_data);
        this.props.update_selected_account_data('requests', requests)
        this.props.add_users_request('outgoing',request_data)
        this.onHide()
    }

    async email(request, selected_account, new_request_id) {
        try {
          
            const email_company_id = this.props.current_user.company_id === 'TMS2' ? 'SLUSA' : 'SLC';
            const subject_line =
                process.env.REACT_APP_EMAIL_TITLE +
                ': ' +
                email_company_id +
                ' Extension Request For ' +
                request.customer_id;
            let message_text = `Extension requested for ${selected_account.name}. \n\nThis ${selected_account.type_of} is set to expire on ${selected_account.expiry_date}.\n`;
            if (request.remarks) {
                message_text = `${message_text} \nAdditional notes from LAM: ${request.remarks}`
            }

            message_text = `${message_text} \n\nView request at: ${process.env.REACT_APP_WEB_URL}/dashboard/${new_request_id}`
            const recipients = [
                {
                    emailAddress: {
                        address: this.props.current_user.extension_manager_email
                    },
                },
            ];
            const message_body = {
                message: {
                    subject: subject_line,
                    body: {
                        contentType: 'Text',
                        content: message_text,
                    },
                    toRecipients: recipients,
                },
            }
            const inst = this.context.instance;
            const acct = this.context.accounts;
            await utilityFunctions.Send_Email(inst, acct, message_body)
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { new_request, clicked } = this.state;
        const selected_account = this.props.selected_account;
        let requests = this.props.requests ? this.props.requests.sort((b, a) => new Date(a.date_requested) - new Date(b.date_requested)) : [];
        let pending_request = requests.length > 0 ? requests.find(s => s.status === 'P' && s.request_type === 'EREQUEST') : null;
        let last_request = requests.length > 0 ? requests[0] : null


        const recipient = this.props.current_user ? <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold " style={{ width: '5rem' }}>
                Recipient
            </InputGroup.Text>
            <Form.Control
                disabled={true}
                className="text-sm bg-white text-start"
                value={this.props.current_user.extension_manager_email}
            />
        </InputGroup> : null;

        const requestor = this.props.current_user ? <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold" style={{ width: '5rem' }}>
                Requestor
            </InputGroup.Text>
            <Form.Control
                disabled={true}
                className="text-sm bg-white text-start"
                value={this.props.current_user.salesperson_id}
            />
        </InputGroup> : null;

        const account = this.props.selected_account ? <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold" style={{ width: '5rem' }}>
                Account
            </InputGroup.Text>
            <Form.Control
                disabled={true}
                className="text-sm bg-white text-start"
                value={this.props.selected_account.id}
            />
        </InputGroup> : null;

        const additional_remarks = <InputGroup className="overview-tab-input-group mb-3">
            <InputGroup.Text className="text-wrap text-sm p-1 text-bold pe-3" style={{ width: '5rem' }}>
                Additional Remarks
            </InputGroup.Text>
            <Form.Control
                disabled={clicked}
                onChange={(e) => this.handle_remarks_update(e)}
                as="textarea"
                className="text-sm bg-white text-start"
                placeholder="Enter remarks here..."
                value={new_request.remarks}
            />
        </InputGroup>



        return (
            <Modal
                {...this.props}
                size="xl"
                className="bg-modal-focus"
                contentClassName="light-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                    <label className="hover-over w-25" onClick={() => this.onHide()}>{svg_icons.cancel_icon}</label>
                    <div className="d-flex flex-column justify-content-center w-50 text-center p-0 pt-2">
                        <h5 className="">Request Extension</h5>
                        {pending_request ?
                            <small className="text-danger align-self-center fw-bold">**This account already has a pending Extension request**</small>
                            : null}


                    </div>

                    <div className="w-25"></div>
                </Modal.Header>
                {selected_account ?
                    <Modal.Body className="d-flex flex-column">
                        <h6 className='align-self-center tile-title'>Submit Extension Request for {selected_account.name}</h6>
                        {last_request ?
                            <small className="align-self-center mb-2">{`[An Extension request was previously submitted on ${last_request.date_requested_str} by ${last_request.requestor_name}]`}</small>
                            : null}
                        <div className="d-flex flex-column w-50 align-self-center mt-2">
                            {recipient}
                            {requestor}
                            {account}
                            {additional_remarks}
                            <Button
                                className="text-sm w-25 align-self-center mt-3 mb-3"
                                onClick={() => this.post()}
                                variant={clicked ? 'outline-success' : 'success'} >
                                {clicked ? 'Sending...' : 'Send Request'}

                            </Button>
                        </div>
                    </Modal.Body> :
                    null
                }




            </Modal>

        )
    }



}

