import {
    Card,
    Stack,
} from 'react-bootstrap';

export const BarGraphToolTip = ({ active, payload, label, color }) => {
    if (active && payload && payload.length) {
        const text_color = color === 'warning' ? 'dark' : 'light';
        const monday_popover = (
            <Card id="monday-popover" bg={color} text={text_color} className="opacity70">
                <Card.Header className="d-flex flex-row w-100 p-1">
                    <label className="w-100" style={{ alignSelf: 'center' }}>
                        Monday
                    </label>
                </Card.Header>
                <Card.Body>
                    <Stack gap={2}>
                        <label className="text-sm">
                            <strong>Last Week:</strong> {payload[0].payload.Num_Last}
                        </label>
                        <label className="text-sm">
                            <strong>This Week:</strong> {payload[0].payload.Num}
                        </label>
                    </Stack>
                </Card.Body>
            </Card>
        );
        const tuesday_popover = (
            <Card id="tuesday-popover" bg={color} text={text_color} className="opacity70">
                <Card.Header className="d-flex flex-row w-100 p-1">
                    <label className="w-100" style={{ alignSelf: 'center' }}>
                        Tuesday
                    </label>
                </Card.Header>
                <Card.Body>
                    <Stack gap={2}>
                        <label className="text-sm">
                            <strong>Last Week:</strong> {payload[0].payload.Num_Last}
                        </label>
                        <label className="text-sm">
                            <strong>This Week:</strong> {payload[0].payload.Num}
                        </label>
                    </Stack>
                </Card.Body>
            </Card>
        );
        const wednesday_popover = (
            <Card id="wednesday-popover" bg={color} text={text_color} className="opacity70">
                <Card.Header className="d-flex flex-row w-100 p-1">
                    <label className="w-100" style={{ alignSelf: 'center' }}>
                        Wednesday
                    </label>
                </Card.Header>
                <Card.Body>
                    <Stack gap={2}>
                        <label className="text-sm">
                            <strong>Last Week:</strong> {payload[0].payload.Num_Last}
                        </label>
                        <label className="text-sm">
                            <strong>This Week:</strong> {payload[0].payload.Num}
                        </label>
                    </Stack>
                </Card.Body>
            </Card>
        );
        const thursday_popover = (
            <Card id="thursday-popover" bg={color} text={text_color} className="opacity70">
                <Card.Header className="d-flex flex-row w-100 p-1">
                    <label className="w-100" style={{ alignSelf: 'center' }}>
                        Thursday
                    </label>
                </Card.Header>
                <Card.Body>
                    <Stack gap={2}>
                        <label className="text-sm">
                            <strong>Last Week:</strong> {payload[0].payload.Num_Last}
                        </label>
                        <label className="text-sm">
                            <strong>This Week:</strong> {payload[0].payload.Num}
                        </label>
                    </Stack>
                </Card.Body>
            </Card>
        );
        const friday_popover = (
            <Card id="friday-popover" bg={color} text={text_color} className="opacity70">
                <Card.Header className="d-flex flex-row w-100 p-1">
                    <label className="w-100" style={{ alignSelf: 'center' }}>
                        Friday
                    </label>
                </Card.Header>
                <Card.Body>
                    <Stack gap={2}>
                        <label className="text-sm">
                            <strong>Last Week:</strong> {payload[0].payload.Num_Last}
                        </label>
                        <label className="text-sm">
                            <strong>This Week:</strong> {payload[0].payload.Num}
                        </label>
                    </Stack>
                </Card.Body>
            </Card>
        );
        return (
            <div className="d-flex flex-column p-1 w-100">
                {label === 'MON' ? monday_popover : null}
                {label === 'TUE' ? tuesday_popover : null}
                {label === 'WED' ? wednesday_popover : null}
                {label === 'THU' ? thursday_popover : null}
                {label === 'FRI' ? friday_popover : null}
            </div>
        );
    }
};