import React, { Component } from 'react';
import { Card, Alert, Button, InputGroup, Form } from 'react-bootstrap';
import * as svg_icons from '../../Media/svg_exports';
import * as utilityFunctions from '../Functions/UtilityFunctions'
import * as fetchFunctions from '../Functions/FetchFunctions'
import { numericFormatter } from 'react-number-format';
import { differenceInDays } from 'date-fns';

export class RandomUnclaimedTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true,
        };
    }

    

    Display_Phone_Property(selected_account){
        let phone = '';
        if (selected_account) {
            phone = selected_account.main_phone ? fetchFunctions.formatPhoneNumber(selected_account.main_phone) : '';
            if (phone) {
                return (
                    <div
                        onClick={() => window.open(`tel:+${phone}`, '_blank')}
                        variant="link"
                        title="Open website in new tab"
                        className="text-primary align-self-center hover-over my-2">
                        <bdi className="pe-2 text-med underlined">{phone}</bdi>
                        {svg_icons.phone_icon}
                    </div>
                )
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    Display_Website_Property(selected_account) {
        let website = '';
        if (selected_account) {
            website = selected_account.website_url ? selected_account.website_url : '';
            if (website) {
                return (
                    <div
                        onClick={() => window.open(`https://${website}`, '_blank')}
                        className="text-primary align-self-center hover-over mb-2"
                        title="Open website in new tab">
                        <bdi className="pe-2 text-med underlined">{website}</bdi>
                        {svg_icons.website_icon}
                    </div>
                )
            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    view_details(current_unclaimed) {
        this.props.view_account(current_unclaimed, this.props.list.filter(s => s.salesperson_id === 'UNCLAIMD'))
    }


    render() {
        const { open_tile } = this.state;
        const index = this.props.index;
        const list = this.props.list ? this.props.list : [];
        const current_unclaimed = index > -1 && list.length > 0 ? list[index] : null;
        let last_contacted_date = current_unclaimed ? current_unclaimed.action_date_str ? new Date(current_unclaimed.action_date_str) : null : null;
        let last_contacted_days = last_contacted_date ? numericFormatter(differenceInDays(new Date(), last_contacted_date).toString(), utilityFunctions.number_format_props)+' Days' :'Never';

        
        const display_property = (property,value) =>
            <div className="d-flex flex-column bg-light-gray rounded p-1 border" style={{width:'48%'}}>
                <small className="opacity-75 text-xs">{property}</small>
                <bdi className="text-sm">{ value}</bdi>
            </div>

        const unclaimed_body = current_unclaimed ? <div className="d-flex flex-column flex-grow-1 justify-content-between px-1">
            <div className="d-flex flex-column justify-content-between">
                <label className="w-100 text-center fw-bold text-lg align-self-center capitalize">{current_unclaimed.name.toLowerCase()}</label>
                {current_unclaimed.primary_contact ?
                    <label className="w-100 text-center text-sm text-secondary">{current_unclaimed.primary_contact}</label> :
                    null}
                {this.Display_Phone_Property(current_unclaimed)}
                {this.Display_Website_Property(current_unclaimed)}
                <Button
                    onClick={() => this.view_details(current_unclaimed)}
                    className="text-sm w-50 align-self-center my-2"
                    size="sm"
                    variant="success"
                >
                    View Details
                </Button>
                <div className="d-flex flex-column mt-2">
                    <div className="d-flex flex-row justify-content-between">
                        {display_property('Last Contacted: ', last_contacted_days)}
                        {display_property('Credit Status: ', current_unclaimed.credit_status)}
                    </div>

                    <div className="d-flex flex-row justify-content-between mt-2">

                        {display_property('Last LAM: ', `${current_unclaimed.prev_salesperson_name ? current_unclaimed.prev_salesperson_name : 'None'}${current_unclaimed.prev_salesperson_is_active === 'N' ? ' (inactive)' : ''}`)}
                        {display_property('Type: ', current_unclaimed.type_of)}
                    </div>
                    <div className="d-flex flex-column bg-light-gray rounded p-1 border w-100 mt-2" style={{ height: '6rem', overflow: 'auto' }}>

                        <small className="opacity-75 text-xs">{current_unclaimed.action_date_str ? `Last Remark: [${current_unclaimed.action_date_str}]` : 'Last Remark: N/A'}</small>
                        {current_unclaimed.last_activity_remarks ?
                            <p className="text-sm">{current_unclaimed.last_activity_remarks}</p> :
                            null
                        }

                    </div>

                </div>
            </div>
            <label className="w-100 text-center text-lg align-self-center my-3"> {index > -1 ? `${index + 1} / ${list.length}` : null}</label>
        </div> :
            null;


        return current_unclaimed ? (<div className="d-flex flex-column justify-content-start w-100 align-self-center">

   


            <div className="d-flex flex-row justify-content-between w-100 align-self-center">
                <label className="hover-over align-self-center" style={{ width: '2rem' }}
                    onClick={() => this.props.update_index(index>0?(index-1):index)}>
                    {index > 0 ? svg_icons.left_chevron : null}
                </label>

                {unclaimed_body}
                
                <label className="hover-over align-self-center" style={{ width: '2rem' }}
                    onClick={() => this.props.update_index(index < list.length - 1 ? (index + 1) : index)}>
                    {index < list.length-1 ? svg_icons.right_chevron : null}
                </label>
               
            </div>
        </div>):null
    }
}
