

export async function Get_By_Customer(company_id, customer_id) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`Order/GetByCustomer/${company_id}/${customer_id}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}
export async function Get_By_Customer_For_LAM(company_id, customer_id) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`Order/GetByCustomerForLam/${company_id}/${customer_id}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}