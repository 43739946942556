import { MsalContext } from '@azure/msal-react';
import React, { Component } from 'react';
import { Alert,  Card,InputGroup} from 'react-bootstrap';
import * as customerFunctions from '../Functions/CustomerFunctions';
import * as searchFilterFunctions from '../Functions/SearchFilterFunctions';
import * as possibleMatchFunctions from '../Functions/PossibleMatchFunctions';
import { PossibleMatchesModal } from '../Modals/PossibleMatchesModal';
import { ViewAccountModal } from '../Modals/Account/ViewAccountModal';
import { LoadingModal } from '../Modals/LoadingModal';
import { GoogleAutocompleteInput } from '../Inputs/GoogleAutocompleteInput';
import * as template from './Templates';
import { NewProspectForm } from './NewProspectForm';
import { Footer } from './Footer';
import { PossibleMatchesResults } from './PossibleMatchesResults';
import verified_checkbox from '../../Media/verified_checkbox.PNG'
import check_button_img from '../../Media/check_button.PNG'
import * as svg_icons from '../../Media/svg_exports';

export class AddNewProspect extends Component {
    static displayName = AddNewProspect.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            add_button_clicked: false,         
            searching: false,
            agreement_signed:false,
            account_clicked: false,
            show_matches_modal:false,
            possible_matches: [],
            list_to_verify: [],
            no_verify_list:[],
            verified_matches:[],
            selected_account_data: null,
            show_selected_account: true,
            new_customer: null,
            step_number: 1
        };
    }

    set_template() {
        let selected_template = this.props.salesperson.company_id === 'TMS2' ? template.slusa : template.slc;
        this.setState({

            new_customer: {
                company_id: this.props.salesperson ? this.props.salesperson.company_id : '',
                name: selected_template.name,
                remarks: '',
                bill_format_flag: '',
                main_phone: selected_template.main_phone,
                website_url: selected_template.website_url,
                address1: selected_template.address1,
                address2: '',
                city: selected_template.city,
                state_id: selected_template.state_id,
                zip_code: selected_template.zip_code,
                salesperson_id: this.props.salesperson ? this.props.salesperson.salesperson_id : '',
                sales_manager_id: this.props.salesperson ? this.props.salesperson.sales_manager_id : '',
                google_place_id: ''
            },
            step_number:2
        })
    }

    async reset_new_customer() {
        const current_user = this.props.salesperson
        const new_prospect_template = await customerFunctions.Get_Template(current_user.company_id, current_user.salesperson_id, current_user.sales_manager_id);
        this.setState({
            new_customer: new_prospect_template,
            add_button_clicked: false,
            searching: false,
            agreement_signed: false,
            account_clicked: false,
            show_matches_modal: false,
            possible_matches: [],
            verified_matches: [],
            list_to_verify: [],
            no_verify_list: [],
            selected_account_data: null,
            show_selected_account: true,
            step_number: 1
        })
    }


    async log_possible_match_audit(company, list) {
        const current_user = this.props.salesperson;
        try {
            const matched_body = {
                name: current_user.salesperson_name,
                user_id: current_user.user_id,
                company_id: current_user.company_id,
                entered_customer_name: company.name,
                entered_dba: company.remarks,
                entered_main_phone: company.main_phone,
                entered_website: company.website_url,
                entered_address: `${company.address1} ${company.address2 ? company.address2 : ''}`,
                entered_city: company.city,
                entered_state_id: company.state_id,
                entered_zip_code: company.zip_code,
                possible_matches_list: JSON.stringify(list),
            };
            const data = await possibleMatchFunctions.Log_Possible_Matches(matched_body)
            console.log(data)


        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async get_possible_matches() {
        const new_customer = this.state.new_customer;
        if (new_customer) {
            let company_id =new_customer.company_id;
            this.setState({ searching: true, possible_matches: [], verified_matches: [] })
            const data = await searchFilterFunctions.Search_For_Matches(new_customer)
            this.log_possible_match_audit(new_customer, data)
            this.setState({
                possible_matches: data,
                list_to_verify: data.filter(s => s.company_id === company_id),
                no_verify_list: data.filter(s => s.company_id !== company_id),
                searching: false,
                step_number: 3
            })
        }
      
    }

    update_account(e) {
        let customer = this.state.new_customer
        customer[e.target.name] = e.target.value;
        if (e.target.name === 'company_id') {
            customer.salesperson_id = '';
            customer.sales_manager_id = '';
        }
        this.setState({ new_customer: customer })
    }

    update_account_from_google(e) {
        let customer = this.state.new_customer
        customer[e.target.name] = e.target.value;
        if (e.target.name === 'company_id') {
            customer.salesperson_id = '';
            customer.sales_manager_id = '';
        }
        this.setState({ new_customer: customer, step_number:2 })
    }


    async componentDidMount() {
        const current_user = this.props.salesperson;
        const new_prospect_template = await customerFunctions.Get_Template(current_user.company_id, current_user.salesperson_id, current_user.sales_manager_id);
        this.setState({ new_customer: new_prospect_template })

    }

    async add_prospect() {
        this.setState({ add_button_clicked :true})
        let customer = this.state.new_customer;
        const data = await customerFunctions.Add_Customer(customer)
        if (data) {
            if (this.props.role === 'gm' || this.props.role === 'lam') {
                this.props.add_account(data);
            }
            
            this.reset_new_customer();
            this.setState({ step_number:4 })
        }
       

    }

    add_to_verified_matches(e, customer) {
        let verified_matches = this.state.verified_matches;
        if (e.target.checked) {
            verified_matches.push(customer);         
        } else {
            verified_matches = verified_matches.filter(s=>s.id!==customer.id)
        }
        this.setState({ verified_matches: verified_matches });
    }

    go_back_step_1(step) {
        if (step > 1) {
            this.reset_new_customer()
        }    
    }

    go_back_step_2(step) {
        if (step > 2) {
            this.setState({
                add_button_clicked: false,
                searching: false,
                agreement_signed: false,
                account_clicked: false,
                show_matches_modal: false,
                possible_matches: [],
                verified_matches: [],
                list_to_verify: [],
                no_verify_list:[],
                selected_account_data: null,
                show_selected_account: true,
                step_number: 2
            })
        }
    }

    render() {
        const {
            add_button_clicked,
            account_clicked,
            selected_account_data,
            show_selected_account,
            possible_matches,
            verified_matches,
            show_matches_modal,
            new_customer,
            agreement_signed,
            searching,
            step_number,
            list_to_verify,
            no_verify_list
        } = this.state;

        const width = this.props.width ? parseInt(this.props.width) : 0;
        const num_prospects = this.props.prospects ? this.props.prospects.list ? this.props.prospects.list.length : 0 : 0;

        const step_1_instructions = new_customer ?
            <Alert variant="secondary" className={`mb-3 d-flex flex-column ${step_number > 1 ? ' opacity-75 hover-over' : ''}`} onClick={() => this.go_back_step_1(step_number)}>
                <Alert.Heading className=" w-100 text-center portal-tile-title pb-0 mb-1">
                    1. Search For Company By Name
                </Alert.Heading>
                {step_number > 1 ?
                    null :
                    <div className={`${width < 1200 ? 'w-100' : 'w-50'} align-self-center mb-3 mt-2`}>
                        <p className="text-secondary w-100 text-center text-med">
                            First find the prospect's information in google using the search bar below. If you cannot find your prospect using the search bar,
                            <bdi className="underlined hover-over px-1 text-primary" onClick={() => this.set_template()}>click here</bdi>
                            to generate a template to build your new prospect with.
                        </p>

                        <InputGroup className="overview-tab-input-group ">
                            <InputGroup.Text className="text-nowrap text-sm p-1 text-bold pe-3">Search:</InputGroup.Text>
                            <GoogleAutocompleteInput
                                className="text-sm bg-white text-start flex-grow-1"
                                width="flex-grow-1"
                                update_account={(name, value) => this.update_account_from_google({ target: { name: name, value: value } })}
                                address1={new_customer.address1}
                                address2={new_customer.address2}
                                city={new_customer.city}
                                state_id={new_customer.state_id}
                                zip_code={new_customer.zip_code}
                                current_property='name'
                                default_value={new_customer.name}
                                disabled={step_number > 1}

                            />
                        </InputGroup>

                    </div>
                }
            </Alert> : null;

        const step_2_instructions = new_customer && step_number>1 ?
            <Alert variant="secondary" className={`mb-3 d-flex flex-column ${step_number > 2 ? ' opacity-75 hover-over' : ''}`} onClick={() => this.go_back_step_2(step_number)}>
                <Alert.Heading className=" w-100 text-center portal-tile-title pb-0 mb-1">
                    2. Update Incorrect/Missing Fields
                </Alert.Heading>
                {step_number > 2 ?
                    null :
                    <div className={`${width < 1200 ? 'w-100' : 'w-50'} align-self-center mb-3 mt-2 d-flex flex-column`}>
                        <p className="text-secondary w-100 text-center text-med">
                           
                            Update any fields for your new prospect that are incorrect or missing, then click the
                            <img className='' alt='check-accounts-button' title='Check existing accounts' src={check_button_img} style={{transform:'scale(0.75)'}} />
                            button to check if this prospect already exists. 
                        </p>

                        <NewProspectForm
                            new_customer={new_customer}
                            update_account={(prop) => this.update_account(prop)}
                            commodities={this.props.commodities}
                            salesperson_list={this.props.salesperson_list}
                            role={this.props.role}
                        />
                        
                    </div>}
            </Alert> : null;

        const step_3_instructions = new_customer && step_number > 2 ? <Alert variant="secondary" className={`mb-3 d-flex flex-column ${step_number > 3 ? ' opacity-75 hover-over' : ''}`}>
            <Alert.Heading className=" w-100 text-center portal-tile-title pb-0 mb-1">
                3. Verify Possible Matches List
            </Alert.Heading>
            {step_number > 3 ?
                null :
                <div className={`w-100 align-self-center mb-3 mt-2 d-flex flex-column`}>
                    <p className={`align-self-center text-secondary ${width < 1200 ? 'w-100' : 'w-50'} text-center text-med align-self-center`}>
                        View each possible match in the list below (if any). Verify the prospect you are creating does not already exist by clicking the
                        <img className='' alt='unverified-checkbox' title='Verify checkbox' src={verified_checkbox} style={{ transform: 'scale(0.75)' }} />
                        checkbox under the 'Verify' column. Once all possible matches are verified, you will be able to create the new prospect.
                    </p>
                    <PossibleMatchesResults
                        verified_matches={verified_matches}
                        new_customer={new_customer}
                        salesperson={this.props.salesperson}
                        role={this.props.role}
                        possible_matches={possible_matches}
                        list_to_verify={list_to_verify}
                        no_verify_list={no_verify_list}
                        add_to_verified_matches={(e, d) => this.add_to_verified_matches(e, d)}
                        view_account={(company_id, customer_id) => this.view_account(company_id, customer_id)}
                    />
                </div>}
        </Alert> :
            null;

        const step_4_instructions =step_number > 3?
            <Alert variant="success" className={`mb-3 d-flex flex-column ${step_number > 3 ? ' opacity-75' : ''}`}>
                <Alert.Heading className=" w-100 text-center portal-tile-title pb-0 mb-1">
                    Prospect Created {svg_icons.checkmark_icon}
                </Alert.Heading>                
            </Alert>:null



        return (
            <div className="d-flex flex-column w-100 h-100 p-5">

                <Card className="d-flex flex-column justify-content-between" style={{minHeight:'60vh'}} >
                    <Card.Header className="w-100 text-center lg-page-title p-2">
                        Add New Prospect
                    </Card.Header>
                    {num_prospects < 50 ?
                        <Card.Body className="d-flex flex-column justify-content-start w-100 h-100" style={{ height: '80vh', overflow: 'auto' }}>

                            {step_1_instructions}
                            {step_2_instructions}
                            {step_3_instructions}
                            {step_4_instructions}

                        </Card.Body> :
                        <Card.Body className="d-flex flex-column justify-content-start w-100 h-100" style={{ height: '80vh', overflow: 'auto' }}>

                            <Alert variant="danger">
                                <Alert.Heading className=" w-100 text-center portal-tile-title pb-0 mb-1">
                                   You have the max number of prospects (50). Please release a prospect before creating a new one.
                                </Alert.Heading>
                            </Alert>

                        </Card.Body>
                    }
                  
                    <Footer
                        step_number={step_number}
                        add_button_clicked={add_button_clicked}
                        add_prospect={() => this.add_prospect()}
                        on_next={() => this.setState({ show_matches_modal: true })}
                        verified_matches={verified_matches}
                        possible_matches={list_to_verify}
                        agreement_signed={agreement_signed}
                        new_customer={new_customer}
                        searching={searching }
                        get_possible_matches={() => this.get_possible_matches()}
                    />

                    
                </Card>
                {selected_account_data ?
                    <ViewAccountModal
                        show={show_selected_account}
                        onHide={() => this.setState({
                            show_selected_account: false,
                            selected_account_data: null
                        })}
                        current_user={this.props.salesperson}
                        msal_account={this.props.msal_account}
                        role={this.props.role}
                        accounts_list={possible_matches}
                        prospects={this.props.prospects}
                        commodities={this.props.commodities}
                        categories={this.props.categories}
                        salesperson_list={this.props.salesperson_list}
                        selected_account_data={selected_account_data}
                        add_account={(prop) => this.props.add_account(prop)}
                        release_account={(prop) => this.props.release_account(prop)}
                        update_accounts_list={(prop) => this.setState({ possible_matches: prop })}
                        view_account={(account) => this.view_account(account.company_id, account.id)}
                        update_selected_account_data={(prop) => this.setState({ selected_account_data: prop })}
                        update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                        add_users_request={(type, prop) => this.props.add_users_request(type, prop)}
                        add_users_action={(prop) => this.props.add_users_action(prop)}
                        update_users_action={(prop) => this.props.add_users_action(prop)}
                    /> : null}

                {this.props.msal_account ?
                    <PossibleMatchesModal
                        show={show_matches_modal}
                        onHide={() => this.setState({ show_matches_modal: '' })}
                        msal_account={this.props.msal_account}
                        onSave={() => this.setState({ show_matches_modal: false, agreement_signed: true })}
                        possible_matches={possible_matches}
                        new_customer={ new_customer}
                    /> : null}
                <LoadingModal show={account_clicked} title="Account" />
                <LoadingModal show={searching} title="Possible matches" />
            </div>
         
        );
    }

    async view_account(company_id, customer_id) {
        this.setState({ account_clicked: true});
        const account_data = await customerFunctions.View_Account(company_id, customer_id);
        this.setState({
            selected_account_data: account_data,
            show_selected_account: true,
            account_clicked: false
        })
    }
}
