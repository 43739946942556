import { MsalContext } from '@azure/msal-react';
import React, { Component } from 'react';
import { format} from 'date-fns'
import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    FormCheck,
    InputGroup,
    Row,
    ButtonGroup,
    Offcanvas,
} from 'react-bootstrap';
import { OrdersSearch } from './CommissionViews/OrdersSearch';
import { OrdersView } from './CommissionViews/OrdersView';
import { ReportsSearch } from './CommissionViews/ReportsSearch';
import { ReportsView } from './CommissionViews/ReportsView';
import * as commissionFunctions from '../components/Functions/CommissionFunctions';
import * as svg_icons from '../Media/svg_exports';

export class Commissions extends Component {
    static displayName = Commissions.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: '',
            sort_field: '',
            edit_field: '',
            view: 'Order',
            commission_dim_orders: { list: [], loaded: false, failed: false },
            order_commissions: { list: [], loaded: false, failed: false },
            unpaid_commissions: [],
            unpaid_commissions_loaded:false,
            salesperson_list: { list: [], loaded: false, failed: false },
            show_filter: false,
            selected_commission: null,
            selected_unpaid_commission:null,
            update_clicked: false,
            changes_made: false,
            changes_saved: false,
            lam_unpaid_commissions: [],
            lam_unpaid_commissions_loaded: false,

            customer_ord_list: [],
            salesperson_ord_list: [],
            customer_salesperson_list_loaded: false,
            search_company_id:'TMS'
        };
    }
    componentDidMount() {
        this.get_order_commissions(1);
        this.get_customer_salesperson_lists()
      //  this.get_unpaid_order_commissions();
      //  this.get_unpaid_order_lams();
    }

    async get_order_commissions(offset) {
        try {
            let company_id = this.state.search_company_id
            const data = await commissionFunctions.Get(company_id, offset)
     
            this.setState({ order_commissions: { list: data, loaded: true, failed: false } });
        } catch (e) {
            console.log(e);
            this.setState({ order_commissions: { list: [], loaded: true, failed: true } });
        }
    }



    async get_customer_salesperson_lists() {
        this.setState({
            customer_ord_list: [],
            salesperson_ord_list: [],
            customer_salesperson_list_loaded: false
        })
        const customers = await commissionFunctions.Get_Customers();
        const salespersons = await commissionFunctions.Get_Salespersons();
        this.setState({
            customer_ord_list: customers,
            salesperson_ord_list: salespersons,
            customer_salesperson_list_loaded: true
        })
    }


    update_order_commissions_varchar(val, property, d) {
        let commissions_list = this.state.order_commissions.list;
        let selected_commissions = commissions_list.filter(s => s.order_id === d.order_id);


        for (let i = 0; i < selected_commissions.length; i++) {
           
            selected_commissions[i][property] = val;
            const index = commissions_list.findIndex(s => s.pk === selected_commissions[i].pk);
            commissions_list[index] = selected_commissions[i];
            this.setState({ order_commissions: { list: commissions_list, loaded: true, failed: false } })
        }
 
    }

    get_margin(d) {

        let margin = parseFloat(d.margin);
        let carrier_expense = parseFloat(d.carrier_expense);
        if (parseFloat(d.carrier_expense_override) > 0) {
            carrier_expense = parseFloat(d.carrier_expense_override);
        }
        else if (parseFloat(d.sold_to_assets_override) > 0) {
            carrier_expense = parseFloat(d.sold_to_assets_override);
        } else {
            carrier_expense = parseFloat(d.carrier_expense);
        }
        let total_charge = d.total_charge_override > 0 ? parseFloat(d.total_charge_override) : parseFloat(d.total_charge);
    
            margin = (Math.round((total_charge - carrier_expense) * 1000)) / 1000;
  
        


        return parseFloat(margin);
    }


    get_commission(d) {
        let margin = this.get_margin(d);
        let percent = parseFloat(d.commission_percent);
        return (Math.round((margin * percent) * 1000)) / 1000;
    }


    update_order_commissions(val, property, d) {
        let commissions_list = this.state.order_commissions.list;
        let selected_commissions = commissions_list.filter(s => s.order_id === d.order_id);
      
        for (let i = 0; i < selected_commissions.length; i++) {
            selected_commissions[i].change_user = this.props.msal_account.username.replaceAll('@scotlynn.com', '')
            if (val.floatValue) {              
                selected_commissions[i][property] = val.floatValue;
               
            } else {
                selected_commissions[i][property] = 0;
       
            }
           
            const index = commissions_list.findIndex(s => s.pk === selected_commissions[i].pk);
            selected_commissions[i].margin = this.get_margin(selected_commissions[i]);
            selected_commissions[i].commission_amount = this.get_commission(selected_commissions[i]);
            commissions_list[index] = selected_commissions[i];
            this.setState({ order_commissions: { list: commissions_list, loaded: true, failed: false } })
        }

    }

    update_unpaid_commissions_varchar(val, property, pk) {
        let commissions_list = this.state.unpaid_commissions;
        let selected_commission = this.state.selected_unpaid_commission
        if (selected_commission) {
            selected_commission[property] = val;
            selected_commission.change_user = this.props.msal_account.username.replaceAll('@scotlynn.com', '')
            const index = commissions_list.findIndex(s => s.pk === pk);
            commissions_list[index] = selected_commission;
            this.setState({ unpaid_commissions: commissions_list })

        }
    }


    update_unpaid_commissions(val, property, pk) {
        let commissions_list = this.state.unpaid_commissions;
        let selected_commission = this.state.selected_unpaid_commission;
        const index = commissions_list.findIndex(s => s.pk === pk);
        selected_commission.change_user = this.props.msal_account.username.replaceAll('@scotlynn.com', '')
        if (val.floatValue && this.state.selected_unpaid_commission) {
            selected_commission[property] = val.floatValue;

        } else {
            selected_commission[property] = 0;
        }
        commissions_list[index] = selected_commission;
        this.setState({
            unpaid_commissions: commissions_list,
            changes_made: true,
            changes_saved: false
        })
    }

    async put_order_commissions() {
        this.setState({ update_clicked: true })
        if(window.confirm('Are you sure you want to update this list?')){
            const commissions_list = this.state.order_commissions.list;
            const data = await commissionFunctions.Update(commissions_list);
            this.setState({ update_clicked: false })
        }else {
            this.setState({ update_clicked: false })
        }

      
    }



    render() {
        const {
            view,
            commission_dim_orders,
            order_commissions,
            show_filter,
            salesperson_list,
            selected_commission,
            update_clicked,
            unpaid_commissions,
            unpaid_commissions_loaded,
            selected_unpaid_commission,
            lam_unpaid_commissions,
            lam_unpaid_commissions_loaded,

            customer_ord_list,
            salesperson_ord_list,
            customer_salesperson_list_loaded,
            search_company_id
        } = this.state;



        //#region Order Tabs
        const order_tabs = (
            <ButtonGroup className="mb-2 opacity-75 shadow-0">
                <Button
                    onClick={() => this.setState({ view: 'Order' })}
                    className="text-sm border border-dark"
                    size="sm"
                    variant={view === 'Order' ? 'secondary' : 'outline-secondary'}
                >
                    Orders
                </Button>
                <Button
                    onClick={() => this.setState({ view: 'Processing' })}
                    className="text-sm border border-dark"
                    size="sm"
                    variant={view === 'Processing' ? 'secondary' : 'outline-secondary'}
                >
                    Processing
                </Button>
            </ButtonGroup>
        );
        //#endregion Order Tabs
     

        return (
            <div className="d-flex flex-column w-100 justify-content-start h-100 p-3">
         
                <Offcanvas show={show_filter} onHide={() => this.setState({ show_filter: false })}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Filters</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {view === 'Order' ? <OrdersSearch /> : null}
                        {view === 'Report' ? <ReportsSearch /> : null}
                    </Offcanvas.Body>
                </Offcanvas>
                {
                    this.props.role==='admin' || this.props.role==='commission'?
                    order_tabs:null}
                {order_commissions.loaded ? (
                    <Card className="p-3 d-flex flex-row w-100">
                        <div>
                            <div className="d-flex flex-row">
                                <label
                                    onClick={() => this.setState({ show_filter: true })}
                                    className="hover-over me-1"
                                    style={{ transform: 'scale(0.8)' }}
                                >
                                    { svg_icons.funnel_icon}
                                </label>
                                <h6>{view === 'Order' ? 'Order Commissions' : 'Commission Processing'}</h6>
                            </div>

                            {view === 'Order' ? (
                                <OrdersView
                                    orders={order_commissions}
                                    salesperson_list={this.props.salesperson_list}
                                    update_clicked={update_clicked}
                                    set_selected_commission={(prop) => this.setState({ selected_commission: prop.commission_paid === 'Y' ? null : prop })}
                                    update_orders={(val, property, pk) => this.update_order_commissions(val, property, pk)}
                                    update_orders_varchar={(val, property, pk) => this.update_order_commissions_varchar(val, property, pk)}
                                    update_orders_list={(prop) => this.setState({ order_commissions: { list: prop, loaded: true, failed: false } })}
                                    selected_commission={selected_commission}
                                    put_order_commissions={() => this.put_order_commissions()}
                                    width={this.props.width}
                                    customer_ord_list={customer_ord_list}
                                    salesperson_ord_list={salesperson_ord_list}
                                    search_company_id={search_company_id}
                                    update_search_company_id={(prop) => this.setState({ search_company_id: prop })}
                                    customer_salesperson_list_loaded={customer_salesperson_list_loaded}
                                />
                            ) : null}
                            {view === 'Processing' ? 
                                <ReportsView orders={commission_dim_orders}
                                    msal_account={this.props.msal_account}
                                    orders={unpaid_commissions}
                                    salesperson_list={salesperson_list}
                                    update_clicked={update_clicked}
                                    set_selected_commission={(prop) => this.setState({ selected_unpaid_commission: prop })}
                                    update_orders={(val, property, pk) => this.update_unpaid_commissions(val, property, pk)}
                                    update_orders_varchar={(val, property, pk) => this.update_unpaid_commissions_varchar(val, property, pk)}
                                    update_orders_list={(prop) => this.setState({ unpaid_commissions: prop })}
                                    selected_commission={selected_unpaid_commission}
                                    put_order_commissions={() => this.put_unpaid_commissions()}
                                    lam_unpaid_commissions={lam_unpaid_commissions}
                                    update_commissions={() => this.get_order_commissions(1)}
                                    width={this.props.width}
                                />
                                : null}
                        </div>
                    </Card>
                ) : (
                    <Alert className="w-100 p-3 mt-4" variant="secondary">
                        Loading...
                    </Alert>
                )}
            </div>
        );
    }
}
