import React, { Component } from 'react';
import { Modal, Button, Alert, Form, InputGroup, ButtonGroup } from 'react-bootstrap';
import SortableTable from '../TableComponents/SortableTable';
import {
    addBusinessDays,
    addDays,
    differenceInDays,
    format,
    isAfter,
    isBefore,
    isDate,
    isFriday,
    isMonday,
    nextFriday,
    previousMonday,
} from 'date-fns';
import sortByStrategy from '../../Domain/sorting-strategy';

export class LAMProspects extends Component {
    constructor(props) {
        super(props);
        const start = isMonday(new Date())
            ? format(new Date(), 'yyyy-MM-dd')
            : format(previousMonday(new Date()), 'yyyy-MM-dd');
        const end = isFriday(new Date())
            ? format(new Date(), 'yyyy-MM-dd')
            : format(nextFriday(new Date()), 'yyyy-MM-dd');
        this.state = {
            lam_name: '',
            button_clicked: false,
            sort_direction: 'H',
            sort_field: '',
            start_date: start,
            end_date: end,
            activity_id: '',
            remark: '',
            view: 'Activity',
            type_selected: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onHide() {
        const start = isMonday(new Date())
            ? format(new Date(), 'yyyy-MM-dd')
            : format(previousMonday(new Date()), 'yyyy-MM-dd');
        const end = isFriday(new Date())
            ? format(new Date(), 'yyyy-MM-dd')
            : format(nextFriday(new Date()), 'yyyy-MM-dd');
        this.setState({
            lam_name: '',
            button_clicked: false,
            sort_direction: 'H',
            sort_field: '',
            start_date: start,
            end_date: end,
            activity_id: '',
            remark: '',
            type_selected: '',
        });
        this.props.onHide();
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ button_clicked: true });
        var currentName = this.state.lam_name;
        this.props.onSave(currentName);
        this.setState({ button_clicked: false, lam_name: '' });
    }

    render() {
        const {
            lam_name,
            button_clicked,
            sort_direction,
            sort_field,
            start_date,
            end_date,
            activity_id,
            remark,
            view,
            type_selected,
        } = this.state;

        //#region Activity Table
        let current_activities =
            this.props.lam_activities && this.props.lam_activities_loaded ? this.props.lam_activities : [];
        const activity_ids = [];
        if (isDate(new Date(start_date)) && isDate(new Date(end_date))) {
            current_activities = current_activities.filter(
                (s) =>
                    isBefore(new Date(s.action_date), addDays(new Date(end_date), 1)) &&
                    isAfter(new Date(s.action_date), new Date(start_date)),
            );
        }
        if (activity_id) {
            current_activities = current_activities.filter((s) => s.activity_id === activity_id);
        }
        if (remark) {
            current_activities = current_activities.filter((s) => s.remarks.toLowerCase().includes(remark));
        }
        if (type_selected) {
            current_activities = current_activities.filter((s) => s.type_of === type_selected);
        }

        //#region Sort
        const activitySortingStrategies = {
            Completed: (a, b) => differenceInDays(new Date(a.action_date), new Date(b.action_date)),
            Activity: (a, b) => a.activity_id.localeCompare(b.activity_id),
            Account: (a, b) => a.customer_name.localeCompare(b.customer_name),
            Type: (a, b) => a.type_of.localeCompare(b.type_of),
            Remark: (a, b) => a.remarks.length - b.remarks.length,
        };
        current_activities = current_activities.sort(
            sortByStrategy(activitySortingStrategies, sort_field, sort_direction),
        );
        //#endregion Sort

        const activity_headers = [
            { column_title: 'Completed', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Activity', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Account', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Type', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Remark', sort_direction: sort_direction, is_sortable: 'N' },
        ];
        const activity_data = [];
        if (current_activities) {
            for (let i = 0; i < current_activities.length; i++) {
                const action_date = current_activities[i].action_date_str
                    ? current_activities[i].action_date_str
                    : current_activities[i].action_date;
                const remark_cell = (
                    <p style={{ whiteSpace: 'pre-wrap' }} className="p-0 m-0">
                        {current_activities[i].remarks.replaceAll('&#xA;', '\n\n')}
                    </p>
                );

                const completed_date = (
                    <label style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        {action_date ? action_date : '-'}
                    </label>
                );
                activity_data.push({
                    row_style: '',
                    cells: [
                        { cell: completed_date, cell_style: 'py-0' },
                        { cell: current_activities[i].activity_id, cell_style: 'py-0' },
                        { cell: current_activities[i].customer_name, cell_style: 'py-0' },
                        { cell: current_activities[i].type_of, cell_style: 'py-0' },
                        { cell: remark_cell, cell_style: 'py-0' },
                    ],
                    data: current_activities[i],
                });
            }
            for (let i = 0; i < this.props.lam_activities.length; i++) {
                if (activity_ids.filter((s) => s === this.props.lam_activities[i].activity_id).length === 0) {
                    activity_ids.push(this.props.lam_activities[i].activity_id);
                }
            }
        }

        const activity_table = this.props.lam_activities_loaded ? (
            <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                row_click={() => null}
                table_headers={activity_headers}
                table_data={activity_data}
                table_title=""
                sort_field={sort_field}
            />
        ) : (
            <Alert variant="secondary">Loading...</Alert>
        );
        //#endregion Activity Table

        //#region Prospect Table
        const prospect_headers = [
            { column_title: 'Name', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'ID', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Market Plan ID', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Category', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Expiry', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Last Contacted', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Last Remark', sort_direction: sort_direction, is_sortable: 'N' },
        ];
        const prospect_data = [];

        let current_prospects = this.props.current_accounts
            ? this.props.current_accounts.filter(
                  (s) => s.type_of !== 'Customer',
              )
            : [];
        //#region Sort
        const prospectSortingStrategies = {
            Name: (a, b) => a.name.localeCompare(b.name),
            ID: (a, b) => a.id.localeCompare(b.id),
            'Market Plan ID': (a, b) => a.mark_plan_id.localeCompare(b.mark_plan_id),
            Category: (a, b) => a.category.localeCompare(b.category),
            Expiry: (a, b) => differenceInDays(new Date(a.expiry_date), new Date(b.expiry_date)),
            'Last Contacted': (a, b) => differenceInDays(new Date(a.action_date), new Date(b.action_date)),
            'Last Remark': (a, b) => a.last_activity_remarks.length - b.last_activity_remarks.length,
        };
        current_prospects = current_prospects.sort(
            sortByStrategy(prospectSortingStrategies, sort_field, sort_direction),
        );
        //#endregion Sort

        for (let i = 0; i < current_prospects.length; i++) {
            const name_cell = <label className="pt-1">{current_prospects[i].name}</label>;
            const id_cell = <label className="pt-1">{current_prospects[i].id}</label>;
            const start_cell = <label className="pt-1">{current_prospects[i].start_date}</label>;
            const mark_plan_cell = <label className="pt-1">{current_prospects[i].mark_plan_id}</label>;
            const category_cell = (
                <label className="pt-1">{current_prospects[i].category ? current_prospects[i].category : '-'}</label>
            );
            const expiry_cell = (
                <label className="pt-1">
                    {current_prospects[i].expiry_date}
                    <bdi
                        className={
                            differenceInDays(new Date(current_prospects[i].expiry_date), new Date()) < 15
                                ? 'text-danger text-bold'
                                : ''
                        }
                    >
                        {' (' +
                            differenceInDays(new Date(current_prospects[i].expiry_date), new Date()) +
                            ' days left)'}
                    </bdi>
                </label>
            );
            const last_contacted_cell = <label className="pt-1">{current_prospects[i].action_date_str}</label>;
            const remark_cell = (
                <p style={{ whiteSpace: 'pre-wrap' }} className="p-0 m-0">
                    {current_prospects[i].last_activity_remarks ? current_prospects[i].last_activity_remarks.replaceAll('&#xA;', '\n\n') : '-'}
                </p>
            );

            prospect_data.push({
                row_style: '',
                cells: [
                    { cell: name_cell, cell_style: 'py-0' },
                    { cell: id_cell, cell_style: 'py-0' },
                    { cell: mark_plan_cell, cell_style: 'py-0' },
                    { cell: category_cell, cell_style: 'py-0' },
                    { cell: expiry_cell, cell_style: 'pe-3 py-0' },
                    { cell: last_contacted_cell, cell_style: 'py-0' },
                    { cell: remark_cell, cell_style: 'w-25 py-0' },
                ],
                data: current_prospects[i],
            });
        }

        const prospect_table = (
            <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                row_click={(prop) => this.props.set_sp_company(prop)}
                table_headers={prospect_headers}
                table_data={prospect_data}
                table_title={'Prospects (' + prospect_data.length + ')'}
                sort_field={sort_field}
            />
        );
        //#endregion Prospect Table

        //#region Customer Table
        const headers = [
            { column_title: 'Name', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'ID', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Start Date', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Market Plan ID', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Category', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Last Ship', sort_direction: sort_direction, is_sortable: 'N' },
            { column_title: 'Last Remark', sort_direction: sort_direction, is_sortable: 'N' },
        ];
        let current_customers = this.props.current_accounts
            ? this.props.current_accounts.filter((s) => s.type_of =='Customer')
              
            : [];

        //#region Sort
        const customerSortingStrategies = {
            Name: (a, b) => a.name.localeCompare(b.name),
            ID: (a, b) => a.id.localeCompare(b.id),
            'Start Date': (a, b) => differenceInDays(new Date(a.start_date), new Date(b.start_date)),
            'Market Plan ID': (a, b) => a.mark_plan_id.localeCompare(b.mark_plan_id),
            Category: (a, b) => a.category.localeCompare(b.category),
            Expiry: (a, b) => differenceInDays(new Date(a.expiry_date), new Date(b.expiry_date)),
            'Last Ship': (a, b) => a.last_ship_days - b.last_ship_days,
            'Last Remark': (a, b) => a.last_activity_remarks.length - b.last_activity_remarks.length,
        };
        current_customers = current_customers.sort(
            sortByStrategy(customerSortingStrategies, sort_field, sort_direction),
        );
        //#endregion Sort

        const customer_data = [];

        for (let i = 0; i < current_customers.length; i++) {
            const name_cell = <label className="pt-1">{current_customers[i].name}</label>;
            const id_cell = <label className="pt-1">{current_customers[i].id}</label>;
            const start_cell = <label className="pt-1">{current_customers[i].start_date}</label>;
            const mark_plan_cell = <label className="pt-1">{current_customers[i].mark_plan_id}</label>;
            const category_cell = (
                <label className="pt-1">{current_customers[i].category ? current_customers[i].category : '-'}</label>
            );
            const last_ship_cell = current_customers[i].last_ship_date ? (
                <label className="pt-1">
                    {current_customers[i].last_ship_date}
                    <bdi className={current_customers[i].last_ship_days > 305 ? 'text-danger text-bold' : ''}>
                        {' (' + current_customers[i].last_ship_days + ' days ago)'}
                    </bdi>
                </label>
            ) : (
                <label className="pt-1">-</label>
            );
            const remark_cell = (
                <p style={{ whiteSpace: 'pre-wrap' }} className="pt-1">
                    {current_customers[i].last_activity_remarks ? current_customers[i].last_activity_remarks.replaceAll('&#xA;', '\n\n') : ''}
                </p>
            );

            customer_data.push({
                row_style: '',
                cells: [
                    { cell: name_cell, cell_style: 'py-0' },
                    { cell: id_cell, cell_style: 'py-0' },
                    { cell: start_cell, cell_style: 'py-0' },
                    { cell: mark_plan_cell, cell_style: 'py-0' },
                    { cell: category_cell, cell_style: 'py-0' },
                    { cell: last_ship_cell, cell_style: 'py-0' },
                    { cell: remark_cell, cell_style: 'w-25 py-0' },
                ],
                data: current_customers[i],
            });
        }

        const customer_table = (
            <SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                }
                row_click={(prop) => this.props.set_sp_company(prop)}
                table_headers={headers}
                table_data={customer_data}
                table_title={'Customers (' + customer_data.length + ')'}
                sort_field={sort_field}
            />
        );

        //#endregion Customer Table

        const loading_alert = (
            <Alert variant="secondary">
                <h5 className="text-center">Loading...</h5>
            </Alert>
        );

        const failed_alert = (
            <Alert variant="danger">
                <h5 className="text-center">Failed to load accounts for this LAM</h5>
            </Alert>
        );

        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName="light-modal"
                    backdrop="static"
                >
                    <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button
                            onClick={() => this.onHide()}
                            variant="outline-secondary"
                            className="btn-op70 p-0 d-flex flex-row justify-content-center border-0"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                class="bi bi-x-square"
                                viewBox="0 0 16 16"
                            >
                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </Button>
                        <Modal.Title
                            id="contained-modal-title-vcenter"
                            className="align-items-center"
                            style={{ fontWeight: '600', textAlign: 'center', width: '100%', filter: 'opacity(0.7)' }}
                        >
                            <h4 style={{ alignSelf: 'end', paddingTop: '0.5rem' }}>
                                {this.props.current_accounts ? this.props.current_accounts.length : 0} Accounts for{' '}
                                {this.props.salesperson ? this.props.salesperson.name : ''}
                            </h4>
                        </Modal.Title>
                        <div style={{ width: '1.25rem' }}></div>
                    </Modal.Header>
                    <Modal.Body className="show-grid pb-5 px-5">
                        <ButtonGroup className="mb-3 d-flex flex-rpw justify-content-between shadow-0">
                            <Button
                                className="text-sm"
                                size="sm"
                                variant={view === 'Activity' ? 'secondary' : 'outline-secondary'}                               
                                onClick={() => this.setState({ view: 'Activity' })}
                            >
                                Activity History
                            </Button>
                            <Button
                                onClick={() => this.setState({ view: 'Prospect' })}
                                className="text-sm"
                                size="sm"
                                variant={view === 'Prospect' ? 'secondary' : 'outline-secondary'}
                            >
                                {current_customers ? `Prospects (${current_prospects.length})` : 'Prospects'}
                            </Button>
                            <Button
                                onClick={() => this.setState({ view: 'Customer' })}
                                className="text-sm"
                                size="sm"
                                variant={view === 'Customer' ? 'secondary' : 'outline-secondary'}
                            >
                                {current_customers ? `Customers (${current_customers.length})` : 'Customers'}
                            </Button>
                        </ButtonGroup>
                        {view === 'Activity' ? (
                            <div className="mb-4 border-bottom mt-4">
                                <div className="d-flex flex-column">
                                    <h6>Activity History ({activity_data.length})</h6>
                                    <div className="d-flex flex-row">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="p-0 m-0 text-lg">Start Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                className="text-lg"
                                                value={start_date}
                                                onChange={(e) => this.setState({ start_date: e.target.value })}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="p-0 m-0 text-lg">End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                className="text-lg"
                                                value={end_date}
                                                onChange={(e) => this.setState({ end_date: e.target.value })}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3 ms-4">
                                            <Form.Label className="p-0 m-0 text-lg">Activity</Form.Label>
                                            <Form.Select
                                                className="text-lg"
                                                value={activity_id}
                                                onChange={(e) => this.setState({ activity_id: e.target.value })}
                                            >
                                                <option value="">Select</option>
                                                {activity_ids.map((d, index) => (
                                                    <option key={index} value={d}>
                                                        {d}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3 ms-4">
                                            <Form.Label className="p-0 m-0 text-lg">Type</Form.Label>
                                            <Form.Select
                                                className="text-lg"
                                                id="customer_type_slicer"
                                                value={type_selected}
                                                onChange={(e) => this.setState({ type_selected: e.target.value })}
                                            >
                                                <option value="">Select</option>
                                                <option value="Customer">Customer</option>
                                                <option value="Dormant">Dormant</option>
                                                <option value="Prospect">Prospect</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="ms-4 mb-3">
                                            <Form.Label className="p-0 m-0 text-lg">Search by Remark</Form.Label>
                                            <Form.Control
                                                className="text-lg"
                                                value={remark}
                                                onChange={(e) =>
                                                    this.setState({ remark: e.target.value.toLowerCase() })
                                                }
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div style={{ maxHeight: '70vh', overflow: 'auto', resize: 'vertical' }}>
                                    {activity_data.length > 0 ? activity_table : null}
                                    {this.props.lam_activities_loaded ? null : (
                                        <Alert variant="secondary">Loading...</Alert>
                                    )}
                                </div>
                            </div>
                        ) : null}
                        {view === 'Customer' ? (
                            <div>
                                <div style={{ maxHeight: '70vh', overflow: 'auto' }} className="mb-3 border-bottom">
                                    {this.props.current_accounts_loaded ? customer_table : loading_alert}
                                </div>
                            </div>
                        ) : null}
                        {view === 'Prospect' ? (
                            <div>
                                {this.props.current_accounts_failed ? failed_alert : null}
                                <div style={{ maxHeight: '70vh', overflow: 'auto' }} className="mb-2 border-bottom">
                                    {this.props.current_accounts_loaded ? prospect_table : null}
                                </div>
                            </div>
                        ) : null}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
