import { MsalContext } from '@azure/msal-react';
import React, { Component } from 'react';
import { addDays, format, getDate, getYear, getMonth, differenceInDays, isBefore} from 'date-fns';
import { Button, Card, Alert, Tab, Tabs, Form, Stack, InputGroup, ButtonGroup } from 'react-bootstrap';
import * as customerFunctions from '../Functions/CustomerFunctions';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import * as possibleMatchFunctions from '../Functions/PossibleMatchFunctions';
import { CreditRequests } from '../CreditComponents/CreditRequests';
import { AuditLogResults } from '../CreditComponents/AuditLogResults';
import SortableTable from '../TableComponents/SortableTable';
import { LoadingModal } from '../Modals/LoadingModal';
import { DisplayedMatchesModal } from '../CreditComponents/DisplayedMatchesModal';
import { ViewAccountModal } from '../Modals/Account/ViewAccountModal';
import { DynamicPortalTile } from '../PageComponents/DynamicPortalTile';


export class CreditPortal extends Component {
    static displayName = CreditPortal.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            user_loaded: false,
            requests_loaded: false,


            start_date: '',
            end_date: '',
            company: '',

            audit_log: [],
            audit_clicked: false,
            selected_audit: null,
   
            show_audit_div: true,
            display_audit: false,
            new_accounts: [],
            new_accounts_loaded: false,
            new_accounts_today: [],
            new_accounts_loaded_today: false,

            selected_account_data: null,
            account_clicked: false,
            show_selected_account: true,
            request_status_type: 'P',
            request_search: '',

            selected_accounts_list:[]
      
        };

        this.set_today = this.set_today.bind(this);
    }

    async componentDidMount() {
        this.get_new_accounts_today();
    }

    componentDidUpdate() {
        if (!this.state.requests_loaded) {
            if (this.props.incoming_requests) {
                if (this.props.incoming_requests.loaded === 'Y') {
                    const pending_requests = this.props.incoming_requests.list.filter((s) => s.status === 'P');
                    this.set_param_index(pending_requests);
                    this.setState({ requests_loaded: true });
                }
            }
        }
    }

    async get_new_accounts_today() {
        const formatted_date = this.format_date(new Date());
        const slc_data = await customerFunctions.Get_By_Entered_Date({
            company_id: 'TMS',
            start_date: formatted_date,
            end_date: formatted_date,
        });
        const slusa_data = await customerFunctions.Get_By_Entered_Date({
            company_id: 'TMS2',
            start_date: formatted_date,
            end_date: formatted_date,
        });

        this.setState({
            new_accounts_today: slc_data.concat(slusa_data),
            new_accounts_today_loaded: true,
        });
    }

    set_param_index(pending_requests) {
        let current_activity_id = '';
        if (window.location.pathname.split('/').length === 3) {
            current_activity_id = window.location.pathname.split('/')[2];
        }
        if (window.location.pathname.split('/').length === 4) {
            current_activity_id = window.location.pathname.split('/')[2];
        }
        if (current_activity_id && pending_requests) {
            const current_activity = pending_requests.find((s) => s.id === current_activity_id);
            if (current_activity) {
                const index = pending_requests.indexOf(current_activity);
                this.setState({ request_index: index > -1 ? index : 0, requests_loaded: true });
            }
       
        }
    }

    async get_audit_log() {
        this.setState({ audit_clicked: true, new_accounts_loaded: false, new_accounts: [] });
        const company = this.state.company;
        const start_date = this.state.start_date.replace('/','-')
        const end_date = this.state.end_date.replace('/', '-');
       

        try {

            const data = await possibleMatchFunctions.Get_Log(company, start_date, end_date)
            console.log(data)
            this.setState({
                audit_log: data,
                audit_clicked: false,
            });
        } catch (e) {
            this.setState({
                audit_log: [],
                audit_clicked: false,
            });
        }
    }

    async get_new_accounts(body) {
        try {
            const data = await customerFunctions.Get_By_Entered_Date(body);
            this.setState({
                new_accounts: data,
                new_accounts_loaded: true,
            });
        } catch (e) {
            this.setState({
                new_accounts: [],
                new_accounts_loaded: true,
            });
        }
    }

    format_date(date) {
        try {
            const year = getYear(date);
            let month = getMonth(date) + 1;
            if (month < 10) {
                month = `0${month}`;
            }
            let day = getDate(date);
            if (day < 10) {
                day = `0${day}`;
            }
            return `${year}-${month}-${day}`;
        } catch (e) {
            console.log(e);
            return '';
        }
    }

    async set_today() {
        this.setState({ audit_clicked: true });
        try {
            const formatted_date = this.format_date(new Date());
            this.setState({ start_date: formatted_date, end_date: formatted_date });
            await this.get_audit_log(this.state.company, formatted_date, formatted_date);
        } catch (e) {
            console.log(e);
        }
    }

    async set_yesterday() {
        this.setState({ audit_clicked: true });
        try {
            const formatted_date = this.format_date(addDays(new Date(), -1));
            this.setState({ start_date: formatted_date, end_date: formatted_date });
            await this.get_audit_log(this.state.company, formatted_date, formatted_date);
        } catch (e) {
            console.log(e);
        }
    }

    render_new_account_table(list) {
        const sort_direction = this.state.sort_direction;
        const sort_field = this.state.sort_field;

        const header = (title) => ({
            column_title: title,
            sort_direction: sort_direction,
            is_sortable: 'Y',
            header_style: 'text-xs text-nowrap',
        });
        const headers = [
            header('Company ID'),
            header('Customer ID'),
            header('Customer Name'),
            header('DBA'),
            header('Salesperson')
        ];

        const data = [];

        for (let i = 0; i < list.length; i++) {
            let address = list[i].address1;
            if (list[i].address2) {
                address = `${address}, ${list[i].address2}`;
            }
            const flag = /^\d+$/.test(list[i].id.slice(-1)) ? 'bg-warning' : '';
            data.push({
                row_style: `text-xs ${flag}`,
                cells: [
                    { cell: list[i].company_id, cell_style: 'py-0' },
                    { cell: list[i].id, cell_style: 'py-0 text-nowrap' },
                    { cell: list[i].name, cell_style: 'py-0 text-nowrap' },
                    { cell: list[i].remarks ? list[i].remarks : '-', cell_style: 'py-0 text-nowrap' },
                    { cell: list[i].salesperson_name, cell_style: 'py-0 text-nowrap' }
                ],
                data: list[i],
            });
        }
        if (data.length) {
            return (<div style={{overflow:'auto'}} className="pe-2 pb-2">
                <SortableTable
                    onSort={(prop) =>
                        this.setState({ sort_field: prop, sort_direction: sort_direction === 'H' ? 'L' : 'H' })
                    }
                    table_headers={headers}
                    table_data={data}
                    row_click={(prop) => this.view_account(JSON.parse(prop).company_id, JSON.parse(prop).id)}
                    table_title=""
                    sort_field={sort_field}
                />
            </div>)
        }
        else {
            return null;
        }
    }

    get_audit_log_headers() {
        let headers = [];
        const audit_headers =
            [
                'Company ID',
                'Entered By',
                'Entered Date',
                'Customer Name',
                'DBA',
                'Address',
                'City, State',
                'Zip Code',
                'Main Phone',
                'Website'
            ]
        audit_headers.map(d =>
            headers.push({ column_title: d, sort_direction: this.state.sort_direction, is_sortable: 'Y', header_style: 'text-xs' })
        )

        return audit_headers;
    }

    get_audit_log_table() {
        const audit_log = this.state.audit_log;
        let audit_data = [];
        let cells_style='text-xs text-nowrap'
        audit_log.map(d =>
            audit_data.push({
                row_style: '',
                cells: [
                    { cell: d.company_id, cell_style: cells_style },
                    { cell: d.name, cell_style: cells_style },
                    { cell: d.entered_date, cell_style: cells_style },
                    { cell: d.entered_customer_name, cell_style: cells_style },
                    { cell: d.entered_dba, cell_style: cells_style },
                    { cell: d.entered_address, cell_style: cells_style },
                    {
                        cell: `${d.entered_city}, ${d.entered_state_id}`,
                        cell_style: cells_style,
                    },
                    { cell: d.entered_zip_code, cell_style: cells_style },
                    { cell: d.entered_main_phone, cell_style: cells_style },
                    { cell: d.entered_website, cell_style: cells_style },
                ],
                data: d,
            })
        )

        if (audit_data.length > 0) {
            return (<SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: this.state.sort_direction === 'H' ? 'L' : 'H' })
                }
                table_headers={this.get_audit_log_headers()}
                table_data={audit_data}
                row_click={(prop) => this.setState({ selected_audit: JSON.parse(prop), display_audit: true })}
                table_title="Entered Information By User"
                sort_field={this.state.sort_field}
            />)
        } else {
            return null;
        }
  
    }

    async open_account_view_for_request(prop, list) {  
        this.setState({ selected_accounts_list: list });
        await this.view_account(prop.company_id, prop.id);
        
    }

    render() {
        const {       
            user_loaded,
            account_clicked,
            start_date,
            end_date,
            company,
            audit_log,
            audit_clicked,
            selected_audit,
            show_audit_div,
            display_audit,
            new_accounts,
            new_accounts_loaded,
            new_accounts_today,
            new_accounts_today_loaded,
            selected_account_data,
            show_selected_account,
            selected_accounts_list,

           
        } = this.state;
        const users_requests_list = this.props.incoming_requests ? this.props.incoming_requests.list ? this.props.incoming_requests.list : [] : [];

 
        const requests_div =
            users_requests_list ? (
                <CreditRequests
                    users_requests={users_requests_list}
                    msal_account={this.props.msal_account}
                    current_user={this.props.salesperson}
                    selected_account_data={selected_account_data}
                    view_account={(prop,list) => this.open_account_view_for_request(prop,list)}
                    role={this.props.role}
                    width={ this.props.width}
                    update_users_request={(type,prop) => this.props.update_users_request(type,prop)}
                />
            ) : null;
   



        //#region Audit Div
        const audit_div = (
            <div>
                <div className="d-flex w-100 justify-content-between p-3">
                    <div className="d-flex flex-row border justify-content-between rounded p-1 w-100">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row">
                                <small className="text-sm text-muted align-self-center text-nowrap pe-1">
                                    Company ID:
                                </small>
                                <Form.Select
                                    className="text-sm border-0 outline-0 p-0 ps-1 text-nowrap"
                                    style={{ width: '10rem' }}
                                    value={company}
                                    onChange={(e) => this.setState({ company: e.target.value })}
                                >
                                    <option value="">Select</option>
                                    <option value="TMS">SLC</option>
                                    <option value="TMS2">SLUSA</option>
                                </Form.Select>
                            </div>

                            <div className="d-flex flex-row mt-2">
                                <div className="d-flex flex-row">
                                    <small className="text-sm text-muted align-self-center text-nowrap pe-1">
                                        Start Date:
                                    </small>
                                    <Form.Control
                                        className="text-sm border-0 outline-0 p-0 ps-1"
                                        style={{ width: '10rem' }}
                                        type="date"
                                        value={start_date}
                                        onChange={(e) => this.setState({ start_date: e.target.value })}
                                    />
                                </div>
                                <div className="d-flex flex-row">
                                    <small className="text-sm text-muted align-self-center text-nowrap pe-1">
                                        End Date:
                                    </small>
                                    <Form.Control
                                        className="text-sm border-0 outline-0 p-0 ps-1"
                                        style={{ width: '10rem' }}
                                        type="date"
                                        value={end_date}
                                        onChange={(e) => this.setState({ end_date: e.target.value })}
                                    />
                                </div>
                                <Button
                                    className="btn-op70"
                                    disabled={audit_clicked || !company}
                                    onClick={() => this.get_audit_log(company, start_date, end_date)}
                                    title={
                                        !company ? 'Select Company ID first' : 'Get possible matches from the prospects'
                                    }
                                    size="sm"
                                    variant={audit_clicked ? 'outline-success' : 'success'}
                                >
                                    Go
                                </Button>
                            </div>

                            <div className="d-flex flex-row my-2">
                                <Button
                                    className="btn-op70 me-3 text-sm"
                                    disabled={audit_clicked || !company}
                                    onClick={() => this.set_today()}
                                    style={{ width: '10rem' }}
                                    title={
                                        !company
                                            ? 'Select Company ID first'
                                            : 'Get possible matches from the prospects that were created today'
                                    }
                                    size="sm"
                                    variant="warning"
                                >
                                    Get Today
                                </Button>
                                <Button
                                    className="btn-op70 text-sm"
                                    disabled={audit_clicked || !company}
                                    onClick={() => this.set_yesterday()}
                                    title={
                                        !company
                                            ? 'Select Company ID first'
                                            : 'Get possible matches from the prospects that were created yesterday'
                                    }
                                    size="sm"
                                    style={{ width: '10rem' }}
                                    variant="warning"
                                >
                                    Get Yesterday
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        //#endregion Audit Div

        //#region Audit Container
        const audit_container = (
            <Card className="d-flex flex-column w-100 h-100">
                <div className="d-flex flex-row justify-content-between p-3">
                    <div className="pt-1 ps-3"></div>
                    <h6 className="text-muted">Possible Matches Log</h6>
              
                </div>
                {audit_div}
                {show_audit_div ? (
                    <Tabs defaultActiveKey="Matches" className="">
                        <Tab eventKey="Matches" title="Possible Matches">
                            <div className="p-3">
                                <div className="p-3">{this.get_audit_log_table()}</div>
                            </div>
                        </Tab>
                        <Tab eventKey="Created" title="Prospects Created">
                            <div className="p-3">
                                {new_accounts_loaded ? this.render_new_account_table(new_accounts) : null}
                            </div>
                        </Tab>
                    </Tabs>
                ) : null}
          
            </Card>
        );
        //#endregion Audit Container

        const possible_match_div = <div className="d-flex flex-column">
            <AuditLogResults
                audit_log={audit_log}
                selected_audit={selected_audit}
                start_date={start_date}
                end_date={end_date}
                company={company}
                update_field={(field, value) => this.setState({ [field]: value })}
                get_audit_log={() => this.get_audit_log()}
                set_selected_audit={(prop, open_log) => this.setState({ selected_audit: prop, display_audit: open_log==='Y'?true:false })}
            />
        </div>


        const flex = this.props.width?parseInt(this.props.width) < 1200 ? 'column' : 'row':'row';
        const max_width = this.props.width ? parseInt(this.props.width) < 1200 ?'w-100' :'w-50':'w-50';

        return this.props.salesperson ? (
            <div className="d-flex flex-column align-self-center pt-3 w-100">
                <div className={`d-flex flex-${flex} align-self-center p-2 w-100 justify-content-between`}>
                    <div className={`${max_width} p-2`}>
                        <DynamicPortalTile
                            title='Requests'
                            height="60rem"

                            body={requests_div}
                            loaded={this.props.incoming_requests ? this.props.incoming_requests.loaded === 'Y' : false}
                            failed={this.props.incoming_requests ? this.props.incoming_requests.failed === 'Y' : false}
                            loading_title="Requests"
                        />
                    </div>
                    <div className={`${max_width} p-2`}>
                        <DynamicPortalTile
                            title='Prospects Created Today'
                            height="40rem"
                            body={this.render_new_account_table(new_accounts_today)}
                            loaded={new_accounts_today_loaded}
                            failed={false}
                            loading_title="Prospects Created Today"
                        />
                    </div>

                       
                </div>
                <div className={`${max_width} p-2`}>
                    <DynamicPortalTile
                        title='Possible Matches'
                        height="40rem"
                        body={possible_match_div}
                        loaded={!audit_clicked}
                        failed={false}
                        loading_title="Possible Matches"
                    />
                </div>
                 


                {selected_account_data ?
                    <ViewAccountModal
                        show={show_selected_account}
                        onHide={() => this.setState({
                            show_selected_account: false,
                            selected_account_data: null,
                            selected_accounts_list: []
                        })}
                        current_user={this.props.salesperson}
                        msal_account={this.props.msal_account}
                        role={this.props.role}
                        accounts_list={selected_accounts_list}
                        prospects={this.props.prospects}
                        commodities={this.props.commodities}
                        categories={this.props.categories}
                        salesperson_list={this.props.salesperson_list}
                        selected_account_data={selected_account_data}
                        add_account={(prop) => this.props.add_account(prop)}
                        release_account={(prop) => this.props.release_account(prop)}
                        update_accounts_list={(prop) => this.setState({ selected_accounts_list: prop })}
                        view_account={(prop) => this.view_account(prop.company_id, prop.id)}                        
                        update_selected_account_data={(prop) => this.setState({ selected_account_data: prop })}                                           
                        update_users_request={(type, prop) => this.props.update_users_request(type, prop)}
                        add_users_request={(type, prop) => this.props.add_users_request(type, prop)}                       
                        add_users_action={(prop) => this.props.add_users_action(prop)}
                        update_users_action={(prop) => this.props.add_users_action(prop)}
                        /> : null}
                <LoadingModal show={account_clicked} title="Account" />
                {selected_audit ? (
                    <DisplayedMatchesModal
                        show={display_audit}
                        onHide={() => this.setState({ selected_audit: null, display_audit: false })}
                        selected_audit={selected_audit}
                    />
                ) : null}
           
                </div>
        ) : (
                <div className="p-2 d-flex flex-column w-100" style={{ height: '80vh', overflow: 'auto' }}>
                    <LoadingModal show={!user_loaded} title="Credit User" />
                </div>
        );
    }


    async view_account(company_id, id) {
        this.setState({ account_clicked: true });
        const account_data = await customerFunctions.View_Account(company_id, id);
        this.setState({
            selected_account_data: account_data,
            show_selected_account: true,
            account_clicked: false
        })
        
        
    }


}
