import React, { Component } from 'react';
import { Button, Col, Container, Form, Row, InputGroup, ListGroup } from 'react-bootstrap';
import * as dataService from '../../Dataservice/Endpoints';
import * as svg_icons from '../../Media/svg_exports';

import * as commentFunctions from '../Functions/CommentFunctions';
import * as utilityFunctions from '../Functions/UtilityFunctions';
import sortByStrategy from '../../Domain/sorting-strategy';
import { differenceInDays } from 'date-fns';
import { AttachmentInput } from '../Inputs/AttachmentInput';

export class CommentsTab extends Component {
    static displayName = CommentsTab.name;

    constructor(props) {
        super(props);
        this.state = {
            sort_field: 'Entered Date',
            sort_direction: 'L',
            show_popup: false,         
            new_comment:this.props.new_comment_template,
            new_attachment: null,
            current_attachment:null,
            current_comment: null,
            message: '',
            current_thumbnail: null,
            current_thumbnail_name: '',
            thumbnail_clicked: false,
            initial_comment: '',
            show_audit_log: false,
            selected_comment_audit:''
        };
    }

    async add_comment_audit(new_comment, method) {
        try {
            const selected_account_data = this.props.selected_account_data
            let comment_audit_list = selected_account_data ? selected_account_data.comment_audit ? selected_account_data.comment_audit : [] : [];
            const current_user_id = this.props.msal_account.username.replaceAll('@scotlynn.com', '')
            let new_comment_format = '';
            if (method === 'Updated') {
                new_comment_format = `Modified by ${current_user_id} [${utilityFunctions.format_datetime_from_input(new Date().toISOString())}]: ${new_comment.comments}`
            }
            else if (method === 'Initial') {
                new_comment_format = `[Initial] ${current_user_id} [${utilityFunctions.format_datetime_from_input(new Date().toISOString())}]: ${new_comment.comments}`
            }
            else if (method === 'Deleted') {
                new_comment_format = `Deleted by ${current_user_id} [${utilityFunctions.format_datetime_from_input(new Date().toISOString())}]`
            }
            const body = {
                company_id: new_comment.company_id,
                comment_id: new_comment.id,
                customer_id: new_comment.parent_row_id,
                changed_by_user: current_user_id,
                initial_comment: this.state.initial_comment,
                new_comment: new_comment_format
            }
            const audit_data = await commentFunctions.Insert_Comment_Audit(body);
            comment_audit_list.push(audit_data)
            this.props.update_selected_account_data('comment_audit', comment_audit_list)    
            this.setState({ initial_comment: '' })
        } catch (e) {
            console.log(e)
        }     
    }

    async reset_new_comment() {
        const selected_account_data = this.props.selected_account_data
        const selected_account = selected_account_data ? selected_account_data.account : null;
        const current_user = this.props.current_user;
        try {
            if (selected_account && current_user) {
                const empty_comment = await commentFunctions.Get_Template(selected_account.company_id, selected_account.id, current_user.user_id);
                this.setState({ new_comment: empty_comment })
            }
        } catch (e) {
            console.log(e)
        }
       
        
    }

    update_new_comment(e) {
        let new_comment = this.state.new_comment;
        new_comment[e.target.name] = e.target.value;
        this.setState({ new_comment: new_comment })
    }

    update_current_comment(e) {
        let current_comment = this.state.current_comment;
        current_comment[e.target.name] = e.target.value;
        this.setState({ current_comment: current_comment })
    }

    async getThumbnail(comment) {
        try {
            this.setState({
                thumbnail_clicked:true,
                current_thumbnail: null,
                show_popup: false,
                current_thumbnail_name: '',
            });
            const fetch_string = dataService.getAttachment(comment.company_id, comment.id);
            
            const headers = dataService.fileHeader(comment.company_id);
            const options = { method: 'GET', headers: headers };
            await fetch(fetch_string, options)
                .then((res) => {
                    return res.blob();
                })
                .then((data) => {
                    const file = window.URL.createObjectURL(data);
                    this.setState({
                        current_thumbnail: file,
                        show_popup: true,
                        thumbnail_clicked: false,
                        current_thumbnail_name: comment.attach_filename
                    });
                })
        } catch (e) {
            console.log(e);
            this.setState({
                thumbnail_clicked: false,
                current_thumbnail: null,
                show_popup: false,
                current_thumbnail_name: '',
            });
        }
    }

    async post_comment() {
        this.setState({ clicked: true, message: 'Adding comment...' })
        try {
            const selected_account_data = this.props.selected_account_data;
            let comments = selected_account_data ? selected_account_data.comments ? selected_account_data.comments : [] : [];
            let new_comment = this.state.new_comment
            const new_attachment = this.state.new_attachment;
            
            const comment_data = await commentFunctions.Post(new_comment);
            await this.add_comment_audit(comment_data, 'Initial');
            if (this.state.new_attachment) {
                commentFunctions.Upload(new_comment.company_id, new_comment.parent_row_id, new_comment.id, new_attachment);
                new_comment.attachment = new_attachment;
                new_comment.attach_filename = new_attachment.name
            }
            comments.push(comment_data)
            this.props.update_selected_account_data('comments', comments);
            this.setState({ clicked: false, message: '' })
            this.reset_new_comment();
            
        } catch (e) {
            console.log(e)
            this.setState({ clicked: false, message: '', new_attachment: null })
            this.reset_new_comment();
        }
      
    }

    async put_comment() {
        this.setState({ clicked: true, message: 'Saving comment...' })
        try {
            const selected_account_data = this.props.selected_account_data;
            let comments = selected_account_data ? selected_account_data.comments ? selected_account_data.comments : [] : [];
            const current_attachment = this.state.current_attachment;
            let current_comment = this.state.current_comment;
            await this.add_comment_audit(current_comment, 'Updated');
            const comment_data = await commentFunctions.Put(current_comment);
            if (this.state.current_attachment) {
                commentFunctions.Upload(current_comment.company_id, current_comment.parent_row_id, current_comment.id, current_attachment);
                current_comment.attachment = current_attachment;
                current_comment.attach_filename = current_attachment.name
            }
            const idx = comments.findIndex(s => s.id === current_comment.id)
            comments[idx] = comment_data;
            this.props.update_selected_account_data('comments', comments);
            this.setState({ clicked: false, message: '', current_comment: null, current_attachment: null })
            this.reset_new_comment()

        } catch (e) {
            console.log(e)
            this.setState({ clicked: false, message: '', current_attachment: null, current_comment:null })
            this.reset_new_comment();
        }
      

        
    }

    get_audit_log(d) {
        if (this.props.selected_account_data.comment_audit && !this.state.current_comment) {
            if (this.props.selected_account_data.comment_audit.find(s => s.comment_id === d.id)) {
                if (this.state.selected_comment_audit === d.id) {
                    return null
                } else {
                    return (<Button
                        size="sm"
                        title="View historical of this comment"
                        id="comments-audit-log-icon"
                        className="btn-op70 bg-transparent"
                        variant="light"
                        onClick={() => this.setState({ show_audit_log: true, selected_comment_audit: d.id })}
                    >
                        {svg_icons.clock}
                    </Button>)
                }
               
            } else {
                return null;
            }
           
        } else {
            return null;
        }

    }

    check_user_id(comment) {
        const options_button = <Button
            size="sm"
            className="btn-op70 bg-transparent"
            variant="light"
            onClick={() => this.set_current_comment(comment)}
        >
            {svg_icons.edit_icon}
        </Button>;

        const cancel_button = <Button
            size="sm"
            className="me-3"
            title="Cancel"
            variant="secondary"
            onClick={() => this.setState({ current_comment: null })}
        >
            {svg_icons.cancel_icon}
        </Button>

        const save_button = <Button
            size="sm"
            className="btn-op70 opacity-75 "
            variant="success"
            title="Save"
            onClick={() => this.put_comment()}
        >
            {svg_icons.save_icon}
        </Button>
    


        const save_cancel_div =
            <div className="d-flex flex-row justify-content-end pt-3">
                {cancel_button}
                {save_button}
        </div>

        try {
            if (this.props.current_user && comment.entered_user_id && !this.state.selected_comment_audit) {
                if (
                    this.props.current_user.user_id.toLowerCase() === comment.entered_user_id.toLowerCase() ||
                    this.props.role === 'admin' ||
                    (this.props.role === 'credit' && comment.comment_type_id==='INFO')
                ) {

                    if (this.state.current_comment) {
                        if (this.state.current_comment.id === comment.id) {
                            return save_cancel_div;
                        } else {
                            return options_button;
                        }
                    } else {
                        return options_button;
                    }
                }
            }
        } catch (e) {
            return false;
        }
    }

    new_comment_form() {
        const new_comment = this.state.new_comment;
        const clicked = this.state.clicked
        const file_uploading = this.state.file_uploading;

        let button_label = "Add";
        if (clicked) {
            button_label="Adding..."
        }
        if (file_uploading) {
            button_label = "Checking attachment..."
        } else {
            button_label = "Add";
        }

        const new_comment_form = new_comment?
            <div className="d-flex flex-column flex-grow-1 justify-content-between h-50">
                <div className="d-flex flex-column flex-grow-1">
                    <h6 className="text-muted text-lg">Add New Comment</h6>
               
                    <InputGroup className="d-flex flex-row justify-content-between mb-0">
                        <Form.Select
                            className="text-sm bg-white text-start flex-grow-2"
                 
                            disabled={clicked || file_uploading}
                            isInvalid={!new_comment.comment_type_id}
                            value={new_comment.comment_type_id ? new_comment.comment_type_id : ''}
                            onChange={(e) => this.update_new_comment(e)}
                            name="comment_type_id">
                            <option value="">Select Type</option>
                            {this.props.role === 'credit' || this.props.role === 'admin' ?
                                <option value="INFO">INFO</option> :
                            null}
                            
                            {commentFunctions.comment_types.map(d =>
                                <option value={d} key={d}>{d}</option>
                            )}
                        </Form.Select>
                        <Form.Control
                            value={new_comment.comments ? new_comment.comments : ''}
                            placeholder="Enter new comment here..."
                            disabled={clicked || file_uploading}
                            isInvalid={!new_comment.comments}
                            onChange={(e) => this.update_new_comment(e)}
                            as="textarea"
                            rows={ 5}
                            name="comments"
                            className="text-sm bg-white text-start flex-grow-1 w-75"

                        />
                        <Button
                            className="btn-op70 text-sm flex-grow-3"
                        
                            size="sm" variant={clicked ? 'outline-success' : 'success'}
                            disabled={clicked || file_uploading || !new_comment.comments || !new_comment.comment_type_id}
                            onClick={() => this.post_comment()}>
                            {button_label}
                        </Button>
                    </InputGroup>
                    

                    <AttachmentInput
                        current_comment={new_comment}
                        current_attachment={this.state.new_attachment}
                        update_message={(prop) => this.setState({ message: prop })}
                        update_clicked={(prop) => this.setState({ file_uploading: prop })}
                        update_attachment={(prop) => this.setState({ new_attachment: prop })}
                    />

                 
                </div>
             
            </div>:null;


        try {
            const selected_account_data = this.props.selected_account_data;
            const selected_account = selected_account_data ? selected_account_data.account : null;
            const current_user = this.props.current_user;

            if (current_user && selected_account) {
                if (selected_account.salesperson_id === current_user.salesperson_id ||
                   selected_account.salesperson_id === "UNCLAIMD" ||
                    this.props.role === "admin" ||
                    this.props.role === "credit"
                ) {
                    return (new_comment_form)
                } else {
                    return null;
                }
            }
        } catch (e) {
            return false;
        }
    }

    set_current_comment(d) {

        const current_comment = this.state.current_comment;
        this.reset_new_comment();
        if (current_comment) {
            if (current_comment.id === d.id) {               
                this.setState({
                    current_comment: null,
                    show_comment_options: false,
                    initial_comment:''
                });
            } else {
                this.setState({ current_comment: d, show_comment_options: true, initial_comment:d.comments });
            }
        } else {
            this.setState({ current_comment: d, show_comment_options: true, initial_comment: d.comments });
        }
    }

    get_comments_list() {
        const selected_account_data = this.props.selected_account_data
        let comments = selected_account_data ? selected_account_data.comments ? selected_account_data.comments : [] : [];
        const audit_log = selected_account_data ? selected_account_data.comment_audit ? selected_account_data.comment_audit : [] : [];
        const current_comment = this.state.current_comment;
        const sort_field = this.state.sort_field;
        const sort_direction = this.state.sort_direction;

        if (comments.length > 0) {
            const sortingStrategies = {
                Type: (a, b) => a.comment_type_id.localeCompare(b.comment_type_id),
                Comment: (a, b) => a.comments.length - b.comments.length,
                'Entered User': (a, b) => a.entered_user_id.localeCompare(b.entered_user_id),
                Attachment: (a, b) => a.attach_filename.localeCompare(b.attach_filename),
                'Entered Date': (a, b) => differenceInDays(new Date(a.entered_date_str), new Date(b.entered_date_str)),
            };
            comments = comments.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
        }



        const comments_block = (d) =>
            <div className="text-sm mt-2">
                <small className="fw-bold text-sm bg-light-gray rounded px-2">{d.comment_type_id ? d.comment_type_id:'NONE'}</small>
                <small className="fw-bold text-sm opacity-75 ps-1" style={{ whiteSpace: 'pre-wrap' }}>{d.comments}</small>
            </div>


        if (comments) {
            return (
                <ListGroup>
                    {comments.map(d =>
                        <ListGroup.Item key={d.id} className="pb-3">
                            <div className="d-flex flex-column justify-content-start">
                                <div className="d-flex flex-row justify-content-between">
                                    <div className="d-flex flex-column">
                                        <div className="fw-bold text-lg">{d.entered_user_name ? d.entered_user_name : 'Unknown'}</div>
                                       
                                    </div>
                                    
                                    <div className="d-flex flex-row justify-content-end">
                                        <small className="fw-bold opacity-75 text-xs">Modified: {d.entered_date_str}</small>
                                    </div>
                                </div>
                                {current_comment ?
                                    current_comment.id === d.id ?
                                    <div className="d-flex flex-column">
                                            <Form.Control
                                                value={current_comment.comments}
                                                disabled={this.state.clicked || this.state.file_uploading}
                                                onChange={(e) => this.update_current_comment(e)}
                                                as="textarea"
                                                rows={8}
                                                name="comments"
                                                className="text-sm bg-white text-start flex-grow-1 w-75"
                                            />
                                            <AttachmentInput
                                                current_comment={current_comment}
                                                current_attachment={this.state.current_attachment}
                                                update_message={(prop) => this.setState({ message: prop })}
                                                update_clicked={(prop) => this.setState({ file_uploading: prop })}
                                                update_attachment={(prop) => this.setState({ current_attachment: prop })}
                                            />
                                            {this.state.message ?
                                                <small className="opacity-75 text-start">{this.state.message}</small> :
                                                null
                                            }
                                         
                                    </div>
                                        :
                                        comments_block(d)    :
                                    comments_block(d) }
                                
                         
                                    <div className="d-flex flex-row justify-content-between w-100 mt-2">
                                    {d.attach_filename ?
                                        <Button variant="link" size="sm" className="text-xs underlined text-start p-0" disabled={this.state.thumbnail_clicked} onClick={() => this.getThumbnail(d)}>
                                                {svg_icons.attachment_icon}
                                                {d.attach_filename}
                                            </Button> :
                                        <div></div>}
                                    <div className="d-flex">
                                        {this.check_user_id(d)}
                                        {this.get_audit_log(d)}
                                    </div>
                                 
                                </div>

                                        {
                                    this.state.selected_comment_audit === d.id && this.state.show_audit_log ?
                                                <div className="d-flex flex-column mt-2 border" style={{ borderRadius: '0.3rem' }}>
                                                    <div className="d-flex flex-row justify-content-between bg-light-gray" style={{ borderRadius: '0.3rem 0.3rem 0rem 0rem' }}>
                                                        <h6 className="p-0 m-0 p-2 text-med text-bold">Comment History</h6>
                                                        <Button
                                                            size="sm"
                                                            title="View historical of this comment"
                                                            className="bg-transparent light-gray-border"
                                                            variant="light"
                                                            onClick={() => this.setState({ show_audit_log: false, selected_comment_audit: '' })}
                                                        >
                                                            {svg_icons.cancel_icon}
                                                        </Button>
                                                    </div>

                                            {audit_log.filter(s => s.comment_id === d.id).sort((b,a) => new Date(a.changed_date_time) - new Date(b.changed_date_time)).map((x, index) =>
                                                        <div key={x.id} className={`d-flex flex-row text-xs opacity-75 p-2 ${index % 2 !== 0 ? 'alternate-container-row' : ''}`}>
                                                            <label className="pe-1" style={{ whiteSpace: 'pre-wrap' }}>{x.new_comment}</label>
                                                        </div>
                                                    )}
                                                </div> :
                                                null}
                               
                              
                                
                
                            </div>
                   
                        </ListGroup.Item>
                    )}
                   
                </ListGroup>)
        } else {
            return null
        }
        
    }



    render() {
        const {
            show_popup,
            current_thumbnail,
            current_thumbnail_name,
            clicked,
            current_comment,
            new_comment,
            new_attachment,
            thumbnail_clicked

        } = this.state;



        const iframed = <iframe src={current_thumbnail} title={current_thumbnail_name} className="iframe" />

 
          
        return (
            <div className="d-flex flex-column px-2 w-100 pb-5">

                <h6 className="text-muted tile-title">Comments</h6>
                <hr className="mt-0" />
                {show_popup ? (
                    <div className="d-flex flex-column w-100 h-100 justify-content-start">
                        <Button
                            className="btn-op70 my-3 align-self-start"
                            id="back-to-comments-btn"
                            variant="light"
                            onClick={() =>
                                this.setState({
                                    current_thumbnail: null,
                                    show_popup: false,
                                    current_thumbnail_name: '',
                                })
                            }
                        >
                            {svg_icons.back_arrow}
                        </Button>

                        {current_thumbnail !== null ? (
                            iframed
                        ) : null}
                    </div>
                ) : (
                        <div className="d-flex flex-column justify-content-start w-100 h-100">
              
                            {this.new_comment_form()}
                            {this.get_comments_list()}
                    </div>
                )}
            </div>
        );
    }
}
