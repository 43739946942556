import { addDays, addMonths, differenceInWeeks, isBefore, isSameISOWeek, isToday, differenceInDays } from 'date-fns';
import React, { Component } from 'react';
import { Card, Form, Table, Button, Popover, OverlayTrigger, InputGroup } from 'react-bootstrap';
import * as svg_icons from '../../Media/svg_exports';
import * as prospectActionFunctions from '../Functions/ProspectActionFunctions'
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';

export class InactiveLamAccounts extends Component {
    static displayName = InactiveLamAccounts.name;
    constructor(props) {
        super(props);
        this.state = {
            sort_direction: 'L',
            sort_field: 'Days Inactive',
            filter: '',
            selected_id: '',
            selected_customer: '',
            clicked:false
            
        };
    }

    async gift_account() {
        this.setState({ clicked :true})
        let salesperson = this.props.salesperson_list.find(s => s.company_id === this.props.current_user.company_id &&
            s.salesperson_id === this.state.selected_id);

        const body = {
            company_id: salesperson.company_id,
            customer_id: this.state.selected_customer,
            salesperson_id: salesperson.salesperson_id,
            sales_manager_id: salesperson.sales_manager_id,
            user_id: this.props.current_user.salesperson_id
        };
        let current_gm_dashboard = this.props.current_gm_dashboard
        console.log(current_gm_dashboard)
        this.setState({ clicked: false })
    }

    get_account_button(d) {
        if (d.name) {
            return (<label className="hover-over underlined p-0 text-primary" onClick={ ()=>this.props.view_account(d.company_id, d.id)}>{ d.name}</label>)
        } else {
            return null;
        }
    }

    get_lam_dropdown(d) {
        const edit_icon = <div style={{ transform: 'scale(0.8)' }} className="hover-over pe-2" onClick={() => this.props.show_gifted(d)} title="Gift account to another LAM">
            {svg_icons.edit_icon}
        </div>
        return (<label size="sm" className="text-xs d-flex" variant="outline-secondary">
            {edit_icon}
            {d.salesperson_name}
            
        </label>)
        //const clicked = this.state.clicked
        //let selected_id = this.state.selected_id ? this.state.selected_id : d.salesperson_id;
        //const selected_customer = this.state.selected_customer
      
        //if (this.props.salesperson_list) {
        //    let salesperson_list = this.props.current_gm_dashboard;
        //    if (selected_customer === d.id) {
        //        return (

        //            <InputGroup className="p-0 m-0">
        //                <Form.Select className="p-1 text-xs" value={selected_id} onChange={(e) => this.setState({ selected_id: e.target.value })}>

        //                    {salesperson_list.sort((a, b) => a.name.localeCompare(b.name)).map(m =>
        //                        <option value={m.salesperson_id} key={m.salesperson_id}>{`${m.name} (${d.num_prospects})`}</option>
        //                    )}
        //                    <option value='UNCLAIMD'>UNCLAIMED</option>
        //                </Form.Select>
        //                <Button
        //                    className="p-0"
        //                    onClick={() => this.gift_account()}
        //                    disabled={!selected_id || selected_id === d.salesperson_id || clicked}
        //                    size="sm" variant={clicked ? 'outline-success' : 'success'}>
        //                    {save_icon}
        //                </Button>
        //            </InputGroup>
        //        )
        //    } else {
        //        return (

        //            <InputGroup className="p-0 m-0">
        //                <Form.Select className="p-1 text-xs" value={d.salesperson_id}  onClick={() => this.setState({ selected_customer: d.id })}>
        //                    <option value={d.salesperson_id} >{`${d.salesperson_name}`}</option>
        //                    {salesperson_list.sort((a, b) => a.name.localeCompare(b.name)).map(m =>
        //                        <option value={m.salesperson_id} key={m.salesperson_id}>{`${d.salesperson_name}`}</option>
        //                    )}
        //                    <option value='UNCLAIMD'>UNCLAIMED</option>
        //                </Form.Select>
        //            </InputGroup>
        //        )
        //    }
           
        //} else {
        //    return null;
        //}
       
    }

    get_headers() {

        const headers_list = [
            'Account',
            'LAM (# Prospects)',
            'Type',
            'Days Inactive'
        ]
        let headers = []
        headers_list.map(s => headers.push({
            column_title: s,
            sort_direction: this.state.sort_direction,
            is_sortable: 'Y',
            header_style: 'py-1 text-sm text-nowrap opacity-75'
        }))
        return headers;
    }

    get_table() {
        let current_accounts = this.props.inactive_lam_accounts ? this.props.inactive_lam_accounts.filter(s=>s.salesperson_is_active==='N') : [];
        if (this.state.filter) {
            const filter = this.state.filter.toLowerCase()
            current_accounts = current_accounts.filter(s =>
                s.name.toLowerCase().includes(filter) ||
                s.id.toLowerCase().includes(filter) ||
                s.salesperson_name.toLowerCase().includes(filter) ||
                s.salesperson_id.toLowerCase().includes(filter))
        }
        const data = [];
        let cell_style = 'text-sm'
        current_accounts.map((d) =>
            data.push(
                {
                    row_style:'',
                    cells: [
                        { cell: this.get_account_button(d), cell_style: cell_style },
                        { cell:  this.get_lam_dropdown(d) , cell_style: cell_style },
                        { cell: d.type_of, cell_style: cell_style },
                        { cell: d.days_inactive, cell_style: cell_style },
                    ],
                    data: d,

                })
        )

        if (data.length) {
            return (<SortableTable
                onSort={(prop) =>
                    this.setState({ sort_field: prop, sort_direction: this.state.sort_direction === 'H' ? 'L' : 'H' })
                }
                table_headers={this.get_headers()}
                table_data={data}
                row_click={(prop) => null}
                table_title=""
                sort_field={this.state.sort_field}
            />)
        } else {
            return null;
        }
    }




    render() {
        const { sort_field, sort_direction, display_type, filter } = this.state;
        let current_accounts = this.props.inactive_lam_accounts ? this.props.inactive_lam_accounts.filter(s => s.salesperson_is_active === 'N') : [];

        if (current_accounts.length > 0) {
            const sortingStrategies = {
                Account: (a, b) => a.name.localeCompare(b.name),
                'LAM (# Prospects)': (a, b) => a.salesperson_id.localeCompare(b.salesperson_id),
                Type: (a, b) => a.type_of.localeCompare(b.type_of),
                'Days Inactive': (a, b) => a.days_inactive-b.days_inactive
            };
            current_accounts = current_accounts.sort(
                sortByStrategy(sortingStrategies, sort_field, sort_direction),
            );
        }
       

        //#region Salesperson Data
        const salesperson_table = (
            <div className="d-flex flex-column justify-content-center w-100">
                <Form.Control
                    type="text"
                    className="mb-3 rounded-0 text-sm"
                    placeholder="Search by name or account..."
                    value={filter}
                    onChange={(e) => this.setState({ filter: e.target.value })}
                />
                <div className="d-flex flex-column justify-content-center w-100 table-round p-0  m-0 bg-white">
                    {this.get_table()}
                </div>

            </div>
        );
        //#endregion Salesperson Table
        let button_style = 'underlined hover-over text-secondary text-bold p-0 text-start'


        return (
            <div className="d-flex flex-column w-100 justify-content-start p-2">
                <h6 className="text-muted tile-title">
                    {`Inactive LAM Accounts (${current_accounts.length})` }
                </h6>


                <div style={{overflow: 'auto', maxHeight:'30vh'}}>{salesperson_table}</div>
            </div>
        );
    }
}
