import { format } from 'date-fns';
import React, { Component } from 'react';
import {
    Alert,
    Button,
    CloseButton,
    Col,
    Container,
    FloatingLabel,
    Form,
    InputGroup,
    Modal,
    Row,
} from 'react-bootstrap';
import SortableTable from '../TableComponents/SortableTable';
import sortByStrategy from '../../Domain/sorting-strategy';
export class DisplayedMatchesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sort_direction: '',
            sort_field: '',
            search_filter:''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ button_clicked: true });
        var currentName = this.state.lam_name;
        this.props.onSave(currentName);
        this.setState({ button_clicked: false, lam_name: '' });
    }

    render() {
        const { sort_direction, sort_field, search_filter } = this.state;

        let matches = this.props.selected_audit ? JSON.parse(this.props.selected_audit.possible_matches_list) : [];

        const entered = this.props.selected_audit ? this.props.selected_audit : null;

        const possible_match_headers = [
            { column_title: '', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Company', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Name', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Code', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'LAM', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Type', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Address', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'City', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'State/Prov', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Zip Code', sort_direction: sort_direction, is_sortable: 'Y' },
            { column_title: 'Phone', sort_direction: sort_direction, is_sortable: 'Y' },
        ];

        if (matches.length > 0) {
            if (search_filter) {
                matches = matches.filter(s => s.name.toLowerCase().includes(search_filter.toLowerCase()) || s.id.toLowerCase().includes(search_filter.toLowerCase()))
            }
            const sortingStrategies = {
                Company: (a, b) => a.company_id.localeCompare(b.company_id),
                'Name': (a, b) => a.name.localeCompare(b.name),
                'Code': (a, b) => a.id.localeCompare(b.id),
                'LAM': (a, b) => a.salesperson_id.localeCompare(b.entesalesperson_idred_dba),
                'Type': (a, b) => a.type_of.localeCompare(b.type_of),
                'Address': (a, b) => a.address1.localeCompare(b.address1),
                'City': (a, b) => a.city.localeCompare(b.city),
                'State/Prov': (a, b) => a.state_id.localeCompare(b.state_id),
                'Zip Code': (a, b) => a.zip_code.localeCompare(b.zip_code),
                'Phone': (a, b) => a.main_phone.localeCompare(b.main_phone),

            };
            matches = matches.sort(sortByStrategy(sortingStrategies, sort_field, sort_direction));
        }
        const data = [];
        for (let i = 0; i < matches.length; i++) {
            data.push({
                row_style: '',
                cells: [
                    { cell: i + 1, cell_style: '' },
                    { cell: matches[i].company_id, cell_style: '' },
                    { cell: matches[i].name, cell_style: '' },
                    { cell: matches[i].id, cell_style: '' },
                    { cell: matches[i].salesperson_id, cell_style: '' },
                    { cell: matches[i].type_of, cell_style: '' },
                    { cell: matches[i].address1, cell_style: '' },
                    { cell: matches[i].city, cell_style: '' },
                    { cell: matches[i].state_id, cell_style: '' },
                    { cell: matches[i].zip_code, cell_style: '' },
                    { cell: matches[i].main_phone, cell_style: '' },
                ],
                data: matches[i],
            });
        }

        const matches_table =
            data.length > 0 ? (
                <SortableTable
                    onSort={(prop) =>
                        this.setState({
                            sort_field: prop,
                            sort_direction: sort_direction === 'H' ? 'L' : 'H',
                        })
                    }
                    row_click={(prop) => console.log(JSON.parse(prop))}
                    sort_field={sort_field}
                    table_headers={possible_match_headers}
                    table_data={data}
                    table_title="Matches"
                />
            ) : null;

        return (
            <div>
                <Modal
                    {...this.props}
                    fullscreen={ true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName="light-modal"
                    backdrop="static"
                >
                    <Modal.Header className="p-3">
                        <Modal.Title
                            id="contained-modal-title-vcenter"
                            className="text-muted text-center w-100 pt-3 m-0"
                        >
                            <div className="d-flex flex-row justify-content-between">
                                <label className="hover-over lg-page-title" onClick={() => this.props.onHide()}>X</label>
                                <h5 className="lg-page-title">Possible Matches Displayed to User</h5>
                                <div></div>
                            </div>

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="show-grid pb-5">
                        <InputGroup className="my-2">
                            <Form.Control
                                placeholder='Search By Name or ID'
                                value={search_filter}
                                className="text-xs"
                                onChange={(e) => this.setState({ search_filter:e.target.value })}
                            />
                            <Button variant='outline-danger opacity-50' size='sm' onClick={() => this.setState({ search_filter :''})}>X</Button>
                        </InputGroup>


                        {matches_table}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
