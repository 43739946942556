import React, { Component } from 'react';
import { Card, Alert, Button, Form } from 'react-bootstrap';

export class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true,
        };
        this.handle_input_change = this.handle_input_change.bind(this);
    }

    handle_input_change(input) {
        if (this.props.displayed_prospects.length > 0) {
            this.props.filter_changed();
        }
        const current_filter = this.props.search_filter;
        if (input.includes('&')) {
            const index_of_amper = input.indexOf('&');
            if (index_of_amper + 1 <= input.length - 1 && index_of_amper > 0) {
                if (input[index_of_amper - 1] === ' ' && input[index_of_amper + 1] === ' ') {
                    current_filter.Search_Input = input;
                } else if (input[index_of_amper - 1] === ' ' && input[index_of_amper + 1] !== ' ') {
                    const first_part = input.substring(0, Number.parseInt(index_of_amper));
                    const second_part = input.substring(Number.parseInt(index_of_amper) + 1);
                    current_filter.Search_Input = `${first_part}& ${second_part}`;
                } else {
                    const first_part = input.substring(0, Number.parseInt(index_of_amper));
                    const second_part = input.substring(Number.parseInt(index_of_amper) + 1);
                    current_filter.Search_Input = `${first_part} & ${second_part}`;
                }
            } else {
                current_filter.Search_Input = input;
            }
        } else {
            current_filter.Search_Input = input;
        }
        if (input[0]===' ') {
            current_filter.Search_Input = current_filter.Search_Input.trim()
        }
        this.props.update_search_filter(current_filter);
    }

    render() {
        const search_filter_input = this.props.search_filter ? this.props.search_filter.Search_Input : '';

        const go_button = (
            <Button
                size="sm"
                variant="success"
                style={{ borderRadius: ' 0rem 0.3rem 0.3rem 0rem' }}
                className="align-self-stretch h-100 text-sm"
                onClick={() => this.props.search_page()}
            >
                Go
            </Button>
        );

        const cancel_button = (
            <Button
                size="sm"
                variant="danger"
                style={{
                    border: 'solid 0.1rem rgba(0,0,0,0.1)',
                    borderRadius: ' 0rem 0.3rem 0.3rem 0rem',
                }}
                className="align-self-stretch h-100 opacity-75"
                onClick={() => this.props.cancel_search()}
            >
                Cancel
            </Button>
        );

        return (
            <div className="d-flex flex-row justify-content-start border rounded p-0 w-100">
                <div className="d-flex flex-column justify-content-start p-1 w-100">
                    <label className="text-start text-sm opacity-75 text-muted">Search By</label>
                    <div className="d-flex flex-row w-100">
                        <Form.Select
                            className="text-sm ps-1 border-0 outline-0 shadow-0"
                            onChange={(e) => this.props.search_type_change(e.target.value)}
                            value={this.props.search_name_code}
                        >
                            <option value="Name">Name</option>
                            <option value="Code">Code</option>
                        </Form.Select>
                        <Form.Control
                            className="text-sm ps-1 border-0 outline-0 shadow-0"
                            value={search_filter_input}
                            onChange={(e) => this.handle_input_change(e.target.value)}
                            onKeyDown={(e) => this.props.handle_key_press(e)}
                        />
                    </div>
                </div>
                <div className="border" style={{ borderRadius: ' 0rem 0.3rem 0.3rem 0rem' }}>
                    {this.props.search_clicked ? cancel_button : go_button}
                </div>
            </div>
        );
    }
}
