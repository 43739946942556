import * as utilityFunctions from './UtilityFunctions';

const get_options_w_token = (token) => {
    return {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    }
};


const put_options = (request) => {
    return {
        method: 'PUT',
            headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)}
}

const post_options = (request) => {
    return {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }
}


// GET Functions

export async function Get_By_Manager(sales_manager_id) {
    try {
        const response = await fetch(`CRMRequest/GetByManager/${sales_manager_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}
export async function Get_By_Requestor(company_id, salesperson_id) {
    try {
        const response = await fetch(`CRMRequest/GetByRequestor/${company_id}/${salesperson_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}
export async function Get_By_Requestee(company_id, salesperson_id) {
    try {
        const response = await fetch(`CRMRequest/GetByRequestee/${company_id}/${salesperson_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}
export async function Get_By_Responder(company_id, salesperson_id) {
    try {
        const response = await fetch(`CRMRequest/GetByResponder/${company_id}/${salesperson_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        window.location.reload()
        console.log(e);
        return [];
    }
}
export async function Get_By_Customer(company_id, customer_id) {
    try {
        const response = await fetch(`CRMRequest/GetByCustomer/${company_id}/${customer_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}
export async function Get_Credit_Requests() {
    try {
        const response = await fetch(`CRMRequest/GetCreditRequests`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        window.location.reload()
        console.log(e);
        return [];
    }
}
export async function Cancel_Request(id) {
    try {
        const response = await fetch(`CRMRequest/CancelRequest/${id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e)
    }  
}

export async function Cancel_Request_For_LAM(id, user_id) {
    try {
        const response = await fetch(`CRMRequest/CancelRequestForLAM/${id}/${user_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e)
    }
}
export async function Escalate_Account_Request(company_id, id, sales_manager_id) {
    try {
        const response = await fetch(`CRMRequest/EscalateAccountRequest/${id}/${sales_manager_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
        
    } catch (e) {
        console.log(e);
        return null;
    }
}
export async function Resolve_Account_Request(company_id, id) {
    try {
        const response = await fetch(`CRMRequest/ResolveAccountRequest/${id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}
export async function Switch_Account_Request(body) {
    try {
        const response = await fetch(`CRMRequest/SwitchAccountRequest/`, put_options(body));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
      

    } catch (e) {
        console.log(e);
        return null;
    }
}
export async function Switch_Account_Request_As_GM(body) {
    try {
        const response = await fetch(`CRMRequest/SwitchAccountRequestAsGM/`, put_options(body));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }


    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Deny_Account_Request_As_GM(body) {
    try {
        const response = await fetch(`CRMRequest/DenyAccountRequestAsGM/`, put_options(body));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }


    } catch (e) {
        console.log(e);
        return null;
    }
}

// PUT Functions
export async function Respond_To_Account_Request(company_id,id, remarks) {
    try {
        const response = await fetch(`CRMRequest/RespondToAccountRequest`, put_options({id:id,remarks:remarks}));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
       
    } catch (e) {
        console.log(e);
        return e;
    }
}
export async function Approve_Precheck(request) {
    try {
        const response = await fetch(`CRMRequest/ApprovePrecheck`, put_options(request));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }

    } catch (e) {
        console.log(e);
        return null;
    }
}
export async function Deny_Precheck(request) {
    try {
        const response = await fetch(`CRMRequest/DenyPrecheck`, put_options(request));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }

    } catch (e) {
        console.log(e);
        return null;
    }
}
export async function Approve_Conversion(request) {
    try {
        const response = await fetch('CRMRequest/ApproveConversion', put_options(request));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}
export async function Deny_Conversion(request) {
    try {
        const response = await fetch('CRMRequest/DenyConversion', put_options(request));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}
export async function Approve_Extension(request) {
    try {
        const response = await fetch('CRMRequest/ApproveExtension', put_options(request));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}
export async function Deny_Extension(request) {
    try {
        const response = await fetch('CRMRequest/DenyExtension', put_options(request));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}



// POST Functions
export async function Request_Precheck(request) {
    try {
        const response = await fetch('CRMRequest/RequestPrecheck', post_options(request));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }       
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Request_Extension(request) {
    try {
        const response = await fetch('CRMRequest/RequestExtension', post_options(request));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Request_Conversion(request) {
    try {
        const response = await fetch('CRMRequest/RequestConversion', post_options(request));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Request_Account(request) {
    try {
        const response = await fetch('CRMRequest/RequestAccount', post_options(request));
        const data = await response.json();
        if (data.length > 0) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function Get_No_Action_ACCT(company_id, sales_manager_id) {
    try {
        const response = await fetch(`CRMRequest/GetNoActionAcct/${company_id}/${sales_manager_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return [];
    }
}







