import React, { Component } from 'react';
import {  Form, InputGroup, Modal } from 'react-bootstrap';
import LoadingIcons from 'react-loading-icons';

export class ViewRequestModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            additional_details: '',
        };
    }

    render() {
        const { additional_details } = this.state;
        const selected_request = this.props.selected_request;
        return (
            selected_request?
            <div>
                <Modal
                    {...this.props}
                    size="xl"
                    fullscreen="md-down"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="w-100 d-flex flex-row justify-content-between">
                            <div className="flex-grow-2">
                                <h5 onClick={() => this.props.onHide()} className="opacity-75 hover-over p-2">
                                X
                                </h5>
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-grow-1">
                                <h4 className="w-100 text-center p-0 pt-2">
                                    {`${selected_request.request_name} Request for ${selected_request.customer_name}`}
                                </h4>
                                <h6 className="underlined text-center text-primary hover-over" onClick={() => this.props.view_account(selected_request.company_id, selected_request.customer_id)}>View Account</h6>
                            </div>
                        
                            <div className="flex-grow-2"></div>
                    </Modal.Header>
                        <Modal.Body style={{height:'70vh', overflow:'auto'} }>
                            {Object.keys(selected_request).map((key) => (
                                selected_request[key]?
                                                <InputGroup className="d-flex flex-row justify-content-between p-1" style={{ width: '100%' }}>
                                                    <InputGroup.Text className="text-nowrap text-sm p-1 text-bold capitalize w-25">
                                                        {key.replaceAll('_',' ')}:
                                            </InputGroup.Text>
                                            {key === 'remarks' ?
                                                <Form.Control
                                                    value={selected_request[key].replaceAll('&#xA;','\n')}
                                                    rows={ 4}
                                                    disabled={true}
                                                    as="textarea"
                                                    className="text-sm bg-white text-start"
                                                    style={{whiteSpace: 'pre-wrap' }}
                                                /> :
                                                <Form.Control
                                                    value={selected_request[key]}
                                                    className="text-sm bg-white text-start"
                                                    disabled={true}
                                                />
                                        }
                                                </InputGroup>:null

                                    ))}

                    </Modal.Body>
                </Modal>
            </div>:null
        );
    }
}
