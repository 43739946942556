import React, { Component } from 'react';
import { Card, Alert, Button, Spinner } from 'react-bootstrap';
import  LoadingIcons  from 'react-loading-icons';
import * as svg_icons from '../../Media/svg_exports';
export class DynamicPortalTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_tile: true,
        };
    }


    render_title(title) {
        const open_tile = this.state.open_tile
        const open_chevron = <label className="hover-over align-self-center" onClick={() => this.setState({ open_tile: false })}>
            {svg_icons.down_chevron}
        </label>

        const closed_chevron =
            <label className="hover-over align-self-center" onClick={() => this.setState({ open_tile: true })}>
                {svg_icons.left_chevron}
            </label>


        return (<div className="d-flex flex-row justify-content-between w-100 p-2">
            <label className="portal-tile-title align-self-center"> {title}</label>
            {this.props.loaded?open_tile ? open_chevron : closed_chevron:null}
        </div>)
       
    }

    render() {
        const { open_tile } = this.state;


        return (
            <Card className="flex-grow-1 d-flex w-100" style={this.props.style} >
                <Card.Header className="d-flex flex-row p-0 w-100 justify-content-between m-0">
                    <Card.Title className="d-flex flex-row w-100 justify-content-between p-0 m-0 text-nowrap">
                        {this.props.loaded ? this.render_title(this.props.title) : this.render_title(`Loading ${this.props.loading_title}...`)}
                    </Card.Title>                    
                </Card.Header>


                {open_tile ? (
                    <Card.Body className="p-2">
                        {this.props.loaded ?
                            this.props.body :
                            <div className="d-flex flex-row p-3 w-100 justify-content-center">
                                <LoadingIcons.TailSpin stroke="#25272c" strokeOpacity={.125} speed={.75} />
                            </div>
                            }
                    </Card.Body>
                ) : null}
            </Card>
        );
    }
}
