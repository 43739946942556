import { Credit_App_Uploaded } from './CustomerFunctions';

const get_options_w_token = (token) => {
    return {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    }
};


const put_options =(comment)=> {
    return {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(comment)}
};
const post_options = (comment) => {
    return {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(comment)
    }
};

export const comment_types = ['NOTES', 'CUSSETUP', 'CUSTCON', 'RATES', 'CONTRACT', 'CUSCREDI'];

export async function Get_By_Customer(company_id, customer_id) {
    try {
        const response = await fetch(`Comment/GetByCustomer/${company_id}/${customer_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Put(comment) {
    try {
        const response = await fetch('Comment', put_options(comment));

        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Post(comment) {
        try {
            const options = {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(comment),
            };
            
            const response = await fetch('Comment', options);
            const data = await response.json();

            return data;
        } catch (e) {
            console.log(e);
            return e;
        }

    }

export async function Upload(company_id,parent_row_id, comment_id,file_upload) {
    try {
        const headers = new Headers();
        headers.append('Authorization', process.env.REACT_APP_MCLEOD_AUTH);
        headers.append('x-com.mcleodsoftware.CompanyID', company_id);
        const options = {
            method: 'PUT',
            headers: headers,
            body: file_upload,
        };
        let base_url = company_id === "TMS" ? process.env.REACT_APP_SLC_BASE_URL : process.env.REACT_APP_SLUSA_BASE_URL;    
        const request_url = `${base_url}comments/${comment_id}/attachFile?fileName=${file_upload.name}`;
        const customer_data = await Credit_App_Uploaded(company_id, parent_row_id);
        const response = await fetch(request_url, options);
        const data = await response.text();


        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Delete(company_id, id) {
    try {
        const response = await fetch(`Comment/Delete/${company_id}/${id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Get_Template(company_id, customer_id, user_id) {
    try {
        const response = await fetch(`Comment/GetTemplate/${company_id}/${customer_id}/${user_id}`, get_options_w_token(sessionStorage.getItem('token')));
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Get_Audit_By_ID(company_id, customer_id) {
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
        };
        const response = await fetch(`CommentAudit/GetByAccount/${company_id}/${customer_id}`, options);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function Insert_Comment_Audit(prop) {
    try {
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(prop),
        };
        const response = await fetch('CommentAudit', options);
        const data = await response.json();
        if (data.length) {
            return data[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

//export async function View_File(company_id, id) {
//    try {
//        let base_url = company_id === "TMS" ? process.env.REACT_APP_SLC_BASE_URL : process.env.REACT_APP_SLUSA_BASE_URL;
//        const headers = new Headers();
//        headers.append('Authorization', process.env.REACT_APP_MCLEOD_AUTH);
//        headers.append('x-com.mcleodsoftware.CompanyID', company_id);
//        const options = {
//            method: 'GET', headers: headers
//        };
//        const response = await fetch(`${base_url}comments/${id}/attachment`, options);
//        const data = await response.text();
//        return data;
//    } catch (e) {
//        console.log(e);
//        return e;
//    }
//}
